import { useState, useEffect } from "react";

export const useCopyToClipboardLinks = (initialValue = false) => {
  const [isCopied, setIsCopied] = useState(initialValue);

  const copyToClipboardLinks = (path) => {
    const linkToCopy = `https://nikbakhtkhodro.com/custome?&identification=${path}`;
    const textArea = document.createElement("textarea");
    textArea.value = linkToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  return [isCopied, copyToClipboardLinks];
};
