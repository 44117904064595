import { Box, Button, Divider, Fade, Modal, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ModalInfo from "./ModalInfo";
import ModalContent from "./ModalContent";
import ButtonContactUs from "../../components/main/contact-us/ButtonContactUs";
import { useResolveBatchMutation } from "../../Store/callApi/apiContactus";
const ContactUsModal = ({ open, onClose, date, clock, message = {} }) => {
  const [resolveFn, { isLoading, error }] = useResolveBatchMutation();
  const { _id, createdAt, name, branch, description, phone } = message;
  const handleResolved = async () => {
    await resolveFn({
      resolved: true,
      contactUsMessagesIDs: [`${_id}`],
    });
    onClose();
  };
  console.log(message);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ borderRadius: 5 }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1,
          maxWidth: 600,
          height: 1,
          maxHeight: 360,
          bgcolor: "background.paper",
          boxShadow: 24,
          outline: "unset",
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ModalInfo
          name={name}
          phone={phone}
          date={date}
          onClose={onClose}
          clock={clock}
          branch={branch}
        />
        <Divider />
        <ModalContent content={description} />
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mx: 3,
            gap: 1,
            my: 2,
          }}
        >
          <Button
            sx={[
              {
                bgcolor: "#2196F3",
                borderRadius: 25,
              },
            ]}
            // startIcon={startIcon ? startIcon : null}
            variant="contained"
            onClick={handleResolved}
            disabled={isLoading}
          >
            بررسی شد
          </Button>
          {/* <ButtonContactUs
            disabled={isLoading}
            onClick={handleResolved}
            type="contained"
            label="بررسی شد"
            startIcon={null}
          /> */}
          <ButtonContactUs
            type="outlined"
            label="انصراف"
            startIcon={null}
            onClick={onClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactUsModal;
