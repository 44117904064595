import React, { useEffect } from "react";
import { Switch } from "@material-ui/core";
import { useState } from "react";
import styles from './Filter.module.css'
const TypeFilter = ({ title, typeFilter }) => {
  const [buyAndSeller, setBuyAndSeller] = useState("");

  useEffect(() => {
    if (buyAndSeller) {
      typeFilter("sell");
    } else {
      typeFilter("buy");
    }
  }, [buyAndSeller]);

  return (
    <>
      <h4>{title}</h4>
      <div className={styles.holder}>
        <Switch
          checked={buyAndSeller}
          onChange={() => setBuyAndSeller(!buyAndSeller)}
          name="checkedA"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        <div className={styles.holder}>
          <p className={buyAndSeller ? styles.bolder : styles.option}>
            فروش
          </p>
          <p> / </p>
          <p className={!buyAndSeller ? styles.bolder : styles.option}>
          خرید
          </p>
        </div>
      </div>
    </>
  );
};

export default TypeFilter;
