import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: null,
};

export const dataGetErrorsSlice = createSlice({
  name: "dataGetErrors",
  initialState,
  reducers: {
    updateData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateData } = dataGetErrorsSlice.actions;
export default dataGetErrorsSlice.reducer;
