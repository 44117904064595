import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, FormControl, MenuItem } from "@material-ui/core";
import AlertDialog from "../../common/AlertDialog";
import { inject, observer } from "mobx-react";
import Select from "@material-ui/core/Select";
import EditorGallery from "../../common/EditorGallery";
import { ApiHelper } from "../../../data/remote";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { Autocomplete } from "@material-ui/lab";
import { DefecenciesList } from "../../../values/DefecenciesList";
import Grid from "@material-ui/core/Grid";

const CAR_POINTS = [
  { type: "چرخ_جلو_سمت_شاگرد", x: 29, y: 5, identifier: 1 },
  { type: "چرخ_عقب_سمت_شاگرد", x: 66, y: 5, identifier: 2 },
  { type: "رکاب_عقب_سمت_شاگرد", x: 55, y: 5, identifier: 3 },
  { type: "رکاب_جلو_سمت_شاگرد", x: 43, y: 5, identifier: 4 },
  { type: "در_جلو_سمت_شاگرد", x: 43, y: 12, identifier: 5 },
  { type: "در_عقب_سمت_شاگرد", x: 55, y: 13, identifier: 6 },
  { type: "گلگیر_عقب_سمت_شاگرد", x: 72, y: 15, identifier: 7 },
  { type: "گلگیر_جلو_سمت_شاگرد", x: 29, y: 16, identifier: 8 },
  { type: "ستون_جلو_سمت_شاگرد", x: 40, y: 23, identifier: 9 },
  { type: "ستون_عقب_سمت_شاگرد", x: 62, y: 25, identifier: 10 },
  { type: "ستون_وسط_سمت_شاگرد", x: 51, y: 23, identifier: 11 },
  { type: "سقف", x: 50, y: 48, identifier: 12 },
  { type: "کاپوت", x: 25, y: 48, identifier: 13 },
  { type: "صندوق", x: 72, y: 49, identifier: 14 },
  { type: "چراغ_جلو_سمت_شاگرد", x: 9, y: 37, identifier: 15 },
  { type: "چراغ_عقب_سمت_شاگرد", x: 86, y: 35, identifier: 16 },
  { type: "چراغ_عقب_سمت_راننده", x: 86, y: 60, identifier: 17 },
  { type: "چراغ_جلو_سمت_راننده", x: 9, y: 59, identifier: 18 },
  { type: "سپر_جلو", x: 6, y: 48, identifier: 19 },
  { type: "سپر_عقب", x: 90, y: 48, identifier: 20 },
  { type: "چرخ_جلو_سمت_راننده", x: 29, y: 90, identifier: 21 },
  { type: "چرخ_عقب_سمت_راننده", x: 66, y: 90, identifier: 22 },
  { type: "در_عقب_سمت_راننده", x: 56, y: 82, identifier: 23 },
  { type: "در_جلو_سمت_راننده", x: 43, y: 84, identifier: 24 },
  { type: "رکاب_جلو_سمت_راننده", x: 43, y: 91, identifier: 25 },
  { type: "رکاب_عقب_سمت_راننده", x: 55, y: 91, identifier: 26 },
  { type: "گلگیر_عقب_سمت_راننده", x: 72, y: 81, identifier: 27 },
  { type: "گلگیر_جلو_سمت_راننده", x: 28, y: 80, identifier: 28 },
  { type: "ستون_جلو_سمت_راننده", x: 40, y: 72, identifier: 29 },
  { type: "ستون_وسط_سمت_راننده", x: 51, y: 72, identifier: 30 },
  { type: "ستون_عقب_سمت_راننده", x: 64, y: 72, identifier: 31 },
];

const carsParts = [
  { k: "bodyAndTires", v: "بدنه + لاستیک" },
  { k: "motor", v: "موتور" },
  { k: "electricAndInternal", v: "سیستم برقی + داخلی" },
  {
    k: "suspension",
    v: "سیستم تعلیق",
  },
  { k: "ventilation", v: "تهویه مطبوع" },
];

@inject("CarsStore")
@observer
class AddDefectDialog extends Component {
  constructor(props) {
    super(props);

    let indexCurrent =
      props.curent && props.curent.index >= 0 ? props.curent.index : -1;
    let parent = indexCurrent >= 0 ? props.curent.parent : "-1";
    let type = indexCurrent >= 0 ? props.curent.type : "";
    let identifier = indexCurrent >= 0 ? props.curent.identifier : "";
    let title = indexCurrent >= 0 ? props.curent.title : "";
    let image = indexCurrent >= 0 ? props.curent.image : "";
    let value = indexCurrent >= 0 ? props.curent.value : "";
    let level =
      indexCurrent >= 0 && props.curent.level ? props.curent.level : "-1";
    this.state = {
      indexCurrent: indexCurrent,
      parent: parent,
      message: "",
      type: type,
      identifier: identifier,
      title: title,
      description: value,
      showGalleryAsDialog: false,
      image: image,
      level: level,
      refresh: false,
      manuallDescription: "",
    };
    this.autoCompleteRef = React.createRef();
  }

  handleClose() {
    this.props.onClose();
  }

  submit() {
    if (this.state.parent === "-1") {
      this.setState({ message: "نوع نقص را مشخص کنید" });
      return;
    }

    if (this.state.parent === "bodyAndTires" && this.state.type === "") {
      this.setState({ message: "مکان نقص را مشخص کنید" });
      return;
    }

    if (this.state.title.toString().trim() === "") {
      this.setState({ message: "عنوان را مشخص کنید" });
      return;
    }

    if (this.state.description.toString().trim() === "") {
      this.setState({ message: "توضیحات را مشخص کنید" });
      return;
    }

    if (this.state.level === "-1") {
      this.setState({ message: "شدت نقص را مشخص کنید" });
      return;
    }

    if (this.state.indexCurrent >= 0) {
      this.props.CarsStore.currentCar.specifications.defects[this.state.parent][
        this.state.indexCurrent
      ] = {
        type: this.state.type,
        title: this.state.title,
        value: this.state.description,
        image: this.state.image,
        level: this.state.level,
        identifier: this.state.identifier,
      };
    } else {
      this.props.CarsStore.currentCar.specifications.defects[
        this.state.parent
      ].push({
        type: this.state.type,
        title: this.state.title,
        value: this.state.description,
        image: this.state.image,
        level: this.state.level,
        identifier: this.state.identifier,
      });
    }

    this.props.onSuccess(
      this.props.CarsStore.currentCar.specifications.defects
    );
  }

  closeListOfDefeciencies() {
    this.autoCompleteRef.current.blur();
  }

  render() {
    const DefecenciesOptions = DefecenciesList.map((option) => {
      const firstLetter = option.label[0];
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{ width: 300, padding: 0, margin: 0, height: "auto" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              {this.state.indexCurrent < 0 && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControl label="نوع نقص" fullWidth>
                    <Select
                      defaultValue={
                        !this.state.parent ? "-1" : this.state.parent
                      }
                      variant="outlined"
                      margin={"dense"}
                      style={{ fontSize: 13, margin: "10px 10px 0 10px" }}
                      onChange={(e) =>
                        this.setState({ parent: e.target.value, type: "" })
                      }
                    >
                      <MenuItem style={{ fontSize: 13 }} value={"-1"}>
                        انتخاب نوع نقص
                      </MenuItem>

                      {carsParts.map((l, i) => (
                        <MenuItem key={i} style={{ fontSize: 13 }} value={l.k}>
                          {l.v}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {this.state.parent === "bodyAndTires" && (
                <div
                  style={{
                    width: 300,
                    height: 224,
                    maxWidth: 300,
                    maxHeight: 224,
                    position: "relative",
                    marginTop: 10,
                  }}
                >
                  <img
                    style={{ width: 300, height: 224 }}
                    src={require("../../../assets/images/open_car.png")}
                  />

                  {CAR_POINTS.map((p, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        this.setState({
                          type: p.type,
                          identifier: p.identifier,
                          title: p.type.replace(/_/g, " "),
                        });
                      }}
                      style={{
                        backgroundColor:
                          this.state.type === p.type ? "red" : "green",
                        top: (p.y / 100) * 222,
                        left: (p.x / 100) * 298,
                        position: "absolute",
                        zIndex: 100,
                        width: 14,
                        height: 14,
                        borderRadius: 7,
                        cursor: "pointer",
                      }}
                    ></div>
                  ))}
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  fontSize: 13,
                  marginTop: 5,
                }}
              >
                {this.state.image === "" ? (
                  <Button
                    fullWidth
                    onClick={() => this.setState({ showGalleryAsDialog: true })}
                    style={{ margin: 10 }}
                    variant="outlined"
                    color="secondary"
                    size="small"
                  >
                    انتخاب تصویر
                  </Button>
                ) : (
                  <div style={{ position: "relative" }}>
                    <img
                      src={this.state.image}
                      style={{
                        margin: 10,
                        border: "1px solid #d2d2d2",
                        borderRadius: 5,
                        width: 280,
                        height: 200,
                        objectFit: "cover",
                      }}
                    />
                    <IconButton
                      size="small"
                      style={{
                        position: "absolute",
                        left: 20,
                        top: 15,
                        backgroundColor: "#fff",
                      }}
                      onClick={() => this.setState({ image: "" })}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </div>
                )}
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  onChange={(t) => this.setState({ title: t.target.value })}
                  style={{ margin: 10, flex: 1 }}
                  value={this.state.title}
                  label={"عنوان"}
                  placeholder={"عنوان"}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{ style: { fontSize: 15 }, maxLength: 400 }}
                />
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <FormControl label="نوع قیمت" style={{ margin: 10, flex: 1 }}>
                  <Select
                    defaultValue={!this.state.level ? "-1" : this.state.level}
                    variant="outlined"
                    margin={"dense"}
                    style={{ fontSize: 13, marginTop: 10 }}
                    onChange={(e) => (this.state.level = e.target.value)}
                  >
                    <MenuItem style={{ fontSize: 13 }} value={"-1"}>
                      شدت نقص
                    </MenuItem>
                    {[
                      { k: "خفیف (بدون رنگ)", v: "0" },
                      { k: "نسبی (بدون رنگ)", v: "1" },
                      { k: "(رنگ) شدید", v: "2" },
                    ].map((l, i) => (
                      <MenuItem key={i} style={{ fontSize: 13 }} value={l.v}>
                        {l.k}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{ position: "relative", margin: "1rem 0 4rem 0" }}>
                <Autocomplete
                  ref={this.autoCompleteRef}
                  onChange={(event, newValue) => {
                    if (newValue?.label != undefined)
                      this.setState({
                        description:
                          this.state.description + "," + newValue?.label,
                      });
                  }}
                  value={this.state.description}
                  disablePortal
                  freeSolo
                  autoHighlight
                  groupBy={(option) => option.firstLetter}
                  id="defecencies_list"
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => option?.label?.toString() || ""}
                  options={DefecenciesOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => {
                        if (e.target.value != undefined) {
                          this.state.manuallDescription = e.target.value;
                        }
                      }}
                      label="توضیح"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                  style={{ width: "90%", margin: "0 auto" }}
                />
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "2rem 1rem",
                  }}
                >
                  <Button>{this.state.description}</Button>
                </div>
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "2rem 1rem",
                  }}
                >
                  <Button
                    style={{ backgroudColor: "red" }}
                    variant="contained"
                    onClick={() => this.setState({ description: "" })}
                  >
                    حذف
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() =>
                      this.setState({
                        description:
                          this.state.description +
                          "," +
                          this.state.manuallDescription,
                      })
                    }
                  >
                    ثبت
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>

          {this.state.sendRequest ? (
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={25} />
            </DialogActions>
          ) : (
            <DialogActions style={{ justifyContent: "space-between" }}>
              <Grid lg={4}>
                <Button
                  onClick={() => this.closeListOfDefeciencies()}
                  color="secondary"
                >
                  بستن لیست
                </Button>
              </Grid>

              <Grid
                item
                lg={8}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button onClick={() => this.submit()} color="primary">
                  انجام
                </Button>

                <Button onClick={() => this.handleClose()} color="secondary">
                  بستن
                </Button>
              </Grid>
            </DialogActions>
          )}
        </Dialog>

        {this.state.showGalleryAsDialog && (
          <EditorGallery
            addImageToEditor={(src) =>
              this.setState({ showGalleryAsDialog: false, image: src })
            }
            closeGalleryDialog={() =>
              this.setState({ showGalleryAsDialog: false })
            }
            showGalleryDialog={this.state.showGalleryAsDialog}
            images={[]}
            onChangeImages={(images) => {}}
            storeKey="currentGallery"
            getGalleryURL={ApiHelper.get_car_gallery_GET(
              this.props.CarsStore.currentCar.code
            )}
            uploadImageURL={ApiHelper.uploadCarImage_POST(
              this.props.CarsStore.currentCar._id
            )}
            removeImageURL={ApiHelper.removeCarImage_GET(
              this.props.CarsStore.currentCar._id
            )}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </div>
    );
  }
}

AddDefectDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  curent: PropTypes.any,
};
export default AddDefectDialog;
