import { configureStore } from "@reduxjs/toolkit";
import SliceDataRequestCar from "./slice/SliceDataRequestCar";
import { setupListeners } from "@reduxjs/toolkit/query";
import { GetApiLinks } from "../Store/callApi/getApiLinks";
import { DataListAdmins } from "../Store/callApi/AdminDataService";
import ListInfoLinks from "./slice/sliceListInfoLinks";
import { CitiesData } from "../Store/callApi/MarketingInformation";
import { apiGetErrors } from "./callApi/apiGetErrors.js";
import getErrorsParams from "./slice/getErrorsParams.js";
import dataGetErrorsSlice from "./slice/dataGetErrorsSlice.js";
import snackbarSlice from "./slice/Snackbar";
import dateSlice from "./slice/dateSlice";
import { carInquiryApi } from "../Store/callApi/carInquiryApi.jsx";
import { apiRequest } from "./callApi/request.js";
import sliceNewRequest from "./slice/sliceNewRequest.js";
import { webApiSell } from "./callApi/webApiSell.js";
import { apiCoworkers } from "./callApi/apiCoworkers.js";
import { rtkQueryErrorLogger } from "./middlewares/errMiddleware.js";
import sliceContactUs from "./slice/sliceContactUs.js";
import { apiContactus } from "./callApi/apiContactus.js";
export const store = configureStore({
  reducer: {
    SellRequestCar: SliceDataRequestCar,
    dataLinks: ListInfoLinks,
    getErrorsParams,
    dataGetErrors: dataGetErrorsSlice,
    snackbar: snackbarSlice,
    inquiryData: dateSlice,
    newRequest: sliceNewRequest,
    contactUs: sliceContactUs,
    [GetApiLinks.reducerPath]: GetApiLinks.reducer,
    [DataListAdmins.reducerPath]: DataListAdmins.reducer,
    [CitiesData.reducerPath]: CitiesData.reducer,
    [apiGetErrors.reducerPath]: apiGetErrors.reducer,
    [carInquiryApi.reducerPath]: carInquiryApi.reducer,
    [apiRequest.reducerPath]: apiRequest.reducer,
    [webApiSell.reducerPath]: webApiSell.reducer,
    [apiCoworkers.reducerPath]: apiCoworkers.reducer,
    [apiContactus.reducerPath]: apiContactus.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      GetApiLinks.middleware,
      DataListAdmins.middleware,
      CitiesData.middleware,
      apiGetErrors.middleware,
      CitiesData.middleware,
      webApiSell.middleware,
      carInquiryApi.middleware,
      apiRequest.middleware,
      apiCoworkers.middleware,
      rtkQueryErrorLogger,
      apiContactus.middleware
    ),
});

setupListeners(store.dispatch);
