import React from "react";
import styles from "./ActiveStatusCircle.module.css";
export default function ActiveStatusCircle({ active }) {
  return (
    <p>
      {active ? (
        <span className={styles.textContainer}>
          <p className={styles.text}>فعال</p>
          <span className={styles.activeDot}></span>
        </span>
      ) : (
        <span className={styles.DiactiveTextContainer}>
          <p className={styles.text}>غیر فعال</p>
          <span className={styles.diActiveDot}></span>
        </span>
      )}
    </p>
  );
}
