import React, { useEffect, useState } from "react";
import Main from "../../common/UserMain";
import styles from "./exclusiveLinks.module.css";
import ExclusiveLinksDialog from "./exclusive_links_dialog/exclusiveLinksDialog";
import InfoListLink from "./list_infoLink/infoListLink";
import SnackBar from "../../common/SnackBar";
import { useDispatch, useSelector } from "react-redux";
import { setLinks } from "../../../Store/slice/sliceListInfoLinks";
import { useGetPokemonByNameQuery } from "../../../Store/callApi/getApiLinks";

import { Fab, Grid, Paper, Divider, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Pagination from "@material-ui/lab/Pagination";

const ExclusiveLinks = () => {
  const [openDialogAddEdit, setOpenDialogAddEdit] = useState(false);
  const [messageSnakBar, setMessageSnakBar] = useState("");
  const [numberPage, setNumberPage] = useState(1);
  const Dispatch = useDispatch();
  const { data } = useGetPokemonByNameQuery({
    page: numberPage,
  });

  const newListLinks = useSelector(({ dataLinks }) => dataLinks.links);
  useEffect(() => {
    if (data) {
      Dispatch(setLinks(data.links));
    }
  }, [data]);

  return (
    <Main
      page={"exclusive_links"}
      pageName="ایجاد لینک اختصاصی"
      transparentBar={false}
      leftAppBarComponent={
        <Fab
          onClick={() => setOpenDialogAddEdit(true)}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          ایجاد <AddIcon />
        </Fab>
      }
    >
      <Paper style={{ minHeight: "80vh" }} className={styles.paperStyle}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={styles.headerText} variant="h5">
              تگ ها
            </Typography>
            <Divider />
          </Grid>
        </Grid>
        {newListLinks?.map((item) => (
          <InfoListLink
            OpenDialogEdit={setOpenDialogAddEdit}
            key={item._id}
            infoLink={item}
          />
        ))}
      </Paper>
      <ExclusiveLinksDialog
        openDialog={openDialogAddEdit}
        OpenDialogAddEdit={setOpenDialogAddEdit}
      />
      {data?.pageCount >= 1 && (
        <Pagination
          onChange={(e, page) => setNumberPage(page)}
          className={styles.paginationStyle}
          count={data?.pageCount}
          page={numberPage}
          variant="outlined"
          shape="rounded"
        />
      )}
      <SnackBar
        variant={"success"}
        message={messageSnakBar}
        open={messageSnakBar !== ""}
        onClose={() => setMessageSnakBar("")}
      />
    </Main>
  );
};

export default ExclusiveLinks;
