import React from "react";
import { Calendar, CalendarProvider } from "zaman";

const CalendarRequest = ({ handleChange, dateMutual }) => {
  return (
    <CalendarProvider
      key={new Date()}
      locale="fa"
      round="x3"
      accentColor="#2196F3"
    >
      <Calendar
        key={new Date().getMilliseconds()}
        defaultValue={new Date(dateMutual)}
        onChange={(date) => {
          handleChange(date.toISOString().slice(0, 10));
        }}
        weekends={[6]}
      />
    </CalendarProvider>
  );
};

export default CalendarRequest;
