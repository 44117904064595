const InfoListSubPermissions = [
  {
    permissionCode: 1,
    label: "مزایده زنده",
  },
  {
    permissionCode: 2,
    label: "وضعیت خودرو",
  },
  {
    permissionCode: 3,
    label: "ویرایش وضعیت خودرو",
  },
  {
    permissionCode: 4,
    label: " ویرایش خودرو به صورت کلی",
  },
  {
    permissionCode: 5,
    label: "دسترسی به  تمام بروکر ها",
  },
  {
    permissionCode: 7,
    label: "استعلام",
  },
];

export default InfoListSubPermissions;
