import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {},
  header: {
    padding: "10px",
  },
  itemReporting: {
    width: "412px",
    border: "1px solid #ddd",
    margin: "10px",
  },
  stylePaperBox: {
    padding: "5px",
    marginTop: "17px",
  },
  infoContractStyle: {
    padding: "10px",
  },
  styleCreatedAt: {
    display: "flex",
    flexDirection: "column",
  },
  textContract: {
    fontWeight: "bold",
  },
  btnStyleContracts: {
    margin: "4px",
    width: "181px",
  },
  accordionStyle: {
    marginBottom: "10px",
  },
  boxListContracts: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "start",
  },
  infoCarStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  switchAgent: {
    marginLeft: "10px",
    padding: "10px",
  },
  paginationStyle: {
    display: "flex",
    justifyContent: "center",
  },
  titleStyleCar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textStyleCar: {
    marginRight: "5px",
    display: "flex",
    marginBottom: "7px",
    marginTop: "7px",
  },
  titleContract: {
    padding: "10px",
  },
  messageContract: {
    textAlign: "center",
  },
  loadingStyle: {
    margin: "0 5px",
  },
  accordionItemStyle: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnGruopStyle: {
    marginTop: "20px",
    marginBottom: "20px",
    textAlign: "center",
  },
  imageStyle: {
    width: "29em",
    marginTop: "33px",
  },
  closeIconStyle: {
    margin: "10px",
    cursor: "pointer",
    background: "#ab0000",
    color: "white",
    borderRadius: "5px",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  skeletonStyle: {
    width: 412,
    height: 360,
    margin: 15,
    transform: "unset",
  },
  textContractNumber: {
    textAlign: "end",
  },
  textDate: {
    margin: "10px",
  },
  styleBoxReporting: {
    width: "300px",
    border: "1px solid gray",
    padding: "10px",
  },
});
