import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../Store/slice/dataGetErrorsSlice";
import {
  deleteAllChartCheckBoxes,
  selectAllCheckbox,
  updateRTK,
} from "../../Store/slice/getErrorsParams";
import {
  useGetErrorsQuery,
  usePatchResolveMutation,
} from "../../Store/callApi/apiGetErrors";

import DisplayDateString from "../../components/common/DisplayDateString/DisplayDateString";
import Calendar from "../../components/common/Calendar/Calendar";
import DatePickerMobile from "../../components/common/DatePickerMobile/DatePickerMobile";
import Toast from "../../components/common/HotToast/ToasterError";
import SelectAllCheckbox from "../../components/common/SelectAllCheckbox/SelectAllCheckbox";

import NoError from "./NoError";
import GridContainer from "./GridContainer";
import PaginationError from "./PaginationError";

import {
  main,
  checkBox,
  checkBoxInput,
  btnSolve,
  chart,
  calendar,
  datePickerTop,
} from "../../styles/main";
import LoadingSkeleton from "./LoadingSkeleton";
import DisplayDateError from "./DisplayDateError";
const Main = () => {
  const [dateMutual, setDateMutual] = useState(new Date());
  const [dateDigit, setDateDigit] = useState("");
  const mainStyle = main();
  const checkBoxContainer = checkBox();
  const checkBoxStyle = checkBoxInput();
  const solveBtn = btnSolve();
  const calendarStyle = calendar();
  const datePickerTopStyle = datePickerTop();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMatchesMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMatchesTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const { chartCheckBox, isSelectAll } = useSelector(
    (store) => store.getErrorsParams
  );

  const {
    page,
    itemCount,
    createdAt,
    criteriaLevel,
    recent,
    topic = "",
    resolved,
  } = useSelector((store) => store.getErrorsParams);
  const {
    isLoading,
    isSuccess,
    data: dataGet,
    isFetching,
  } = useGetErrorsQuery({
    page,
    itemCount,
    createdAt,
    topic,
    criteriaLevel,
    recent,
    resolved,
  });

  const [patchFn, b] = usePatchResolveMutation();
  const chartContainer = chart();

  const resolve = async () => {
    if (!chartCheckBox.length > 0) return;
    const result = await patchFn({
      errsIDs: chartCheckBox,
      resolved: true,
    });
    dispatch(deleteAllChartCheckBoxes());
  };

  const handleOnChangeSelectAll = () => {
    dispatch(selectAllCheckbox(dataGet.errors));
    if (isSelectAll) {
      dispatch(deleteAllChartCheckBoxes());
    }
  };

  useEffect(() => {
    if (!isFetching) {
      dispatch(updateData(dataGet));
    }
  }, [dataGet]);
  return (
    <>
      <Toast />
      <Grid
        container
        className={`${mainStyle.root} ${
          isMatchesTablet ? mainStyle.tabletMarginTop : ""
        }`}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
          <Hidden smDown>
            {/* <DisplayDateString
              key={new Date()}
              dateMutual={dateMutual}
              onSetDateMutual={setDateMutual}
              onSetDateDigit={setDateDigit}
              param={"createdAt"}
              updateStore={updateRTK}
            /> */}
            <DisplayDateError
              key={new Date()}
              dateMutual={dateMutual}
              onSetDateMutual={setDateMutual}
              onSetDateDigit={setDateDigit}
            />
          </Hidden>
          <Hidden mdUp>
            <DatePickerMobile
              className={datePickerTopStyle.root}
              param={"createdAt"}
              updateStore={updateRTK}
              dateOnChange={(value) =>
                dispatch(
                  updateStore({
                    createdAt: `${value.toISOString().slice(0, 10)}`,
                  })
                )
              }
            />
          </Hidden>
          <Box
            component="div"
            className={`${checkBoxContainer.root} ${
              isMatchesMobile && checkBoxContainer.responsiveMobile
            }`}
          >
            <SelectAllCheckbox
              className={`${checkBoxStyle.root} ${
                isMatchesMobile ? checkBoxStyle.fontSizeMobile : ""
              }`}
              checked={isSelectAll}
              onChange={handleOnChangeSelectAll}
              type="primary"
            />
            <Button
              variant="outlined"
              color="primary"
              disabled={!dataGet?.errors?.length > 0 ? true : false}
              startIcon={<DoneIcon />}
              className={`${solveBtn.root} ${
                isMatchesMobile ? solveBtn.fontSizeMobile : ""
              }`}
              onClick={() => resolve()}
            >
              حل شده
            </Button>
          </Box>
          <Box
            component="div"
            className={`${chartContainer.root} ${
              isSuccess && dataGet?.errors.length <= 0
                ? chartContainer.noneBorder
                : ""
            }`}
          >
            <LoadingSkeleton isLoading={isLoading} />
            {isSuccess &&
              dataGet?.errors?.map((error) => (
                <GridContainer error={error} key={error._id} />
              ))}
            {dataGet?.errors.length <= 0 && <NoError />}
          </Box>
          <PaginationError />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <Hidden smDown>
            <Calendar
              key={new Date()}
              dateMutual={dateMutual}
              onSetDateMutual={setDateMutual}
              className={calendarStyle.root}
              updateStore={updateRTK}
            />
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
};

export default Main;
