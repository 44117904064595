import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { ApiClient, ApiHelper } from "../../../data/remote";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import AlertDialog from "../../common/AlertDialog";
import { inject, observer } from "mobx-react";
import { convertNumberToEN } from "../../../language/NumberConverter";
import Select from "@material-ui/core/Select";

var moment = require("moment-jalaali");

let apiClient = null;

@inject("AccountStore")
@inject("ExpertsStore")
@observer
class ChangeStatusRequestDialog extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();

    this.state = {
      status: "",
      msg: "",
      sendRequest: false,
      message: "",
      showUserInfo: "",
    };
  }

  handleClose() {
    this.props.onClose();
  }

  submit() {
    if (this.state.sendRequest) return;

    if (this.state.status === "") {
      this.setState({ message: "لطفا وضعیت را مشخص کنید" });
      return;
    }

    this.setState({ sendRequest: true, inputWarn: 0 });

    apiClient.get(
      ApiHelper.changeStatusAgent_GET(
        this.state.status,
        this.props.ExpertsStore.current._id
      ),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false }, () =>
            this.props.onSuccess(response.data._id)
          );
        }
      }
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{ width: "80vw", maxWidth: 500, minWidth: 300, padding: 5 }}
          >
            <div style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  margin: 12,
                  borderBottom: "1px solid #d2d2d2",
                }}
              >
                <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                  {this.props.ExpertsStore.current.status}
                </p>
                <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                  {moment(this.props.ExpertsStore.current.createdAt).format(
                    "jYYYY/jM/jD"
                  )}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  margin: 12,
                  borderBottom: "1px solid #d2d2d2",
                }}
              >
                <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                  {this.props.ExpertsStore.current.name}
                </p>
                <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                  {this.props.ExpertsStore.current.username}
                </p>
              </div>

              <div style={{ display: "flex", flex: 1, margin: 12 }}>
                <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                  {this.props.ExpertsStore.current.phone}
                </p>
              </div>

              <FormControl style={{ flex: 1, margin: 10 }} label="وضعیت">
                <Select
                  defaultValue={
                    this.state.status === "" ? "0" : this.state.status
                  }
                  variant="outlined"
                  margin={"dense"}
                  style={{ fontSize: 13 }}
                  onChange={(e) =>
                    this.setState({
                      status: e.target.value === "0" ? "" : e.target.value,
                    })
                  }
                >
                  <MenuItem style={{ fontSize: 13 }} value={"0"}>
                    انتخاب وضعیت
                  </MenuItem>
                  <MenuItem style={{ fontSize: 13 }} value={"accepted"}>
                    تایید
                  </MenuItem>
                  <MenuItem style={{ fontSize: 13 }} value={"blocked"}>
                    مسدود
                  </MenuItem>
                  <MenuItem style={{ fontSize: 13 }} value={"deleted"}>
                    حذف
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </DialogContent>

          {this.state.sendRequest ? (
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={25} />
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => this.submit()} color="primary">
                ثبت
              </Button>

              <Button onClick={() => this.handleClose()} color="secondary">
                بستن
              </Button>
            </DialogActions>
          )}
        </Dialog>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </div>
    );
  }
}

ChangeStatusRequestDialog.defultProps = {};

ChangeStatusRequestDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  history: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default ChangeStatusRequestDialog;
