import { createApi } from "@reduxjs/toolkit/query/react";
//////
import stores from "../../data/stores";
//////
import { baseURL } from "../../baseURL";
import createBaseQueryWithReauth from "../../modules/HOC/createBaseQueryWithReauth ";
import { showSnackbar } from "../slice/Snackbar";
export const CitiesData = createApi({
  reducerPath: "cities",
  baseQuery: createBaseQueryWithReauth(baseURL + "admin/cities"),
  tagTypes: ["CitiesList"],
  endpoints: (builder) => ({
    getCities: builder.query({
      query: () => `/`,
      providesTags: ["CitiesList"],
    }),
    getArchivedCities: builder.query({
      query: () => `/archive`,
      providesTags: ["CitiesList"],
    }),
    changeCityStatus: builder.mutation({
      query: (cityId) => ({
        url: `/${cityId}`,
        method: "POST",
      }),
      invalidatesTags: ["CitiesList"],
    }),
    createCity: builder.mutation({
      query: (body) => ({
        url: ``,
        method: "POST",
        body,
      }),
      invalidatesTags: ["CitiesList"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            showSnackbar({
              message: result.data.message,
              variant: "success",
            })
          );
        } catch (error) {
          dispatch(
            showSnackbar({
              message: " شهری با این نام وجود دارد  .",
              variant: "error",
            })
          );
        }
      },
    }),
    updateCity: builder.mutation({
      query: ({ ID, body }) => ({
        url: `/${ID}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["CitiesList"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
               toast.success("Success toast!", {
                 style: {
                   background: "#4CAF50",
                   color: "#fff",
                   width: "100%",
                   maxWidth: "320px",
                 },
                 icon: <SuccessHotToast />,
               });
        } catch (error) {
          let errorMessage = error?.data?.message;
          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error",
            })
          );
        }
      },
    }),
    deleteCity: builder.mutation({
      query: ({ ID, body }) => ({
        url: `/${ID}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["CitiesList"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            showSnackbar({
              message: result.data.message,
              variant: "success",
            })
          );
        } catch (error) {
          let errorMessage = error?.data?.message;
          dispatch(
            showSnackbar({
              message: errorMessage,
              variant: "error",
            })
          );
        }
      },
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useCreateCityMutation,
  useUpdateCityMutation,
  useDeleteCityMutation,
  useGetArchivedCitiesQuery,
} = CitiesData;
