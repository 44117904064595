import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ApiHelper, ApiClient } from "../../../../data/remote";
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  root: {
    background: 'white',
    color : "red",
    '&:hover' : {
      backgroundColor : 'red',
      color:  "white"

    }
  },
  outlinedPrimary : {
    border: '1px solid red',
    '&:hover' : {
      border: '1px solid red',
    }
  }
 
});

const DeleteAllLogs = ({ setLogs, setError, setLoading }) => {
  const [deleteDialogOpen , setDeleteDialogOpen] = useState(false);
  const apiClient = ApiClient.getInstance();
  const classes = useStyles();

  const handkeClickOpen = () => {
    setDeleteDialogOpen(true);
  }

  const handleClose = () => {
    setDeleteDialogOpen(false);
  }

  const DeleteSubmit = () => {
    setLoading(true);
    apiClient.post(ApiHelper.deleteAllLogs, (error, response) => {
      setLoading(false);
      if (error) {
        setError(true);
      } else {
        setLogs([]);
        handleClose();
      }
    });
  };

  return (
    <div>
      <Grid item lg={8} style={{ display: "flex", marginBottom: 5, marginRight: 5 }}>
        <Button
          onClick={() => handkeClickOpen()}
          color="primary"
          variant="outlined"
          startIcon={<DeleteIcon />}
          classes={{
            root: classes.root, // class name, e.g. `classes-nesting-root-x`
            outlinedPrimary : classes.outlinedPrimary
          }}
        >
          حذف تمامی لاگ ها
        </Button>
        <Dialog
        open={deleteDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"آیا از حذف تمامی لاگ ها اطمینان دارید؟"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>لغو کردن</Button>
          <Button onClick={DeleteSubmit} autoFocus>
            بله
          </Button>
        </DialogActions>
      </Dialog>
      </Grid>
    </div>
  );
};

export default DeleteAllLogs;
