import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../data/remote";
import CircularProgress from "@material-ui/core/CircularProgress";
import AlertDialog from "../common/AlertDialog";
import { Styles } from "../../values/Styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Colors } from "../../values/Colors";
let apiClient = null;

@inject("AccountStore")
@observer
class Login extends Component {
  constructor(props) {
    super(props);

    if (props.AccountStore.token !== "") props.history.replace("/home");

    apiClient = ApiClient.getInstance();
    this.state = {
      username: "",
      password: "",
      invalidInput: false,
      sendRequest: false,
      sendRequestErr: false,
      message: "",
      userNotFound: false,
      showPassword: false,
    };
  }

  sendRequest() {
    if (this.state.sendRequest) {
      return;
    }

    if (this.state.username.toString().length < 4) {
      this.setState({ inputWarn: 1 }, () => this.inputUser.focus());
      return;
    }

    if (this.state.password.toString().length < 6) {
      this.setState({ inputWarn: 2 }, () => this.inputPass.focus());
      return;
    }

    this.setState({
      sendRequest: true,
      sendRequestErr: false,
      userNotFound: false,
    });

    let data = {
      username: this.state.username,
      password: this.state.password,
      fcmId: this.props.AccountStore.fcmId,
    };

    apiClient.post(
      ApiHelper.loginRequest_POST,
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          this.props.AccountStore.setTokenAndType(
            response.token,
            response.type,
            response.name
          );
          this.props.history.replace("/home");
        }
      },
      data
    );
  }

  render() {
    return (
      <Grid
        item
        style={Styles.main}
        container
        justify={"center"}
        alignItems={"center"}
      >
        <div style={{ ...Styles.centerAlignItemsContainer, paddingBottom: 70 }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              padding: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px 2px rgba(0,0,0,0.2)",
            }}
          >
            <img
              width="150px"
              src={require("../../assets/images/logo_width_text_2_400.png")}
              style={{ objectFit: "cover" }}
            />
          </div>

          <Paper style={{ margin: "100px 10px 10px 10px" }}>
            <Grid style={{ padding: 10, minWidth: 250, maxWidth: 390 }}>
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 10,
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                ورود به مدیریت
              </Typography>

              <TextField
                inputRef={(r) => (this.inputUser = r)}
                onChange={(t) => (this.state.username = t.target.value)}
                style={{ marginBottom: 5 }}
                defaultValue={this.state.username}
                error={this.state.inputWarn === 1}
                label={"نام کاربری"}
                placeholder={"نام کاربری"}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                name="username"
                inputProps={{
                  style: { textAlign: "left", fontSize: 15, direction: "ltr" },
                  maxLength: 30,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>person</Icon>
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") this.inputPass.focus();
                }}
              />

              <TextField
                inputRef={(r) => (this.inputPass = r)}
                onChange={(t) => (this.state.password = t.target.value)}
                style={{ marginBottom: 20 }}
                defaultValue={this.state.password}
                error={this.state.inputWarn === 2}
                label={"رمز عبور"}
                placeholder={"رمز عبور"}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                type={this.state.showPassword ? "text" : "password"}
                autoComplete="current-password"
                name="password"
                inputProps={{
                  style: { textAlign: "left", direction: "ltr", fontSize: 15 },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>lock_outline</Icon>
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    this.sendRequest();
                  }
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
              >
                {this.state.showPassword
                  ? " پنهان کردن رمز عبور"
                  : "نمایش رمز عبور"}
              </Button>

              <Grid item>
                <Button
                  style={{ marginTop: 10 }}
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={() => this.sendRequest()}
                >
                  {this.state.sendRequest ? (
                    <CircularProgress size={22} color="inherit" />
                  ) : (
                    "ورود"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <a
            href="https://nikbakhtkhodro.com"
            style={{
              fontSize: 12,
              color: Colors.colorPrimaryLight,
              margin: 15,
              textDecoration: "underline",
            }}
          >
            بازگشت به سایت
          </a>
        </div>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() =>
              this.setState(
                { message: "" },
                () =>
                  this.state.userNotFound &&
                  this.props.history.push("/register")
              )
            }
            type={"error"}
            question={false}
            description={this.state.message}
          />
        )}
      </Grid>
    );
  }
}

export default Login;
