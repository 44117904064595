import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import DisplayDateString from "../../components/common/DisplayDateString/DisplayDateString";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveRequestDate,
  setCreatedAt,
  setDateMutual,
  setIsShowAllDays,
  setReservedAt,
} from "../../Store/slice/sliceNewRequest";
const DisplayDate = ({ dateDigit, onSetDateDigit }) => {
  const { dateMutual, isShowAllDays } = useSelector(
    (store) => store.newRequest
  );
  const dispatch = useDispatch();
  const handleShowDisplayDateString = () => {
    dispatch(setIsShowAllDays());
    dispatch(setActiveRequestDate());
  };
  return (
    <Box
      sx={{
        marginTop: "55px",
        display: { xs: "none", lg: "flex" },
        alignItems: "center",
        justifyContent: "center",
        marginBottom: isShowAllDays && "-41px",
      }}
    >
      {isShowAllDays && (
        <DisplayDateString
          dateMutual={new Date(dateMutual)}
          onSetDateMutual={setDateMutual}
          updateStore={null}
          dateDigit={dateDigit}
          onSetDateDigit={onSetDateDigit}
          onSetCreatedAt={setCreatedAt}
          onSetReservedAt={setReservedAt}
          param="createdAt"
          hasStoreState={true}
        />
      )}

      {!isShowAllDays && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 6 }}>
          <IconButton
            sx={{
              bgcolor: "primary.light",
              "&:hover": { bgcolor: "primary.light" },
              "& .MuiSvgIcon-root": {
                fill: "#fff",
              },
            }}
            onClick={handleShowDisplayDateString}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <Typography
            variant="h5"
            component="h5"
            color="primary.dark"
            fontSize={18}
          >
            همه روزها
          </Typography>
          <IconButton
            sx={{
              bgcolor: "primary.light",
              width: 40,
              height: 40,
              position: "relative",
              "&:hover": { bgcolor: "primary.light" },
              "& .MuiSvgIcon-root": {
                position: "absolute",
                left: "33%",
                fill: "#fff",
              },
            }}
            onClick={handleShowDisplayDateString}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default DisplayDate;
