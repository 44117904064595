import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import FilterMessagesMobile from "./FilterMessagesMobile";
import DepartmentContactus from "./DepartmentContactus";
const AccordionContactus = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: 2,
        "&> *:not(:last-child)": {
          marginBottom: "16px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: 1,
          borderBottom: "1px solid #0000001F",
          paddingBottom: "16px",
        }}
      >
        <Accordion sx={{ boxShadow: "unset", width: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              color: "#000000DE",
              fontSize: 14,
              paddingRight: "unset",
              paddingLeft: 1,
              minHeight: "unset",
              margin: "unset",
              "&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "unset",
                marginBottom: "13px",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "unset",
              },
              "& .MuiAccordionSummary-content": {
                margin: "unset",
              },
            }}
          >
            <Typography>پیام ها</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "unset" }}>
            <FilterMessagesMobile />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: 1,
          paddingBottom: "16px",
        }}
      >
        <Accordion sx={{ boxShadow: "unset", width: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              color: "#000000DE",
              fontSize: 14,
              paddingRight: "unset",
              paddingLeft: 1,
              minHeight: "unset",
              margin: "unset",
              "&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "unset",
                marginBottom: "13px",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "unset",
              },
              "& .MuiAccordionSummary-content": {
                margin: "unset",
              },
            }}
          >
            <Typography>تیم بررسی</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "unset" }}>
            <DepartmentContactus />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default AccordionContactus;
