import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../baseURL";
import stores from "../../data/stores";
import toast from "react-hot-toast";
import React from "react";
import SuccessHotToast from "../../packages/icons/SuccessHotToast";
import ErrorHotToast from "../../packages/icons/ErrorHotToast";
import createBaseQueryWithReauth from "../../modules/HOC/createBaseQueryWithReauth ";
export const apiGetErrors = createApi({
  reducerPath: "errors",
  baseQuery: createBaseQueryWithReauth(baseURL),

  tagTypes: ["error"],
  endpoints: (builder) => ({
    getErrors: builder.query({
      query: (params) => {
        const searchParams = new URLSearchParams(params).toString();
        return `admin/errors/?${searchParams}`;
      },
      providesTags: ["error"],
    }),
    patchResolve: builder.mutation({
      query: (idArray) => ({
        url: "admin/errors/resolveBatch",
        method: "PATCH",
        body: idArray,
      }),

      invalidatesTags: ["error"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        try {
          toast.success(`${data.message}`, {
            style: {
              background: "#4CAF50",
              color: "#fff",
              width: "100%",
              maxWidth: "355px",
              fontSize: "13px",
            },
            duration: 4000,
            position: "top-center",
            icon: <SuccessHotToast />,
          });
        } catch (err) {
          toast.error(`مشکلی پیش آمده است!`, {
            style: {
              background: "#EF5350",
              color: "#fff",
              fontSize: "13px",
              width: "100%",
              maxWidth: "355px",
            },
            position: "top-center",
            duration: 4000,
            icon: <ErrorHotToast />,
          });
        }
      },
    }),
  }),
});

export const { useGetErrorsQuery, usePatchResolveMutation } = apiGetErrors;
