import { Box, Typography } from "@mui/material";
import React from "react";
import norequest from "../../Images/norequestnew.png";

const NoRequests = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: "text.primary", fontSize: 20 }}>
          درخواستی نداریم🤔
        </Typography>
      </Box>
      <Box
        sx={{
          width: { sm: 250, lg: 465 },
          height: { sm: 200, lg: 365 },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ width: 1, height: "100%" }}
          component="img"
          src={norequest}
          alt="ls"
        />
      </Box>
    </Box>
  );
};

export default NoRequests;
