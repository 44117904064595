import { Badge, Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import Done from "../../packages/icons/Done";
import Refresh from "../../packages/icons/Refresh";
import Cancel from "../../packages/icons/Cancel";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStatus,
  setStatusParams,
} from "../../Store/slice/sliceNewRequest";

const StatusTabs = () => {
  const { data, activeStatus } = useSelector((store) => store?.newRequest);
  const maxWidth315 = useMediaQuery("(max-width: 315px)");
  const dispatch = useDispatch();
  const handleActiveStatusButton = (param) => {
    dispatch(setActiveStatus(param));
    dispatch(setStatusParams(param));
  };
  const numberInProgressRequest = data?.[0]?.totalRequestInProgress;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", sm: "flex-start" },
      }}
    >
      <Button
        sx={[
          {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 100,
            borderBottomRightRadius: 100,
            gap: { xs: 0.5, sm: 1 },
            paddingRight: { xl: 1.83375, lg: "10px", sm: "10px", xs: "5px" },
            paddingLeft: { xl: 1.5, lg: "10px", sm: "10px", xs: "5px" },
            borderLeft: "unset",
            fontSize: { xs: 11.5, md: 14 },
            color: "text.primary",
            lineHeight: "24.18px",
            bgcolor: activeStatus === "confirmed" ? "#2196F314" : "white",
            "&:hover": {
              borderLeft: "unset",
            },
            "& .MuiButton-startIcon": {
              marginLeft: "unset",
              marginRight: "unset",
            },
          },
          maxWidth315 && {
            "& .MuiButton-startIcon": {
              display: "none",
            },
            color: "success.light",
          },
        ]}
        variant="outlined"
        startIcon={<Done />}
        onClick={() => handleActiveStatusButton("confirmed")}
      >
        تائیدشده
      </Button>
      <Button
        variant="outlined"
        sx={[
          {
            display: "flex",
            alignItems: "cetner",
            borderRadius: "unset",
            lineHeight: "24.18px",
            color: "text.primary",
            bgcolor:
              activeStatus === "inProgressFlow" ||
              activeStatus === "notChecked" ||
              activeStatus === "waiting" ||
              activeStatus === "unknown"
                ? "#2196F314"
                : "white",
            fontSize: { xs: 11.5, md: 14 },
            gap: { xs: 0.6, sm: 1 },
            width: "100%",
            flexBasis: {
              xl: "164px",
              lg: "160px",
              md: "152px",
              sm: "137px",
              xs: "132px",
            },
            paddingRight: "unset",
            paddingLeft: { xl: "12px", lg: "6px" },
            "& .MuiButton-startIcon": {
              marginLeft: "unset",
              marginRight: "unset",
            },
          },
          maxWidth315 && {
            "& .MuiButton-startIcon": {
              width: 10,
              height: 10,
              display: "flex",
              alignItems: "center",
            },

            color: "#03A9F4",
          },
        ]}
        startIcon={<Refresh />}
        onClick={() => handleActiveStatusButton("inProgressFlow")}
      >
        {maxWidth315 ? "پیگیری" : "درحال پیگیری"}
        <Badge
          badgeContent={`${
            numberInProgressRequest
              ? `${
                  numberInProgressRequest > 99
                    ? `+${numberInProgressRequest}`
                    : `${numberInProgressRequest}`
                }`
              : 0
          }`}
          sx={[
            {
              "& .MuiBadge-badge": {
                bgcolor: "info.light",
                color: "info.contrastText",
                marginRight: 1,

                fontSize: 10,
              },
            },
          ]}
        />
      </Button>
      <Button
        variant="outlined"
        sx={[
          {
            borderTopRightRadius: "unset",
            borderBottomRightRadius: "unset",
            borderRight: "unset",
            borderTopLeftRadius: 100,
            borderBottomLeftRadius: 100,
            fontSize: { xs: 11.5, md: 14 },
            lineHeight: "24.18px",
            color: "text.primary",
            paddingRight: { lg: "10px", sm: "10px", xs: "5px" },
            paddingLeft: { lg: "10px", sm: "12px", xs: "5px" },
            bgcolor: activeStatus === "canceled" ? "#2196F314" : "white",
            "&:hover": {
              borderRight: "unset",
            },
            "& .MuiButton-startIcon": {
              marginLeft: "unset",
              marginRight: "unset",
            },
            display: "flex",
            alignItems: "center",
            gap: { xs: 0.5, sm: 1 },
          },
          maxWidth315 && {
            "& .MuiButton-startIcon": {
              display: "none",
            },
            color: "error.light",
          },
        ]}
        startIcon={<Cancel />}
        onClick={() => handleActiveStatusButton("canceled")}
      >
        لغوشده
      </Button>
    </Box>
  );
};

export default StatusTabs;
