import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { useGetAllCoworkersQuery } from "../../Store/callApi/apiCoworkers";
import { useDispatch, useSelector } from "react-redux";
import { emptyRequestIds, setUserId } from "../../Store/slice/sliceNewRequest";
import { useAssignRequestToCoworkerMutation } from "../../Store/callApi/request";
const AssignRequestsMobile = () => {
  const [value, setValue] = useState("");
  const requestIDs = useSelector((state) => state?.newRequest?.checkedItems);
  const dispatch = useDispatch();
  const {
    data: coworkersData,
    isLoading,
    isSuccess,
  } = useGetAllCoworkersQuery({
    status: "accepted",
    type: "coworker",
  });
  const [assignRequestFn] = useAssignRequestToCoworkerMutation();
  const handleChange = (e) => {
    if (!requestIDs.length) return;
    setValue(e.target.value);
  };

  const handleConfirm = async (e) => {
    await assignRequestFn({ requestIDs, coworkerId: value });
    dispatch(emptyRequestIds());
    setValue("");
  };

  const handleCancel = () => setValue("");
  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        paddingRight: "30px",
        flexDirection: "column",
      }}
    >
      <FormControl sx={{ width: 1 }}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          sx={{
            justifyContent: "space-evenly",
            "& label span:last-child": { fontSize: 14 },
          }}
        >
          {isSuccess &&
            coworkersData?.records.map((coworker) => (
              <FormControlLabel
                key={coworker._id}
                sx={{ marginRight: "unset" }}
                value={coworker._id}
                control={<Radio />}
                label={coworker.name}
              />
            ))}
        </RadioGroup>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: 1,
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleCancel}
          disabled={!value}
          color="error"
          variant="outlined"
        >
          لغو
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={!value || isLoading}
          color="primary"
          variant="outlined"
        >
          تائید
        </Button>
      </Box>
    </Box>
  );
};

export default AssignRequestsMobile;
