import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import FeaturesAffadative from "../../../../features/FeaturesAffadative";
import style from "../contracts.module.css";

const DialogManagement = (props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const { DialogAffidavit, saveInfoOfContract, AffidavitChecked } = props;

  let handleClose = () => {
    DialogAffidavit(false);
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <FeaturesAffadative
          AffidavitChecked={AffidavitChecked}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
        <div className={style.BtnDialogAffidavit}>
          <Button
            variant="contained"
            size="small"
            disabled={selectedValue ? false : true}
            style={{ margin: 5 }}
            fullWidth
            type="submit"
            color={"primary"}
            onClick={() => {
              saveInfoOfContract();
              handleClose();
            }}
          >
            تایید
          </Button>
          <Button
            variant="outlined"
            size="small"
            style={{ margin: 5 }}
            fullWidth
            onClick={() => handleClose()}
            color={"secondary"}
          >
            بازگشت
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogManagement;