import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Main from "../../common/UserMain";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Button from "@material-ui/core/Button";
import SnackBar from "../../common/SnackBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import AlertDialog from "../../common/AlertDialog";
import { convertNumberToEN } from "../../../language/NumberConverter";
import { goBackUseHistory } from "../../AppHistory";
import MultiSelectForm from "./MultiSelectForm";
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import {
  priceTypesOptions,
  ownerTypesOptions,
  fuelTypesOptions,
  gearboxTypesOptions,
  insuranceOptionsTypes,
  insuranceDiscountTypes,
  bodyColorTypes,
  colorInsideTypes,
  SelectFormCars,
} from "../../../values/AddEditCarValues";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from "@material-ui/icons/Add";
import RequestState from "../../common/RequestState";
import CreateCar from "./CreateCar";
import EditorGallery from "../../common/EditorGallery";
import AddDefectDialog from "./AddDefectDialog";
import AddEditReviewsDialog from "./AddEditReviewsDialog";
import AddMoreDialog from "./AddMoreDialog";
import PriceFormater from "../../../utils/priceFormater";
import { SelectForm, SwitchForm } from "../../common/Form/index";
import "../../../styles/add-edit-car-styles.css";
let apiClient = null;
var moment = require("moment-jalaali");

@inject("AccountStore")
@inject("AgentsStore")
@inject("CarsStore")
@observer
class AddEditCar extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (!props.AccountStore.isLogin()) this.props.history.replace("/");

    this.state = {
      id: props.match.params.id,
      sendRequest: false,
      sendRequestErr: false,
      submitRequest: false,
      sendRequestErrMessage: "",
      inputWarn: 0,
      warnInputMessage: "",
      success: false,
      getAgentsErr: false,
      message: "",
      positivePoint: "",
      showCreateCarDialog: false,
      showGalleryAsDialog: false,
      carInfo: props.CarsStore.getCurrentCar(),
      showAddDefectDialog: false,
      showAddEditReviewsDialog: false,
      showAddMoreDialog: false,
      auctionTime: "",
      expanded: false,
      renderedFirstTime: false,
      sendSmsToSeller: false,
      alternativeSellerPhoneNumber: "",
      carToPublicTransition: false,
      depoDateTime: null,
      hasEditAccess: true,
      hasAccessEdit: true,
      agentManagerDescription: "",
      sellerAnnouncedPrice: "",
    };
  }

  // autoUpdatePer5sec() {
  //   let data = JSON.parse(JSON.stringify(this.state.carInfo));
  //   data.auctionTime = this.state.auctionTime;
  //   data.sendSmsToSeller = this.state.sendSmsToSeller;
  //   data.userReceptionCodeSearch = this.state.carInfo.code;
  //   data.alternativeSellerPhoneNumber = this.state.alternativeSellerPhoneNumber;
  //   data.agentManagerDescription = this.state.agentManagerDescription;
  //   data.sellerAnnouncedPrice = this.state.sellerAnnouncedPrice;
  //   apiClient.post(
  //     ApiHelper.addEditCar_POST,
  //     (error, response) => {
  //       if (error) {
  //         console.log(error);
  //       } else {
  //         console.log( response);
  //       }
  //     },
  //     data
  //   );
  // }

  componentDidMount() {
    this.getCarInfo();
    // if (this.props.AccountStore.type !== "expert") this.getAgents();
    // setInterval(() => {
    //   this.autoUpdatePer5sec();
    // }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getCarInfo() {
    if (this.state.sendRequest) {
      return;
    }
    this.setState({ sendRequest: true, sendRequestErr: false });
    apiClient.get(
      ApiHelper.getCarInfoById_GET(this.state.id),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          this.props.CarsStore.setCurrentCar(response.data);
          if (response.data.depoDateTime != null) {
            this.setState({ carToPublicTransition: true });
          }
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            carInfo: response.data,
            depoDateTime: response.data.depoDateTime,
            hasEditAccess: response.hasEditAccess,
            hasAccessEdit: response.data.hasAccessEdit,
            agentManagerDescription: response.data.agentManagerDescription,
            sellerAnnouncedPrice: response.data.sellerAnnouncedPrice,
          });
        }
      }
    );
  }

  initiateDefaultValuesForCar() {
    this.setState({
      carInfo: {
        ...this.state.carInfo,
        details: { ...this.state.carInfo.details, fuelType: "بنزین" },
      },
    });
  }

  getAgents(ref = false) {
    if (!ref && this.props.AgentsStore.agents > 0) return;

    if (this.props.AccountStore.type === "agent") {
      if (this.props.AccountStore.profile._id) {
        this.props.AgentsStore.setAgents([this.props.AccountStore.profile]);
        this.setState({ getAgentsErr: false });
        return;
      } else {
        this.props.AgentsStore.setAgents([]);
        this.setState({ getAgentsErr: false });
        apiClient.get(ApiHelper.getProfileInfo_GET, (error, response) => {
          if (error) {
            this.setState({
              getAgentsErr: true,
              message: response ? response.message : "",
            });
          } else {
            this.props.AccountStore.profile = response.data;
            this.props.AgentsStore.setAgents([this.props.AccountStore.profile]);
            this.setState({ getAgentsErr: false });
          }
        });
      }
    }

    this.setState({ getAgentsErr: false });

    apiClient.get(ApiHelper.getAgents_GET("accepted"), (error, response) => {
      if (error) {
        this.setState({
          getAgentsErr: this.props.AgentsStore.agents.length <= 0 && !ref,
        });
      } else {
        this.props.AgentsStore.setAgents(response.records);
        this.setState({ getAgentsErr: false });
      }
    });
  }

  submit() {
    if (this.state.submitRequest) return;

    if (
      this.state.carInfo.details.colorInside.toString().trim() === "" ||
      this.state.carInfo.details.bodyColor.toString().trim() === "" ||
      this.state.carInfo.details.insurance.toString().trim() === "" ||
      this.state.carInfo.details.fuelType.toString().trim() === "" ||
      this.state.carInfo.details.gearboxType.toString().trim() === ""
    ) {
      this.setState({ message: "جزئیات خودرو را وارد کنید" });
      return;
    }

    if (this.state.carToPublicTransition && this.state.depoDateTime === null) {
      this.setState({ message: "روز انتقال خودرو را وارد کنید" });
      return;
    }
    let data = JSON.parse(JSON.stringify(this.state.carInfo));
    data.auctionTime = this.state.auctionTime;
    data.sendSmsToSeller = this.state.sendSmsToSeller;
    data.userReceptionCodeSearch = this.state.carInfo.code;
    data.alternativeSellerPhoneNumber = this.state.alternativeSellerPhoneNumber;
    data.agentManagerDescription = this.state.agentManagerDescription;
    data.sellerAnnouncedPrice = this.state.sellerAnnouncedPrice;
    if (this.state.carToPublicTransition) {
      data.depoDateTime = this.state.depoDateTime;
    } else {
      data.depoDateTime = null;
    }
    this.setState({ submitRequest: true });

    apiClient.post(
      ApiHelper.addEditCar_POST,
      (error, response) => {
        this.setState({ submitRequest: false });
        if (error) {
          this.setState({
            submitRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ submitRequest: false, success: true });
        }
      },
      data
    );
  }

  addPositivePoint() {
    if (this.state.positivePoint.toString().replace(/ /g, "") === "") {
      this.setState({ message: "نکته مثبت را وارد کنید" });
      return;
    }
    this.state.carInfo.positivePoints.push(this.state.positivePoint);
    this.positivePoint.value = "";
    this.setState({ positivePoint: "" });
  }

  getP_NumberAsString(key) {
    let obj = {};
    try {
      obj = JSON.parse(this.state.carInfo.p_number);
      if (key) {
        return obj[key] ? obj[key] : "";
      }
      return obj;
    } catch (e) {
      if (key) {
        return "";
      }
      return obj;
    }
  }

  setP_NumberAsObj(key, value) {
    let obj = this.getP_NumberAsString();
    obj[key] = value;
    this.state.carInfo.p_number = JSON.stringify(obj);
  }
  handleAccordionChange(value) {
    this.setState({ expanded: value });
  }
  handleCarTransitionDate(hours) {
    let currentDate = new Date();
    // currentDate.setDate(currentDate.getDate() + Math.floor(days)); this is for adding days
    currentDate.setTime(currentDate.getTime() + hours * 60 * 60 * 1000);
    this.setState({ depoDateTime: currentDate });
  }

  restrictionChecking() {
    if (!this.state.hasEditAccess) {
      this.setState({
        sendRequestErrMessage: "ماشین تو مزایدس نمیتونی ادیت کنی جیگر!!!!",
      });
      return;
    }
  }
  handlerSetValue(setField, value) {
    this.setState({
      carInfo: {
        ...this.state.carInfo,
        details: {
          ...this.state.carInfo.details,
          [setField]: value,
        },
      },
    });
  }
  render() {
    return (
      <Main
        onBackPress={() => goBackUseHistory(this.props.history)}
        page={"cars"}
        pageName={"خودرو"}
        context={this.props.history}
      >
        <Paper style={{ padding: 15, margin: 0 }}>
          {this.state.sendRequestErr || this.state.sendRequest ? (
            <RequestState
              error={this.state.sendRequestErr}
              retryOnPress={() => this.getCarInfo()}
              title={"در حال دریافت اطلاعات ..."}
              request={this.state.sendRequest}
            />
          ) : (
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <p style={{ margin: "10px 5px" }}>
                  کد خودرو : {this.state.carInfo.code}
                </p>
                <Divider />
              </Grid>
              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.props.CarsStore.setCurrentCar(this.state.carInfo);
                    this.setState({ showCreateCarDialog: true });
                  }}
                  color="primary"
                  style={{ margin: "10px 0" }}
                  fullWidth
                >
                  {this.state.carInfo.brand} /{this.state.carInfo.model} /
                  {this.state.carInfo.year} /{this.state.carInfo.option}
                </Button>
              </Grid>

              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) => (this.state.carInfo.km = t.target.value)}
                  defaultValue={this.state.carInfo.km}
                  label={"کیلومتر"}
                  placeholder={"کیلومتر"}
                  fullWidth={true}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                    maxLength: 20,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? convertNumberToEN(e.target.value)
                          .toString()
                          .replace(/[^0-9]/g, "")
                          .toString()
                      : "";
                  }}
                  type="tel"
                />
              </Grid>

              {this.props.AccountStore.type !== "expert" && (
                <Grid item sm={6} xs={12} style={classes.gridInput}>
                  <FormControl label="نوع" fullWidth>
                    <Select
                      disabled={!this.state.hasAccessEdit}
                      defaultValue={
                        !this.state.carInfo.type ? "0" : this.state.carInfo.type
                      }
                      variant="outlined"
                      margin={"dense"}
                      style={{ fontSize: 13, marginTop: 10 }}
                      onChange={(e) =>
                        (this.state.carInfo.type = e.target.value)
                      }
                    >
                      <MenuItem style={{ fontSize: 13 }} value={"0"}>
                        انتخاب نوع آگهی
                      </MenuItem>

                      {[
                        { k: "in_queue", v: "کارشناسی" },
                        { k: "live", v: "مزایده زنده" },
                        { k: "depot", v: "دپو" },
                        { k: "public", v: "عمومی" },
                      ].map((l, i) => (
                        <MenuItem key={i} style={{ fontSize: 13 }} value={l.k}>
                          {l.v}
                        </MenuItem>
                      ))}

                      <MenuItem
                        disabled={true}
                        style={{ fontSize: 13 }}
                        value={"negotiating"}
                      >
                        درحال مذاکره
                      </MenuItem>
                      <MenuItem
                        disabled={true}
                        style={{ fontSize: 13 }}
                        value={"awaiting_payment"}
                      >
                        تسویه نشده
                      </MenuItem>
                      <MenuItem
                        disabled={true}
                        style={{ fontSize: 13 }}
                        value={"payment_done"}
                      >
                        پرداخت شده
                      </MenuItem>
                      <MenuItem
                        disabled={true}
                        style={{ fontSize: 13 }}
                        value={"ended"}
                      >
                        تمام شده
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) =>
                    (this.state.carInfo.basePrice = t.target.value
                      .toString()
                      .replace(/,/g, ""))
                  }
                  defaultValue={PriceFormater(this.state.carInfo.basePrice)}
                  label="پایه قیمت (تومان)"
                  fullWidth={true}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      direction: "ltr",
                    },
                    maxLength: 22,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? PriceFormater(convertNumberToEN(e.target.value))
                      : "";
                  }}
                  type="tel"
                />

                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) =>
                    (this.state.carInfo.auctionPrice = t.target.value
                      .toString()
                      .replace(/,/g, ""))
                  }
                  defaultValue={PriceFormater(this.state.carInfo.auctionPrice)}
                  label="پایه قیمت مزایده (تومان)"
                  fullWidth={true}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      direction: "ltr",
                    },
                    maxLength: 22,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? PriceFormater(convertNumberToEN(e.target.value))
                      : "";
                  }}
                  type="tel"
                />

                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) =>
                    (this.state.carInfo.fairPrice = t.target.value
                      .toString()
                      .replace(/,/g, ""))
                  }
                  defaultValue={PriceFormater(this.state.carInfo.fairPrice)}
                  label="ارزش منصفانه (تومان)"
                  fullWidth={true}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      direction: "ltr",
                    },
                    maxLength: 22,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? PriceFormater(convertNumberToEN(e.target.value))
                      : "";
                  }}
                  type="tel"
                />

                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) =>
                    (this.state.carInfo.stepPrice = t.target.value
                      .toString()
                      .replace(/,/g, ""))
                  }
                  defaultValue={PriceFormater(this.state.carInfo.stepPrice)}
                  label="قیمت پله مزایده (تومان)"
                  fullWidth={true}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      direction: "ltr",
                    },
                    maxLength: 22,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? PriceFormater(convertNumberToEN(e.target.value))
                      : "";
                  }}
                  type="tel"
                />
              </Grid>

              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <SelectForm
                  isDisabled={!this.state.hasAccessEdit}
                  value={
                    !this.state.carInfo.typePrice
                      ? "0"
                      : this.state.carInfo.typePrice
                  }
                  label="انتخاب نوع قیمت"
                  options={priceTypesOptions}
                  onchange={(value) =>
                    this.setState({
                      carInfo: { ...this.state.carInfo, typePrice: value },
                    })
                  }
                />
              </Grid>

              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) => (this.state.auctionTime = t.target.value)}
                  defaultValue={this.state.auctionTime}
                  label={"زمان مزایده (دقیقه)"}
                  placeholder={"زمان مزایده (دقیقه)"}
                  fullWidth={true}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                    maxLength: 10,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? convertNumberToEN(e.target.value)
                          .toString()
                          .replace(/[^0-9]/g, "")
                          .toString()
                      : "";
                  }}
                  type="tel"
                />
              </Grid>

              {this.props.AccountStore.type !== "expert" && (
                <Grid item sm={6} xs={12} style={classes.gridInput}>
                  {this.state.getAgentsErr ? (
                    <Button
                      onClick={() => this.getAgents(true)}
                      color="secondary"
                      variant="outlined"
                      size="small"
                    >
                      خطا در دریافت نمایندگان !
                    </Button>
                  ) : (
                    <FormControl
                      label="نمایندگان"
                      style={{ width: "calc(100% - 50px)" }}
                    >
                      <Select
                        disabled={!this.state.hasAccessEdit}
                        defaultValue={
                          !this.state.carInfo.agentId
                            ? "0"
                            : this.state.carInfo.agentId
                        }
                        variant="outlined"
                        margin={"dense"}
                        style={{ fontSize: 13, marginTop: 10 }}
                        onChange={(e) => {
                          if (e.target.value === "0") {
                            this.state.carInfo.agentId = null;
                            this.state.carInfo.expertInfo = null;
                            this.state.carInfo.address = "";
                            this.state.carInfo.agentInfo = {};
                          } else {
                            this.state.carInfo.agentId = e.target.value;
                            this.state.carInfo.agentInfo =
                              this.props.AgentsStore.agents.find(
                                (t) => t._id === e.target.value
                              );
                            this.state.carInfo.address =
                              this.state.carInfo.agentInfo.address;
                          }
                          this.setState({ ref: true });
                        }}
                      >
                        <MenuItem style={{ fontSize: 13 }} value={"0"}>
                          {this.props.AgentsStore.agents.length < 0 ? (
                            <CircularProgress size={16} style={{ margin: 1 }} />
                          ) : (
                            "انتخاب نماینده"
                          )}
                        </MenuItem>

                        {this.props.AgentsStore.agents.map((l, i) => (
                          <MenuItem
                            key={l._id}
                            style={{ fontSize: 13 }}
                            value={l._id}
                          >
                            {l.username}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <IconButton onClick={() => this.getAgents(true)}>
                    <Icon>sync</Icon>
                  </IconButton>
                </Grid>
              )}

              {this.state.carInfo.agentId && (
                <Grid item sm={6} xs={12} style={classes.gridInput}>
                  <FormControl label="پاسخگو" fullWidth>
                    <Select
                      defaultValue={
                        !this.state.carInfo.expertInfo
                          ? "0"
                          : this.state.carInfo.expertInfo._id
                      }
                      variant="outlined"
                      margin={"dense"}
                      style={{ fontSize: 13, marginTop: 10 }}
                      onChange={(e) => {
                        if (e.target.value === "0") {
                          this.state.carInfo.expertInfo = null;
                        } else {
                          this.state.carInfo.expertInfo =
                            this.state.carInfo.agentInfo.experts.find(
                              (t) => t._id === e.target.value
                            );
                        }
                        this.setState({ ref: true });
                      }}
                    >
                      <MenuItem style={{ fontSize: 13 }} value={"0"}>
                        انتخاب پاسخگو
                      </MenuItem>

                      {this.state.carInfo.agentInfo.experts.map((l, i) => (
                        <MenuItem
                          key={l._id}
                          style={{ fontSize: 13 }}
                          value={l._id}
                        >
                          {l.name} / {l.phone}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {this.state.carInfo.agentId && (
                <Grid item sm={6} xs={12} style={classes.gridInput}>
                  <TextField
                    onChange={(t) =>
                      (this.state.carInfo.address = t.target.value)
                    }
                    defaultValue={this.state.carInfo.address}
                    label="آدرس"
                    inputProps={{ maxLength: 200 }}
                    fullWidth={true}
                    variant="outlined"
                    margin={"dense"}
                  />
                </Grid>
              )}
              <Grid
                item
                sm={6}
                xs={12}
                style={{ ...classes.gridInput, width: "100% !important" }}
              >
                <TextField
                  onChange={(t) =>
                    (this.state.agentManagerDescription = t.target.value)
                  }
                  defaultValue={this.state.agentManagerDescription}
                  label="توضیحات مدیر شعبه"
                  multiline={true}
                  rows={4}
                  style={{ width: "100%" }}
                  variant="outlined"
                  margin={"dense"}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                style={{ ...classes.gridInput, width: "100% !important" }}
              >
                <TextField
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? PriceFormater(convertNumberToEN(e.target.value))
                      : "";
                  }}
                  onChange={(t) =>
                    (this.state.sellerAnnouncedPrice = t.target.value)
                  }
                  defaultValue={this.state.sellerAnnouncedPrice}
                  label="قیمت اعلامی مالک (تومان)"
                  multiline={true}
                  style={{ width: "100%" }}
                  variant="outlined"
                  margin={"dense"}
                />
              </Grid>
              <Grid sm={12} xs={12}>
                <br />
                <Divider />
                <p>مدارک</p>
              </Grid>
              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) =>
                    (this.state.carInfo.c_number = t.target.value)
                  }
                  defaultValue={this.state.carInfo.c_number}
                  label="شماره شاسی"
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                    maxLength: 200,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? convertNumberToEN(e.target.value)
                      : "";
                  }}
                  fullWidth={true}
                  variant="outlined"
                  margin={"dense"}
                />
              </Grid>

              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) =>
                    (this.state.carInfo.e_number = t.target.value)
                  }
                  defaultValue={this.state.carInfo.e_number}
                  label="شماره موتور"
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                    maxLength: 200,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? convertNumberToEN(e.target.value)
                      : "";
                  }}
                  fullWidth={true}
                  variant="outlined"
                  margin={"dense"}
                />
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
                style={{ ...classes.gridInput, display: "flex" }}
              >
                <p> پلاک : </p>
                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) => this.setP_NumberAsObj("c", t.target.value)}
                  defaultValue={this.getP_NumberAsString("c")}
                  label="ایران"
                  placeholder={"**"}
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                    maxLength: 2,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? convertNumberToEN(e.target.value)
                      : "";
                  }}
                  style={{ width: 50 }}
                  variant="outlined"
                  margin={"dense"}
                />
                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) => this.setP_NumberAsObj("d3", t.target.value)}
                  defaultValue={this.getP_NumberAsString("d3")}
                  label="ب۲"
                  placeholder={"***"}
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                    maxLength: 3,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? convertNumberToEN(e.target.value)
                      : "";
                  }}
                  style={{ width: 60 }}
                  variant="outlined"
                  margin={"dense"}
                />
                <FormControl label="حرف" style={{ width: 65 }}>
                  <Select
                    disabled={!this.state.hasAccessEdit}
                    defaultValue={this.getP_NumberAsString("al")}
                    variant="outlined"
                    label="حرف"
                    margin={"dense"}
                    style={{ fontSize: 13, marginTop: 10 }}
                    onChange={(e) =>
                      this.setP_NumberAsObj("al", e.target.value)
                    }
                  >
                    {[
                      "الف",
                      "ب",
                      "ج",
                      "د",
                      "ص",
                      "س",
                      "ط",
                      "ق",
                      "م",
                      "ل",
                      "ن",
                      "و",
                      "ه",
                      "ی",
                      "ت",
                      "ژ",
                      "ع",
                      "گ",
                    ].map((l, i) => (
                      <MenuItem key={i} style={{ fontSize: 13 }} value={l}>
                        {l}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) => this.setP_NumberAsObj("d2", t.target.value)}
                  defaultValue={this.getP_NumberAsString("d2")}
                  label="ب۱"
                  placeholder={"**"}
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                    maxLength: 2,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? convertNumberToEN(e.target.value)
                      : "";
                  }}
                  style={{ width: 50 }}
                  variant="outlined"
                  margin={"dense"}
                />
              </Grid>

              {this.state.carInfo._id && (
                <EditorGallery
                  addImageToEditor={(src) => {}}
                  closeGalleryDialog={() => {}}
                  title={"تصاویر مدارک"}
                  showGalleryDialog={false}
                  images={[]}
                  justShow={true}
                  storeKey="docsGallery"
                  onChangeImages={(images) => {}}
                  getGalleryURL={ApiHelper.get_car_gallery_GET(
                    this.state.carInfo.code,
                    true
                  )}
                  uploadImageURL={ApiHelper.uploadCarImage_POST(
                    this.state.carInfo._id,
                    true
                  )}
                  removeImageURL={ApiHelper.removeCarImage_GET(
                    this.state.carInfo._id,
                    true
                  )}
                  hasChangeAccess={this.state.hasEditAccess}
                  accessError={() => this.restrictionChecking()}
                />
              )}

              <br />

              <Grid item sm={12} xs={12}>
                <p>افزودن نکات مثبت</p>
                <Grid item sm={6} xs={12} style={classes.gridInput}>
                  <TextField
                    disabled={!this.state.hasAccessEdit}
                    inputRef={(r) => (this.positivePoint = r)}
                    onChange={(t) =>
                      (this.state.positivePoint = t.target.value)
                    }
                    defaultValue={this.state.positivePoint}
                    label="نکات مثبت"
                    inputProps={{ maxLength: 50 }}
                    style={{ flex: 1 }}
                    variant="outlined"
                    margin={"dense"}
                  />
                  <Button
                    disabled={!this.state.hasAccessEdit}
                    variant="outlined"
                    color="primary"
                    style={{ margin: "10px 5px" }}
                    onClick={() => this.addPositivePoint()}
                  >
                    ایجاد
                  </Button>
                </Grid>
                {this.state.carInfo.positivePoints.map((p, i) => (
                  <Chip
                    key={i}
                    size="medium"
                    color="secondary"
                    style={{
                      margin: "15px 0 10px 10px",
                      height: "auto",
                      alignSelf: "flex-start",
                    }}
                    label={
                      <p
                        style={{
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          margin: "5px 0",
                        }}
                      >
                        {p}
                      </p>
                    }
                    onDelete={() => {
                      this.state.carInfo.positivePoints.splice(i, 1);
                      this.setState({ ref: true });
                    }}
                  />
                ))}
              </Grid>

              <Grid item sm={12} xs={12}>
                <Divider />
                <p>ویژگی ها</p>
                <Grid item sm={6} xs={12}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        disabled={!this.state.hasAccessEdit}
                        checked={
                          this.state.carInfo.attributes.indexOf("guaranteed") >=
                          0
                        }
                        onChange={(e) => {
                          let iA =
                            this.state.carInfo.attributes.indexOf("guaranteed");
                          if (iA >= 0) {
                            this.state.carInfo.attributes.splice(iA, 1);
                          } else {
                            this.state.carInfo.attributes.push("guaranteed");
                          }
                          this.setState({ ref: true });
                        }}
                        control={<Checkbox color="primary" />}
                        label="گارانتی ۷ روزه سلامت"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>

                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        disabled={!this.state.hasAccessEdit}
                        checked={
                          this.state.carInfo.attributes.indexOf(
                            "immediateDelivery"
                          ) >= 0
                        }
                        onChange={(e) => {
                          let iA =
                            this.state.carInfo.attributes.indexOf(
                              "immediateDelivery"
                            );
                          if (iA >= 0) {
                            this.state.carInfo.attributes.splice(iA, 1);
                          } else {
                            this.state.carInfo.attributes.push(
                              "immediateDelivery"
                            );
                          }
                          this.setState({ ref: true });
                        }}
                        control={<Checkbox color="primary" />}
                        label="تحویل آنی"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                  <br />
                  <FormControl component="fieldset">
                    <FormGroup
                      aria-label="position"
                      row
                      style={{ margin: "10px 15px 10px 15px" }}
                    >
                      <TextField
                        onChange={(t) =>
                          (this.state.carInfo.discountAmount = t.target.value
                            .toString()
                            .replace(/,/g, ""))
                        }
                        defaultValue={this.state.carInfo.discountAmount
                          .toString()
                          .replace(/[^0-9]/g, "")
                          .toString()
                          .split("")
                          .reverse()
                          .join("")
                          .split(/(...)/)
                          .reverse()
                          .join(",")
                          .replace(/,(?=,)|,$|^,/g, "")
                          .replace(/(,|^)(\d)(\d)?(\d)?/g, "$1$4$3$2")}
                        label="میزان تخفیف (تومان)"
                        placeholder="میزان تخفیف (تومان)"
                        fullWidth={true}
                        inputProps={{
                          style: {
                            fontSize: 14,
                            direction: "ltr",
                          },
                          maxLength: 22,
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            ? convertNumberToEN(e.target.value)
                                .toString()
                                .replace(/[^0-9]/g, "")
                                .toString()
                                .split("")
                                .reverse()
                                .join("")
                                .split(/(...)/)
                                .reverse()
                                .join(",")
                                .replace(/,(?=,)|,$|^,/g, "")
                                .replace(/(,|^)(\d)(\d)?(\d)?/g, "$1$4$3$2")
                            : "";
                        }}
                        type="tel"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Divider />
                <p>جزئیات</p>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    spacing={2}
                  >
                    <Grid
                      item
                      lg={3}
                      sm={6}
                      xs={4}
                      style={classes.gridInputSmall}
                    >
                      <p style={ordinaryLabel}>گارانتی شرکت</p>
                      <SwitchForm
                        isDisabled={!this.state.hasAccessEdit}
                        checked={
                          this.state.carInfo.details.companyGuarantee === "دارد"
                            ? true
                            : false
                        }
                        handleChange={(value) =>
                          this.setState({
                            carInfo: {
                              ...this.state.carInfo,
                              details: {
                                ...this.state.carInfo.details,
                                companyGuarantee:
                                  this.state.carInfo.details
                                    .companyGuarantee === "دارد"
                                    ? "ندارد"
                                    : "دارد",
                              },
                            },
                          })
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      sm={6}
                      xs={4}
                      style={classes.gridInputSmall}
                    >
                      <p style={ordinaryLabel}>بیمه بدنه</p>
                      <SwitchForm
                        isDisabled={!this.state.hasAccessEdit}
                        checked={
                          this.state.carInfo.details.comprehensiveInsurance ===
                          "دارد"
                            ? true
                            : false
                        }
                        handleChange={(value) =>
                          this.setState({
                            carInfo: {
                              ...this.state.carInfo,
                              details: {
                                ...this.state.carInfo.details,
                                comprehensiveInsurance:
                                  this.state.carInfo.details
                                    .comprehensiveInsurance === "دارد"
                                    ? "ندارد"
                                    : "دارد",
                              },
                            },
                          })
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      sm={6}
                      xs={4}
                      style={classes.gridInputSmall}
                    >
                      <p style={ordinaryLabel}>کارت سوخت</p>
                      <SwitchForm
                        isDisabled={!this.state.hasAccessEdit}
                        checked={
                          this.state.carInfo.details.fuelCard === "دارد"
                            ? true
                            : false
                        }
                        handleChange={(value) =>
                          this.setState({
                            carInfo: {
                              ...this.state.carInfo,
                              details: {
                                ...this.state.carInfo.details,
                                fuelCard:
                                  this.state.carInfo.details.fuelCard === "دارد"
                                    ? "ندارد"
                                    : "دارد",
                              },
                            },
                          })
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      sm={6}
                      xs={4}
                      style={classes.gridInputSmall}
                    >
                      <p style={ordinaryLabel}>کلید زاپاس</p>
                      <SwitchForm
                        isDisabled={!this.state.hasAccessEdit}
                        checked={
                          this.state.carInfo.details.spareKey === "دارد"
                            ? true
                            : false
                        }
                        handleChange={(value) =>
                          this.setState({
                            carInfo: {
                              ...this.state.carInfo,
                              details: {
                                ...this.state.carInfo.details,
                                spareKey:
                                  this.state.carInfo.details.spareKey === "دارد"
                                    ? "ندارد"
                                    : "دارد",
                              },
                            },
                          })
                        }
                      />
                    </Grid>
                  </Grid>

                  {SelectFormCars.map((value) => (
                    <Grid item lg={4} sm={6} xs={12} style={classes.gridInput}>
                      <MultiSelectForm
                        carInfo={this.state.carInfo}
                        optionName={value.optionName}
                        valueKey={value.key}
                        titleSelect={value.titleSelect}
                        hasAccessEdit={!this.state.hasAccessEdit}
                        handlerValue={this.handlerSetValue.bind(this)}
                      />
                    </Grid>
                  ))}
                </Grid>

                {this.state.carInfo.details.more &&
                  this.state.carInfo.details.more.map((v, i) => (
                    <TextField
                      key={"more_" + i}
                      onChange={(t) =>
                        (this.state.carInfo.details.more[i].value =
                          t.target.value)
                      }
                      defaultValue={this.state.carInfo.details.more[i].value}
                      label={this.state.carInfo.details.more[i].title}
                      style={{ marginLeft: 5, minWidth: 200, maxWidth: 300 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              this.props.CarsStore.currentCar.details.more.splice(
                                i,
                                1
                              );
                              this.state.carInfo.details.more.splice(i, 1);
                              this.setState({ ref: true });
                            }}
                          >
                            <Icon>highlight_off</Icon>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{ maxLength: 200 }}
                      variant="outlined"
                      margin={"dense"}
                    />
                  ))}

                <Button
                  disabled={!this.state.hasAccessEdit}
                  variant="outlined"
                  color="primary"
                  style={{ margin: "10px 5px" }}
                  onClick={() => this.setState({ showAddMoreDialog: true })}
                >
                  ایجاد گزینه بیشتر
                </Button>

                <br />
                <TextField
                  disabled={!this.state.hasAccessEdit}
                  onChange={(t) =>
                    (this.state.carInfo.details.options = t.target.value)
                  }
                  defaultValue={this.state.carInfo.details.options}
                  label="توضیحات"
                  multiline={true}
                  rows={4}
                  style={{ marginLeft: 5, minWidth: 200, maxWidth: 300 }}
                  inputProps={{ maxLength: 1000 }}
                  variant="outlined"
                  margin={"dense"}
                />
              </Grid>

              {this.state.carInfo._id && (
                <EditorGallery
                  addImageToEditor={(src) => {}}
                  closeGalleryDialog={() =>
                    this.setState({ showGalleryAsDialog: false })
                  }
                  showGalleryDialog={this.state.showGalleryAsDialog}
                  images={this.state.carInfo.images}
                  onChangeImages={(images) => {
                    this.state.carInfo.images = images;
                    this.setState({ ref: true });
                  }}
                  storeKey="currentGallery"
                  getGalleryURL={ApiHelper.get_car_gallery_GET(
                    this.state.carInfo.code
                  )}
                  uploadImageURL={ApiHelper.uploadCarImage_POST(
                    this.state.carInfo._id
                  )}
                  removeImageURL={ApiHelper.removeCarImage_GET(
                    this.state.carInfo._id
                  )}
                  hasChangeAccess={this.state.hasEditAccess}
                  accessError={() => this.restrictionChecking()}
                />
              )}

              <Grid item sm={12} xs={12}>
                <Button
                  disabled={!this.state.hasAccessEdit}
                  variant="outlined"
                  color="primary"
                  style={{ margin: "20px 10px" }}
                  onClick={() => {
                    this.props.CarsStore.setCurrentCar(this.state.carInfo);
                    this.setState({ showAddDefectDialog: true });
                  }}
                >
                  افزودن نواقص اصلی
                </Button>
                <FormControl
                  component="fieldset"
                  style={{ margin: "20px 10px" }}
                >
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      disabled={!this.state.hasAccessEdit}
                      checked={
                        this.state.carInfo.attributes.indexOf("hasColor") >= 0
                      }
                      onChange={(e) => {
                        let iA =
                          this.state.carInfo.attributes.indexOf("hasColor");
                        if (iA >= 0) {
                          this.state.carInfo.attributes.splice(iA, 1);
                        } else {
                          this.state.carInfo.attributes.push("hasColor");
                        }
                        this.setState({ ref: true });
                      }}
                      control={<Checkbox color="primary" />}
                      label="خودرو رنگ شدگی دارد"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>

                {this.state.carInfo.specifications.defects.bodyAndTires.length >
                  0 && (
                  <div
                    style={{
                      borderRadius: 5,
                      border: "1px solid #d2d2d2",
                      margin: 7,
                      padding: 7,
                    }}
                  >
                    <p style={{ margin: 10, fontSize: 13 }}>بدنه + لاستیک</p>
                    {this.state.carInfo.specifications.defects.bodyAndTires.map(
                      (p, i) => (
                        <Chip
                          key={i}
                          onClick={() => {
                            this.setState({
                              showAddDefectDialog: {
                                ...p,
                                parent: "bodyAndTires",
                                index: i,
                              },
                            });
                          }}
                          size="medium"
                          color="secondary"
                          style={{
                            margin: "15px 0 10px 10px",
                            height: "auto",
                            alignSelf: "flex-start",
                          }}
                          label={
                            <p
                              style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                                margin: "5px 0",
                              }}
                            >
                              {p.type + " / " + p.title + " / " + p.value}
                            </p>
                          }
                          onDelete={() => {
                            this.state.carInfo.specifications.defects.bodyAndTires.splice(
                              i,
                              1
                            );
                            this.setState({ ref: true });
                          }}
                        />
                      )
                    )}
                  </div>
                )}

                {this.state.carInfo.specifications.defects.motor.length > 0 && (
                  <div
                    style={{
                      borderRadius: 5,
                      border: "1px solid #d2d2d2",
                      margin: 7,
                      padding: 7,
                    }}
                  >
                    <p style={{ margin: 10, fontSize: 13 }}>موتور</p>
                    {this.state.carInfo.specifications.defects.motor.map(
                      (p, i) => (
                        <Chip
                          key={i}
                          onClick={() =>
                            this.setState({
                              showAddDefectDialog: {
                                ...p,
                                parent: "motor",
                                index: i,
                              },
                            })
                          }
                          size="medium"
                          color="secondary"
                          style={{
                            margin: "15px 0 10px 10px",
                            height: "auto",
                            alignSelf: "flex-start",
                          }}
                          label={
                            <p
                              style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                                margin: "5px 0",
                              }}
                            >
                              {p.title + " / " + p.value}
                            </p>
                          }
                          onDelete={() => {
                            this.state.carInfo.specifications.defects.motor.splice(
                              i,
                              1
                            );
                            this.setState({ ref: true });
                          }}
                        />
                      )
                    )}
                  </div>
                )}

                {this.state.carInfo.specifications.defects.electricAndInternal
                  .length > 0 && (
                  <div
                    style={{
                      borderRadius: 5,
                      border: "1px solid #d2d2d2",
                      margin: 7,
                      padding: 7,
                    }}
                  >
                    <p style={{ margin: 10, fontSize: 13 }}>
                      سیستم برقی + داخلی
                    </p>
                    {this.state.carInfo.specifications.defects.electricAndInternal.map(
                      (p, i) => (
                        <Chip
                          key={i}
                          onClick={() =>
                            this.setState({
                              showAddDefectDialog: {
                                ...p,
                                parent: "electricAndInternal",
                                index: i,
                              },
                            })
                          }
                          size="medium"
                          color="secondary"
                          style={{
                            margin: "15px 0 10px 10px",
                            height: "auto",
                            alignSelf: "flex-start",
                          }}
                          label={
                            <p
                              style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                                margin: "5px 0",
                              }}
                            >
                              {p.title + " / " + p.value}
                            </p>
                          }
                          onDelete={() => {
                            this.state.carInfo.specifications.defects.electricAndInternal.splice(
                              i,
                              1
                            );
                            this.setState({ ref: true });
                          }}
                        />
                      )
                    )}
                  </div>
                )}

                {this.state.carInfo.specifications.defects.suspension.length >
                  0 && (
                  <div
                    style={{
                      borderRadius: 5,
                      border: "1px solid #d2d2d2",
                      margin: 7,
                      padding: 7,
                    }}
                  >
                    <p style={{ margin: 10, fontSize: 13 }}>سیستم تعلیق</p>
                    {this.state.carInfo.specifications.defects.suspension.map(
                      (p, i) => (
                        <Chip
                          key={i}
                          onClick={() =>
                            this.setState({
                              showAddDefectDialog: {
                                ...p,
                                parent: "suspension",
                                index: i,
                              },
                            })
                          }
                          size="medium"
                          color="secondary"
                          style={{
                            margin: "15px 0 10px 10px",
                            height: "auto",
                            alignSelf: "flex-start",
                          }}
                          label={
                            <p
                              style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                                margin: "5px 0",
                              }}
                            >
                              {p.title + " / " + p.value}
                            </p>
                          }
                          onDelete={() => {
                            this.state.carInfo.specifications.defects.suspension.splice(
                              i,
                              1
                            );
                            this.setState({ ref: true });
                          }}
                        />
                      )
                    )}
                  </div>
                )}

                {this.state.carInfo.specifications.defects.ventilation.length >
                  0 && (
                  <div
                    style={{
                      borderRadius: 5,
                      border: "1px solid #d2d2d2",
                      margin: 7,
                      padding: 7,
                    }}
                  >
                    <p style={{ margin: 10, fontSize: 13 }}>سیستم تهویه</p>
                    {this.state.carInfo.specifications.defects.ventilation.map(
                      (p, i) => (
                        <Chip
                          key={i}
                          onClick={() =>
                            this.setState({
                              showAddDefectDialog: {
                                ...p,
                                parent: "ventilation",
                                index: i,
                              },
                            })
                          }
                          size="medium"
                          color="secondary"
                          style={{
                            margin: "15px 0 10px 10px",
                            height: "auto",
                            alignSelf: "flex-start",
                          }}
                          label={
                            <p
                              style={{
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                                margin: "5px 0",
                              }}
                            >
                              {p.title + " / " + p.value}
                            </p>
                          }
                          onDelete={() => {
                            this.state.carInfo.specifications.defects.ventilation.splice(
                              i,
                              1
                            );
                            this.setState({ ref: true });
                          }}
                        />
                      )
                    )}
                  </div>
                )}
              </Grid>

              <Grid item sm={12} xs={12}>
                <Divider />
                <p style={{ margin: 10 }}>کارشناسی سلامت</p>
                <Button
                  disabled={!this.state.hasAccessEdit}
                  variant="outlined"
                  color="primary"
                  style={{ margin: "20px 10px" }}
                  onClick={() => {
                    this.props.CarsStore.setCurrentCar(this.state.carInfo);
                    this.setState({ showAddEditReviewsDialog: true });
                  }}
                >
                  افزودن موارد بیشتر
                </Button>
                <Button
                  disabled={!this.state.hasAccessEdit}
                  variant="outlined"
                  color="primary"
                  style={{ margin: "20px 10px" }}
                  onClick={() => {
                    this.state.carInfo.specifications.defects.bodyAndTires.forEach(
                      (p, i) => {
                        if (p.level === "2") {
                          this.props.CarsStore.currentCar.specifications.reviews[
                            "bodyAndTires"
                          ].push({
                            valid: p.valid ?? false,
                            title: p.title ?? "عنوان وارد نشده",
                            value: p.value ?? "مقدار وارد نشده",
                          });
                          this.state.carInfo.specifications.reviews =
                            this.props.CarsStore.currentCar.specifications.reviews;
                          this.props.CarsStore.setSpecificationsReviewsCar(
                            this.props.CarsStore.currentCar.specifications
                              .reviews
                          );
                        }
                      }
                    );
                    this.handleAccordionChange(
                      this.state.expanded === "bodyAndLastic"
                        ? ""
                        : "bodyAndLastic"
                    );
                  }}
                >
                  انتقال نواقص اصلی به برگه سبز
                </Button>
                <div
                  style={{
                    borderRadius: 5,
                    border: "1px solid #d2d2d2",
                    margin: 7,
                    padding: 7,
                  }}
                >
                  <Accordion
                    disabled={!this.state.hasAccessEdit}
                    expanded={this.state.expanded === "bodyAndLastic"}
                    onChange={() =>
                      this.handleAccordionChange(
                        this.state.expanded === "bodyAndLastic"
                          ? ""
                          : "bodyAndLastic"
                      )
                    }
                  >
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p style={{ margin: 10, fontSize: 13 }}>بدنه + لاستیک</p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {this.state.carInfo.specifications.reviews.bodyAndTires.map(
                        (p, i) => (
                          <Chip
                            key={i}
                            onClick={() => {
                              this.setState({
                                showAddEditReviewsDialog: {
                                  ...p,
                                  parent: "bodyAndTires",
                                  index: i,
                                },
                              });
                            }}
                            size="medium"
                            color="secondary"
                            style={{
                              margin: "15px 0 10px 10px",
                              height: "auto",
                              alignSelf: "flex-start",
                              backgroundColor: p.valid ? "#0d731d" : "#701a0f",
                            }}
                            label={
                              <p
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                  margin: "5px 0",
                                }}
                              >
                                {p.title + " / " + p.value}
                              </p>
                            }
                            onDelete={() => {
                              this.state.carInfo.specifications.reviews.bodyAndTires.splice(
                                i,
                                1
                              );
                              this.setState({ ref: true });
                            }}
                          />
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div
                  style={{
                    borderRadius: 5,
                    border: "1px solid #d2d2d2",
                    margin: 7,
                    padding: 7,
                  }}
                >
                  <Accordion
                    disabled={!this.state.hasAccessEdit}
                    expanded={this.state.expanded === "motor"}
                    onChange={() =>
                      this.handleAccordionChange(
                        this.state.expanded === "motor" ? "" : "motor"
                      )
                    }
                  >
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <p style={{ margin: 10, fontSize: 13 }}>موتور</p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {this.state.carInfo.specifications.reviews.motor.map(
                        (p, i) => (
                          <Chip
                            key={i}
                            onClick={() =>
                              this.setState({
                                showAddEditReviewsDialog: {
                                  ...p,
                                  parent: "motor",
                                  index: i,
                                },
                              })
                            }
                            size="medium"
                            color="secondary"
                            style={{
                              margin: "15px 0 10px 10px",
                              height: "auto",
                              alignSelf: "flex-start",
                              backgroundColor: p.valid ? "#0d731d" : "#701a0f",
                            }}
                            label={
                              <p
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                  margin: "5px 0",
                                }}
                              >
                                {p.title + " / " + p.value}
                              </p>
                            }
                            onDelete={() => {
                              this.state.carInfo.specifications.reviews.motor.splice(
                                i,
                                1
                              );
                              this.setState({ ref: true });
                            }}
                          />
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div
                  style={{
                    borderRadius: 5,
                    border: "1px solid #d2d2d2",
                    margin: 7,
                    padding: 7,
                  }}
                >
                  <Accordion
                    disabled={!this.state.hasAccessEdit}
                    expanded={this.state.expanded === "electeric"}
                    onChange={() =>
                      this.handleAccordionChange(
                        this.state.expanded === "electeric" ? "" : "electeric"
                      )
                    }
                  >
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <p style={{ margin: 10, fontSize: 13 }}>
                        سیستم برقی + داخلی
                      </p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {this.state.carInfo.specifications.reviews.electricAndInternal.map(
                        (p, i) => (
                          <Chip
                            key={i}
                            onClick={() =>
                              this.setState({
                                showAddEditReviewsDialog: {
                                  ...p,
                                  parent: "electricAndInternal",
                                  index: i,
                                },
                              })
                            }
                            size="medium"
                            color="secondary"
                            style={{
                              margin: "15px 0 10px 10px",
                              height: "auto",
                              alignSelf: "flex-start",
                              backgroundColor: p.valid ? "#0d731d" : "#701a0f",
                            }}
                            label={
                              <p
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                  margin: "5px 0",
                                }}
                              >
                                {p.title + " / " + p.value}
                              </p>
                            }
                            onDelete={() => {
                              this.state.carInfo.specifications.reviews.electricAndInternal.splice(
                                i,
                                1
                              );
                              this.setState({ ref: true });
                            }}
                          />
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div
                  style={{
                    borderRadius: 5,
                    border: "1px solid #d2d2d2",
                    margin: 7,
                    padding: 7,
                  }}
                >
                  <Accordion
                    disabled={!this.state.hasAccessEdit}
                    expanded={this.state.expanded === "taligh"}
                    onChange={() =>
                      this.handleAccordionChange(
                        this.state.expanded === "taligh" ? "" : "taligh"
                      )
                    }
                  >
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <p style={{ margin: 10, fontSize: 13 }}>سیستم تعلیق</p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {this.state.carInfo.specifications.reviews.suspension.map(
                        (p, i) => (
                          <Chip
                            key={i}
                            onClick={() =>
                              this.setState({
                                showAddEditReviewsDialog: {
                                  ...p,
                                  parent: "suspension",
                                  index: i,
                                },
                              })
                            }
                            size="medium"
                            color="secondary"
                            style={{
                              margin: "15px 0 10px 10px",
                              height: "auto",
                              alignSelf: "flex-start",
                              backgroundColor: p.valid ? "#0d731d" : "#701a0f",
                            }}
                            label={
                              <p
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                  margin: "5px 0",
                                }}
                              >
                                {p.title + " / " + p.value}
                              </p>
                            }
                            onDelete={() => {
                              this.state.carInfo.specifications.reviews.suspension.splice(
                                i,
                                1
                              );
                              this.setState({ ref: true });
                            }}
                          />
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div
                  style={{
                    borderRadius: 5,
                    border: "1px solid #d2d2d2",
                    margin: 7,
                    padding: 7,
                  }}
                >
                  <Accordion
                    disabled={!this.state.hasAccessEdit}
                    expanded={this.state.expanded === "tahvieh"}
                    onChange={() =>
                      this.handleAccordionChange(
                        this.state.expanded === "tahvieh" ? "" : "tahvieh"
                      )
                    }
                  >
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel6a-content"
                      id="panel6a-header"
                    >
                      <p style={{ margin: 10, fontSize: 13 }}>سیستم تهویه</p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {this.state.carInfo.specifications.reviews.ventilation.map(
                        (p, i) => (
                          <Chip
                            key={i}
                            onClick={() =>
                              this.setState({
                                showAddEditReviewsDialog: {
                                  ...p,
                                  parent: "ventilation",
                                  index: i,
                                },
                              })
                            }
                            size="medium"
                            color="secondary"
                            style={{
                              margin: "15px 0 10px 10px",
                              height: "auto",
                              alignSelf: "flex-start",
                              backgroundColor: p.valid ? "#0d731d" : "#701a0f",
                            }}
                            label={
                              <p
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                  margin: "5px 0",
                                }}
                              >
                                {p.title + " / " + p.value}
                              </p>
                            }
                            onDelete={() => {
                              this.state.carInfo.specifications.reviews.ventilation.splice(
                                i,
                                1
                              );
                              this.setState({ ref: true });
                            }}
                          />
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Divider />
                <p style={{ margin: 10 }}>تنظیمات بیشتر</p>
                <FormControl
                  component="fieldset"
                  style={{ margin: "20px 10px" }}
                >
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      disabled={!this.state.hasAccessEdit}
                      checked={this.state.sendSmsToSeller}
                      onChange={(e) => {
                        this.setState({
                          sendSmsToSeller: !this.state.sendSmsToSeller,
                        });
                      }}
                      control={<Checkbox color="primary" />}
                      label="ارسال پیامک برای فروشنده"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
                <Grid item sm={6} xs={12} style={classes.gridInput}>
                  <TextField
                    disabled={!this.state.hasAccessEdit}
                    onChange={(t) =>
                      (this.state.alternativeSellerPhoneNumber = t.target.value)
                    }
                    defaultValue={this.state.alternativeSellerPhoneNumber}
                    label="شماره جایگزین"
                    inputProps={{
                      style: {
                        textAlign: "left",
                        direction: "ltr",
                        fontSize: 15,
                      },
                      maxLength: 200,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        ? convertNumberToEN(e.target.value)
                        : "";
                    }}
                    fullWidth={true}
                    variant="outlined"
                    margin={"dense"}
                  />
                </Grid>

                <Grid item sm={6} xs={12} style={classes.gridInput}>
                  <FormControl
                    component="fieldset"
                    style={{ margin: "20px 10px" }}
                  >
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        disabled={!this.state.hasAccessEdit}
                        checked={this.state.carToPublicTransition}
                        onChange={(e) => {
                          this.setState({
                            carToPublicTransition:
                              !this.state.carToPublicTransition,
                          });
                        }}
                        control={<Checkbox color="primary" />}
                        label="انتقال خودکار به عمومی از دپو"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                  <TextField
                    onChange={(t) => {
                      this.handleCarTransitionDate(t.target.value);
                    }}
                    defaultValue={"وارد نشده"}
                    label="ساعت"
                    inputProps={{
                      style: {
                        textAlign: "right",
                        direction: "ltr",
                        fontSize: 15,
                        paddingRight: 4,
                      },
                      maxLength: 2,
                    }}
                    disabled={!this.state.carToPublicTransition}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        ? convertNumberToEN(e.target.value)
                        : "";
                    }}
                    type="number"
                    fullWidth={true}
                    variant="outlined"
                    margin={"dense"}
                  />
                  {this.state.depoDateTime &&
                  this.state.carToPublicTransition ? (
                    <p style={{ fontStyle: "bold" }}>
                      <br /> تاریخ انتقال خودکار :
                      {moment(this.state.depoDateTime).format(
                        "jYYYY/jMM/jDD  _ HH:mm"
                      )}
                    </p>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Divider />
                {this.state.submitRequest ? (
                  <div style={classes.progressBarContainer}>
                    <LinearProgress
                      style={{ width: 150, height: 4, marginTop: 15 }}
                    />
                    <p>درحال ارسال اطلاعات ...</p>
                  </div>
                ) : (
                  <div
                    style={{ display: "flex", marginTop: 20, maxWidth: 330 }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.restrictionChecking();
                        if (this.state.hasEditAccess) this.submit();
                      }}
                      style={{ margin: 5 }}
                      fullWidth
                      color={"primary"}
                    >
                      بروزرسانی
                    </Button>

                    <Button
                      variant="outlined"
                      style={{ margin: 5 }}
                      onClick={() =>
                        goBackUseHistory(this.props.history, "cars/created/all")
                      }
                      fullWidth
                      color={"secondary"}
                    >
                      بازگشت
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </Paper>

        <SnackBar
          variant={"error"}
          message={this.state.sendRequestErrMessage}
          open={this.state.sendRequestErrMessage !== ""}
          onClose={() => this.setState({ sendRequestErrMessage: "" })}
        />

        {this.state.success && (
          <AlertDialog
            onClose={() =>
              this.props.history.replace(
                "/cars/accepted/" + this.state.carInfo.type
              )
            }
            question={false}
            description={"بروزرسانی با موفقیت انجام شد."}
          />
        )}

        {this.state.warnInputMessage !== "" && (
          <AlertDialog
            onClose={() => this.setState({ warnInputMessage: "" })}
            question={false}
            description={this.state.warnInputMessage}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}

        {this.state.showCreateCarDialog && (
          <CreateCar
            history={this.props.history}
            onClose={() => this.setState({ showCreateCarDialog: false })}
            onSuccess={(item) => {
              this.props.CarsStore.setCurrentCar(item);
              this.setState({ showCreateCarDialog: false, carInfo: item });
            }}
          />
        )}

        {this.state.showAddDefectDialog && (
          <AddDefectDialog
            history={this.props.history}
            onClose={() => this.setState({ showAddDefectDialog: false })}
            curent={this.state.showAddDefectDialog}
            onSuccess={(item) => {
              this.props.CarsStore.setSpecificationsDefectsCar(item);
              this.state.carInfo.specifications.defects = item;
              this.setState({ showAddDefectDialog: false });
            }}
          />
        )}

        {this.state.showAddEditReviewsDialog && (
          <AddEditReviewsDialog
            history={this.props.history}
            onClose={() => this.setState({ showAddEditReviewsDialog: false })}
            curent={this.state.showAddEditReviewsDialog}
            onSuccess={(item) => {
              this.props.CarsStore.setSpecificationsReviewsCar(item);
              this.state.carInfo.specifications.reviews = item;
              this.setState({ showAddEditReviewsDialog: false });
            }}
          />
        )}

        {this.state.showAddMoreDialog && (
          <AddMoreDialog
            onClose={() => this.setState({ showAddMoreDialog: false })}
            onSuccess={(item) => {
              if (this.props.CarsStore.currentCar.details.more) {
                this.props.CarsStore.currentCar.details.more.push(item);
              } else {
                this.props.CarsStore.currentCar.details["more"] = [item];
              }
              this.state.carInfo.details["more"] =
                this.props.CarsStore.currentCar.details.more;
              this.setState({ showAddMoreDialog: false });
            }}
          />
        )}
      </Main>
    );
  }
}

export default AddEditCar;

const classes = {
  textInput: {
    margin: 10,
  },
  gridInput: {
    maxWidth: 350,
    minWidth: 300,
  },
  gridInputSmall: {
    padding: 0,
    minWidth: 120,
    maxWidth: 120,
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
};

const detailsSelectsStyle = { marginLeft: 5, minWidth: 200, maxWidth: 300 };
const mandatoryLabel = { color: "red", margin: 0 };
const ordinaryLabel = { margin: 0 };
