import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Main from "../../common/UserMain";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Button from "@material-ui/core/Button";
import SnackBar from "../../common/SnackBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import AlertDialog from "../../common/AlertDialog";

import { goBackUseHistory } from "../../AppHistory";
import RequestState from "../../common/RequestState";
import Editor from "../../common/Editor";

let apiClient = null;

@inject("AccountStore")
@inject("AgentsStore")
@inject("CarsStore")
@observer
class AddEditCarHtml extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (!props.AccountStore.isLogin()) this.props.history.replace("/");

    this.state = {
      id: props.match.params.id,
      sendRequest: false,
      sendRequestErr: false,
      submitRequest: false,
      sendRequestErrMessage: "",
      inputWarn: 0,
      warnInputMessage: "",
      success: false,
      message: "",
      showGalleryAsDialog: false,
      carInfo: props.CarsStore.getCurrentCar(),
    };
  }

  componentDidMount() {
    this.getCarInfo();
  }

  getCarInfo() {
    if (this.state.sendRequest) {
      return;
    }

    this.setState({ sendRequest: true, sendRequestErr: false });

    apiClient.get(
      ApiHelper.getCarInfoById_GET(this.state.id),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          this.props.CarsStore.setCurrentCar(response.data);
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            carInfo: response.data,
          });
        }
      }
    );
  }

  submit() {
    if (this.state.submitRequest) return;

    let data = { description: this.state.carInfo.description };

    this.setState({ submitRequest: true });

    apiClient.post(
      ApiHelper.addEditCarHTML_POST(this.state.id),
      (error, response) => {
        this.setState({ submitRequest: false });
        if (error) {
          this.setState({
            submitRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ submitRequest: false, success: true });
        }
      },
      data
    );
  }

  render() {
    return (
      <Main
        onBackPress={() => goBackUseHistory(this.props.history)}
        page={"cars"}
        pageName={"خودرو"}
        context={this.props.history}
      >
        <Paper style={{ padding: 15 }}>
          {this.state.sendRequestErr || this.state.sendRequest ? (
            <RequestState
              error={this.state.sendRequestErr}
              retryOnPress={() => this.getCarInfo()}
              title={"در حال دریافت اطلاعات ..."}
              request={this.state.sendRequest}
            />
          ) : (
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <p style={{ margin: "10px 5px" }}>
                  کد خودرو : {this.state.carInfo.code}
                </p>
                <Divider />
                <p style={{ margin: "10px 5px" }}>
                  {this.state.carInfo.brand} | {this.state.carInfo.model} |{" "}
                  {this.state.carInfo.year} | {this.state.carInfo.option}
                </p>
                <Divider />
              </Grid>

              <Grid item sm={12} xs={12}>
                <Editor
                  jodit={(i) => (this.jodit = i)}
                  lan={"fa"}
                  sliderReplace={false}
                  value={this.state.carInfo.description}
                  onChange={(newContent) =>
                    (this.state.carInfo.description = newContent)
                  }
                  alert={(message) =>
                    this.setState({ warnInputMessage: message })
                  }
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <Divider />
                {this.state.submitRequest ? (
                  <div style={classes.progressBarContainer}>
                    <LinearProgress
                      style={{ width: 150, height: 4, marginTop: 15 }}
                    />
                    <p>درحال ارسال اطلاعات ...</p>
                  </div>
                ) : (
                  <div
                    style={{ display: "flex", marginTop: 20, maxWidth: 330 }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => this.submit()}
                      style={{ margin: 5 }}
                      fullWidth
                      color={"primary"}
                    >
                      بروزرسانی
                    </Button>

                    <Button
                      variant="outlined"
                      style={{ margin: 5 }}
                      onClick={() =>
                        goBackUseHistory(this.props.history, "cars/created/all")
                      }
                      fullWidth
                      color={"secondary"}
                    >
                      بازگشت
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </Paper>

        <SnackBar
          variant={"error"}
          message={this.state.sendRequestErrMessage}
          open={this.state.sendRequestErrMessage !== ""}
          onClose={() => this.setState({ sendRequestErrMessage: "" })}
        />

        {this.state.success && (
          <AlertDialog
            onClose={() => goBackUseHistory(this.props.history, "cars/all/all")}
            question={false}
            description={"بروزرسانی با موفقیت انجام شد."}
          />
        )}

        {this.state.warnInputMessage !== "" && (
          <AlertDialog
            onClose={() => this.setState({ warnInputMessage: "" })}
            question={false}
            description={this.state.warnInputMessage}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </Main>
    );
  }
}

export default AddEditCarHtml;

const classes = {
  textInput: {
    margin: 10,
  },
  gridInput: {
    maxWidth: 350,
    minWidth: 300,
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
};
