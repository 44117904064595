import React, { useState, useEffect, useReducer } from "react";
import { ApiClient, ApiHelper } from "../../../../data/remote";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { convertNumberToEN } from "../../../../language/NumberConverter";
import DialogReceiveCode from "../DialogReceiveCode/DialogReceiveCode";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircularIntegration from "../../../common/ProgressButton/ProgressButton";
import SnackBar from "../../../common/snackBar/snackBar";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ContractInitialInformation from "../../../../interface/contract";
import Chip from "@material-ui/core/Chip";

const reducer = (state, action) => {
  if (action.copy) {
    return action.payload;
  }
  if (action.type === "reset") {
    return {};
  } else
    return {
      ...state,
      ...(typeof action === "function" ? action() : action),
    };
};
let deliverReceiptContract = new ContractInitialInformation();
deliverReceiptContract.addCustomeInformation({
  sellerReceptionCode: "",
  sellerSellDate: "",
  sellerCarBrand: "",
  sellerCarKind: "",
  sellerCarTreim: "",
  sellerCarProductionYear: "",
  sellercarBodyColor: "",
  buyerCarBrand: "",
  buyerCarKind: "",
  buyerCarTreim: "",
  buyerCarProductionYear: "",
  buyercarBodyColor: "",
  buyerReceptionCode: "",
  sales: true,
});
const initial = {
  name: "",
  father: "",
  nationalCode: "",
  city: "",
  address: "",
  phoneNumber: "",
  km: "",
  hour: "",
  company: "",
  registerCodeCompany: "",
  nationalCodeCompany: "",
  branchName: "",
  branchAddress: "",
  branchPhoneNumber: "",
  carKind: "",
  carBrand: "",
  carModel: "",
  carTreim: "",
  carYear: "",
  carCapacity: "",
  carColor: "",
  carFeul: "",
  carMotorCode: "",
  carShashiNumber: "",
  carVIN: "",
  carPlaque: "",
  stringDate: "",
  carCard: true,
  carCompanyPaper: true,
  carGreenCard: true,
  carGuarantee: true,
  carThirdPartyInsurance: true,
  carTechExamSheet: true,
  carMortgageForm: true,
  carBodyInsurance: true,
  nationalCard: true,
  fuelCard: true,
  jackWheelWrench: true,
  carServiceBooklet: true,
  spareSwitch: true,
  spareWheel: true,
  timLock: true,
  goldenCard: true,
  soles: true,
  antena: true,
  ashTray: true,
  screwCorrosionWrench: true,
  reverseCamera: true,
  electricGlass: true,
  engineDoorScrewFenderTrunkHood: true,
  rearSeatMonitor: true,
  recordingMonitor: true,
  burglarAlarm: true,
  windshield: true,
  lighter: true,
  sunroof: true,
  cruiseControl: true,
  seatHeater: true,
  typeRadar: true,
  tronicBrigade: true,
  electricMemoryChair: true,
  receptionCode: "",
};
let requestStateObject = {
  message: "",
  error: false,
  success: false,
  responseObj: {},
  openNotif: false,
  pending: false,
};
const requestReducer = (state, action) => {
  switch (action.type) {
    case "throwError":
      return {
        ...state,
        message: action.message,
        success: false,
        error: true,
        responseObj: action.responseObj || {},
        openNotif: true,
        pending: false,
      };
      break;
    case "throwSuccess":
      return {
        ...state,
        message: action.message,
        error: false,
        success: true,
        responseObj: action.responseObj || {},
        openNotif: true,
        pending: false,
      };
    case "closeNotif":
      return {
        ...state,
        error: false,
        success: false,
        openNotif: false,
        pending: false,
      };
    case "pending":
      return {
        ...state,
        pending: true,
        success: false,
        error: false,
      };
    case "openNotif":
      return {
        ...state,
        openNotif: true,
      };
    default:
      return {
        message: "",
        error: false,
        success: false,
        responseObj: {},
        openNotif: false,
        pending: false,
      };
      break;
  }
};

const PurchaseOrderSurveyForm = ({ sales }) => {
  const [information, infoDispatch] = useReducer(reducer, initial);
  const [message, setMessage] = useState("");
  const [showDialogReceiveCode, setShowDialogReceiveCode] = useState(false);
  const [receptionCodeEnteredByUser, setReceptionCodeEnteredByUser] =
    useState("");
  const [requetsState, dispatchRequestState] = useReducer(
    requestReducer,
    requestStateObject
  );
  const [pdfUrl, setPdfUrl] = useState(null);
  const [infoFromCodeReq, setInfoFromCodeReq] = useState({ loading: false });
  const [contractRequest, setContractRequest] = useState({
    loading: false,
    success: false,
    error: false,
  });
  const [saveContractReq, setSaveContractReq] = useState({
    loading: false,
    success: false,
    error: false,
  });
  let apiClient = ApiClient.getInstance();
   const storedId = localStorage.getItem("accountStoreAdmin");
   const parsedData = JSON.parse(storedId);

  const getInfoByReceptionCode = () => {
    setShowDialogReceiveCode(true);
  };

  const getContract = () => {
    let data = {
      ...information,
      sales: sales,
      contractKind: sales ? "salesManager" : "purchaseManager",
    };
    dispatchRequestState({ type: "pending" });
    setContractRequest({ loading: true, success: false, error: false });
    apiClient.post(
      ApiHelper.getContract,
      (error, response) => {
        if (error) {
          dispatchRequestState({ type: "openNotif" });
          dispatchRequestState({
            type: "throwError",
            message: "لطفا بعد از چند ثانیه دوباره تلاش کنید !",
            responseObj: response,
          });
          setContractRequest({ loading: false, success: false, error: true });
        } else {
          dispatchRequestState({ type: "openNotif" });
          setPdfUrl(response.pdfurl);
          dispatchRequestState({
            type: "throwSuccess",
            message: "درخواست با موفقیت فرستاده شد",
            responseObj: response,
          });
          setContractRequest({ loading: false, success: true, error: false });
        }
      },
      data
    );
  };

  const saveInfoOfContract = () => {
    let data = {
      ...information,
      receptionCode: receptionCodeEnteredByUser,
      agentId: parsedData.profile._id,
      dateNow: new Date(),
    };
    dispatchRequestState({ type: "pending" });
    setSaveContractReq({ loading: true, success: false, error: false });
    apiClient.post(
      ApiHelper.updateContract,
      (error, response) => {
        if (error) {
          dispatchRequestState({ type: "openNotif" });
          dispatchRequestState({
            type: "throwError",
            message: response.message,
            responseObj: response,
          });
          setSaveContractReq({ loading: false, success: false, error: true });
        } else {
          dispatchRequestState({ type: "openNotif" });
          dispatchRequestState({
            type: "throwSuccess",
            message: response.message,
            responseObj: response,
          });
          setSaveContractReq({ loading: false, success: true, error: false });
        }
        setTimeout(() => {
          setSaveContractReq({ loading: false, success: false, error: false });
        }, 5000);
      },
      data
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Button
            style={{ whiteSpace: "nowrap" }}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => getInfoByReceptionCode()}
          >
            گرفتن اطلاعات با کد پذیرش
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Chip
            label={sales ? "فرم نظرسنجی آمر فروش" : "فرم نظرسنجی آمر خرید"}
            color="primary"
            style={{ fontSize: "larger" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          {(() => {
            if (information.receptionCode === initial.receptionCode) {
              return (
                <Chip
                  label="کد پذیرش را وارد کنید"
                  style={{ backgroundColor: "red", color: "white" }}
                />
              );
            }
            return (
              <Chip
                label={"کد پذیرش : " + information.receptionCode}
                style={{ backgroundColor: "green", color: "white" }}
              />
            );
          })()}
        </Grid>
      </Grid>
      {sales ? (
        <>
          <h4>مشخصات فروشنده:</h4>
          <Divider />
          <Grid container spacing={2} style={classes.gridContainer}>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ sellerName: t.target.value })}
                value={information.sellerName || " "}
                label="نام"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) =>
                  infoDispatch({ sellerLastName: t.target.value })
                }
                value={information.sellerLastName || " "}
                label="نام خانوادگی"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) =>
                  infoDispatch({ receptionCode: t.target.value })
                }
                value={information.receptionCode || " "}
                label="کد پذیرش"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) =>
                  infoDispatch({ sellerPhoneNumber: t.target.value })
                }
                value={information.sellerPhoneNumber || " "}
                label="شماره تماس"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ sellDate: t.target.value })}
                value={information.sellDate || " "}
                label="تاریخ فروش"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ carKind: t.target.value })}
                value={information.carKind || " "}
                label="نوع خودرو"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ carBrand: t.target.value })}
                value={information.carBrand || " "}
                label="برند خودرو"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ carTreim: t.target.value })}
                value={information.carTreim || " "}
                label="تریم خودرو"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) =>
                  infoDispatch({ carProductionYear: t.target.value })
                }
                value={information.carProductionYear || " "}
                label="سال ساخت"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ carBodyColor: t.target.value })}
                value={information.carBodyColor || " "}
                label="رنگ بدنه"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <h4>مشخصات خریدار:</h4>
          <Divider />
          <Grid container spacing={2} style={classes.gridContainer}>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ buyerName: t.target.value })}
                value={information.buyerName || " "}
                label="نام"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) =>
                  infoDispatch({ buyerLastName: t.target.value })
                }
                value={information.buyerLastName || " "}
                label="نام خانوادگی"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) =>
                  infoDispatch({ receptionCode: t.target.value })
                }
                value={information.receptionCode || " "}
                label="کد پذیرش"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) =>
                  infoDispatch({ buyersPhoneNumber: t.target.value })
                }
                value={information.buyersPhoneNumber || " "}
                label="شماره تماس"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ sellDate: t.target.value })}
                value={information.sellDate || " "}
                label="تاریخ فروش"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ buyerCarKind: t.target.value })}
                value={information.buyerCarKind || " "}
                label="نوع خودرو"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ carBrand: t.target.value })}
                value={information.carBrand || " "}
                label="برند خودرو"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ carTreim: t.target.value })}
                value={information.carTreim || " "}
                label="تریم خودرو"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) =>
                  infoDispatch({ carProductionYear: t.target.value })
                }
                value={information.carProductionYear || " "}
                label="سال ساخت"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
            <Grid item sm={12} xs={4} style={classes.gridInput}>
              <TextField
                onChange={(t) => infoDispatch({ carBodyColor: t.target.value })}
                value={information.carBodyColor || " "}
                label="رنگ بدنه"
                inputProps={{ maxLength: 100, readOnly: true }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value;
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item sm={6} xs={4} style={classes.getContractButton}>
          <Button
            style={{ whiteSpace: "nowrap" }}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => getContract()}
          >
            درخواست قرارداد
            {contractRequest.loading ? (
              <CircularProgress color="secondary" />
            ) : null}
          </Button>
        </Grid>
        <Grid item sm={6} xs={4} style={classes.getContractButton}>
          <CircularIntegration
            title={"ذخیره اطلاعات قرارداد"}
            success={saveContractReq.success}
            loading={saveContractReq.loading}
            onclick={() => saveInfoOfContract()}
          />
        </Grid>
      </Grid>
      {pdfUrl ? (
        <Grid item sm={6} xs={4} style={classes.getContractButton}>
          <a href={pdfUrl} style={{ color: "black", width: "100%" }}>
            <CircularIntegration
              title={"دانلود قرارداد"}
              success={contractRequest.success}
              loading={contractRequest.loading}
              Icon={<PictureAsPdfIcon />}
            />
          </a>
        </Grid>
      ) : null}
      {message !== "" && (
        <AlertDialog
          onClose={() => this.setState({ message: "" })}
          type={"alert"}
          question={false}
          description={message}
        />
      )}
      {showDialogReceiveCode && (
        <DialogReceiveCode
          setShowDialogReceiveCode={setShowDialogReceiveCode}
          setMessage={setMessage}
          infoDispatch={infoDispatch}
          setReceptionCodeEnteredByUser={setReceptionCodeEnteredByUser}
          dispatchRequestState={dispatchRequestState}
          sendRequest={infoFromCodeReq.loading}
          setInfoFromCodeReq={setInfoFromCodeReq}
          getterFunction={(receptionCode) =>
            ApiHelper.getContractByCode(receptionCode)
          }
        />
      )}
      <SnackBar
        open={requetsState.openNotif}
        handleClose={() => dispatchRequestState({ type: "closeNotif" })}
        message={requetsState.message}
        severity={requetsState.error ? "error" : "success"}
      />
    </>
  );
};

export default PurchaseOrderSurveyForm;

const classes = {
  gridContainer: {
    marginTop: 20,
  },
  textInput: {
    margin: 10,
  },
  gridInput: {
    display: "flex",
    alignItems: "center",
    maxWidth: 400,
    minWidth: 200,
  },
  gridInputPercents: {
    display: "flex",
    alignItems: "center",
    maxWidth: 550,
    minWidth: 200,
    marginTop: 15,
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
  getContractButton: {
    marginTop: 20,
  },
};
