import { useEffect } from "react";
import { useState } from "react";
import { ApiClient, ApiHelper } from "../../data/remote";
let apiClient = null;

const useGetAgents = (agent_status) => {
  const [agents, setAgents] = useState([]);
  const [agentloading, setAgentloading] = useState(true);
  apiClient = ApiClient.getInstance();
  useEffect(() => {
    setAgentloading(true);
    apiClient.get(ApiHelper.getAgents_GET(agent_status), (error, response) => {
      if (error) {
        //logic for handling error in here
        setAgentloading(false);
      } else {
        setAgents(response.records);
        setAgentloading(false);
      }
    });
  }, []);

  return [agents, agentloading];
};

export default useGetAgents;
