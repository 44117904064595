import React from "react";
import { Switch } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import styles from './Filter.module.css'

const TypeDateFilter = ({ title, handlertypeDate }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isChecked) {
      handlertypeDate(isChecked);
    } else {
      handlertypeDate(isChecked);
    }
  }, [isChecked]);
  return (
    <>
    <h4>{title}</h4>
    <div className={styles.holder}>
      <Switch
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      <div className={styles.holder}>
        <p className={isChecked ? styles.bolder : styles.option}>
          تاریخ رزرو   
        </p>
        <p> / </p>
        <p className={!isChecked ? styles.bolder : styles.option}>
        تاریخ ارسال 
        </p>
      </div>
    </div>
    </>
  );
};

export default TypeDateFilter;
