import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "10 !important",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    maxWidth: "600px",
    width: "90%",
    outline: "none",
    borderRadius: theme.shape.borderRadius,
    marginLeft: "200px",
    [theme.breakpoints.down(854)]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  topBar: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
    maxHeight: 400,
    overflowY: "auto",
    marginTop: theme.spacing(1),
    "&::-webkit-scrollbar": {
      width: 6,
      height: 6,
      borderRadius: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#1E88E5",
      borderRadius: 6,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#FFFFFF",
      borderRadius: 6,
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  },
  inputItem: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  triple: {
    display: "flex",
  },
  tinyLines: {
    width: "1.5px",
    height: "19px",
    backgroundColor: "#2196F3",
    margin: "0px 5px",
  },
  topBarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: {
    width: "71px",
    display: "flex",
    alignItems: "center",
  },
  tinytext: {
    marginLeft: "11px",
  },
  ClearInput: {
    marginRight: "30px",
  },
  checkboxLabel: {
    justifyContent: "flex-end",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  },
  textContainer: {
    color: "#ed5555",
    display: "flex",
    alignItems: "center",
    marginRight : '25px'
  },
  submitButton: {
    borderRadius: "100px",
    marginRight: theme.spacing(1),
    boxShadow: "none",
  },
  cancelButton: {
    borderRadius: "100px",
    marginLeft: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(33, 150, 243, 0.5)",
    color: "#2196F3",
    boxShadow: "none",
  },
  iconContainer: {
    width: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default useStyles;
