import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Main from "../../common/UserMain";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Button from "@material-ui/core/Button";
import SnackBar from "../../common/SnackBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import AlertDialog from "../../common/AlertDialog";
import { convertNumberToEN } from "../../../language/NumberConverter";
import { goBackUseHistory } from "../../AppHistory";
import {
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import StarRater from "../../common/StarRater/StartRater";
import Typography from "@material-ui/core/Typography";

let apiClient = null;

@inject("AccountStore")
@inject("BrokersStore")
@inject("LocationsStore")
@observer
class AddEditBroker extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();
    if (!props.AccountStore.isLogin()) this.props.history.replace("/");
    this.state = {
      isNewBroker: !props.BrokersStore.current._id,
      sendRequest: false,
      sendRequestErrMessage: "",
      inputWarn: 0,
      warnInputMessage: "",
      success: false,
      getLocationErr: false,
      message: "",
      expertName: "",
      expertPhone: "",
      PositiveRate: 0,
      NegativeRate: 0,
      description: "",
      sellerId: props.match.params.agentId,
      page: props.match.params.page ? props.match.params.page : 1,
      status: props.match.params.status,
      pageNum: 1,
      sellerName: props?.location?.state?.agentName,
    };
  }

  componentDidMount() {
    this.getLocation();
    this.setState({ sellerId: this.props.location.id });
  }
  getLocation(ref = false) {
    if (!ref && this.props.LocationsStore.locations.length > 0) return;

    this.setState({ getLocationErr: false });

    apiClient.get(ApiHelper.getLocations_GET, (error, response) => {
      if (error) {
        this.setState({
          getLocationErr:
            this.props.LocationsStore.locations.length <= 0 && !ref,
        });
      } else {
        this.props.LocationsStore.setLocations(response.records);
        this.setState({ getLocationErr: false });
      }
    });
  }

  submit() {
    if (this.state.sendRequest) return;
    let data = this.props.BrokersStore.getCurrent();
    if (data.location && data.location._id) data.location = data.location._id;

    this.setState({ sendRequest: true });

    apiClient.post(
      ApiHelper.addEditBroker_POST,
      (error, response) => {
        this.setState({ sendRequest: false });
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false, success: true });
        }
      },
      data
    );
  }

  changePositiveRate(newPostiveValue) {
    this.setState({ RatingPositive: newPostiveValue });
    this.props.BrokersStore.current.PositiveRate = newPostiveValue;
  }

  changeNegativeRate(newNegativeValue) {
    this.setState({ RatingNegative: newNegativeValue });
    this.props.BrokersStore.current.NegativeRate = newNegativeValue;
  }

  changeBrokerDescription(description) {
    this.state.description = description;
    this.props.BrokersStore.current.description = description;
  }

  render() {
    return (
      <Main
        onBackPress={() => goBackUseHistory(this.props.history)}
        page={"brokers/accepted"}
        pageName={"ثبت بروکر"}
        context={this.props.history}
      >
        <Paper style={{ padding: 15 }}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputName = r)}
                onChange={(t) =>
                  (this.props.BrokersStore.current.name = t.target.value)
                }
                defaultValue={this.props.BrokersStore.current.name}
                error={this.state.inputWarn === 3}
                label="نام کامل"
                inputProps={{ maxLength: 200 }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) =>
                  ev.key === "Enter" && this.inputPhone.focus()
                }
              />
            </Grid>

            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputPhone = r)}
                onChange={(t) =>
                  (this.props.BrokersStore.current.phone = t.target.value)
                }
                defaultValue={this.props.BrokersStore.current.phone}
                label={"موبایل"}
                placeholder={"موبایل با ۰۹ شروع شود"}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                inputProps={{
                  style: { textAlign: "left", direction: "ltr", fontSize: 15 },
                  maxLength: 11,
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value)
                        .toString()
                        .replace(/[^0-9]/g, "")
                        .toString()
                    : "";
                }}
                type="tel"
              />
            </Grid>

            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputName = r)}
                onChange={(t) =>
                  (this.props.BrokersStore.current.activityType =
                    t.target.value)
                }
                defaultValue={this.props.BrokersStore.current.activityType}
                error={this.state.inputWarn === 3}
                label="نوع فعالیت"
                inputProps={{ maxLength: 200 }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
              />
            </Grid>

            <Grid item sm={6} xs={12} style={classes.gridInput}>
              {this.state.getLocationErr ? (
                <Button
                  onClick={() => this.getLocation(true)}
                  color="secondary"
                  variant="outlined"
                  size="small"
                >
                  خطا در دریافت شهرها !
                </Button>
              ) : (
                <FormControl label="شهر">
                  <Select
                    defaultValue={
                      !this.props.BrokersStore.current.location
                        ? "0"
                        : this.props.BrokersStore.current.location._id
                    }
                    variant="outlined"
                    margin={"dense"}
                    style={{ fontSize: 13, marginTop: 10 }}
                    onChange={(e) =>
                      (this.props.BrokersStore.current.location =
                        e.target.value)
                    }
                  >
                    <MenuItem style={{ fontSize: 13 }} value={"0"}>
                      {this.props.LocationsStore.locations.length < 0 ? (
                        <CircularProgress size={16} style={{ margin: 1 }} />
                      ) : (
                        "انتخاب شهر"
                      )}
                    </MenuItem>

                    {this.props.LocationsStore.locations.map((l) => (
                      <MenuItem
                        key={l._id}
                        style={{ fontSize: 13 }}
                        value={l._id}
                      >
                        {l.location}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <IconButton onClick={() => this.getLocation(true)}>
                <Icon>sync</Icon>
              </IconButton>
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <Typography variant="h6" component="h6">
                نکته مثبت مورد توافق
              </Typography>
              <StarRater
                rate={this.props.BrokersStore.current.PositiveRate}
                state="positive"
                onRateChange={(newPostiveValue) =>
                  this.changePositiveRate(newPostiveValue)
                }
                name="positive_changing"
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <Typography variant="h6" component="h6">
                نکته منفی مورد توافق
              </Typography>
              <StarRater
                rate={this.props.BrokersStore.current.NegativeRate}
                state="negative"
                onRateChange={(newNegativeValue) =>
                  this.changeNegativeRate(newNegativeValue)
                }
                name="negative_changing"
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridDescription}>
              <TextField
                id="broker_description"
                label="توضیحات"
                multiline
                maxRows={12}
                defaultValue={this.props.BrokersStore.current.description}
                onChange={(e) => this.changeBrokerDescription(e.target.value)}
                style={classes.gridDescription}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Divider />
              {this.state.sendRequest ? (
                <div style={classes.progressBarContainer}>
                  <LinearProgress
                    style={{ width: 150, height: 4, marginTop: 15 }}
                  />
                  <p>درحال ارسال اطلاعات ...</p>
                </div>
              ) : (
                <div style={{ display: "flex", marginTop: 20, maxWidth: 330 }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.submit();
                      this.setState({
                        searchParams: new URLSearchParams(
                          window.location.search
                        ),
                      });
                    }}
                    style={{ margin: 5 }}
                    fullWidth
                    color={"primary"}
                  >
                    {this.state.isNewBroker ? "ارسال" : "بروزرسانی"}
                  </Button>

                  <Button
                    variant="outlined"
                    style={{ margin: 5 }}
                    onClick={() => goBackUseHistory(this.props.history)}
                    fullWidth
                    color={"secondary"}
                  >
                    بازگشت
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </Paper>

        <SnackBar
          variant={"error"}
          message={this.state.sendRequestErrMessage}
          open={this.state.sendRequestErrMessage !== ""}
          onClose={() => this.setState({ sendRequestErrMessage: "" })}
        />
        {this.state.success && (
          <AlertDialog
            onClose={() => {
              this.props.history.push(
                `/brokers/${this.state.status}/${this.props.match.params.agentId}/${this.state.page}`,
                { agentName: this.state.sellerName }
              );
            }}
            question={false}
            description={
              this.state.isNewBroker
                ? "بروکر با موفقیت ایجاد شد."
                : "بروزرسانی با موفقیت انجام شد."
            }
          />
        )}

        {this.state.warnInputMessage !== "" && (
          <AlertDialog
            onClose={() => this.setState({ warnInputMessage: "" })}
            question={false}
            description={this.state.warnInputMessage}
          />
        )}
        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </Main>
    );
  }
}

export default AddEditBroker;

const classes = {
  textInput: {
    margin: 10,
  },
  gridInput: {
    maxWidth: 350,
    minWidth: 300,
  },
  gridDescription: {
    maxWidth: "100%",
    minWidth: 330,
    lineHeight: "1.8em",
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
};
