export const DefecenciesList = [
  { label: "صافکاری و موج دارد" },
  { label: "صافکاری و نیاز به رنگ دارد" },
  { label: "موج شدید دارد" },
  { label: "تعویض و رنگ است" },
  { label: "رنگ و بتونه دارد" },
  { label: "تعویض است" },
  { label: "سنگ خوردگی دارد" },
  { label: "خط و خش دارد" },
  { label: "شکستگی دارد" },
  { label: "اسید پاشی شده است" },
  { label: "نیاز به صافکاری دارد" },
  { label: "نیاز به رنگ دارد" },
  { label: "قلم گیری دارد" },
  { label: "لیسه گیری دارد" },
  { label: "رنگ پریدگی دارد" },
  { label: "نیاز به ترمیم دارد" },
  { label: "رنگ پریدگی و نیاز به ترمیم دارد" },
  { label: "صافکاری شده " },
  { label: "آبرنگ دارد" },
  { label: "تعویض استوک است" },
  { label: "دیاق پایین تعویض" },
  { label: "دیاق پایین جوش کاری دارد" },
  { label: "دیاق پایین صافکاری شده" },
  { label: "دیاق سر شاسی تعویض است" },
  { label: "دیاق سر شاسی جوش کاری دارد" },
  { label: "سینی جلو تعویض" },
  { label: "سینی جلو صافکاری شده" },
  { label: "سینی جلو رنگ دارد" },
  { label: "سینی پایین جوش کاری دارد" },
  { label: "سینی پشت موتور رنگ دارد" },
  { label: "سینی پشت موتور صافکاری دارد" },
  { label: "پالونی سمت شاگرد رنگ دارد" },
  { label: "پالونی سمت شاگرد صافکاری دارد" },
  { label: "پالونی سمت راننده رنگ دارد" },
  { label: "پالونی سمت راننده صافکاری دارد" },
  { label: "جا چراغی جلو سمت راننده رنگ دارد" },
  { label: "جا چراغی جلو سمت راننده صافکاری دارد" },
  { label: "جا چراغی جلو سمت شاگرد رنگ دارد" },
  { label: "جا چراغی جلو سمت شاگرد صافکاری دارد" },
  { label: "جا چراغی عقب سمت راننده رنگ دارد" },
  { label: "جا چراغی عقب سمت راننده صافکاری دارد" },
  { label: "جا چراغی عقب سمت شاگرد رنگ دارد" },
  { label: "جا چراغی عقب سمت شاگرد صافکاری دارد" },
  { label: "کف صندوق عقب صافکاری دارد" },
  { label: "کف صندوق عقب خوردگی دارد" },
  { label: "کف صندوق عقب ضربه دارد" },
  { label: "سینی پشت صندوق ضربه دارد" },
  { label: "سینی پشت صندوق صافکاری شده" },
  { label: "سینی عقب خوردگی دارد" },
  { label: "سرشاسی های عقب ضربه دارد" },
  { label: "سرشاسی عقب فشار دارد" },
];
