import React from "react";

import Filter from "./Filter";
import { Grid } from "@material-ui/core";
import ErrorSolving from "./ErrorSolving";

const Header = () => {
  return (
    <Grid container>
      <Grid item xs={8} sm={9} md={6} lg={6} xl={8}>
        <ErrorSolving />
      </Grid>
      <Grid item xs={4} sm={3} md={6} lg={6} xl={4}>
        <Filter />
      </Grid>
    </Grid>
  );
};

export default Header;
