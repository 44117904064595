import React from "react";
const Search = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5297 11.4697C13.2368 11.1768 12.762 11.1768 12.4691 11.4697C12.1762 11.7626 12.1762 12.2375 12.4691 12.5303L13.5297 11.4697ZM15.1357 15.197C15.4285 15.4899 15.9034 15.4899 16.1963 15.197C16.4892 14.9041 16.4892 14.4292 16.1963 14.1363L15.1357 15.197ZM12.4691 12.5303L15.1357 15.197L16.1963 14.1363L13.5297 11.4697L12.4691 12.5303ZM7.66602 11.9166C4.76652 11.9166 2.41602 9.56612 2.41602 6.66663H0.916016C0.916016 10.3945 3.93809 13.4166 7.66602 13.4166V11.9166ZM12.916 6.66663C12.916 9.56612 10.5655 11.9166 7.66602 11.9166V13.4166C11.3939 13.4166 14.416 10.3945 14.416 6.66663H12.916ZM7.66602 1.41663C10.5655 1.41663 12.916 3.76713 12.916 6.66663H14.416C14.416 2.9387 11.3939 -0.083374 7.66602 -0.083374V1.41663ZM7.66602 -0.083374C3.93809 -0.083374 0.916016 2.9387 0.916016 6.66663H2.41602C2.41602 3.76713 4.76652 1.41663 7.66602 1.41663V-0.083374Z"
        fill="black"
        fill-opacity="0.38"
      />
    </svg>
  );
};

export default Search;
