import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import React, { memo, useRef } from "react";
import Close from "../../packages/icons/Close";
import StatusDropDown from "../../components/new-request-mutual-components/StatusDropDown";
const ModalSituation = memo(
  ({
    options,
    disable,
    handleChange,
    situation,
    sendingSms,
    description,
    handleSetFieldManually,
  }) => {
    const cacheRtl = createCache({
      key: "muirtl",
      stylisPlugins: [prefixer, stylisRTLPlugin],
    });
    const maxWidth600 = useMediaQuery("(max-width: 600px)");
    const input = useRef();

    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
          >
            وضعیت درخواست
          </Typography>
          <StatusDropDown
            situation={situation}
            disable={disable}
            handleSetFieldManually={handleSetFieldManually}
            handleChange={handleChange}
          />
        </Box>
        <Box>
          <FormControlLabel
            disabled={!disable ? true : false}
            sx={{
              "&.MuiFormControlLabel-root": {
                width: "100%",
                justifyContent: "space-between",
                marginInline: "unset",
                color: "text.secondary",
              },
              "& .MuiTypography-root": {
                fontSize: { xs: 13, sm: 16 },
              },
            }}
            control={
              <Switch
                name="sendingSms"
                checked={sendingSms}
                onChange={handleChange}
              />
            }
            label="ارسال پیامک"
            labelPlacement="start"
          />
        </Box>
        <Box>
          <CacheProvider value={cacheRtl}>
            <FormControl
              disabled={!disable ? true : false}
              sx={{
                m: 1,
                width: "100%",
                margin: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0000001F",
                },
              }}
              variant="outlined"
            >
              <InputLabel
                htmlFor="description"
                sx={{ fontSize: { xs: 13, sm: 16 } }}
              >
                توضیحات
              </InputLabel>
              <OutlinedInput
                id="description"
                name="description"
                type="text"
                defaultValue={description}
                onBlur={(e) =>
                  handleSetFieldManually("description", e.target.value)
                }
                inputRef={input}
                sx={{
                  fontSize: { xs: 13, sm: 16 },
                }}
                rows={maxWidth600 ? 5 : 1}
                multiline
                endAdornment={
                  <InputAdornment
                    sx={{ display: { xs: "none", sm: "flex" } }}
                    position="end"
                  >
                    <IconButton
                      onClick={() => {
                        handleSetFieldManually("description", "");
                        input.current.value = "";
                      }}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                }
                label="توضیحات"
              />
            </FormControl>
          </CacheProvider>
        </Box>
      </>
    );
  }
);
export default ModalSituation;
