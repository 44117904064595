import { Box, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { setting, BtnIcon, heading } from "../../../styles/calendarTop";
import { useDispatch, useSelector } from "react-redux";
import { updateRTK } from "../../../Store/slice/getErrorsParams";
const DisplayDateString = ({
  dateMutual,
  onSetDateMutual,
  onSetDateDigit,
  updateStore,
  param,
  hasStoreState = false,
  onSetCreatedAt = null,
  onSetReservedAt = null,
}) => {
  const styles = setting();
  const btnStyle = BtnIcon();
  const headingStyle = heading();
  const [dateCounter, setDateCounter] = useState(0);
  const [dateTop, setDateTop] = useState("");
  const dispatch = useDispatch();
  const { activeFilterDate } = useSelector((store) => store.newRequest);
  useEffect(() => {
    const DateFormatter = () => {
      const newDate = dateMutual.setDate(dateMutual.getDate() + dateCounter);
      setDateCounter(0);
      onSetDateMutual(new Date(newDate));
      if (hasStoreState) {
        dispatch(onSetDateMutual(new Date(newDate).toISOString().slice(0, 10)));
      }
      if (onSetCreatedAt || onSetReservedAt) {
        if (activeFilterDate === "submit") {
          dispatch(onSetCreatedAt());
        }

        if (activeFilterDate === "reserve") {
          dispatch(onSetReservedAt());
        }
      }
      if (updateStore) {
        dispatch(
          updateStore({ [param]: new Date(newDate).toISOString().slice(0, 10) })
        );
      }
      updateStore;
      const solarDate = new Intl.DateTimeFormat("fa", {
        year: "numeric",
        day: "2-digit",
        weekday: "long",
        month: "long",
      })
        .format(newDate)
        .replace(",", "")
        .split(" ");
      const solarDateDigits = new Intl.DateTimeFormat("fa", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
      }).format(newDate);

      const day = solarDate.at(-1);
      solarDate.pop();
      const formattingDay = `${day}،`;
      solarDate.push(formattingDay);
      const reformattingSolarDate = solarDate.reverse().join(" ");
      setDateTop(reformattingSolarDate);
      onSetDateDigit(solarDateDigits);
    };

    DateFormatter();
  }, [dateCounter, dateMutual]);
  return (
    <Box component="div" className={styles.root}>
      <IconButton
        aria-label="before"
        className={btnStyle.root}
        onClick={() => setDateCounter((c) => c - 1)}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <Typography component="h5" variant="h5" className={headingStyle.root}>
        {dateTop}
      </Typography>
      <IconButton
        aria-label="before"
        className={`${btnStyle.root} ${btnStyle.btnLeft}`}
        onClick={() => setDateCounter((c) => c + 1)}
      >
        <ArrowBackIosIcon />
      </IconButton>
    </Box>
  );
};

export default DisplayDateString;
