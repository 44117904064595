import { Box, Divider, Grid, Skeleton } from "@mui/material";
import React, { memo } from "react";
const LoadingSkeleton = memo(() => {
  return Array.from(
    {
      length: 9,
    },
    (_, index) => (
      <Grid key={index} item xl={4} lg={4} md={6} sm={6} xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "250px",
            paddingBlock: "14px",
            position: "relative",
            border: "1px solid #0000001F",
            "&::after": {
              content: '""',
              display: "block",
              height: "90%",
              width: "4px",
              background: "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              borderTopLeftRadius: "9999px",
              borderBottomLeftRadius: "9999px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "41.5px",
            }}
          >
            <Box
              sx={{
                paddingInline: {
                  xl: 3,
                  lg: "11px",
                  md: 1,
                  sm: 1,
                  xs: "6px",
                },
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #C2C2C2 0%, #989898 100%);",
                }}
                variant="rounded"
                width={21}
                height={21}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                }}
                variant="text"
                width={95}
                height={10}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                }}
                variant="text"
                width={95}
                height={10}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                }}
                variant="text"
                width={95}
                height={10}
              />
            </Box>
            <Box
              sx={{
                paddingInline: {
                  xl: 3,
                  lg: "11px",
                  md: 1,
                  sm: 1,
                  xs: "6px",
                },
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                }}
                variant="text"
                width={95}
                height={10}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                }}
                variant="text"
                width={95}
                height={10}
              />
            </Box>
            <Box
              sx={{
                paddingInline: {
                  xl: 3,
                  lg: "11px",
                  md: 1,
                  sm: 1,
                  xs: "6px",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Skeleton
                sx={{
                  display: "flex",
                  borderRadius: 1,
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                }}
                variant="rectangular"
                height={32}
                width={95}
              />
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              flexGrow: 1,
              paddingInline: {
                xl: 3,
                lg: "11px",
                md: 1,
                sm: 1,
                xs: "6px",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "15.6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
                width: 1,
              }}
            >
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #C2C2C2 0%, #989898 100%);",
                }}
                variant="rounded"
                width={21}
                height={21}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                  flexGrow: 1,
                }}
                variant="text"
                height={14}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
                width: 1,
              }}
            >
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #C2C2C2 0%, #989898 100%);",
                }}
                variant="rounded"
                width={21}
                height={21}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                  flexGrow: 1,
                }}
                variant="text"
                height={14}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
                width: 1,
              }}
            >
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #C2C2C2 0%, #989898 100%);",
                }}
                variant="rounded"
                width={21}
                height={21}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                  flexGrow: 1,
                }}
                variant="text"
                height={14}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
                width: 1,
              }}
            >
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #C2C2C2 0%, #989898 100%);",
                }}
                variant="rounded"
                width={21}
                height={21}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                  flexGrow: 1,
                }}
                variant="text"
                height={14}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
                width: 1,
              }}
            >
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #C2C2C2 0%, #989898 100%);",
                }}
                variant="rounded"
                width={21}
                height={21}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                  flexGrow: 1,
                }}
                variant="text"
                height={14}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
                width: 1,
              }}
            >
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #C2C2C2 0%, #989898 100%);",
                }}
                variant="rounded"
                width={21}
                height={21}
              />
              <Skeleton
                sx={{
                  background:
                    "linear-gradient(90deg, #D9D9D9 0%, #C1C1C1 100%);",
                  flexGrow: 1,
                }}
                variant="text"
                height={14}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    )
  );
});

export default LoadingSkeleton;
