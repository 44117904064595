import React from "react";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../baseURL";
import createBaseQueryWithReauth from "../../modules/HOC/createBaseQueryWithReauth ";
import toast from "react-hot-toast";
import SuccessHotToast from "../../packages/icons/SuccessHotToast";
import ErrorHotToast from "../../packages/icons/ErrorHotToast";
export const apiContactus = createApi({
  reducerPath: "contactus2",
  baseQuery: createBaseQueryWithReauth(`${baseURL}app/contactus/`),
  tagTypes: ["contactus"],
  endpoints: (builder) => ({
    getAllMessages: builder.query({
      query: ({ resolved, createdAt, currentPage, branch }) =>
        `${branch ? branch : "all"}/?${
          resolved === true
            ? `resolved=${resolved}`
            : resolved === false
            ? "resolved=false"
            : resolved === "all"
            ? ""
            : ""
        }${createdAt ? `&createdAt=${createdAt}` : ""}${
          currentPage ? `&currentPage=${currentPage}` : ""
        }`,
      providesTags: ["contactus"],
    }),

    resolveBatch: builder.mutation({
      query: (body) => ({
        url: `resolveBatch`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["contactus"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          toast.success(`${result?.data?.message}`, {
            style: {
              background: "#4CAF50",
              color: "#fff",
              width: "100%",
              maxWidth: "355px",
              fontSize: "13px",
            },
            duration: 4000,
            position: "top-center",
            icon: <SuccessHotToast />,
          });
        } catch (error) {
          toast.error(`${error.message}`, {
            style: {
              background: "#EF5350",
              color: "#fff",
              fontSize: "13px",
              width: "100%",
              maxWidth: "355px",
            },
            position: "top-center",
            duration: 4000,
            icon: <ErrorHotToast />,
          });
        }
      },
    }),
  }),
});

export const { useGetAllMessagesQuery, useResolveBatchMutation } = apiContactus;
