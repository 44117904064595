import React, { useRef, useState } from "react";
import FilterDate from "./FilterDate";
import { Box } from "@mui/material";
import CalendarRequest from "./CalendarRequest";
import { useDispatch, useSelector } from "react-redux";
import {
  setCreatedAt,
  setDateMutual,
  setIsShowAllDays,
  setReservedAt,
} from "../../Store/slice/sliceNewRequest";
const NewRequestCalendar = ({ isShowReserveDate = true }) => {
  const { dateMutual } = useSelector((store) => store.newRequest);
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [isStableCalendar, setIsStableCalendar] = useState(false);
  const { isRequestDate, isReserveDate, isShowAllDays } = useSelector(
    (store) => store.newRequest
  );
  const calendarEl = useRef();
  const dispatch = useDispatch();
  const handleChange = (param) => {
    dispatch(setDateMutual(param));
    dispatch(setIsShowAllDays());
    if (isRequestDate) {
      dispatch(setCreatedAt());
    }

    if (isReserveDate) {
      dispatch(setReservedAt());
    }
  };
  return (
    <>
      <FilterDate
        onSetIsShowCalendar={setIsShowCalendar}
        onSetIsStableCalendar={setIsStableCalendar}
        calendarEl={calendarEl}
        isShowReserveDate={isShowReserveDate}
      />
      {(isShowCalendar || isStableCalendar) && (
        <Box
          ref={calendarEl}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            transform: isShowAllDays ? "translateY(26%)" : "translateY(11%)",
            zIndex: 500000,
          }}
        >
          <CalendarRequest
            dateMutual={dateMutual}
            handleChange={handleChange}
          />
        </Box>
      )}
    </>
  );
};

export default NewRequestCalendar;
