import React, { useState } from "react";
import {
  FormControl,
  Hidden,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStyles } from "../../styles/SelectFilter";

import { useDispatch } from "react-redux";
import { updateRTK } from "../../Store/slice/getErrorsParams";
const SelectFilter = ({ options }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMatchesLaptopBp = useMediaQuery(theme.breakpoints.down("md"));
  const isMatchesBp = useMediaQuery("(max-width: 1285px)");
  const [value, setValue] = useState(options.at(0));
  const dispatch = useDispatch();
  const isTopic = options.some((option) => option === "sms");
  return (
    <Hidden smDown>
      <FormControl
        className={`${classes.formControl} ${
          isMatchesBp && classes.responsiveFontSize
        } ${isMatchesLaptopBp && classes.responsiveLaptop}`}
      >
        <Select
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            if (isTopic) dispatch(updateRTK({ topic: e.target.value }));
            if (!isTopic)
              dispatch(updateRTK({ criteriaLevel: e.target.value }));
          }}
        >
          {options.map((opt) => (
            <MenuItem key={opt} style={{ width: "100%" }} value={opt}>
              {opt}
              {opt === "normal" ? (
                <span className="newerrors_bullet newerrors_normal"></span>
              ) : opt === "necessary" ? (
                <span className="newerrors_bullet newerrors_necessary"></span>
              ) : opt === "ultimate" ? (
                <span className="newerrors_bullet newerrors_ultimate"></span>
              ) : (
                ""
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Hidden>
  );
};

export default SelectFilter;
