import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import { Styles } from "../../../values/Styles";
import RequestState from "../../common/RequestState";
import AlertDialog from "../../common/AlertDialog";
import PaginationView from "../../common/PaginationView";
import { reaction } from "mobx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { Chip, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import ListItems from "./ListItems";
import CreateCar from "./CreateCar";
import ChangeStatusDialog from "./ChangeStatusDialog";
import { setMomentOffset } from "../../../data/presenter/Utiles";
import CarInquiryModal from "./CarInqueryDialog.js";
let apiClient = null;

@inject("AccountStore")
@inject("CarsStore")
@observer
class Cars extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    let pageTitle = Cars.getPageTitle(
      props.match.params.status,
      props.match.params.type
    );
    this.state = {
      status: props.match.params.status,
      type: props.match.params.type,
      pageTitle: pageTitle,
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      totalPage: 1,
      search: "",
      showUpdateRequest: false,
      page: props.match.params.page ? parseInt(props.match.params.page) : 1,
      message: "",
      showChangeStatusDialog: false,
      changeStatusUpdateIndexRequest: -1,
      agentId: props.CarsStore.agentFilter
        ? props.CarsStore.agentFilter._id
        : null,
      brokerId: props.CarsStore.brokerFilter
        ? props.CarsStore.brokerFilter._id
        : null,
      showStatusDialog: false,
      hasAccessToEditStatus: false,
      carId: "",
      freeRoadToll: "",
      vehicleViolation: "",
      transferTax: "",
      effectsOfTrafficPlan: "",
      annualFees: "",
      governmentCounterOfficeFee: "",
      total: "",
    };

    this.reaction = reaction(
      () => this.props.AccountStore.notificationsUpdateKey,
      () => this.updateInfo()
    );
  }

  componentWillUnmount() {
    if (this.reaction) this.reaction();

    if (this.intervalSearch) {
      clearTimeout(this.intervalSearch);
    }
  }

  updateInfo() {
    this.setState({ showUpdateRequest: true });
  }

  static getPageTitle(status, type) {
    let pageTitle = "خودروها";
    if (status && type) {
      switch (status + "/" + type) {
        case "created/all":
          pageTitle = "ایجاد شده";
          break;
        case "accepted/in_queue":
          pageTitle = "کارشناسی";
          break;
        case "accepted/live":
          pageTitle = "مزایده زنده";
          break;
        case "accepted/live_ended":
          pageTitle = "مزایده پایان یافته";
          break;
        case "accepted/depot":
          pageTitle = "دپو";
          break;
        case "accepted/public":
          pageTitle = "عمومی";
          break;
        case "accepted/negotiating":
          pageTitle = "درحال مذاکره";
          break;
        case "accepted/awaiting_payment":
          pageTitle = "تسویه نشده";
          break;
        case "accepted/payment_done":
          pageTitle = "پرداخت شده";
          break;
        case "accepted/ended":
          pageTitle = "تمام شده";
          break;
        case "deleted/all":
          pageTitle = "حذف شده";
          break;
      }
    }
    return pageTitle;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let brokerId = nextProps.CarsStore.brokerFilter
      ? nextProps.CarsStore.brokerFilter._id
      : null;
    let agentId = nextProps.CarsStore.agentFilter
      ? nextProps.CarsStore.agentFilter._id
      : null;

    if (
      prevState.status !== nextProps.match.params.status ||
      prevState.type !== nextProps.match.params.type ||
      prevState.brokerId !== brokerId ||
      prevState.agentId !== agentId
    ) {
      let pageTitle = Cars.getPageTitle(
        nextProps.match.params.status,
        nextProps.match.params.type
      );

      return {
        page: 1,
        pageTitle: pageTitle,
        status: nextProps.match.params.status,
        type: nextProps.match.params.type,
        agentId: agentId,
        brokerId: brokerId,
        sendRequest: false,
        sendRequestErr: false,
        sendRequestStatus: "",
        data: [],
        totalPage: 1,
        showCreateCarDialog: false,
        isModalOpen: false,
      };
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.status !== this.state.status ||
      prevState.type !== this.state.type
    ) {
      this.getRequests(true);
    }
    if (
      prevState.brokerId !== this.state.brokerId ||
      prevState.agentId !== this.state.agentId
    ) {
      this.getRequests(true);
    }
  }

  componentDidMount() {
    this.getRequests();
  }

  search(t) {
    if (this.intervalSearch) {
      clearTimeout(this.intervalSearch);
    }
    this.intervalSearch = setTimeout(() => {
      this.setState(
        {
          search: t,
          data: [],
          totalPage: 1,
          page: 1,
          message: "",
        },
        () => this.getRequests(true)
      );
    }, 800);
  }

  getRequests(ref) {
    if (this.state.sendRequest) {
      return;
    }

    if (ref) {
      this.state.data = [];
      this.state.page = 1;
      this.state.totalPage = 1;
    }

    this.props.history.replace(
      "/cars/" +
        this.state.status +
        "/" +
        this.state.type +
        "/" +
        this.state.page
    );

    this.setState({
      sendRequest: true,
      sendRequestErr: false,
      changeStatusUpdateIndexRequest: -1,
    });

    let data = { agentId: this.state.agentId, brokerId: this.state.brokerId };

    if (this.state.search && this.state.search.trim() !== "")
      data["search"] = this.state.search;

    let api = ApiHelper.getCars_GET(
      this.state.type,
      this.state.status,
      this.state.page
    );
    if (this.state.status === "all") {
      api = ApiHelper.getAllCarsContent_GET(this.state.page);
    }
    apiClient.get(
      api,
      (error, response) => {
        console.log("response: ", response);
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          setMomentOffset(response.currentServerTime);
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
            totalPage: response.totalPage,
            hasAccessToEditStatus: response.hasAccessToEditStatus,
          });
        }
      },
      data
    );
  }

  openModal = (ID) => {
    this.setState({ isModalOpen: true });
    this.setState({ carId: ID });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };
  renderResult() {
    if (this.state.data.length <= 0) {
      return (
        <div style={{ margin: 10 }}>
          {`لیست خودرو ها  ${this.state.pageTitle} خالی.`}
        </div>
      );
    }
    return (
      <div style={{ ...Styles.containerWrap900, padding: 2 }}>
        {this.state.data.map((item, i) => {
          return (
            <ListItems
              key={item._id}
              brand={item.brand}
              model={item.model}
              image={item.image}
              type={item.type}
              hashCode={item.hashCode}
              createdAt={item.createdAt}
              nameAgent={item.agentId?.name}
              onClickEdit={() => this.edit(item._id)}
              onClickStatus={() =>
                this.setState({ showStatusDialog: item._id })
              }
              statusIndicator={this.state.changeStatusUpdateIndexRequest === i}
              views={item.views}
              code={item.code}
              isLive={
                this.state.type === "live" || this.state.type === "live_ended"
              }
              auctionTime={item.auctionTime}
              totalRequests={item.totalRequests}
              hasAccessToEditStatus={this.state.hasAccessToEditStatus}
              openModal={() => this.openModal(item._id)}
            />
          );
        })}
      </div>
    );
  }

  addNew() {
    this.props.CarsStore.emptyCurrentCar();
    this.setState({ showCreateCarDialog: true });
  }
  edit(id) {
    this.props.CarsStore.emptyCurrentCar();
    if (this.state.status === "all") {
      this.props.history.push("/add_edit_car_html/" + id);
    } else {
      this.props.history.push("/add_edit_car/" + id);
    }
  }

  updateChangeStatus(item) {
    let i = this.state.data.findIndex((t) => t._id === item._id);
    this.setState(
      { showStatusDialog: false, changeStatusUpdateIndexRequest: i },
      () => this.getRequests()
    );
  }

  render() {
    const { isModalOpen } = this.state;
    return (
      <Main
        page={"cars/" + this.state.status + "/" + this.state.type}
        pageName={"خودروها - " + this.state.pageTitle}
        centerAppBarComponent={
          <RequestState
            error={this.state.sendRequestErr}
            retryOnPress={() => this.getRequests()}
            title={"در حال دریافت لیست ..."}
            request={this.state.sendRequest}
          />
        }
        leftAppBarComponent={
          <Fab
            onClick={() => this.addNew()}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            ایجاد
            <AddIcon />
          </Fab>
        }
        context={this.props.history}
      >
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, marginBottom: 10 }}>
            <TextField
              onChange={(t) => this.search(t.target.value)}
              placeholder="جستجو ..."
              type="search"
              defaultValue={this.state.search}
              variant="standard"
              margin={"dense"}
              style={{ maxWidth: 190, margin: 20 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  this.search(ev.target.value);
                }
              }}
            />
          </div>

          {this.props.CarsStore.agentFilter !== null && (
            <Chip
              size="medium"
              color="primary"
              style={{ margin: 20, alignSelf: "flex-start" }}
              label={this.props.CarsStore.agentFilter.name}
              onDelete={() => {
                this.props.CarsStore.agentFilter = null;
                this.getRequests(true);
              }}
            />
          )}

          {this.props.CarsStore.brokerFilter !== null && (
            <Chip
              size="medium"
              color="primary"
              style={{ margin: 20, alignSelf: "flex-start" }}
              label={this.props.CarsStore.brokerFilter.name}
              onDelete={() => {
                this.props.CarsStore.brokerFilter = null;
                this.getRequests(true);
              }}
            />
          )}

          {this.state.showUpdateRequest &&
            (this.state.type === "payment_done" ||
              this.state.type === "awaiting_payment") && (
              <Button
                onClick={() => {
                  this.setState({ showUpdateRequest: false });
                  this.getRequests();
                }}
                style={{ marginBottom: 10 }}
                variant="contained"
                size="small"
                color="secondary"
              >
                خودرو یا درخواست جدید موجود است ! بروزرسانی
              </Button>
            )}

          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            {this.renderResult()}
          </div>
        </div>
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <PaginationView
            total={parseInt(this.state.totalPage)}
            page={parseInt(this.state.page)}
            nextOnPress={() => {
              this.state.page += 1;
              this.getRequests();
            }}
            prevOnPress={() => {
              this.state.page -= 1;
              this.getRequests();
            }}
          />
        </div>
        {this.state.showCreateCarDialog && (
          <CreateCar
            history={this.props.history}
            onClose={() => this.setState({ showCreateCarDialog: false })}
            onSuccess={(item, redirect = true) => {
              this.props.CarsStore.emptyCurrentCar();
              this.setState({ showCreateCarDialog: false });
              this.props.history.push("/add_edit_car/" + item._id);
            }}
          />
        )}
        {this.state.showStatusDialog && (
          <ChangeStatusDialog
            history={this.props.history}
            hasAccessToEditStatus={this.state.hasAccessToEditStatus}
            id={this.state.showStatusDialog}
            onClose={() => this.setState({ showStatusDialog: false })}
            onSuccess={(item) => {
              this.updateChangeStatus(item);
            }}
          />
        )}
        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
        <CarInquiryModal
          isOpen={isModalOpen}
          onClose={this.closeModal}
          carId={this.state.carId}
        />
      </Main>
    );
  }
}

export default Cars;
