import React, { memo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";

import InfoCardRequest from "./InfoCardRequest";

import ModalRequest from "./ModalRequest";
import { useDispatch, useSelector } from "react-redux";
import { setRequestIds } from "../../Store/slice/sliceNewRequest";
const CardRequest = memo(
  ({
    createdAt,
    customerName,
    customerPhone,
    customerReceptionCode,
    customerCar,
    branch,
    responsible,
    clock,
    date,
    status,
    reservedTime,
    id,
    coworkerId,
    createdAtFullDate,
    reserveDatetime,
    description,
    togetway,
    assignCoworkerDate,
    sendingSms,
    reservedDay,
    reservedShortMonth,
  }) => {
    const [openModal, setOpenModal] = useState(false);
    const data = useSelector((store) => store.newRequest?.data);
    const { checkedItems } = useSelector((store) => store.newRequest);
    const hasAccess = data?.[0]?.hasAdminAccess;
    const isCheckedItem = checkedItems.some((item) => item === id);
    const dispatch = useDispatch();
    const handleOpenModal = () => setOpenModal(true);
    const handleChecked = () => {
      dispatch(setRequestIds(id));
    };
    return (
      <>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            height: "auto",
            paddingBlock: "14px",
            position: "relative",
            "&::after": {
              content: '""',
              display: "block",
              height: "90%",
              width: "4px",
              background: `${
                status === "مجهول"
                  ? "#FF9800"
                  : status === "بررسی نشده"
                  ? "#03A9F4"
                  : status === "در انتظار مشتری"
                  ? "#BA68C8"
                  : status === "لغو شده"
                  ? "#EF5350"
                  : status === "تایید شده"
                  ? "#4CAF50"
                  : ""
              }`,
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              borderTopLeftRadius: "9999px",
              borderBottomLeftRadius: "9999px",
            },
          }}
        >
          <Box
            sx={{
              paddingInline: { xl: 3, lg: "11px", md: 1, sm: 1, xs: "6px" },
              display: "flex",
              gap: "14px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "unset",
              }}
              control={
                <Checkbox
                  sx={{ display: hasAccess ? "block" : "none" }}
                  checked={isCheckedItem}
                  onChange={responsible ? handleChecked : null}
                />
              }
              label={
                <Box
                  sx={{
                    "& > *": { fontSize: { xs: 12, sm: 14 } },
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: hasAccess ? "pointer" : "text",
                  }}
                >
                  <Typography variant="body1">{createdAt}</Typography>
                  <Typography component="time" variant="body1">
                    {clock}
                  </Typography>
                  <Typography variant="body1">{date}</Typography>
                  <Typography
                    sx={{
                      marginBlock: 1,
                      fontSize: { xs: 12, sm: 14 },
                      color: `${
                        status === "مجهول"
                          ? "#FF9800"
                          : status === "بررسی نشده"
                          ? "#03A9F4"
                          : status === "در انتظار مشتری"
                          ? "#BA68C8"
                          : status === "لغو شده"
                          ? "#EF5350"
                          : status === "تایید شده"
                          ? "#4CAF50"
                          : ""
                      }`,
                    }}
                    variant="body1"
                  >
                    {status}
                  </Typography>
                  <Typography
                    component="time"
                    variant="body1"
                    sx={{
                      color: `${
                        status === "مجهول"
                          ? "#FF9800"
                          : status === "بررسی نشده"
                          ? "#03A9F4"
                          : status === "در انتظار مشتری"
                          ? "#BA68C8"
                          : status === "لغو شده"
                          ? "#EF5350"
                          : status === "تایید شده"
                          ? "#4CAF50"
                          : ""
                      }`,

                      fontSize: { xs: 12, sm: 14 },
                    }}
                  >
                    {reservedDay}
                  </Typography>
                  <Typography
                    component="time"
                    variant="body1"
                    sx={{
                      color: `${
                        status === "مجهول"
                          ? "#FF9800"
                          : status === "بررسی نشده"
                          ? "#03A9F4"
                          : status === "در انتظار مشتری"
                          ? "#BA68C8"
                          : status === "لغو شده"
                          ? "#EF5350"
                          : status === "تایید شده"
                          ? "#4CAF50"
                          : ""
                      }`,
                      fontSize: { xs: 12, sm: 14 },
                    }}
                  >
                    {reservedTime}
                  </Typography>
                  <Typography
                    component="time"
                    variant="body1"
                    sx={{
                      color: `${
                        status === "مجهول"
                          ? "#FF9800"
                          : status === "بررسی نشده"
                          ? "#03A9F4"
                          : status === "در انتظار مشتری"
                          ? "#BA68C8"
                          : status === "لغو شده"
                          ? "#EF5350"
                          : status === "تایید شده"
                          ? "#4CAF50"
                          : ""
                      }`,
                      fontSize: { xs: 12, sm: 14 },
                    }}
                  >
                    {status === "مجهول" ? "" : reservedShortMonth}
                  </Typography>
                </Box>
              }
            />
            <Button
              variant="contained"
              sx={{
                bgcolor: "primary.light",
                padding: { xs: "5px", md: "6px 17px" },
                fontSize: { xs: 12, sm: 14 },
              }}
              onClick={handleOpenModal}
              disableRipple
            >
              وضعیت
            </Button>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              flexGrow: 1,
              paddingInline: { xl: 3, lg: "11px", md: 1, sm: 1, xs: "6px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              "& > *:not(:nth-child(6))": {
                marginBottom: 2,
              },
            }}
          >
            <InfoCardRequest
              customerName={customerName}
              customerPhone={customerPhone}
              customerReceptionCode={customerReceptionCode}
              customerCar={customerCar}
              branch={branch}
              responsible={responsible}
            />
          </Box>
        </Card>
        {openModal && (
          <ModalRequest
            id={id}
            responsible={responsible}
            open={openModal}
            onSetOpenModal={setOpenModal}
            coworkerId={coworkerId}
            customerName={customerName}
            customerPhone={customerPhone}
            customerReceptionCode={customerReceptionCode}
            customerCar={customerCar}
            branch={branch}
            clock={clock}
            createdAt={createdAt}
            createdAtFullDate={createdAtFullDate}
            reserveDatetime={reserveDatetime}
            description={description}
            togetway={togetway}
            status={status}
            assignCoworkerDate={assignCoworkerDate}
            sendingSms={sendingSms}
          />
        )}
      </>
    );
  }
);

export default CardRequest;
