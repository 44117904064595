const userCondition = [
  {
    value: "accepted",
    label: "تایید",
  },
  {
    value: "blocked",
    label: "مسدود",
  },
  {
    value: "deleted",
    label: "حذف",
  },
];

export default userCondition;
