import React from "react";
//import ImageEditor from "@toast-ui/react-image-editor";
//import "tui-image-editor/dist/tui-image-editor.css";
import styles from "./imageEditor.module.css";

const ImageEditorReact = () => {
  const myTheme = {};
  return (
    <div className={styles.imageEditorContainer}>
      <ImageEditor
        includeUI={{
          loadImage: {
            path: "img/sampleImage.jpg",
            name: "SampleImage",
          },
          theme: myTheme,
          menu: ["shape", "draw"],
          initMenu: "filter",
          uiSize: {
            width: "1000px",
            height: "700px",
          },
          menuBarPosition: "bottom",
        }}
        cssMaxHeight={500}
        cssMaxWidth={700}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70,
        }}
        usageStatistics={true}
      />
    </div>
  );
};

export default ImageEditorReact;
