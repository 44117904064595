import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import CropImageModal from "./CropImageModal";

function SelectImage(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imageFileSRC, setImageFileSRC] = React.useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectFile = (e, useCrop = false) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      if (useCrop) {
        reader.addEventListener(
          "load",
          () => {
            setImageFileSRC(reader.result);
          },
          false
        );
        reader.readAsDataURL(e.target.files[0]);
      } else {
        if (props.base64) {
          reader.addEventListener(
            "load",
            () => {
              props.onSelect(reader.result);
            },
            false
          );
          reader.readAsDataURL(e.target.files[0]);
        } else {
          props.onSelect(e.target.files);
        }
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        marginBottom: 10,
      }}
    >
      {props.image !== "" && (
        <img
          style={{
            width: props.width,
            borderRadius: 5,
            margin: 5,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#d2d2d2",
          }}
          height={"auto"}
          src={props.image}
        />
      )}

      <Button
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        color={props.colorButton}
        onClick={handleClick}
        component="label"
        style={{ width: props.width, margin: 5 }}
      >
        {props.textButton}
        <Icon style={{ marginRight: 5 }}>{props.iconButton}</Icon>
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component="label" onClick={handleClose}>
          انتخاب فایل
          <input
            onChange={onSelectFile}
            accept="image/*"
            multiple
            type="file"
            style={{ display: "none" }}
          />
        </MenuItem>
        <MenuItem component="label" onClick={handleClose}>
          برش دهنده
          <input
            onChange={(e) => onSelectFile(e, true)}
            accept="image/*"
            type="file"
            style={{ display: "none" }}
          />
        </MenuItem>
      </Menu>

      {imageFileSRC !== "" && (
        <CropImageModal
          circularCrop={false}
          src={imageFileSRC}
          aspect={props.aspect}
          onClose={(image) => {
            if (image) {
              props.onSelect(props.base64 ? image.base64Image : image.blob);
            }
            setImageFileSRC("");
          }}
        />
      )}
    </div>
  );
}

SelectImage.defaultProps = {
  image: "",
  width: 220,
  base64: false,
  textButton: "انتخاب",
  colorButton: "default",
  iconButton: "add_a_photo",
  aspect: 1,
};

SelectImage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  image: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  width: PropTypes.number,
  base64: PropTypes.bool,
  textButton: PropTypes.string,
  colorButton: PropTypes.string,
  iconButton: PropTypes.string,
  aspect: PropTypes.number,
};
export default SelectImage;
