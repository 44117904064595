import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { CircularProgress, FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { convertNumberToEN } from "../../../../language/NumberConverter";
import { ApiClient, ApiHelper } from "../../../../data/remote";
import { ReceiptTwoTone } from "@material-ui/icons";

const DialogReceiveCode = ({
  setShowDialogReceiveCode,
  infoDispatch,
  setReceptionCodeEnteredByUser,
  dispatchRequestState,
  sendRequest,
  setInfoFromCodeReq,
  getterFunction = (receptionCode) => {
    ApiHelper.getContract(receptionCode);
  },
}) => {
  const [receptionCode, setReceptionCode] = useState("");
  let apiClient = ApiClient.getInstance();

  const handleClose = () => {
    setShowDialogReceiveCode();
  };

  const registerAllInfoFromServerToContract = (response) => {
    let seller = response.userRequest || {};
    let carInfo = response?.userRequest?.sellCarInfo || {};
    let carAuction = response?.carAuction || {};
    let contractFound = response?.contractFound || {};
    delete contractFound._id;
    delete carInfo?._id;
    delete seller?._id;
    delete carAuction?._id;
    let carPlaqueString = "";
    if (carAuction && carAuction?.p_number?.length) {
      carPlaqueString =
        JSON.parse(carAuction?.p_number)?.c +
        " " +
        JSON.parse(carAuction?.p_number)?.d3 +
        " " +
        JSON.parse(carAuction?.p_number)?.al +
        " " +
        JSON.parse(carAuction?.p_number)?.d2;
    }

    infoDispatch({
      copy: true,
      payload: {
        ...carAuction,
        ...seller,
        ...carInfo,
        ...contractFound,
        carBrand: contractFound.carBrand?.length
          ? contractFound.carBrand
          : carAuction?.brand,
        carKind: contractFound.carKind?.length
          ? contractFound.carKind
          : carAuction?.model,
        carTreim: contractFound.carTreim?.length
          ? contractFound.carTreim
          : carAuction?.option,
        carProductionYear: contractFound.carProductionYear?.length
          ? contractFound.carProductionYear
          : carInfo?.year,
        carKm: contractFound.carKm?.length
          ? contractFound.carKm
          : carAuction?.km,
        carFuelType: contractFound.carFuelType?.length
          ? contractFound.carFuelType
          : carAuction?.carFuelType,
        carBodyColor: contractFound.carBodyColor?.length
          ? contractFound.carBodyColor
          : carAuction?.details?.bodyColor,
        carFuelType: contractFound.carFuelType?.length
          ? contractFound.carFuelType
          : carAuction?.details?.fuelType,
        carMotorCode: contractFound.carMotorCode?.length
          ? contractFound.carMotorCode
          : carAuction?.e_number,
        carShasiCode: contractFound.carShasiCode?.length
          ? contractFound.carShasiCode
          : carAuction?.c_number,
        carVIN: contractFound?.carVIN?.length
          ? contractFound.carVIN
          : carAuction?.carVIN,
        carPlqueCode: contractFound.carPlqueCode?.length
          ? contractFound.carPlqueCode
          : carPlaqueString,
        sellerName: contractFound?.sellerName?.length
          ? contractFound?.sellerName
          : seller?.name,
        sellerPhoneNumber: contractFound.sellerPhoneNumber?.length
          ? contractFound.sellerPhoneNumber
          : seller?.phone,
      },
    });
  };

  const submit = () => {
    dispatchRequestState({ type: "pending" });
    setInfoFromCodeReq({ loading: true });
    apiClient.get(
      getterFunction(receptionCode),
      (error, response) => {
        if (error) {
          dispatchRequestState({
            type: "throwError",
            message: response.message,
          });
          setInfoFromCodeReq({ loading: false });
        } else {
          dispatchRequestState({
            type: "throwSuccess",
            message: response.message,
          });
          setShowDialogReceiveCode(false);
          registerAllInfoFromServerToContract(response);
          setInfoFromCodeReq({ loading: false });
        }
      },
      { receptionCode: receptionCode }
    );
  };
  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ maxWidth: 500, minWidth: 300, padding: 5 }}>
        <div style={{ position: "relative" }}>
          <Grid item>
            <FormControl
              style={{ flex: 1, margin: 10, minWidth: 220, marginBottom: 20 }}
              label="کد پذیرش"
            >
              <TextField
                onChange={(e) => {
                  setReceptionCode(e.target.value.toString().replace(/,/g, ""));
                  setReceptionCodeEnteredByUser(
                    e.target.value.toString().replace(/,/g, "")
                  );
                }}
                placeholder="کد پذیرش"
                type="text"
                defaultValue={receptionCode}
                label="کد پذیرش"
                fullWidth={true}
                inputProps={{
                  style: {
                    fontSize: 14,
                    direction: "ltr",
                  },
                  maxLength: 22,
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value)
                    : "";
                }}
              />
            </FormControl>
          </Grid>
        </div>
      </DialogContent>
      {sendRequest ? (
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={25} />
        </DialogActions>
      ) : (
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Grid item lg={8} style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => submit()} color="primary">
              ثبت
            </Button>
            <Button onClick={() => handleClose()} color="secondary">
              بستن
            </Button>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogReceiveCode;
