import { observable, action, get } from "mobx";

export default class AppRouter {
  @observable history = [];

  pushHistory(url) {
    if (this.history.length < 2) {
      this.history.push(url);
    } else {
      this.history = [this.history[1], url];
    }
  }

  canGoBack() {
    if (this.history.length < 2) {
      return false;
    }

    return this.history[0].pathname !== this.history[1].pathname;
  }

  getLastHistoryPath() {
    let historyLength = this.history.length;
    if (historyLength > 0) {
      let h = this.history[historyLength - 1];
      if (h.pathname) return h.pathname;
    }
    return null;
  }
}
