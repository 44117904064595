import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { memo, useRef, useState } from "react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import Close from "../../packages/icons/Close";
import { useSelector } from "react-redux";
import LoadingBalls from "../../gifs/loadingTripleDot.gif";
const ModalContentInfo = ({
  showInfo,
  customerName,
  customerCar,
  customerPhone,
  customerReceptionCode,
  createdAtFullDate,
  clock,
  handleChange,
  handleBlur,
  handleSetFieldManually,
}) => {
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, stylisRTLPlugin],
  });

  const { isLoadingTakeOver } = useSelector((store) => store.newRequest);
  const input = useRef();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <CacheProvider value={cacheRtl}>
        <FormControl
          disabled={!showInfo}
          sx={{
            m: 1,
            width: "100%",
            margin: 0,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#0000001F",
            },
          }}
          variant="outlined"
        >
          <InputLabel
            htmlFor="outlined-adornment-inputField"
            sx={{ fontSize: { xs: 13, sm: 16 } }}
          >
            نام مشتری
          </InputLabel>
          <OutlinedInput
            id="name"
            type="text"
            defaultValue={customerName}
            inputRef={input}
            onBlur={(e) => {
              handleSetFieldManually("name", e.target.value);
            }}
            sx={{ fontSize: { xs: 13, sm: 16 } }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={!showInfo}
                  onClick={(e) => {
                    handleSetFieldManually("name", "");
                    input.current.value = "";
                  }}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
            }
            label="نام مشتری"
          />
        </FormControl>
      </CacheProvider>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
        >
          تلفن
        </Typography>
        {isLoadingTakeOver ? (
          <Box component="img" sx={{ height: 30 }} src={LoadingBalls} />
        ) : (
          <Typography
            variant="body1"
            sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
          >
            {showInfo ? customerPhone : "نامشخص"}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
        >
          کد پذیرش
        </Typography>
        {isLoadingTakeOver ? (
          <Box component="img" sx={{ height: 30 }} src={LoadingBalls} />
        ) : (
          <Typography
            variant="body1"
            sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
          >
            {showInfo ? customerReceptionCode : "نامشخص"}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
        >
          کارکرد
        </Typography>
        {isLoadingTakeOver ? (
          <Box component="img" sx={{ height: 30 }} src={LoadingBalls} />
        ) : (
          <Typography
            variant="body1"
            sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
          >
            {showInfo ? (
              <Typography
                variant="body1"
                sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
              >
                کیلومتر: {`${customerCar.km}`}
              </Typography>
            ) : (
              "نامشخص"
            )}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
        >
          خودرو
        </Typography>
        {isLoadingTakeOver ? (
          <Box component="img" sx={{ height: 30 }} src={LoadingBalls} />
        ) : (
          <Typography
            variant="body1"
            sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
          >
            {showInfo ? (
              <Typography
                variant="body1"
                sx={{
                  color: "text.primary",
                  fontSize: { xs: 13, sm: 16 },
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Typography sx={{ fontSize: { xs: 13, sm: 16 } }}>
                  {customerCar.brand}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: "primary.main", borderWidth: "1px" }}
                />
                <Typography sx={{ fontSize: { xs: 13, sm: 16 } }}>
                  {customerCar.model}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: "primary.main", borderWidth: "1px" }}
                />
                <Typography sx={{ fontSize: { xs: 13, sm: 16 } }}>
                  {customerCar.year}
                </Typography>
              </Typography>
            ) : (
              "نامشخص"
            )}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
        >
          تاریخ درخواست
        </Typography>
        {isLoadingTakeOver ? (
          <Box component="img" sx={{ height: 30 }} src={LoadingBalls} />
        ) : (
          <Typography
            variant="body1"
            sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
          >
            {showInfo ? `${clock} - ${createdAtFullDate}` : "نامشخص"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(ModalContentInfo);
