import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    width: "97%",
    marginTop: "40px",
    marginBottom: "80px",
  },

  responsiveStyle: {
    width: "100%",
    marginTop: "40px",
    marginBottom: "80px",
  },

  containerMobile: {
    marginTop: "unset",
  },
});
