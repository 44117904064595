import moment from "moment-jalaali";
export const timeOneHourAhead = () => {
  const formatDate = (Datetime) => {
    if (process.env.NODE_ENV === "production") {
      const newDatetime = moment(Datetime).add(1, "hour").format("HH:mm");
      return newDatetime;
    } else {
      const newDatetime = moment(Datetime).format("HH:mm");
      return newDatetime;
    }
  };
  return { formatDate };
};
//In production mode, when the request is registered in the panel
//the request time was delayed by one hour, that's why we wrote this hook
