import React from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

const Agents = ({
  agenName,
  agentId,
  activeCheckboxAgent,
  handleAgentCheckbox,
}) => {
  return (
    <>
      <Grid item sm={4} xs={6} lg={2}>
        <Checkbox
          checked={activeCheckboxAgent === agentId}
          onChange={() => handleAgentCheckbox(agentId)}
        />
        {agenName}
      </Grid>
    </>
  );
};
export default Agents;
