import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import SortDown from "../../packages/icons/SortDown";
import SortUp from "../../packages/icons/SortUp";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import { useDispatch, useSelector } from "react-redux";
import {
  changeRecentQuery,
  setActiveStatus,
  setStatusParams,
} from "../../Store/slice/sliceNewRequest";
import CoworkerActivity from "./CoworkerActivity";

const SortFilter = () => {
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, stylisRTLPlugin],
  });
  const { recent, activeStatus, data } = useSelector(
    (store) => store.newRequest
  );
  const hasAccess = data?.[0]?.hasAdminAccess;
  const dispatch = useDispatch();
  const situations = [
    { title: "همه", value: "all" },
    { title: "مجهول", value: "unknown" },
    { title: "بررسی نشده", value: "notChecked" },
    { title: "در انتظار مشتری", value: "waiting" },
  ];
  const theme = useTheme();
  const maxWidthLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [newest, setNewest] = useState(recent);

  const handleRecentChange = () => {
    setNewest((currentState) => !currentState);
    dispatch(changeRecentQuery(!newest));
  };

  const handleChangeStatus = (e) => {
    dispatch(setActiveStatus(e.target.value));
    dispatch(setStatusParams(e.target.value));
  };

  const activeSelectStatus =
    activeStatus === "inProgressFlow"
      ? ""
      : activeStatus === "confirmed"
      ? ""
      : activeStatus === "canceled"
      ? ""
      : activeStatus;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: hasAccess ? 1 : 2,
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="contained"
        startIcon={newest ? <SortUp /> : <SortDown />}
        sx={{
          justifyContent: "space-around",
          bgcolor: "#fff",
          color: "info.dark",
          width: { xl: "150px", lg: "120px", sm: "150px", xs: "150px" },
          p: "7px 10px",
          "&:hover": { bgcolor: "#fff", boxShadow: 2 },
          boxShadow: 2,
          "& .MuiButton-startIcon": {
            marginLeft: { lg: "5px", xl: "-4px" },
          },
        }}
        onClick={handleRecentChange}
      >
        {newest ? "جدیدترین" : "قدیمی ترین"}
      </Button>

      {hasAccess && <CoworkerActivity />}

      <CacheProvider value={cacheRtl}>
        <FormControl
          sx={{
            display: maxWidthLg && "none",
            borderRadius: 1,
            boxShadow: 2,
            border: "unset !important",
            width: { xl: "168px", lg: "120px" },
            ".MuiFormLabel-root.MuiInputLabel-root": {
              color: "info.dark",
              top: "-7px",
              "&.Mui-focused": {
                top: "0 !important",
              },
            },
            "& .MuiInputBase-root .MuiSvgIcon-root": {
              color: "info.dark",
            },
          }}
        >
          <InputLabel id="situations">وضعیت</InputLabel>
          <Select
            // multiple
            value={activeSelectStatus}
            label="وضعیت"
            // MenuProps={menuProps}
            sx={{
              bgcolor: "#fff",
              ".MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                height: "unset",
                padding: "8px 16px",
              },
            }}
            onChange={handleChangeStatus}
          >
            {situations.map((situation, index) => (
              <MenuItem
                key={index}
                value={`${situation.value}`}
                disableRipple
                disableTouchRipple
              >
                {situation.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CacheProvider>
    </Box>
  );
};

export default SortFilter;
