import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import SelectAllCheckbox from "../../components/common/SelectAllCheckbox/SelectAllCheckbox";
import PaginationPanel from "../../components/common/Pagination/PaginationPanel";
import ButtonContactUs from "../../components/main/contact-us/ButtonContactUs";
import Done from "../../packages/icons/Done";
import ChartContainer from "./ChartContainer";
import CalendarReuseable from "../../components/common/CalendarReuseable/CalendarReuseable";
import {
  useGetAllMessagesQuery,
  useResolveBatchMutation,
} from "../../Store/callApi/apiContactus";
import { useDispatch, useSelector } from "react-redux";
import { cleanIds, pushIds, setPage } from "../../Store/slice/sliceContactUs";
import { Toaster } from "react-hot-toast";
import ToasterError from "../../components/common/HotToast/ToasterError";
const MainSection = () => {
  const dispatch = useDispatch();
  const maxWidth340 = useMediaQuery("(max-width: 340px)");
  const {
    createdAt,
    resolved,
    branch,
    currentPage,
    checkedItems,
    isSelectAll,
  } = useSelector((state) => state.contactUs);
  const {
    data: messages,
    isSuccess,
    isLoading,
    error,
  } = useGetAllMessagesQuery({
    createdAt,
    resolved,
    branch,
    currentPage,
  });
  const [resolveFn, { isLoading: resolving }] = useResolveBatchMutation();
  const hasIds = checkedItems.length === 0;
  const handleSelectAll = () => {
    if (!isSelectAll) {
      messages?.data.map((item) => dispatch(pushIds(item._id)));
    }

    if (isSelectAll) {
      dispatch(cleanIds());
    }
  };

  const handleResolving = async () => {
    await resolveFn({ resolved: true, contactUsMessagesIDs: checkedItems });
  };

  return (
    <Container maxWidth="xl">
      <ToasterError />
      <Grid container spacing={2}>
        <Grid item lg={12} xl={12} xs={12}>
          <Box
            sx={[
              {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 3,
                marginRight: { xs: "17px", sm: "17px", md: "35px" },
              },
              maxWidth340 && {
                "& .MuiFormControlLabel-root span": {
                  fontSize: 13,
                },
                marginRight: "unset !important",
                "& button": {
                  fontSize: 13,
                  paddingInline: "6px",
                },

                "& .MuiButtonBase-root .MuiButton-startIcon": {
                  marginLeft: "5px !important",
                },
                "& .MuiButtonBase-root .MuiButton-startIcon svg": {
                  width: 10,
                },
              },
            ]}
          >
            <SelectAllCheckbox
              type="primary"
              onChange={handleSelectAll}
              checked={isSelectAll}
            />
            <ButtonContactUs
              type="outlined"
              label="بررسی شده"
              startIcon={<Done />}
              onClick={handleResolving}
              disabled={resolving || hasIds}
            />
          </Box>
          <ChartContainer />
          <PaginationPanel
            marginTop="52px"
            paddingBottom="none"
            onSetPageStore={setPage}
            count={isSuccess && messages?.totalPage}
            currentPage={currentPage}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainSection;
