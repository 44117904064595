exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".coworkers_BoxDialogConfirm__1-FHq {\n  max-width: 1000px;\n  min-width: 500px;\n  padding: 20px;\n}\n.coworkers_textDialogConfirm__2_V7I {\n  color: #d58508;\n  font-weight: bold;\n}\n.coworkers_warningDialogConfirm__JeDEn {\n  color: #ed5935;\n  display: flex;\n  align-items: end;\n}\n.coworkers_iconDialogConfirm__2pcmo {\n  display: flex;\n  margin-top: 10px;\n  max-width: 100px;\n}\n.coworkers_BoxBtnDialogConfirm__3CpDa {\n  display: flex;\n  max-width: 100px;\n  padding-bottom: 20px;\n}\n.coworkers_textEmptyAdmins__ZMC5N {\n  margin-right: 10px;\n}\n.coworkers_notSubPermission__1c5D7 {\n  display: flex;\n  align-items: center;\n  color: #ed5935;\n}\n", ""]);

// Exports
exports.locals = {
	"BoxDialogConfirm": "coworkers_BoxDialogConfirm__1-FHq",
	"textDialogConfirm": "coworkers_textDialogConfirm__2_V7I",
	"warningDialogConfirm": "coworkers_warningDialogConfirm__JeDEn",
	"iconDialogConfirm": "coworkers_iconDialogConfirm__2pcmo",
	"BoxBtnDialogConfirm": "coworkers_BoxBtnDialogConfirm__3CpDa",
	"textEmptyAdmins": "coworkers_textEmptyAdmins__ZMC5N",
	"notSubPermission": "coworkers_notSubPermission__1c5D7"
};