import React from "react";
const Watch = () => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1667 7.99992C11.1667 9.86676 11.1667 10.8002 10.8034 11.5132C10.4838 12.1404 9.97384 12.6504 9.34664 12.9699C8.6336 13.3333 7.70018 13.3333 5.83333 13.3333C3.96649 13.3333 3.03307 13.3333 2.32003 12.9699C1.69282 12.6504 1.18289 12.1404 0.863312 11.5132C0.5 10.8002 0.5 9.86676 0.5 7.99992C0.5 6.13308 0.5 5.19966 0.863312 4.48662C1.18289 3.85941 1.69282 3.34947 2.32003 3.0299C3.03307 2.66659 3.96649 2.66659 5.83333 2.66659C7.70018 2.66659 8.6336 2.66659 9.34664 3.0299C9.97384 3.34947 10.4838 3.85941 10.8034 4.48662C11.1667 5.19966 11.1667 6.13308 11.1667 7.99992ZM11.1667 7.99992H12.5M5.83334 5.33325V7.66659C5.83334 8.21887 6.28105 8.66659 6.83333 8.66659V8.66659H7.83333M3.16667 1.33325H8.5M3.16667 14.6666H8.5"
        stroke=""
        stroke-opacity="0.6"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Watch;
