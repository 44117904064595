import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { selectOption } from "../../../values/selectOption";
import styles from "./requests.module.css";

const TogetwaySelect = ({ handlerStringTogetway, togetway }) => {
  const [otherThings, setOtherThings] = useState();
  const [stateTogetway, setStateTogetway] = useState();

  const handlerOtherTogetway = (getTogetway) => {
    setOtherThings(getTogetway);
    handlerStringTogetway(getTogetway);
  };

  const handlerTogetway = (getTogetway) => {
    handlerStringTogetway(getTogetway);
    setStateTogetway(getTogetway);
    setOtherThings("");
  };

  useEffect(() => {
    const findTogetway = selectOption.find((item) => item.name === togetway);
    if (findTogetway?.name) {
      setStateTogetway(togetway);
    } else {
      setStateTogetway("موارد دیگر");
      setOtherThings(togetway);
    }
  }, []);
  
  const handlerOtherThings = () => {
    return (
      <div className={styles.dialogTimeFieldStyle}>
        <TextField
          value={otherThings}
          onChange={(e) => handlerOtherTogetway(e.target.value)}
          style={{ margin: 10, flex: 1 }}
          label={" موارد دیگر"}
          placeholder="لطفا موارد دیگر را وارد کنید..."
          variant="outlined"
          margin={"dense"}
          inputProps={{ style: { fontSize: 15 }, maxLength: 400 }}
        />
      </div>
    );
  };

  return (
    <>
      <div className={styles.dialogTimeFieldStyle}>
        <FormControl
          size="small"
          variant="outlined"
          style={{ margin: 10, flex: 1 }}
        >
          <InputLabel htmlFor="togetway1">طریقه آشنایی با شرکت</InputLabel>
          <Select
            native
            value={otherThings ? "موارد دیگر" : stateTogetway}
            onChange={(e) => handlerTogetway(e.target.value)}
            label="طریقه آشنایی با شرکت"
            inputProps={{
              name: "togetway1",
              id: "togetway1",
            }}
          >
            <option aria-label="None" value="" />
            {selectOption.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      {stateTogetway === "موارد دیگر" && handlerOtherThings()}
    </>
  );
};
export default TogetwaySelect;
