import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import InfoListSubPermissions from "./../../../features/subPermissions/InfoListSubPermissions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Warning from "@material-ui/icons/Warning";
import panelPermissions from "./permissions";
import style from "./coworkers.module.css";

const DialogUpdateConfirm = (props) => {
  const { submit, HandlerCloseAlert, permissions, name, subpermissions } =
    props;

  const [nameSubPermissions, setNameSubPermissions] = useState([]);

  const commonNames = panelPermissions.reduce((result, access) => {
    const matchingObject = permissions.find((id) => access.id == id);
    if (matchingObject) {
      result.push(access.label);
    }
    return result;
  }, []);

  useEffect(() => {
    const resultArray = subpermissions?.map((number) => {
      const foundPermission = InfoListSubPermissions.find(
        (permission) => permission.permissionCode === number
      );
      return foundPermission ? foundPermission.label : null;
    });
    setNameSubPermissions(resultArray);
  }, [subpermissions]);

  const RenderPermissions = () => {
    if (permissions.length) {
      return (
        <p style={{ lineHeight: 2 }}>
          همکار مورد نظر <span className={style.textDialogConfirm}>{name}</span>{" "}
          دارای دسترسی به{" "}
          <span className={style.textDialogConfirm}>
            {commonNames.join(" - ")}
          </span>{" "}
          هستند
        </p>
      );
    } else {
      return (
        <p>
          همکار مورد نظر دارای{" "}
          <span className={style.textDialogConfirm}>هیچ</span> دسترسی نمی باشد
        </p>
      );
    }
  };
  const RenderSubPermissions = () => {
    if (nameSubPermissions?.length > 0) {
      return (
        <p>
          و همچنین دارای سطح فرمان{" "}
          <span className={style.textDialogConfirm}>
            {nameSubPermissions.join(" - ")}
          </span>{" "}
          میباشد
        </p>
      );
    } else {
      return (
        <p>
          و همچنین دارای <span className={style.textDialogConfirm}>هیچ</span>{" "}
          سطح فرمانی نمی باشد.
        </p>
      );
    }
  };

  return (
    <Dialog open={true}>
      <DialogContent className={style.BoxDialogConfirm}>
        <h4>توجه:</h4>
        {RenderPermissions()}
        {RenderSubPermissions()}
        <h4 className={style.warningDialogConfirm}>
          <Warning className={style.iconDialogConfirm} />
          آیا این اختیارات مورد تایید است؟
        </h4>
        <Divider />
        <div className={style.BoxBtnDialogConfirm}>
          <Button
            variant="contained"
            style={{ margin: 5 }}
            fullWidth
            type="submit"
            color={"primary"}
            onClick={() => {
              submit();
              HandlerCloseAlert(false);
            }}
          >
            تایید
          </Button>
          <Button
            variant="outlined"
            style={{ margin: 5 }}
            fullWidth
            onClick={() => {
              HandlerCloseAlert(false);
            }}
            color={"secondary"}
          >
            لغو
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogUpdateConfirm;
