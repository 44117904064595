import React from "react";
const Close = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0.000244141C4.47 0.000244141 0 4.47024 0 10.0002C0 15.5302 4.47 20.0002 10 20.0002C15.53 20.0002 20 15.5302 20 10.0002C20 4.47024 15.53 0.000244141 10 0.000244141ZM10 18.0002C5.59 18.0002 2 14.4102 2 10.0002C2 5.59024 5.59 2.00024 10 2.00024C14.41 2.00024 18 5.59024 18 10.0002C18 14.4102 14.41 18.0002 10 18.0002ZM10 8.59024L13.59 5.00024L15 6.41024L11.41 10.0002L15 13.5902L13.59 15.0002L10 11.4102L6.41 15.0002L5 13.5902L8.59 10.0002L5 6.41024L6.41 5.00024L10 8.59024Z"
        fill="black"
        fill-opacity="0.6"
      />
    </svg>
  );
};

export default Close;
