import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import { Styles } from "../../../values/Styles";
import RequestState from "../../common/RequestState";
import AlertDialog from "../../common/AlertDialog";
import AddEditDialog from "./AddEditDialog";
import { CircularProgress, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { List, arrayMove } from "react-movable";
import Icon from "@material-ui/core/Icon";

let apiClient = null;

@inject("AccountStore")
@inject("LocationsStore")
@observer
class Locations extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    this.state = {
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      message: "",
      alertDialogQuestionMessage: "",
      addEditDialog: false,
      deleteRequestIndex: -1,
    };

    this.alertFunction = null;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    if (this.state.sendRequest) {
      return;
    }

    this.setState({ sendRequest: true, sendRequestErr: false });

    apiClient.get(ApiHelper.getLocations_GET, (error, response) => {
      if (error) {
        this.setState({
          sendRequest: false,
          sendRequestErr: true,
          message: response ? response.message : "",
        });
      } else {
        this.props.LocationsStore.setLocations(response.records);
        this.setState({ sendRequest: false, sendRequestErr: false });
      }
    });
  }

  remove(id) {
    let index = this.props.LocationsStore.locations.findIndex(
      (t) => t._id === id
    );
    this.alertFunction = this.removeRequest.bind(this, id, index);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به حذف شهر هستید ؟",
    });
  }

  removeRequest(id, index) {
    if (this.state.deleteRequestIndex !== -1) return;

    this.setState({ deleteRequestIndex: id });

    apiClient.get(ApiHelper.removeLocation_GET(id), (error, response) => {
      if (error) {
        this.setState({ deleteRequestIndex: -1, message: "حذف شهر ناموفق" });
      } else {
        if (response.success) {
          this.props.LocationsStore.locations.splice(index, 1);
          this.setState({ deleteRequestIndex: -1 });
        } else {
          this.setState({ deleteRequestIndex: -1, message: "حذف شهر ناموفق" });
        }
      }
    });
  }

  updateLocationsPositionRequest(locations) {
    let data = [];
    locations.slice().forEach((v) => data.push(v._id));
    apiClient.post(
      ApiHelper.updateLocationsPosition_POST,
      (error, response) => {},
      { locations: data }
    );
  }

  renderResult() {
    if (this.props.LocationsStore.locations.length <= 0) {
      return <div style={{ margin: 10 }}>{`لیست شهرها خالی.`}</div>;
    }
    return (
      <TableContainer>
        <p style={{ margin: 10, color: "#642b2b", fontSize: 12 }}>
          {" "}
          * هر شهر به تمامی رابط ها متصل است حذف آن ممکن است در عملکر برنامه
          اختلال ایجاد کند{" "}
        </p>

        <List
          values={this.props.LocationsStore.locations}
          onChange={({ oldIndex, newIndex }) => {
            this.props.LocationsStore.locations = arrayMove(
              this.props.LocationsStore.locations,
              oldIndex,
              newIndex
            );
            this.updateLocationsPositionRequest(
              this.props.LocationsStore.locations
            );
          }}
          renderList={({ children, props }) => (
            <Table
              style={{
                border: "1px solid #d2d2d2",
                minWidth: 200,
                maxWidth: 350,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: "#000", fontWeight: 600 }}>
                    نام شهر
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody {...props}>{children}</TableBody>
            </Table>
          )}
          renderItem={({ value, props }) => (
            <TableRow {...props}>
              <TableCell style={{ width: "50%", cursor: "move" }} align="left">
                {" "}
                <Icon
                  style={{
                    fontSize: 18,
                    margin: "5px -5px -5px 5px",
                    cursor: "move",
                  }}
                >
                  drag_indicator
                </Icon>{" "}
                {value.location}{" "}
              </TableCell>
              <TableCell style={{ width: "50%" }} align="right">
                <Button
                  onClick={() => this.edit(value)}
                  size="small"
                  variant="text"
                  color="primary"
                >
                  ویرایش
                </Button>

                <Button
                  onClick={() => this.remove(value._id)}
                  size="small"
                  variant="text"
                  color="secondary"
                >
                  {this.state.deleteRequestIndex === value._id ? (
                    <CircularProgress size={16} style={{ margin: 0 }} />
                  ) : (
                    "حذف"
                  )}
                </Button>
              </TableCell>
            </TableRow>
          )}
        />
      </TableContainer>
    );
  }

  edit(item) {
    this.props.LocationsStore.setCurrent(item);
    this.setState({ addEditDialog: true });
  }

  addNew() {
    this.props.LocationsStore.emptyCurrent();
    this.setState({ addEditDialog: true });
  }

  updateItem(item) {
    this.props.LocationsStore.updateItem(item);
    this.setState({ addEditDialog: false });
  }

  render() {
    return (
      <Main
        page={"locations"}
        pageName={"شهرها"}
        leftAppBarComponent={
          <Fab
            onClick={() => this.addNew()}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            ایجاد
            <AddIcon />
          </Fab>
        }
        centerAppBarComponent={
          <RequestState
            error={this.state.sendRequestErr}
            retryOnPress={() => this.getData()}
            title={"در حال دریافت لیست ..."}
            request={this.state.sendRequest}
          />
        }
        context={this.props.history}
      >
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            {this.renderResult()}
          </div>
        </div>

        {this.state.addEditDialog && (
          <AddEditDialog
            onClose={() => this.setState({ addEditDialog: false })}
            onSuccess={(item) => {
              this.updateItem(item);
            }}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}

        {this.state.alertDialogQuestionMessage !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) this.alertFunction();
              this.alertFunction = null;
              this.setState({ alertDialogQuestionMessage: "" });
            }}
            type="alert"
            question={true}
            description={this.state.alertDialogQuestionMessage}
          />
        )}
      </Main>
    );
  }
}

export default Locations;
