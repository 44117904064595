import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { ApiClient, ApiHelper } from "../../../data/remote";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import AlertDialog from "../../common/AlertDialog";
import { inject, observer } from "mobx-react";
import { convertNumberToEN } from "../../../language/NumberConverter";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import ConfirmDialog from "../../common/ConfirmDialog";

var moment = require("moment-jalaali");

let apiClient = null;

@inject("AccountStore")
@inject("CarsStore")
@observer
class CreateCar extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();

    this.state = {
      status: "",
      msg: "",
      sendRequest: false,
      message: "",
      showUserInfo: "",
      brand: "",
      brandIndex: -1,
      model: "",
      modelIndex: -1,
      year: "",
      yearIndex: -1,
      option: "",
      getCategoriesErr: false,
      userReceptionCodeSearch: "",
      userReceptionCodeInfo: null,
      showInfoByUserReceptionCode: false,
    };

    this.initInputs();
  }

  initInputs() {
    this.state.brand = this.props.CarsStore.currentCar.brand;
    this.state.brandIndex = this.props.CarsStore.categories.findIndex(
      (t) => t.brand === this.props.CarsStore.currentCar.brand
    );

    this.state.modelIndex =
      this.state.brandIndex >= 0
        ? this.props.CarsStore.categories[
            this.state.brandIndex
          ].models.findIndex(
            (t) => t.model === this.props.CarsStore.currentCar.model
          )
        : -1;
    this.state.model =
      this.state.modelIndex >= 0 ? this.props.CarsStore.currentCar.model : "";

    this.state.yearIndex =
      this.state.modelIndex >= 0
        ? this.props.CarsStore.categories[this.state.brandIndex].models[
            this.state.modelIndex
          ].types.findIndex(
            (t) => t.year === this.props.CarsStore.currentCar.year
          )
        : -1;
    this.state.year =
      this.state.yearIndex >= 0 ? this.props.CarsStore.currentCar.year : "";

    this.state.optionIndex =
      this.state.yearIndex >= 0
        ? this.props.CarsStore.categories[this.state.brandIndex].models[
            this.state.modelIndex
          ].types[this.state.yearIndex].options.findIndex(
            (t) => t.option === this.props.CarsStore.currentCar.option
          )
        : -1;
    this.state.option =
      this.state.optionIndex >= 0 ? this.props.CarsStore.currentCar.option : "";
  }

  handleClose() {
    this.props.onClose();
  }

  submit() {
    if (this.state.sendRequest) return;

    if (this.state.brandIndex < 0) {
      this.setState({ message: "لطفا برند را مشخص کنید" });
      return;
    }
    if (this.state.modelIndex < 0) {
      this.setState({ message: "لطفا مدل را مشخص کنید" });
      return;
    }
    if (this.state.yearIndex < 0) {
      this.setState({ message: "لطفا سال را مشخص کنید" });
      return;
    }

    if (
      this.state.option === "" &&
      this.props.CarsStore.categories[this.state.brandIndex].models[
        this.state.modelIndex
      ].types[this.state.yearIndex].options.length > 0
    ) {
      this.setState({ message: "لطفا آپشن را مشخص کنید" });
      return;
    }

    if (this.props.CarsStore.currentCar._id) {
      this.props.CarsStore.currentCar.brand = this.state.brand;
      this.props.CarsStore.currentCar.model = this.state.model;
      this.props.CarsStore.currentCar.year = this.state.year;
      this.props.CarsStore.currentCar.option = this.state.option;
      this.props.onSuccess(this.props.CarsStore.currentCar);
      return;
    }

    this.setState({ sendRequest: true, inputWarn: 0 });

    apiClient.post(
      ApiHelper.createCar_POST,
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false }, () =>
            this.props.onSuccess(response.data)
          );
        }
      },
      {
        brand: this.state.brand,
        model: this.state.model,
        year: this.state.year,
        option: this.state.option,
      }
    );
  }

  submitWithUserReceptionCode() {
    if (!this.state.userReceptionCodeSearch) {
      return;
    }
    this.setState({ sendRequest: true });
    let data = { userReceptionCode: this.state.userReceptionCodeSearch };
    apiClient.post(
      ApiHelper.getCarInfoByUserReceptionCode,
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({
            showInfoByUserReceptionCode: true,
            userReceptionCodeInfo: response.data,
            sendRequest: false,
          });
        }
      },
      data
    );
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories(ref) {
    if (!ref && this.props.CarsStore.categories.length > 0) return;

    this.setState({ getCategoriesErr: false });

    apiClient.get(ApiHelper.getCategories_GET, (error, response) => {
      if (error) {
        this.setState({
          getCategoriesErr: this.props.CarsStore.categories.length <= 0 && !ref,
        });
      } else {
        this.props.CarsStore.setCategories(response.records);
        this.initInputs();
        this.setState({ getCategoriesErr: false });
      }
    });
  }

  submitFinalSubmission() {
    this.setState({ sendRequest: true, inputWarn: 0 });

    apiClient.post(
      ApiHelper.createCar_POST,
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState(
            { sendRequest: false, showInfoByUserReceptionCode: false },
            () => this.props.onSuccess(response.data, false)
          );
        }
      },
      {
        brand: this.state.userReceptionCodeInfo?.sellCarInfo?.brand,
        model: this.state.userReceptionCodeInfo?.sellCarInfo?.model,
        year: this.state.userReceptionCodeInfo?.sellCarInfo?.year,
        option: this.state.userReceptionCodeInfo?.sellCarInfo?.option,
        carType: 1,
        userReceptionCodeSearch: this.state?.userReceptionCodeSearch,
      }
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ maxWidth: 500, minWidth: 300, padding: 5 }}>
            <div style={{ position: "relative" }}>
              <Grid item>
                <FormControl
                  style={{
                    flex: 1,
                    margin: 10,
                    minWidth: 220,
                    marginBottom: 20,
                  }}
                  label="کد پذیرش"
                >
                  <TextField
                    onChange={(e) =>
                      (this.state.userReceptionCodeSearch = e.target.value
                        .toString()
                        .replace(/,/g, ""))
                    }
                    placeholder="کد پذیرش"
                    type="text"
                    defaultValue={this.state.userReceptionCodeSearch}
                    label="کد پذیرش"
                    fullWidth={true}
                    inputProps={{
                      style: {
                        fontSize: 14,
                        direction: "ltr",
                      },
                      maxLength: 22,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        ? convertNumberToEN(e.target.value)
                        : "";
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                {this.state.getCategoriesErr ? (
                  <Button
                    onClick={() => this.getCategories(true)}
                    color="secondary"
                    variant="outlined"
                    size="small"
                  >
                    خطا در دریافت دسته ها !
                  </Button>
                ) : (
                  <FormControl
                    style={{ flex: 1, margin: 10, minWidth: 220 }}
                    label="برند"
                  >
                    <Select
                      value={this.state.brand === "" ? "0" : this.state.brand}
                      variant="outlined"
                      margin={"dense"}
                      style={{ fontSize: 13 }}
                      onChange={(e) =>
                        this.setState({
                          brand: e.target.value === "0" ? "" : e.target.value,
                          model: "",
                          modelIndex: -1,
                          year: "",
                          yearIndex: -1,
                          option: "",
                          brandIndex: this.props.CarsStore.categories.findIndex(
                            (t) => t.brand === e.target.value
                          ),
                        })
                      }
                    >
                      <MenuItem style={{ fontSize: 13 }} value={"0"}>
                        انتخاب برند
                      </MenuItem>

                      {this.props.CarsStore.categories.map((v, index) => (
                        <MenuItem
                          key={index}
                          style={{ fontSize: 13 }}
                          value={v.brand}
                        >
                          {v.brand}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <IconButton onClick={() => this.getCategories(true)}>
                  {this.props.CarsStore.categories.length <= 0 ? (
                    <CircularProgress size={20} style={{ margin: 5 }} />
                  ) : (
                    <Icon>sync</Icon>
                  )}
                </IconButton>
              </Grid>

              {this.state.brandIndex >= 0 && (
                <Grid item>
                  <FormControl
                    style={{ flex: 1, margin: 10, minWidth: 220 }}
                    label="مدل"
                  >
                    <Select
                      value={this.state.model === "" ? "0" : this.state.model}
                      variant="outlined"
                      margin={"dense"}
                      style={{ fontSize: 13 }}
                      onChange={(e) =>
                        this.setState({
                          model: e.target.value === "0" ? "" : e.target.value,
                          year: "",
                          yearIndex: -1,
                          option: "",
                          modelIndex: this.props.CarsStore.categories[
                            this.state.brandIndex
                          ].models.findIndex((t) => t.model === e.target.value),
                        })
                      }
                    >
                      <MenuItem style={{ fontSize: 13 }} value={"0"}>
                        انتخاب مدل
                      </MenuItem>

                      {this.props.CarsStore.categories[
                        this.state.brandIndex
                      ].models.map((v, index) => (
                        <MenuItem
                          key={index}
                          style={{ fontSize: 13 }}
                          value={v.model}
                        >
                          {v.model}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {this.state.modelIndex >= 0 && (
                <Grid item>
                  <FormControl
                    style={{ flex: 1, margin: 10, minWidth: 220 }}
                    label="سال"
                  >
                    <Select
                      value={this.state.year === "" ? "0" : this.state.year}
                      variant="outlined"
                      margin={"dense"}
                      style={{ fontSize: 13 }}
                      onChange={(e) =>
                        this.setState({
                          year: e.target.value === "0" ? "" : e.target.value,
                          option: "",
                          yearIndex: this.props.CarsStore.categories[
                            this.state.brandIndex
                          ].models[this.state.modelIndex].types.findIndex(
                            (t) => t.year === e.target.value
                          ),
                        })
                      }
                    >
                      <MenuItem style={{ fontSize: 13 }} value={"0"}>
                        انتخاب سال
                      </MenuItem>
                      {this.props.CarsStore.categories[
                        this.state.brandIndex
                      ].models[this.state.modelIndex].types.map((v, index) => (
                        <MenuItem
                          key={index}
                          style={{ fontSize: 13 }}
                          value={v.year}
                        >
                          {v.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {this.state.yearIndex >= 0 &&
                this.props.CarsStore.categories[this.state.brandIndex].models[
                  this.state.modelIndex
                ].types[this.state.yearIndex].options.length > 0 && (
                  <Grid item>
                    <FormControl
                      style={{ flex: 1, margin: 10, minWidth: 220 }}
                      label="سال"
                    >
                      <Select
                        value={
                          this.state.option === "" ? "0" : this.state.option
                        }
                        variant="outlined"
                        margin={"dense"}
                        style={{ fontSize: 13 }}
                        onChange={(e) =>
                          this.setState({
                            option:
                              e.target.value === "0" ? "" : e.target.value,
                          })
                        }
                      >
                        <MenuItem style={{ fontSize: 13 }} value={"0"}>
                          انتخاب اپشن
                        </MenuItem>

                        {this.props.CarsStore.categories[
                          this.state.brandIndex
                        ].models[this.state.modelIndex].types[
                          this.state.yearIndex
                        ].options.map((v, index) => (
                          <MenuItem
                            key={index}
                            style={{ fontSize: 13 }}
                            value={v.option}
                          >
                            {v.option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
            </div>
          </DialogContent>

          {this.state.sendRequest ? (
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={25} />
            </DialogActions>
          ) : (
            <DialogActions style={{ justifyContent: "space-between" }}>
              <Grid lg={4} item>
                <Button
                  onClick={() => this.submitWithUserReceptionCode()}
                  color="secondary"
                >
                  ثبت با کد پذیرش
                </Button>
              </Grid>
              <Grid
                item
                lg={8}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button onClick={() => this.submit()} color="primary">
                  {this.props.CarsStore.currentCar._id ? "ویرایش" : "ثبت"}
                </Button>

                <Button onClick={() => this.handleClose()} color="secondary">
                  بستن
                </Button>
              </Grid>
            </DialogActions>
          )}
        </Dialog>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
        {this.state.showInfoByUserReceptionCode ? (
          <ConfirmDialog
            handleConfirmDialogClose={() =>
              this.setState({ showInfoByUserReceptionCode: false })
            }
            finalSubmission={() => this.submitFinalSubmission()}
            sendRequest={this.state.sendRequest}
          >
            <div>
              <ul>
                <li style={listStyle}>
                  <b>نام فروشنده : </b>
                  {this.state.userReceptionCodeInfo?.name || "وارد نشده است"}
                </li>
                <li style={listStyle}>
                  <b>برند :</b>{" "}
                  {this.state.userReceptionCodeInfo?.sellCarInfo?.brand ||
                    "وارد نشده است"}
                </li>
                <li style={listStyle}>
                  <b>مدل :</b>{" "}
                  {this.state.userReceptionCodeInfo?.sellCarInfo?.model ||
                    "وارد نشده است"}
                </li>
                <li style={listStyle}>
                  <b> سال ساخت :</b>{" "}
                  {this.state.userReceptionCodeInfo?.sellCarInfo?.year ||
                    "وارد نشده است"}
                </li>
                <li style={listStyle}>
                  <b> تیپ :</b>{" "}
                  {this.state.userReceptionCodeInfo?.sellCarInfo?.option ||
                    "وارد نشده است"}
                </li>
                <li style={listStyle}>
                  <b>کارکرد :</b>{" "}
                  {this.state.userReceptionCodeInfo?.sellCarInfo?.km ||
                    "وارد نشده است"}
                </li>
                <li style={listStyle}>
                  <b>کد پذیرش جستجو شده :</b>{" "}
                  {this.state.userReceptionCodeInfo?.userReceptionCode ||
                    "وارد نشده است"}
                </li>
              </ul>
            </div>
          </ConfirmDialog>
        ) : null}
      </div>
    );
  }
}

CreateCar.defultProps = {};

CreateCar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  history: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

const listStyle = {
  margin: "1rem 0rem",
};

export default CreateCar;
