import { Box, Typography } from "@mui/material";
import React from "react";
const ModalContent = ({ content }) => {
  return (
    <Box
      sx={{
        my: 2,
        mx: 3,
        overflow: "scroll",
        "&::-webkit-scrollbar": {
          width: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#1E88E5",
          borderRadius: "30px",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: 14,
          color: "#00000099",
          textAlign: "justify",
          px: 1,
        }}
        variant="body1"
      >
        {content}
      </Typography>
    </Box>
  );
};

export default ModalContent;
