import { Box, Button, Typography } from "@mui/material";
import Calendar from "../../packages/icons/Calendar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCreatedAt,
  removeShowCalendar,
  setActiveFilterDate,
  setMutualDate,
  setShowCalendar,
  setUnActiveFilterDate,
} from "../../Store/slice/sliceContactUs";
import CalendarReuseable from "../../components/common/CalendarReuseable/CalendarReuseable";

const FilterDate = () => {
  const { activeFilterDate, showCalendar, mutualDate } = useSelector(
    (state) => state.contactUs
  );
  const dispatch = useDispatch();
  const handleDateChange = (newDate) => {
    dispatch(setMutualDate(newDate));
    dispatch(setActiveFilterDate());
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          justifyContent: "space-between",
          marginRight: "auto",
        }}
      >
        <Typography variant="body1">فیلتر برساس: </Typography>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
          onMouseEnter={() => dispatch(setShowCalendar())}
          onMouseLeave={() => dispatch(removeShowCalendar())}
        >
          <Button
            startIcon={<Calendar />}
            sx={{
              color:
                activeFilterDate === "submit"
                  ? "text.primary"
                  : "text.secondary",
              bgcolor: activeFilterDate === "submit" ? "#2196F314" : "unset",
              borderRadius: 25,
              "& .MuiButton-startIcon svg path": {
                fill: activeFilterDate === "submit" ? "#2196F3" : "#00000099",
              },
              "& .MuiButton-startIcon": {
                marginLeft: "unset",
                marginRight: "unset",
              },
              gap: "10px",
              marginRight: "auto",
              justifyContent: "flex-end",
            }}
            variant="outlined"
            onClickCapture={() => dispatch(setActiveFilterDate())}
          >
            تاریخ ثبت درخواست
          </Button>
          {
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position:
                  activeFilterDate && !showCalendar ? "relative" : "absolute",
                left: 0,
                top: "100%",
                bgcolor: "#fafafa",
                zIndex: 10,
              }}
            >
              {showCalendar && (
                <CalendarReuseable
                  onChange={handleDateChange}
                  dateMutual={mutualDate}
                />
              )}
            </Box>
          }
        </Box>
        <Button
          disabled={!activeFilterDate}
          disableElevation
          variant="contained"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "11px",
            color: "#EF5350",
            bgcolor: "unset",
            "&:hover": {
              bgcolor: "#fafafa",
            },
            justifyContent: "center",
          }}
          onClick={() => {
            dispatch(setUnActiveFilterDate());
            dispatch(setMutualDate(new Date().toISOString().slice(0, 10)));
            dispatch(removeCreatedAt());
          }}
        >
          <Box component="span" sx={{ fontSize: 20 }}>
            &times;
          </Box>
          حذف فیلتر تاریخ
        </Button>
      </Box>
    </Box>
  );
};

export default FilterDate;
