import { Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import SelectAllCheckbox from "../../components/common/SelectAllCheckbox/SelectAllCheckbox";
import Done from "../../packages/icons/Done";
import Cancel from "../../packages/icons/Cancel";
import loadingSpinnerDot from "../../gifs/loadingSpinnerDot.gif";
import { useChangeRequestStatusMutation } from "../../Store/callApi/request";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyRequestIds,
  selectAllCheckbox,
} from "../../Store/slice/sliceNewRequest";
import SendRequestToCoworker from "../../features/new-request-sell/SendRequestToCoworker";
import NumberRequests from "./NumberRequests";
const StatusAssignment = ({ type = "sell" }) => {
  const maxWidth363 = useMediaQuery("(max-width: 363px)");
  const maxWidth333 = useMediaQuery("(max-width: 333px)");
  const maxWidth315 = useMediaQuery("(max-width: 315px)");
  const maxWidth285 = useMediaQuery("(max-width: 285px)");
  const dispatch = useDispatch();
  const { checkedItems, data, isSelectAll, activeStatus } = useSelector(
    (store) => store.newRequest
  );

  const hasAdminAccess = data?.[0]?.hasAdminAccess;

  const [changeRequstFn, { isLoading, isError, isSuccess }] =
    useChangeRequestStatusMutation();
  const handleConfirmed = async () => {
    await changeRequstFn({ status: "confirmed", request_ids: checkedItems });
    dispatch(emptyRequestIds());
  };

  const handleWaiting = async () => {
    await changeRequstFn({ status: "waiting", request_ids: checkedItems });
    dispatch(emptyRequestIds());
  };

  const handleCaneled = async () => {
    await changeRequstFn({ status: "canceled", request_ids: checkedItems });
    dispatch(emptyRequestIds());
  };

  const handleSelectAllCheckboxes = () => {
    dispatch(selectAllCheckbox(data?.at(0)?.requests));
    if (isSelectAll) dispatch(emptyRequestIds());
  };

  if (!hasAdminAccess) return null;

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt="12px"
        sx={[
          {
            "& .MuiFormControl-root .MuiFormControlLabel-root .MuiTypography-root":
              { fontSize: { xs: 12, sm: 14 } },
          },
          maxWidth363 && {
            "& .MuiFormControl-root .MuiFormControlLabel-root .MuiButtonBase-root":
              {
                paddingLeft: "3px",
              },
          },

          maxWidth333 && {
            "& .MuiFormControl-root .MuiFormControlLabel-root .MuiTypography-root":
              {
                fontSize: "11.5px",
              },
          },
        ]}
      >
        <SelectAllCheckbox
          type="primary"
          checked={isSelectAll}
          onChange={handleSelectAllCheckboxes}
        />
        <Box
          sx={[
            { display: "flex", alignItems: "center", gap: { sm: 2, xs: 1 } },
            maxWidth363 && { gap: "4px !important" },
          ]}
        >
          {type === "sell" && <SendRequestToCoworker />}
          {(activeStatus === "inProgressFlow" ||
            activeStatus === "notChecked" ||
            activeStatus === "unknown" ||
            activeStatus === "waiting" ||
            activeStatus === "") && (
            <Button
              variant="outlined"
              startIcon={
                isLoading ? (
                  <Box
                    component="img"
                    src={loadingSpinnerDot}
                    alt="spinner"
                    sx={{ width: 24, height: 24 }}
                  />
                ) : (
                  <Done />
                )
              }
              onClick={handleConfirmed}
              sx={[
                {
                  borderRadius: 25,
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: "4px", sm: "11px" },
                  fontSize: { xs: 12, sm: 14 },
                  padding: { xs: "5px 10px", sm: "5px 15px" },
                  marginRight: { xs: "3px", sm: "8px" },
                  color: "text.primary",
                  "& .MuiButton-startIcon": {
                    marginLeft: "unset",
                    marginRight: { xs: "0px", sm: "8px" },
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    color: "#00000099",
                  },
                },
                maxWidth363 && {
                  padding: "5px 7px !important",
                  "& .MuiButton-startIcon": {
                    width: "9px",
                    height: "10px",
                  },
                },

                maxWidth333 && {
                  padding: "5px !important",
                  fontSize: "11.5px !important",
                },

                maxWidth315 && {
                  "& .MuiButton-startIcon": {
                    display: "none",
                  },
                  color: "success.light",
                },
              ]}
              disabled={checkedItems.length === 0 || isLoading}
            >
              {isLoading ? "پردازش.." : "تائیدکردن"}
            </Button>
          )}
          {activeStatus === "confirmed" && (
            <Button
              variant="outlined"
              startIcon={
                isLoading ? (
                  <Box
                    component="img"
                    src={loadingSpinnerDot}
                    alt="spinner"
                    sx={{ width: 24, height: 24 }}
                  />
                ) : (
                  <Done />
                )
              }
              onClick={handleWaiting}
              sx={[
                {
                  borderRadius: 25,
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: "4px", sm: "11px" },
                  fontSize: { xs: 12, sm: 14 },
                  padding: { xs: "5px 10px", sm: "5px 15px" },
                  marginRight: { xs: "3px", sm: "8px" },
                  color: "text.primary",
                  "& .MuiButton-startIcon": {
                    marginLeft: "unset",
                    marginRight: { xs: "0px", sm: "8px" },
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    color: "#00000099",
                  },
                },
                maxWidth363 && {
                  padding: "5px 7px !important",
                  "& .MuiButton-startIcon": {
                    width: "9px",
                    height: "10px",
                  },
                },

                maxWidth333 && {
                  padding: "5px !important",
                  fontSize: "11.5px !important",
                },

                maxWidth315 && {
                  "& .MuiButton-startIcon": {
                    display: "none",
                  },
                  color: "success.light",
                },
              ]}
              disabled={checkedItems.length === 0 || isLoading}
            >
              {isLoading
                ? "پردازش.."
                : maxWidth285
                ? "درانتظار"
                : "درانتظارمشتری"}
            </Button>
          )}
          {activeStatus !== "canceled" && (
            <Button
              variant="outlined"
              startIcon={
                isLoading ? (
                  <Box
                    component="img"
                    src={loadingSpinnerDot}
                    alt="spinner"
                    sx={{ width: 24, height: 24 }}
                  />
                ) : (
                  <Cancel />
                )
              }
              onClick={handleCaneled}
              sx={[
                {
                  borderRadius: 25,
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: "4px", sm: "11px" },
                  fontSize: { xs: 12, sm: 14 },
                  padding: { xs: "5px 10px", sm: "5px 15px" },
                  color: "text.primary",
                  "& .MuiButton-startIcon": {
                    marginLeft: "unset",
                    marginRight: { xs: "0px", sm: "8px" },
                    width: { xs: "12px", sm: "14px" },
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    color: "#00000099",
                  },
                },
                maxWidth363 && {
                  padding: "5px 7px !important",
                  "& .MuiButton-startIcon": {
                    width: "9px",
                    height: "10px",
                  },
                },
                maxWidth333 && {
                  padding: "5px !important",
                  fontSize: "11.5px !important",
                },
                maxWidth315 && {
                  "& .MuiButton-startIcon": {
                    display: "none",
                  },
                  color: "error.light",
                },
              ]}
              disabled={checkedItems.length === 0 || isLoading}
            >
              {isLoading ? "پردازش.." : "لغوکردن"}
            </Button>
          )}
        </Box>
      </Box>
      <NumberRequests />
    </>
  );
};

export default StatusAssignment;
