import { observable, action, get } from "mobx";

class CoworkerModel {
  _id = null;
  name = "";
  username = "";
  phone = "";
  password = "";
  type = "";
  createdAt = "";
  updatedAt = "";
  permissions = [];
}

export default class Coworkers {
  @observable current = new CoworkerModel();
  @observable changeCoworkerItem = null;
  @observable coworkers = [];

  @action setCoWorkers(coworkers) {
    this.coworkers = coworkers;
  }

  @action setCurrent(item) {
    this.current = JSON.parse(JSON.stringify(item));
  }

  @action emptyCurrent() {
    this.current = new CoworkerModel();
  }

  @get getCurrent() {
    return JSON.parse(JSON.stringify(this.current));
  }
}
