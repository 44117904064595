import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

const selectFontSize = {
  xs: 13,
  sm: 16,
};

const dotStyleInSelect = {
  content: '""',
  display: "block",
  width: "5.82px",
  height: "5.82px",
  borderRadius: "50%",
  marginRight: "7.09px",
};

const style = {
  fontSize: selectFontSize,
  "&:before": dotStyleInSelect,
};

const selectHeight = {
  xs: 40,
  sm: 52,
};

const selectStyle = {
  fontSize: selectFontSize,
  height: selectHeight,
};

const formControlWidth = {
  xs: 130,
  sm: 168,
};

const notchedOutline = {
  border: "unset",
  boxShadow: 2,
};

const formControlStyle = {
  width: formControlWidth,
  "& .MuiOutlinedInput-notchedOutline": notchedOutline,
  "& > *": {
    transition: "unset",
    animation: "unset",
  },
};

const options = [
  { title: "notChecked", value: "بررسی نشده" },
  { title: "unknown", value: "مجهول" },
  { title: "waiting", value: "در انتظار مشتری" },
  { title: "confirmed", value: "تایید شده" },
  { title: "canceled", value: "لغو شده" },
];
const StatusDropDown = memo(
  ({ disable, situation, handleChange, handleSetFieldManually }) => {
    const cacheRtl = createCache({
      key: "muirtl",
      stylisPlugins: [prefixer, stylisRTLPlugin],
    });
    const [openStatus, setOpenStatus] = useState(false);
    return (
      <CacheProvider value={cacheRtl}>
        <FormControl disabled={!disable} sx={formControlStyle}>
          <Select
            labelId="situation"
            name="situation"
            id="situation"
            value={situation}
            open={openStatus}
            onOpen={() => setOpenStatus(true)}
            onClose={() => setOpenStatus(false)}
            onChange={handleChange}
            renderValue={(value) => (
              <Box
                className={`status_new_Request_${value}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7.09px",
                  "&::before": {
                    content: '""',
                    display: "block",
                    width: "5.82px",
                    height: "5.82px",
                    borderRadius: "50%",
                  },
                }}
              >
                {value === "confirmed"
                  ? "تائید شده"
                  : value === "canceled"
                  ? "لغو شده"
                  : value === "waiting"
                  ? "درانتظارمشتری"
                  : value === "notChecked"
                  ? "بررسی نشده"
                  : value === "unknown"
                  ? "مجهول"
                  : ""}
              </Box>
            )}
            sx={selectStyle}
          >
            {options.map((option, index) => (
              <MenuItem
                className={`status_new_Request_${option.title}`}
                key={index}
                value={option.title}
                disabled={index === 1}
                sx={style}
                disableRipple
                disableTouchRipple
              >
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CacheProvider>
    );
  }
);

export default StatusDropDown;
