import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import { Colors } from "../../values/Colors";

const RequestState = ({
  style,
  request = false,
  error = false,
  color = "#ffe5e5",
  title = "درحال دریافت اطلاعات ...",
  retryOnPress,
  errorTitle = "خطا در دریافت اطلاعات !",
  retryTitle = "تلاش مجدد",
}) => {
  return (
    <Grow in={error || request}>
      <div style={style}>
        {error && (
          <div style={{ ...styles.error }}>
            <p style={{ color: Colors.colorAccent2 }}>{errorTitle}</p>
            <div style={{ width: 5 }} />
            <Button variant="outlined" onClick={retryOnPress} color={"primary"}>
              {retryTitle}
            </Button>
          </div>
        )}
        {request && (
          <div style={styles.request}>
            <LinearProgress style={{ width: 150, height: 4, marginTop: 5 }} />
            <p style={{ margin: 0, paddingTop: 5 }}>{title}</p>
          </div>
        )}
      </div>
    </Grow>
  );
};

export default RequestState;

const styles = {
  request: {
    display: "flex",
    marginBottom: 10,
    marginTop: 10,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    boxShadow: "3px 4px 5px 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    background: "#fff",
  },
  error: {
    display: "flex",
    marginBottom: 10,
    marginTop: 10,
    justifySelf: "center",

    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 5,
    paddingLeft: 5,
    boxShadow: "3px 4px 5px 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
  },
};
