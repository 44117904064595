import React, { useReducer, useState } from "react";
import Main from "../../../common/UserMain";
import styles from "./reporting.module.css";
import ListRequests from "./listRequests";
import { SkeletonMap } from "../../../../values/SkeletonMap";
import { Statement } from "../../../../values/Statement";
import DayFilter from "../../../../features/filters/dayFilter";
import AgentFilter from "../../../../features/filters/agentFilter";
import TypeFilter from "../../../../features/filters/typeFilter";
import TypeDateFilter from "../../../../features/filters/typeDateFilter";
import { ApiClient, ApiHelper } from "../../../../data/remote";
import Skeleton from "@material-ui/lab/Skeleton";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Divider,
  Switch,
  Box,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useEffect } from "react";

const initialValue = {
  fromDay: "",
  toDay: "",
  reserveOrCreate: false,
  agentIds: [],
  type: "",
};
const reducer = (filterData, action) => {
  switch (action.type) {
    case "fromDay":
      return { ...filterData, fromDay: action.payload };
    case "toDay":
      return { ...filterData, toDay: action.payload };
    case "reserveOrCreate":
      return { ...filterData, reserveOrCreate: action.payload };
    case "agentIds":
      return { ...filterData, agentIds: action.payload };
    case "TypeFilter":
      return { ...filterData, type: action.payload };
    default:
      return filterData;
  }
};

const ReportingRequests = () => {
  let apiClient = ApiClient.getInstance();
  const [filterData, dispatch] = useReducer(reducer, initialValue);
  const [isLoading, setIsLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  const handlerDayFilter = (fromDay, toDay) => {
    dispatch({ type: "fromDay", payload: fromDay });
    dispatch({ type: "toDay", payload: toDay });
  };
  const handlertypeDate = (typeDate) => {
    dispatch({ type: "reserveOrCreate", payload: typeDate });
  };
  const handlerAgent = (listIDAgents) => {
    dispatch({ type: "agentIds", payload: listIDAgents });
  };
  const handlerTypeFilter = (typeFilter) => {
    dispatch({ type: "TypeFilter", payload: typeFilter });
  };

  useEffect(() => {
    setIsLoading(true);
    apiClient.post(
      ApiHelper.userRequestsReport_POST,
      (error, response) => {
        if (error) {
        } else {
          if (response.success) {
            setResponseData(response.result);
            setIsLoading(false);
          }
        }
      },
      filterData
    );
  }, [filterData]);

  return (
    <Main page={"requests/Reporting"} pageName={"آمارگیری از درخواست ها"}>
      <Accordion>
        <AccordionSummary
          className={styles.titleAccordionStyle}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h4 style={{ margin: 0 }}> فیلتر ها</h4>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid xs={12} item>
              <Divider />
              <p>
                کاربر گرامی جست و جوی تاریخ بر اساس{" "}
                <span className={styles.sendRequestStyle}>
                  {filterData.TypeDateFilter}
                </span>{" "}
                درخواست می باشد.
              </p>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Box className={styles.searchBoxStyle}>
                <DayFilter handlerDay={handlerDayFilter} />
              </Box>
            </Grid>
            <Grid item xs={12} className={styles.rowBoxStyle}>
              <Divider />
              <TypeDateFilter
                handlertypeDate={handlertypeDate}
                title={Statement.titleTypeDateFilter}
              />
            </Grid>
            <Grid item xs={12} className={styles.rowBoxStyle}>
              <Divider />
              <AgentFilter
                handlerAgent={handlerAgent}
                title={Statement.titleAgentFilter}
              />
            </Grid>
            <Grid item xs={12} className={styles.rowBoxStyle}>
              <Divider />
              <TypeFilter typeFilter={handlerTypeFilter} title="نوع درخواست" />
            </Grid>
            <Grid item xs={12} className={styles.cityBoxStyle}>
              <Divider />
              <h4>شهر</h4>
              تهران
              <Switch disabled checked={true} name="checkedA" />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Paper className={styles.paperStyle}>
        <Grid className={styles.itemRequestStyle} container spacing={2}>
          {isLoading
            ? SkeletonMap.map((item) => (
                <Skeleton className={styles.SkeletonStyle} animation="wave" />
              ))
            : responseData.map((item) => (
                <ListRequests
                  key={item.key}
                  number={item.number}
                  requestName={item.name}
                  path={item.path}
                />
              ))}
        </Grid>
      </Paper>
    </Main>
  );
};
export default ReportingRequests;
