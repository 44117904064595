import React from "react";
import {
  Box,
  Grid,
  Button,
  Divider,
  IconButton,
  Badge,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { useCopyToClipboardLinks } from "../../../../Hooks/copy_to_clipboard/useCopyToClipboardLinks";
import DeleteExclusiveLink from "../delete_exclusive_link/deleteExclusiveLink";
import { useDispatch } from "react-redux";
import { setEditLink } from "../../../../Store/slice/sliceListInfoLinks";
import SnackBar from "../../../common/SnackBar";

import style from "./infoListLink.module.css";

const InfoListLink = ({ infoLink, OpenDialogEdit }) => {
  const { color, name, visited, path } = infoLink;
  const [isCopied, copyToClipboardLinks] = useCopyToClipboardLinks();

  const dispatch = useDispatch();
  const linkToCopy = "https://nikbakhtkhodro.com/custome?&identification=";
  const handlerEditLink = () => {
    OpenDialogEdit(true);
    dispatch(setEditLink(infoLink));
  };

  return (
    <>
      <Grid item xs={12} className={style.rowInfoTags}>
        <Box className={style.addEdidTagsStyle}>
          <Button className={style.ButtonStyle} style={{ background: color }}>
            {name}
          </Button>
          <Box>
            <IconButton aria-label="delete">
              <DeleteExclusiveLink infoLink={infoLink} />
            </IconButton>
            <IconButton aria-label="edit">
              <Tooltip title="ویرایش">
                <EditIcon size="small" onClick={handlerEditLink} />
              </Tooltip>
            </IconButton>
          </Box>
          <Button className={style.detailsButton}>جزئیات</Button>
          <Tooltip title={linkToCopy + path} placement="top">
            <Box className={style.copyLinkStyle}>
              <FileCopyOutlinedIcon
                onClick={() => copyToClipboardLinks(path)}
              />
              {isCopied && (
                <span className={style.textCopyLink}>لینک کپی شد!</span>
              )}
            </Box>
          </Tooltip>

          <Box className={style.visitedStyle}>
            <Badge badgeContent={visited} showZero style={{ color: color }}>
              <Visibility />
            </Badge>
            تعداد بازدید
          </Box>
        </Box>
      </Grid>
      <Divider />
      <SnackBar
        variant={"info"}
        message={`لینک درخواست ${name} کپی شد`}
        open={isCopied}
      />
    </>
  );
};

export default InfoListLink;
