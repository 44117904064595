import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import User from "../../packages/icons/User";
import Phone from "../../packages/icons/Phone";
import Profile from "../../packages/icons/Profile";
import Car from "../../packages/icons/Car";
import { useCopyToClipboard } from "../../Hooks/copy_to_clipboard/useCopyToClipboard";
import SnackBar from "../../components/common/SnackBar";
import CarCode from "../../packages/icons/CarCode";
const InfoCardRequest = ({
  customerName,
  customerPhone,
  customerReceptionCode,
  responsible,
  customerCar,
  carCode,
}) => {
  const [isCopied, copyToClipboard] = useCopyToClipboard(false);
  const maxWidth1350px = useMediaQuery("(max-width: 1350px)");
  const minWidth1200px = useMediaQuery("(min-width: 1200px)");
  const maxWidth1077 = useMediaQuery("(max-width: 1077px)");
  const minWidth960 = useMediaQuery("(min-width: 960px)");
  const maxWidth770 = useMediaQuery("(max-width: 770px)");
  const minWidth600 = useMediaQuery("(min-width: 600px)");
  const maxWidth600 = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          gap: "8px",
          whiteSpace: "nowrap",
        }}
      >
        <Box component="span">
          <User />
        </Box>
        <Typography
          component="p"
          sx={{
            fontSize: { xs: 12, sm: 12.5 },
            display: "grid",
            gridTemplateColumns: "min-content 1fr",
            alignItems: "center",
            gap: "5px",
            color: "#00000099",
          }}
        >
          <Box component="span">مشتری: </Box>
          <Typography
            component="span"
            sx={{
              fontSize: { xs: 12, sm: "12.5px !important" },
              display: "inline",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "#000000DE",
              direction: "rtl",
            }}
          >
            {customerName ? customerName : "نامشخص"}
          </Typography>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          gap: "8px",
          whiteSpace: "nowrap",
        }}
      >
        <Box component="span">
          <Phone />
        </Box>
        <Typography
          component="p"
          sx={{
            fontSize: { xs: 12, sm: 12.5 },
            display: "grid",
            gridTemplateColumns: "min-content 1fr",
            alignItems: "center",
            gap: "5px",
            color: "#00000099",
          }}
        >
          <Box component="span">تلفن: </Box>
          <Typography
            component="span"
            sx={{
              fontSize: { xs: 12, sm: "12.5px !important" },
              display: "inline",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "#000000DE",
              direction: "ltr",
            }}
            onClick={() => {
              if (responsible) copyToClipboard(customerPhone);
            }}
          >
            {customerName ? customerPhone : "نامشخص"}
          </Typography>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          gap: "8px",
          whiteSpace: "nowrap",
        }}
      >
        <Box component="span">
          <Profile />
        </Box>
        <Typography
          component="p"
          sx={{
            fontSize: { xs: 12, sm: 12.5 },
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            gap: "5px",
            color: "#00000099",
          }}
        >
          <Box component="span">کدپذیرش: </Box>
          <Typography
            component="span"
            sx={{
              fontSize: { xs: 12, sm: "12.5px !important" },
              display: "inline",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "#000000DE",
              direction: "ltr",
            }}
          >
            {customerReceptionCode ? customerReceptionCode : "نامشخص"}
          </Typography>
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          gap: "8px",
          whiteSpace: "nowrap",
        }}
      >
        <Box component="span">
          <CarCode />
        </Box>
        <Typography
          component="p"
          sx={{
            fontSize: { xs: 12, sm: 12.5 },
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "5px",
            color: "#00000099",
          }}
        >
          <Box component="span">کد خودرو: </Box>
          <Typography
            component="span"
            sx={{
              fontSize: { xs: 12, sm: "12.5px !important" },
              display: "inline",
              //   overflow: "hidden",
              //   textOverflow: "ellipsis",
              display: "grid",
              gridTemplateColumns: "min-content 1fr",
              alignItems: "center",
              whiteSpace: "wrap",
              color: "#000000DE",
              direction: "ltr",
            }}
          >
            {carCode ? carCode : "نامشخص"}
          </Typography>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          gap: "8px",
          whiteSpace: "nowrap",
        }}
      >
        <Box component="span">
          <Car />
        </Box>
        <Typography
          component="p"
          sx={{
            fontSize: { xs: 12, sm: 12.5 },
            display: "grid",
            gridTemplateColumns: `${
              (minWidth1200px && maxWidth1350px) ||
              (maxWidth1077 && minWidth960) ||
              (maxWidth770 && minWidth600) ||
              maxWidth600
                ? "auto"
                : "min-content 1fr"
            }`,
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Box component="span" sx={{ color: "#00000099" }}>
            خودرو:
          </Box>
          <Typography
            component="span"
            sx={{
              fontSize: { xs: 12, sm: "12.5px !important" },
              display: "flex",
              gap: "5px",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexWrap: "wrap",
              color: "#000000DE",
              direction: "rtl",
            }}
          >
            {customerCar.brand}
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#2196F3" }}
            />
            {customerCar.model}
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#2196F3" }}
            />
            {customerCar.year}
          </Typography>
        </Typography>
      </Box>
      <SnackBar variant={"success"} message={"شماره کپی شد"} open={isCopied} />
    </>
  );
};

export default InfoCardRequest;
