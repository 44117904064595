import React, { useEffect, useState } from "react";
import Rating from "@material-ui/lab/Rating";
import styles from "./Filter.module.css";

const RatingFilter = ({ title, handlerRating, ratingOne, ratingTwo }) => {
  const [numberRating, setNumberRating] = useState({
    Positive: "",
    Negative: "",
  });

  useEffect(() => {
    handlerRating(numberRating.Negative, numberRating.Positive);
  }, [numberRating]);
  return (
    <>
      <div>
        <div className={styles.holder}>
          <h4>{ratingOne}</h4>
          <Rating
            onChange={(event, newValue) =>
              setNumberRating({ Positive: newValue, Negative: "" })
            }
            name="Positive"
            value={numberRating.Positive}
            size="large"
            style={{ color: "green" }}
          />
        </div>
        <div className={styles.holder}>
          <h4>{ratingTwo}</h4>
          <Rating
            onChange={(event, newValue) =>
              setNumberRating({ Positive: "", Negative: newValue })
            }
            name="Negative"
            value={numberRating.Negative}
            size="large"
            style={{ color: "red" }}
          />
        </div>
      </div>
    </>
  );
};
export default RatingFilter;
