import React, { useState, useEffect, useReducer } from "react";
import { ApiClient, ApiHelper } from "../../../../data/remote";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { convertNumberToEN } from "../../../../language/NumberConverter";
import DialogReceiveCode from "../DialogReceiveCode/DialogReceiveCode";
import SnackBar from "../../../common/snackBar/snackBar";
import CircularIntegration from "../../../common/ProgressButton/ProgressButton";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContractInitialInformation from "../.././../../interface/contract";
import PriceFormater from "../../../../utils/priceFormater";
import Chip from "@material-ui/core/Chip";

let initialObject = new ContractInitialInformation().getInitObject();

let requestStateObject = {
  message: "",
  error: false,
  success: false,
  responseObj: {},
  openNotif: false,
  pending: false,
};

const reducer = (state, action) => {
  if (action.copy) {
    return action.payload;
  }
  if (action.type === "reset") {
    return {};
  } else
    return {
      ...state,
      ...(typeof action === "function" ? action() : action),
    };
};

const requestReducer = (state, action) => {
  switch (action.type) {
    case "throwError":
      return {
        ...state,
        message: action.message,
        success: false,
        error: true,
        responseObj: action.responseObj || {},
        openNotif: true,
        pending: false,
      };
      break;
    case "throwSuccess":
      return {
        ...state,
        message: action.message,
        error: false,
        success: true,
        responseObj: action.responseObj || {},
        openNotif: true,
        pending: false,
      };
    case "closeNotif":
      return {
        ...state,
        error: false,
        success: false,
        openNotif: false,
        pending: false,
      };
    case "pending":
      return {
        ...state,
        pending: true,
        success: false,
        error: false,
      };
    case "openNotif":
      return {
        ...state,
        openNotif: true,
      };
    default:
      return {
        message: "",
        error: false,
        success: false,
        responseObj: {},
        openNotif: false,
        pending: false,
      };
      break;
  }
};

const Critic = () => {
  const [message, setMessage] = useState("");
  const [showDialogReceiveCode, setShowDialogReceiveCode] = useState(false);
  const [information, infoDispatch] = useReducer(reducer, initialObject);
  const [receptionCodeEnteredByUser, setReceptionCodeEnteredByUser] =
    useState("");
  const [requetsState, dispatchRequestState] = useReducer(
    requestReducer,
    requestStateObject
  );
  const [pdfUrl, setPdfUrl] = useState(null);
  const [infoFromCodeReq, setInfoFromCodeReq] = useState({ loading: false });
  const [contractRequest, setContractRequest] = useState({
    loading: false,
    success: false,
    error: false,
  });
  const [saveContractReq, setSaveContractReq] = useState({
    loading: false,
    success: false,
    error: false,
  });
  let apiClient = ApiClient.getInstance();
   const storedId = localStorage.getItem("accountStoreAdmin");
   const parsedData = JSON.parse(storedId);

  const getInfoByReceptionCode = () => {
    setShowDialogReceiveCode(true);
  };

  const getContract = () => {
    let data = { ...information, contractKind: "critic" };
    dispatchRequestState({ type: "pending" });
    setContractRequest({ loading: true, success: false, error: false });
    apiClient.post(
      ApiHelper.getContract,
      (error, response) => {
        if (error) {
          dispatchRequestState({ type: "openNotif" });
          dispatchRequestState({
            type: "throwError",
            message: "لطفا بعد از چند لحظه دوباره تلاش کنید !",
            responseObj: response,
          });
          setContractRequest({ loading: false, success: false, error: true });
        } else {
          dispatchRequestState({ type: "openNotif" });
          setPdfUrl(response.pdfurl);
          dispatchRequestState({
            type: "throwSuccess",
            message: "درخواست با موفقیت فرستاده شد",
            responseObj: response,
          });
          setContractRequest({ loading: false, success: true, error: false });
        }
      },
      data
    );
  };

  const saveInfoOfContract = () => {
    let data = {
      ...information,
      receptionCode: receptionCodeEnteredByUser,
      agentId: parsedData.profile._id,
      dateNow: new Date(),
    };
    setSaveContractReq({ loading: true, success: true, error: false });
    dispatchRequestState({ type: "pending" });
    apiClient.post(
      ApiHelper.updateContract,
      (error, response) => {
        if (error) {
          dispatchRequestState({ type: "openNotif" });
          dispatchRequestState({
            type: "throwError",
            message: response.message,
            responseObj: response,
          });
          setSaveContractReq({ loading: false, success: false, error: true });
        } else {
          dispatchRequestState({ type: "openNotif" });
          dispatchRequestState({
            type: "throwSuccess",
            message: response.message,
            responseObj: response,
          });
          setSaveContractReq({ loading: false, success: true, error: false });
        }
        setTimeout(() => {
          setSaveContractReq({ loading: false, success: false, error: false });
        }, 5000);
      },
      data
    );
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Button
            style={{ whiteSpace: "nowrap" }}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => getInfoByReceptionCode()}
          >
            گرفتن اطلاعات با کد پذیرش
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Chip
            label="کارشناسی خودرو"
            color="primary"
            style={{ fontSize: "larger" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          {(() => {
            if (information.receptionCode === initialObject.receptionCode) {
              return (
                <Chip
                  label="کد پذیرش را وارد کنید"
                  style={{ backgroundColor: "red", color: "white" }}
                />
              );
            }
            return (
              <Chip
                label={"کد پذیرش : " + information.receptionCode}
                style={{ backgroundColor: "green", color: "white" }}
              />
            );
          })()}
        </Grid>
      </Grid>
      <h4>مشخصات فروشنده:</h4>
      <Divider />
      <Grid container spacing={2} style={classes.gridContainer}>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => infoDispatch({ sellerName: t.target.value })}
            value={information.sellerName}
            label="نام"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => infoDispatch({ sellerLastName: t.target.value })}
            value={information.sellerLastName}
            label="نام خانوادگی"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => infoDispatch({ sellerCity: t.target.value })}
            value={information.sellerCity}
            label="شهر : "
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) =>
              infoDispatch({ sellerPhoneNumber: t.target.value })
            }
            value={information.sellerPhoneNumber}
            label="شماره تماس"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) =>
              infoDispatch({ sellerReserveDate: t.target.value })
            }
            value={information.sellerReserveDate}
            label="تاریخ رزرو: "
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) =>
              infoDispatch({ sellerRequestDate: t.target.value })
            }
            value={information.sellerRequestDate}
            label="تاریخ درخواست :"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
      </Grid>
      <h4>موضوع و مشخصات مورد معامله:</h4>
      <Divider />
      <Grid container spacing={2} style={classes.gridContainer}>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => infoDispatch({ carBrand: t.target.value })}
            value={information.carBrand}
            label="برند خودرو"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => infoDispatch({ carModel: t.target.value })}
            value={information.carModel}
            label="مدل خودرو"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => infoDispatch({ carTreim: t.target.value })}
            value={information.carTreim}
            label="تریم خودرو"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) =>
              infoDispatch({ carProductionYear: t.target.value })
            }
            value={information.carProductionYear}
            label="سال ساخت"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => infoDispatch({ carFuelType: t.target.value })}
            value={information.carFuelType}
            label="نوع سوخت"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        {/* <Grid item sm={12} xs={4} style={classes.gridInput}>
            <TextField
                onChange={(t) => infoDispatch({carBodyInsurance: t.target.value})}
                value={information.carBodyInsurance}
                label="بیمه: "
                inputProps={{ maxLength: 100 }}
                fullWidth={true}
                variant="outlined"
                margin={'dense'}
                onInput={(e) => {
                e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value).toString()
                    : '';
                }}
            />
            </Grid> */}
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => infoDispatch({ carBodyColor: t.target.value })}
            value={information.carBodyColor}
            label="رنگ بدنه"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} lg={4} md={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => infoDispatch({ carPlqueCode: t.target.value })}
            value={information.carPlqueCode}
            label="شماره پلاک"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={4} style={classes.getContractButton}>
          <Button
            style={{ whiteSpace: "nowrap" }}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => getContract()}
            disabled={contractRequest.loading}
          >
            درخواست قرارداد
            {contractRequest.loading ? (
              <CircularProgress color="secondary" />
            ) : null}
          </Button>
        </Grid>
        <Grid item sm={6} xs={4} style={classes.getContractButton}>
          <CircularIntegration
            title={"ذخیره اطلاعات قرارداد"}
            success={saveContractReq.success}
            loading={saveContractReq.loading}
            onclick={() => saveInfoOfContract()}
          />
        </Grid>
        {pdfUrl ? (
          <Grid item sm={6} xs={4} style={classes.getContractButton}>
            <a href={pdfUrl} style={{ color: "black", width: "100%" }}>
              <CircularIntegration
                title={"دانلود قرارداد"}
                success={contractRequest.success}
                loading={contractRequest.loading}
                Icon={<PictureAsPdfIcon />}
              />
            </a>
          </Grid>
        ) : null}
      </Grid>
      {message !== "" && (
        <AlertDialog
          onClose={() => this.setState({ message: "" })}
          type={"alert"}
          question={false}
          description={message}
        />
      )}
      {showDialogReceiveCode && (
        <DialogReceiveCode
          setShowDialogReceiveCode={setShowDialogReceiveCode}
          setMessage={setMessage}
          infoDispatch={infoDispatch}
          setReceptionCodeEnteredByUser={setReceptionCodeEnteredByUser}
          dispatchRequestState={dispatchRequestState}
          sendRequest={infoFromCodeReq.loading}
          setInfoFromCodeReq={setInfoFromCodeReq}
          getterFunction={(receptionCode) =>
            ApiHelper.getContractByCode(receptionCode)
          }
        />
      )}
      <SnackBar
        open={requetsState.openNotif}
        handleClose={() => dispatchRequestState({ type: "closeNotif" })}
        message={requetsState.message}
        severity={requetsState.error ? "error" : "success"}
      />
    </>
  );
};

export default Critic;

const classes = {
  gridContainer: {
    marginTop: 20,
  },
  textInput: {
    margin: 10,
  },
  gridInput: {
    display: "flex",
    alignItems: "center",
    maxWidth: 400,
    minWidth: 200,
  },
  gridInputPercents: {
    display: "flex",
    alignItems: "center",
    maxWidth: 550,
    minWidth: 200,
    marginTop: 15,
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
  getContractButton: {
    marginTop: 20,
  },
};
