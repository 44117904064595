import { createSlice } from "@reduxjs/toolkit";
const dateSlice = createSlice({
  name: "paidDate",
  initialState: {
    paidDate: null,
  },
  reducers: {
    updateDate: (state, action) => {
      state.paidDate = action.payload;
    },
  },
});

export const { updateDate } = dateSlice.actions;
export default dateSlice.reducer;
