import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Main from "../../common/UserMain";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Button from "@material-ui/core/Button";
import SnackBar from "../../common/SnackBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import AlertDialog from "../../common/AlertDialog";
import { convertNumberToEN } from "../../../language/NumberConverter";
import { goBackUseHistory } from "../../AppHistory";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PriceFormater from "../../../utils/priceFormater";
import EditorGallery from "../../common/EditorGallery";
import ImageUploader from "../../common/imageUploader/imageUploader";
let apiClient = null;

@inject("AccountStore")
@inject("CarsStore")
@observer
class AddEditCategory extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();
    if (!props.AccountStore.isLogin()) this.props.history.replace("/");
    this.state = {
      isNew: !props.CarsStore.currentCategory._id,
      data: props.CarsStore.getCurrentCategory(),
      sendRequest: false,
      sendRequestErrMessage: "",
      inputWarn: 0,
      warnInputMessage: "",
      success: false,
      message: "",
      expanded: "",
      showGalleryDialog: false,
    };
  }

  submit() {
    if (this.state.sendRequest) return;
    this.setState({ sendRequest: true });
    let dataForServer = this.state.data;
    dataForServer.colorChangePriceDrop = parseInt(
      dataForServer.colorChangePriceDrop
    );
    dataForServer.carBodyChangePriceDrop = parseInt(
      dataForServer.carBodyChangePriceDrop
    );
    apiClient.post(
      ApiHelper.addEditCategory_POST,
      (error, response) => {
        this.setState({ sendRequest: false });
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false, success: true });
        }
      },
      dataForServer
    );
  }

  addModel() {
    let newData = this.state.data;
    newData.models.push({ model: "", modelStartingPrice: null, types: [] });
    this.setState({ data: newData });
    this.state.expanded = "panel" + (this.state.data.models.length - 1);
    this.setState({ ref: true });
  }

  addType(i) {
    this.state.data.models[i]["types"].push({
      year: "",
      yearStartingPrice: null,
      options: [],
    });
    this.setState({ ref: true });
  }

  addOption(i, iY) {
    this.state.data.models[i].types[iY]["options"].push({
      option: "",
      optionStartingPrice: null,
    });
    this.setState({ ref: true });
  }

  setModelStartingPrice(price, modelIndex) {
    this.state.data.models[modelIndex].modelStartingPrice = price.target.value
      .toString()
      .replace(/,/g, "");
  }

  setYearStartingPrice(price, modelIndex, typeIndex) {
    this.state.data.models[modelIndex].types[typeIndex].yearStartingPrice =
      price.target.value.toString().replace(/,/g, "");
  }

  setOptionStartingPrice(price, modelIndex, typeIndex, optionIndex) {
    this.state.data.models[modelIndex].types[typeIndex].options[
      optionIndex
    ].optionStartingPrice = price.target.value.toString().replace(/,/g, "");
  }

  setColorChangePriceDrop(percent) {
    this.state.data.colorChangePriceDrop = percent.toString().replace(/,/g, "");
  }

  setBodyChangePriceDrop(percent) {
    this.state.data.carBodyChangePriceDrop = percent
      .toString()
      .replace(/,/g, "");
  }

  setYearDropChangePrice(percent, modelIndex) {
    this.state.data.models[modelIndex].yearChangePriceDrop = percent
      .toString()
      .replace(/,/g, "");
  }

  setChassis(percent, location) {
    if (location === "front")
      this.state.data.chassiFront = percent.toString().replace(/,/g, "");
    this.state.data.chassiBack = percent.toString().replace(/,/g, "");
  }

  render() {
    return (
      <Main
        onBackPress={() => goBackUseHistory(this.props.history)}
        page={"categories"}
        pageName={"ثبت دسته خودرو"}
        context={this.props.history}
      >
        <div style={{ padding: 15 }}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputName = r)}
                onChange={(t) => (this.state.data.brand = t.target.value)}
                defaultValue={this.state.data.brand}
                label="برند"
                inputProps={{ maxLength: 50 }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value).toString()
                    : "";
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Divider />
              <Button
                style={{ marginTop: 15, marginBottom: 5, whiteSpace: "nowrap" }}
                variant="outlined"
                color="primary"
                onClick={() => this.addModel()}
              >
                ایجاد مدل
              </Button>
              {/* gallery for car category each brand will container a picture */}
              {this.state.data.models.map((model, i) => (
                <Accordion
                  key={i + "-" + model.model}
                  style={{ margin: "15px 5px 15px 5px", padding: 10 }}
                  expanded={this.state.expanded === "panel" + i}
                  onChange={() => this.setState({ expanded: "panel" + i })}
                >
                  <AccordionSummary
                    style={{
                      flexDirection: "row-reverse",
                      margin: -10,
                      padding: 0,
                    }}
                    expandIcon={<Icon>expand_more</Icon>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Grid container spacing={2}>
                      {model._id ? (
                        <Grid item sm={10} xs={10}>
                          <ImageUploader
                            getGalleryURL={ApiHelper.get_car_category_image(
                              model?._id
                            )}
                            uploadImageURL={ApiHelper.uploadCarCategoryImage_POST(
                              model?._id
                            )}
                            removeImageURL={ApiHelper.removeCarCategoryImage_Get(
                              model?._id
                            )}
                            afterUploadCallBack={(imageAddress) => {
                              model.images.push(imageAddress);
                            }}
                          />
                        </Grid>
                      ) : null}
                      <Grid item sm={8} xs={12} style={classes.gridInput}>
                        <TextField
                          onChange={(t) =>
                            (this.state.data.models[i].model = t.target.value)
                          }
                          defaultValue={model.model}
                          label="مدل"
                          inputProps={{ maxLength: 100 }}
                          fullWidth={true}
                          variant="outlined"
                          margin={"dense"}
                          onInput={(e) => {
                            e.target.value = e.target.value
                              ? convertNumberToEN(e.target.value).toString()
                              : "";
                          }}
                        />
                        <IconButton
                          size="small"
                          onClick={() => {
                            this.state.data.models.splice(i, 1);
                            this.setState({ expanded: "" });
                          }}
                        >
                          <Icon style={{ color: "red" }}>delete</Icon>
                        </IconButton>
                        <Button
                          style={{ whiteSpace: "nowrap" }}
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => this.addType(i)}
                        >
                          ایجاد سال
                        </Button>
                        <TextField
                          onChange={(t) => this.setModelStartingPrice(t, i)}
                          defaultValue={
                            this.state.data.models[i].modelStartingPrice &&
                            PriceFormater(
                              this.state.data.models[i].modelStartingPrice
                            )
                          }
                          label=" قیمت پایه مدل صفر خودرو"
                          fullWidth={true}
                          style={{ marginRight: 10 }}
                          inputProps={{
                            style: {
                              fontSize: 14,
                              direction: "ltr",
                            },
                            maxLength: 22,
                          }}
                          onInput={(e) =>
                            (e.target.value = e.target.value
                              ? PriceFormater(convertNumberToEN(e.target.value))
                              : "")
                          }
                          type="tel"
                        />
                        <TextField
                          onChange={(t) =>
                            this.setYearDropChangePrice(t.target.value, i)
                          }
                          defaultValue={
                            this.state.data.models[i].yearChangePriceDrop
                          }
                          label="کم شدن قیمت بر حسب سال"
                          fullWidth={true}
                          style={{ marginRight: 10 }}
                          inputProps={{
                            style: {
                              fontSize: 14,
                              direction: "ltr",
                            },
                            maxLength: 22,
                          }}
                          onInput={(e) =>
                            (e.target.value = e.target.value
                              ? PriceFormater(convertNumberToEN(e.target.value))
                              : "")
                          }
                          type="tel"
                        />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {this.state.data.models[i].types.map((year, iY) => (
                        <div
                          key={iY + "-" + year.year}
                          style={{
                            border: "1px solid #e3e3e3",
                            margin: 10,
                            borderRadius: 4,
                            backgroundColor: "#f8f8f8",
                            padding: 10,
                          }}
                        >
                          <Grid item sm={8} xs={12} style={classes.gridInput}>
                            <TextField
                              onChange={(t) =>
                                (this.state.data.models[i].types[iY].year =
                                  t.target.value)
                              }
                              defaultValue={year.year}
                              label="سال"
                              fullWidth={true}
                              inputProps={{
                                style: {
                                  textAlign: "left",
                                  direction: "ltr",
                                  fontSize: 15,
                                },
                                maxLength: 4,
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  ? convertNumberToEN(e.target.value)
                                      .toString()
                                      .replace(/[^0-9]/g, "")
                                      .toString()
                                  : "";
                              }}
                              type="tel"
                            />
                            <IconButton
                              size="small"
                              onClick={() => {
                                this.state.data.models[i].types.splice(iY, 1);
                                this.setState({ ref: true });
                              }}
                            >
                              <Icon>close</Icon>
                            </IconButton>
                            <Button
                              style={{ whiteSpace: "nowrap", padding: 5 }}
                              size="medium"
                              variant="outlined"
                              color="secondary"
                              onClick={() => this.addOption(i, iY)}
                            >
                              قیمت , آپشن
                            </Button>
                            <TextField
                            disabled
                              onChange={(t) =>
                                this.setYearStartingPrice(t, i, iY)
                              }
                              label="قیمت پایه سال"
                              fullWidth={true}
                              defaultValue={
                                this.state.data.models[i].types[iY]
                                  .yearStartingPrice &&
                                PriceFormater(
                                  this.state.data.models[i].types[iY]
                                    .yearStartingPrice
                                )
                              }
                              style={{ marginRight: 10 }}
                              inputProps={{
                                style: {
                                  fontSize: 14,
                                  direction: "ltr",
                                },
                                maxLength: 22,
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  ? PriceFormater(
                                      convertNumberToEN(e.target.value)
                                    )
                                  : "";
                              }}
                              type="tel"
                            />
                          </Grid>

                          {this.state.data.models[i].types[iY].options.map(
                            (option, iO) => (
                              <div
                                key={iO + "-" + option.option}
                                style={{
                                  border: "1px solid #e3e3e3",
                                  margin: 10,
                                  borderRadius: 4,
                                  backgroundColor: "#e2e2e2",
                                  padding: 10,
                                }}
                              >
                                <Grid
                                  item
                                  sm={6}
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    maxWidth: 750,
                                    minWidth: 600,
                                  }}
                                >
                                  <TextField
                                    onChange={(t) =>
                                      (this.state.data.models[i].types[
                                        iY
                                      ].options[iO].option = t.target.value)
                                    }
                                    defaultValue={option.option}
                                    label="آپشن"
                                    inputProps={{
                                      maxLength: 200,
                                      style: { fontSize: 14 },
                                    }}
                                    fullWidth={true}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        ? convertNumberToEN(
                                            e.target.value
                                          ).toString()
                                        : "";
                                    }}
                                  />
                                  <TextField
                                   disabled
                                    onChange={(t) =>
                                      this.setOptionStartingPrice(t, i, iY, iO)
                                    }
                                    defaultValue={
                                      option.optionStartingPrice
                                        ? PriceFormater(
                                            option.optionStartingPrice
                                          )
                                        : ""
                                    }
                                    label="قیمت پایه آپشن"
                                    fullWidth={true}
                                    inputProps={{
                                      style: {
                                        fontSize: 14,
                                        direction: "ltr",
                                      },
                                      maxLength: 22,
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        ? PriceFormater(
                                            convertNumberToEN(e.target.value)
                                          )
                                        : "";
                                    }}
                                    type="tel"
                                  />
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      this.state.data.models[i].types[
                                        iY
                                      ].options.splice(iO, 1);
                                      this.setState({ ref: true });
                                    }}
                                  >
                                    <Icon>close</Icon>
                                  </IconButton>
                                </Grid>
                              </div>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            <Grid item sm={12} xs={12}>
              <Divider />
              {this.state.sendRequest ? (
                <div style={classes.progressBarContainer}>
                  <LinearProgress
                    style={{ width: 150, height: 4, marginTop: 15 }}
                  />
                  <p>درحال ارسال اطلاعات ...</p>
                </div>
              ) : (
                <div style={{ display: "flex", marginTop: 20, maxWidth: 330 }}>
                  <Button
                    variant="contained"
                    onClick={() => this.submit()}
                    style={{ margin: 5 }}
                    fullWidth
                    color={"primary"}
                  >
                    {this.state.isNew ? "ارسال" : "بروزرسانی"}
                  </Button>

                  <Button
                    variant="outlined"
                    style={{ margin: 5 }}
                    onClick={() =>
                      goBackUseHistory(this.props.history, "categories")
                    }
                    fullWidth
                    color={"secondary"}
                  >
                    بازگشت
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </div>

        <SnackBar
          variant={"error"}
          message={this.state.sendRequestErrMessage}
          open={this.state.sendRequestErrMessage !== ""}
          onClose={() => this.setState({ sendRequestErrMessage: "" })}
        />

        {this.state.success && (
          <AlertDialog
            onClose={() => goBackUseHistory(this.props.history)}
            question={false}
            description={
              this.state.isNew
                ? "دسته با موفقیت ایجاد شد."
                : "بروزرسانی با موفقیت انجام شد."
            }
          />
        )}

        {this.state.warnInputMessage !== "" && (
          <AlertDialog
            onClose={() => this.setState({ warnInputMessage: "" })}
            question={false}
            description={this.state.warnInputMessage}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </Main>
    );
  }
}

export default AddEditCategory;

const classes = {
  textInput: {
    margin: 10,
  },
  gridInput: {
    display: "flex",
    alignItems: "center",
    maxWidth: 700,
    minWidth: 200,
  },
  gridInputPercents: {
    display: "flex",
    alignItems: "center",
    maxWidth: 550,
    minWidth: 200,
    marginTop: 15,
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
};
