import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { validationFormikTagLink } from "../../../../values/validation_formik/validationFormikTagLink";

import { ApiClient, ApiHelper } from "../../../../data/remote";
import SnackBar from "../../../common/SnackBar";
import LinkForm from "../link_form/LinkForm";
import style from "./linksDialog.module.css";

import { Dialog, DialogContent, Divider, DialogTitle } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useSelector, useDispatch } from "react-redux";
import {
  addLink,
  updatedLink,
  setEditLink,
} from "../../../../Store/slice/sliceListInfoLinks";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ExclusiveLinksDialog = ({ openDialog, OpenDialogAddEdit }) => {
  const apiClient = ApiClient.getInstance();
  const [colorTags, setColorTags] = useState([]);
  const [messageSnakBar, setmessageSnakBar] = useState({
    statusRes: "",
    messageRes: "",
    openSnakbar: false,
  });
  const [isActiveColor, setIsActiveColor] = useState(false);

  const handleColorChange = (code) => {
    setIsActiveColor(code);
    formik.setFieldValue("color", code);
  };

  const editLink = useSelector(({ dataLinks }) => dataLinks.changeLink);
  const Dispatch = useDispatch();
  const idEdit = editLink._id;

  useEffect(() => {
    if (idEdit) {
      formik.setValues(editLink);
    }
  }, [idEdit]);

  const handlerUpdate = (response) => {
    Dispatch(updatedLink(response.link));
    Dispatch(setEditLink([]));
    formik.resetForm();
    setmessageSnakBar({
      messageRes: response.message,
      statusRes: true,
      openSnakbar: true,
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      color: "",
      path: "",
    },

    validationSchema: validationFormikTagLink,
    onSubmit: (values) => {
      if (idEdit) {
        apiClient.put(
          ApiHelper.edit_link_PUT(idEdit),
          (error, response) => {
            if (error) {
            } else {
              if (response.success) {
                handlerUpdate(response);
              }
            }
          },
          values
        );
      } else {
        apiClient.post(
          ApiHelper.createLink_POST,
          (error, response) => {
            if (error) {
              setmessageSnakBar({
                messageRes: response.message,
                statusRes: false,
                openSnakbar: true,
              });
            } else {
              if (response.success) {
                Dispatch(addLink(response.createdLink));
                setmessageSnakBar({
                  messageRes: response.message,
                  statusRes: true,
                  openSnakbar: true,
                });
                formik.resetForm();
              }
            }
          },
          values
        );
      }
    },
  });

  const handlerCloseDialog = () => {
    OpenDialogAddEdit(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (openDialog) {
      apiClient.get(ApiHelper.linksColors_GET, (error, response) => {
        if (error) {
        } else {
          setColorTags(response.colors);
        }
      });
    }
  }, [openDialog, formik.values.path]);

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handlerCloseDialog()}
    >
      <DialogTitle className={style.editAddLink}>
        ایجاد و ویرایش لینک اختصاصی
      </DialogTitle>
      <Divider />
      <Divider />
      <DialogContent>
        <LinkForm
          formik={formik}
          colorTags={colorTags}
          handleColorChange={handleColorChange}
          isActiveColor={isActiveColor}
          handlerCloseDialog={handlerCloseDialog}
        />
      </DialogContent>
      <SnackBar
        variant={messageSnakBar.statusRes ? "success" : "warning"}
        message={messageSnakBar.messageRes}
        open={messageSnakBar.openSnakbar}
        onClose={() =>
          setmessageSnakBar((prev) => ({ ...prev, openSnakbar: false }))
        }
      />
    </Dialog>
  );
};

export default ExclusiveLinksDialog;
