import { observable, action, get } from "mobx";
import { persist } from "mobx-persist";
import { setMomentOffset } from "../presenter/Utiles";

class Notifications {
  userRequests = 0;
  regRequests = 0;
  userRequestsSell = 0;
  userRequestsSell_reserved = 0;
  userRequestsBuy = 0;
  userRequestsBuy_verified = 0;
  carsAuctions_awaiting_payment = 0;
  carsAuctions_payment_done = 0;
  carsAuctions_negotiating = 0;
  carsAuctions_live = 0;
  carsAuctions_live_ended = 0;
  carsAuctions_depot = 0;
  carsAuctions_inqueue = 0;
  errors = 0;
  refHash = "";
}

class ProfileModel {
  _id = null;
  name = "";
  address = "";
  type = "";
  username = "";
  experts = "";
  phone = "";
  officePhone = "";
  permissions = [];
}

export default class Account {
  @persist("object") @observable profile = new ProfileModel();
  @persist("list") @observable sessions = [];
  @persist @observable fcmId = "NA";
  @persist @observable token = "";
  @persist @observable type = "";
  @observable notifications = new Notifications();
  @observable sumNotifications = 0;

  serverTime = "";

  @persist @observable notificationsUpdateKey = "";
  @persist @observable notificationsRefreshMenuKey = "";

  @action reset() {
    this.token = "";
    this.type = "";
    this.profile = new ProfileModel();
    this.sessions = [];
    this.notifications = new Notifications();
  }

  @action signOut() {
    this.token = "";
    this.type = "";
    this.profile = new ProfileModel();
    this.sessions = [];
    this.notifications = new Notifications();
  }

  @action setTokenAndType(token, type, name) {
    this.profile.name = name;
    this.token = token;
    this.type = type;
  }

  @action setNotifications(notifications) {
    this.notifications = notifications;
    this.notificationsUpdateKey = notifications.refHash;
    this.notificationsRefreshMenuKey = Math.random();
    this.sumNotifications =
      this.notifications.userRequestsSell_reserved +
      this.notifications.carsAuctions_negotiating +
      this.notifications.carsAuctions_live +
      this.notifications.carsAuctions_depot +
      this.notifications.carsAuctions_live_ended +
      this.notifications.userRequestsBuy_verified +
      this.notifications.carsAuctions_awaiting_payment +
      this.notifications.carsAuctions_payment_done +
      this.notifications.regRequests;
  }

  @action setSessions(sessions) {
    this.sessions = sessions;
  }

  @get getCurrentSessions() {
    return this.sessions.find((t) => t.activeNow);
  }
  @get getSessions() {
    return this.sessions.filter((t) => !t.activeNow);
  }

  @get isLogin() {
    return this.token !== "";
  }

  @action setServerTime(date) {
    this.serverTime = date;
    setMomentOffset(date);
  }
}
