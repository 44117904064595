import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeRecentQuery } from "../../Store/slice/sliceNewRequest";

const SortFilterMobile = () => {
  const recent = useSelector((state) => state?.newRequest?.recent);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (e.target.value === "recent") {
      dispatch(changeRecentQuery(true));
    }

    if (e.target.value === "old") {
      dispatch(changeRecentQuery(false));
    }
  };

  return (
    <FormControl sx={{ width: 1 }}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={recent ? "recent" : "old"}
        onChange={handleChange}
        row
        sx={{
          justifyContent: "space-evenly",
          "& label span:last-child": { fontSize: 14 },
        }}
      >
        <FormControlLabel
          sx={{ marginRight: "unset" }}
          value="recent"
          control={<Radio />}
          label="جدیدترین"
        />
        <FormControlLabel
          sx={{ marginRight: "unset" }}
          value="old"
          control={<Radio />}
          label="قدیمی ترین"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default SortFilterMobile;
