import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 300;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  centerAppBarComponent: {
    position: "absolute",
    display: "flex",
    left: 50,
    right: 50,
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "auto",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
  },
  appBarTransparent: {
    backgroundColor: "transparent",
    webkitBoxShadow: "none",
    mozBoxShadow: "none",
    boxShadow: "none",
    pointerEvents: "none",
    userSelect: "none",
  },

  appBar: {
    userSelect: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    pointerEvents: "auto",
  },
  toolbar: theme.mixins.toolbar,
  drawerHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
  },
  drawerPaper: {
    overflowX: "hidden",
    width: drawerWidth,
  },
  drawerCloseButton: {
    display: "none",
    position: "absolute",
    right: 0,
    top: 0,
    pointerEvents: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  content: {
    flexGrow: 1,
  },
}));

export const styles = {
  drawerPersonalInfoContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  adminTextStyle: {
    margin: 5,
    color: "#ffca00",
    fontWeight: "bold",
    display: "flex",
    alignItems: "end",
  },
  textPanelStyle: {
    margin: 5,
  },
  profileImage: {
    border: "2px solid #fff",
    height: 40,
    width: "100%",
    objectFit: "cover",
    margin: 5,
  },
  drawerTitleHeader: {
    textAlign: "right",
    margin: 5,
    fontSize: 12,
  },
  childContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: 5,
    overflow: "auto",
  },
  subscriber: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 15,
    borderRadius: 8,
    padding: 8,
  },
  profileButton: {
    marginRight: 5,
    width: 150,
    marginLeft: 22,
    paddingLeft: 20,
    pointerEvents: "auto",
    position: "relative",
    display: "flex",
  },
};
