import React from "react";
import { Button, Icon } from "@material-ui/core";

const RequestsBtnsDiscount = ({
  iconColor,
  iconName,
  text,
  propClass,
  userDiscount,
}) => {
  return userDiscount ? (
    <Button className={propClass}>
      <Icon color={iconColor}>{iconName}</Icon>
      {text}
    </Button>
  ) : null;
};

export default RequestsBtnsDiscount;
