import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseModal from "../../packages/icons/CloseModal";
import ClearInput from "../../packages/icons/ClearInput";
import {
  useCreateCityMutation,
  useUpdateCityMutation,
} from "../../Store/callApi/MarketingInformation";
import styles from "./CitiesDialog.module.css";
const AlertDialog = ({ isNew, isOpen, onClose, title, cityName, cityId }) => {
  const [city, setCity] = useState(cityName);
  const [createCity] = useCreateCityMutation();
  const [updateCity] = useUpdateCityMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  useEffect(() => {
    if (isOpen) {
      setCity(cityName || "");
    }
  }, [isOpen, cityName]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const citySubmitHandler = async () => {
    try {
      let res;
      if (isNew) {
        res = await createCity({ name: city });
      } else {
        res = await updateCity({ ID: cityId, body: { name: city } });
      }

      if (res?.data?.success) {
        setSnackbarSeverity("success");
        setSnackbarMessage(res.data.message);
      } else {
        setSnackbarSeverity("error");
        setSnackbarMessage("شهری با این نام وجود دارد");
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.log("Error:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        res?.error?.data?.message ||
          "Error occurred while processing the request."
      );
      setSnackbarOpen(true);
    }
  };

  const handleClearInput = () => {
    setCity("");
  };

  const handleConfirm = () => {
    citySubmitHandler();
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <p className={styles.dialogTitle}>{title}</p>
              <span className={styles.customClose} onClick={onClose}>
                <CloseModal />
              </span>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  id="inputField"
                  placeholder="نام شهر"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
                {city && (
                  <button
                    id="clearInput"
                    className={styles.iconButton}
                    onClick={handleClearInput}
                  >
                    <ClearInput />
                  </button>
                )}
              </div>
              <div className={styles.inputLabel}>
                {isNew ? "نام شهر جدید را وارد کنید" : "نام شهر را ویرایش کنید"}
              </div>
            </div>
            <div className={styles.modalFooter}>
              <button
                id="confirmButton"
                className={styles.modalButton}
                onClick={handleConfirm}
              >
                تایید
              </button>
              <button
                className={styles.modalButtonCancle}
                onClick={handleCancel}
              >
                انصراف
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

AlertDialog.propTypes = {
  isNew: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  cityName: PropTypes.string,
  cityId: PropTypes.string,
};

export default AlertDialog;
