import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";


const CustomSkeleton=({height , width , variant, customClass})=>{

    return (
      <Skeleton
        className={customClass}
        variant={variant}
        width={width}
        height={height}
      />
    );
}

export default CustomSkeleton;