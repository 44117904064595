import { observable, action, get } from "mobx";
import { persist } from "mobx-persist";

class LocationModel {
  _id = null;
  location = "";
  createdAt = "";
  updatedAt = "";
}

export default class Locations {
  @persist("list") @observable locations = [];
  @observable current = new LocationModel();

  @action setLocations(data) {
    this.locations = data;
  }

  @action setCurrent(item) {
    this.current = JSON.parse(JSON.stringify(item));
  }

  @action updateItem(item) {
    let index = this.locations.findIndex(
      (t) => t._id.toString() === item._id.toString()
    );
    if (index >= 0) {
      this.locations[index] = item;
    } else {
      this.locations.push(item);
    }
  }

  @action emptyCurrent() {
    this.current = new LocationModel();
  }

  @get getCurrent() {
    return JSON.parse(JSON.stringify(this.current));
  }
}
