const panelPermissions = [
  {
    permissionCode: "cities",
    label: "شهر ها",
    id: 11,
  },
  {
    permissionCode: "carCategories",
    label: "دسته بندی خودرو",
    id: 12,
  },
  {
    permissionCode: "coworkers",
    label: "همکاران",
    id: 13,
  },
  {
    permissionCode: "agents",
    label: "نمایندگان",
    id: 14,
  },
  {
    permissionCode: "experts",
    label: "کارشناسان",
    id: 15,
  },
  {
    permissionCode: "sellers",
    label: "فروشندگان",
    id: 16,
  },
  {
    permissionCode: "cars",
    label: "خودرو ها",
    id: 17,
  },
  {
    permissionCode: "brokers",
    label: "بروکر ها",
    id: 18,
  },
  {
    permissionCode: "receits",
    label: "پرداخت ها",
    id: 19,
  },
  {
    permissionCode: "requests",
    label: "درخواست ها",
    id: 20,
  },
  {
    permissionCode: "registerRequests",
    label: "درخواست های ثبت نام",
    id: 21,
  },
  {
    permissionCode: "carPrices",
    label: "قیمت خودرو",
    id: 22,
  },
  {
    permissionCode: "systemLogs",
    label: "لاگ های سیستم",
    id: 23,
  },
  {
    permissionCode: "contracts",
    label: "قرارداد ها",
    id: 24,
  },
  {
    permissionCode: "brands",
    label: "برند ها",
    id: 25,
  },
  {
    permissionCode: "backups",
    label: "بک آپ",
    id: 26,
  },
  {
    permissionCode: "errors",
    label: "ارور",
    id: 27,
  },
  {
    permissionCode: "timeManagement",
    label: "مدیریت زمان",
    id: 28,
  },
  {
    permissionCode: "information",
    label: " اطلاعات",
    id: 29,
  },
];

export default panelPermissions;
