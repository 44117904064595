import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import UseGetSellers from "../../Hooks/sellers/useGetSellers";
import CircularProgress from "@material-ui/core/CircularProgress";

const SellerFilter = ({ title, handlerSeller }) => {
  const [nameSeller, setNameSeller] = useState("");
  const [seller, handlerGetSellers, sellerloading] = UseGetSellers();

  const handleNameSellerChange = (event) => {
    handlerSeller(event.target.value);
    setNameSeller(event.target.value);
  };

  return (
    <div>
      <FormControl>
        <NativeSelect
          style={{ width: "200px" }}
          native
          value={nameSeller}
          onChange={handleNameSellerChange}
          onClick={() => handlerGetSellers("accepted")}
          inputProps={{
            name: "seller",
            id: "seller-native-helper",
          }}
        >
          <option>انتخاب کنید</option>
          {seller.map((item, i) => (
            <option key={i} value={item._id}>
              {item.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      {sellerloading && (
        <CircularProgress style={{ marginRight: "20px" }} color="secondary" />
      )}
    </div>
  );
};

export default SellerFilter;
