import React, { useState } from "react";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import Box from "@material-ui/core/Box";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { buttonStyle } from "../../styles/main";
import { useDispatch } from "react-redux";
import { updateRTK } from "../../Store/slice/getErrorsParams";

const ErrorSolving = () => {
  const buttonClass = buttonStyle();
  const [activeBtn, setIsActiveBtn] = useState("error");
  const dispatch = useDispatch();
  dispatch(updateRTK({ resolved: `${activeBtn === "error" ? false : true}` }));
  const theme = useTheme();
  const isMatchesLessThanSM = useMediaQuery(theme.breakpoints.down("xs"));
  const handleActiveBtn = (e) => {
    setIsActiveBtn(e);
  };

  return (
    <Box display="flex" height="40px">
      <Button
        className={`${buttonClass.root} ${
          activeBtn === "error" ? buttonClass.firstActiveButton : ""
        } ${buttonClass.firstButtonStyle} ${
          isMatchesLessThanSM && buttonClass.responsiveFirstButtonStyle
        } btn`}
        variant="contained"
        disableElevation
        disableRipple
        startIcon={<ErrorIcon />}
        onClick={() => handleActiveBtn("error")}
      >
        ارورها
      </Button>
      <Button
        className={`${buttonClass.secondButtonStyle} ${buttonClass.root} ${
          activeBtn === "resolve" && buttonClass.secondActiveButton
        } ${
          isMatchesLessThanSM && buttonClass.responsiveSecondButtonStyle
        } btn`}
        variant="contained"
        disableElevation
        disableRipple
        startIcon={<DoneIcon />}
        onClick={() => handleActiveBtn("resolve")}
      >
        حل شده ها
      </Button>
    </Box>
  );
};

export default ErrorSolving;
