import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { DatePicker } from "zaman";
import DatePickerIcon from "../../../packages/icons/DatePickerIcon";
import { useDispatch } from "react-redux";
import { datePickerTop } from "../../../styles/main";

const DatePickerMobile = ({
  className,
  updateStore,
  param,
  dateOnChange,
  value,
}) => {
  const datePickerTopStyle = datePickerTop();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());

  return (
    <div className={`${datePickerTopStyle.root} ${className}`}>
      <Box component="div">
        <Typography component="span">تاریخ</Typography>
        <DatePicker
          key={"not random"}
          round="x4"
          defaultValue={value ? value : date}
          onChange={({ value }) => {
            setDate(value);
            dispatch(
              updateStore({ [param]: `${value.toISOString().slice(0, 10)}` })
            );
          }}
          weekends={[6]}
          position="center"
        />
        <Typography component="span">روز/ماه/سال</Typography>
        <DatePickerIcon />
      </Box>
    </div>
  );
};

export default DatePickerMobile;
