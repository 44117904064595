import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { ApiClient, ApiHelper } from "../../../data/remote";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import AlertDialog from "../../common/AlertDialog";
import { inject, observer } from "mobx-react";

import Select from "@material-ui/core/Select";
import RequestState from "../../common/RequestState";
import IconButton from "@material-ui/core/IconButton";
import StarRater from "../../common/StarRater/StartRater";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

var momentServerOffset = require("moment");
var moment = require("moment-jalaali");

let apiClient = null;

@inject("AccountStore")
@inject("BrokersStore")
@inject("CarsStore")
@inject("AppRouterStore")
@observer
class ChangeStatusDialog extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();

    props.BrokersStore.emptyCurrent();
    this.state = {
      id: props.id,
      data: props.BrokersStore.getCurrent(),
      status: "",
      msg: "",
      sendRequest: false,
      message: "",
      showUserInfo: "",
      getDataRequest: false,
      getDataRequestErr: true,
      isOnline: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  handleClose() {
    this.props.onClose();
  }

  getData() {
    if (this.state.getDataRequest) {
      return;
    }

    this.setState({ getDataRequest: true, getDataRequestErr: false });

    apiClient.get(
      ApiHelper.getBrokerInfoById_GET(this.state.id),
      (error, response) => {
        if (error) {
          this.setState({
            getDataRequest: false,
            getDataRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          let isOnline = response.data.isOnline;
          if (
            !!response.data.onlineTime &&
            moment
              .duration(
                moment(response.data.onlineTime).diff(momentServerOffset())
              )
              .asMinutes() < -120
          ) {
            isOnline = false;
          }
          if (this.props.onUpdateItem) {
            this.props.onUpdateItem(response.data);
          }
          this.setState({
            getDataRequest: false,
            getDataRequestErr: false,
            data: response.data,
            isOnline,
          });
        }
      }
    );
  }

  submit() {
    if (this.state.sendRequest) return;

    if (this.state.status === "") {
      this.setState({ message: "لطفا وضعیت را مشخص کنید" });
      return;
    }

    this.setState({ sendRequest: true, inputWarn: 0 });

    apiClient.get(
      ApiHelper.changeStatusBroker_GET(this.state.status),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false }, () =>
            this.props.onSuccess(response.data._id)
          );
        }
      },
      { id: this.state.data._id }
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{ width: "80vw", maxWidth: 500, minWidth: 300, padding: 5 }}
          >
            {this.state.getDataRequest || this.state.getDataRequestErr ? (
              <RequestState
                error={this.state.getDataRequestErr}
                retryOnPress={() => this.getData()}
                title={"در حال دریافت مشخصات بروکر ..."}
                request={this.state.getDataRequest}
              />
            ) : (
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.status}
                  </p>
                  <p
                    style={{
                      flex: 1,
                      fontSize: 13,
                      margin: 5,
                    }}
                  >
                    {this.state.data.location &&
                      this.state.data.location.location}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.activityType}
                  </p>
                  <p
                    style={{
                      flex: 1,
                      fontSize: 13,
                      margin: 5,
                    }}
                  >
                    {moment(this.state.data.createdAt).format("jYYYY/jM/jD")}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.name}
                  </p>
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.phone}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.agentId
                      ? this.state.data.agentId.name
                      : "ایجاد شده توسط ادمین"}
                  </p>
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.agentId
                      ? this.state.data.agentId.username
                      : "admin"}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p
                    style={{
                      flex: 1,
                      fontSize: 13,
                      margin: 5,
                      color: this.state.isOnline ? "green" : "orange",
                    }}
                  >
                    {this.state.isOnline ? "آنلاین" : "آفلاین"}
                  </p>
                  {this.state.data.onlineTime && (
                    <p
                      style={{
                        flex: 1,
                        fontSize: 13,
                        margin: 5,
                      }}
                    >
                      {moment(this.state.data.onlineTime).format(
                        "jYYYY/jM/jD , HH:mm"
                      )}
                    </p>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <IconButton size="small" style={{ margin: 5 }}>
                    <img
                      width={25}
                      height={25}
                      src={
                        !this.state.data.sessions ||
                        this.state.data.sessions.findIndex(
                          (t) => t.os === "pwa"
                        ) < 0
                          ? require("../../../assets/images/sms.svg")
                          : this.state.data.enableSMS
                          ? require("../../../assets/images/sms_on.svg")
                          : require("../../../assets/images/sms_off.svg")
                      }
                    />
                  </IconButton>

                  {this.state.data.sessions &&
                    this.state.data.sessions.findIndex((t) => t.os === "pwa") >=
                      0 && (
                      <IconButton size="small" style={{ margin: 5 }}>
                        <img
                          width={25}
                          height={25}
                          src={require("../../../assets/images/pwa.svg")}
                        />
                      </IconButton>
                    )}

                  {this.state.data.sessions &&
                    this.state.data.sessions.findIndex(
                      (t) => t.os === "mobile"
                    ) >= 0 && (
                      <IconButton size="small" style={{ margin: 5 }}>
                        <img
                          width={25}
                          height={25}
                          src={require("../../../assets/images/mobile.svg")}
                        />
                      </IconButton>
                    )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>نقطه قوت</p>
                  <div style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    <StarRater
                      rate={this.state.data.PositiveRate}
                      readOnly
                      state="positive"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>نقطه ضعف</p>
                  <div style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    <StarRater
                      rate={this.state.data.NegativeRate}
                      readOnly
                      state="negative"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                    maxWidth: "100%",
                  }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>توضیحات</Typography>
                    </AccordionSummary>
                    {!!this.state.data.description && (
                      <AccordionDetails>
                        {/* <Typography> */}
                        {this.state.data.description}
                        {/* </Typography> */}
                      </AccordionDetails>
                    )}
                  </Accordion>
                </div>
                <div style={{ display: "flex", flex: 1, margin: 5 }}>
                  <Button
                    style={{ margin: 5 }}
                    variant="outlined"
                    onClick={() => {
                      this.props.BrokersStore.setBrokerPaymentFilter(
                        this.state.data
                      );
                      if (
                        !this.props.AppRouterStore.getLastHistoryPath() ||
                        this.props.AppRouterStore.getLastHistoryPath().indexOf(
                          "payments"
                        ) < 0
                      ) {
                        this.props.history.push("/payments/created");
                      } else {
                        this.props.onClose();
                      }
                    }}
                    color="secondary"
                  >
                    پرداخت ها
                  </Button>

                  <Button
                    style={{ margin: 5 }}
                    variant="outlined"
                    onClick={() => {
                      this.props.CarsStore.setBrokerFilter(this.state.data);
                      if (
                        !this.props.AppRouterStore.getLastHistoryPath() ||
                        this.props.AppRouterStore.getLastHistoryPath().indexOf(
                          "cars"
                        ) < 0
                      ) {
                        this.props.history.push("/cars/accepted/ended");
                      } else {
                        this.props.onClose();
                      }
                    }}
                    color="secondary"
                  >
                    خودروهای شرکت کرده
                  </Button>
                </div>
              </div>
            )}
          </DialogContent>

          {this.state.sendRequest ||
          this.state.getDataRequestErr ||
          this.state.getDataRequest ? (
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={25} />
            </DialogActions>
          ) : (
            <DialogActions>
              {(this.props.AccountStore.type === "admin" ||
                this.props.AccountStore.type === "agent") && (
                <FormControl style={{ flex: 1, margin: 10 }} label="وضعیت">
                  <Select
                    defaultValue={
                      this.state.status === "" ? "0" : this.state.status
                    }
                    variant="outlined"
                    margin={"dense"}
                    style={{ fontSize: 13 }}
                    onChange={(e) =>
                      this.setState({
                        status: e.target.value === "0" ? "" : e.target.value,
                      })
                    }
                  >
                    <MenuItem style={{ fontSize: 13 }} value={"0"}>
                      انتخاب وضعیت
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value={"accepted"}>
                      تایید
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value={"blocked"}>
                      مسدود
                    </MenuItem>
                  </Select>
                </FormControl>
              )}

              <Button onClick={() => this.submit()} color="primary">
                ثبت
              </Button>

              <Button onClick={() => this.handleClose()} color="secondary">
                بستن
              </Button>
            </DialogActions>
          )}
        </Dialog>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </div>
    );
  }
}

ChangeStatusDialog.defultProps = {};

ChangeStatusDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  history: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onUpdateItem: PropTypes.func,
  id: PropTypes.string.isRequired,
};
export default ChangeStatusDialog;
