import React from "react";
const Clerk = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 12.75C11.4142 12.75 11.75 12.4142 11.75 12C11.75 11.5858 11.4142 11.25 11 11.25V12.75ZM11 20.75C11.4142 20.75 11.75 20.4142 11.75 20C11.75 19.5858 11.4142 19.25 11 19.25V20.75ZM20.5588 12.5003C20.8351 12.1917 20.8089 11.7175 20.5003 11.4412C20.1917 11.1649 19.7175 11.1911 19.4412 11.4997L20.5588 12.5003ZM16.6308 15.7632L16.0721 15.2629L16.6308 15.7632ZM10.8692 15.7632L11.4279 15.2629H11.4279L10.8692 15.7632ZM10.5588 14.2921C10.2825 13.9835 9.80833 13.9573 9.49973 14.2336C9.19113 14.5099 9.16493 14.984 9.44123 15.2926L10.5588 14.2921ZM11.25 5C11.25 6.79493 9.79493 8.25 8 8.25V9.75C10.6234 9.75 12.75 7.62335 12.75 5H11.25ZM8 8.25C6.20507 8.25 4.75 6.79493 4.75 5H3.25C3.25 7.62335 5.37665 9.75 8 9.75V8.25ZM4.75 5C4.75 3.20507 6.20507 1.75 8 1.75V0.25C5.37665 0.25 3.25 2.37665 3.25 5H4.75ZM8 1.75C9.79493 1.75 11.25 3.20507 11.25 5H12.75C12.75 2.37665 10.6234 0.25 8 0.25V1.75ZM5 12.75H11V11.25H5V12.75ZM11 19.25H5V20.75H11V19.25ZM5 19.25C3.20507 19.25 1.75 17.7949 1.75 16H0.25C0.25 18.6234 2.37665 20.75 5 20.75V19.25ZM5 11.25C2.37665 11.25 0.25 13.3766 0.25 16H1.75C1.75 14.2051 3.20507 12.75 5 12.75V11.25ZM19.4412 11.4997L16.0721 15.2629L17.1896 16.2635L20.5588 12.5003L19.4412 11.4997ZM11.4279 15.2629L10.5588 14.2921L9.44123 15.2926L10.3104 16.2634L11.4279 15.2629ZM16.0721 15.2629C15.3894 16.0254 14.9296 16.5363 14.5365 16.8667C14.1621 17.1814 13.9421 17.25 13.75 17.25V18.75C14.4361 18.75 14.989 18.4458 15.5016 18.0149C15.9957 17.5997 16.5372 16.9921 17.1896 16.2635L16.0721 15.2629ZM10.3104 16.2634C10.9628 16.9921 11.5043 17.5997 11.9984 18.0149C12.511 18.4458 13.0639 18.75 13.75 18.75V17.25C13.5579 17.25 13.3379 17.1814 12.9635 16.8667C12.5704 16.5363 12.1106 16.0254 11.4279 15.2629L10.3104 16.2634Z"
        fill="#03A9F4"
      />
    </svg>
  );
};

export default Clerk;
