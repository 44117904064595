import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessageToFilteredButton,
  setResolved,
} from "../../Store/slice/sliceContactUs";
const FilterMessagesMobile = () => {
  const { resolved } = useSelector((state) => state.contactUs);
  const dispatch = useDispatch();

  return (
    <Box sx={{ display: "flex", width: 1, paddingRight: "30px" }}>
      <FormControl component="fieldset" variant="standard" sx={{ width: 1 }}>
        <FormGroup
          sx={{
            "& label": {
              justifyContent: "flex-end",
              position: "relative",
              fontSize: 14,
            },

            "& label span:last-child": {
              fontSize: 14,
            },

            "& .MuiButtonBase-root": {
              marginRight: "auto",
            },
            "& label:first-child::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              // background: "#D9D9D9",
              right: "-16px",
            },
            "& label:nth-child(2)::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              // background: "#42A5F5",
              right: "-16px",
            },
            "& label:nth-child(3)::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              // background: "#BA68C8",
              right: "-16px",
            },
            "& label:nth-child(4)::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              // background: "#FF9800",
              right: "-16px",
            },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  dispatch(setResolved("all"));
                  dispatch(
                    addMessageToFilteredButton({
                      message: "همه پیام ها",
                    })
                  );
                }}
                checked={resolved === "" || resolved === "all"}
              />
            }
            label="همه پیام ها"
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              marginInline: "unset",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  resolved === false || resolved === "all" || resolved === ""
                }
                onChange={() => {
                  dispatch(setResolved(false));
                  dispatch(
                    addMessageToFilteredButton({
                      message: "بررسی نشده ها",
                    })
                  );
                }}
              />
            }
            label="بررسی نشده ها"
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              marginInline: "unset",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  resolved === true || resolved === "all" || resolved === ""
                }
                onChange={() => {
                  dispatch(setResolved(true));
                  dispatch(
                    addMessageToFilteredButton({
                      message: "بررسی شده ها",
                    })
                  );
                }}
              />
            }
            label="بررسی شده ها"
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              marginInline: "unset",
            }}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default FilterMessagesMobile;
