import { observable, action, get } from "mobx";

class AgentModel {
  _id = null;
  name = "";
  username = "";
  phone = "";
  officePhone = "";
  password = "";
  address = "";
  location = "";
  map = "";
  latlng = [];
  experts = [];
  type = "";
  status = "";
  statusCode = 0;
  createdAt = "";
  updatedAt = "";
}

export default class Agents {
  @observable current = new AgentModel();
  @observable agents = [];

  @action setAgents(agents) {
    this.agents = agents;
  }

  @action setCurrent(item) {
    this.current = JSON.parse(JSON.stringify(item));
  }

  @action emptyCurrent() {
    this.current = new AgentModel();
  }

  @get getCurrent() {
    return JSON.parse(JSON.stringify(this.current));
  }
}
