exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".agentRequests_stylesPeper__2pDfN {\n  padding: 20px !important;\n}\n.agentRequests_btnAgentStyle__33t7z {\n  width: 150px;\n  margin: 5px !important;\n  overflow-x: hidden;\n}\n.agentRequests_btnSendStyle__1K94F {\n  text-align: end;\n}\n.agentRequests_titleReferral__1chyw {\n  margin-bottom: 10px;\n  font-weight: bold;\n}\n.agentRequests_itemAgentStyle__2EwjF {\n  margin-top: 20px;\n  justify-content: space-between;\n}\n.agentRequests_messageReference__liKis {\n  color: red;\n}\n.agentRequests_btnAndMessageStyle__2jf8P {\n  margin-top: 10px;\n}\n.agentRequests_selectReference__2L0yP {\n  font-weight: bold;\n}\n", ""]);

// Exports
exports.locals = {
	"stylesPeper": "agentRequests_stylesPeper__2pDfN",
	"btnAgentStyle": "agentRequests_btnAgentStyle__33t7z",
	"btnSendStyle": "agentRequests_btnSendStyle__1K94F",
	"titleReferral": "agentRequests_titleReferral__1chyw",
	"itemAgentStyle": "agentRequests_itemAgentStyle__2EwjF",
	"messageReference": "agentRequests_messageReference__liKis",
	"btnAndMessageStyle": "agentRequests_btnAndMessageStyle__2jf8P",
	"selectReference": "agentRequests_selectReference__2L0yP"
};