import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "../styles/main_app_style.css";

import stores from "../data/stores";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import {
  createTheme as createThemeV5,
  ThemeProvider as ThemeProviderV5,
} from "@mui/material/styles";

import { StylesProvider, jssPreset } from "@material-ui/styles";
import { create as createJss } from "jss";
import rtl from "jss-rtl";
import { create } from "mobx-persist";
import { Provider } from "mobx-react";
import Login from "./login/Login";
import { ApiClient, ApiHelper } from "../data/remote";
import { AppHistory } from "./AppHistory";
import Home from "./main/home/Home";
import { messaging } from "../firebase_init";
import ChangePassword from "./login/ChangePassword";
import Errors from "./main/errors/Errors";
import NewError from "./main/errorPage/NewError";
import Agents from "./main/agents/Agents";
import Admins from "./main/admins/admins";
import Locations from "./main/locations/Locations";
import AddEditAgent from "./main/agents/AddEditAgent";
import Categories from "./main/categories/Categories";
import AddEditCategory from "./main/categories/AddEditCategory";
import Cars from "./main/cars/Cars";
import AddEditCar from "./main/cars/AddEditCar";
import Brokers from "./main/brokers/Brokers";
import ReportingBroker from "./main/brokers/reporting/reportingBroker";
import AddEditBroker from "./main/brokers/AddEditBroker";
import Payments from "./main/brokers_payment/Payments";
import Requests from "./main/user_requests/Requests";
import Registrations from "./main/registration/Registrations";
import Experts from "./main/experts/Experts";
import AddEditExpert from "./main/experts/AddEditExpert";
import Sellers from "./main/sellers/Sellers";
import AddEditSeller from "./main/sellers/AddEditSeller";
import Backups from "./main/backup/Backups";
import AddEditCarHtml from "./main/cars/AddEditCarHtml";
import CarPrice from "./main/car_price/CarPrice";
import ImageEditor from "./main/imageEditor/ImageEditor";
import Logs from "./main/Logs/Logs";
import Contracts from "./main/contracts/contracts";
import Brands from "./main/brands/brand";
import AddEditCoworkers from "../components/main/coworker/AddEditCoworker";
import Coworkers from "./main/coworker/Coworkers";
import TimeManagement from "./main/time_management/timeManagement";
import ExclusiveLinks from "./main/exclusive_links/exclusiveLinks";
import AddSellRequest from "./main/time_management/AddSellRequest";
import Reporting from "./main/contracts/reporting/reporting";
import AddEditAdmin from "./main/admins/addEditAdmin";
import ReportingRequests from "./main/user_requests/reporting/ReportingRequests";
import Information from "./main/information/Information";
ContactUs;
// import AddEditInfoRequestsCity from "../features/info-requests/AddEditInfoRequestsCity";
import SellCreated from "./main/newRequest/SellCreated";
import BuyCreated from "./main/newRequestBuy/BuyCreated";
import ContactUs from "./main/contact-us/ContactUs";
var moment = require("moment-timezone");
let apiClient = null;

const hydrate = create({
  storage: localStorage, // or AsyncStorage in react-native.// default: localStorage
  jsonify: true, // if you use AsyncStorage, here shoud be true
  // default: true
});

const COUNT_OF_HYDRATIONS = 2;

const jss = createJss({ plugins: [...jssPreset().plugins, rtl()] });

class MainRouter extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    this.state = {
      hydrate: 0,
      sendRequest: false,
      fcmID: "NA",
      fcmIDisUpdated: false,
    };

    moment.tz.setDefault("Asia/Tehran");
    this.initFCM();
  }

  initFCM() {
    if (!messaging) return;
    messaging
      .requestPermission()
      .then(() => {
        messaging
          .getToken({
            vapidKey:
              "BNKaxslqFzdSY2hoUSPR4BmPgtZuqr5k_eqNyREHbqwOEsggVooUYVXK6SG-otiRhT4phuPFnsJd4hrIcbpeKMk",
          })
          .then((token) => {
            this.state.fcmID = token;
            stores.AccountStore.fcmId = token;
            this.updateFCMID();
          })
          .catch(function (err) {
            console.log("Unable to get token .", err);
          });
      })
      .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
      });

    // messaging.onMessage((payload) => {
    //    console.log("onMessaging",payload)
    // });
  }

  updateFCMID() {
    if (
      !stores.AccountStore.isLogin() ||
      this.state.fcmID === "NA" ||
      this.state.fcmIDisUpdated
    )
      return;
    apiClient = ApiClient.getInstance();
    apiClient.post(
      ApiHelper.updateFCMID_POST,
      (error, response) => {
        if (!error) {
          stores.AccountStore.setSessions(response.data);
          this.state.fcmIDisUpdated = true;
        }
      },
      { fcmId: this.state.fcmID }
    );
  }

  checkStatus() {
    this.checkStatusRequest();
    this.checkStatusInterval = setInterval(() => {
      this.checkStatusRequest();
    }, 7000);
  }

  updateProfile() {
    if (!stores.AccountStore.isLogin()) return;
    apiClient = ApiClient.getInstance();
    apiClient.get(ApiHelper.getProfileInfo_GET, (error, response) => {
      if (!error) {
        stores.AccountStore.profile = response.data;
      }
    });
  }

  checkStatusRequest() {
    if (this.state.sendRequest || !stores.AccountStore.isLogin()) return;

    apiClient = ApiClient.getInstance();
    this.state.sendRequest = true;
    apiClient.get(ApiHelper.getAdminNotification_GET, (error, response) => {
      this.state.sendRequest = false;
      if (!error) {
        console.log("response.notifications: ", response.notifications);
        stores.AccountStore.setNotifications(response.notifications);
      }
    });
  }

  componentWillUnmount() {
    if (this.checkStatusInterval) clearInterval(this.checkStatusInterval);
  }

  componentDidMount() {
    document.getElementsByClassName("preloader")[0].style.display = "none";

    hydrate("accountStoreAdmin", stores.AccountStore)
      .then(() => {
        this.checkHydrate();
      })
      .catch(() => this.checkHydrate());

    hydrate("locationsStore", stores.LocationsStore)
      .then(() => {
        this.checkHydrate();
      })
      .catch(() => this.checkHydrate());

    this.getServerTime();
  }

  getServerTime() {
    apiClient = ApiClient.getInstance();

    apiClient.get(ApiHelper.getServerTime_GET, (error, response) => {
      if (error) {
        stores.AccountStore.serverTime = null;
      } else {
        stores.AccountStore.setServerTime(response.currentServerTime);
      }
    });
  }

  checkHydrate() {
    this.state.hydrate += 1;
    if (this.state.hydrate === COUNT_OF_HYDRATIONS) {
      this.setState({ hydrate: COUNT_OF_HYDRATIONS }, () => {
        if (stores.AccountStore.type !== "expert") {
          this.checkStatus();
          this.updateFCMID();
          this.updateProfile();
        }
      });
    }
  }

  renderSplash() {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          padding: 20,
          alignItems: "center",
        }}
      >
        <h3>کمی صبر کنید...</h3>
      </div>
    );
  }

  render() {
    if (this.state.hydrate < COUNT_OF_HYDRATIONS) {
      return this.renderSplash();
    } else {
      return (
        <StylesProvider jss={jss}>
          <MuiThemeProvider theme={THEME}>
            <ThemeProviderV5 theme={THEME_MUI5}>
              <Provider {...stores}>
                <BrowserRouter basename={"nk_admin/"}>
                  <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/locations" component={Locations} />
                    <Route exact path="/categories" component={Categories} />
                    <Route
                      exact
                      path="/add_edit_category"
                      component={AddEditCategory}
                    />
                    <Route exact path="/admins/:status" component={Admins} />
                    <Route
                      exact
                      path="/add_edit_admin"
                      component={AddEditAdmin}
                    />
                    <Route exact path="/agents/:status" component={Agents} />
                    <Route
                      exact
                      path="/add_edit_agent"
                      component={AddEditAgent}
                    />
                    <Route
                      exact
                      path="/time_management/:status"
                      component={TimeManagement}
                    />
                    <Route
                      exact
                      path="/add_sell_request"
                      component={AddSellRequest}
                    />
                    <Route exact path="/experts/:status" component={Experts} />
                    <Route
                      exact
                      path="/coworkers/:status"
                      component={Coworkers}
                    />
                    <Route
                      exact
                      path="/add_edit_coworkers"
                      component={AddEditCoworkers}
                    />
                    <Route
                      exact
                      path="/add_edit_expert"
                      component={AddEditExpert}
                    />
                    <Route exact path="/sellers/:status" component={Sellers} />
                    <Route
                      exact
                      path="/add_edit_seller"
                      component={AddEditSeller}
                    />
                    <Route
                      exact
                      path="/cars/:status/:type/:page?"
                      component={Cars}
                    />
                    <Route
                      exact
                      path="/add_edit_car/:id"
                      component={AddEditCar}
                    />
                    <Route
                      exact
                      path="/add_edit_car_html/:id"
                      component={AddEditCarHtml}
                    />
                    <Route exact path="/car_price" component={CarPrice} />
                    <Route
                      exact
                      path="/editing_image"
                      component={ImageEditor}
                    />
                    <Route
                      exact
                      path="/requests/reporting"
                      component={ReportingRequests}
                    />
                    <Route
                      exact
                      path="/brokers/:status/:agentId?/:page?"
                      component={Brokers}
                    />
                    <Route
                      exact
                      path="/brokerss/reporting"
                      component={ReportingBroker}
                    />
                    <Route
                      exact
                      path="/add_edit_broker/:status?/:agentId?/:page?"
                      component={AddEditBroker}
                    />
                    <Route
                      exact
                      path="/payments/:status/:page?"
                      component={Payments}
                    />
                    <Route
                      exact
                      path="/requests/:status/:type/:page?"
                      component={Requests}
                    />{" "}
                    <Route
                      path="/request-new/sell/created"
                      component={SellCreated}
                    />
                    <Route
                      path="/requests-new/created/buy"
                      component={BuyCreated}
                    />
                    <Route
                      exact
                      path="/registrations/:status"
                      component={Registrations}
                    />
                    <Route exact path="/dashboard" component={Home} />
                    <Route exact path="/backup" component={Backups} />
                    <Route exact path="/errors" component={Errors} />
                    <Route exact path="/newerrors" component={NewError} />
                    <Route exact path="/password" component={ChangePassword} />
                    <Route exact path="/logs" component={Logs} />
                    <Route
                      exact
                      path="/contracts/:type"
                      component={Contracts}
                    />
                    <Route exact path="/brands" component={Brands} />
                    <Route
                      exact
                      path="/manage_req"
                      component={TimeManagement}
                    />
                    <Route
                      exact
                      path="/exclusive_links"
                      component={ExclusiveLinks}
                    />
                    <Route exact path="/reporting" component={Reporting} />
                    <Route
                      exact
                      path="/information/:archived?"
                      component={Information}
                    />
                    {/* <Route exact path="/information" component={Information} /> */}
                    {/* <Route
                    exact
                    path="/add_edit_city/:city?"
                    component={AddEditInfoRequestsCity}
                  /> */}
                    <Route exact path="/backup" component={Backups} />
                    <Route exact path="/errors" component={Errors} />
                    <Route exact path="/password" component={ChangePassword} />
                    <Route exact path="/logs" component={Logs} />
                    <Route
                      exact
                      path="/contracts/:type"
                      component={Contracts}
                    />
                    <Route exact path="/brands" component={Brands} />
                    <Route
                      exact
                      path="/manage_req"
                      component={TimeManagement}
                    />
                    <Route
                      exact
                      path="/exclusive_links"
                      component={ExclusiveLinks}
                    />
                    <Route exact path="/reporting" component={Reporting} />
                    <Route exact path="/information" component={Information} />
                    <Route exact path="/contact-us" component={ContactUs} />
                    {/* <Route
                      exact
                      path="/add_edit_city/:city?"
                      component={AddEditInfoRequestsCity}
                    /> */}
                    <Route path={"*"} component={Home} />
                  </Switch>

                  <AppHistory />
                </BrowserRouter>
              </Provider>
            </ThemeProviderV5>
          </MuiThemeProvider>
        </StylesProvider>
      );
    }
  }
}

const THEME = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "IRANSans_LFN",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: "#43acff",
      main: "#129DFF",
      dark: "#106aa7",
      contrastText: "#fff",
    },
    secondary: {
      light: "#22e79a",
      main: "#19B87A",
      dark: "#0f704a",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "0 0 20px 0 rgba(0,0,0,0.1)",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          color: "#828282",
        },
      },
    },
    MuiTabs: {
      indicator: { backgroundColor: "#129DFF" },
    },
  },
});

const THEME_MUI5 = createThemeV5({
  typography: {
    fontFamily: "IRANSans_LFN",
  },
  direction: "rtl",
});

export default MainRouter;
