import React from "react";
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { skeletonChart } from "../../styles/main";
const LoadingSkeleton = ({ isLoading }) => {
  const skeleton = skeletonChart();
  const theme = useTheme();
  const isMatchesTablet = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {Array.from(
        { length: 20 },
        (_, index) =>
          isLoading && (
            <Skeleton
              key={index}
              height={81}
              variant="recent"
              className={`${skeleton.root} ${
                isMatchesTablet && skeleton.tablet
              }`}
            />
          )
      )}
    </>
  );
};

export default LoadingSkeleton;
