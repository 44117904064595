import React from "react";
import { HeadsetMic, DesktopMac } from "@material-ui/icons";

const BtnsClerkSellRequest = ({ clerkSellRequest, propClass }) => {
  return (
    <>
      {clerkSellRequest ? (
        <div className={propClass}>
          <HeadsetMic />
          مدیریت زمان
        </div>
      ) : (
        <div className={propClass}>
          <DesktopMac style={{ marginLeft: "3px" }} />
          سایت
        </div>
      )}
    </>
  );
};

export default BtnsClerkSellRequest;
