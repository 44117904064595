import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
var momentServerOffset = require("moment");
var moment = require("moment-jalaali");
import StarRater from "../../common/StarRater/StartRater";
import styles from "./broker.module.css";

export default function ListItems({
  name = "",
  phone = "",
  brokerId,
  sellerName,
  changeEnableSMSStatus,
  activityType,
  changeEnableSMS,
  sessions,
  logoutPWAOnClick,
  enableSMS,
  isOnline,
  onlineTime,
  status,
  onClickChangeSeller,
  onClickEdit,
  onClickStatus,
  statusIndicator = false,
  PositiveRate,
  NegativeRate,
  selectAllBroker,
  handleSelectBrokerId,
  selectFirstBroke,
}) {
  let online = isOnline;
  if (
    !!onlineTime &&
    moment.duration(moment(onlineTime).diff(momentServerOffset())).asMinutes() <
      -120
  ) {
    online = false;
  }
  const [selectBroker, setSelectBroker] = useState(false);

  let isPWA = sessions && sessions.findIndex((t) => t.os === "pwa") >= 0;
  let isMobile = sessions && sessions.findIndex((t) => t.os === "mobile") >= 0;

  useEffect(() => {
    if (selectAllBroker) {
      setSelectBroker(true);
    } else {
      setSelectBroker(false);
    }
  }, [selectAllBroker]);

  const handleItemClick = () => {
    handleSelectBrokerId(brokerId);
    setSelectBroker(!selectBroker);
  };

  return (
    <div
      className={
        selectBroker ? styles.brokerSelectItemStyle : styles.brokerItemStyle
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1 }}>
          <div
            className={styles.clickItemBrokerStyle}
            onClick={() => handleItemClick()}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                marginTop: "5px",
              }}
            >
              {!!PositiveRate && (
                <div style={{ margin: 5 }}>
                  <StarRater rate={PositiveRate} readOnly state="positive" />
                </div>
              )}
              {!!NegativeRate && (
                <div style={{ margin: 5 }}>
                  <StarRater rate={NegativeRate} readOnly state="negative" />
                </div>
              )}
              {selectFirstBroke && (
                <Tooltip title="انتخاب این بروکر" placement="right">
                  <FormControlLabel
                    className={styles.checedSelectBrokerStyle}
                    control={
                      <Checkbox
                        checked={selectBroker}
                        icon={
                          <Brightness1OutlinedIcon
                            className={styles.iconUnChekedStyle}
                            fontSize="large"
                          />
                        }
                        checkedIcon={
                          <CheckCircleRoundedIcon
                            color="primary"
                            className={styles.iconChekedStyle}
                            fontSize="large"
                          />
                        }
                        name="checkedH"
                      />
                    }
                  />
                </Tooltip>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 style={{ margin: 5 }}>{name}</h4>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 style={{ margin: 5 }}>{phone}</h4>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ margin: 5 }}>{activityType}</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ margin: 5 }}>فروشنده</p>
              <p style={{ margin: 5 }}>{sellerName}</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ margin: 5 }}>وضعیت</p>
              <p style={{ margin: 5 }}>{status}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: 14,
                height: 14,
                margin: 5,
                borderRadius: 7,
                backgroundColor: online ? "green" : "orange",
              }}
            ></div>
            {onlineTime && (
              <p
                style={{
                  flex: 1,
                  fontSize: 12,
                  margin: 5,
                }}
              >
                {moment(onlineTime).format("jYYYY/jM/jD , HH:mm")}
              </p>
            )}

            {isPWA && (
              <IconButton
                onClick={logoutPWAOnClick}
                size="small"
                style={{ float: "left", margin: 2 }}
              >
                {changeEnableSMSStatus ? (
                  <CircularProgress size={18} color="inherit" />
                ) : (
                  <img
                    width={25}
                    height={25}
                    src={require("../../../assets/images/pwa.svg")}
                  />
                )}
              </IconButton>
            )}
            {isMobile && (
              <IconButton size="small" style={{ float: "left", margin: 2 }}>
                <img
                  width={25}
                  height={25}
                  src={require("../../../assets/images/mobile.svg")}
                />
              </IconButton>
            )}
            <IconButton
              onClick={() => isPWA && changeEnableSMS()}
              size="small"
              style={{ float: "left", margin: 2 }}
            >
              {changeEnableSMSStatus ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                <img
                  width={25}
                  height={25}
                  src={
                    !isPWA
                      ? require("../../../assets/images/sms.svg")
                      : enableSMS
                      ? require("../../../assets/images/sms_on.svg")
                      : require("../../../assets/images/sms_off.svg")
                  }
                />
              )}
            </IconButton>
          </div>
        </div>
      </div>

      <Divider style={{ marginTop: 5, marginBottom: 0 }} />
      <div style={{ display: "flex" }}>
        <Button onClick={onClickEdit} color="primary" style={{ marginTop: 2 }}>
          ویرایش
        </Button>
        <Button
          onClick={onClickStatus}
          color="secondary"
          style={{ marginTop: 2 }}
        >
          {statusIndicator ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            "وضعیت"
          )}
        </Button>

        {onClickChangeSeller && (
          <Button
            onClick={onClickChangeSeller}
            color="secondary"
            style={{ marginTop: 2 }}
          >
            تغییر فروشنده
          </Button>
        )}
      </div>
    </div>
  );
}
