import React, { memo, useState } from "react";
import createCache from "@emotion/cache";
import { CacheProvider, useTheme } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useGetAllCoworkersQuery } from "../../Store/callApi/apiCoworkers";
import { useDispatch } from "react-redux";
import { setUserId } from "../../Store/slice/sliceNewRequest";

const CoworkerActivity = memo(() => {
  const theme = useTheme();
  const maxWidthLg = useMediaQuery(theme.breakpoints.down("lg"));
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, stylisRTLPlugin],
  });

  const [coworkerId, setCoworkerId] = useState("");
  const dispatch = useDispatch();
  const {
    data: coworkersData,
    isLoading,
    isSuccess,
  } = useGetAllCoworkersQuery({
    status: "accepted",
    type: "coworker",
  });
  const handleChange = (e) => {
    setCoworkerId(e.target.value);
    dispatch(setUserId(e.target.value));
  };
  return (
    <CacheProvider value={cacheRtl}>
      <FormControl
        sx={{
          display: maxWidthLg && "none",
          borderRadius: 1,
          boxShadow: 2,
          border: "unset !important",
          width: { xl: "168px", lg: "120px" },
          ".MuiFormLabel-root.MuiInputLabel-root": {
            color: "info.dark",
            top: "-7px",
            "&.Mui-focused": {
              top: "0 !important",
            },
          },
          "& .MuiInputBase-root .MuiSvgIcon-root": {
            color: "info.dark",
          },
        }}
      >
        <InputLabel id="coworker">همکار</InputLabel>
        <Select
          // multiple
          value={coworkerId}
          labelId="coworker"
          label="همکار"
          disabled={!isSuccess}
          sx={{
            bgcolor: "#fff",
            ".MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
              height: "unset",
              padding: "8px 16px",
            },
          }}
          onChange={handleChange}
        >
          <MenuItem value="all">همه</MenuItem>
          {isSuccess &&
            coworkersData?.records.map((coworker) => (
              <MenuItem
                disableRipple
                disableTouchRipple
                key={coworker._id}
                value={coworker._id}
              >
                {coworker.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </CacheProvider>
  );
});

export default CoworkerActivity;
