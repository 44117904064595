import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addTeamToFilteredButton,
  setBranch,
} from "../../Store/slice/sliceContactUs";
const DepartmentContactus = () => {
  const { branch } = useSelector((state) => state.contactUs);
  const dispatch = useDispatch();
  return (
    <Box sx={{ display: "flex", width: 1, paddingRight: "30px" }}>
      <FormControl component="fieldset" variant="standard" sx={{ width: 1 }}>
        <FormGroup
          sx={{
            "& label": {
              justifyContent: "flex-end",
              position: "relative",
              fontSize: 14,
            },

            "& label span:last-child": {
              fontSize: 14,
            },

            "& .MuiButtonBase-root": {
              marginRight: "auto",
            },
            "& label:first-child::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              background: "#D9D9D9",
              right: "-16px",
            },
            "& label:nth-child(2)::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              background: "#FF9800",
              right: "-16px",
            },
            "& label:nth-child(3)::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              background: "#EF5350",
              right: "-16px",
            },
            "& label:nth-child(4)::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              background: "#BA68C8",
              right: "-16px",
            },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={branch === "all" || branch === ""}
                onChange={() => {
                  dispatch(setBranch("all"));
                  dispatch(addTeamToFilteredButton({ team: "همه تیم ها" }));
                }}
              />
            }
            label="همه"
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              marginInline: "unset",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  branch === "support" || branch === "all" || branch === ""
                }
                onChange={() => {
                  dispatch(setBranch("support"));
                  dispatch(addTeamToFilteredButton({ team: "کارشناسی" }));
                }}
              />
            }
            label="کارشناسی"
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              marginInline: "unset",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  dispatch(setBranch("tech"));
                  dispatch(addTeamToFilteredButton({ team: "فنی" }));
                }}
                checked={branch === "tech" || branch === "all" || branch === ""}
              />
            }
            label="فنی"
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              marginInline: "unset",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  branch === "management" || branch === "all" || branch === ""
                }
                onChange={() => {
                  dispatch(setBranch("management"));
                  dispatch(addTeamToFilteredButton({ team: "مدیریت" }));
                }}
              />
            }
            label="مدیریت"
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              marginInline: "unset",
            }}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default DepartmentContactus;
