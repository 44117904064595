import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  recent: true,
  search: "",
  page: "1",
  createdAt: "",
  reservedAt: "",
  data: null,
  dateMutual: new Date().toISOString().slice(0, 10),
  userId: "",
  responsibeCoworker: [],
  checkedItems: [],
  isSelectAll: false,
  isShowAllDays: false,
  isRequestDate: false,
  isReserveDate: false,
  activeRequestDate: "",
  activeFilterDate: "",
  status: "",
  isLoadingTakeOver: false,
  activeStatus: "",
  isRequestDateMobile: "submit",
  statusCheckbox: [],
};

const sliceNewRequest = createSlice({
  name: "newReqest",
  initialState,
  reducers: {
    updateNewRequestState: (state, action) => {
      state.data = [action.payload];
    },
    setSearchQuery: (state, action) => {
      state.search = action.payload;
    },
    changeRecentQuery: (state, action) => {
      state.recent = action.payload;
    },

    setUserId: (state, action) => {
      state.userId = action.payload;
    },

    emptyUserId: (state) => {
      state.userId = initialState.userId;
    },

    setPageQuery: (state, action) => {
      state.page = action.payload;
    },

    setResponsibleCoworker: (state, action) => {
      const hasRequest = state.responsibeCoworker.some(
        (req) => req === action.payload
      );
      if (!hasRequest) {
        state.responsibeCoworker = [
          ...state.responsibeCoworker,
          action.payload,
        ];
      }
      return;
    },

    setRequestIds: (state, action) => {
      const hasInCheckedItems = state.checkedItems.some(
        (item) => item === action.payload
      );
      if (!hasInCheckedItems) {
        state.checkedItems = [...state.checkedItems, action.payload];
      }

      if (hasInCheckedItems) {
        state.checkedItems = state.checkedItems.filter(
          (item) => item !== action.payload
        );
      }
    },

    emptyRequestIds: (state) => {
      state.checkedItems = [];
      state.isSelectAll = false;
    },

    selectAllCheckbox: (state, action) => {
      action.payload.map((item) => {
        if (item.responsibleCoworker) {
          state.checkedItems.push(item._id);
        }
      });
      state.isSelectAll = true;
    },

    setCreatedAt: (state) => {
      state.createdAt = state.dateMutual;
      state.reservedAt = initialState.reservedAt;
    },

    setReservedAt: (state) => {
      state.reservedAt = state.dateMutual;
      state.createdAt = initialState.createdAt;
    },

    setDateMutual: (state, action) => {
      state.dateMutual = action.payload;
    },
    setIsShowAllDays: (state) => {
      state.isShowAllDays = true;
    },

    setIsRequestDate: (state) => {
      state.isReserveDate = false;
      state.isRequestDate = true;
    },

    setIsReserveDate: (state) => {
      state.isRequestDate = false;
      state.isReserveDate = true;
    },

    removeDateFilters: (state) => {
      state.createdAt = initialState.createdAt;
      state.reservedAt = initialState.reservedAt;
      state.dateMutual = initialState.dateMutual;
      state.isShowAllDays = false;
    },

    setActiveRequestDate: (state) => {
      state.activeRequestDate = "submit";
    },

    removeActiveRequestDate: (state) => {
      state.activeRequestDate = initialState.activeRequestDate;
    },

    setActiveFilterDate: (state, action) => {
      state.activeFilterDate = action.payload;
    },

    setStatusParams: (state, action) => {
      state.status = action.payload;
    },

    setIsLoadingTakeOver: (state, action) => {
      state.isLoadingTakeOver = action.payload;
    },

    setActiveStatus: (state, action) => {
      state.activeStatus = action.payload;
    },

    setIsRequestDateMobile: (state, action) => {
      state.isRequestDateMobile = action.payload;
    },

    setStatusCheckbox: (state, action) => {
      const hasStatus = statusCheckbox.some(
        (status) => status === action.payload
      );

      if (hasStatus) return;
      state.statusCheckbox.push(action.payload);
    },
  },
});

export const {
  updateNewRequestState,
  setSearchQuery,
  setPageQuery,
  changeRecentQuery,
  setUserId,
  setResponsibleCoworker,
  setRequestIds,
  emptyRequestIds,
  selectAllCheckbox,
  setDateMutual,
  setIsShowAllDays,
  setCreatedAt,
  setReservedAt,
  setIsReserveDate,
  setIsRequestDate,
  removeDateFilters,
  setActiveRequestDate,
  removeActiveRequestDate,
  setActiveFilterDate,
  setStatusParams,
  emptyUserId,
  setIsLoadingTakeOver,
  setActiveStatus,
  setIsReserveDateMobile,
  setIsRequestDateMobile,
} = sliceNewRequest.actions;
export default sliceNewRequest.reducer;
