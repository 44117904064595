function GetDataImage(image, crop, fileName, maxWidth = 1200) {
  const canvas = document.createElement("canvas");

  let scaleX = image.naturalWidth / image.width;
  let scaleY = image.naturalHeight / image.height;

  let scaleX2 = image.naturalWidth / image.width;
  let scaleY2 = image.naturalHeight / image.height;

  if (crop === null) {
    if (image.width > maxWidth) {
      let r = image.width / image.height;
      canvas.width = maxWidth;
      canvas.height = maxWidth / r;
    } else {
      canvas.width = image.width;
      canvas.height = image.height;
    }
  } else {
    if (!crop.aspect) {
      crop.aspect = crop.width / crop.height;
    }
    if (crop.aspect !== 1) {
      if (crop.width * scaleX2 > maxWidth || crop.height * scaleY2 > maxWidth) {
        scaleX2 = maxWidth / crop.width;
        scaleY2 = maxWidth / crop.aspect / crop.height;
      }
    } else {
      if (crop.width * scaleX2 > maxWidth || crop.height * scaleY2 > maxWidth) {
        scaleX2 = maxWidth / crop.width;
        scaleY2 = maxWidth / crop.height;
      }
    }

    canvas.width = crop.width * scaleX2;
    canvas.height = crop.height * scaleY2;
  }

  const ctx = canvas.getContext("2d");

  if (crop === null) {
    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
  } else {
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX2,
      crop.height * scaleY2
    );
  }

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        blob.name = fileName + Math.random().toString().substr(-5) + ".jpg";

        resolve({
          base64: canvas.toDataURL("image/jpeg", 0.8),
          blob: blob,
        });
      },
      "image/jpeg",
      0.8
    );
  });
}

export default GetDataImage;
