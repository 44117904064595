exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".reporting_paperStyle__1Fi3F {\n  padding: 0 10px !important;\n  margin: 20px 0;\n}\n.reporting_rowBoxStyle__1uIBm {\n  padding: 10px 0 !important;\n}\n.reporting_cityBoxStyle__2YOGS {\n  padding: 10px 0 !important;\n  opacity: 0.7;\n}\n.reporting_sendRequestStyle__3Zpnj {\n  color: #f68b0b;\n  font-size: 17px;\n  font-weight: bold;\n}\n.reporting_gridSpaceStyle__3e6jz {\n  padding-top: 10px;\n  display: flex;\n  align-items: center;\n}\n.reporting_searchBoxStyle__3tRaA {\n  display: flex;\n  align-items: center;\n  padding: 10px 0;\n}\n.reporting_typeRequestStyle__1W5Qd {\n  padding: 10px 3px;\n  border: 1px solid #ddd;\n  display: flex;\n  justify-content: space-between;\n}\n.reporting_itemRequestStyle__2BzmR {\n  display: flex;\n  justify-content: center;\n}\n.reporting_iconNameRequestStyle__3Q1GG {\n  display: flex;\n  align-items: center;\n}\n.reporting_iconAndBtnRequest__KyuXH {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.reporting_titleAccordionStyle__Io0mx {\n  min-height: 0 !important;\n}\n.reporting_linearProgressStyle__3I44Z {\n  width: 100%;\n  margin: 5px 0;\n}\n.reporting_SkeletonStyle__1MFhN {\n  width: 100%;\n  height: 80px !important;\n  margin: 10px;\n}\n.reporting_spasingStyle__3FGTs {\n  padding: 6px 20px !important;\n}\n", ""]);

// Exports
exports.locals = {
	"paperStyle": "reporting_paperStyle__1Fi3F",
	"rowBoxStyle": "reporting_rowBoxStyle__1uIBm",
	"cityBoxStyle": "reporting_cityBoxStyle__2YOGS",
	"sendRequestStyle": "reporting_sendRequestStyle__3Zpnj",
	"gridSpaceStyle": "reporting_gridSpaceStyle__3e6jz",
	"searchBoxStyle": "reporting_searchBoxStyle__3tRaA",
	"typeRequestStyle": "reporting_typeRequestStyle__1W5Qd",
	"itemRequestStyle": "reporting_itemRequestStyle__2BzmR",
	"iconNameRequestStyle": "reporting_iconNameRequestStyle__3Q1GG",
	"iconAndBtnRequest": "reporting_iconAndBtnRequest__KyuXH",
	"titleAccordionStyle": "reporting_titleAccordionStyle__Io0mx",
	"linearProgressStyle": "reporting_linearProgressStyle__3I44Z",
	"SkeletonStyle": "reporting_SkeletonStyle__1MFhN",
	"spasingStyle": "reporting_spasingStyle__3FGTs"
};