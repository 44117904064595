import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ContactUsModal from "./ContactUsModal";
import { dateStringNumber } from "../../utils/persianDate/dateStringNumber";
import { dateFormatter } from "../../utils/persianDate/dateFormatter";

export default function OpenModalButton({ message = {} }) {
  const { createdAt } = message;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Button
        sx={{
          borderBottom: "1px solid #2196F3",
          color: "#2196F3",
          borderRadius: "unset",
          paddingBottom: "3px !important",
          lineHeight: 1,
          "&:hover": {
            bgcolor: "unset",
          },
        }}
        onClick={handleOpen}
      >
        مشاهده
      </Button>
      {open && (
        <ContactUsModal
          date={dateStringNumber(createdAt)}
          open={open}
          onClose={handleClose}
          clock={dateFormatter(createdAt, false).clock().slice(0, 5)}
          message={message}
        />
      )}
    </Box>
  );
}
