import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import RequestState from "../../common/RequestState";
import { Styles } from "../../../values/Styles";
import AlertDialog from "../../common/AlertDialog";
import PaginationView from "../../common/PaginationView";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
var moment = require("moment-jalaali");
let apiClient = null;

@inject("AccountStore")
@observer
class Backups extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    this.state = {
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      message: "",
      alertDialogQuestionMessage: "",
      backupRequest: false,
      restoreRequestIndex: -1,
      path: "",
    };

    this.alertFunction = null;
  }

  componentDidMount() {
    this.getRequests();
  }

  restore(item, index) {
    this.alertFunction = this.restoreRequest.bind(this, item.filename, index);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به ریستور بکاپ هستید ؟",
    });
  }

  backup() {
    this.alertFunction = this.backupRequest.bind(this);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به ایجاد بکاپ هستید ؟",
    });
  }

  backupRequest() {
    if (this.state.backupRequest) {
      return;
    }

    this.setState({ backupRequest: true });

    apiClient.get(ApiHelper.getServerAddBackups_GET, (error, response) => {
      if (error) {
        this.setState({
          backupRequest: false,
          message: response ? response.message : "",
        });
      } else {
        if (response.data) {
          this.state.data = [response.data, ...this.state.data];
        }
        this.setState({ backupRequest: false });
      }
    });
  }

  restoreRequest(filename, index) {
    if (this.state.restoreRequestIndex > 0) {
      return;
    }

    this.setState({ restoreRequestIndex: index });

    apiClient.get(
      ApiHelper.getServerRestore_GET(filename),
      (error, response) => {
        if (error) {
          this.setState({
            restoreRequestIndex: -1,
            message: response ? response.message : "",
          });
        } else {
          if (response.data) {
            this.state.data[index] = response.data;
          }
          this.setState({ restoreRequestIndex: -1 });
        }
      }
    );
  }

  renderResult() {
    if (this.state.data.length <= 0) {
      return <div style={{ margin: 10 }}>{`لیست بکاپ خالی.`}</div>;
    } else {
      return (
        <div style={{ ...Styles.containerWrap900, padding: 10 }}>
          {this.state.data.map((item, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                maxWidth: 430,
                padding: 4,
                border: "1px solid #d2d2d2",
              }}
            >
              <p
                style={{
                  fontFamily: "tahoma",
                  border: "1px solid #d2d2d2",
                  direction: "ltr",
                  flex: 1,
                  margin: 5,
                  padding: 2,
                  minWidth: 300,
                  color: "#5f5f5f",
                }}
              >
                {item.filename}
              </p>
              <p style={{ flex: 1, margin: 5, minWidth: 300, color: "#000" }}>
                {item.status}
              </p>
              <p style={{ flex: 1, margin: 5, minWidth: 300, color: "#000" }}>
                {item.desc}
              </p>
              {item.createdAt && (
                <p
                  style={{
                    flex: 1,
                    margin: 5,
                    minWidth: 300,
                    color: "#969696",
                  }}
                >
                  تاریخ ایجاد :{" "}
                  {moment(item.createdAt).format("jYYYY/jM/jD _ HH:mm")} __
                  تاریخ دستور :{" "}
                  {moment(item.updatedAt).format("jYYYY/jM/jD _ HH:mm")}
                </p>
              )}

              <Button
                variant="contained"
                onClick={() => this.restore(item, i)}
                style={{ margin: 5 }}
                color={item.registered ? "primary" : "secondary"}
                size="small"
              >
                {this.state.restoreRequestIndex === i ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "ریستور (احتیاط)"
                )}
              </Button>
            </div>
          ))}
        </div>
      );
    }
  }

  getRequests() {
    if (this.state.sendRequest) {
      return;
    }

    this.setState({ sendRequest: true, sendRequestErr: false });

    apiClient.get(ApiHelper.getServerGetBackups_GET, (error, response) => {
      if (error) {
        this.setState({
          sendRequest: false,
          sendRequestErr: true,
          message: response ? response.message : "",
        });
      } else {
        this.setState({
          sendRequest: false,
          sendRequestErr: false,
          data: response.records,
          path: response.path,
        });
      }
    });
  }

  render() {
    return (
      <Main
        page={"backup"}
        pageName={"پکاپ ها"}
        centerAppBarComponent={
          <RequestState
            error={this.state.sendRequestErr}
            retryOnPress={() => this.getRequests()}
            title={"در حال دریافت لیست ..."}
            request={this.state.sendRequest}
          />
        }
        context={this.props.history}
      >
        <Button
          variant="contained"
          style={{ margin: 10 }}
          onClick={() => this.backup()}
          size="small"
          color="primary"
        >
          {this.state.backupRequest ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            "ایجاد بکاپ"
          )}
        </Button>

        <IconButton onClick={() => this.getRequests()}>
          <Icon>sync</Icon>
        </IconButton>

        <p
          style={{
            fontFamily: "tahoma",
            backgroundColor: "#d2d2d2",
            direction: "ltr",
            wordBreak: "break-all",
            flex: 1,
            margin: 5,
            padding: 2,
            minWidth: 300,
            color: "#5f5f5f",
          }}
        >
          path : {this.state.path}
        </p>

        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            {this.renderResult()}
          </div>
        </div>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}

        {this.state.alertDialogQuestionMessage !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) this.alertFunction();
              this.alertFunction = null;
              this.setState({ alertDialogQuestionMessage: "" });
            }}
            type="alert"
            question={true}
            description={this.state.alertDialogQuestionMessage}
          />
        )}
      </Main>
    );
  }
}

export default Backups;
