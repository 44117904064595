import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import Calendar from "../../packages/icons/Calendar";
import { useDispatch, useSelector } from "react-redux";
import {
  setCreatedAt,
  setIsRequestDateMobile,
  setReservedAt,
} from "../../Store/slice/sliceNewRequest";
const FilterDateRadio = () => {
  const minWidth1200 = useMediaQuery("(min-width: 1200px)");
  const dispatch = useDispatch();
  useEffect(() => {
    if (!minWidth1200) dispatch(setCreatedAt());
  }, []);
  return (
    <FormControl
      sx={{
        display: { xs: "none", sm: "block", lg: "none" },
      }}
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={"submit"}
      >
        <FormControlLabel
          value="submit"
          control={
            <Radio
              sx={{
                "&.Mui-checked + .MuiTypography-root .MuiBox-root .MuiBox-root svg path":
                  {
                    fill: "#2196F3",
                  },
                "&.Mui-checked + .MuiTypography-root div .MuiTypography-root": {
                  color: "#000000DE !important",
                },
              }}
            />
          }
          label={
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box component="span" sx={{ display: "flex" }}>
                <Calendar />
              </Box>
              <Typography
                sx={{ fontSize: 14, color: "text.secondary" }}
                variant="caption"
              >
                تاریخ ثبت درخواست
              </Typography>
            </Box>
          }
          sx={{
            "& .MuiTypography-root .MuiBox-root .MuiBox-root svg path": {
              fill: "#00000099",
            },
            marginRight: "unset",
          }}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default FilterDateRadio;
