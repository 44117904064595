import * as yup from "yup";
export const validationFormikAdmins = yup.object({
  name: yup.string("").required("لطفا نام تگ را وارد کنید"),
  username: yup.string("").required("لطفا نام کاریری را وارد کنید"),
  phone: yup
    .string("Enter your email")
    .required("لطفا شماره موبایل را وارد کنید")
    .matches(/^[0-9]+$/, "لطفا شماره موبایل را به عدد وارد نمایید")
    .matches(/^09\d{9}$/, "لطفا شماره همراه صحیح وارد کنید.")
    .max(11, "لطفا شماره موبایل را درست وارد نمایید"),
  password: yup
    .string("")
    .required("رمز عبور خود را وارد کنید")
    .min(6, "لطفا رمز عبور را وارد کنید - حداقل 6 حرف"),
});
