export const Styles = {
  container: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  main: {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    background: "#fcfdff",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  centerAlignItemsContainer: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  centerJustifyContentContainer: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  centerAllContainer: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  gridInput: {
    marginBottom: 10,
    marginTop: 10,
  },
  contentContainerWrap900: {
    padding: 3,
    display: "flex",
    width: "calc(100% - 10px)",
    flexWrap: "wrap",
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    margin: "10px 5px 10px 5px",
  },
  contentContainerCol900: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    webKitFlex: "1 1 auto",
    minWidth: 290,
    padding: 3,
    width: "calc(100% - 10px)",
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    margin: 5,
  },
  containerWrap900: { display: "flex", flexWrap: "wrap", width: "100%" },
};
