import React from 'react'

export default function UnArchiveIcon() {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50016 11.1701L2.33016 7.00009L0.910156 8.41009L6.50016 14.0001L18.5002 2.00009L17.0902 0.590088L6.50016 11.1701Z"
        fill="#1B5E20"
      />
    </svg>
  );
}
