import React from "react";
const Cancel = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33301 1.33338L10.6663 10.6667M1.33303 10.6667L5.99969 6.00004L10.6664 1.33337"
        stroke="#EF5350"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Cancel;
