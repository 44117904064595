import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import React from "react";
const ModalInfo = ({ onClose, date, clock, name, phone, branch }) => {
  const maxWidth400 = useMediaQuery("(max-width: 400px)");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        marginBottom: 2,
        marginTop: 2,
        mx: 3,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography sx={{ color: "#00000099", fontSize: { xs: 12, sm: 14 } }}>
          نام درخواست کننده :
        </Typography>
        <Typography sx={{ color: "#000000DE", fontSize: { xs: 14, sm: 16 } }}>
          {name}
        </Typography>
        <Box
          sx={{
            marginRight: "auto",
            cursor: "pointer",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 30,
            width: 30,
            position: "relative",
            padding: 2,
          }}
          onClick={onClose}
        >
          <Box
            component="span"
            sx={{
              color: "#00000099",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -39%)",
              fontSize: 24,
            }}
          >
            &times;
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography
          sx={{
            color: "#00000099",
            fontSize: { xs: "12px !important", sm: 14 },
          }}
        >
          شماره همراه :
        </Typography>
        <Typography sx={{ color: "#000000DE", fontSize: { xs: 14, sm: 16 } }}>
          {phone}
        </Typography>
      </Box>
      <Box
        sx={[
          {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
          maxWidth400 && {
            flexDirection: "column !important",
            justifyContent: "unset",
            alignItems: "flex-start",
            gap: "12px",
          },
        ]}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            sx={{
              display: "flex",
              gap: 1,
              color: "#00000099",
              fontSize: { xs: 12, sm: 14 },
            }}
          >
            {date}
            <Divider flexItem orientation="vertical" />
            <Typography
              sx={{ color: "#00000099", fontSize: { xs: 12, sm: 14 } }}
            >
              ساعت
            </Typography>
            {clock}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box
            component="span"
            sx={{
              display: "inline-block",
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              bgcolor:
                branch === "support"
                  ? "#FF9800"
                  : branch === "tech"
                  ? "#EF5350"
                  : branch === "management"
                  ? "#BA68C8"
                  : "",
            }}
          ></Box>
          <Typography sx={{ fontSize: { xs: 12, sm: 14 }, color: "#00000099" }}>
            {branch === "tech"
              ? "فنی"
              : branch === "support"
              ? "کارشناسی"
              : branch === "management"
              ? "مدیریت"
              : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ModalInfo;
