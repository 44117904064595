import React, { useEffect, useState } from "react";
import { ApiClient, ApiHelper } from "../data/remote";

export const BranchField = (props) => {
  let apiClient = ApiClient.getInstance();
  const [state, setState] = useState([]);
  const [IdAgent, setIdAgent] = useState("");

  const HandlerFilterAgents = () => {
    setIdAgent(
      state
        ?.filter((item) => item._id === props.idAgent)
        .map((field) => field.name)
    );
  };

  useEffect(() => {
    apiClient.get(ApiHelper.getAgents_GET("accepted"), (error, response) => {
      if (error) {
      } else {
        setState(response.records);
      }
    });
    HandlerFilterAgents();
  }, []);

  useEffect(() => {
    HandlerFilterAgents();
  }, [state]);

  return IdAgent;
};
