import React, { useState } from "react";
import { Button, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import SortUp from "../../packages/icons/SortUp";
import SortDown from "../../packages/icons/SortDown";
import { button } from "../../styles/button";
import { useDispatch } from "react-redux";
import { updateRTK } from "../../Store/slice/getErrorsParams";
import SelectFilter from "./SelectFilter";
const Filter = () => {
  const theme = useTheme();
  const isMatchesMobileBp = useMediaQuery(theme.breakpoints.down("xs"));
  const isMatchesLaptopBp = useMediaQuery(theme.breakpoints.down("md"));
  const isMatchesbpLg = useMediaQuery("(max-width: 1325px)");
  const isMatchesBp = useMediaQuery("(max-width: 1285px)");
  const dispatch = useDispatch();
  const btnClass = button();
  const [isNewest, setIsNewest] = useState(false);
  const spacing = isMatchesbpLg ? 1 : 2;
  return (
    <Grid
      container
      spacing={spacing}
      alignItems="center"
      className={`${isMatchesMobileBp && btnClass.responsiveGrid}`}
    >
      <Grid item xs={12} md={4} lg={4}>
        <Button
          className={`${
            isMatchesBp ? btnClass.responsiveFontSize : btnClass.root
          } ${isMatchesMobileBp && btnClass.responsiveMobile} ${
            isMatchesLaptopBp && btnClass.responsiveLaptop
          }`}
          variant="contained"
          startIcon={!isNewest ? <SortDown /> : <SortUp />}
          onClick={() => {
            setIsNewest(!isNewest);
            dispatch(updateRTK({ recent: `${isNewest ? "2" : "1"}` }));
          }}
        >
          {isNewest ? "جدیدترین" : "قدیمی ترین"}
        </Button>
      </Grid>
      <Grid item md={4}>
        <SelectFilter
          options={["sms", "client", "server", "fcm", "application", "general"]}
        />
      </Grid>
      <Grid item md={4}>
        <SelectFilter options={["normal", "necessary", "ultimate"]} />
      </Grid>
    </Grid>
  );
};

export default Filter;
