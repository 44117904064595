const subPermissionArray = [
  {
    title: "خودروها :",
    subPermission: [
      {
        permissionCode: 1,
        label: "مزایده زنده",
      },
      {
        permissionCode: 2,
        label: "وضعیت خودرو",
      },
      {
        permissionCode: 3,
        label: "ویرایش وضعیت خودرو ",
      },
      {
        permissionCode: 4,
        label: "ویرایش خودرو به صورت کلی",
      },
      {
        permissionCode: 7,
        label: "استعلام",
      },
    ],
  },
  {
    title: "بروکر ها:",
    subPermission: [
      {
        permissionCode: 5,
        label: "دسترسی به تمام بروکرها",
      },
      {
        permissionCode: 6,
        label: "دسترسی به آمارگیری بروکر",
      },
    ],
  },
];

export default subPermissionArray;
