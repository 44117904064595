import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  priceTypesOptions,
  ownerTypesOptions,
  fuelTypesOptions,
  gearboxTypesOptions,
  insuranceOptionsTypes,
  insuranceDiscountTypes,
  bodyColorTypes,
  colorInsideTypes,
  SelectFormCars,
} from "../../../values/AddEditCarValues";
import { SelectForm } from "../../common/Form/index";
import style from "./car.module.css";
const MultiSelectForm = ({
  titleSelect,
  valueKey,
  carInfo,
  optionName,
  handlerValue,
  hasAccessEdit,
}) => {
  const [dataOption, setDataOption] = useState([]);
  const [value, setvalue] = useState(carInfo.details);
  const detailsSelectsStyle = { marginLeft: 5, minWidth: 200, maxWidth: 300 };

  const handlerSelectValue = (value) => {
    handlerValue(optionName, value);
    setvalue(value);
  };
  useEffect(() => {
    switch (valueKey) {
      case 1:
        setDataOption(insuranceDiscountTypes);
        setvalue(value.insurance);
        break;
      case 2:
        setDataOption(insuranceOptionsTypes);
        setvalue(value.insuranceDiscount);
        break;
      case 3:
        setDataOption(fuelTypesOptions);
        setvalue(value.fuelType);
        break;
      case 4:
        setDataOption(ownerTypesOptions);
        setvalue(value.ownerType);
        break;
      case 5:
        setDataOption(bodyColorTypes);
        setvalue(value.bodyColor);
        break;
      case 6:
        setDataOption(colorInsideTypes);
        setvalue(value.colorInside);
        break;
      case 7:
        setDataOption(gearboxTypesOptions);
        setvalue(value.gearboxType);
        break;
    }
  }, []);

  return (
    <>
      <p className={style.mandatoryLabel}>{titleSelect}</p>
      <SelectForm
        isDisabled={hasAccessEdit}
        styles={detailsSelectsStyle}
        value={value}
        label={titleSelect}
        options={dataOption}
        onchange={(value) => {
          handlerSelectValue(value);
        }}
      />
    </>
  );
};
export default MultiSelectForm;
