import { useDispatch } from "react-redux";
import {
  removeMessage,
  removeTeam,
  setActiveFilterDate,
  setBranch,
  setMutualDate,
  setResolved,
} from "../../Store/slice/sliceContactUs";

export const useEventHandler = () => {
  const dispatch = useDispatch();

  const handleBranchChange = (e) => {
    dispatch(setBranch(e.target.value));
  };

  const handleStatusChange = (e) => {
    if (e.target.value === true) {
      dispatch(setResolved(true));
    }

    if (e.target.value === false) {
      dispatch(setResolved(false));
    }

    if (e.target.value === "all") {
      dispatch(setResolved("all"));
    }
  };

  const handleDate = (date) => {
    dispatch(setMutualDate(date));
    dispatch(setActiveFilterDate());
  };

  const handleDeleteIndividualFilter = (e) => {
    const filtered = JSON.parse(e.target.value);
    if (filtered.message) {
      dispatch(removeMessage());
      dispatch(setResolved("all"));
    }

    if (filtered.team) {
      dispatch(removeTeam());
      dispatch(setBranch("all"));
    }
  };

  return {
    handleBranchChange,
    handleStatusChange,
    handleDate,
    handleDeleteIndividualFilter,
  };
};
