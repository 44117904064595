import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import stores from "../../data/stores";

const createBaseQuery = (baseUrl) => {
  return fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = stores.AccountStore.token;
      if (token) {
        headers.set("Token", `${token}`);
      }
      return headers;
    },
  });
};

export default createBaseQuery;
