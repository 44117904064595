import React from "react";
import { useParams } from "react-router-dom";
import Main from "../../common/UserMain";
import Affadative from "./Affadative/Affadative";
import DeliveryReceipt from "./Delivery_Receipt/DeliveryReceipt";
import RequestAuction from "./Request_Auction/RequestAuction";
import SalesManagerSurveyForm from "./Sales_Manager_Survey_Form/SalesManagerSurveyForm";
import CarServiceContract from "./Car_Service_Contracts/CarServiceContracts";
import Critic from "./Critic/Critic";
import Reporting from "./reporting/reporting";

const Contracts = () => {
  const { type } = useParams();
  const getContractBasedOnPage = () => {
    switch (type) {
      case "affidavit":
        return <Affadative />;
        break;
      case "critic":
        return <Critic />;
        break;
      case "request_auction":
        return <RequestAuction />;
        break;
      case "delivery_receipt":
        return <DeliveryReceipt delivery={true} />;
        break;
      case "receive_receipt":
        return <DeliveryReceipt delivery={false} />;
        break;
      case "purchase_order_survey_form":
        return <SalesManagerSurveyForm sales={false} />;
        break;
      case "sales_order_survey_form":
        return <SalesManagerSurveyForm sales={true} />;
        break;
      case "car_service_contract":
        return <CarServiceContract />;
      case "reporting":
        return <Reporting />;
        break;
      default:
        break;
    }
  };
  return (
    <Main page={"cars/" + type} pageName={"قرار داد ها"}>
      {getContractBasedOnPage()}
    </Main>
  );
};

export default Contracts;
