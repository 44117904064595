function ReturnModelBasedOnOption(models, searchOption) {
  let newModelsList = [];
  const optionNamesList = models.forEach((model) => {
    let unoptionNames = [];
    let uniqueOptions = [];
    let accurateModel = model;
    if (model._doc) model = model._doc;
    model?.types?.forEach((type) => {
      type.options.forEach((option) => {
        unoptionNames.push({ option: option.option });
      });
    });

    let optionList = unoptionNames.filter((option) => {
      const isDuplicate = uniqueOptions.includes(option.option);
      if (!isDuplicate && searchOption === option.option) {
        uniqueOptions.push(option.option);
        return true;
      }
      return false;
    });
    if (optionList.length) {
      optionList.forEach((eachOption, index) => {
        let myNewModle = model;
        let newTypes = [];
        newTypes = myNewModle?.types.map((year) => {
          let newOptions = year.options.filter((voption) => {
            if (voption.option === searchOption) return true;
          });
          return { ...year, options: newOptions };
        });
        myNewModle = { ...myNewModle, types: newTypes };
        newModelsList.push({
          ...myNewModle,
          option: eachOption.option,
          newid: `${model?._id?.toString()}${index}`,
        });
      });
    } else {
      newModelsList.push({
        ...model,
        option: "ندارد",
        newid: `${model?._id?.toString()}`,
      });
    }
  });
  console.log("newModelList: ", newModelsList);
  return newModelsList;
}

export default ReturnModelBasedOnOption;
