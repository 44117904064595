import { Box, Button, useMediaQuery } from "@mui/material";
import DrawerMobileFilter from "./DrawerMobileFilter";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import FilterIcon from "../../../packages/icons/FilterIcon";
import CloseIcon from "@mui/icons-material/Close";
const MobileFilter = ({
  customAccordionContainer,
  filteredButtons,
  onClickFilteredBtn,
  onHandleRemoveFilters,
  hasObject = false,
}) => {
  const [openFilters, setOpenFilters] = useState(false);
  const theme = useTheme();
  const isMatches = useMediaQuery(theme.breakpoints.up("lg"));
  if (isMatches) return null;
  return (
    <Box sx={{ width: 1 }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gap: "8px",
          paddingBlock: "8px",
          overflowX: "auto",
          borderBlock: "1px solid #F3F4F6",
          boxShadow: " 0px 1px 2px 0px #0000000D",
          scrollbarWidth: "none",
          whiteSpace: "nowrap",
        }}
      >
        <Button
          startIcon={<FilterIcon />}
          onClick={() => setOpenFilters((curState) => !curState)}
          variant="outlined"
          sx={{
            color: "text.primary",
            borderColor: "#00000099",
            borderRadius: "20px",
            paddingBlock: "2px",
            paddingInline: "10px",

            gap: "10px",
            "&:hover": {
              bgcolor: "#fff",
              borderColor: "#00000099",
            },
            "& .MuiButton-startIcon": {
              marginRight: "unset",
            },
          }}
        >
          فیلترها
        </Button>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            overflow: "auto",
            gap: 1,
            scrollbarWidth: "none",
          }}
        >
          {filteredButtons?.map(
            (btn) =>
              btn && (
                <Button
                  key={btn}
                  // value={btn}
                  endIcon={<CloseIcon />}
                  variant="outlined"
                  value={hasObject ? JSON.stringify(btn) : btn}
                  onClick={
                    onClickFilteredBtn ? (e) => onClickFilteredBtn(e) : null
                  }
                  sx={{
                    minWidth: "unset",
                    color: "text.primary",
                    borderColor: "#00000099",
                    borderRadius: "20px",
                    paddingBlock: "2px",
                    paddingInline: "10px",
                    color: "#2196F3",
                    borderColor: "#2196F3",
                    gap: 0.5,
                    "&:hover": {
                      bgcolor: "#fff",
                      borderColor: "#2196F3",
                    },
                    "& .MuiButton-endIcon": {
                      marginRight: "unset !important",
                      marginLeft: "unset !important",
                      pointerEvents: "none",
                    },
                  }}
                >
                  {hasObject ? Object.values(btn).flat() : btn}
                </Button>
              )
          )}
        </Box>
      </Box>
      <DrawerMobileFilter
        openFilters={openFilters}
        onSetOpenFilters={setOpenFilters}
        customAccordionContainer={customAccordionContainer}
        onHandleRemoveFilters={onHandleRemoveFilters}
      />
    </Box>
  );
};

export default MobileFilter;
