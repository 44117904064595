import React from "react";
import { Button, Icon } from "@material-ui/core";
import useStylesMui from "../../../src/components/main/user_requests/useStylesMui";

const BtnsCustomDiscount = ({ iconColor, propClass, customDiscount }) => {
  const classes = useStylesMui();
  
  const handlerSeller = (isSeller) => {
    const iconName = isSeller ? "person" : "local_offer";
    return <Icon color={iconColor}>{iconName}</Icon>;
  };
  return customDiscount ? (
    <Button
       style={{color: customDiscount?.color }}
      className={propClass}
      classes={{
        root: classes[`${customDiscount?.adminColor}Theme`],
        outlinedPrimary: classes.yellowOutlinedPrimary,
      }}
    >
      {handlerSeller(customDiscount.seller)}
      {customDiscount.name || "نام وارد نشده"}
    </Button>
  ) : null;
};

export default BtnsCustomDiscount;
