import React, { memo, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import useGetAgents from "../../Hooks/Agents/useGetAgents";
const BranchSelect = memo(({ disabledFields, branch, handleChange }) => {
  const [agents, agentloading] = useGetAgents("accepted");
  const [openBranch, setOpenBranch] = useState(false);
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, stylisRTLPlugin],
  });
  return (
    <CacheProvider value={cacheRtl}>
      <FormControl
        sx={{
          m: 1,
          minWidth: 120,
          "& > *": {
            transition: "unset",
            animation: "unset",
            animationDelay: "unset",
          },
        }}
        disabled={!disabledFields}
      >
        <InputLabel
          id="branch_agent"
          sx={{ "&": { fontSize: { xs: 13, sm: 16 } } }}
        >
          شعبه
        </InputLabel>
        <Select
          labelId="branch_agent"
          id="branch"
          value={branch}
          open={openBranch}
          onOpen={() => setOpenBranch(true)}
          onClose={() => setOpenBranch(false)}
          label="شعبه"
          onChange={handleChange}
          name="branch"
          sx={{
            "& #branch": {
              animation: "unset !important",
              animationDuration: "unset !important",
            },
          }}
        >
          {agents?.map((item, index) => (
            <MenuItem
              sx={{ animation: "unset", transition: "unset" }}
              key={index}
              value={item?._id}
              disableRipple
              disableTouchRipple
            >
              {item?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CacheProvider>
  );
});

export default BranchSelect;
