import React from "react";

const Done = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3337 1.66663L8.73987 5.97917C7.31579 7.68807 6.60374 8.54253 5.66699 8.54253C4.73024 8.54253 4.0182 7.68807 2.59411 5.97916L1.66699 4.86663"
        stroke="#4CAF50"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Done;
