import { useEffect, useState } from "react";
import { ApiClient, ApiHelper } from "../data/remote";
import { recentDaysService } from "../services/date/RecentDaysService";

export const useDaysWithHolidays = () => {
  let apiClient = ApiClient.getInstance();
  const MyIntervalDays = 20;
  const appointments = recentDaysService(MyIntervalDays);

  const [daysWithHolidays, setDaysWithHolidays] = useState([]);
  const [rangeDaye, setRangeDaye] = useState({
    startDay: "",
    endDay: "",
  });

  let handlerStartEndDay = (StartDay, EndDay) => {
    if (StartDay && EndDay) {
      GetShutdownDayApi(StartDay, EndDay);
    } else {
      GetShutdownDayApi();
    }
  };

  useEffect(() => {
    setRangeDaye({
      startDay: appointments[0].date.split("T")[0],
      endDay: appointments[appointments.length - 1].date.split("T")[0],
    });
  }, []);

  useEffect(() => {
    GetShutdownDayApi();
  }, [rangeDaye]);

  let GetResultShutdownDay = (objDateApi) => {
    const resultArray = appointments.map((field) => {
      const EqualArrays = objDateApi.find(
        (item) => field.date.split("T")[0] === item.date.split("T")[0]
      );
      if (EqualArrays) {
        return {
          date: EqualArrays.date,
          label: EqualArrays.occasion,
          ShutdownDay: 1,
        };
      } else {
        return field;
      }
    });
    setDaysWithHolidays(resultArray);
  };

  let GetShutdownDayApi = (Start, End) => {
    apiClient.get(
      ApiHelper.shutdownDay_GET,
      (error, response) => {
        if (error) {
        } else {
          GetResultShutdownDay(response.shutedDownDays);
        }
      },
      {
        start_date: Start ?? rangeDaye.startDay,
        end_date: End ?? rangeDaye.endDay,
      }
    );
  };

  return {
    daysWithHolidays,
    handlerStartEndDay,
  };
};
