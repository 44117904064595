import { Box, Typography } from "@mui/material";
import React from "react";
import StatusDropDown from "../../components/new-request-mutual-components/StatusDropDown";
const ModalStatus = ({ situation, handleChange }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
        >
          درخواست از
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
        >
          سایت
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
        >
          وضعیت
        </Typography>
        <StatusDropDown
          disable={true}
          situation={situation}
          handleChange={handleChange}
        />
      </Box>
    </Box>
  );
};

export default ModalStatus;
