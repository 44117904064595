import { Box, Typography } from "@material-ui/core";
import pic from "../../Images/noError.svg";
import React from "react";
import { noErrorStyle } from "../../styles/main";
const NoError = () => {
  const classes = noErrorStyle();
  return (
    <Box component="div" className={classes.root}>
      <Typography variant="body1" component="p">
        هیچ خطایی نداریم
      </Typography>
      <Box component="div">
        <Box component="img" src={pic} alt="no error photo" />
      </Box>
    </Box>
  );
};

export default NoError;
