import { observable, action, get } from "mobx";

class RequestModel {
  _id = null;
  sellCarInfo = {
    brand: "",
    model: "",
    year: "",
    option: "",
    km: "",
  };
  buyCarId = null;
  name = "";
  reserveDatetime = "";
  verifiedPhone = false;
  type = "";
  description = "";
  userId = "";
  location = {
    _id: "",
    location: "",
  };
  phone = "";
  status = "ایجاد شد";
  statusCode = 100;
  createdAt = "";
  updatedAt = "";
  reserveLocation = {
    name: "",
    _id: null,
    username: "",
  };
}

export default class Requests {
  @observable current = new RequestModel();

  @observable userFilter = null;

  @action setCurrent(item) {
    this.current = JSON.parse(JSON.stringify(item));
  }

  @action emptyCurrent() {
    this.current = new RequestModel();
  }

  @get getCurrent() {
    return JSON.parse(JSON.stringify(this.current));
  }
}
