import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

const ConfirmDialog = ({
  children,
  handleConfirmDialogClose,
  finalSubmission,
  sendRequest,
  ...props
}) => {
  return (
    <Dialog
      open={true}
      onClose={() => handleConfirmDialogClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ maxWidth: 500, minWidth: 300, padding: 5 }}>
        <div style={{ position: "relative" }}>
          <Grid item>{children}</Grid>
        </div>
      </DialogContent>

      {sendRequest ? (
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={25} />
        </DialogActions>
      ) : (
        <DialogActions>
          <Grid item lg={8} style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => finalSubmission()} color="primary">
              ثبت
            </Button>

            <Button
              onClick={() => handleConfirmDialogClose()}
              color="secondary"
            >
              انصراف
            </Button>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
