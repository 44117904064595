import React from "react";
import { Box } from "@mui/material";
import { useGetAppointmentsQuery } from "../../Store/callApi/webApiSell";
import BranchSelect from "./BranchSelect";
import DayAppointmentSelect from "./DayAppointmentSelect";
import HourAppointmentSelect from "./HourAppointmentSelect";
import KnowCompanySelect from "./KnowCompanySelect";

const ModalTimeStatus = ({
  disabledFields,
  branch,
  reserveDatetime,
  handleChange,
  dateReserved,
  hourReserved,
  knowCompany,
  knowCompanyValue,
  handleSetFieldManually,
}) => {
  let dataAppointments;
  if (branch) {
    const {
      data: dataAppointmentss,
      isLoading,
      isError,
      isSuccess,
    } = useGetAppointmentsQuery(
      { agentID: branch },
      { refetchOnMountOrArgChange: true }
    );
    dataAppointments = dataAppointmentss;
  }
  const timeTable = dataAppointments?.appointments
    .filter((item) => item.date.slice(0, 10) === dateReserved)
    .map((val) => JSON.parse(val.timetable))
    .flat();

  const isContainDay = dataAppointments?.appointments?.some(
    (item) => item.date.slice(0, 10) === dateReserved
  );

  const isContainHour = timeTable?.some((item) => item.hour === hourReserved);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <BranchSelect
        disabledFields={disabledFields}
        handleChange={handleChange}
        branch={branch}
      />
      <DayAppointmentSelect
        disabledFields={disabledFields}
        handleChange={handleChange}
        value={isContainDay ? dateReserved : ""}
        dataAppointments={dataAppointments}
      />
      <HourAppointmentSelect
        value={isContainHour ? hourReserved : ""}
        disabledFields={disabledFields}
        handleChange={handleChange}
        timeTable={timeTable}
      />

      <KnowCompanySelect
        disabledFields={disabledFields}
        knowCompany={knowCompany}
        knowCompanyValue={knowCompanyValue}
        handleChange={handleChange}
        handleSetFieldManually={handleSetFieldManually}
      />
    </Box>
  );
};

export default React.memo(ModalTimeStatus);
