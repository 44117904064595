import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../data/remote";
import Main from "../common/UserMain";
import { Colors } from "../../values/Colors";
import { Styles } from "../../values/Styles";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import AlertDialog from "../common/AlertDialog";

import stores from "../../data/stores";
import RequestState from "../common/RequestState";
import DialogActions from "@material-ui/core/DialogActions";

var moment = require("moment-jalaali");

let apiClient = null;

@inject("AccountStore")
@observer
class ChangePassword extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    this.state = {
      oldPassword: "",
      newPassword: "",
      rePassword: "",
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      uploadRequest: false,
      getSessionsRequest: false,
      getSessionsRequestErr: false,
      alertDialogQuestionMessage: "",
      terminateSessionRequestIndex: -1,
      inputWarn: 0,
      message: "",
    };
    this.alertFunction = null;
  }

  componentDidMount() {
    this.getSessions();
  }

  getSessions() {
    if (this.state.getSessionsRequest) {
      return;
    }
    this.setState({ getSessionsRequest: true, getSessionsRequestErr: false });
    apiClient.post(ApiHelper.updateFCMID_POST, (error, response) => {
      if (!error) {
        stores.AccountStore.setSessions(response.data);
        this.setState({
          getSessionsRequest: false,
          getSessionsRequestErr: false,
        });
      } else {
        this.setState({
          getSessionsRequest: false,
          getSessionsRequestErr: true,
        });
      }
    });
  }

  changePassRequest() {
    if (this.state.sendRequest) {
      return;
    }

    if (this.state.oldPassword.length <= 5) {
      this.setState({ inputWarn: 1 }, () => this.inputOldPass.focus());
      return;
    }

    if (this.state.newPassword.length <= 5) {
      this.setState({ inputWarn: 2 }, () => this.inputNewPass.focus());
      return;
    }

    if (this.state.rePassword.length <= 5) {
      this.setState({ inputWarn: 3 }, () => this.inputRePass.focus());
      return;
    }

    if (this.state.newPassword !== this.state.rePassword) {
      this.setState({ message: "پسورد جدید با تکرار آن مطابقت ندارد." });
      return;
    }

    this.setState({ sendRequest: true, sendRequestErr: false });

    let data = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
    };

    apiClient.post(
      ApiHelper.changePassword_POST,
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          this.setState({
            success: true,
            sendRequest: false,
            sendRequestErr: false,
            message: "پسور شما با موفقیت تغییر یافت.",
          });
        }
      },
      data
    );
  }

  terminateSession(id, index) {
    this.alertFunction = this.terminateSessionRequest.bind(this, id, index);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به پایان دادن نشست هستید ؟",
    });
  }

  terminateSessionRequest(id, index) {
    if (this.state.terminateSessionRequestIndex >= 0) return;

    this.setState({ terminateSessionRequestIndex: index });

    apiClient.post(
      ApiHelper.terminateSession_POST,
      (error, response) => {
        if (error) {
          this.setState({
            terminateSessionRequestIndex: -1,
            message: response ? response.message : "",
          });
        } else {
          this.props.AccountStore.sessions.splice(index, 1);
          this.setState({ terminateSessionRequestIndex: -1 });
        }
      },
      { sessionId: id }
    );
  }

  render() {
    return (
      <Main page={"profile"} pageName={"پروفایل"} context={this.props.history}>
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={Styles.containerWrap900}>
            <div style={Styles.contentContainerCol900}>
              <h3
                style={{
                  margin: 5,
                  color: Colors.colorAccent,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  className="material-icons-outlined"
                  style={{ margin: "0 0 0 5px" }}
                >
                  lock
                </Icon>
                تغییر رمز عبور
              </h3>

              <br />

              <div style={{ display: "flex" }}>
                <TextField
                  inputRef={(r) => (this.inputOldPass = r)}
                  onChange={(t) => (this.state.oldPassword = t.target.value)}
                  style={{ margin: 10, maxWidth: 400, flex: 1 }}
                  defaultValue={this.state.oldPassword}
                  error={this.state.inputWarn === 1}
                  label={"رمز عبور فعلی"}
                  placeholder={"رمز عبور فعلی"}
                  variant="outlined"
                  margin={"dense"}
                  type="password"
                  autoComplete="current-password"
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>lock_outline</Icon>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") this.inputNewPass.focus();
                  }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  inputRef={(r) => (this.inputNewPass = r)}
                  onChange={(t) => (this.state.newPassword = t.target.value)}
                  style={{ margin: 10, maxWidth: 400, flex: 1 }}
                  defaultValue={this.state.newPassword}
                  error={this.state.inputWarn === 2}
                  label={"رمز عبور جدید"}
                  placeholder={"رمز عبور جدید"}
                  variant="outlined"
                  margin={"dense"}
                  type="password"
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>lock_outline</Icon>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") this.inputRePass.focus();
                  }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  inputRef={(r) => (this.inputRePass = r)}
                  onChange={(t) => (this.state.rePassword = t.target.value)}
                  style={{ margin: 10, maxWidth: 400, flex: 1 }}
                  defaultValue={this.state.rePassword}
                  error={this.state.inputWarn === 3}
                  label={"تکرار رمز عبور"}
                  placeholder={"تکرار رمز عبور"}
                  variant="outlined"
                  margin={"dense"}
                  type="password"
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>lock_outline</Icon>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      this.changePassRequest();
                    }
                  }}
                />
              </div>
              <br />
              <Divider style={{ margin: 5 }} />

              <Button
                onClick={() => this.changePassRequest()}
                variant="contained"
                size="small"
                color="primary"
                style={{ margin: 5, fontWeight: 900, width: 200 }}
              >
                {this.state.sendRequest ? (
                  <CircularProgress size={22} color="inherit" />
                ) : (
                  "تغییر"
                )}
              </Button>
            </div>
          </div>

          <div style={Styles.containerWrap900}>
            <div style={Styles.contentContainerCol900}>
              <h3
                style={{
                  margin: 5,
                  color: Colors.colorAccent,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  className="material-icons-outlined"
                  style={{ margin: "0 0 0 5px" }}
                >
                  verified_user
                </Icon>
                نشست ها
              </h3>

              <RequestState
                error={this.state.getSessionsRequestErr}
                retryOnPress={() => this.getData()}
                title={"در حال دریافت نشست ها ..."}
                request={this.state.getSessionsRequest}
              />
              {this.props.AccountStore.getCurrentSessions() && (
                <p style={{ margin: 10, color: "#00537a" }}>
                  نشست فعلی : <br />
                  <br />
                  {this.props.AccountStore.getCurrentSessions().userAgent}{" "}
                  <br /> ip :{this.props.AccountStore.getCurrentSessions().ip}{" "}
                  <br /> تاریخ :
                  {moment(
                    this.props.AccountStore.getCurrentSessions().createdAt
                  ).format("jYYYY/jMM/jDD  _ HH:mm")}
                </p>
              )}
              <br />

              {this.props.AccountStore.getSessions().map((v, i) => (
                <div
                  style={{ padding: 10, borderTop: "1px solid #d2d2d2" }}
                  key={v._id}
                >
                  {v.userAgent} <br />
                  ip : {v.ip} <br />
                  تاریخ : {moment(v.createdAt).format(
                    "jYYYY/jMM/jDD _ HH:mm"
                  )}{" "}
                  <br />
                  <Button
                    onClick={() => this.terminateSession(v._id, i)}
                    variant="outlined"
                    size="small"
                    style={{
                      color: "red",
                      borderColor: "red",
                      margin: "10px 0 10px 0",
                    }}
                  >
                    {this.state.terminateSessionRequestIndex === i ? (
                      <CircularProgress size={20} />
                    ) : (
                      "پایان دادن به نشست"
                    )}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() =>
              this.state.success
                ? this.props.history.replace("/home")
                : this.setState({ message: "" })
            }
            type={this.state.success ? "success" : "alert"}
            question={false}
            description={this.state.message}
          />
        )}

        {this.state.alertDialogQuestionMessage !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) this.alertFunction();
              this.alertFunction = null;
              this.setState({ alertDialogQuestionMessage: "" });
            }}
            type="alert"
            question={true}
            description={this.state.alertDialogQuestionMessage}
          />
        )}
      </Main>
    );
  }
}

export default ChangePassword;
