import React, { useState, useEffect, useRef } from "react";
import Main from "../../common/UserMain";
import PaginationView from "../../common/PaginationView";
import { ApiHelper, ApiClient } from "../../../data/remote";
import LogItem from "./LogItem/LogItem";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import RequestState from "../../common/RequestState";
import { Chip, Fab, Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DeleteAllLogs from "./DeleteAllLogs/DeleteAllLogs";
import SearchBar from "../../common/SearchBar/SearchBar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));
const Logs = () => {
  const apiClient = ApiClient.getInstance();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [logs, setLogs] = useState([]);
  const [checked, setChecked] = useState([]);
  const [getDataForced, setGetDataForced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalNumberOfResult, setTotalNumberOfResult] = useState(); //will do later on...
  const [search, setSearch] = useState("");
  const classes = useStyles();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handkeClickOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleClose = () => {
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    getLogs();
  }, [page, getDataForced]);

  const onPressNextPageClickHandler = () => {
    setPage(page + 1);
  };  

  const onPressBeforePageClickHandler = () => {
    setPage(page - 1);
  };

  const getLogs = () => {
    setLoading(true);
    let data = { search: search };
    apiClient.get(
      ApiHelper.getLogsFromServer(page),
      (error, response) => {
        setLoading(false);
        if (error) {
          setError(true);
          setMessage(response ? response.message : "");
        } else {
          setTotalPage(response.totalPage);
          setLogs(response.records);
        }
      },
      data
    );
  };

  const deleteSelectedLogs = () => {
    setLoading(true);
    let data = {
      idsArray: checked,
    };

    apiClient.post(
      ApiHelper.deleteManyLogs,
      (error, response) => {
        setLoading(false);
        if (error) {
          setError(true);
        } else {
          setChecked([]);
          setLogs((prevState) =>
            prevState.filter((log) => checked.indexOf(log._id) == -1)
          );
          handleClose();
        }
      },
      data
    );
  };

  return (
    <>
      <Main
        page={"dashboard"}
        // pageName={"لاگ"}
        centerAppBarComponent={
          <RequestState
            error={error}
            retryOnPress={() => setGetDataForced(!getDataForced)}
            title={"در حال دریافت لیست ..."}
            request={loading}
          />
        }
        leftAppBarComponent={
          <Fab
            onClick={() => handkeClickOpen()}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            حدف
          </Fab>
        }
        rightAppBarComponent={
          <Chip
            label={`تعداد لاگ های انتخاب شده: ${checked.length}`}
            style={{ backgroundColor: "orange" }}
          />
        }
      >
        <SearchBar
          search={search}
          searchChangehandler={setSearch}
          operateSearch={getLogs}
        />

        <DeleteAllLogs
          setLogs={setLogs}
          setError={setError}
          setLoading={setLoading}
        />
        <List className={classes.root}>
          <LogItem
            value={{
              createdAt: "2023-03-14T06:57:37.004Z",
              name: "نام",
              requestType: "نوع درخواست",
              serviceUsed: "نوع عملیات",
              updatedAt: "زمان عملیات",
              userName: "",
            }}
            checked={checked}
            setChecked={setChecked}
            labelId={"labelId"}
          />
          {logs?.map((log) => {
            const labelId = `checkbox-list-label-${log._id}`;
            return (
              <LogItem
                value={log}
                checked={checked}
                setChecked={setChecked}
                labelId={labelId}
              />
            );
          })}
        </List>

        <PaginationView
          total={parseInt(totalPage)}
          page={parseInt(page)}
          nextOnPress={() => onPressNextPageClickHandler()}
          prevOnPress={() => onPressBeforePageClickHandler()}
        />
      </Main>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"آیا از حذف این لاگ  اطمینان دارید؟"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>لغو کردن</Button>
          <Button onClick={deleteSelectedLogs} autoFocus>
            بله
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Logs;
