import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brand: "",
  model: "",
  year: "",
  option: "",
  km: "",
  location: "",
  phone: "",
  agentID: "",
  name: "",
  isVIP: false,
  time: "",
  date: "",
  IdRequest: "",
};

export const SliceDataRequestCar = createSlice({
  name: " SellRequestCar",
  initialState,
  reducers: {
    setDataCar: (state, action) => {
      return {
        ...action.payload.sellCarInfo,
        location: action.payload.location.location,
        phone: action.payload.phone,
        name: action.payload.name,
        description: action.payload.description,
        isVIP: action.payload.vip,
        time: action.payload.reserveDatetime,
        date: action.payload.reserveDatetime,
        agentID: action.payload.reserveLocation,
        IdRequest: action.payload._id,
      };
    },
  },
});

export const { setDataCar } = SliceDataRequestCar.actions;

export default SliceDataRequestCar.reducer;
