import React, { useEffect, useReducer, useState } from "react";
import {
  Box,
  Grid,
  Badge,
  Paper,
  Switch,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import { useStyles } from "./stylesReporting";
import DatePickerCom from "../../../common/DatePicker/DatePicker";
import ListContracts from "./listContracts";
import { ApiClient, ApiHelper } from "../../../../data/remote";
import { Statement } from "../../../../values/Statement";
import useGetAgents from "../../../../Hooks/Agents/useGetAgents";
import Pagination from "@material-ui/lab/Pagination";
import photoNotRequest from "../../../../../src/Images/photoNotRequest.png";
import { SkeletonMap } from "../../../../values/SkeletonMap";
import Skeleton from "@material-ui/lab/Skeleton";

const initialState = {
  fromDay: "",
  toDay: "",
  contracts: [],
  pageNumber: 1,
};

const Reporting = () => {
  const apiClient = ApiClient.getInstance();
  const [agents, agentloading] = useGetAgents("accepted");
  const [selectedAgentsIDs, setSelectedAgentsIDs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const classes = useStyles();
  const handlerReducer = (state, action) => {
    switch (action.type) {
      case "getData":
        return { ...state, ...action.payload };
      case "fromDay":
        return { ...state, fromDay: action.payload.split("T")[0] };
      case "toDay":
        return { ...state, toDay: action.payload.split("T")[0] };
      case "pageCount":
        return { ...state, pageNumber: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(handlerReducer, initialState);

  useEffect(() => {
    setIsLoading(true);
    const pageSize = 12;
    if (state.fromDay && state.toDay) {
      apiClient.post(
        ApiHelper.contracts_POST,
        (error, response) => {
          if (error) {
          } else {
            if (response.success) {
              dispatch({ type: "getData", payload: response });
              setIsLoading(false);
            }
          }
        },
        {
          fromDay: state.fromDay,
          toDay: state.toDay,
          page: state.pageNumber,
          pageSize,
          agentIDs: selectedAgentsIDs,
        }
      );
    }
    if (state.contracts.length > 0) {
      setInitialLoadComplete(true);
    } else {
      setInitialLoadComplete(false);
    }
  }, [state.fromDay, state.toDay, state.pageNumber, selectedAgentsIDs]);

  const handlerIsContracts = () => {
    if (!(state.fromDay && state.toDay))
      return <h4>{Statement.selectContracts}</h4>;
    if (!state.contracts.length > 0) {
      return (
        <>
          <h4>{Statement.emptyContracts}</h4>
          <img
            className={classes.imageStyle}
            src={photoNotRequest}
            alt="Not Found"
          />
        </>
      );
    }
  };

  const handlerNumberContracts = () => {
    if (state.contracts.length > 0)
      return (
        <Typography variant="h6">
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            badgeContent={state.contractcount}
            color="primary"
          >
            <AssignmentOutlinedIcon />
          </Badge>
          تعداد قرار دادها{" "}
        </Typography>
      );
  };

  const handleAgentToggle = (agentId) => {
    setSelectedAgentsIDs((prevSelectedAgentsIDs) => {
      if (prevSelectedAgentsIDs.includes(agentId)) {
        return prevSelectedAgentsIDs.filter((id) => id !== agentId);
      } else {
        return [...prevSelectedAgentsIDs, agentId];
      }
    });
  };
  const handlerAgents = () => {
    return agents.map((agent, index) => (
      <FormControlLabel
        key={index}
        control={
          <Switch onChange={() => handleAgentToggle(agent._id)} name="jason" />
        }
        label={agent.name}
      />
    ));
  };

  const handlerPagination = () => {
    if (state.pageCount > 1)
      return (
        <Pagination
          onChange={(e, page) => dispatch({ type: "pageCount", payload: page })}
          count={state.pageCount}
          variant="outlined"
          shape="rounded"
        />
      );
  };
  return (
    <div className={classes.root}>
      <Paper>
        <h4 className={classes.titleContract}>{Statement.titleContract}</h4>
        <Grid className={classes.header} container spacing={2}>
          <Grid item xs={8}>
            <Box className={classes.searchBox}>
              <Typography className={classes.textDate} variant="h6">
                جستجو از تاریخ
              </Typography>
              <DatePickerCom
                onchange={(e) => {
                  dispatch({ type: "fromDay", payload: e.value.toISOString() });
                }}
              />
              <Typography className={classes.textDate} variant="h6">
                {" "}
                تا{" "}
              </Typography>
              <DatePickerCom
                onchange={(e) => {
                  dispatch({
                    type: "toDay",
                    payload: e.value.toISOString(),
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid className={classes.textContractNumber} item xs={4}>
            {handlerNumberContracts()}
          </Grid>
        </Grid>
        <h4 className={classes.titleContract}>
          جست و جو بر اساس فیلتر شعبه که قرار داد را درخواست کردند.
        </h4>
        <Grid className={classes.switchAgent}>{handlerAgents()}</Grid>
      </Paper>
      <Paper className={classes.stylePaperBox}>
        <Grid className={classes.messageContract} item sm={12}>
          {handlerIsContracts()}
        </Grid>
        <div className={classes.boxListContracts}>
          {isLoading && initialLoadComplete
            ? SkeletonMap.map((item) => (
                <Skeleton
                  key={item.key}
                  className={classes.skeletonStyle}
                  animation="wave"
                />
              ))
            : state.contracts?.map((item, index) => (
                <ListContracts key={index} contracts={item} />
              ))}
        </div>
        <div className={classes.paginationStyle}>{handlerPagination()}</div>
      </Paper>
    </div>
  );
};
export default Reporting;
