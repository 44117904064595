import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { CircularProgress, FormControl, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { ApiClient, ApiHelper } from "../../../data/remote";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { inject } from "mobx-react";
import AlertDialog from "../../common/AlertDialog";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import styles from "./requests.module.css";
import TogetwaySelect from "./togetwaySelect";
var moment = require("moment-jalaali");
moment.loadPersian({ dialect: "persian-modern" });

let timetable = [
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
];

let timetableThursday = [
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
];
let apiClient = null;

@inject("AgentsStore")
class ChangeDateTimeDialog extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    let dateTime = moment(props.dateTime);
    let appointments = [];
    let currentTime = moment();
    appointments.push({
      date: currentTime.toISOString(),
      label: currentTime.format("dddd jD jMMMM jYYYY"),
    });
    for (var i = 0; i < 20; i++) {
      currentTime.add(1, "day");
      appointments.push({
        date: currentTime.toISOString(),
        label: currentTime.format("dddd jD jMMMM jYYYY"),
      });
    }
    this.state = {
      beforeDate: {
        label: dateTime.format("dddd jD jMMMM jYYYY"),
        date: dateTime.toISOString(),
      },
      beforeTime: dateTime.format("HH:mm"),
      date: {
        label: dateTime.format("dddd jD jMMMM jYYYY"),
        date: dateTime.toISOString(),
      },
      time: dateTime.format("HH:mm"),
      timeChanged: false,
      appointments: appointments,
      id: props.id,
      agentInfo: props.agentInfo,
      getAgentErr: false,
      name: props.name,
      message: "",
      description: props.description,
      inRangeReservedTimes: [],
      error: "",
      filteredReserved: [],
      progress: false,
      isUnknown: props.isUnknown,
      togetway: props.togetway,
    };
  }

  getInRangeReservedTimes() {
    let prevDayDate = new Date();
    prevDayDate.setDate(prevDayDate.getDate() - 1);
    this.setState({ progress: true });
    apiClient.post(
      ApiHelper.getAllInRangeRequestsTimes,
      (error, response) => {
        if (error) {
          this.setState({
            error: "در دریافت اطلاعات خطایی رخ داده است",
          });
        } else {
          this.setState({ inRangeReservedTimes: response.data }, () =>
            this.makeTimeTableInformative()
          );
        }
        this.setState({ progress: false });
      },
      {
        startDate: prevDayDate,
        rangeOfDays: 20,
        agentId: this.state?.agentInfo?._id || null,
      }
    );
  }
  handleClose() {
    this.props.onClose();
  }
  makeTimeTableInformative() {
    let timepassed = timetable;
    let dateCurrent = moment(new Date(this.state.date.date));
    if (dateCurrent.format("dddd jD jMMMM jYYYY").includes("پنج‌شنبه ")) {
      timepassed = timetableThursday;
    }

    let informativeTimeTable = timepassed.map((hourlyTimeString) => {
      let dateCurrent = moment(new Date(this.state.date.date));
      let splitedHour = hourlyTimeString.split(":");
      dateCurrent.set({ h: splitedHour[0], m: splitedHour[1] });
      dateCurrent = new Date(dateCurrent.toISOString());
      return {
        hour: hourlyTimeString,
        hourInDateObj: dateCurrent,
        reserved: false,
      };
    });
    let filteredReserved = this.filterBaseOnReservedtimeTable(
      informativeTimeTable,
      this.state.inRangeReservedTimes
    );
    this.setState({ filteredReserved: filteredReserved });
  }
  filterBaseOnReservedtimeTable(timeTableInObj, allInRangeUserRequestTimes) {
    let filteredByReservedHours = timeTableInObj.map((hourTimeObj) => {
      let reserved = false;

      allInRangeUserRequestTimes.forEach((rangeDate) => {
        if (
          new Date(rangeDate.reserveDatetime).getDay() ===
            hourTimeObj.hourInDateObj.getDay() &&
          new Date(rangeDate.reserveDatetime).getHours() + 1 ===
            hourTimeObj.hourInDateObj.getHours() &&
          new Date(rangeDate.reserveDatetime).getMinutes() ===
            hourTimeObj.hourInDateObj.getMinutes() &&
          new Date(rangeDate.reserveDatetime).getDate() ===
            hourTimeObj.hourInDateObj.getDate()
        ) {
          reserved = true;
        }
      });
      return {
        ...hourTimeObj,
        reserved: reserved,
      };
    });
    return filteredByReservedHours;
  }
  submit() {
    if (this.state.sendRequest) return;
    this.setState({ sendRequest: true });
    let dateTime = moment(this.state.date.date);
    let time = this.state.time.split(":");
    dateTime.set({ h: Number(time[0]) - 1, m: time[1] });
    if (!this.state.timeChanged) {
      let hourAdd = Number(time[0]);
      dateTime.set({ h: hourAdd, m: time[1] });
    }
    apiClient.post(
      ApiHelper.changeUserRequestDateTime_POST,
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.props.onSuccess(
            {
              dateTime,
              agentInfo: this.state.agentInfo,
              name: this.state.name,
              description: response.data.description,
              togetway: this.state.togetway,
            },
            () => this.getInRangeReservedTimes()
          );
        }
      },
      {
        id: this.state.id,
        dateTime: dateTime.toISOString(),
        agentId: this.state.agentInfo ? this.state.agentInfo._id : null,
        name: this.state.name,
        description: this.state.description,
        togetway: this.state.togetway,
      }
    );
  }

  setDate(date) {
    let d = moment(date);
    this.setState(
      {
        date: { label: d.format("dddd jD jMMMM jYYYY"), date: d.toISOString() },
      },
      () => this.makeTimeTableInformative()
    );
  }

  componentDidMount() {
    this.getAgents();
    this.getInRangeReservedTimes();
  }

  getAgents(ref = false) {
    if (!ref && this.props.AgentsStore.agents.length > 0) return;

    this.setState({ getAgentErr: false });

    apiClient.get(ApiHelper.getAgents_GET("accepted"), (error, response) => {
      if (error) {
        this.setState({
          getAgentErr: this.props.AgentsStore.agents.length <= 0 && !ref,
        });
      } else {
        this.props.AgentsStore.setAgents(response.records);
        this.setState({ getAgentErr: false });
      }
    });
  }

  handlerTogetway(togetway) {
    this.setState({ togetway });
  }

  render() {
    let hourOfBeforeTime = this.state.beforeTime.split(":")[0];
    let oneHourAddedBeforeTime = parseInt(hourOfBeforeTime) + 1;
    let newGeneratedBeforeTimeString =
      oneHourAddedBeforeTime.toString() +
      ":" +
      this.state.beforeTime.split(":")[1];
    console.log("this.state.filteredReserved: ", this.state.filteredReserved);
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className={styles.dialogContentDataTime}>
            <div style={{ position: "relative" }}>
              {this.state.isUnknown && (
                <Typography>درخواست مجهول در حالت ویرایش!</Typography>
              )}
              <div className={styles.dialogTimeFieldStyle}>
                <TextField
                  onChange={(t) => (this.state.name = t.target.value)}
                  style={{ margin: 10, flex: 1 }}
                  defaultValue={this.state.name}
                  label={"نام کاربر"}
                  placeholder={"نام کاربر"}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{ style: { fontSize: 15 }, maxLength: 400 }}
                />
              </div>

              <div className={styles.dialogTimeFieldStyle}>
                <FormControl
                  label="تاریخ مراجعه"
                  style={{ margin: 10, flex: 1 }}
                >
                  <Select
                    defaultValue={this.state.date.date}
                    variant="outlined"
                    margin={"dense"}
                    style={{ fontSize: 13, marginTop: 10 }}
                    onChange={(e) => this.setDate(e.target.value)}
                  >
                    <MenuItem
                      style={{ fontSize: 13 }}
                      value={this.state.beforeDate.date}
                    >
                      {this.state.beforeDate.label} (فعلی)
                    </MenuItem>
                    {this.state.appointments.map((l, i) => (
                      <MenuItem key={i} style={{ fontSize: 13 }} value={l.date}>
                        {l.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className={styles.dialogTimeFieldStyle}>
                <FormControl
                  label="ساعت مراجعه"
                  style={{ margin: 10, flex: 1 }}
                >
                  <Select
                    defaultValue={newGeneratedBeforeTimeString}
                    variant="outlined"
                    margin={"dense"}
                    style={{ fontSize: 13, marginTop: 10 }}
                    onChange={(e) =>
                      this.setState({ time: e.target.value, timeChanged: true })
                    }
                  >
                    {this.state.filteredReserved &&
                      this.state.filteredReserved.map((eachTime, i) => (
                        <MenuItem
                          key={i}
                          style={{ fontSize: 13 }}
                          value={eachTime.hour}
                          disabled={eachTime.reserved}
                        >
                          {eachTime.hour} {eachTime.reserved ? "رزرو" : ""}
                          {newGeneratedBeforeTimeString === eachTime.hour
                            ? "(فعلی)"
                            : ""}
                        </MenuItem>
                      ))}
                    {this.state.progress ? <CircularProgress /> : null}
                  </Select>
                </FormControl>
              </div>

              <div className={styles.dialogTimeFieldStyle}>
                {this.state.getAgentErr ? (
                  <Button
                    style={{ margin: 10, flex: 1 }}
                    onClick={() => this.getAgents(true)}
                    color="secondary"
                    variant="outlined"
                    size="small"
                  >
                    خطا در دریافت نمایندگان !
                  </Button>
                ) : (
                  <FormControl label="نماینده" style={{ margin: 10, flex: 1 }}>
                    <Select
                      defaultValue={
                        this.state.agentInfo ? this.state.agentInfo._id : "0"
                      }
                      variant="outlined"
                      margin={"dense"}
                      style={{ fontSize: 13, marginTop: 10 }}
                      onChange={(e) => {
                        this.setState({ ref: true });
                      }}
                    >
                      <MenuItem style={{ fontSize: 13 }} value={"0"}>
                        {this.props.AgentsStore.agents.length < 0 ? (
                          <CircularProgress size={16} style={{ margin: 1 }} />
                        ) : (
                          "انتخاب نماینده"
                        )}
                      </MenuItem>

                      {this.props.AgentsStore.agents.map((l, i) => (
                        <MenuItem
                          key={l._id}
                          style={{ fontSize: 13 }}
                          onClick={() =>
                            this.setState({ agentInfo: l }, () => {
                              this.getInRangeReservedTimes();
                            })
                          }
                          value={l._id}
                        >
                          {l.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <IconButton onClick={() => this.getAgents(true)}>
                  <Icon>sync</Icon>
                </IconButton>
              </div>

              <div className={styles.dialogTimeFieldStyle}>
                <TextField
                  id="description_field"
                  multiline
                  maxRows={4}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  style={{ margin: 10, flex: 1 }}
                  value={this.state.description}
                  label="توضیحات"
                  inputProps={{ maxLength: 400 }}
                  fullWidth={true}
                  variant="outlined"
                  margin={"dense"}
                />
              </div>
              <TogetwaySelect
                togetway={this.state.togetway}
                handlerStringTogetway={this.handlerTogetway.bind(this)}
              />
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => this.submit()} color="primary">
              {this.state.sendRequest ? (
                <CircularProgress size={20} style={{ margin: 5 }} />
              ) : (
                "انجام"
              )}
            </Button>

            <Button onClick={() => this.handleClose()} color="secondary">
              بستن
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </div>
    );
  }
}

ChangeDateTimeDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  dateTime: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  agentInfo: PropTypes.string,
};
export default ChangeDateTimeDialog;
