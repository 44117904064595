import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paginationContainer } from "../../styles/main";
import { updateRTK } from "../../Store/slice/getErrorsParams";

const PaginationError = () => {
  const classes = paginationContainer();
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const totalPage = useSelector(
    (store) => store?.dataGetErrors?.data?.totalPage
  );
  const isMathcesuseMediaQuery = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatchesMediaQueryLg = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box
      component="div"
      className={`${classes.root} ${classes.mobileFontSize} ${
        isMatchesMediaQueryLg ? classes.tabletMarginTop : ""
      }`}
    >
      {totalPage > 1 && (
        <Pagination
          count={totalPage}
          shape="rounded"
          siblingCount={
            isMathcesuseMediaQuery ? 0 : isMatchesMediaQueryLg ? 0 : 1
          }
          boundaryCount={
            isMathcesuseMediaQuery ? 1 : isMatchesMediaQueryLg ? 1 : 2
          }
          page={page}
          onChange={(e, value) => {
            setPage(value);
            dispatch(updateRTK({ page: `${value}` }));
          }}
          hideNextButton={totalPage === page && true}
          hidePrevButton={page === 1 && true}
          defaultPage={1}
        />
      )}
    </Box>
  );
};

export default PaginationError;
