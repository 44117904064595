import React, { useState } from "react";
import Main from "../../common/UserMain";
import { useMediaQuery } from "@mui/material";
import Header from "../../../features/new-request-sell/Header";
import MainSection from "../../../features/new-request-sell/MainSection";

const SellCreated = () => {
  const isMatchesTabletbp = useMediaQuery("(max-width: 959px)");
  const minWidth900 = useMediaQuery("(min-width: 0)");
  return (
    <Main
      page={"newRequest"}
      pageName={"فروش"}
      showToolbar={isMatchesTabletbp && minWidth900 ? true : false}
    >
      <Header />
      <MainSection />
    </Main>
  );
};

export default SellCreated;
