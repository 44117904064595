exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CarPrice_brands__lxa9R {\n  display: flex;\n  flex-direction: column;\n}\n\n.CarPrice_breadCrumbsContainer__w1kvw {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  margin-top: 5rem;\n  background-color: #70163c;\n  padding: 1rem;\n  color: white;\n  border-radius: 100px;\n}\n\n.CarPrice_breadCrumb__2TKnH {\n  margin: 0 10px;\n}\n\n.CarPrice_breadCrumbClose__12n53 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 5px;\n  border: 1px solid white;\n  border-radius: 1000px;\n  margin-left: 1rem;\n  cursor: pointer;\n}\n.CarPrice_boxskeletonClass__3aIe9 {\n  margin-top: 30px;\n}\n.CarPrice_firstSkeletonStyle__2V_c3 {\n  margin-bottom: 10px;\n}\n\n.CarPrice_skeletonStyle__2TVQQ {\n  margin-bottom: 4px;\n}\n", ""]);

// Exports
exports.locals = {
	"brands": "CarPrice_brands__lxa9R",
	"breadCrumbsContainer": "CarPrice_breadCrumbsContainer__w1kvw",
	"breadCrumb": "CarPrice_breadCrumb__2TKnH",
	"breadCrumbClose": "CarPrice_breadCrumbClose__12n53",
	"boxskeletonClass": "CarPrice_boxskeletonClass__3aIe9",
	"firstSkeletonStyle": "CarPrice_firstSkeletonStyle__2V_c3",
	"skeletonStyle": "CarPrice_skeletonStyle__2TVQQ"
};