import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import React from "react";
const SelectAllCheckbox = ({ onChange, checked, type, className }) => {
  return (
    <FormControl className={className}>
      <FormControlLabel
        control={
          <Checkbox
            color={type}
            checked={checked}
            onChange={() => onChange()}
          />
        }
        label="انتخاب همه"
      />
    </FormControl>
  );
};

export default SelectAllCheckbox;
