import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { DatePicker } from "zaman";
// import DatePickerIcon from "../../../packages/icons/DatePickerIcon";
import { useDispatch, useSelector, useStore } from "react-redux";
import { datePickerTop } from "../../styles/main";
import DatePickerIcon from "../../packages/icons/DatePickerIcon";
import { setMutualDate } from "../../Store/slice/sliceContactUs";

const DatepickerContactus = ({ className, onChange, dateOnChange, value }) => {
  const datePickerTopStyle = datePickerTop();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const { activeFilterDate, mutualDate } = useSelector(
    (state) => state.contactUs
  );
  if (!activeFilterDate) {
    return (
      <div className={`${datePickerTopStyle.root} ${className}`}>
        <Box component="div">
          <Typography component="span">تاریخ</Typography>
          <DatePicker
            key={"not random"}
            round="x4"
            onChange={({ value }) => {
              setDate(value);
              onChange(value.toISOString().slice(0, 10));
            }}
            weekends={[6]}
            position="center"
          />
          <Typography component="span">روز/ماه/سال</Typography>
          <DatePickerIcon />
        </Box>
      </div>
    );
  }

  if (activeFilterDate) {
    return (
      <div
        key={new Date().getMilliseconds()}
        className={`${datePickerTopStyle.root} ${className}`}
      >
        <Box component="div">
          <Typography component="span">تاریخ</Typography>
          <DatePicker
            key={"not random"}
            round="x4"
            defaultValue={value}
            onChange={({ value }) => {
              setDate(value);
              onChange(value.toISOString().slice(0, 10));
            }}
            weekends={[6]}
            position="center"
          />
          <Typography component="span">روز/ماه/سال</Typography>
          <DatePickerIcon />
        </Box>
      </div>
    );
  }
};

export default DatepickerContactus;
