import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { ApiClient, ApiHelper } from "../../../data/remote";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import AlertDialog from "../../common/AlertDialog";
import { inject, observer } from "mobx-react";
import { convertNumberToEN } from "../../../language/NumberConverter";
import Select from "@material-ui/core/Select";
import RequestState from "../../common/RequestState";
import Main from "../../common/UserMain";
import { Styles } from "../../../values/Styles";
import PaginationView from "../../common/PaginationView";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ChangeStatusDialogBroker from "../brokers/ChangeStatusDialog";
import { setMomentOffset } from "../../../data/presenter/Utiles";
import stores from "../../../data/stores";
import { Switch } from "@material-ui/core";
import carStyles from "./car.module.css";
var moment = require("moment-jalaali");
var momentServerOffset = require("moment");

let apiClient = null;
const label = { inputProps: { "aria-label": "Checkbox demo" } };

@inject("AccountStore")
@inject("CarsStore")
@inject("BrokersStore")
@inject("AppRouterStore")
@observer
class ChangeStatusDialog extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();
    props.CarsStore.emptyCurrentCar();
    this.state = {
      id: props.id,
      data: props.CarsStore.getCurrentCar(),
      status: "",
      userPhone: "",
      carModel: "",
      carYear: "",
      carColor: "",
      applicationLink: false,
      msg: "",
      sendRequest: false,
      getDataRequest: false,
      getDataRequestErr: true,
      message: "",
      showUserInfo: "",
      alertDialogQuestionMessage: "",
      showPriceInput: false,
      price: "",
      setPaymentRequest: false,
      requestsPage: 1,
      auctionTime: 0,
      sendLinkRequest: false,
      showStatusDialogBroker: false,
      getPaymentInfoRequest: false,
      getPaymentInfoRequestErr: false,
      cancelPaymentRequest: false,
      paymentInfo: null,
      cancelBrokerWinnerRequest: false,
      hasAccess: false,
    };

    this.alertFunction = null;
  }

  handleClose() {
    this.props.onClose();
  }

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }

  getDiffTime(t) {
    let d =
      t && moment.duration(moment(t).diff(momentServerOffset())).asMinutes();
    let time = d && d > 0 ? Math.ceil(d) : 0;
    return time;
  }

  cancelPayment() {
    this.alertFunction = this.cancelPaymentRequest.bind(this);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به لغو پرداخت هستید ؟",
    });
  }

  cancelPaymentRequest() {
    if (this.state.cancelPaymentRequest || !this.state.paymentInfo) {
      return;
    }

    this.setState({ cancelPaymentRequest: true });

    apiClient.get(
      ApiHelper.cancelPaymentInfoById_GET(this.state.paymentInfo._id),
      (error, response) => {
        if (error) {
          this.setState({
            cancelPaymentRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.state.data.type = response.carType;
          this.setState({
            cancelPaymentRequest: false,
            paymentInfo: response.data,
          });
        }
      }
    );
  }

  cancelBrokerWinner() {
    this.alertFunction = this.cancelBrokerWinnerRequest.bind(this);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به لغو برنده هستید ؟",
    });
  }
  cancelBrokerWinnerRequest() {
    if (this.state.cancelBrokerWinnerRequest) {
      return;
    }

    this.setState({ cancelBrokerWinnerRequest: true });

    apiClient.post(
      ApiHelper.cancelBrokerWinnerByCarId_GET(this.state.id),
      (error, response) => {
        if (error) {
          this.setState({
            cancelBrokerWinnerRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.state.data.type = response.carType;
          this.state.data.requestWinner = null;
          this.state.data.brokerWinner = null;
          this.setState({
            cancelBrokerWinnerRequest: false,
          });
        }
      }
    );
  }

  getPaymentInfoRequest() {
    if (this.state.getPaymentInfoRequest) {
      return;
    }

    this.setState({
      getPaymentInfoRequest: true,
      getPaymentInfoRequestErr: false,
    });

    apiClient.get(
      ApiHelper.getPaymentInfoByCarId_GET(this.state.id),
      (error, response) => {
        if (error) {
          this.setState({
            getPaymentInfoRequest: false,
            getPaymentInfoRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          this.setState({
            getPaymentInfoRequest: false,
            getPaymentInfoRequestErr: false,
            paymentInfo: response.data,
          });
        }
      }
    );
  }

  getData() {
    if (this.state.getDataRequest) {
      return;
    }

    this.setState({ getDataRequest: true, getDataRequestErr: false });

    apiClient.get(
      ApiHelper.getCarInfoById_GET(this.state.id),
      (error, response) => {
        if (error) {
          this.setState({
            getDataRequest: false,
            getDataRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          setMomentOffset(response.currentServerTime);
          let time = this.getDiffTime(response.data.auctionTime);
          if (this.timer) clearInterval(this.timer);
          if (response.data.type === "live") {
            this.timer = setInterval(
              () =>
                this.setState({
                  auctionTime: this.getDiffTime(this.state.data.auctionTime),
                }),
              1000
            );
          }
          this.setState(
            {
              getDataRequest: false,
              getDataRequestErr: false,
              data: response.data,
              auctionTime: time,
              hasAccess: response.data.hasAccess,
            },
            () => {
              if (this.state.data.brokerWinner) {
                this.getPaymentInfoRequest();
              }
            }
          );
        }
      },
      { requestsPage: this.state.requestsPage }
    );
  }

  submit() {
    if (this.state.sendRequest) return;

    if (this.state.status === "") {
      this.setState({ message: "لطفا وضعیت را مشخص کنید" });
      return;
    }

    this.setState({ sendRequest: true, inputWarn: 0 });

    apiClient.get(
      ApiHelper.changeStatusCar_GET(this.state.status, this.state.data.type),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false }, () =>
            this.props.onSuccess(response.data)
          );
        }
      },
      { id: this.state.id }
    );
  }

  setBrokerPayment(brokerId) {
    if (!this.state.showPriceInput) {
      this.setState({ showPriceInput: true });
      return;
    }

    if (this.state.setPaymentRequest) {
      return;
    }

    this.setState({ setPaymentRequest: true, showPriceInput: false });

    apiClient.get(
      ApiHelper.setBrokerPayment_GET(brokerId),
      (error, response) => {
        if (error) {
          this.setState({
            setPaymentRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.props.onSuccess(response.data);
          this.setState(
            { setPaymentRequest: false, message: "پرداخت با موفقیت ثبت شد" },
            () => {
              this.getPaymentInfoRequest();
            }
          );
        }
      },
      {
        carId: this.state.id,
        price: this.state.price.toString().replace(/,/g, ""),
      }
    );
  }

  setBrokerWinner(request) {
    this.alertFunction = this.setBrokerWinnerRequest.bind(this, request);
    this.setState({
      alertDialogQuestionMessage: `آیا مطمئن به ثبت برنده هستید ؟ \n ( ${
        request.name
      } - ${convertNumberToEN(request.price, true)} تومان) `,
    });
  }

  sendLink() {
    if (this.state.sendLinkRequest) {
      return;
    }

    this.setState({ sendLinkRequest: true });
    let dataBody = {
      phone: this.state.userPhone,
      model: this.state.carModel,
      year: this.state.carYear,
      color: this.state.carColor,
    };
    if (this.state.applicationLink) {
      apiClient.post(
        ApiHelper.sendCarApplicationLink_POST(this.state.id),
        (error, response) => {
          if (error) {
            this.setState({
              sendLinkRequest: false,
              message: response ? response.message : "",
            });
          } else {
            this.setState({ sendLinkRequest: false, message: "ارسال شد" });
          }
        },
        dataBody
      );
    } else {
      apiClient.post(
        ApiHelper.sendCarLink_POST(this.state.id),
        (error, response) => {
          if (error) {
            this.setState({
              sendLinkRequest: false,
              message: response ? response.message : "",
            });
          } else {
            this.setState({ sendLinkRequest: false, message: "ارسال شد" });
          }
        },
        dataBody
      );
    }
  }

  sendLinkWhatsApp() {
    if (!this.state.userPhone || this.state.userPhone.length !== 11) {
      this.setState({ message: "شماره کاربر صحیح نیست" });
      return;
    }

    let l = "https://nikbakhtkhodro.com/c/" + this.state.data.hashCode;
    l = `https://wa.me/98${this.state.userPhone.substr(1)}/?text=${l}`;
    window.open(l, "_blank");
  }

  setBrokerWinnerRequest(request) {
    if (this.state.getDataRequest) {
      return;
    }

    this.setState({ getDataRequest: true, getDataRequestErr: false });

    apiClient.post(
      ApiHelper.setBrokerWinner_GET(request.brokerId),
      (error, response) => {
        if (error) {
          this.setState({
            getDataRequest: false,
            getDataRequestErr: false,
            message: response ? response.message : "",
          });
        } else {
          this.props.onSuccess(response.data);
          this.setState(
            { getDataRequest: false, getDataRequestErr: false },
            () => this.getData()
          );
        }
      },
      { carId: this.state.id, request: request }
    );
  }

  getFormatedPrice(p) {
    return p
      .toString()
      .replace(/[^0-9]/g, "")
      .toString()
      .split("")
      .reverse()
      .join("")
      .split(/(...)/)
      .reverse()
      .join(",")
      .replace(/,(?=,)|,$|^,/g, "")
      .replace(/(,|^)(\d)(\d)?(\d)?/g, "$1$4$3$2");
  }

  renderDetails() {
    return (
      <Accordion
        style={{ margin: 5, borderRadius: 5, backgroundColor: "#fff" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p
            style={{
              flex: 1,
              fontSize: 13,
              margin: 0,
            }}
          >
            {this.state.data.brand} | {this.state.data.model} |{" "}
            {this.state.data.year}
            <span
              style={{
                backgroundColor: "#c8effc",
                float: "left",
                borderRadius: 15,
                padding: "2px 10px",
              }}
            >
              {this.state.data.code}
            </span>
          </p>
        </AccordionSummary>
        <AccordionDetails
          style={{ flexDirection: "column", padding: 5, margin: 0 }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flex: 1,
              margin: 5,
              padding: 5,
              backgroundColor: "#eee",
              borderRadius: 5,
            }}
          >
            <p
              style={{
                flex: 1,
                fontSize: 13,
                minWidth: 170,
                margin: 5,
              }}
            >
              {this.state.data.agentInfo
                ? this.state.data.agentInfo.name
                : "ادمین"}{" "}
              _ {moment(this.state.data.createdAt).format("jYYYY/jM/jD")}
            </p>

            <p
              style={{
                flex: 1,
                fontSize: 13,
                minWidth: 170,
                margin: 5,
              }}
            >
              کارشناس :{" "}
              {this.state.data.expertId
                ? this.state.data.expertId.name
                : " __ "}{" "}
              _{" "}
              {this.state.data.expertId
                ? this.state.data.expertId.username
                : " __ "}
            </p>
          </div>
          {this.state.data.agentInfo && (
            <div
              style={{
                margin: 5,
                borderRadius: 5,
                backgroundColor: "#f8f8f8",
              }}
            >
              <div style={{ flex: 1, padding: 5, position: "relative" }}>
                <p style={{ flex: 1, fontSize: 13, margin: 5 }}>ارسال لینک</p>

                <div
                  style={{
                    overflowX: "auto",
                    color: "blue",
                    fontSize: 13,
                    margin: 5,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <a
                    target="_blank"
                    href={`https://nikbakhtkhodro.com/c/${this.state.data.hashCode}`}
                  >
                    https://nikbakhtkhodro.com/c/{this.state.data.hashCode}
                  </a>
                  <p style={{ color: "black", margin: "0 1rem 0 0" }}>
                    لینک وبسایت
                  </p>
                </div>
                <div
                  style={{
                    overflowX: "auto",
                    color: "blue",
                    fontSize: 13,
                    margin: 5,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <a
                    target="_blank"
                    href={`https://nikbakhtkhodro.com/share/${this.state.id}`}
                  >
                    https://nikbakhtkhodro.com/share/{this.state.id}
                  </a>
                  <p style={{ color: "black", margin: "0 1rem 0 0" }}>
                    لینک اپلیکیشن
                  </p>
                </div>
              </div>
              <div>
                <p>اطلاعات را وارد کنید</p>
                <div>
                  <TextField
                    onChange={(t) => (this.state.carModel = t.target.value)}
                    defaultValue={this.state.carModel}
                    label={"مدل"}
                    placeholder={"مدل ماشین را وارد کنید"}
                    variant="outlined"
                    margin={"dense"}
                    inputProps={{
                      style: {
                        textAlign: "right",
                        direction: "ltr",
                        fontSize: 15,
                      },
                      maxLength: 11,
                    }}
                    style={{
                      margin: 5,
                      minWidth: 200,
                      flex: 1,
                      maxWidth: 350,
                      backgroundColor: "#fff",
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        ? convertNumberToEN(e.target.value).toString()
                        : "";
                    }}
                  />
                  <TextField
                    onChange={(t) => (this.state.carYear = t.target.value)}
                    defaultValue={this.state.carYear}
                    label={"سال"}
                    placeholder={"سال ماشین را وارد کنید"}
                    variant="outlined"
                    margin={"dense"}
                    inputProps={{
                      style: {
                        textAlign: "right",
                        direction: "ltr",
                        fontSize: 15,
                      },
                      maxLength: 11,
                    }}
                    style={{
                      margin: 5,
                      minWidth: 200,
                      flex: 1,
                      maxWidth: 350,
                      backgroundColor: "#fff",
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        ? convertNumberToEN(e.target.value).toString()
                        : "";
                    }}
                  />
                  <TextField
                    onChange={(t) => (this.state.carColor = t.target.value)}
                    defaultValue={this.state.carColor}
                    label={"رنگ"}
                    placeholder={"رنگ ماشین را وارد کنید"}
                    variant="outlined"
                    margin={"dense"}
                    inputProps={{
                      style: {
                        textAlign: "right",
                        direction: "ltr",
                        fontSize: 15,
                      },
                      maxLength: 11,
                    }}
                    style={{
                      margin: 5,
                      minWidth: 200,
                      flex: 1,
                      maxWidth: 350,
                      backgroundColor: "#fff",
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        ? convertNumberToEN(e.target.value).toString()
                        : "";
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flex: 1,
                  margin: 5,
                }}
              >
                <TextField
                  onChange={(t) => (this.state.userPhone = t.target.value)}
                  defaultValue={this.state.userPhone}
                  label={"موبایل مشترک"}
                  placeholder={"موبایل با ۰۹ شروع شود"}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                    maxLength: 11,
                  }}
                  style={{
                    margin: 5,
                    minWidth: 200,
                    flex: 1,
                    maxWidth: 350,
                    backgroundColor: "#fff",
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? convertNumberToEN(e.target.value)
                          .toString()
                          .replace(/[^0-9]/g, "")
                          .toString()
                      : "";
                  }}
                  type="tel"
                />
                <div
                  style={{
                    display: "flex",
                    minWidth: 180,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => this.sendLinkWhatsApp()}
                    color="secondary"
                    size="small"
                    style={{ margin: "10px 5px", minWidth: 80, maxHeight: 35 }}
                  >
                    {this.state.sendLinkRequest ? (
                      <CircularProgress size={20} />
                    ) : (
                      "واتساپ"
                    )}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => this.sendLink()}
                    color="secondary"
                    size="small"
                    style={{ margin: "10px 5px", minWidth: 80, maxHeight: 35 }}
                  >
                    {this.state.sendLinkRequest ? (
                      <CircularProgress size={20} />
                    ) : (
                      "اس ام اس"
                    )}
                  </Button>
                </div>
              </div>
              <div>
                <Switch
                  checked={!this.state.applicationLink}
                  onChange={() =>
                    this.setState({
                      applicationLink: !this.state.applicationLink,
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
                ارسال لینک وبسایت
              </div>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          fullScreen={window.matchMedia("(max-width: 800px)").matches}
          fullWidth
        >
          <DialogContent
            style={{ width: "100%", padding: 5, backgroundColor: "#ececec" }}
          >
            {this.state.getDataRequest || this.state.getDataRequestErr ? (
              <RequestState
                error={this.state.getDataRequestErr}
                retryOnPress={() => this.getData()}
                title={"در حال دریافت مشخصات خودرو ..."}
                request={this.state.getDataRequest}
              />
            ) : (
              <div style={{ position: "relative" }}>
                {this.renderDetails()}

                {this.state.data.type === "live" && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 5,
                      padding: "0 5px",
                      borderRadius: 5,
                      backgroundColor:
                        this.state.auctionTime > 0 ? "#e8fff2" : "#ffe6e6",
                    }}
                  >
                    <p
                      style={{
                        flex: 1,
                        fontSize: 13,
                        margin: 5,
                      }}
                    >
                      زمان مزایده{" "}
                    </p>
                    <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                      {this.state.auctionTime} دقیقه
                    </p>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flex: 1,
                    margin: 5,
                    padding: "0 5px",
                    borderRadius: 5,
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      fontSize: 13,
                      minWidth: 220,
                    }}
                  >
                    <p style={{ margin: 5, width: 80 }}>قیمت پایــه</p>
                    <p
                      style={{
                        margin: 5,
                      }}
                    >
                      {this.state.data.basePrice
                        .toString()
                        .replace(/[^0-9]/g, "")
                        .toString()
                        .split("")
                        .reverse()
                        .join("")
                        .split(/(...)/)
                        .reverse()
                        .join(",")
                        .replace(/,(?=,)|,$|^,/g, "")
                        .replace(/(,|^)(\d)(\d)?(\d)?/g, "$1$4$3$2")}
                    </p>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      fontSize: 13,
                      minWidth: 220,
                    }}
                  >
                    <p style={{ margin: 5, width: 80 }}>قیمت مزایده</p>
                    <p
                      style={{
                        margin: 5,
                      }}
                    >
                      {this.state.data.auctionPrice
                        .toString()
                        .replace(/[^0-9]/g, "")
                        .toString()
                        .split("")
                        .reverse()
                        .join("")
                        .split(/(...)/)
                        .reverse()
                        .join(",")
                        .replace(/,(?=,)|,$|^,/g, "")
                        .replace(/(,|^)(\d)(\d)?(\d)?/g, "$1$4$3$2")}
                    </p>
                  </div>
                </div>

                {this.state.data.brokerWinner && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      margin: "10px 5px",
                      backgroundColor: "#fff",
                      border: "2px solid #50a000",
                      borderRadius: 5,
                    }}
                  >
                    <div
                      style={{ display: "flex", flexDirection: "row", flex: 1 }}
                    >
                      <h2
                        style={{
                          flex: 1,
                          fontSize: 13,
                          margin: "13px 10px",
                          color: "#50a000",
                        }}
                      >
                        بروکر برنده
                      </h2>

                      {this.state.showPriceInput ? (
                        <TextField
                          onChange={(t) =>
                            (this.state.price = t.target.value
                              .toString()
                              .replace(/,/g, ""))
                          }
                          defaultValue={this.state.price
                            .toString()
                            .replace(/[^0-9]/g, "")
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .split(/(...)/)
                            .reverse()
                            .join(",")
                            .replace(/,(?=,)|,$|^,/g, "")
                            .replace(/(,|^)(\d)(\d)?(\d)?/g, "$1$4$3$2")}
                          placeholder="مبلغ فاکتور (تومان)"
                          variant="outlined"
                          margin={"dense"}
                          style={{ margin: 5, height: 35, width: 170 }}
                          inputProps={{
                            style: {
                              fontSize: 13,
                              direction: "ltr",
                            },
                            maxLength: 22,
                          }}
                          InputProps={{
                            startAdornment: (
                              <IconButton
                                size="small"
                                style={{ margin: "0 -10px 0 0" }}
                                onClick={() =>
                                  this.setState({ showPriceInput: false })
                                }
                              >
                                <Icon>close</Icon>
                              </IconButton>
                            ),
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value
                              ? convertNumberToEN(e.target.value)
                                  .toString()
                                  .replace(/[^0-9]/g, "")
                                  .toString()
                                  .split("")
                                  .reverse()
                                  .join("")
                                  .split(/(...)/)
                                  .reverse()
                                  .join(",")
                                  .replace(/,(?=,)|,$|^,/g, "")
                                  .replace(/(,|^)(\d)(\d)?(\d)?/g, "$1$4$3$2")
                              : "";
                          }}
                          type="tel"
                        />
                      ) : (
                        <Button
                          variant="text"
                          onClick={() =>
                            this.setState({
                              showStatusDialogBroker:
                                this.state.data.brokerWinner._id,
                            })
                          }
                          color="secondary"
                          size="small"
                          style={{
                            margin: 5,
                            maxWidth: 130,
                            padding: 0,
                            height: 35,
                          }}
                        >
                          <p
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              margin: 5,
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            {this.state.data.brokerWinner.name &&
                            this.state.data.hasAccess
                              ? this.state.data.brokerWinner.name
                              : "مشخصات بروکر"}
                          </p>
                        </Button>
                      )}

                      <Button
                        onClick={() =>
                          this.setBrokerPayment(
                            this.state.data.brokerWinner._id
                          )
                        }
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{ margin: 5 }}
                      >
                        {this.state.setPaymentRequest ? (
                          <CircularProgress color={"inherit"} size={20} />
                        ) : (
                          "ثبت فاکتور"
                        )}
                      </Button>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 5,
                      }}
                    >
                      <p
                        style={{
                          margin: 5,
                          backgroundColor: "#eee",
                          borderRadius: 5,
                          padding: "0 6px",
                        }}
                      >
                        {this.state.data.requestWinner
                          ? this.state.data.requestWinner.auctionType === 3
                            ? "دپو"
                            : "مزایده"
                          : "-"}
                      </p>
                      <p
                        style={{ width: "100%", margin: 5, textAlign: "right" }}
                      >
                        پیشنهاد :
                        {this.state.data.requestWinner &&
                        this.state.data.hasAccess
                          ? convertNumberToEN(
                              this.state.data.requestWinner.price,
                              true
                            )
                          : "-"}{" "}
                        تومان{" "}
                      </p>
                      <Button
                        onClick={() => this.cancelBrokerWinner()}
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{
                          margin: 5,
                          width: 100,
                          backgroundColor: "orange",
                        }}
                      >
                        {this.state.cancelBrokerWinnerRequest ? (
                          <CircularProgress color={"inherit"} size={20} />
                        ) : (
                          "لغو برنده"
                        )}
                      </Button>
                    </div>
                  </div>
                )}

                {this.state.data.brokerWinner && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      margin: "10px 5px",
                      backgroundColor: "#fff",
                      border: "2px solid #795000",
                      borderRadius: 5,
                    }}
                  >
                    <h2
                      style={{
                        flex: 1,
                        fontSize: 13,
                        margin: "13px 10px",
                        color: "#795000",
                      }}
                    >
                      وضعیت آخرین فاکتور
                      <IconButton
                        onClick={() => this.getPaymentInfoRequest()}
                        size="small"
                      >
                        <Icon>sync</Icon>
                      </IconButton>
                    </h2>
                    <RequestState
                      title={"دریافت وضعیت فاکتور..."}
                      error={this.state.getPaymentInfoRequestErr}
                      request={this.state.getPaymentInfoRequest}
                      errorTitle={"خطا در دریافت اطلاعات فاکتور"}
                      retryOnPress={() => this.getPaymentInfoRequest()}
                    />
                    {!this.state.getPaymentInfoRequest &&
                      this.state.paymentInfo && (
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            flexWrap: "wrap",
                            margin: 5,
                          }}
                        >
                          <p
                            style={{
                              margin: 5,
                              minWidth: 50,
                              backgroundColor:
                                this.state.paymentInfo.statusCode === 400
                                  ? "#ffa6a6"
                                  : this.state.paymentInfo.statusCode === 201
                                  ? "#e0ffb5"
                                  : "#eee",
                              borderRadius: 5,
                              padding: "0 6px",
                            }}
                          >
                            {this.state.paymentInfo.status}
                          </p>
                          <p
                            style={{
                              margin: 5,
                              minWidth: 90,
                              backgroundColor: "#eee",
                              borderRadius: 5,
                              padding: "0 6px",
                            }}
                          >
                            {convertNumberToEN(
                              this.state.paymentInfo.amount,
                              true
                            )}{" "}
                            <span style={{ fontSize: 10 }}>تومان</span>
                          </p>
                          <p
                            style={{
                              margin: 5,
                              minWidth: 130,
                              backgroundColor: "#eee",
                              borderRadius: 5,
                              padding: "0 6px",
                            }}
                          >
                            {moment(this.state.paymentInfo.createdAt).format(
                              "jYYYY/jM/jD _ HH:mm:ss"
                            )}
                          </p>
                          <p
                            style={{
                              margin: 5,
                              minWidth: 130,
                              backgroundColor: "#eee",
                              borderRadius: 5,
                              padding: "0 6px",
                            }}
                          >
                            {this.state.paymentInfo.zarinpalStatus}
                          </p>
                        </div>
                      )}

                    {!this.state.getPaymentInfoRequest &&
                      this.state.paymentInfo && (
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            flexWrap: "wrap",
                            margin: 5,
                          }}
                        >
                          <Button
                            onClick={() =>
                              this.setState({
                                showStatusDialogBroker:
                                  this.state.paymentInfo.brokerId._id,
                              })
                            }
                            fullWidth
                            variant="text"
                            color="primary"
                            size="small"
                            style={{ margin: 5 }}
                          >
                            <p
                              style={{
                                width: "100%",
                                margin: 0,
                                textAlign: "right",
                              }}
                            >
                              صادر شده برای :{" "}
                              {this.state.paymentInfo.brokerId.name}
                            </p>
                          </Button>

                          <Button
                            onClick={() => this.cancelPayment()}
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{
                              margin: 5,
                              backgroundColor: "red",
                              width: 100,
                            }}
                          >
                            {this.state.cancelPaymentRequest ? (
                              <CircularProgress color={"inherit"} size={20} />
                            ) : (
                              "لغو فاکتور"
                            )}
                          </Button>

                          <Button
                            onClick={() => {
                              this.props.BrokersStore.setCarPaymentFilter(
                                this.state.data
                              );
                              if (
                                !this.props.AppRouterStore.getLastHistoryPath() ||
                                this.props.AppRouterStore.getLastHistoryPath().indexOf(
                                  "payments"
                                ) < 0
                              ) {
                                this.props.history.push("/payments/created");
                              } else {
                                this.props.onClose();
                              }
                            }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ margin: 5, width: 150 }}
                          >
                            تمام تراکنشهای خودرو
                          </Button>
                        </div>
                      )}
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: "15px 5px 5px 5px",
                  }}
                >
                  {this.state.data.hasAccess ? (
                    <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                      درخواست ها ({this.state.data.totalRequests})
                    </p>
                  ) : null}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.getData();
                    }}
                    size="small"
                  >
                    بروزرسانی درخواست ها
                  </Button>
                </div>

                {this.state.data.hasAccess ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      padding: 5,
                    }}
                  >
                    {this.state.data.requests.length > 0 ? (
                      this.state.data.requests.map((v, i) =>
                        !v ? (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              flex: 1,
                              padding: 5,
                              backgroundColor: "orange",
                            }}
                          >
                            <p style={{ margin: "0 5px" }}>تغییر وضعیت جدید</p>
                          </div>
                        ) : (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              flex: 1,
                              borderBottom: "1px solid #d2d2d2",
                              backgroundColor: this.state.data.requestWinner
                                ? this.state.data.requestWinner._id === v._id
                                  ? "#daffe4"
                                  : "#fff"
                                : this.state.data.brokerWinner
                                ? v.brokerId ===
                                  this.state.data.brokerWinner._id
                                  ? "#daffe4"
                                  : "#fff"
                                : "#fff",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                flex: 1,
                                fontSize: 13,
                                margin: 5,
                              }}
                            >
                              <p
                                style={{
                                  margin: 5,
                                  minWidth: 90,
                                }}
                              >
                                {this.getFormatedPrice(v.price)}
                              </p>
                              {v.auctionType && (
                                <p
                                  style={{
                                    minWidth: 40,
                                    margin: 5,
                                    color:
                                      v.auctionType === 3
                                        ? "#a24700"
                                        : "#618500",
                                  }}
                                >
                                  {v.auctionType === 3 ? "دپو" : "مزایده"}
                                </p>
                              )}
                              {v.dateTime && (
                                <p
                                  style={{
                                    minWidth: 125,
                                    margin: 5,
                                    color: "#8a8a8a",
                                  }}
                                >
                                  {moment(v.dateTime).format(
                                    "jYYYY/jM/jD , HH:mm:ss"
                                  )}
                                </p>
                              )}
                            </div>
                            <Button
                              variant="text"
                              onClick={() =>
                                this.setState({
                                  showStatusDialogBroker: v.brokerId,
                                })
                              }
                              color="secondary"
                              size="small"
                              style={{
                                margin: 5,
                                width: 100,
                                padding: 0,
                                height: 35,
                              }}
                            >
                              <p
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  margin: 5,
                                  width: "100%",
                                  textAlign: "right",
                                }}
                              >
                                {v.name ? v.name : "مشخصات بروکر"}
                              </p>
                            </Button>

                            <Button
                              onClick={() => this.setBrokerWinner(v)}
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{ margin: 5, height: 35 }}
                            >
                              ثبت برنده
                            </Button>
                          </div>
                        )
                      )
                    ) : (
                      <p
                        style={{
                          margin: 10,
                          textAlign: "center",
                          fontSize: 13,
                        }}
                      >
                        درخواستی موجود نیست
                      </p>
                    )}

                    <div style={{ ...Styles.centerAlignItemsContainer }}>
                      <PaginationView
                        total={parseInt(this.state.data.totalRequestsPage)}
                        page={parseInt(this.state.requestsPage)}
                        nextOnPress={() => {
                          this.state.requestsPage += 1;
                          this.getData();
                        }}
                        prevOnPress={() => {
                          this.state.requestsPage -= 1;
                          this.getData();
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={carStyles.notAuthorized}>
                    شما دسترسی لازم را ندارید
                  </div>
                )}
              </div>
            )}
          </DialogContent>

          {this.state.sendRequest ||
          this.state.getDataRequestErr ||
          this.state.getDataRequest ? (
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={25} />
            </DialogActions>
          ) : (
            <DialogActions style={{ borderTop: "1px solid #d2d2d2" }}>
              {(stores.AccountStore.type === "admin" ||
                stores.AccountStore.type === "agent" ||
                stores.AccountStore.type === "coworker") && (
                <FormControl style={{ flex: 1, margin: 10 }} label="وضعیت">
                  <Select
                    defaultValue={
                      this.state.status === "" ? "0" : this.state.status
                    }
                    variant="outlined"
                    margin={"dense"}
                    style={{ fontSize: 13 }}
                    onChange={(e) =>
                      this.setState({
                        status: e.target.value === "0" ? "" : e.target.value,
                      })
                    }
                  >
                    <MenuItem style={{ fontSize: 13 }} value={"0"}>
                      انتخاب وضعیت
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value={"accepted"}>
                      تایید
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value={"deleted"}>
                      حذف
                    </MenuItem>
                  </Select>
                </FormControl>
              )}

              {(stores.AccountStore.type === "admin" ||
                stores.AccountStore.type === "agent" ||
                stores.AccountStore.type === "coworker") && (
                <FormControl style={{ flex: 1, margin: 10 }} label="نوع آگهی">
                  <Select
                    defaultValue={
                      this.state.data.type === "" ? "0" : this.state.data.type
                    }
                    variant="outlined"
                    margin={"dense"}
                    style={{ fontSize: 13 }}
                    onChange={(e) => {
                      this.state.data.type =
                        e.target.value === "0" ? "" : e.target.value;
                      this.setState({ ref: true });
                    }}
                  >
                    <MenuItem style={{ fontSize: 13 }} value={"0"}>
                      انتخاب نوع آگهی
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value={"in_queue"}>
                      کارشناسی
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value={"live"}>
                      مزایده زنده
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value={"depot"}>
                      دپو
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value={"public"}>
                      عمومی
                    </MenuItem>
                    <MenuItem
                      disabled={!this.state.data.brokerWinner}
                      style={{ fontSize: 13 }}
                      value={"negotiating"}
                    >
                      درحال مذاکره
                    </MenuItem>
                    <MenuItem
                      disabled={!this.state.data.brokerWinner}
                      style={{ fontSize: 13 }}
                      value={"awaiting_payment"}
                    >
                      تسویه نشده
                    </MenuItem>
                    <MenuItem
                      disabled={!this.state.data.brokerWinner}
                      style={{ fontSize: 13 }}
                      value={"payment_done"}
                    >
                      پرداخت شده
                    </MenuItem>
                    <MenuItem style={{ fontSize: 13 }} value={"ended"}>
                      تمام شده
                    </MenuItem>
                  </Select>
                </FormControl>
              )}

              <Button
                onClick={() => this.submit()}
                disabled={!this.props.hasAccessToEditStatus}
                color="primary"
              >
                ثبت
              </Button>

              <Button onClick={() => this.handleClose()} color="secondary">
                بستن
              </Button>
            </DialogActions>
          )}
        </Dialog>

        {this.state.showStatusDialogBroker && (
          <ChangeStatusDialogBroker
            id={this.state.showStatusDialogBroker}
            history={this.props.history}
            onClose={() => this.setState({ showStatusDialogBroker: false })}
            onSuccess={(item) => {}}
          />
        )}

        {this.state.alertDialogQuestionMessage !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) this.alertFunction();
              this.alertFunction = null;
              this.setState({ alertDialogQuestionMessage: "" });
            }}
            type="alert"
            question={true}
            description={this.state.alertDialogQuestionMessage}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </div>
    );
  }
}

ChangeStatusDialog.defultProps = {};

ChangeStatusDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  history: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  id: PropTypes.string.isRequired,
};
export default ChangeStatusDialog;
