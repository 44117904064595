import React, { useState } from "react";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
const SelectItem = ({ label, options, onChange, renderValue }) => {
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, stylisRTLPlugin],
  });
  const [value, setValue] = useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event);
  };
  return (
    <Box sx={{ width: 1, maxWidth: 150.5, height: 48 }}>
      <CacheProvider value={cacheRtl}>
        <FormControl
          fullWidth
          sx={{ height: 48, "& .MuiInputBase-root": { height: 48 } }}
        >
          <InputLabel
            sx={{
              // transform: "translate(12px, 10px)",
              top: "-7px",
              "&.Mui-focused": {
                // transform: "translate(14px, -9px) scale(0.75)",
                top: "0 !important",
              },
            }}
            id="demo-simple-select-label"
          >
            {label}
          </InputLabel>
          <Select
            labelId={`demo-simple-select-${label}`}
            id={`demo-simple-${label}`}
            value={value}
            label={label}
            onChange={handleChange}
            renderValue={(value) =>
              renderValue ? (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      bgcolor:
                        value === "support"
                          ? "#FF9800"
                          : value === "tech"
                          ? "#EF5350"
                          : value === "management"
                          ? "#BA68C8"
                          : "",
                    }}
                  ></Box>
                  {options.find((item) => item.value === value).title}
                </Box>
              ) : (
                options.find((item) => item.value === value).title
              )
            }
            // renderValue={(value) =>
            //   options.find((item) => item.value === value).title
            // }
            sx={{
              height: 48,
              "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                {
                  height: 48,
                  transform: "translateY(10px) !important",
                },
              "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input ~ fieldset":
                {
                  height: 48,
                },
            }}
          >
            {options?.map((item) => (
              <MenuItem value={item.value}>{item.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </CacheProvider>
    </Box>
  );
};

export default SelectItem;
