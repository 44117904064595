exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".requests_clerkSellRequest__2o_BN {\n  display: flex;\n  align-items: center;\n  color: #ff8400;\n  margin-left: 5px;\n}\n.requests_RequestTextStyle__2FkM5 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.requests_RequestTextStyle__2FkM5 p,\nh4 {\n  margin: 5px;\n}\n.requests_boxRequestStyle__2zmIv {\n  display: flex;\n  flex-direction: column;\n  padding: 2px;\n  width: 280px;\n  background-color: #fff;\n  border: 1px solid #ddd;\n  margin: 10px;\n}\n.requests_btnRequestBox__1Rh4a {\n  margin-top: 2px;\n  color: dodgerblue;\n}\n.requests_dateStyle__3waeE {\n  direction: ltr;\n}\n.requests_colorRed__2IkFT {\n  color: red;\n}\n.requests_colorGreen__2QQJq {\n  color: green;\n}\n.requests_displayFlex__2C2Tq {\n  display: flex;\n}\n.requests_marginTop__3eAfe {\n  margin-top: 5px;\n}\n.requests_flexOne__1b9Hl {\n  flex: 1 1;\n}\n.requests_btnBadgeStyle__24Dtv {\n  z-index: 0 !important;\n}\n.requests_dialogTimeFieldStyle__1Z2h4 {\n  display: flex;\n  flex-wrap: wrap;\n}\n.requests_dialogContentDataTime__1NRCS {\n  width: 300px;\n  padding: 5px;\n  overflow: hidden !important;\n}\n", ""]);

// Exports
exports.locals = {
	"clerkSellRequest": "requests_clerkSellRequest__2o_BN",
	"RequestTextStyle": "requests_RequestTextStyle__2FkM5",
	"boxRequestStyle": "requests_boxRequestStyle__2zmIv",
	"btnRequestBox": "requests_btnRequestBox__1Rh4a",
	"dateStyle": "requests_dateStyle__3waeE",
	"colorRed": "requests_colorRed__2IkFT",
	"colorGreen": "requests_colorGreen__2QQJq",
	"displayFlex": "requests_displayFlex__2C2Tq",
	"marginTop": "requests_marginTop__3eAfe",
	"flexOne": "requests_flexOne__1b9Hl",
	"btnBadgeStyle": "requests_btnBadgeStyle__24Dtv",
	"dialogTimeFieldStyle": "requests_dialogTimeFieldStyle__1Z2h4",
	"dialogContentDataTime": "requests_dialogContentDataTime__1NRCS"
};