import React, { useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import stores from "../../../data/stores";
import { CircularProgress } from "@material-ui/core";
import { carAuctionTypeByCode } from "../../../data/presenter/GetCases";
import styles from "./car.module.css";

var momentServerOffset = require("moment");
var moment = require("moment-jalaali");

export default function ListItems({
  image,
  brand,
  model,
  code,
  totalRequests,
  auctionTime,
  views,
  isLive,
  createdAt,
  hashCode,
  type,
  onClickEdit,
  nameAgent,
  onClickStatus,
  statusIndicator = false,
  openModal,
}) {
  let [diffTime, setDiffTime] = React.useState(
    auctionTime &&
      moment
        .duration(moment(auctionTime).diff(momentServerOffset()))
        .asMinutes()
  );

  let time = diffTime && diffTime > 0 ? Math.ceil(diffTime) : 0;

  const timer =
    isLive &&
    setTimeout(
      () =>
        diffTime &&
        diffTime > 0 &&
        setDiffTime(
          auctionTime &&
            moment
              .duration(moment(auctionTime).diff(momentServerOffset()))
              .asMinutes()
        ),
      3000
    );

  useEffect(() => {
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        width: 305,
        backgroundColor: "#fff",
        border: "1px solid #ddd",
        margin: 7,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {image && image !== "" ? (
            <img
              style={{
                objectFit: "cover",
                width: 110,
                height: 100,
                margin: 5,
                border: "1px solid #d2d2d2",
              }}
              src={image}
            />
          ) : (
            <img
              style={{
                objectFit: "fill",
                width: 110,
                height: 100,
                margin: 5,
                border: "1px solid #d2d2d2",
              }}
              src={require("../../../assets/images/no_car_image.jpg")}
            />
          )}
          <div
            style={{
              margin: "0 5px 0 5px",
              flex: 1,
              borderRadius: 2,
              backgroundColor: "#eee",
              fontSize: 12,
            }}
          >
            {isLive ? (
              <div
                style={{
                  display: "flex",
                  borderRadius: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: time > 0 ? "#e8fff2" : "#ffe0e0",
                }}
              >
                <p style={{ margin: 5 }}>مزایده</p>
                <p style={{ margin: 5, direction: "ltr" }}>{time} دقیقه</p>
              </div>
            ) : (
              <p style={{ margin: 0, padding: 5, textAlign: "center" }}>
                {carAuctionTypeByCode[type]}
              </p>
            )}
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div className={styles.infoCarItemStyle}>
            <h4 style={{ margin: 5 }}>کد</h4>
            <h4 style={{ margin: 5, direction: "ltr" }}>{code}</h4>
          </div>

          <div className={styles.infoCarItemStyle}>
            <h4 style={{ margin: 5 }}>{brand}</h4>
            <h4 style={{ margin: 5 }}>{model}</h4>
          </div>

          <div className={styles.infoCarItemStyle}>
            <p style={{ margin: 5 }}>شعبه</p>
            <p style={{ margin: 5 }}>{nameAgent}</p>
          </div>

          <div className={styles.infoCarItemStyle}>
            <p style={{ margin: 5 }}>تاریخ</p>
            <p style={{ margin: 5 }}>
              {moment(createdAt).format(" HH:mm:ss jYYYY/jM/jD")}
            </p>
          </div>

          <div className={styles.infoCarItemStyle}>
            <p style={{ margin: 5 }}>بازدید</p>
            <p style={{ margin: 5 }}>{views}</p>
          </div>

          <div className={styles.infoCarItemStyle}>
            <p style={{ margin: 5 }}>درخواستها</p>
            <p style={{ margin: 5 }}>{totalRequests}</p>
          </div>
        </div>
      </div>
      <Divider style={{ marginTop: 5, marginBottom: 0 }} />
      <div style={{ display: "flex" }}>
        {stores.AccountStore.type !== "seller" && (
          <Button
            onClick={onClickEdit}
            color="primary"
            style={{ marginTop: 2 }}
          >
            ویرایش
          </Button>
        )}
        <a target="_blank" href={`https://nikbakhtkhodro.com/c/${hashCode}`}>
          <Button color="secondary" style={{ marginTop: 2 }}>
            مشاهده
          </Button>
        </a>
        {(stores.AccountStore.type === "admin" ||
          stores.AccountStore.type === "agent" ||
          stores.AccountStore.type === "coworker" ||
          stores.AccountStore.type === "seller") && (
          <Button
            onClick={onClickStatus}
            color="secondary"
            style={{ marginTop: 2 }}
          >
            {statusIndicator ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              "وضعیت"
            )}
          </Button>
        )}
        {(
          stores.AccountStore.type === "admin" ||
          // stores.AccountStore.type === "agent" ||
          stores.AccountStore.type === "coworker" 
          ) && (
          <Button
            onClick={() => openModal()}
            color="secondary"
            style={{ marginTop: 2 }}
          >
            استعلام
          </Button>
        )}
      </div>
    </div>
  );
}
