import React, { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";

import { ApiClient, ApiHelper } from "../../../../data/remote";
import AlertDialog from "../../../common/AlertDialog";
import { Statement } from "../../../../values/Statement";
import { deleteLink } from "../../../../Store/slice/sliceListInfoLinks";

import { useDispatch } from "react-redux";

const DeleteExclusiveLink = ({ infoLink }) => {
  const apiClient = ApiClient.getInstance();
  const dispatch = useDispatch();

  const { _id: idLink } = infoLink;
  const [alertDialog, setAlertDialog] = useState(false);

  const handlerDeleteLink = () => {
    apiClient.delete(
      ApiHelper.delete_link_DELETE(idLink),
      (error, response) => {
        if (error) {
        } else {
          if (response.success) {
            dispatch(deleteLink(response.link));
          }
        }
      }
    );
  };

  return (
    <>
      <Tooltip title="حذف">
        <DeleteIcon size="small" onClick={() => setAlertDialog(true)} />
      </Tooltip>
      {alertDialog && (
        <AlertDialog
          onClose={({ yesPress }) => {
            if (yesPress) {
              handlerDeleteLink();
            } else {
              setAlertDialog(false);
            }
          }}
          question={true}
          description={Statement.deleteRequestLink}
          negativeTextButton={"خیر"}
          positiveTextButton={"بله"}
        />
      )}
    </>
  );
};

export default DeleteExclusiveLink;
