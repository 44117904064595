import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

class AddMoreDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      value: "",
      inputError: -1,
    };
  }

  handleClose() {
    this.props.onClose();
  }

  submit() {
    this.setState({ inputError: -1 });

    if (this.state.title.toString().trim() === "") {
      this.setState({ inputError: 1 });
      return;
    }

    if (this.state.value.toString().trim() === "") {
      this.setState({ inputError: 2 });
      return;
    }

    this.props.onSuccess({ title: this.state.title, value: this.state.value });
  }

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: 300, padding: 5 }}>
            <div style={{ position: "relative" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  onChange={(t) => (this.state.title = t.target.value)}
                  style={{ margin: 10, flex: 1 }}
                  error={this.state.inputError === 1}
                  defaultValue={this.state.title}
                  label={"عنوان"}
                  placeholder={"عنوان"}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{ style: { fontSize: 15 }, maxLength: 400 }}
                />
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  onChange={(t) => (this.state.value = t.target.value)}
                  style={{ margin: 10, flex: 1 }}
                  error={this.state.inputError === 2}
                  defaultValue={this.state.value}
                  label={"توضیح"}
                  placeholder={"توضیح"}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{ style: { fontSize: 15 }, maxLength: 400 }}
                />
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => this.submit()} color="primary">
              انجام
            </Button>

            <Button onClick={() => this.handleClose()} color="secondary">
              بستن
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddMoreDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default AddMoreDialog;
