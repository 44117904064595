import { makeStyles } from "@material-ui/core";

export const main = makeStyles({
  root: {
    position: "relative",
    zIndex: "0",
    marginTop: "70px",
  },

  tabletMarginTop: {
    marginTop: "40px",
  },
});

export const checkBox = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "32px",
    paddingLeft: "20px",
  },

  responsiveMobile: {
    paddingLeft: "unset !important",
  },
});

export const checkBoxInput = makeStyles({
  root: {
    "& .MuiFormControlLabel-root": {
      marginInline: "unset",
      "& .MuiFormControlLabel-label": {
        color: "#0000008F",
      },
    },
  },

  fontSizeMobile: {
    "& .MuiFormControlLabel-root": {
      marginInline: "unset",
      "& .MuiFormControlLabel-label": {
        color: "#0000008F",
        fontSize: "11.5px",
      },
    },
  },
});

export const btnSolve = makeStyles({
  root: {
    "&.MuiButton-outlinedPrimary": {
      border: "1px solid #0000001F !important",
      borderRadius: "100px",
    },

    transition: "all .3s",

    "&:hover": {
      backgroundColor: "#2196F30A",
    },

    "& .MuiButton-label .MuiButton-startIcon .MuiSvgIcon-root": {
      fill: "#4CAF50",
    },

    "&.Mui-disabled .MuiButton-label .MuiButton-startIcon .MuiSvgIcon-root": {
      fill: "#00000061",
    },
  },

  fontSizeMobile: {
    fontSize: "11.5px",
    "&.MuiButton-outlined": {
      padding: "5px 9px",
    },
  },
});

export const chart = makeStyles({
  root: {
    border: " 1px solid #0000001F",
    borderRadius: "8px",
    padding: "16px, 24px, 16px, 32px",
  },

  noneBorder: {
    border: "unset !important",
  },
});

export const gridContainer = makeStyles({
  root: {
    paddingLeft: "30px",
    display: "grid",
    gridTemplateColumns: "2fr repeat(3, .7fr)",
    gridAutoRows: "80px",
    alignItems: "center",

    "&:not(:last-child)": {
      borderBottom: "1px solid #0000001F",
    },

    "& > *:not(:first-child)": {
      justifySelf: "center",
    },

    "& > time": {
      color: "#00000099",
    },

    "& > p": {
      color: "#00000099",
    },

    "& > button": {
      color: "#2196F3",
      cursor: "pointer",
      fontSize: "16px",
      position: "relative",
      textDecorationLine: "underline",
      textUnderlineOffset: "6px",

      "&:hover": {
        textDecorationLine: "underline",
        textUnderlineOffset: "6px",
        background: "unset",
      },
    },
  },

  responsiveLg: {
    paddingLeft: "11px",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    // gridTemplateRows: "80px",
    gridAutoRows: "45px",
    alignItems: "center",
  },

  responsiveFirstChild: {
    gridColumn: "1 / -1",
    justifySelf: "start",
    "& .MuiFormControlLabel-label": {
      fontSize: "12px !important",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "-webkit-line-clamp": "1",
    },
  },

  responsiveDateTime: {
    gridColumn: "1 / 3",
    gridRow: "2",
    justifySelf: "start !important",
    paddingLeft: "6px",
    fontSize: "11.5px",
  },

  paddingRightDateTime: {
    paddingLeft: "8px",
    fontSize: "11.5px !important",
  },

  timeFontSizeLaptop: {
    fontSize: "13px !important",
  },

  fontSizeSituation: {
    fontSize: "11.5px",
  },

  situation: {
    justifySelf: "end !important",
    paddingRight: "21px",
    position: "relative",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    direction: "rtl",
  },

  bullet: {
    display: "inline-block",
    width: "6px",
    height: "6px",
    borderRadius: "100%",
    position: "absolute",
    transform: "translate(10%, 87%)",
    right: "7%",
  },

  bulletRed: {
    backgroundColor: "red",
  },

  bulletYellow: {
    backgroundColor: "#ff9800",
  },

  bulletPink: {
    backgroundColor: "violet",
  },

  gridLaptop: {
    gridTemplateColumns: " repeat(4, 1fr) !important",
    paddingLeft: "10px",
  },

  situationGrid: {
    paddingRight: "23px",
    fontSize: "12px !important",
  },

  titleChart: {
    "& .MuiFormControl-root .MuiTypography-root": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },

  situationMobile: {
    gridColumn: "3 / -1",
  },
});

export const checkBoxChart = makeStyles({
  root: {
    color: "#000000DE",
    fontSize: "16px",
  },

  responsiveLapTop: {
    "& .MuiFormControlLabel-label": {
      fontSize: "13px",
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    fontSize: "14px",
    width: "600px",
    height: "400px",
    paddingBlock: "16px",
    borderRadius: "20px",
  },
  container: {
    paddingInline: "32px",
  },
  heading: {
    fontSize: "18px",
  },
  justifyBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  btnClose: {
    "&.MuiButton-root": {
      marginRight: "-26px !important",
    },

    "& .MuiButton-label": {
      fontSize: "27px",
    },
  },

  date: {
    color: "#00000061",
  },

  dateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  },
  mb: {
    marginBottom: "-5px",
  },

  mt: {
    marginTop: "10px",
    marginBottom: "16px",
  },

  mBlock: {
    marginBlock: "16px",
  },

  middlePart: {
    height: "160px",
    overflowY: "scroll",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: "10px !important",
    },

    "&::-webkit-scrollbar": {
      width: "12px",
    },

    "&::-webkit-scrollbar-track": {
      display: "none !important",
    },

    scrollbarWidth: "thin",
    scrollbarColor: "#1E88E5 transparent",
  },

  radius: {
    borderRadius: "100px",
  },

  btnContainer: {
    paddingLeft: "32px",
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  bullet: {
    display: "inline-block",
    width: "6px",
    height: "6px",
    borderRadius: "100%",
    transform: "translateY(-1px)",
  },

  bulletRed: {
    backgroundColor: "red",
  },

  bulletYellow: {
    backgroundColor: "#ff9800",
  },

  bulletPink: {
    backgroundColor: "violet",
  },
}));

export const paginationContainer = makeStyles((theme) => ({
  root: {
    marginTop: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiPagination-root .MuiPagination-ul .MuiButtonBase-root .MuiSvgIcon-root":
      {
        fill: theme.palette.primary.main,
        width: "35px",
        height: "40px",
      },

    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },

    "& .MuiPaginationItem-page.Mui-disabled .MuiSvgIcon-root": {
      fill: "#00000061 !important",
    },

    "& li:not(:first-child):not(:last-child) .MuiPaginationItem-page:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "#fff",
    },

    "& .MuiPaginationItem-root": {
      fontSize: "17px",
    },
  },

  tabletMarginTop: {
    marginTop: "35px",
  },

  mobileFontSize: {
    "& .MuiPaginationItem-root": {
      fontSize: "14px",
    },

    "& .MuiButtonBase-root": {
      fontSize: "15px",
    },

    "& .MuiPaginationItem-root": {
      minWidth: "22px",
      minHeight: "22px",
      padding: "0 3px",
    },

    "& .MuiPagination-root .MuiPagination-ul .MuiButtonBase-root .MuiSvgIcon-root":
      {
        fill: theme.palette.primary.main,
        width: "30px",
        height: "35px",
      },
  },
}));

export const buttonStyle = makeStyles({
  root: {
    "&.MuiButton-contained": {
      backgroundColor: "#fff",
    },
    border: "1px solid rgba(0, 0, 0, .08)",
    paddingBlock: "unset",
  },

  firstButtonStyle: {
    borderTopLeftRadius: "100px",
    borderBottomLeftRadius: "100px",
    borderTopRightRadius: "unset",
    borderBottomRightRadius: "unset",
    flexBasis: "120px",
    borderRight: "1px solid rgba(0, 0, 0, .08)",
    "&:hover": {
      backgroundColor: "#2196F314",
      "& .MuiButton-label .MuiButton-startIcon .MuiSvgIcon-root": {
        fill: "red",
      },
    },
  },

  responsiveFirstButtonStyle: {
    fontSize: "11.9px",
    flexBasis: "80px",
    "&.MuiButton-root": {
      padding: "6px 0 !important",
    },

    "& .MuiButton-label .MuiButton-startIcon": {
      marginRight: "2px",
    },

    "& .MuiButton-startIcon svg": {
      fontSize: "15px",
    },
  },

  secondButtonStyle: {
    borderTopRightRadius: "100px",
    borderBottomRightRadius: "100px",
    borderTopLeftRadius: "unset",
    borderBottomLeftRadius: "unset",
    borderLeft: "1px solid rgba(0, 0, 0, .08)",
    flexBasis: "130px",
    "&:hover": {
      backgroundColor: "#2196F30A",
    },
  },

  responsiveSecondButtonStyle: {
    fontSize: "11.9px",
    flexBasis: "95px",

    "&.MuiButton-root": {
      padding: "6px 0 !important",
    },

    "& .MuiButton-label .MuiButton-startIcon": {
      marginRight: "2px",
    },

    "& .MuiButton-startIcon svg": {
      fontSize: "15px",
    },
  },

  firstActiveButton: {
    "&.MuiButton-contained": {
      backgroundColor: "rgba(33, 150, 243, 0.08)",
      "& .MuiButton-label .MuiButton-startIcon .MuiSvgIcon-root": {
        fill: "red",
      },
    },
  },

  secondActiveButton: {
    "&.MuiButton-contained": {
      backgroundColor: "#2196F314",
      "& .MuiButton-label .MuiButton-startIcon .MuiSvgIcon-root": {
        fill: "#4CAF50",
      },
    },
  },
});

export const skeletonChart = makeStyles({
  root: {
    "&:not(:last-child)": {
      marginBottom: "1px",
    },
    width: "100%",
  },

  laptop: {
    maxWidth: "645px",
  },

  tablet: {
    maxWidth: "862px",
  },
});

export const noErrorStyle = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "48px",
    "& .MuiBox-root": {
      width: "100%",
      maxWidth: "200px",
      minWidth: "150px",
      "& > img": {
        width: "100%",
        height: "100%",
      },
    },
  },
});

export const datePickerTop = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "36px",
    "& .MuiBox-root": {
      position: "relative",
      "& svg": {
        position: "absolute",
        right: "23px",
        transform: "translateY(-50%)",
        top: "32.5%",
        pointerEvents: "none",
      },

      "& span:first-child": {
        position: "absolute",
        left: "10px",
        top: "-11px",
        zIndex: "1",
        backgroundColor: "#fff",
        color: "#00000099",
        padding: "1px 3px",
        fontSize: "12px !important",
      },

      "& span:nth-child(3)": {
        fontSize: "14px",
        color: "#00000099",
        display: "inline-block",
        marginTop: "8px",
        marginLeft: "16px",
      },
    },
    "& .MuiBox-root > input": {
      padding: "8px",
      fontSize: "16px !important",
      lineHeight: "24px",
      position: "relative",
      width: "100%",
      border: "2px solid #0000001F",
      borderRadius: "4px",
      fontFamily: "inherit",
    },
  },
});

export const calendar = makeStyles({
  root: {
    marginTop: "86px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .zm-Header": {
      height: "50px",
    },
  },
});

export const fullWidth = makeStyles({
  root: {
    "& .MuiBox-root": {
      width: "100%",
    },
  },
});
