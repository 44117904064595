import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { useStyles } from "../../styles/main";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAllChartCheckBoxes,
  deleteChartCheck,
} from "../../Store/slice/getErrorsParams";
import { usePatchResolveMutation } from "../../Store/callApi/apiGetErrors";

const ModalError = ({
  openModal,
  onSetOpen,
  label,
  situation,
  date,
  time,
  content,
  onchecked,
  id,
}) => {
  const classes = useStyles();
  const { chartCheckBox: chartCheckBoxMain } = useSelector(
    (store) => store.getErrorsParams
  );
  const dispatch = useDispatch();
  const [patchFn, b] = usePatchResolveMutation();
  const handleFillCheckbox = () => {
    if (chartCheckBoxMain?.some((title) => title === id)) {
      dispatch(deleteChartCheck(id));
      onchecked(false);
    }
  };
  const resolve = async () => {
    await patchFn({
      errsIDs: [id],
      resolved: true,
    });
    dispatch(deleteAllChartCheckBoxes());
    onSetOpen((cur) => !cur);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={() => onSetOpen((cur) => !cur)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: classes.backdrop,
      }}
    >
      <Fade in={openModal}>
        <div className={classes.paper}>
          <Box component="div" display="flex" flexDirection="column" gap={8}>
            <Box
              className={`${classes.container} ${classes.justifyBetween} ${classes.mb}`}
            >
              <Typography
                component="h3"
                variant="h3"
                id="transition-modal-title"
                className={classes.heading}
              >
                {label}
              </Typography>
              <Button
                disableRipple
                className={classes.btnClose}
                onClick={() => {
                  onSetOpen((cur) => !cur);
                  handleFillCheckbox();
                }}
              >
                &times;
              </Button>
            </Box>
            <Box
              component="div"
              className={`${classes.container} ${classes.dateContainer}`}
            >
              <Typography variant="body2" className={classes.date}>
                {date}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography variant="body2">
                ساعت <Box component="time">{time}</Box>
              </Typography>
            </Box>
            <Box
              component="div"
              className={`${classes.container} ${classes.mt}`}
            >
              <Typography variant="body2">
                {
                  <span
                    className={`${classes.bullet} ${
                      situation === "normal"
                        ? classes.bulletYellow
                        : situation === "ultimate"
                        ? classes.bulletRed
                        : situation === "necessary"
                        ? classes.bulletPink
                        : ""
                    }`}
                  ></span>
                }{" "}
                {situation}
              </Typography>
            </Box>
          </Box>
          <Divider variant="fullWidth" />
          <Box
            component="div"
            className={`${classes.container} ${classes.mBlock} ${classes.middlePart}`}
          >
            <Typography variant="body2" style={{ textAlign: "justify" }}>
              {content}
            </Typography>
          </Box>
          <Divider variant="fullWidth" />

          <Box component="div" className={classes.btnContainer}>
            <Button
              color="primary"
              variant="contained"
              className={classes.radius}
              onClick={resolve}
            >
              حل شد
            </Button>
            <Button
              variant="outlined"
              className={classes.radius}
              onClick={() => {
                onSetOpen((cur) => !cur);
                handleFillCheckbox();
              }}
            >
              انصراف
            </Button>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalError;
