import React from "react";
const Phone = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.76085 1.66187C2.8315 1.59123 2.86682 1.5559 2.89794 1.52731C3.6631 0.82423 4.83921 0.82423 5.60436 1.52731C5.63548 1.5559 5.67081 1.59123 5.74145 1.66187L7.19965 3.12007C8.0017 3.92212 8.23109 5.13336 7.77785 6.17314C7.32461 7.21293 7.554 8.42416 8.35605 9.22622L10.7529 11.623C11.5549 12.4251 12.7661 12.6545 13.8059 12.2012C14.8457 11.748 16.057 11.9774 16.859 12.7794L18.3172 14.2376C18.3878 14.3083 18.4232 14.3436 18.4518 14.3747C19.1548 15.1399 19.1548 16.316 18.4518 17.0811C18.4232 17.1123 18.3878 17.1476 18.3172 17.2182L17.4309 18.1046C16.7091 18.8264 15.6685 19.1294 14.6721 18.9079C7.88248 17.3991 2.57995 12.0966 1.07115 5.307C0.849715 4.31054 1.15272 3.27001 1.87451 2.54821L2.76085 1.66187Z"
        stroke="#4CAF50"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default Phone;
