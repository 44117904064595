import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
const NumberRequests = () => {
  const requests = useSelector(
    (state) => state.newRequest?.data?.[0]?.requests
  );
  const requestsLength = requests?.length;
  const { activeStatus } = useSelector((state) => state.newRequest);
  if (activeStatus !== "inProgressFlow" || activeStatus !== "all")
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
        <Typography variant="body2" sx={{ fontSize: 15 }}>
          {requestsLength}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: 15 }}>
          درخواست
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color:
              activeStatus === "unknown"
                ? "#FF9800"
                : activeStatus === "notChecked"
                ? "#03A9F4"
                : activeStatus === "waiting"
                ? "#BA68C8"
                : activeStatus === "confirmed"
                ? "#4CAF50"
                : activeStatus === "canceled"
                ? "#EF5350"
                : "",
            fontSize: 15,
          }}
        >
          {activeStatus === "unknown"
            ? "مجهول"
            : activeStatus === "notChecked"
            ? "بررسی نشده"
            : activeStatus === "waiting"
            ? "درانتظارمشتری"
            : activeStatus === "confirmed"
            ? "تائید شده"
            : activeStatus === "canceled"
            ? "لغوشده"
            : ""}
        </Typography>
      </Box>
    );
};

export default NumberRequests;
