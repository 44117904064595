import { makeStyles } from "@material-ui/core";

export const filterContainer = makeStyles({
  root: {
    paddingBlock: "",
  },
});

export const filterMobileStyles = makeStyles({
  btn: {
    "&.MuiButton-outlined": {
      padding: "2px 10px 2px 10px ",
    },
    borderRadius: "20px",
    fontSize: "12px",
    minWidth: "unset",
    "&:hover": {
      background: "unset",
    },
  },

  container: {
    width: "100%",
  },
  child: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gap: "8px",
    marginBottom: "24px",
    borderBlock: "1px solid #0000000D",
    paddingBlock: "8px",
  },

  child2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    gap: "8px",
    flexWrap: "nowrap",
    overflowX: "auto",
    scrollbarWidth: "none",
    // width: "max-content",
  },

  filterBtn: {
    "&.MuiButton-outlined": {
      border: "1px solid #00000099",
    },
  },

  selectCheckBox: {
    minWidth: "unset",
    border: "1px solid #2196F3",
    padding: "2px 10px 2px 10px",
    borderRadius: "20px",
    fontSize: "12px",
    color: "#2196F3",
    textTransform: "lowercase !important",
    "&:hover": {
      background: "unset",
    },
    "& .MuiButton-endIcon": {
      marginLeft: "unset",
      "& svg": {
        width: "16px",
        height: "12px",
        fill: "#2196F3",
      },
    },
  },
});
