import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    boxShadow: theme.shadows[2],
    width: "100%",
    backgroundColor: "#fff",
    padding: "6px 16px",
    borderRadius: theme.shape.borderRadius,
    direction: "ltr",

    "& .MuiInputBase-root": {
      color: "#01579B",
      fontSize: "14px",
      width: "100%",
      lineHeight: "1.75em",
      "& .MuiSvgIcon-root": {
        fill: "#01579B",
      },
    },

    "& .MuiInput-underline::after": {
      display: "none",
    },

    "& .MuiInput-underline::before": {
      display: "none",
    },

    "& .MuiInputBase-input": {
      padding: "unset",
    },

    "& .MuiSelect-select:focus:focus": {
      backgroundColor: "#fff",
    },
  },
  responsiveFontSize: {
    "& .MuiInputBase-root": {
      fontSize: "12.5px",
    },
  },

  responsiveLaptop: {
    padding: "6px 8px",
    "& .MuiInputBase-root": {
      fontSize: "11.5px",
    },
  },
}));
