import { Box, FormControlLabel, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";

import React, { memo } from "react";
import { dateFormatter } from "../../utils/persianDate/dateFormatter";
import { useAssignCoworkerToRequestMutation } from "../../Store/callApi/request";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoadingTakeOver } from "../../Store/slice/sliceNewRequest";
import LoadingBalls from "../../gifs/loadingTripleDot.gif";
const ModalServiceDate = memo(
  ({
    showInfo,
    onSetShowInfo,
    requestId,
    coworkerId,
    togetway,
    assignCoworkerDate,
  }) => {
    const { data: dataResponse } = useSelector((state) => state.newRequest);
    const userType = dataResponse?.[0]?.userType;
    const dispatch = useDispatch();
    const { isLoadingTakeOver } = useSelector((store) => store.newRequest);
    const [assignFn, { data, isLoading, isError, isSuccess }] =
      useAssignCoworkerToRequestMutation();
    const handleCheckedChange = async (e) => {
      e.preventDefault();
      await assignFn({ requestId, userId: coworkerId });
    };

    if (isLoading) {
      dispatch(setIsLoadingTakeOver(true));
    }
    if (!isLoading) {
      if (showInfo && isSuccess) {
        onSetShowInfo(true);
        dispatch(setIsLoadingTakeOver(false));
      }
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {userType !== "admin" && (
          <FormControlLabel
            sx={{
              "&.MuiFormControlLabel-root": {
                width: "100%",
                justifyContent: "space-between",
                marginInline: "unset",
                color: "text.secondary",
                "& .MuiTypography-root": {
                  fontSize: { xs: 13, sm: 16 },
                },
              },
            }}
            control={
              <Switch
                checked={showInfo}
                disabled={showInfo || isLoading}
                onChange={handleCheckedChange}
              />
            }
            label="برعهده گرفتن"
            labelPlacement="start"
          />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
          >
            مسئول درخواست
          </Typography>
          {!isLoadingTakeOver ? (
            <Typography
              variant="body1"
              sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
            >
              {showInfo ? showInfo?.name : "نامشخص"}
            </Typography>
          ) : (
            <Box component="img" sx={{ height: 30 }} src={LoadingBalls} />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
          >
            تاریخ انتصاب
          </Typography>
          {isLoadingTakeOver ? (
            <Box component="img" sx={{ height: 30 }} src={LoadingBalls} />
          ) : (
            <Typography
              variant="body1"
              sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
            >
              {showInfo && !assignCoworkerDate && isSuccess
                ? `${dateFormatter(
                    data?.assignDate ? data?.assignDate : "",
                    true
                  )
                    .clock()
                    .slice(0, -3)} - ${dateFormatter(
                    data?.assignDate ? data?.assignDate : "",
                    false
                  ).shortMonth()}`
                : showInfo && assignCoworkerDate
                ? `${dateFormatter(assignCoworkerDate, true)
                    .clock()
                    .slice(0, -3)} - ${dateFormatter(
                    assignCoworkerDate,
                    false
                  ).shortMonth()}`
                : "نامشخص"}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", fontSize: { xs: 13, sm: 16 } }}
          >
            درخواست از
          </Typography>

          {showInfo ? (
            <Typography
              variant="body1"
              sx={{
                color: "text.primary",
                fontSize: { xs: 13, sm: 16 },
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              {!isLoadingTakeOver ? (
                <Typography
                  component="span"
                  sx={{ fontSize: { xs: 13, sm: 16 }, color: "text.secondary" }}
                >
                  {/* {togetway} */}
                  سایت
                </Typography>
              ) : (
                <Box component="img" sx={{ height: 30 }} src={LoadingBalls} />
              )}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              sx={{ color: "text.primary", fontSize: { xs: 13, sm: 16 } }}
            >
              نامشخص
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
);

export default ModalServiceDate;
