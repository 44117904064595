import React, { useRef, useEffect } from "react";
import { TextField } from "@material-ui/core";

const TextFieldFormik = ({ field, formik }) => {
  const ref = useRef(0);
  useEffect(() => {
    if (field.name === "name") ref.current.focus();
  }, []);
  return (
    <TextField
      size="small"
      name={field.name}
      type={field.name === "password" && "password"}
      value={formik.values[field.name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
      helperText={formik.touched[field.name] && formik.errors[field.name]}
      label={field.label}
      variant="outlined"
      fullWidth
      inputRef={ref}
    />
  );
};

export default TextFieldFormik;
