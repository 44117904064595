import React from "react";
import Drawer from "@material-ui/core/Drawer";
import DrawerListItems from "./DrawerListItems";
import { useStyles2 } from "../../../styles/drawer";
import { deleteAll } from "../../../Store/slice/getErrorsParams";

const DrawerFilter = ({ onToggler, isOpen }) => {
  const classes = useStyles2();
  return (
    <div>
      <Drawer
        anchor={"left"}
        open={isOpen}
        onClose={onToggler}
        className={classes.drawer}
      >
        <DrawerListItems deleteAll={deleteAll} onClose={onToggler} />
      </Drawer>
    </div>
  );
};

export default DrawerFilter;
