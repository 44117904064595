exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".admins_itemAdminStyle__3sLYK {\n  display: flex;\n  flex-direction: column;\n  padding: 8px;\n  width: 280px;\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgb(221, 221, 221);\n  margin: 10px;\n}\n.admins_boxItemAdminStyle__3RKtY {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  padding: 10px;\n}\n.admins_statusStyle__34Nzk {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.admins_styleTypography__OJhXO {\n  font-weight: bold !important;\n}\n.admins_addEditAdminBox__2xL51 {\n  padding: 20px;\n}\n.admins_textFieldStyle__1mHF6 {\n  margin-bottom: 20px !important;\n}\n.admins_statusDialogStyle__2f8ap {\n  padding: 20px;\n  min-width: 500px;\n}\n.admins_dialogTextStyle__1RJ77 {\n  display: flex;\n  justify-content: space-between;\n}\n.admins_selectStatus__66o3L {\n  width: 13rem;\n}\n.admins_btnColorRed__FtAeb {\n  color: red !important;\n}\n.admins_textStatusAdmin__3KpcQ{\n  margin-bottom: 10px;\n  padding: 10px;\n}\n", ""]);

// Exports
exports.locals = {
	"itemAdminStyle": "admins_itemAdminStyle__3sLYK",
	"boxItemAdminStyle": "admins_boxItemAdminStyle__3RKtY",
	"statusStyle": "admins_statusStyle__34Nzk",
	"styleTypography": "admins_styleTypography__OJhXO",
	"addEditAdminBox": "admins_addEditAdminBox__2xL51",
	"textFieldStyle": "admins_textFieldStyle__1mHF6",
	"statusDialogStyle": "admins_statusDialogStyle__2f8ap",
	"dialogTextStyle": "admins_dialogTextStyle__1RJ77",
	"selectStatus": "admins_selectStatus__66o3L",
	"btnColorRed": "admins_btnColorRed__FtAeb",
	"textStatusAdmin": "admins_textStatusAdmin__3KpcQ"
};