import React from "react";
import { DatePicker } from "zaman";

const DatePickerComp = ({ className, onchange, show = true, inputClass }) => {
  return (
    <DatePicker
      inputClass={inputClass}
      className={className}
      onChange={onchange}
      show={show}
    />
  );
};

export default DatePickerComp;
