import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "400px",
    overflowY: "scroll",
    border: "1px solid #999999",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    direction: "rtl",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "10px",
    },
  },
  listItem: {
    height: "68.49px",
    textAlign: "right",
    padding: "16px 24px 16px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listItemTablet: {
    height: "68.49px",
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  archiveIcon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tabletFont: {
    "& .MuiTypography-body2": {
      fontSize: "0.7rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "0.7rem",
    },
    "&.MuiTypography-body1": {
      fontSize: "0.7rem",
    },

  },
  tabletSpaces: {
    "&.MuiListItem-gutters": {
      padding: "0",
    },
  },
}));

export default useStyles;
