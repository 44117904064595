import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
const DrawerMobileLists = ({
  onSetOpenFilters,
  customAccordionContainer,
  onHandleRemoveFilters,
}) => {
  return (
    <Box sx={{ width: 1, maxWidth: "450px", bgcolor: "#fff", p: "14px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography component="h6">فیلترها</Typography>
        <IconButton
          sx={{ fontSize: 30 }}
          onClick={() => onSetOpenFilters((curState) => !curState)}
        >
          &times;
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="text"
          sx={{ color: "#E65100", fontSize: 12 }}
          onClick={onHandleRemoveFilters}
        >
          حذف فیلترها
        </Button>
      </Box>
      {customAccordionContainer}
    </Box>
  );
};

export default DrawerMobileLists;
