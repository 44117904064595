import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import { Styles } from "../../../values/Styles";
import RequestState from "../../common/RequestState";
import AlertDialog from "../../common/AlertDialog";
import PaginationView from "../../common/PaginationView";
import ListItems from "./ListItems";
import { Chip } from "@material-ui/core";
import ChangeStatusDialogBroker from "../brokers/ChangeStatusDialog";
import ChangeStatusDialogCar from "../cars/ChangeStatusDialog";

let apiClient = null;

@inject("AccountStore")
@inject("BrokersStore")
@observer
class Payments extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    let pageTitle = Payments.getPageTitle(props.match.params.status);
    this.state = {
      status: props.match.params.status,
      pageTitle: pageTitle,
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      totalPage: 1,
      page: props.match.params.page ? parseInt(props.match.params.page) : 1,
      message: "",
      showStatusDialogBroker: false,
      showStatusDialogCar: false,
      carId: props.BrokersStore.carPaymentFilter
        ? props.BrokersStore.carPaymentFilter._id
        : null,
      brokerId: props.BrokersStore.brokerPaymentFilter
        ? props.BrokersStore.brokerPaymentFilter._id
        : null,
    };
  }

  static getPageTitle(status) {
    let pageTitle = "پرداخت ها";

    if (status) {
      switch (status) {
        case "created":
          pageTitle = "ایجاد شده";
          break;
        case "awaiting":
          pageTitle = "در انتظار";
          break;
        case "done":
          pageTitle = "انجام شده";
          break;
        case "canceled":
          pageTitle = "لغو شده";
          break;
      }
    }
    return pageTitle;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let carId = nextProps.BrokersStore.carPaymentFilter
      ? nextProps.BrokersStore.carPaymentFilter._id
      : null;
    let brokerId = nextProps.BrokersStore.brokerPaymentFilter
      ? nextProps.BrokersStore.brokerPaymentFilter._id
      : null;

    if (
      prevState.status !== nextProps.match.params.status ||
      prevState.brokerId !== brokerId ||
      prevState.carId !== carId
    ) {
      let pageTitle = Payments.getPageTitle(nextProps.match.params.status);

      return {
        page: 1,
        pageTitle: pageTitle,
        status: nextProps.match.params.status,
        sendRequest: false,
        sendRequestErr: false,
        sendRequestStatus: "",
        data: [],
        totalPage: 1,
        showCreateCarDialog: false,
        carId: carId,
        brokerId: brokerId,
      };
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      this.getRequests(true);
    }
    if (
      prevState.brokerId !== this.state.brokerId ||
      prevState.carId !== this.state.carId
    ) {
      this.getRequests(true);
    }
  }

  componentDidMount() {
    this.getRequests();
  }

  getRequests(ref) {
    if (this.state.sendRequest) {
      return;
    }

    if (ref) {
      this.state.data = [];
      this.state.page = 1;
      this.state.totalPage = 1;
    }

    this.props.history.replace(
      "/payments/" + this.state.status + "/" + this.state.page
    );

    this.setState({ sendRequest: true, sendRequestErr: false });

    let data = {
      carAuctionIdFilter: this.state.carId,
      brokerIdFilter: this.state.brokerId,
    };

    apiClient.get(
      ApiHelper.getPayments_GET(this.state.status, this.state.page),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
            totalPage: response.totalPage,
          });
        }
      },
      data
    );
  }

  renderResult() {
    if (this.state.data.length <= 0) {
      return (
        <div style={{ margin: 10 }}>
          {`لیست پرداخت ها  ${this.state.pageTitle} خالی.`}
        </div>
      );
    }
    return (
      <div style={{ ...Styles.containerWrap900, padding: 10 }}>
        {this.state.data.map((item, i) => (
          <ListItems
            key={item._id}
            amount={item.amount}
            createdAt={item.createdAt}
            onClickBrokerInfo={() =>
              this.setState({ showStatusDialogBroker: item.brokerId?._id })
            }
            onClickCarInfo={() =>
              this.setState({ showStatusDialogCar: item.carAuctionId?._id })
            }
            status={item.status}
            authority={item.authority}
            brokerName={item.brokerId ? item.brokerId.name : "-"}
            carCode={item.carAuctionId ? item.carAuctionId.code : "-"}
            ref_id={item.zarinpal_ref_id}
            msg={item.zarinpalStatus}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <Main
        page={"payments/" + this.state.status}
        pageName={"پرداخت ها - " + this.state.pageTitle}
        centerAppBarComponent={
          <RequestState
            error={this.state.sendRequestErr}
            retryOnPress={() => this.getRequests()}
            title={"در حال دریافت لیست ..."}
            request={this.state.sendRequest}
          />
        }
        context={this.props.history}
      >
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          {this.props.BrokersStore.brokerPaymentFilter !== null && (
            <Chip
              size="medium"
              color="primary"
              style={{ margin: 20, alignSelf: "flex-start" }}
              label={this.props.BrokersStore.brokerPaymentFilter.name}
              onDelete={() => {
                this.props.BrokersStore.brokerPaymentFilter = null;
                this.getRequests(true);
              }}
            />
          )}

          {this.props.BrokersStore.carPaymentFilter !== null && (
            <Chip
              size="medium"
              color="primary"
              style={{ margin: 20, alignSelf: "flex-start" }}
              label={
                "کد خودرو : " + this.props.BrokersStore.carPaymentFilter.code
              }
              onDelete={() => {
                this.props.BrokersStore.carPaymentFilter = null;
                this.getRequests(true);
              }}
            />
          )}

          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            {this.renderResult()}
          </div>
        </div>

        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <PaginationView
            total={parseInt(this.state.totalPage)}
            page={parseInt(this.state.page)}
            nextOnPress={() => {
              this.state.page += 1;
              this.getRequests();
            }}
            prevOnPress={() => {
              this.state.page -= 1;
              this.getRequests();
            }}
          />
        </div>

        {this.state.showStatusDialogBroker && (
          <ChangeStatusDialogBroker
            id={this.state.showStatusDialogBroker}
            history={this.props.history}
            onClose={() => this.setState({ showStatusDialogBroker: false })}
            onSuccess={(item) => {}}
          />
        )}

        {this.state.showStatusDialogCar && (
          <ChangeStatusDialogCar
            id={this.state.showStatusDialogCar}
            history={this.props.history}
            onClose={() => this.setState({ showStatusDialogCar: false })}
            onSuccess={(item) => {}}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </Main>
    );
  }
}

export default Payments;
