import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { checkBoxChart, gridContainer } from "../../styles/main";
import { useDispatch, useSelector } from "react-redux";
import {
  addChartCheck,
  deleteChartCheck,
} from "../../Store/slice/getErrorsParams";
import { dateFormatter } from "../../utils/persianDate/dateFormatter";
import ModalError from "./ModalError";
const GridContainer = ({ error }) => {
  const [openModal, setOpenModal] = useState(false);
  const grid = gridContainer();
  const chartCheckBox = checkBoxChart();
  const theme = useTheme();
  const isMatchesMedaLg = useMediaQuery(theme.breakpoints.down("xs"));
  const isMatchesLapTopBpMax = useMediaQuery("(max-width: 1100px)");
  const isMatchesLapTopBpMin = useMediaQuery("(min-width: 960px)");
  const isMatchesLapTopGrid = useMediaQuery("(max-width: 1443px)");
  const isMathcesbp = useMediaQuery("(max-width: 308px)");
  const isMachesbpMobileUp = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const { chartCheckBox: chartCheckBoxMain } = useSelector(
    (store) => store.getErrorsParams
  );
  const [checked, setChecked] = useState(() =>
    chartCheckBoxMain?.some((title) => title === error._id)
  );

  const handleOnChange = () => {
    if (!chartCheckBoxMain?.some((title) => title === error._id)) {
      dispatch(addChartCheck(error._id));
    }

    if (chartCheckBoxMain?.some((title) => title === error._id)) {
      dispatch(deleteChartCheck(error._id));
    }
  };

  const handleShowModal = () => {
    if (!chartCheckBoxMain?.some((title) => title === error._id)) {
      dispatch(addChartCheck(error._id));
      setChecked(true);
      setOpenModal((cur) => !cur);
    }

    if (chartCheckBoxMain?.some((title) => title === error._id)) {
      setOpenModal((cur) => !cur);
    }
  };

  return (
    <>
      <Box
        component="div"
        className={`${grid.root} ${isMatchesMedaLg && grid.responsiveLg} ${
          isMatchesLapTopGrid && isMachesbpMobileUp ? grid.gridLaptop : ""
        } ${isMachesbpMobileUp ? grid.titleChart : ""}`}
      >
        <FormControl
          className={`${chartCheckBox.root} ${
            isMatchesMedaLg && grid.responsiveFirstChild
          }`}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={chartCheckBoxMain?.some(
                  (title) => title === error._id
                )}
                onChange={handleOnChange}
                id={error._id}
              />
            }
            label={error?.title}
            title={error?.title}
            onClick={() => isMatchesMedaLg && setOpenModal((cur) => !cur)}
            className={`${
              isMatchesLapTopBpMax &&
              isMatchesLapTopBpMin &&
              chartCheckBox.responsiveLapTop
            }`}
          />
        </FormControl>
        <Box
          component="time"
          className={` ${
            isMatchesLapTopBpMax &&
            isMatchesLapTopBpMin &&
            grid.timeFontSizeLaptop
          } ${isMatchesMedaLg && grid.responsiveDateTime} ${
            isMathcesbp && grid.paddingRightDateTime
          }`}
        >
          {isMatchesMedaLg &&
            `${dateFormatter(error.createdAt, false).shortMonth()} - `}
          {dateFormatter(error.createdAt, true).clock()}
        </Box>
        {!isMatchesMedaLg && (
          <Button
            onClick={() => handleShowModal()}
            className={`${
              isMatchesLapTopBpMax &&
              isMatchesLapTopBpMin &&
              grid.timeFontSizeLaptop
            }`}
          >
            مشاهده
          </Button>
        )}
        <Typography
          variant="body2"
          className={`${
            isMatchesLapTopBpMax &&
            isMatchesLapTopBpMin &&
            grid.timeFontSizeLaptop
          } ${isMatchesMedaLg && grid.fontSizeSituation} ${grid.situation} ${
            isMatchesLapTopGrid ? grid.situationGrid : ""
          } ${isMatchesMedaLg ? grid.situationMobile : ""}`}
        >
          {error.criteriaLevel}{" "}
          <span
            className={`${grid.bullet} ${
              error.criteriaLevel === "normal"
                ? grid.bulletYellow
                : error.criteriaLevel === "ultimate"
                ? grid.bulletRed
                : error.criteriaLevel === "necessary"
                ? grid.bulletPink
                : ""
            }`}
          ></span>
        </Typography>
      </Box>
      <ModalError
        onSetOpen={setOpenModal}
        openModal={openModal}
        label={error.title}
        date={dateFormatter(error.createdAt, true).longMonth()}
        time={dateFormatter(error.createdAt, true).clock()}
        situation={error.criteriaLevel}
        content={error.content}
        onchecked={setChecked}
        id={error._id}
      />
    </>
  );
};

export default GridContainer;
