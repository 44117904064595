import React, { useState } from "react";
import {
  useMediaQuery,
  useTheme,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import AlertDialog from "./CitiesDialogAddEdit";
import ArchiveDialog from "./CitiesDialogArchive";
import ArchiveIcon from "../../packages/icons/ArchiveIocn";
import UnArchiveIcon from "../../packages/icons/UnArchiveIcon";
import {
  useGetCitiesQuery,
  useGetArchivedCitiesQuery,
} from "../../Store/callApi/MarketingInformation";
import useStyles from "../../styles/citiesList";
import styles from "./InfoRequests.module.css";
const CitiesList = () => {
  const classes = useStyles();
  const { data } = useGetCitiesQuery();
  const { data: cities } = useGetArchivedCitiesQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery("(max-width: 500px)");
  const closeModal = () => setIsModalOpen(false);
  const closeArchiveModal = () => setIsArchiveModalOpen(false);

  const openEditModal = (city) => {
    setIsModalOpen(true);
    setSelectedCity(city);
  };

  const openArchiveModal = (city) => {
    setIsArchiveModalOpen(true);
    setSelectedCity(city);
  };

  return (
    <Grid container spacing={2} className={styles.listContainer}>
      <div className={styles.listItemContainer}>
        <Grid item className={styles.container}>
          <div className={styles.containerTopbar}>
            <span className={styles.title}>
              <span className={styles.circle}></span>
              <span className={styles.containerTitle}>شهرهای فعال</span>
            </span>
            <span className={styles.cityNum}>{data?.cities.length} شهر</span>
          </div>
          <List className={classes.root}>
            {data?.cities.map((city, index) => (
              <React.Fragment key={city._id}>
                <ListItem
                  className={`${styles.listItem} ${
                    isTablet && classes.listItemTablet
                  }`}
                >
                  <div className={styles.listItemTextContainer}>
                    <div className={styles.iconTextContainer}>
                      <div
                        className={classes.archiveIcon}
                        onClick={() => openArchiveModal(city)}
                      >
                        <ArchiveIcon />
                        <ListItemText
                          className={`${styles.archiveBut} ${
                            isTablet && classes.tabletFont
                          }`}
                        >
                          {" "}
                          آرشیو
                        </ListItemText>
                      </div>
                    </div>

                    <ListItemText
                      className={`${styles.editButton} ${
                        isTablet && classes.tabletFont
                      }`}
                      onClick={() => openEditModal(city)}
                    >
                      ویرایش{" "}
                    </ListItemText>
                    <ListItemText
                      secondary={` ${city.visited} ${
                        isSmallScreen ? "تعداد" : ":تعداد درخواست ها"
                      }  `}
                      className={`${styles.listItemText} ${
                        isTablet && classes.tabletFont
                      }`}
                    />
                    <ListItemText
                      primary={city.name}
                      className={`${styles.listItemText} ${styles.cityName} ${
                        isTablet && classes.tabletFont
                      }`}
                    />
                  </div>
                </ListItem>
                {index < data?.cities.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </div>
      <div className={styles.listItemContainer}>
        <Grid item className={styles.container}>
          <div className={styles.containerTopbar}>
            <span className={styles.title}>
              <span className={styles.circleArchive}></span>
              <span className={styles.containerTitleArchive}>
                شهرهای آرشیو شده
              </span>
            </span>
            <span className={styles.cityNum}>{cities?.cities.length} شهر</span>
          </div>
          <List className={classes.root}>
            {cities?.cities.map((city, index) => (
              <React.Fragment key={city._id}>
                <ListItem
                  className={`${styles.listItem} ${
                    isTablet && classes.listItemTablet
                  }`}
                >
                  <div className={styles.listItemTextContainer}>
                    <div className={styles.iconTextContainer}></div>
                    <div
                      className={classes.archiveIcon}
                      onClick={() => openArchiveModal(city)}
                    >
                      <UnArchiveIcon />
                      <ListItemText
                        className={`${styles.unArchiveBut} ${
                          isTablet && classes.tabletFont
                        }`}
                      >
                        {" "}
                        فعال کردن
                      </ListItemText>
                    </div>

                    <ListItemText
                      className={`${styles.editButton} ${
                        isTablet && classes.tabletFont
                      }`}
                      onClick={() => openEditModal(city)}
                    >
                      ویرایش{" "}
                    </ListItemText>
                    <ListItemText
                      secondary={` ${city.visited} ${
                        isSmallScreen ? " : تعداد" : ":تعداد درخواست ها"
                      }  `}
                      className={`${styles.listItemText} ${
                        isTablet && classes.tabletFont
                      }`}
                    />
                    <ListItemText
                      primary={city.name}
                      className={`${styles.listItemText} ${styles.cityName} ${
                        isTablet && classes.tabletFont
                      }`}
                    />
                  </div>
                </ListItem>
                {index < cities?.cities.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </div>
      {selectedCity && (
        <>
          <AlertDialog
            isNew={false}
            isOpen={isModalOpen}
            onClose={closeModal}
            title="ویرایش نام شهر"
            cityName={selectedCity.name}
            cityId={selectedCity._id}
          />
          <ArchiveDialog
            isOpen={isArchiveModalOpen}
            onClose={closeArchiveModal}
            cityName={selectedCity.name}
            cityId={selectedCity._id}
            cityArchive={selectedCity.archive}
          />
        </>
      )}
    </Grid>
  );
};

export default CitiesList;
