import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Accordion from "../../../features/errorPage/AccordionFilter";
import { useStyles } from "../../../styles/drawer";
import { useDispatch } from "react-redux";
import { criteriaLevel, topics } from "../../../values/errorPage/values";

const DrawerListItems = ({ onClose, deleteAll }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMatchesMobileBp = useMediaQuery("(max-width: 309px)");

  return (
    <Box component="div" className={classes.list}>
      <Box className={classes.header}>
        <Typography variant="h6" component="h6">
          فیلترها
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box
        component="div"
        className={`${classes.main} ${
          isMatchesMobileBp ? classes.mainMobile : ""
        }`}
      >
        <Box component="div" className={classes.btn}>
          <Button variant="text" onClick={() => dispatch(deleteAll())}>
            حذف فیلترها
          </Button>
        </Box>
        <Accordion topics={topics} criterialLevel={criteriaLevel} />
      </Box>
    </Box>
  );
};

export default DrawerListItems;
