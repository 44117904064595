import React, { useState, useEffect } from "react";
import DatePickerCom from "../../components/common/DatePicker/DatePicker";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0",
  },
});

const DayFilter = ({ title, handlerDay }) => {
  const classes = useStyles();
  const [fromDay, setFromDay] = useState("");
  const [toDay, setToDay] = useState("");

  useEffect(() => {
    const from_day = fromDay.split("T")[0];
    const to_day = toDay.split("T")[0];
    handlerDay(to_day, from_day);
  }, [fromDay, toDay]);

  return (
    <>
      <h4>{title}</h4>
      <Box className={classes.root}>
        <h4>جستجو از تاریخ</h4>
        <DatePickerCom onchange={(e) => setFromDay(e.value.toISOString())} />
        <h4>تا</h4>
        <DatePickerCom onchange={(e) => setToDay(e.value.toISOString())} />
      </Box>
    </>
  );
};

export default DayFilter;
