import React from "react";
import Radio from "@material-ui/core/Radio";
import { Tooltip } from "@material-ui/core";
import styles from "./exclusiveColorTag.module.css";

const ExclusiveColorTag = ({ infoColor, isActiveColor, handleColorChange }) => {
  const { color, taken } = infoColor;

  const handleChange = () => {
    if (!taken) {
      handleColorChange(color);
    }
  };
  return (
    <Tooltip title={taken ? "این رنگ قبلا استفاده شده" : ""}>
      <Radio
        className={taken && styles.isTekenStyle}
        style={{ color: taken ? "" : color }}
        checked={isActiveColor === color}
        onChange={handleChange}
        value={color}
        name="radio-button-demo"
        inputProps={{ "aria-label": color }}
      />
    </Tooltip>
  );
};

export default ExclusiveColorTag;
