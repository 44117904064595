import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  list: {
    width: "100%",
    maxWidth: "550px",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingInline: "14.31px",
    marginBottom: "16px",
    "& .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "700",
      color: "#3F3F46",
    },
  },

  main: {
    paddingInline: "16px",
  },

  mainMobile: {
    paddingInline: "1px",
  },

  btn: {
    display: "flex",
    paddingBlock: "12px",
    "& .MuiButtonBase-root": {
      marginLeft: "auto",
      color: "#E65100",
      "&:hover": {
        background: "#fff",
      },
    },
  },

  fullList: {
    width: "auto",
  },
});

export const useStyles2 = makeStyles({
  list: {
    width: "100%",
    maxWidth: "380",
  },
  fullList: {
    width: "auto",
  },

  drawer: {
    "& .MuiDrawer-paper": {
      width: "100%",
      maxWidth: "380px",
    },
  },
});

// .newerrors_normal {
//   background-color: #ff9800;
// }

// .newerrors_ultimate {
//   background-color: #ef5350;
// }

// .newerrors_necessary {
//   background-color: violet;
// }

export const useStyles3 = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordion-root:last-child .MuiFormGroup-root:first-child": {
      "& .MuiFormControlLabel-label": {
        paddingLeft: "7px",
        position: "relative",
        "&::before": {
          content: '""',
          display: "block",
          height: "8px",
          width: "8px",
          backgroundColor: "#ff9800",
          borderRadius: "9999px",
          position: "absolute",
          transform: "translate(-160%, -70%)",
          left: "0",
          top: "49%",
        },
      },
    },
    "& .MuiAccordion-root:last-child .MuiFormGroup-root:nth-child(2)": {
      "& .MuiFormControlLabel-label": {
        paddingLeft: "7px",
        position: "relative",
        "&::before": {
          content: '""',
          display: "block",
          height: "8px",
          width: "8px",
          backgroundColor: "violet",
          borderRadius: "9999px",
          position: "absolute",
          transform: "translate(-160%, -70%)",
          left: "0",
          top: "49%",
        },
      },
    },

    "& .MuiAccordion-root:last-child .MuiFormGroup-root:last-child": {
      "& .MuiFormControlLabel-label": {
        paddingLeft: "7px",
        position: "relative",
        "&::before": {
          content: '""',
          display: "block",
          height: "8px",
          width: "8px",
          backgroundColor: "#ef5350",
          borderRadius: "9999px",
          position: "absolute",
          transform: "translate(-160%, -70%)",
          left: "0",
          top: "49%",
        },
      },
    },
    "& .MuiAccordion-root": {
      borderRadius: "unset !important",
      borderBottom: "1px solid #0000001F",
      paddingBottom: "10px",
    },
  },
  heading: {
    fontSize: "13.7px",
    flexBasis: "33.33%",
    flexShrink: 0,
    color: "#000000DE",
    "& span": {
      content: "",
      display: "inline-block",
      width: "8px",
      height: "8px",
      borderRadius: "9999px",
      backgroundColor: "#9C27B0",
      marginLeft: "7px",
    },
  },

  checkboxContainer: {
    width: "100%",
    "& .MuiFormControlLabel-root": {
      justifyContent: "space-between",
    },
  },
}));
