import React, { memo, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import { values } from "../../values/newRequest/values";
import Close from "../../packages/icons/Close";

const KnowCompanySelect = memo(
  ({
    disabledFields,
    knowCompany,
    knowCompanyValue,
    handleChange,
    handleSetFieldManually,
  }) => {
    const [openKnow, setOpenKnow] = useState(false);
    const input = useRef();
    const cacheRtl = createCache({
      key: "muirtl",
      stylisPlugins: [prefixer, stylisRTLPlugin],
    });
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <CacheProvider value={cacheRtl}>
          <FormControl
            sx={{
              m: 1,
              minWidth: 120,
              maxWidth: 508,
              "& > *": {
                transition: "unset",
                animation: "unset",
                animationDelay: "unset",
              },
            }}
            disabled={!disabledFields}
          >
            <InputLabel
              id="user_request_know_company"
              sx={{ "&": { fontSize: { xs: 13, sm: 16 } } }}
            >
              طریقه آشنایی با شرکت
            </InputLabel>
            <Select
              labelId="user_request_know_company"
              id="knowCompany"
              name="knowCompany"
              open={openKnow}
              onOpen={() => setOpenKnow(true)}
              onClose={() => setOpenKnow(false)}
              value={knowCompany}
              label="طریقه آشنایی با شرکت"
              onChange={handleChange}
              sx={{
                "& #knowCompany": {
                  animation: "unset !important",
                  animationDuration: "unset !important",
                },
              }}
            >
              {values?.map((item, index) => (
                <MenuItem
                  sx={{ animation: "unset", transition: "unset" }}
                  key={index}
                  value={item}
                  disableRipple
                  disableTouchRipple
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CacheProvider>
        {knowCompany === "موارد دیگر" && (
          <CacheProvider value={cacheRtl}>
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,

                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0000001F",
                },
              }}
              variant="outlined"
            >
              <InputLabel
                htmlFor="knowCompanyValuee"
                sx={{ fontSize: { xs: 13, sm: 16 } }}
              >
                طریقه آشنایی
              </InputLabel>
              <OutlinedInput
                name="knowCompanyValue"
                type="text"
                inputRef={input}
                value={knowCompanyValue}
                onChange={handleChange}
                sx={{ fontSize: { xs: 13, sm: 16 } }}
                endAdornment={
                  <InputAdornment
                    onClick={() => {
                      handleSetFieldManually("knowCompanyValue", "");
                      input.current.value = "";
                    }}
                    sx={{ display: { xs: "none", sm: "flex" } }}
                    position="end"
                  >
                    <IconButton>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                }
                label="طریقه آشنایی"
              />
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  color: "text.secondary",
                  fontSize: { xs: 12, sm: 16 },
                  mt: 1,
                }}
              >
                اگر موارد دیگر را انتخاب کردید طریقه آشنایی را در اینجا بنویسید
              </Typography>
            </FormControl>
          </CacheProvider>
        )}
      </Box>
    );
  }
);

export default KnowCompanySelect;
