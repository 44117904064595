import * as yup from "yup";
export const validationFormikInquiryCar = yup.object({
  vehicleViolation: yup.string().required("خلافی خودرو الزامی است"),
  customerPhoneNumber: yup.string().required("شماره تماس الزامی است"),
  freeRoadToll: yup.string().required("عوارض آزادراهی الزامی است"),
  transferTax: yup.string().required("مالیات نقل و انتقال الزامی است"),
  effectsOfTrafficPlan: yup.string().required("عوارض طرح ترافیک الزامی است"),
  annualFees: yup.string().required("عوارض سالیانه الزامی است"),
  governmentCounterOfficeFee: yup
    .string()
    .required("کارمزد دفتر پیشخوان دولت الزامی است"),
  // total: yup.string().required("مجموع مبلغ پرداختی الزامی است"),
  // paidDate: yup.string().required("تاریخ پرداخت الزامی است"),
  // paid: yup.boolean(),
});
