import { Box } from "@mui/material";
import React from "react";
import GridContainer from "./GridContainer";
import { useSelector } from "react-redux";
import { useGetAllMessagesQuery } from "../../Store/callApi/apiContactus";
import LoadingSkeleton from "./LoadingSkeleton";
// import ErrorRequest from "../";
import NoMessage from "./NoMessage";
const ChartContainer = () => {
  const { createdAt, resolved, branch, currentPage } = useSelector(
    (state) => state.contactUs
  );
  const {
    data: messages,
    isSuccess,
    isLoading,
    error,
  } = useGetAllMessagesQuery({
    createdAt,
    resolved,
    branch,
    currentPage,
  });
  return (
    <>
      {isSuccess && messages?.data.length !== 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid #0000001F",
            borderRadius: 2,
            marginTop: 2,
          }}
        >
          {messages?.data?.map((message) => (
            <GridContainer key={message._id} message={message} />
          ))}
        </Box>
      )}
      {isLoading && <LoadingSkeleton />}
      {/* {error && <ErrorRequest />} */}
      {messages?.data?.length === 0 && <NoMessage />}
    </>
  );
};

export default ChartContainer;
