import { useState } from "react";
import { ApiClient, ApiHelper } from "../data/remote";
export const useShutdownDay = () => {
  let apiClient = ApiClient.getInstance();
  const [successRes, setSuccessRes] = useState("");

  let HandlerShutdownDay = (date, messageDay) => {
    apiClient.post(
      ApiHelper.shutdownDay_POST,
      (error, response) => {
        if (error) {
          setSuccessRes(response.data.success);
        } else {
          setSuccessRes(response.success);
        }
      },
      {
        date: date,
        occasion: messageDay,
      }
    );
  };
  return { successRes, HandlerShutdownDay };
};
