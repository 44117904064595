export const permissionsCode = {
  buy_requests: 1,
  sell_request: 2,
  car_description: 3,
  registrationRequest: 4,
  car_priceEdit: 5,
  car_brandEdit: 6,

  //allSections
  cities: 11,
  carCategories: 12,
  coworkers: 13,
  agents: 14,
  experts: 15,
  sellers: 16,
  cars: 17,
  brokers: 18,
  receits: 19,
  requests: 20,
  registerRequests: 21,
  carPrices: 22,
  systemLogs: 23,
  contracts: 24,
  brands: 25,
  backups: 26,
  errors: 27,
  timeManagement: 28,
};

export const carAuctionTypeByCode = {
  1: "کارشناسی",
  2: "مزایده",
  3: "دپو",
  4: "عمومی",
  5: "مذاکره",
  6: "تسویه نشده",
  7: "پرداخت شده",
  8: "تمام شده",
};
