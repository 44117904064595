import {
  Accordion,
  AccordionSummary,
  Box,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import StatusMobile from "../../components/new-request-mutual-components/StatusMobile";
import SortFilterMobile from "../../components/new-request-mutual-components/SortFilterMobile";

const AccordionFilterMobile = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: 2,
        "&> *:not(:last-child)": {
          marginBottom: "16px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: 1,
          borderBottom: "1px solid #0000001F",
          paddingBottom: "16px",
        }}
      >
        <Accordion sx={{ boxShadow: "unset", width: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{
              color: "#000000DE",
              fontSize: 14,
              paddingRight: "unset",
              paddingLeft: 1,
              minHeight: "unset",
              margin: "unset",
              "&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "unset",
                marginBottom: "13px",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "unset",
              },
              "& .MuiAccordionSummary-content": {
                margin: "unset",
              },
            }}
          >
            وضعیت درخواست ها
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "unset" }}>
            <StatusMobile />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            sm: "none",
            borderBottom: "1px solid #0000001F",
            paddingBottom: "10px",
          },
          width: 1,
        }}
      >
        <Accordion sx={{ boxShadow: "unset", width: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel1-header"
            sx={{
              color: "#000000DE",
              fontSize: 14,
              paddingRight: "unset",
              paddingLeft: 1,
              minHeight: "unset",
              margin: "unset",
              "&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "unset",
                marginBottom: "13px",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "unset",
              },
              "& .MuiAccordionSummary-content": {
                margin: "unset",
              },
            }}
          >
            ترتیب درخواست ها
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "unset" }}>
            <SortFilterMobile />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default AccordionFilterMobile;
