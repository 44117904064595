import { Divider, Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import useGetAgents from "../../../Hooks/Agents/useGetAgents";
import SelectForm from "../../../components/common/Form/Select/Select";
import { ApiClient, ApiHelper } from "../../../data/remote";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackBar from "../../../components/common/snackBar/snackBar";
import styles from "./agentRequests.module.css";
import AlertDialog from "./../../../components/common/AlertDialog";
import { Statement } from "../../../values/Statement";

let apiClient = null;

const SetAgentForRequestsModule = () => {
  const [agents, agentloading] = useGetAgents("accepted");
  const [agentID, setAgentID] = useState(null);
  const [reqNum, setReqNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setmessage] = useState("");
  apiClient = ApiClient.getInstance();
  const assignRequestsToAgents = () => {
    let data = {};
    data.batchSize = reqNum;
    data.agentID = agentID;
    setLoading(true);

    apiClient.post(
      ApiHelper.assignRequestsToAgents,
      (error, response) => {
        if (error) {
          setLoading(false);
        } else {
          setLoading(false);
          setmessage("عملیات با موفقیت انجام شد");
          setInterval(() => setmessage(""), 3000);
        }
      },
      data
    );
  };

  const handlerReferral = () => {
    if (reqNum)
      return (
        <h4
          className={styles.messageReference}
        >{`تعداد ${reqNum} در خواست آماده ارجاع هستند `}</h4>
      );
    return;
  };

  return (
    <>
      <Paper elevation={3} className={styles.stylesPeper}>
        <Grid className={styles.titleReferral}>
          بخش ارجاع درخواست های مجهول
        </Grid>
        <Divider />
        <Grid container className={styles.itemAgentStyle}>
          <Grid item lg={2} m={2}>
            <h4>انتخاب شعبه مربوطه:</h4>
            {agents.map((agent) => {
              return (
                <Button
                  className={styles.btnAgentStyle}
                  variant={agentID === agent._id ? "contained" : "outlined"}
                  color="secondary"
                  onClick={() => setAgentID(agent._id)}
                >
                  {agent.name}
                </Button>
              );
            })}
          </Grid>
          <Grid item lg={8}>
            <Grid className={styles.selectReference}>
              {Statement.referenceMessage}
            </Grid>
            <Grid>
              <SelectForm
                options={[
                  { key: 1, option: "1" },
                  { key: 5, option: "5" },
                  { key: 10, option: "10" },
                  { key: 15, option: "15" },
                  { key: 20, option: "20" },
                  { key: 25, option: "25" },
                  { key: 50, option: "50" },
                ]}
                value={reqNum}
                onchange={(value) => setReqNum(value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={styles.btnAndMessageStyle} mt={2} container>
          <Grid item lg={10}>
            {handlerReferral()}
          </Grid>
          <Grid className={styles.btnSendStyle} item lg={2}>
            {!loading ? (
              <Button
                variant={"contained"}
                color="secondary"
                onClick={() => assignRequestsToAgents()}
              >
                ثبت
              </Button>
            ) : (
              <CircularProgress disableShrink />
            )}
          </Grid>
        </Grid>
        <SnackBar
          variant={"success"}
          message={message}
          open={message.length}
          onClose={() => setmessage("")}
        />
      </Paper>
    </>
  );
};

export default SetAgentForRequestsModule;
