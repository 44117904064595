import { makeStyles } from "@material-ui/core/styles";

const useStylesMui = makeStyles({
  root: {
    background: "white",
    color: "red",
    "&:hover": {
      backgroundColor: "red",
      color: "white",
    },
  },
  outlinedPrimary: {
    border: "1px solid red",
    "&:hover": {
      border: "1px solid red",
    },
  },
  yellowTheme: {
    background: "white",
    color: "orange !important",
    "&:hover": {
      backgroundColor: "yellow",
      color: "white",
    },
    "&material-icons": {
      color: "orange",
    },
    "& span": {
      "& span": {
        color: "orange",
      },
    },
  },
  greenLightTheme: {
    background: "white",
    color: "#bfd18c !important",
    "&:hover": {
      backgroundColor: "#b5b690",
      color: "white",
    },
    "&material-icons": {
      color: "#bfd18c",
    },
    "& span": {
      "& span": {
        color: "#bfd18c",
      },
    },
  },
  yellowOutlinedPrimary: {
    border: "1px solid orange",
    "&:hover": {
      border: "1px solid orange",
    },
  },
  greenLightOutlinedPrimary: {
    border: "1px solid #bfd18c",
    "&:hover": {
      border: "1px solid #bfd18c",
    },
  },
  mindoraTheme: {
    background: "white",
    color: "#a4f100!important",
    "&:hover": {
      backgroundColor: "#E3F09B",
      color: "white",
    },
    "&material-icons": {
      color: "#a4f100",
    },
    "& span": {
      "& span": {
        color: "#a4f100",
      },
    },
  },
  mindoraPrimary: {
    border: "1px solid #E3F09B",
    "&:hover": {
      border: "1px solid #E3F09B",
    },
  },
  paleDogTheme: {
    background: "white",
    color: "#E8CCBF!important",
    "&:hover": {
      backgroundColor: "#a36f57",
      color: "white",
    },
    "&material-icons": {
      color: "#E8CCBF",
    },
    "& span": {
      "& span": {
        color: "#E8CCBF",
      },
    },
  },
  paleDogPrimary: {
    border: "1px solid #E8CCBF",
    "&:hover": {
      border: "1px solid #E8CCBF",
    },
  },
  dustTheme: {
    background: "white",
    color: "#DDD1C7 !important",
    "&:hover": {
      backgroundColor: "#DDD1C7",
      color: "white",
    },
    "&material-icons": {
      color: "#DDD1C7",
    },
    "& span": {
      "& span": {
        color: "#DDD1C7",
      },
    },
  },
  dustPrimary: {
    border: "1px solid #DDD1C7",
    "&:hover": {
      border: "1px solid #DDD1C7",
    },
  },
  green: {
    background: "white",
    color: "#34d41c !important",
    "&:hover": {
      backgroundColor: "#34d41c",
      color: "white",
    },
    "&material-icons": {
      color: "#34d41c",
    },
    "& span": {
      "& span": {
        color: "#34d41c",
      },
    },
  },
  redTheme: {
    background: "white",
    color: "#cb1111 !important",
    "&:hover": {
      backgroundColor: "#cb1111",
      color: "white !important",
    },
    "&material-icons": {
      color: "#cb1111",
    },
    "& span": {
      "& span": {
        color: "#cb1111",
      },
    },
  },
});

export default useStylesMui;
