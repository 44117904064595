import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import Watch from "../../packages/icons/Watch";
import Calendar from "../../packages/icons/Calendar";
import { useDispatch, useSelector } from "react-redux";
import {
  setCreatedAt,
  setIsRequestDateMobile,
  setReservedAt,
} from "../../Store/slice/sliceNewRequest";
const FilterDateRadio = () => {
  const minWidth1200 = useMediaQuery("(min-width: 1200px)");
  const dispatch = useDispatch();
  const { isRequestDateMobile } = useSelector((state) => state.newRequest);
  const handleChane = (e) => {
    console.log(e.target.value);
    dispatch(setIsRequestDateMobile(e.target.value));
    if (e.target.value === "submit") {
      dispatch(setCreatedAt());
    }
    if (e.target.value === "reserve") {
      dispatch(setReservedAt());
    }
  };

  useEffect(() => {
    if (isRequestDateMobile === "submit" && !minWidth1200) {
      dispatch(setCreatedAt());
    }

    if (isRequestDateMobile === "reserve" && !minWidth1200) {
      dispatch(setReservedAt());
    }
  }, []);

  return (
    <FormControl sx={{ display: { xs: "none", sm: "block" } }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={isRequestDateMobile}
        onChange={handleChane}
      >
        <FormControlLabel
          value="submit"
          control={
            <Radio
              sx={{
                "&.Mui-checked + .MuiTypography-root .MuiBox-root .MuiBox-root svg path":
                  {
                    fill: "#2196F3",
                  },
                "&.Mui-checked + .MuiTypography-root div .MuiTypography-root": {
                  color: "#000000DE !important",
                },
              }}
            />
          }
          label={
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box component="span" sx={{ display: "flex" }}>
                <Calendar />
              </Box>
              <Typography
                sx={{ fontSize: 14, color: "text.secondary" }}
                variant="caption"
              >
                تاریخ ثبت درخواست
              </Typography>
            </Box>
          }
          sx={{
            "& .MuiTypography-root .MuiBox-root .MuiBox-root svg path": {
              fill: "#00000099",
            },
          }}
        />
        <FormControlLabel
          value="reserve"
          control={
            <Radio
              sx={{
                "&.Mui-checked + .MuiTypography-root .MuiBox-root .MuiBox-root svg path":
                  {
                    stroke: "#2196F3",
                  },
                "&.Mui-checked + .MuiTypography-root div .MuiTypography-root": {
                  color: "#000000DE !important",
                },
              }}
            />
          }
          label={
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box component="span" sx={{ display: "flex" }}>
                <Watch />
              </Box>
              <Typography
                sx={{ fontSize: 14, color: "text.secondary" }}
                variant="caption"
              >
                تاریخ رزرو
              </Typography>
            </Box>
          }
          sx={{
            "& .MuiTypography-root .MuiBox-root .MuiBox-root svg path": {
              stroke: "#00000099",
            },
          }}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default FilterDateRadio;
