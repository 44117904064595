import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useStyles } from "./stylesReporting";
import { ApiClient, ApiHelper } from "../../../../data/remote";
import LinearProgress from "@material-ui/core/LinearProgress";

const ListTypesContracts = ({ contractName, contracts, contractKind }) => {
  const classes = useStyles();
  const apiClient = ApiClient.getInstance();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlerMakePdf = () => {
    contracts = { ...contracts, contractKind };
    setLoading(true);
    apiClient.post(
      ApiHelper.getContract,
      (error, response) => {
        if (error) {
        } else {
          setPdfUrl(response.pdfurl);
          setLoading(false);
        }
      },
      contracts
    );
  };

  return (
    <div>
      {!pdfUrl ? (
        <Button
          disabled={loading}
          onClick={handlerMakePdf}
          className={classes.btnStyleContracts}
          variant="contained"
          color="primary"
          size="small"
        >
          {contractName}
        </Button>
      ) : (
        <Button
          className={classes.btnStyleContracts}
          variant="contained"
          size="small"
          color="primary"
        >
          <a href={pdfUrl}>دانلود قرار داد</a>
        </Button>
      )}
      {loading && <LinearProgress className={classes.loadingStyle} />}
    </div>
  );
};
export default ListTypesContracts;
