import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { validationFormikAdmins } from "../../../values/validation_formik/admins_config_value/validationFormikAdmins";
import textFieldsConfig from "../../../values/validation_formik/admins_config_value/textFieldsConfig";
import {
  useCreateAdminMutation,
  useUpdateAdminMutation,
} from "../../../Store/callApi/AdminDataService";
import Main from "../../common/UserMain";
import { useFormik } from "formik";
import styles from "./admins.module.css";
import TextFieldFormik from "../../common/textField/textFieldFormik";
import SubPermissions from "../../common/subPermission/subPermissions";
import SnackBar from "../../common/SnackBar";
import { Statement } from "../../../values/Statement";

import { Button, Box, Grid, Paper, Divider } from "@material-ui/core";

const AddEditAdmin = () => {
  const history = useHistory();
  const { state: infoAdmin, adminStatus } = useLocation();
  const isAdminID = infoAdmin?.id ?? false;
  const [createAdmin] = useCreateAdminMutation();
  const [updateAdmin] = useUpdateAdminMutation();
  const [listSubPermission, setListSubPermission] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState({
    isSuccess: false,
    isUnSuccess: false,
    message: "",
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      phone: "",
      officePhone: "",
      password: "",
      permissionCodes: [],
    },

    validationSchema: validationFormikAdmins,
    onSubmit: async (values) => {
      try {
        let res;
        if (isAdminID) {
          res = await updateAdmin({ adminID: isAdminID, body: values });
        } else {
          res = await createAdmin(values);
        }
        if (res.data.success) {
          setShowSnackBar((prev) => ({
            ...prev,
            isSuccess: true,
            message: res.data.message,
          }));
          formik.resetForm();
          history.push({
            pathname: adminStatus,
            editSuccess: "ادمین با موفقیت ویرایش شد.",
          });
        } else {
          setShowSnackBar((prev) => ({
            ...prev,
            isUnSuccess: true,
            message: res.data.message || Statement.adminUnSuccess,
          }));
        }
      } catch (error) {
        setShowSnackBar((prev) => ({
          ...prev,
          isUnSuccess: true,
          message: Statement.adminUnSuccess,
        }));
      }
    },
  });

  useEffect(() => {
    if (isAdminID) {
      const listPermissionCode = infoAdmin.subPermission.map(
        (e) => e.permissionCode
      );
      setListSubPermission(listPermissionCode);
      formik.setValues({
        ...infoAdmin,
      });
    }
  }, [infoAdmin]);

  const handlerSubPermission = (premCode) => {
    setListSubPermission(premCode);
  };

  useEffect(() => {
    formik.setFieldValue("permissionCodes", listSubPermission);
  }, [listSubPermission]);
  return (
    <>
      <Main page={"/add_edit_admin"} pageName={"ادمین ها"}>
        <Paper className={styles.addEditAdminBox}>
          <form onSubmit={formik.handleSubmit}>
            <Grid className={styles.textFieldStyle} container spacing={2}>
              {textFieldsConfig.map((field, index) => (
                <Grid item xs={4} key={index}>
                  <TextFieldFormik field={field} formik={formik} />
                </Grid>
              ))}
            </Grid>
            <Divider />
            <SubPermissions
              numberPermissionCodes={listSubPermission}
              idEdit={isAdminID}
              handlerSubPermission={handlerSubPermission}
            />
            <Box mt={2}>
              <Button
                disabled={!formik.isValid}
                style={{ margin: "8px" }}
                type="submit"
                color={"primary"}
                variant="contained"
              >
                ثبت
              </Button>
              <Button
                variant="outlined"
                onClick={() => history.push("/admins/accepted")}
                color="secondary"
              >
                بازگشت
              </Button>
            </Box>
          </form>
        </Paper>
      </Main>
      <SnackBar
        variant={showSnackBar.isSuccess ? "success" : "error"}
        message={showSnackBar.message}
        open={showSnackBar.isSuccess || showSnackBar.isUnSuccess}
        onClose={() => setShowSnackBar(false)}
      />
    </>
  );
};

export default AddEditAdmin;
