import React, { useState } from "react";
import { Grid, Divider, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { observer } from "mobx-react";
import Main from "../../common/UserMain";
import InfoTable from "./infoTable";
import SearchBox from "./searchBox";
import { Statement } from "../../../values/Statement";
import photoNotRequest from "../../../../src/Images/photoNotRequest.png";
import style from "./timeManagement.module.css";

const TimeManagement = observer((props) => {
  const [state, setState] = useState({
    data: [],
    showMessageOrTable: false,
    message: Statement.NoDaySelected,
    showImage: false,
  });

  const addNew = () => {
    props.history.push("/add_sell_request");
  };

  const deleteRequest = (dataTable) => {
    setState((prev) => ({ ...prev, data: dataTable }));
  };

  const handlerTableAndText = () => {
    if (state.data.length > 0) {
      return <InfoTable deleteRequest={deleteRequest} data={state.data} />;
    } else {
      return (
        <>
          <h3 className={style.optionalText}>{state.message}</h3>
          {state.showImage && (
            <div className={style.BoxImage}>
              <img
                className={style.MainImage}
                src={photoNotRequest}
                alt="Not Found"
              />
            </div>
          )}
        </>
      );
    }
  };

  return (
    <Main
      page={"req_management"}
      pageName="مدیریت درخواست ها"
      transparentBar={false}
      leftAppBarComponent={
        <Fab
          onClick={addNew}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          ایجاد
          <AddIcon />
        </Fab>
      }
    >
      <Grid item sm={12}>
        <SearchBox
          date={props.location.date}
          state={state}
          setState={setState}
        />
        <Divider className={style.dividerTable} />
      </Grid>
      <Grid item sm={12} className={style.info_table_container}>
        {handlerTableAndText()}
      </Grid>
    </Main>
  );
});

export default TimeManagement;
