import React, { useState } from "react";
import { Box, Button, Typography, Divider } from "@material-ui/core";
import LocalTaxiOutlinedIcon from "@material-ui/icons/LocalTaxiOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useStyles } from "./stylesReporting";
import ContractDialoge from "./contractDialoge";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { contractsValueType } from "../../../../values/contracts/contractsValueType";
import ListTypesContracts from "./listTypesContracts";

const ListContracts = ({ contracts }) => {
  const classes = useStyles();
  const [showDialog, setshowDialog] = useState(false);

  const {
    receptionCode,
    sellerName,
    sellerLastName,
    carTreim,
    carKind,
    carBrand,
    buyerName,
    buyerLastName,
    createdAt,
    updatedAt,
  } = contracts;

  const handlerDialog = (isShow) => {
    setshowDialog(isShow);
  };

  return (
    <>
      <div className={classes.itemReporting}>
        <div className={classes.infoContractStyle}>
          <Box className={classes.titleStyleCar}>
            <h3> قرار داد با کد پذیرش </h3>
            <h3> {receptionCode} </h3>
          </Box>
          <Divider />
          <Box>
            <Box className={classes.infoCarStyle}>
              <div className={classes.titleStyleCar}>
                <LocalTaxiOutlinedIcon />
                <p className={classes.textStyleCar}>خودرو</p>
              </div>
              {carBrand || carKind || carTreim ? (
                <p className={classes.textStyleCar}>
                  {" "}
                  {(carBrand, carKind, carTreim)}
                </p>
              ) : (
                <p className={classes.textStyleCar}>وارد نشده</p>
              )}
            </Box>
            <Box className={classes.infoCarStyle}>
              <div className={classes.titleStyleCar}>
                <KeyboardBackspaceIcon />
                <p className={classes.textStyleCar}>فروشنده</p>
              </div>
              {buyerName || buyerLastName ? (
                <p className={classes.textStyleCar}>
                  {" "}
                  {(buyerName, buyerLastName)}
                </p>
              ) : (
                <p className={classes.textStyleCar}>وارد نشده</p>
              )}
            </Box>
            <Box className={classes.infoCarStyle}>
              <div className={classes.titleStyleCar}>
                <ShoppingCartOutlinedIcon />
                <p className={classes.textStyleCar}>خریدار</p>
              </div>
              {sellerName || sellerLastName ? (
                <p className={classes.textStyleCar}>
                  {" "}
                  {(sellerName, sellerLastName)}
                </p>
              ) : (
                <p className={classes.textStyleCar}>وارد نشده</p>
              )}
            </Box>
          </Box>
        </div>
        <div className={classes.btnGruopStyle}>
          <div>
            <Accordion className={classes.accordionStyle}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>قرار داد ها</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionItemStyle}>
                {contractsValueType.map((item, index) => (
                  <ListTypesContracts
                    key={index}
                    contracts={contracts}
                    contractName={item.name}
                    contractKind={item.contractKind}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
            <Button
              disabled
              className={classes.btnStyleContracts}
              variant="outlined"
              style={{ color: "red" }}
              size="small"
            >
              حذف قرارداد
            </Button>
            <Button
              className={classes.btnStyleContracts}
              onClick={() => handlerDialog(true)}
              variant="outlined"
              color="secondary"
              size="small"
            >
              جزئیات
            </Button>
          </div>
        </div>
      </div>
      {showDialog && (
        <ContractDialoge
          createdAt={createdAt}
          updatedAt={updatedAt}
          handlerDialog={handlerDialog}
          showDialog={showDialog}
        />
      )}
    </>
  );
};

export default ListContracts;
