import React, { useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllCoworkersQuery } from "../../Store/callApi/apiCoworkers";
import { useAssignRequestToCoworkerMutation } from "../../Store/callApi/request";
import { emptyRequestIds } from "../../Store/slice/sliceNewRequest";
const SendRequestToCoworker = () => {
  const maxWidth1200 = useMediaQuery("(max-width: 1199px)");
  const requestIDs = useSelector((state) => state?.newRequest?.checkedItems);
  const {
    isSuccess,
    data: coworkersData,
    isLoading,
  } = useGetAllCoworkersQuery({
    status: "accepted",
    type: "coworker",
  });

  const [assignRequestFn] = useAssignRequestToCoworkerMutation();

  const hasAccess = useSelector(
    (state) => state.newRequest?.data?.[0]?.hasAdminAccess
  );

  const dispatch = useDispatch();

  const [openSelect, setOpenSelect] = useState(false);
  const [coworkerId, setCoworkerId] = useState("");
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, stylisRTLPlugin],
  });

  const handleChange = (e) => {
    if (requestIDs.length === 0) return;
    setCoworkerId(e.target.value);
    assignRequestFn({ requestIDs, coworkerId: e.target.value });
    dispatch(emptyRequestIds());
    setTimeout(() => setCoworkerId(""), 3000);
  };

  if (!hasAccess) return null;
  if (maxWidth1200) return null;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontSize: 14, color: "text.secondary" }}>
        {!maxWidth1200 ? "انتقال درخواست به :" : "انتقال"}
      </Typography>
      <CacheProvider value={cacheRtl}>
        <FormControl
          sx={{
            "& > *": {
              transition: "unset",
              animation: "unset",
              animationDelay: "unset",
            },
          }}
        >
          <Select
            disabled={isLoading || requestIDs.length === 0}
            open={openSelect}
            onOpen={() => setOpenSelect(true)}
            onClose={() => setOpenSelect(false)}
            value={coworkerId}
            onChange={handleChange}
            name="branch"
            sx={[
              {
                width: 202,
                borderRadius: 40,
                height: "35px !important",
              },
              maxWidth1200 && {
                width: 1,
                maxWidth: 202,
                minWidth: 130,
              },
            ]}
          >
            {isSuccess &&
              coworkersData?.records.map((coworker) => (
                <MenuItem
                  disableRipple
                  disableTouchRipple
                  key={coworker._id}
                  value={coworker._id}
                >
                  {coworker.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </CacheProvider>
    </Box>
  );
};

export default SendRequestToCoworker;
