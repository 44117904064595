import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import AlertDialog from "./AlertDialog";
import stores from "../../data/stores";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { Colors } from "../../values/Colors";
import { ApiClient, ApiHelper } from "../../data/remote";
import { Badge, CircularProgress } from "@material-ui/core";
import { reaction } from "mobx";
import { permissionsCode } from "../../data/presenter/GetCases";
import "../../styles/user-main.css";

import {
  coworkerListItem,
  adminListItem,
  agentListItem,
  expertListItem,
  sellerListItem,
} from "../../utils/drawersListsItems";
import { useStyles, styles } from "../../styleConfig/usermain";
import panelSectionContainer from "../../utils/panelSectionContainer";
var moment = require("moment-jalaali");
const coworkerListProvider = (coworkerPermissions, coworkerListItem, props) => {
  Object.entries(panelSectionContainer(props.page)).forEach(
    ([section, sectionProvider]) => {
      permissionsCode[`${section}`];
      coworkerPermissions.indexOf(permissionsCode[`${section}`]);
      if (coworkerPermissions.indexOf(permissionsCode[`${section}`]) >= 0) {
        coworkerListItem.push(sectionProvider);
      }
    }
  );
  return coworkerListItem;
};

function Main(props) {
  const { container, showToolbar = true } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [alertDialogShow, setAlertDialogShow] = React.useState(false);
  const [listItem, setListItem] = React.useState(
    getListItem(stores.AccountStore.type)
  );
  const [changeList, setChangeList] = React.useState(false);
  const commonListItem = [
    { text: "امنیت", icon: "lock", page: "password" },
    {
      text: "خروج از حساب",
      icon: "exit_to_app",
      page: "exit",
    },
  ];
  const [logoutRequestState, setLogoutRequestState] = React.useState(false);

  function getListItem(type) {
    switch (type) {
      case "admin":
        return adminListItem(props.page);
      case "agent":
        return agentListItem(props.page);
      case "expert":
        let expertList = expertListItem(props.page);
        if (
          stores.AccountStore.profile.permissions.indexOf(
            permissionsCode.car_description
          ) >= 0
        ) {
          expertList[2].collapsable.push({
            text: "تمام خودروها",
            page: "cars/all/content",
          });
        }
        return expertList;
      case "seller":
        let sellerList = sellerListItem(props.page);

        if (
          stores.AccountStore.profile.permissions.indexOf(
            permissionsCode.buy_requests
          ) >= 0 ||
          stores.AccountStore.profile.permissions.indexOf(
            permissionsCode.sell_request
          ) >= 0
        ) {
          let extraList = {
            text: "درخواست ها",
            icon: "list",
            page: "",
            open: props.page.toString().search("requests") === 0,
            marker:
              stores.AccountStore.notifications.userRequestsSell_reserved +
              stores.AccountStore.notifications.userRequestsBuy_verified,
            collapsable: [
              { text: "درخواست های لغو شده", page: "requests/canceled/sell" },
            ],
          };
          if (
            stores.AccountStore.profile.permissions.indexOf(
              permissionsCode.buy_requests
            ) >= 0
          ) {
            extraList.collapsable.push({
              text: "خرید ایجاد شده",
              page: "requests/created/buy",
              marker:
                stores.AccountStore.notifications.userRequestsBuy_verified,
              marker_2: stores.AccountStore.notifications.userRequestsBuy,
            });
            extraList.collapsable.push({
              text: "خرید انجام شده",
              page: "requests/done/buy",
            });
          }
          if (
            stores.AccountStore.profile.permissions.indexOf(
              permissionsCode.sell_request
            ) >= 0
          ) {
            extraList.collapsable.push({
              text: "فروش ایجاد شده",
              page: "requests/created/sell",
              marker:
                stores.AccountStore.notifications.userRequestsSell_reserved,
              marker_2: stores.AccountStore.notifications.userRequestsSell,
            });
            extraList.collapsable.push({
              text: "فروش انجام شده",
              page: "requests/done/sell",
            });
          }

          sellerList.push(extraList);
        }
        if (
          stores.AccountStore.profile.permissions.indexOf(
            permissionsCode.car_priceEdit
          ) >= 0
        ) {
          let extra_list = {
            text: "خودرو و قیمت",
            icon: "local_car_wash",
            page: "car_price",
            marker: null, //stores.accounterstore "as an example"
          };
          sellerList.push(extra_list);
        }
        if (
          stores.AccountStore.profile.permissions.indexOf(
            permissionsCode.car_brandEdit
          ) >= 0
        ) {
          let extra_list = {
            text: "برند ها",
            icon: "article",
            page: "car_brand",
            marker: null, //stores.accounterstore "as an example"
          };
          sellerList.push(extra_list);
        }
        if (
          stores.AccountStore.profile.permissions.indexOf(
            permissionsCode.registrationRequest
          ) >= 0
        ) {
          let extraList_registrations = {
            text: "درخواست‌های ثبت نام",
            icon: "how_to_reg",
            page: "",
            open: props.page.toString().search("registrations") === 0,
            marker: stores.AccountStore.notifications.regRequests,
            collapsable: [
              {
                text: "ثبت نام - جدید",
                page: "registrations/created",
                marker: stores.AccountStore.notifications.regRequests,
              },
              {
                text: "ثبت نام - انجام شده",
                page: "registrations/done",
              },
              {
                text: "ثبت نام - حذف شده",
                page: "registrations/deleted",
              },
            ],
          };

          sellerList.push(extraList_registrations);
        }

        return sellerList;
      case "coworker":
        let coworkerList = coworkerListItem(props.page);
        coworkerList = coworkerListProvider(
          stores.AccountStore.profile.permissions,
          coworkerList,
          props
        );
        return coworkerList;
    }
    return [];
  }

  reaction(
    () => stores.AccountStore.notificationsRefreshMenuKey,
    (count, reaction) => {
      let l = getListItem(stores.AccountStore.type);
      listItem.forEach((item, index) => {
        if (typeof item.open === "boolean") {
          l[index].open = item.open;
        }
      });
      setListItem(l);
      reaction.dispose();
    }
  );

  function handleDrawerToggle(e, sub = false) {
    if (sub) {
      if (mobileOpen) setMobileOpen(false);
      return;
    }
    setMobileOpen(!mobileOpen);
  }

  function handleCollapseClick(i) {
    listItem[i].open = !listItem[i].open;
    setChangeList(!changeList);
  }

  function handleDialogShow(yesPress) {
    if (yesPress) {
      logoutRequest();
    } else {
      setAlertDialogShow(!alertDialogShow);
    }
  }

  function handleCommonListItemClick(item) {
    switch (item.page) {
      case "exit":
        setAlertDialogShow(!alertDialogShow);
        break;
      default:
        props.context.push("/" + item.page);
    }
  }

  const logoutRequest = () => {
    if (logoutRequestState) return;
    setLogoutRequestState(true);
    let apiClient = ApiClient.getInstance();
    apiClient.get(ApiHelper.logout_GET, (error, response) => {
      setLogoutRequestState(false);
      if (!error) {
        stores.AccountStore.signOut();
        props.context.replace("/login");
        setAlertDialogShow(!alertDialogShow);
      }
    });
  };

  const handlerTextPanel = () => {
    const { type, profile } = stores.AccountStore;
    const isAdmin = type === "admin";
    const isName = profile.name;

    if (isAdmin && !isName) {
      return <p style={styles.textPanelStyle}>مدیریت اصلی</p>;
    } else if (!isAdmin && isName !== "") {
      return <p style={styles.textPanelStyle}>{isName}</p>;
    } else if (isAdmin && isName !== "") {
      return (
        <p style={styles.adminTextStyle}>
          <SubdirectoryArrowLeftIcon />
          {isName}
        </p>
      );
    }
    return null;
  };

  const drawer = (
    <div>
      <div className={classes.drawerHeader}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 5,
          }}
        >
          <div style={styles.drawerPersonalInfoContent}>
            <img
              src={require("../../assets/images/logo_width_text_2_400.png")}
              style={styles.profileImage}
            />
            {handlerTextPanel()}
          </div>
          <div style={styles.drawerPersonalInfoContent}>
            {stores.AccountStore.serverTime === "" ? (
              <CircularProgress
                size={20}
                style={{ margin: 5, color: "#000" }}
              />
            ) : !!stores.AccountStore.serverTime ? (
              <p style={{ margin: 5 }}>
                {moment(stores.AccountStore.serverTime).format(
                  "jYYYY/jM/jD ___ HH:mm"
                )}
              </p>
            ) : null}
          </div>
        </div>

        <IconButton
          className={classes.drawerCloseButton}
          onClick={handleDrawerToggle}
        >
          <Icon style={{ color: "#000" }}>close</Icon>
        </IconButton>
      </div>

      <List>
        {listItem.map((item, index) => {
          if (item.divider) return <Divider key={index + "d"} />;
          if (item.collapsable)
            return (
              <List key={index + "c"}>
                <ListItem onClick={() => handleCollapseClick(index)} button>
                  <Icon className="material-icons-outlined">{item.icon}</Icon>
                  <ListItemText
                    style={{ display: "flex", flex: 1, marginRight: 10 }}
                    primaryTypographyProps={{ style: { fontSize: 14 } }}
                    primary={item.text}
                  />

                  {typeof item.marker === "number" &&
                    parseInt(item.marker) > 0 && (
                      <Badge
                        overlap="rectangular"
                        badgeContent={item.marker}
                        color="error"
                        style={{ marginLeft: 20 }}
                      ></Badge>
                    )}
                  {item.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={item.open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.collapsable.map((i, o) => (
                      <Link to={"/" + i.page} key={o}>
                        <ListItem
                          style={{ height: 40 }}
                          selected={props.page === i.page}
                          onClick={(e) => handleDrawerToggle(e, true)}
                          button
                        >
                          <Icon color={"secondary"}>chevron_left</Icon>
                          <ListItemText
                            style={{ display: "flex", flex: 1 }}
                            primaryTypographyProps={{
                              color: "secondary",
                              style: { fontSize: 12 },
                            }}
                            primary={i.text}
                          />
                          {typeof i.marker_2 === "number" &&
                            parseInt(i.marker_2) > 0 && (
                              <Badge
                                overlap="rectangular"
                                badgeContent={i.marker_2}
                                style={{ marginLeft: 35 }}
                              ></Badge>
                            )}
                          {typeof i.marker === "number" &&
                            parseInt(i.marker) > 0 && (
                              <Badge
                                overlap="rectangular"
                                badgeContent={i.marker}
                                color="error"
                                style={{ marginLeft: 12 }}
                              ></Badge>
                            )}
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              </List>
            );

          if (item.link)
            return (
              <a target="_blank" href={item.link} key={index}>
                <ListItem button>
                  <Icon className="material-icons-outlined">{item.icon}</Icon>
                  <ListItemText
                    style={{ display: "flex", flex: 1, marginRight: 10 }}
                    primaryTypographyProps={{ style: { fontSize: 14 } }}
                    primary={item.text}
                  />
                </ListItem>
              </a>
            );
          return (
            <Link to={"/" + item.page} key={index}>
              <ListItem selected={props.page === item.page} button>
                <Icon className="material-icons-outlined">{item.icon}</Icon>
                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: 14 } }}
                  style={{
                    display: "flex",
                    flex: 1,
                    marginRight: 10,
                    fontSize: 14,
                  }}
                  primary={item.text}
                />
                {typeof item.marker === "number" &&
                  parseInt(item.marker) > 0 && (
                    <Badge
                      overlap="rectangular"
                      badgeContent={item.marker}
                      color="error"
                      style={{ marginLeft: 12 }}
                    ></Badge>
                  )}
              </ListItem>
            </Link>
          );
        })}
      </List>
      <Divider />
      <List>
        {commonListItem.map((item, index) => (
          <ListItem
            onClick={() => handleCommonListItemClick(item)}
            button
            key={item.text}
          >
            <Icon className="material-icons-outlined">{item.icon}</Icon>
            <ListItemText
              primaryTypographyProps={{ style: { fontSize: 14 } }}
              style={{ display: "flex", flex: 1, marginRight: 10 }}
              primary={item.text}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <p
        style={{
          color: "#858585",
          margin: "10px 20px 10px 20px",
        }}
      >
        {" "}
        نسخه‌ٔ{" "}
        {ApiHelper.VERSION.toString()
          .split("")
          .toString()
          .replace(/,/g, ".")}{" "}
      </p>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color={"inherit"}
        elevation={props.elevation}
        className={
          props.transparentBar ? classes.appBarTransparent : classes.appBar
        }
        style={{ backgroundColor: props.colorBar && props.colorBar }}
      >
        {showToolbar && (
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <Badge
                overlap="rectangular"
                badgeContent={stores.AccountStore.sumNotifications}
                color="error"
              >
                <MenuIcon />
              </Badge>
            </IconButton>

            <Typography style={{ maxWidth: "60%" }} variant="inherit">
              {props.pageName && props.pageName.toString().length > 25
                ? props.pageName.toString().substr(0, 25) + " ..."
                : props.pageName}
              {props.subPageNameTitle && (
                <span style={{ fontSize: 12, marginTop: 2, marginBottom: 0 }}>
                  <br />
                  {props.subPageNameTitle}
                </span>
              )}
            </Typography>

            {props.leftAppBarComponent && (
              <div style={{ position: "absolute", left: 15 }}>
                {props.leftAppBarComponent}
              </div>
            )}

            {props.centerAppBarComponent && (
              <div className={classes.centerAppBarComponent}>
                {props.centerAppBarComponent}
              </div>
            )}

            {props.rightAppBarComponent && (
              <div className="rightAppBar">{props.rightAppBarComponent}</div>
            )}

            <div
              style={{
                display: "flex",
                position: "absolute",
                left: 15,
                top: 15,
                pointerEvents: "auto",
              }}
            >
              {props.onBackPress && (
                <IconButton
                  style={{ marginRight: 5, pointerEvents: "auto" }}
                  onClick={props.onBackPress}
                >
                  <Icon style={{ color: "#fff" }}>arrow_back</Icon>
                </IconButton>
              )}
            </div>
          </Toolbar>
        )}
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={"left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor={"left"}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={showToolbar && classes.toolbar} />

        <div
          style={{
            margin: showToolbar && 15,
            paddingBottom: showToolbar && 60,
          }}
        >
          {props.children}
        </div>

        {props.floatingActionButton && (
          <div style={{ position: "fixed", left: 10, bottom: 10, zIndex: 10 }}>
            {props.floatingActionButton}
          </div>
        )}
      </main>

      {alertDialogShow && (
        <AlertDialog
          description={
            logoutRequestState ? (
              <CircularProgress size={22} color="inherit" />
            ) : (
              "آیا مطمئن به خروج از حساب هستید؟"
            )
          }
          question={true}
          type="alert"
          negativeTextButtonColor={Colors.colorPrimaryLight}
          positiveTextButtonColor={Colors.colorAccent}
          onClose={({ yesPress }) => handleDialogShow(yesPress)}
        />
      )}
    </div>
  );
}

Main.defaultProps = {
  transparentBar: false,
  elevation: 3,
};

Main.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.object,
  page: PropTypes.string,
  pageName: PropTypes.string,
  subPageNameTitle: PropTypes.string,
  context: PropTypes.object,
  onBackPress: PropTypes.func,
  transparentBar: PropTypes.bool,
  leftAppBarComponent: PropTypes.element,
  rightAppBarComponent: PropTypes.element,
  centerAppBarComponent: PropTypes.element,
  floatingActionButton: PropTypes.element,
  colorBar: PropTypes.string,
  elevation: PropTypes.number,
};

export default Main;
