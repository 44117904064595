import Account from "./Account";
import AppRouter from "./AppRouter";
import Agents from "./Agents";
import Cars from "./Cars";
import Brokers from "./Brokers";
import Requests from "./Requests";
import Locations from "./Locations";
import Experts from "./Experts";
import Sellers from "./Sellers";
import Coworker from "./coworkers";

export default {
  AccountStore: new Account(),
  AgentsStore: new Agents(),
  LocationsStore: new Locations(),
  CarsStore: new Cars(),
  BrokersStore: new Brokers(),
  RequestsStore: new Requests(),
  ExpertsStore: new Experts(),
  SellersStore: new Sellers(),
  CoworkerStore: new Coworker(),
  AppRouterStore: new AppRouter(),
};
