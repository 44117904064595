import { useState, useEffect } from "react";

export const useCopyToClipboard = (initialValue = false) => {
  const [isCopied, setIsCopied] = useState(initialValue);

  const copyToClipboard = (text) => {
    const textToCopy = text;
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  return [isCopied, copyToClipboard];
};
