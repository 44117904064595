import { Box, Typography } from "@mui/material";
import React from "react";
import errorrequest from "../../Images/errorrequestnew.png";

const ErrorRequest = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: "text.primary", fontSize: 20 }}>
          ای بابا!
        </Typography>
        <Typography sx={{ color: "text.primary", fontSize: 20 }}>
          اینترنت به مشکل خورد😴
        </Typography>
      </Box>
      <Box
        sx={{
          width: { sm: 200, lg: 300 },
          height: { sm: 200, lg: 250 },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ width: 1, height: "100%" }}
          component="img"
          src={errorrequest}
          alt="ls"
        />
      </Box>
    </Box>
  );
};

export default ErrorRequest;
