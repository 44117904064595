import React from "react";
import Main from "../../common/UserMain";
import { useMediaQuery } from "@mui/material";
// import HeaderBuy from "../../../features/new-request-buy/HeaderBuy";
import HeaderBuy from "../../../features/new-request-buy/HeaderBuy";
import MainSection from "../../../features/new-request-buy/MainSectionBuy";
const BuyCreated = () => {
  const isMatchesTabletbp = useMediaQuery("(max-width: 959px)");
  const minWidth900 = useMediaQuery("(min-width: 0)");
  return (
    <Main
      page={"newRequest"}
      pageName={"خرید"}
      showToolbar={isMatchesTabletbp && minWidth900 ? true : false}
    >
      <HeaderBuy />
      <MainSection />
    </Main>
  );
};

export default BuyCreated;
