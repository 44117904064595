import { Button } from "@mui/material";
import React from "react";
const ButtonContactUs = ({
  type,
  label,
  startIcon,
  onClick,
  disabled = false,
}) => {
  return (
    <Button
      sx={[
        {
          bgcolor: type === "outlined" ? "#fff" : "#2196F3",
          borderRadius: 25,
        },
        startIcon && {
          "& .MuiButton-startIcon": {
            marginRight: "unset",
            marginLeft: "11px",
          },
        },
        {},
      ]}
      startIcon={startIcon ? startIcon : null}
      variant={type}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default ButtonContactUs;
