import { Box, Typography } from "@mui/material";
import React from "react";
const DateContactUs = ({ clock, month }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row-reverse", sm: "column" },
        gap: 1,
        alignItems: "center",
        color: "#00000099",
        fontSize: { sm: "16px !important", xs: "14px !important" },
      }}
    >
      <Typography component="time" sx={{ fontSize: { xs: 14, sm: 16 } }}>
        {clock}
      </Typography>
      <Box
        component="span"
        sx={{ display: { xs: "inline-block", sm: "none" } }}
      >
        &mdash;
      </Box>
      <Typography
        sx={{ fontSize: { xs: 14, sm: 16 } }}
        component="p"
        variant="body2"
      >
        {month}
      </Typography>
    </Box>
  );
};

export default DateContactUs;
