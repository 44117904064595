import React from "react";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import useGetRequestTotalItems from "../../Hooks/Requests/useGetRequestTotalItems";
import useStylesMui from "./useStyles.Mui";

const RequestsBtns = ({ onclick, variant, text, btnInfo, inputStyle }) => {
  const classes = useStylesMui();
  const totalItems = useGetRequestTotalItems(btnInfo);
  return (
    <Badge
      className={inputStyle}
      color="secondary"
      classes={{
        anchorOriginTopRightRectangle: classes.badge,
        colorSecondary: classes.color,
      }}
      badgeContent={totalItems}
    >
      <Button
        variant={variant}
        style={{ marginLeft: 15, borderRadius: 20 }}
        onClick={() => onclick()}
        color="secondary"
      >
        {text}
      </Button>
    </Badge>
  );
};

export default RequestsBtns;
