import React from "react";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../baseURL";
import createBaseQueryWithReauth from "../../modules/HOC/createBaseQueryWithReauth ";
import toast from "react-hot-toast";
import SuccessHotToast from "../../packages/icons/SuccessHotToast";
import ErrorHotToast from "../../packages/icons/ErrorHotToast";
export const apiRequest = createApi({
  reducerPath: "request",
  baseQuery: createBaseQueryWithReauth(`${baseURL}admin/system_request/`),
  tagTypes: ["requests"],
  endpoints: (builder) => ({
    getAllRequests: builder.query({
      query: ({
        page,
        search,
        recent,
        userId,
        createdAt,
        reservedAt,
        status,
        type,
      }) =>
        `get_all_requests/${type}/${status ? `${status}/` : "all/"}${page}/${
          userId ? `${userId}/` : ""
        }?recent=${recent}${search ? `&search=${search}` : ""}${
          reservedAt && type !== "buy" ? `&reservedAt=${reservedAt}` : ""
        }${createdAt ? `&createdAt=${createdAt}` : ""}`,
      providesTags: ["requests"],
    }),

    assignCoworkerToRequest: builder.mutation({
      query: ({ requestId, userId }) => ({
        url: `assign_coworker/${requestId}/${userId}`,
        method: "PATCH",
      }),
      invalidatesTags: ["requests"],
    }),

    changeRequestStatus: builder.mutation({
      query: (body) => ({
        url: `change_requests_status`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["requests"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        try {
          toast.success(`${data.message}`, {
            style: {
              background: "#4CAF50",
              color: "#fff",
              width: "100%",
              maxWidth: "355px",
              fontSize: "13px",
            },
            duration: 4000,
            position: "top-center",
            icon: <SuccessHotToast />,
          });
        } catch (err) {
          toast.error(`${err.message}`, {
            style: {
              background: "#EF5350",
              color: "#fff",
              fontSize: "13px",
              width: "100%",
              maxWidth: "355px",
            },
            position: "top-center",
            duration: 4000,
            icon: <ErrorHotToast />,
          });
        }
      },
    }),

    changeCarRequestDetail: builder.mutation({
      query: (body) => ({
        url: `change_car_request_detail`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["requests"],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        const { data, meta, error } = await queryFulfilled;
        try {
          toast.success(`${data.message}`, {
            style: {
              background: "#4CAF50",
              color: "#fff",
              width: "100%",
              maxWidth: "355px",
              fontSize: "13px",
            },
            duration: 4000,
            position: "top-center",
            icon: <SuccessHotToast />,
          });
        } catch (err) {
          toast.error(err.message, {
            style: {
              background: "#EF5350",
              color: "#fff",
              fontSize: "13px",
              width: "100%",
              maxWidth: "355px",
            },
            position: "top-center",
            duration: 4000,
            icon: <ErrorHotToast />,
          });
        }
      },
    }),

    assignRequestToCoworker: builder.mutation({
      query: (body) => ({
        url: "assign_coworkers_batch",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["requests"],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        const { data, meta, error } = await queryFulfilled;

        try {
          if (error) throw new Error("مجددا تلاش کنید");
          toast.success(`${data.message}`, {
            style: {
              background: "#4CAF50",
              color: "#fff",
              width: "100%",
              maxWidth: "355px",
              fontSize: "13px",
            },
            duration: 4000,
            position: "top-center",
            icon: <SuccessHotToast />,
          });
        } catch (err) {
          toast.error(err.message, {
            style: {
              background: "#EF5350",
              color: "#fff",
              fontSize: "13px",
              width: "100%",
              maxWidth: "355px",
            },
            position: "top-center",
            duration: 4000,
            icon: <ErrorHotToast />,
          });
        }
      },
    }),

    changeBuyStatus: builder.mutation({
      query: (body) => ({
        url: `change_buy_request_status/${body.id}`,
        method: "PATCH",
        body: { status: body.status },
      }),
      invalidatesTags: ["requests"],
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        const { data, meta, error } = await queryFulfilled;

        try {
          if (error) throw new Error("مجددا تلاش کنید");
          toast.success(`${data.message}`, {
            style: {
              background: "#4CAF50",
              color: "#fff",
              width: "100%",
              maxWidth: "355px",
              fontSize: "13px",
            },
            duration: 4000,
            position: "top-center",
            icon: <SuccessHotToast />,
          });
        } catch (err) {
          toast.error(err.message, {
            style: {
              background: "#EF5350",
              color: "#fff",
              fontSize: "13px",
              width: "100%",
              maxWidth: "355px",
            },
            position: "top-center",
            duration: 4000,
            icon: <ErrorHotToast />,
          });
        }
      },
    }),
  }),
});

export const {
  useGetAllRequestsQuery,
  useAssignCoworkerToRequestMutation,
  useChangeRequestStatusMutation,
  useChangeCarRequestDetailMutation,
  useAssignRequestToCoworkerMutation,
  useChangeBuyStatusMutation,
} = apiRequest;
