import createBaseQuery from "../../Store/callApi/baseQuery";
const createBaseQueryWithReauth = (baseUrl) => {
  const baseQuery = createBaseQuery(baseUrl);

  return async (args, api) => {
    let result = await baseQuery(args, api);
    if (result.error && result.error.status === 401) {
      // window.location.href = "/login";
      window.location.href = "https://nikbakhtkhodro.com/nk_admin/";
    }
    return result;
  };
};

export default createBaseQueryWithReauth;
