import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  links: [],
  changeLink: [],
};

export const ListInfoLinks = createSlice({
  name: "exclusiveLinks",
  initialState,
  reducers: {
    setLinks: (state, { payload }) => {
      state.links = payload;
    },
    setEditLink: (state, { payload }) => {
      state.changeLink = payload;
    },
    deleteLink: (state, { payload }) => {
      state.links = state.links.filter((link) => link._id !== payload._id);
    },
    addLink: (state, { payload }) => {
      state.links = [...state.links, payload];
    },
    updatedLink: (state, { payload }) => {
      const index = state.links.findIndex((link) => link._id === payload._id);
      if (index !== -1) {
        state.links[index] = payload;
      }
    },
  },
});

export const { setLinks, deleteLink, addLink, setEditLink, updatedLink } =
  ListInfoLinks.actions;

export default ListInfoLinks.reducer;
