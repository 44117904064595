import React, { useEffect, useState } from "react";
import useGetAgents from "../../Hooks/Agents/useGetAgents";
import { Divider, Switch, FormControlLabel } from "@material-ui/core";

const AgentFilter = ({ title, handlerAgent }) => {
  const [agents, agentloading] = useGetAgents("accepted");
  const [selectedAgentsIDs, setSelectedAgentsIDs] = useState([]);

  const handleAgentToggle = (agentId) => {
    setSelectedAgentsIDs((prevSelectedAgentsIDs) => {
      if (prevSelectedAgentsIDs.includes(agentId)) {
        return prevSelectedAgentsIDs.filter((id) => id !== agentId);
      } else {
        return [...prevSelectedAgentsIDs, agentId];
      }
    });
  };
  useEffect(() => {
    handlerAgent(selectedAgentsIDs);
  }, [selectedAgentsIDs]);
  return (
    <>
      <Divider />
      <h4>{title}</h4>
      {agents.map((agent, index) => (
        <FormControlLabel
          key={index}
          control={
            <Switch
              onChange={() => handleAgentToggle(agent._id)}
              name="jason"
            />
          }
          label={agent.name}
        />
      ))}
    </>
  );
};

export default AgentFilter;
