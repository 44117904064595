export function convertNumberToEN(value, numberSep = false) {
  return toEnglish(value, numberSep);
}

const toEnglish = (value, numberSep) => {
  if (!value && value !== 0 && value !== 0.0) {
    return 0;
  }

  value = value.toString();

  if (numberSep) value = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$&,");

  let englishNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
    persianNumbers = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"],
    arabicNumbers = ["١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩", "٠"];

  for (let i = 0, numbersLen = persianNumbers.length; i < numbersLen; i++) {
    value = value.replace(
      new RegExp(persianNumbers[i], "g"),
      englishNumbers[i]
    );
  }

  for (let i = 0, numbersLen = arabicNumbers.length; i < numbersLen; i++) {
    value = value.replace(new RegExp(arabicNumbers[i], "g"), englishNumbers[i]);
  }

  return value !== undefined ? value : "";
};

const letters = [
  ["", "یکم", "دوم", "سوم", "چهارم", "پنجم", "ششم", "هفتم", "هشتم", "نهم"],
  [
    "دهم",
    "یازدهم",
    "دوازدهم",
    "سیزدهم",
    "چهاردهم",
    "پانزدهم",
    "شانزدهم",
    "هفدهم",
    "هجدهم",
    "نوزدهم",
    "بیستم",
  ],
  ["", "", "بیست", "سی", "چهلم", "پنجاه", "شصت", "هفتاد", "هشتاد", "نود"],
];

const prepareNumber = (num) => {
  let Out = num;
  if (typeof Out === "number") {
    Out = Out.toString();
  }
  const NumberLength = Out.length % 3;
  if (NumberLength === 1) {
    Out = `00${Out}`;
  } else if (NumberLength === 2) {
    Out = `0${Out}`;
  }
  // Explode to array
  return Out.replace(/\d{3}(?=\d)/g, "$&*").split("*");
};

const threeNumbersToLetter = (num) => {
  // return zero
  if (parseInt(num, 0) === 0) {
    return "";
  }
  const parsedInt = parseInt(num, 0);
  if (parsedInt < 10) {
    return letters[0][parsedInt];
  }
  if (parsedInt <= 20) {
    return letters[1][parsedInt - 10];
  }
  if (parsedInt < 100) {
    const one = parsedInt % 10;
    const ten = (parsedInt - one) / 10;
    if (one > 0) {
      return letters[2][ten] + " و " + letters[0][one];
    }
    return letters[2][ten];
  }
};

export const Num2persian = (input) => {
  // Clear Non digits
  if (input.toString() === "1") return "اول";
  if (input.toString() === "30") return "سی ام";
  if (parseInt(input) > 40) return "چهلم";

  input = input.toString().replace(/[^0-9.-]/g, "");

  const floatParse = parseFloat(input);
  // return zero if this isn't a valid number
  if (isNaN(floatParse)) {
    return "";
  }
  // check for zero
  if (floatParse === 0) {
    return "";
  }

  let integerPart = input;
  let pointIndex = input.indexOf(".");
  // Check for float numbers form string and split Int/Dec
  if (pointIndex > -1) {
    integerPart = input.substring(0, pointIndex);
  }

  if (integerPart.length > 66) {
    return "خارج از محدوده";
  }

  // Split to sections
  const slicedNumber = prepareNumber(integerPart);
  // Fetch Sections and convert
  const Output = [];
  const SplitLength = slicedNumber.length;
  for (let i = 0; i < SplitLength; i += 1) {
    const SectionTitle = letters[2][SplitLength - (i + 1)];
    const converted = threeNumbersToLetter(slicedNumber[i]);
    if (converted !== "") {
      Output.push(converted + SectionTitle);
    }
  }

  return Output.join(" و ");
};
