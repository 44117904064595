import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import MainRouter from "./components/MainRouter";
import swConfig from "./swConfig";
import { Provider } from "react-redux";
import { store } from "./Store";
import "./new-errors.css";
ReactDOM.render(
  <Provider store={store}>
    <MainRouter />
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig);
