import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";

let apiClient = null;

@inject("AccountStore")
@observer
class Home extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    this.state = {
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
    };

    if (props.AccountStore.type === "admin") {
      props.history.replace("/agents/accepted");
    } else {
      props.history.replace("/dashboard");
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    if (this.state.sendRequest) {
      return;
    }

    this.setState({ sendRequest: true, sendRequestErr: false });

    apiClient.get(ApiHelper.getProfileInfo_GET, (error, response) => {
      if (error) {
        this.setState({
          sendRequest: false,
          sendRequestErr: true,
          message: response ? response.message : "",
        });
      } else {
        this.props.AccountStore.profile = response.data;
        this.setState({ sendRequest: false, sendRequestErr: false });
      }
    });
  }

  render() {
    return (
      <Main
        page={"dashboard"}
        pageName={"داشبورد"}
        context={this.props.history}
      >
        <div>
          <p style={{ margin: 5, padding: 5 }}>
            {this.props.AccountStore.profile.name} 
          </p>
          <p style={{ margin: 5, padding: 5 }}>
            {this.props.AccountStore.profile.username}
          </p>
          <hr />
          {this.props.AccountStore.type === "agent" && (
            <p style={{ margin: 5, padding: 5 }}>مشخصات پاسخ گویان : </p>
          )}
          {this.props.AccountStore.profile &&
            this.props.AccountStore.profile.experts &&
            this.props.AccountStore.profile.experts.map((v, i) => {
              return (
                <p
                  style={{
                    width: "max-content",
                    margin: 5,
                    borderRadius: 5,
                    padding: 5,
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  {v.name} / {v.phone}
                </p>
              );
            })}
        </div>
      </Main>
    );
  }
}

export default Home;
