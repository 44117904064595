export const contractsValueType = [
  { name: "مبایعه نامه", contractKind: "affadaptive" },
  { name: "کارشناسی خودرو", contractKind: "critic" },
  { name: "درخواست برگزاری مزایده خودرو", contractKind: "requestAuction" },
  { name: "رسید تحویل خودرو و مدرک", contractKind: "car_delivery_receipt" },
  { name: "رسید دریافت خودرو و مدرک", contractKind: "receive_receipt" },
  { name: "فرم نظر سنجی آمر خرید", contractKind: "purchaseManager" },
  { name: "فرم نظر سنجی آمر فروش", contractKind: "salesManager" },
  { name: "قرارداد ارائه خدمات خودرو", contractKind: "carService" },
];
