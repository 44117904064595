import * as yup from "yup";
export const ValidationFormikSellCar = yup.object({
  brand: yup.string("Enter your email").required("لطفا برند را انتخاب کنید"),
  model: yup.string("Enter your email").required("لطفا مدل را انتخاب کنید"),
  year: yup.string("Enter your email").required("لطفا سال را انتخاب کنید"),
  km: yup.string("Enter your email").required("لطفا کارکرد را انتخاب کنید"),
  location: yup.string("Enter your email").required("لطفا شهر را انتخاب کنید"),
  agentID: yup.string("Enter your email").required("لطفا شعبه را انتخاب کنید"),
  phone: yup
    .string("Enter your email")
    .required("لطفا شماره موبایل را وارد کنید")
    .matches(/^[0-9]+$/, "لطفا شماره موبایل را به عدد وارد نمایید")
    .matches(/^09\d{9}$/, "لطفا شماره همراه صحیح وارد کنید.")
    .max(11, "لطفا شماره موبایل را درست وارد نمایید"),
  date: yup
    .string("Enter your email")
    .required("لطفا روز مراجعه را انتخاب کنید"),
  time: yup
    .string("Enter your email")
    .required("لطفا ساعت مراجعه را انتخاب کنید"),
  name: yup
    .string("Enter your email")
    .required("لطفا نام و نام خانوادگی را وارد کنید")
    .min(5, "لطفا بیشتر ار 5 حرف وارد کنید"),
});
