import React from "react";
import { Box, Typography } from "@mui/material";
import pic from "../../Images/noError.svg";
const NoMessage = () => {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: { xl: 10, xs: 4 },
      }}
    >
      <Typography
        variant="body1"
        component="p"
        sx={{ fontSize: 20, color: "#000000DE" }}
      >
        هیچ پیامی نداریم
      </Typography>
      <Box
        component="div"
        sx={{ width: { xl: 423, xs: 226 }, height: { xl: 292, xs: 156 } }}
      >
        <Box
          component="img"
          sx={{ width: 1, height: 1 }}
          src={pic}
          alt="no error photo"
        />
      </Box>
    </Box>
  );
};

export default NoMessage;
