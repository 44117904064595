import React from "react";
const SortUp = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 13H13.5M1.5 8H10.5M1.5 3H7.5M15.5 9V1M15.5 1L18.5 4M15.5 1L12.5 4"
        stroke="#01579B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SortUp;
