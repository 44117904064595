import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { Colors } from "../../values/Colors";

const styles = {
  container: {
    display: "flex",
    padding: 1,
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  btn: { marginRight: 10, marginLeft: 10 },
};

function PaginationView(props) {
  let page = props.page;
  let totalPage = props.total;
  let disableNext = false;
  let disablePrev = false;

  if (page === totalPage) disableNext = true;
  if (page <= 1) disablePrev = true;

  if (totalPage <= 1) return null;

  return (
    <div style={styles.container}>
      <Button
        variant="outlined"
        onClick={props.nextOnPress}
        disabled={disableNext}
        size="small"
        style={styles.btn}
        color="secondary"
      >
        <Icon>navigate_next</Icon>بعدی
      </Button>
      <p
        style={{
          color: Colors.colorPrimaryLight,
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        صفحه‌ی {page} از {totalPage}
      </p>
      <Button
        variant="outlined"
        onClick={props.prevOnPress}
        disabled={disablePrev}
        style={styles.btn}
        size="small"
        color="secondary"
      >
        قبلی<Icon>navigate_before</Icon>
      </Button>
    </div>
  );
}

PaginationView.propTypes = {
  nextOnPress: PropTypes.func,
  prevOnPress: PropTypes.func,
  total: PropTypes.number,
  page: PropTypes.number,
};

export default PaginationView;
