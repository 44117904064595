import { makeStyles } from "@material-ui/core";

export const setting = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
    marginBottom: "48px",
  },
});

export const BtnIcon = makeStyles((theme) => ({
  root: {
    width: "35px",
    height: "35px",
    borderRadius: "9999px",
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: theme.palette.primary.main,
    },
    "& .MuiIconButton-label .MuiSvgIcon-root": {
      fill: "#fff",
    },
  },

  btnLeft: {
    justifyContent: "flex-end",
  },
}));

export const heading = makeStyles((theme) => ({
  root: {
    fontSize: "18px",
    color: theme.palette.primary.dark,
    width: "100%",
    maxWidth: "210px",
    display: "flex",
    justifyContent: "center",
  },
}));
