export const dateStringNumber = (entryDate) => {
  const date = new Date(entryDate);
  const date2 = new Intl.DateTimeFormat("fa", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  }).format(date);

  return date2;
};
