export const Statement = {
  NoDaySelected:
    "هیچ روزی انتخاب نشده است، همکار گرامی لطفا از باکس، روز مورد نظر را انتخاب کنید",
  NoRequestRegistered: "همکار گرامی در این روز هیچ درخواستی ثبت نشده",
  EditsellRequestCar: "همکار گرامی از ویرایش این درخواست مطمئن هستید؟",
  EditTextCarRequest: "همکار گرامی شما در حال ویرایش اطلاعات ",
  DeletesellRequestCar: "همکار گرامی از حذف این درخواست مطمئن هستید؟",
  SearchRegistrDate: "جستجو بر اساس تاریخ ثبت درخواست",
  ChangeRequestStatus: "همکار گرامی از تغییر وضعیت روز مطمئن هستید؟",
  deleteRequestLink: "آیا از حذف این لینک مطمئن هستید؟",
  selectContracts: "لطفا از باکس های بالا تاریخ ثبت قرارداد را انتخاب کنید.",
  emptyContracts: "در این بازه هیچ قرار دادی ثبت نشده است.",
  adminUnSuccess: " درخواست ناموفق - این نام کاربری در سیستم وجود دارد",
  deleteAdmin: "آیا از حذف ادمین مطمئن هستید؟",
  referenceMessage:
    "لطفا تعداد درخواست های انتخابی را که مایل به ارجاع هستید انتخاب کنید",
  titleContract: "کاربر گرامی جست و جوی تاریخ بر اساس ساخت قرار داد می باشد.",
  titleTypeDateFilter: " جست و جو بر اساس نوع تاریخ درخواست",
  titleAgentFilter: "فیلتر درخواست بر اساس شعبه پذیرنده",
  onlineOflineFilter: "جست و جو بر اساس حالت آنلاین",
  selerFilter: "جست و جو بر اساس فروشنده",
  sendMessageFilter: "فیلتر کردن بر اساس حالت ارسال پیامک",
  scoreAndRank: "فیلتر بر اساس رتبه و امتیاز در سیستم",
  numberPurchases: "فیلتر بر اساس تعداد خرید ها",
  registeryDate: "جست و جو بر اساس تاریخ عضویت",
  lastOnlineDay: "فیلتر بر اساس آخرین تاریخ آنلاین شدن",
  reportingBrokerMessage: "لطفا از باکس های بالا فیلتر های خود را انتخاب کنید.",
  deleteInfoCity: " آیااز آرشیو شهر مطمئن هستید؟ ",
  unarchiveInfoCity: " آیااز برگرداندن شهر مطمئن هستید؟ ",
  addInfoCity: " آیااز ایجاد شهر مطمئن هستید؟ ",
  editInfoCity: " آیااز ویرایش شهر مطمئن هستید؟ ",
  cityUnsuccess: "شهری با این نام وجود دارد",
};
