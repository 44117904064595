import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DatePicker } from "zaman";
import DatePickerIcon from "../../packages/icons/DatePickerIcon";
import { useDispatch, useSelector } from "react-redux";
import { datePickerTop } from "../../styles/main";
import {
  setCreatedAt,
  setDateMutual,
  setReservedAt,
} from "../../Store/slice/sliceNewRequest";
const DatePickerMobileNewRequest = ({
  className,
  updateStore,
  param,
  dateOnChange,
}) => {
  const datePickerTopStyle = datePickerTop();
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const { isRequestDateMobile } = useSelector((state) => state.newRequest);

  // useEffect(() => {
  //   dispatch(setDateMutual(`${new Date(date).toISOString().slice(0, 10)}`));
  // }, [date]);

  return (
    <div className={`${datePickerTopStyle.root} ${className}`}>
      <Box component="div">
        <Typography component="span">تاریخ</Typography>
        <DatePicker
          key={new Date().getMilliseconds()}
          round="x4"
          defaultValue={date}
          onChange={({ value }) => {
            setDate(value);
            console.log(value);
            dispatch(setDateMutual(new Date(value).toISOString().slice(0, 10)));
            if (isRequestDateMobile === "submit") {
              dispatch(setCreatedAt());
            } else {
              dispatch(setReservedAt());
            }
          }}
          weekends={[6]}
          position="center"
        />
        <Typography component="span">روز/ماه/سال</Typography>
        <DatePickerIcon />
      </Box>
    </div>
  );
};

export default DatePickerMobileNewRequest;
