import { isRejectedWithValue } from '@reduxjs/toolkit'
import toast from "react-hot-toast";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger =
  (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      toast.error(`${action?.payload?.data?.message || 'خطایی پیش امده است'}`, {
        style: {
          background: "#EF5350",
          color: "#fff",
          fontSize: "13px",
          width: "100%",
          maxWidth: "355px",
        },
        position: "top-center",
        duration: 4000,
      });
    }

    return next(action)
  }