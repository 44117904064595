import { createSlice } from "@reduxjs/toolkit";
const date = new Date().toISOString().slice(0, 10);

const initialState = {
  page: "",
  itemCount: "20",
  createdAt: date,
  topic: "",
  criteriaLevel: "",
  recent: "",
  resolved: false,
  titles: [],
  chartCheckBox: [],
  isSelectAll: false,
};

const getErrorsParams = createSlice({
  name: "getErrorsParams",
  initialState,
  reducers: {
    updateRTK: (state, action) => {
      if (action.payload.hasOwnProperty("page"))
        state.page = action.payload.page;
      if (action.payload.hasOwnProperty("itemCount"))
        state.itemCount = action.payload.itemCount;
      if (action.payload.hasOwnProperty("createdAt"))
        state.createdAt = action.payload.createdAt;
      if (action.payload.hasOwnProperty("topic"))
        state.topic = `${
          action.payload.topic.length > 0 ? action.payload.topic : ""
        }`;

      if (action.payload.hasOwnProperty("criteriaLevel"))
        state.criteriaLevel = `${
          action.payload.criteriaLevel.length > 0
            ? action.payload.criteriaLevel
            : initialState.criteriaLevel
        }`;
      if (action.payload.hasOwnProperty("recent"))
        state.recent = action.payload.recent;

      if (action.payload.hasOwnProperty("resolved")) {
        state.resolved = action.payload.resolved;
      }
    },

    addToCheck: (state, action) => {
      const exist = state.titles.some(
        (title) => title.id === action.payload.id
      );
      if (!exist) state.titles.push(action.payload);
    },

    deleteFromCheck: (state, action) => {
      state.titles = state.titles.filter(
        (title) => title.id !== action.payload.id
      );
    },

    deleteAll: (state) => {
      state.titles = initialState.titles;
    },

    addChartCheck: (state, action) => {
      const exist = state.chartCheckBox.some(
        (title) => title === action.payload
      );
      if (!exist) state.chartCheckBox.push(action.payload);
    },

    deleteChartCheck: (state, action) => {
      state.chartCheckBox = state.chartCheckBox.filter(
        (title) => title !== action.payload
      );
    },

    selectAllCheckbox: (state, action) => {
      action.payload.map((error) => state.chartCheckBox.push(error._id));
      state.isSelectAll = !state.isSelectAll;
    },

    deleteAllChartCheckBoxes: (state) => {
      state.chartCheckBox = initialState.chartCheckBox;
      state.isSelectAll = !state.chartCheckBox;
    },
  },
});

export const {
  updateRTK,
  deleteAll,
  deleteFromCheck,
  addToCheck,
  addChartCheck,
  deleteChartCheck,
  deleteAllChartCheckBoxes,
  selectAllCheckbox,
} = getErrorsParams.actions;
export default getErrorsParams.reducer;
