import { Container, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CardRequest from "./CardRequestBuy";
import PaginationPanel from "../../components/common/Pagination/PaginationPanel";
import { useGetAllRequestsQuery } from "../../Store/callApi/request";
import { useDispatch, useSelector } from "react-redux";
import {
  setPageQuery,
  updateNewRequestState,
} from "../../Store/slice/sliceNewRequest";
import { weekdayFormatter } from "../../utils/persianDate/weekday";
import { dateFormatter } from "../../utils/persianDate/dateFormatter";
import ToasterError from "../../components/common/HotToast/ToasterError";

import DisplayDate from "../../components/new-request-mutual-components/DisplayDate";
import StatusAssignment from "../../components/new-request-mutual-components/StatusAssignment";
import LoadingSkeleton from "../../components/new-request-mutual-components/LoadingSkeleton";
import NoRequests from "../../components/new-request-mutual-components/NoRequest";
import ErrorRequest from "../../components/new-request-mutual-components/ErrorRequest";
const MainSection = () => {
  const [dateDigit, setDateDigit] = useState("");
  const maxWidth1400 = useMediaQuery("(max-width: 1400px)");
  const maxWidth319 = useMediaQuery("(max-width: 319px)");
  const maxWidth284 = useMediaQuery("(max-width: 284px)");
  const dispatch = useDispatch();
  const { recent, search, page, userId, createdAt, reservedAt, status, data } =
    useSelector((store) => store.newRequest);
  // const totalItems = data?.[0]?.totalItems;
  // useRef
  const {
    data: allData,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    error,
    refetch,
  } = useGetAllRequestsQuery(
    {
      search,
      recent,
      page,
      userId,
      createdAt,
      reservedAt,
      status,
      type: "buy",
    },
    { pollingInterval: 3000 }
  );

  useEffect(() => {
    dispatch(updateNewRequestState(allData));
  }, [allData?.totalItems]);

  return (
    <Container
      maxWidth="xl"
      sx={[
        { px: { xl: "52px" }, marginTop: "-60px" },
        maxWidth319 && { paddingInline: "7px !important" },
        maxWidth284 && { paddingInline: "5px !important" },
      ]}
    >
      <ToasterError />
      <Grid container spacing={maxWidth1400 ? 1 : 2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DisplayDate dateDigit={dateDigit} onSetDateDigit={setDateDigit} />
          <StatusAssignment type="buy" />
          <Grid
            container
            spacing={2}
            item
            md={12}
            xs={12}
            mt={{ xs: "0", lg: "10px" }}
          >
            {isSuccess &&
              allData?.requests?.map((cardItem, index, cardItemArray) => (
                <Grid
                  key={cardItem._id}
                  item
                  xl={4}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <CardRequest
                    id={cardItem._id}
                    createdAt={weekdayFormatter(cardItem.createdAt)}
                    clock={dateFormatter(cardItem.createdAt, true)
                      .clock()
                      .slice(0, -3)}
                    date={dateFormatter(cardItem.createdAt, false).shortMonth()}
                    customerName={cardItem.name}
                    customerPhone={cardItem.phone}
                    customerReceptionCode={cardItem.userReceptionCode}
                    customerCar={cardItem.sellCarInfo}
                    status={cardItem.status}
                    reservedTime={
                      cardItem.reserveDatetime
                        ? dateFormatter(cardItem.reserveDatetime, true)
                            .clock()
                            .slice(0, -3)
                        : "تعیین نشده"
                    }
                    coworkerId={allData?.userID}
                    createdAtFullDate={dateFormatter(
                      cardItem.createdAt,
                      false
                    ).shortMonth()}
                    togetway={cardItem.togetway}
                    carCode={cardItem.buyCarId.code}
                    location={cardItem.location.location}
                  />
                </Grid>
              ))}
            {isFetching && <LoadingSkeleton />}
          </Grid>
          {isError && <ErrorRequest />}
          {isSuccess && allData?.requests.length < 1 && <NoRequests />}
          {isSuccess && !isError && (
            <PaginationPanel
              marginTop={{ lg: "37px", sm: "20px", xs: "20px" }}
              paddingBottom={{
                xs: "unset",
                sm: "20px",
                md: "20px",
                lg: "20px",
              }}
              count={isSuccess && allData?.totalPage}
              currentPage={page}
              onSetPageStore={setPageQuery}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainSection;
