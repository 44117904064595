import React, { memo, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ButtonBase, Divider } from "@mui/material";
import ModalContent from "./ModalContent";
// import ModalServiceDate from "./ModalServiceDate";
// import ModalTimeStatus from "./ModalTimeStatus";
// import ModalSituation from "./ModalSituation";
import {
  useChangeBuyStatusMutation,
  useChangeCarRequestDetailMutation,
} from "../../Store/callApi/request";
import { useFormik } from "formik";
import { values } from "../../values/newRequest/values";
import ModalStatus from "./ModalStatus";

const ModalRequest = ({
  open,
  onSetOpenModal,
  id,
  responsible,
  coworkerId,
  createdAt,
  createdAtFullDate,
  branch,
  clock,
  customerCar,
  customerReceptionCode,
  customerPhone,
  customerName,
  togetway,
  status,
  carCode,
  location,
}) => {
  const handleClose = () => onSetOpenModal(false);
  const [changeRequestStatusFn, { isLoading }] = useChangeBuyStatusMutation();

  const formik = useFormik({
    initialValues: {
      situation:
        status === "مجهول"
          ? "unknown"
          : status === "بررسی نشده"
          ? "notChecked"
          : status === "تایید شده"
          ? "confirmed"
          : status === "لغو شده"
          ? "canceled"
          : status === "در انتظار مشتری"
          ? "waiting"
          : "",
    },
    onSubmit: async (values) => {
      console.log(values);
      await changeRequestStatusFn({
        status: values.situation,
        id: id,
      });
      onSetOpenModal(false);
    },
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        disablePortal
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 0,
          },
        }}
        sx={{
          "& .MuiBackdrop-root": {
            background: "rgba(0, 0, 0 , .6)",
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              boxShadow: "unset",
              border: "unset",
              outline: "unset",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: 600,
              height: 1,
              maxHeight: 643,
              bgcolor: "background.paper",
              p: "8px 32px 24px 32px",
              borderRadius: 5,
              overflowY: "scroll",
              scrollbarWidth: "thin",
              scrollbarColor: "#1E88E5 transparent",
            }}
          >
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #0000001F",
                    paddingBottom: 1,
                  }}
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{ color: "text.primary", fontSize: 18 }}
                  >
                    وضعیت درخواست
                  </Typography>
                  <ButtonBase
                    component="div"
                    sx={{
                      fontSize: 25,
                      cursor: "pointer",
                      color: "text.secondary",
                    }}
                    onClick={handleClose}
                  >
                    &times;
                  </ButtonBase>
                </Box>
                <ModalContent
                  customerName={customerName}
                  customerCar={customerCar}
                  customerPhone={customerPhone}
                  customerReceptionCode={customerReceptionCode}
                  createdAtFullDate={createdAtFullDate}
                  clock={clock}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  handleSetFieldManually={formik.setFieldValue}
                  location={location}
                  carCode={carCode}
                />
                <Divider orientation="horizontal" />
                <ModalStatus
                  situation={formik.values.situation}
                  handleChange={formik.handleChange}
                />
                {/* <Divider orientation="horizontal" />
                <ModalServiceDate
                  requestId={id}
                  showInfo={responsible}
                  onSetShowInfo={setShowInfo}
                  coworkerId={coworkerId}
                  togetway={togetway}
                  assignCoworkerDate={assignCoworkerDate}
                />
                <Divider orientation="horizontal" />
                <ModalTimeStatus
                  branch={formik.values.branch}
                  disabledFields={responsible}
                  reserveDatetime={reserveDatetime}
                  dateReserved={formik.values.dateReserved}
                  hourReserved={formik.values.hourReserved}
                  knowCompany={formik.values.knowCompany}
                  knowCompanyValue={formik.values.knowCompanyValue}
                  handleSetFieldManually={formik.setFieldValue}
                  handleChange={formik.handleChange}
                  description={formik.description}
                />
                <Divider orientation="horizontal" />
                <ModalSituation
                  situation={formik.values.situation}
                  handleChange={formik.handleChange}
                  sendingSms={formik.values.sendingSms}
                  description={formik.values.description}
                  handleSetFieldManually={formik.setFieldValue}
                  disable={responsible}
                /> */}
                <Divider orientation="horizontal" />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ borderRadius: 25, minWidth: 85 }}
                    disabled={isLoading}
                    type="submit"
                  >
                    تائید
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: 25, minWidth: 85 }}
                    onClick={handleClose}
                  >
                    انصراف
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default React.memo(ModalRequest);
