export default function PriceFormater(price) {
  return !price
    ? ""
    : price
        .toString()
        .replace(/[^0-9]/g, "")
        .toString()
        .split("")
        .reverse()
        .join("")
        .split(/(...)/)
        .reverse()
        .join(",")
        .replace(/,(?=,)|,$|^,/g, "")
        .replace(/(,|^)(\d)(\d)?(\d)?/g, "$1$4$3$2");
}
