const VERSION = 100;
const get_app_version_GET = "app/version";
const loginRequest_POST = "admin/auth/login";
const changePassword_POST = "admin/auth/change_password";
const updateFCMID_POST = "admin/auth/status";

const getAdminNotification_GET = "admin/notifications/get_admin_notifications";

const logout_GET = "admin/auth/sign_out";

const getAgents_GET = (status, type) =>
  `admin/agents/get_all/${status}${type ? "/" + type : ""}`;

const getCoworkers_GET = (status, type) =>
  `admin/coworkers/get_all/${status}${type ? "/" + type : ""}`;
getCoworkers_GET;
const changeStatusAgent_GET = (status, id) =>
  `admin/agents/change_status/${status}/${id}`;
const getAdminsCoworkers_GET = (status) => `/admin/admins/get_all/${status}`;

const addCarTimeManagement_POST = "/admin/time-management/clerk-create-request";
const editCarTimeManagement_PUT = "/admin/time-management/clerk-edit-request/";

const addEditExpert_POST = "admin/agents/add_edit_expert";
const addEditSeller_POST = "admin/agents/add_edit_seller";
const addEditCoworker_POST = "admin/coworkers/add_edit_coworker";

const userRequestsReport_POST = "admin/user_requests/report";

const subGrantPermission_POST = (id) =>
  `/admin/sub-permission/grant-permission/${id}`;
const subRemovePermission_POST = (id) =>
  `/admin/sub-permission/remove-permission/${id}`;

const getLocations_GET = "admin/agents/get_locations";
const removeLocation_GET = (id) => `admin/agents/remove_location/${id}`;
const addEditLocation_POST = "admin/agents/add_edit_location";
const updateLocationsPosition_POST = "admin/agents/update_locations_position";

const addEditAgent_POST = "admin/agents/add_edit";
const shutdownDay_POST = "/admin/time-management/shut_down_day";
const shutdownDay_GET = "/admin/time-management/shut_down_day";
const shutdownDay_DELETE = "/admin/time-management/shut_down_day/";

const linksColors_GET = "/admin/links/colors";
const createLink_POST = "/admin/links/create_link";
const getLink_GET = "/admin/links/";
const delete_link_DELETE = (id) => `/admin/links/delete_link/${id}`;
const edit_link_PUT = (id) => `/admin/links/edit_link/${id}`;

const getCategories_GET = "admin/cars/get_categories";
const removeCategory_GET = (id) => `admin/cars/remove_category/${id}`;
const updateCategoryBestsellers_POST = "admin/cars/update_category_bestsellers";
const addEditCategory_POST = "admin/cars/add_edit_category";
const updateCategoriesPosition_POST = "admin/cars/update_categories_position";

const getAllCarsContent_GET = (page) =>
  `admin/cars/get_all_cars_content/${page}`;
const getCars_GET = (type, status, page) =>
  `admin/cars/get_all_cars/${type}/${status}/${page}`;
const createCar_POST = "admin/cars/create_car_auction";
const getCarInfoById_GET = (id) => `admin/cars/get_car_info/${id}`;
const addEditCar_POST = "admin/cars/add_edit_car_auction";

const get_car_gallery_GET = (code, isDocs) =>
  `admin/cars/get_images/${code}?is_docs=${isDocs ? 1 : 0}`;
const uploadCarImage_POST = (id, isDocs) =>
  `admin/cars/upload_image/${id}?is_docs=${isDocs ? 1 : 0}`;
const removeCarImage_GET = (id, isDocs) =>
  `admin/cars/remove_image/${id}?is_docs=${isDocs ? 1 : 0}`;
const changeStatusCar_GET = (status, type) =>
  `admin/cars/change_status/${status}/${type}`;
const setBrokerWinner_GET = (id) => `admin/brokers/set_broker_winner/${id}`;
const setBrokerPayment_GET = (id) => `admin/brokers/set_broker_payment/${id}`;

const getErrors_GET = (page) => `admin/errors/get_all/${page}`;
const removeAllErrors_GET = "admin/errors/remove_all";

const brokersDetailedReport_POST = "/admin/brokers/detailed_report";
const getBrokers_GET = (status, page) =>
  `admin/brokers/get_all/${status}/${page}`;
const changeStatusBroker_GET = (status) =>
  `admin/brokers/change_status/${status}`;
const changeEnableSms_GET = (id) => `admin/brokers/change_enable_sms/${id}`;
const addEditBroker_POST = "admin/brokers/add_edit";
const getBrokerInfoById_GET = (id) => `admin/brokers/get_broker_info/${id}`;
const terminateSession_POST = "admin/auth/terminate_session";

const getPayments_GET = (status, page) =>
  `admin/brokers/get_all_broker_payments/${status}/${page}`;

const getRequests_GET = (
  type,
  status,
  page,
  reservedFilter,
  inprogress = false
) =>
  `admin/user_requests/get_all/${type}/${status}/${page}?reserved=${
    reservedFilter ? 1 : 0
  }&inprogress=${inprogress ? 1 : 0}`;

const getReservedRequests_GET = (type, status, page, reservedFilter) =>
  `admin/user_requests/reserved_time/${type}/${status}/${page}?reserved=${
    reservedFilter ? 1 : 0
  }`;

const getRegistrations_GET = (page, status) =>
  `admin/registration/get_all/${status}/${page}`;

const getProfileInfo_GET = "admin/agents/get_agent_info";

const getRequestInfoById_GET = (id) => `admin/user_requests/get_info/${id}`;
const changeStatusRequest_GET = (status) =>
  `admin/user_requests/change_status/${status}`;
const sendCarLink_POST = (id) => `admin/user_requests/send_link/${id}`;
const sendCarApplicationLink_POST = (id) =>
  `admin/user_requests/send_car_app_link/${id}`;

const getServerTime_GET = "admin/server/get_time";
const getServerGetBackups_GET = "admin/server/get_db_backup_files";
const getServerAddBackups_GET = "admin/server/db_backup";
const getServerRestore_GET = (filename) =>
  `admin/server/db_restore?filename=${filename}`;

const getPaymentInfoByCarId_GET = (id) =>
  `admin/brokers/get_payment_info?car_id=${id}`;
const cancelPaymentInfoById_GET = (id) =>
  `admin/brokers/cancel_broker_payment?id=${id}`;
const cancelBrokerWinnerByCarId_GET = (id) =>
  `admin/brokers/cancel_broker_winner/${id}`;

const addEditCarHTML_POST = (id) => `admin/cars/update_car_description/${id}`;
const removeRegRequestById_POST = (id) => `admin/registration/remove/${id}`;
const changeUserRequestDateTime_POST = "admin/user_requests/change_date_time";

const changeSeller_POST = (brokerId) =>
  "admin/brokers/change_seller/" + brokerId;
const changeBrokersSeller_POST = "/admin/brokers/change_brokers_seller/";
const changeCoworker_POST = (brokerId) =>
  "admin/coworkers/change_coworker/" + brokerId;
const getAgentInfoById_GET = (id) => "admin/agents/get_agent_info_by_id/" + id;
const logoutBrokerById_GET = (id, os) => `admin/brokers/logout/${id}/${os}`;

const get_car_category_image = (carCategoryId) =>
  `admin/cars/get_car_category_image/${carCategoryId}`;
const uploadCarCategoryImage_POST = (carCategoryId) =>
  `admin/cars/upload_car_category_image/${carCategoryId}`;
const removeCarCategoryImage_Get = (carCategoryId) =>
  `admin/cars/remove_car_category_image/${carCategoryId}`;
const gettingAllModelsOfCarCategory = "admin/cars/get_model_search";
const getCarInfoByUserReceptionCode =
  "admin/user_requests/get_carInfo_by_userreceptioncode";

const getLogsFromServer = (page) => `admin/logger/get_logs/${page}`;
const deleteManyLogs = "admin/logger/delete_many_logs";
const deleteAllLogs = "admin/logger/delete/all/logs";

const contracts_POST = `/admin/contracts/get_contracts`;

const getContract = "admin/contracts/make_pdf";
const getContractByCode = (receptionCode) =>
  `/admin/contracts/get_contract_info/${receptionCode}`;
const updateContract = "/admin/contracts/update_contract";

const getBrandImage = (brandId) =>
  `admin/car-brands/get_image_brandlogo/${brandId}`;
const uploadBrandImage = (brandId) =>
  `admin/car-brands/add_image_brandlogo/${brandId}`;
const removeBrandImage = (brandId) =>
  `admin/car-brands/remove_image_brandlogo/${brandId}`;

const getModelsBackGround = (brandId) =>
  `admin/car-brands/get_image_modelbackground/${brandId}`;
const uploadModelsBackGround = (brandId) =>
  `admin/car-brands/add_image_modelbackground/${brandId}`;
const removeModelsBackGround = (brandId) =>
  `admin/car-brands/remove_image_modelbackground/${brandId}`;

const getQaBackGround = (brandId) => `admin/car-brands/  /${brandId}`;
const uploadQaBackGround = (brandId) =>
  `admin/car-brands/add_image_qabackground/${brandId}`;
const removeQaBackGround = (brandId) =>
  `admin/car-brands/remove_image_qabackground/${brandId}`;

const getBrandBanner = (brandId) =>
  `admin/car-brands/get_image_brandbanner/${brandId}`;
const uploadBrandBanner = (brandId) =>
  `admin/car-brands/add_image_brandbanner/${brandId}`;
const removeBrandBanner = (brandId) =>
  `admin/car-brands/remove_image_brandbanner/${brandId}`;

const getBrandInfo = (brandId) => `admin/car-brands/get_brand_info/${brandId}`;
const addBrandInfo = (brandId) => `admin/car-brands/add_brand_info/${brandId}`;

const testingAdminRoutes = (id) => `admin/test/testing/${id}`;

const getAllInRangeRequestsTimes =
  "admin/user_requests/get_in_range_reserved_times";

const assignRequestsToAgents = "admin/user_requests/assign_agent_randomly";
const ApiHelper = {
  VERSION,
  loginRequest_POST,
  getAdminNotification_GET,
  get_app_version_GET,
  changePassword_POST,
  getAgents_GET,
  changeStatusAgent_GET,
  getLocations_GET,
  removeLocation_GET,
  addEditLocation_POST,
  addEditAgent_POST,
  getCategories_GET,
  removeCategory_GET,
  updateCategoryBestsellers_POST,
  addEditCategory_POST,
  updateLocationsPosition_POST,
  updateCategoriesPosition_POST,
  userRequestsReport_POST,
  getCars_GET,
  createCar_POST,
  getCarInfoById_GET,
  addEditCar_POST,
  addEditCarHTML_POST,
  subGrantPermission_POST,
  subRemovePermission_POST,
  get_car_gallery_GET,
  removeCarImage_GET,
  uploadCarImage_POST,
  changeStatusCar_GET,
  setBrokerWinner_GET,
  setBrokerPayment_GET,
  contracts_POST,
  terminateSession_POST,
  getErrors_GET,
  removeAllErrors_GET,
  linksColors_GET,
  createLink_POST,
  getBrokers_GET,
  changeStatusBroker_GET,
  addEditBroker_POST,
  getBrokerInfoById_GET,
  getPayments_GET,
  getRequests_GET,
  getRegistrations_GET,
  updateFCMID_POST,
  getProfileInfo_GET,
  logout_GET,
  getLink_GET,
  getRequestInfoById_GET,
  changeStatusRequest_GET,
  brokersDetailedReport_POST,
  addEditExpert_POST,
  shutdownDay_POST,
  shutdownDay_GET,
  shutdownDay_DELETE,
  delete_link_DELETE,
  edit_link_PUT,
  addEditSeller_POST,
  addEditCoworker_POST,
  sendCarLink_POST,
  getServerTime_GET,
  getServerGetBackups_GET,
  getServerAddBackups_GET,
  getServerRestore_GET,
  getPaymentInfoByCarId_GET,
  cancelPaymentInfoById_GET,
  cancelBrokerWinnerByCarId_GET,
  getAllCarsContent_GET,
  removeRegRequestById_POST,
  changeUserRequestDateTime_POST,
  addCarTimeManagement_POST,
  editCarTimeManagement_PUT,
  changeEnableSms_GET,
  getAgentInfoById_GET,
  logoutBrokerById_GET,
  changeSeller_POST,
  changeBrokersSeller_POST,
  changeCoworker_POST,
  getCoworkers_GET,
  getAdminsCoworkers_GET,
  get_car_category_image,
  uploadCarCategoryImage_POST,
  removeCarCategoryImage_Get,

  getBrandImage,
  uploadBrandImage,
  removeBrandImage,

  getModelsBackGround,
  uploadModelsBackGround,
  removeModelsBackGround,

  getQaBackGround,
  uploadQaBackGround,
  removeQaBackGround,

  getBrandBanner,
  uploadBrandBanner,
  removeBrandBanner,

  gettingAllModelsOfCarCategory,
  getCarInfoByUserReceptionCode,
  getLogsFromServer,
  deleteManyLogs,
  deleteAllLogs,
  sendCarApplicationLink_POST,
  getContract,
  getContractByCode,
  updateContract,

  getBrandInfo,
  addBrandInfo,

  testingAdminRoutes,
  getReservedRequests_GET,

  getAllInRangeRequestsTimes,
  assignRequestsToAgents,
};

export { ApiHelper };
