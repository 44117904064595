import { createSlice } from "@reduxjs/toolkit";

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    message: "",
    isVisible: false,
    variant: "success", 
  },
  reducers: {
    showSnackbar: (state, action) => {
      state.message = action.payload.message;
      state.variant = action.payload.variant;
      state.isVisible = true;
    },
    hideSnackbar: (state) => {
      state.isVisible = false;
      state.message = "";
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
