import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import RequestState from "../../common/RequestState";
import { Styles } from "../../../values/Styles";
import AlertDialog from "../../common/AlertDialog";
import PaginationView from "../../common/PaginationView";
import Button from "@material-ui/core/Button";

var moment = require("moment-jalaali");

let apiClient = null;

@inject("AccountStore")
@observer
class Errors extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    this.state = {
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      page: 1,
      totalPage: 1,
      message: "",
      alertDialogQuestionMessage: "",
    };

    this.alertFunction = null;
  }

  componentDidMount() {
    this.getRequests();
  }

  renderResult() {
    if (this.state.data.length <= 0) {
      return <div style={{ margin: 10 }}>{`لیست خطاها خالی.`}</div>;
    } else {
      return (
        <div style={{ ...Styles.containerWrap900, padding: 10 }}>
          {this.state.data.map((item, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                padding: 4,
                border: "1px solid #d2d2d2",
              }}
            >
              <p
                style={{ flex: 1, margin: 5, minWidth: 300, color: "#5f5f5f" }}
              >
                {item.title}
              </p>
              <p style={{ flex: 1, margin: 5, minWidth: 300, color: "#000" }}>
                {item.content}
              </p>
              <p
                style={{ flex: 1, margin: 5, minWidth: 300, color: "#969696" }}
              >
                {moment(item.createdAt).format("jYYYY/jM/jD _ HH:mm")}
              </p>
            </div>
          ))}
        </div>
      );
    }
  }

  getRequests() {
    if (this.state.sendRequest) {
      return;
    }

    this.setState({ sendRequest: true, sendRequestErr: false });

    apiClient.get(
      ApiHelper.getErrors_GET(this.state.page),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
            totalPage: response.totalPage,
          });
        }
      }
    );
  }

  removeErrors() {
    this.alertFunction = this.removeErrorsRequest.bind(this);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به حذف خطاها هستید ؟",
    });
  }

  removeErrorsRequest() {
    if (this.state.sendRequest) {
      return;
    }
    this.setState({ sendRequest: true, sendRequestErr: false });

    apiClient.get(ApiHelper.removeAllErrors_GET, (error, response) => {
      if (error) {
        this.setState({
          sendRequest: false,
          sendRequestErr: true,
          message: response ? response.message : "",
        });
      } else {
        this.setState({
          sendRequest: false,
          sendRequestErr: false,
          data: [],
          totalPage: 1,
        });
      }
    });
  }

  render() {
    return (
      <Main
        page={"errors"}
        pageName={"خطاها"}
        centerAppBarComponent={
          <RequestState
            error={this.state.sendRequestErr}
            retryOnPress={() => this.getRequests()}
            title={"در حال دریافت لیست ..."}
            request={this.state.sendRequest}
          />
        }
        context={this.props.history}
      >
        {this.state.data.length > 0 && (
          <Button
            variant="contained"
            style={{ margin: 10 }}
            onClick={() => this.removeErrors()}
            size="small"
            color="primary"
          >
            پاک کردن تمام خطاها
          </Button>
        )}

        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            {this.renderResult()}
          </div>

          <div style={{ ...Styles.centerAlignItemsContainer }}>
            <PaginationView
              total={parseInt(this.state.totalPage)}
              page={parseInt(this.state.page)}
              nextOnPress={() => {
                this.state.page += 1;
                this.getRequests();
              }}
              prevOnPress={() => {
                this.state.page -= 1;
                this.getRequests();
              }}
            />
          </div>
        </div>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}

        {this.state.alertDialogQuestionMessage !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) this.alertFunction();
              this.alertFunction = null;
              this.setState({ alertDialogQuestionMessage: "" });
            }}
            type="alert"
            question={true}
            description={this.state.alertDialogQuestionMessage}
          />
        )}
      </Main>
    );
  }
}

export default Errors;
