exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".infoListLink_rowInfoTags__b7Yld {\n  margin: 10px !important;\n}\n.infoListLink_ButtonStyle__16_Jm {\n  width: 10rem;\n  overflow: hidden;\n}\n.infoListLink_copyLinkStyle__3hECq {\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n}\n.infoListLink_addEdidTagsStyle__1fnaS {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.infoListLink_requestNumberTag__1vfRD {\n  background: #f7a018;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 58px;\n  height: 58px;\n}\n.infoListLink_visitedStyle__3XMvV {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.infoListLink_textCopyLink__1IWFf {\n  position: absolute;\n  margin-top: 26px;\n}\n", ""]);

// Exports
exports.locals = {
	"rowInfoTags": "infoListLink_rowInfoTags__b7Yld",
	"ButtonStyle": "infoListLink_ButtonStyle__16_Jm",
	"copyLinkStyle": "infoListLink_copyLinkStyle__3hECq",
	"addEdidTagsStyle": "infoListLink_addEdidTagsStyle__1fnaS",
	"requestNumberTag": "infoListLink_requestNumberTag__1vfRD",
	"visitedStyle": "infoListLink_visitedStyle__3XMvV",
	"textCopyLink": "infoListLink_textCopyLink__1IWFf"
};