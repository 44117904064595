import React, { useEffect, useState } from "react";
import Main from "../../common/UserMain";
import AdminItem from "./adminItem.jsx";
import { useParams } from "react-router-dom";
import { useGetAdminsQuery } from "../../../Store/callApi/AdminDataService";
import { useHistory } from "react-router-dom";
import styles from "./admins.module.css";
import SnackBar from "../../common/SnackBar";

import { Fab, Paper, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const Admins = () => {
  const history = useHistory();
  const { status: statusAdmins } = useParams();
  const { data: admins } = useGetAdminsQuery(statusAdmins);
  const [showSnakBar, setShowSnakBar] = useState(false);

  const addNewAdmin = () => {
    history.push("/add_edit_admin");
  };

  const handlerEmptyAdmins = () => {
    if (admins?.records.length < 1) {
      return ` لیست ادمین های ${admins?.status} خالی است `;
    }
  };

  useEffect(() => {
    if (history.location.editSuccess) {
      setShowSnakBar(true);
    }
  }, []);

  return (
    <>
      <Main
        page={"admins"}
        pageName={"ادمین ها"}
        leftAppBarComponent={
          <Fab
            onClick={addNewAdmin}
            variant="extended"
            size="medium"
            color="primary"
          >
            ایجاد
            <AddIcon />
          </Fab>
        }
      >
        <Paper className={styles.textStatusAdmin}>
          <Box sx={{ fontWeight: "bold" }}>ادمین های {admins?.status}</Box>
        </Paper>
        <Paper className={styles.boxItemAdminStyle}>
          {admins?.records.map((item) => (
            <AdminItem
              key={item._id}
              id={item._id}
              name={item.name}
              status={item.status}
              username={item.username}
              phone={item.phone}
              officePhone={item.officePhone}
              subPermission={item.subPermission}
              setShowSnakBar={setShowSnakBar}
            />
          ))}
          {handlerEmptyAdmins()}
        </Paper>
      </Main>
      <SnackBar
        variant={"info"}
        message="درخواست با موفقیت انجام شد."
        open={showSnakBar}
        onClose={() => setShowSnakBar(false)}
      />
    </>
  );
};
export default Admins;
