import React from "react";

export default function ArchiveIcon () {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8336 12.3333L6.50034 6.99997M6.50034 6.99997L1.16699 1.66663M6.50034 6.99997L11.8337 1.66663M6.50034 6.99997L1.16699 12.3333"
        stroke="#C62828"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
