import React from "react";
import InfoRequestTopBar from "../../../features/info-requests/infoRequestTopBar";
import CitiesContainer from "../../../features/info-requests/CitiesContainer";
import Main from "../../common/UserMain";
import styles from "./information.module.css";
import { useMediaQuery, useTheme } from "@material-ui/core";

export default function Information() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Main
      page={"information"}
      pageName={" درخواست ها "}
      showToolbar={isTablet ? true : false}
    >
      <div className={styles.container}>
        <InfoRequestTopBar />
        <CitiesContainer />
      </div>
    </Main>
  );
}
