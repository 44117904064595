import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import createBaseQueryWithReauth from "../../modules/HOC/createBaseQueryWithReauth ";
import { baseURL } from "../../baseURL";
export const carInquiryApi = createApi({
  reducerPath: "carInquiryApi",
  baseQuery: createBaseQueryWithReauth(baseURL + "admin/cars/car-inquiry"),
  endpoints: (builder) => ({
    postCarInquiry: builder.mutation({
      query: ({ carId, inquiryData }) => {
        return {
          url: `/${carId}`,
          method: "POST",
          body: inquiryData,
        };
      },
    }),
  }),
});

export const { usePostCarInquiryMutation } = carInquiryApi;
