import React from "react";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import { CircularProgress } from "@material-ui/core";
import { convertNumberToEN } from "../../../language/NumberConverter";

var moment = require("moment-jalaali");

export default function ListItems({
  amount,
  authority,
  msg,
  ref_id,
  brokerName,
  carCode,
  createdAt,
  status,
  onClickCarInfo,
  onClickBrokerInfo,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        width: 280,
        backgroundColor: "#fff",
        border: "1px solid #ddd",
        margin: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ margin: 5 }}>مبلغ</h4>
            <h4 style={{ margin: 5 }}>{convertNumberToEN(amount, true)}</h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 5 }}>تاریخ</p>
            <h4 style={{ margin: 5, direction: "ltr" }}>
              {moment(createdAt).format("jYYYY/jM/jD _ HH:mm")}
            </h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 5 }}>بروکر</p>
            <h4 style={{ margin: 5, direction: "ltr" }}>{brokerName}</h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 5 }}>کد خودرو</p>
            <h4 style={{ margin: 5, direction: "ltr" }}>{carCode}</h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 5 }}>کد مرجع</p>
            <h4 style={{ margin: 5, direction: "ltr" }}>{ref_id}</h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 5 }}>{msg}</p>
          </div>

          <div
            style={{
              display: "flex",
              fontSize: 11,
              fontFamily: "tahoma",
              direction: "ltr",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 5 }}>{authority}</p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 5 }}>وضعیت</p>
            <p style={{ margin: 5 }}>{status}</p>
          </div>
        </div>
      </div>
      <Divider style={{ marginTop: 5, marginBottom: 0 }} />
      <div style={{ display: "flex" }}>
        <Button
          onClick={onClickBrokerInfo}
          color="primary"
          style={{ marginTop: 2 }}
        >
          مشخصات بروکر
        </Button>
        <Button
          onClick={onClickCarInfo}
          color="secondary"
          style={{ marginTop: 2 }}
        >
          مشخصات خودرو
        </Button>
      </div>
    </div>
  );
}
