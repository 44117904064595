import React, { memo, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
const HourAppointmentSelect = memo(
  ({ handleChange, disabledFields, value, timeTable }) => {
    const [openHour, setOpenHour] = useState(false);
    const cacheRtl = createCache({
      key: "muirtl",
      stylisPlugins: [prefixer, stylisRTLPlugin],
    });
    return (
      <CacheProvider value={cacheRtl}>
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            "& > *": {
              transition: "unset",
              animation: "unset",
              animationDelay: "unset",
            },
          }}
          disabled={!disabledFields}
        >
          <InputLabel
            id="user_request_hour_reserved"
            sx={{ "&": { fontSize: { xs: 13, sm: 16 } } }}
          >
            ساعت قرار ملاقات
          </InputLabel>
          <Select
            labelId="user_request_hour_reserved"
            id="hourReserved"
            name="hourReserved"
            open={openHour}
            onOpen={() => setOpenHour(true)}
            onClose={() => setOpenHour(false)}
            value={value}
            onChange={handleChange}
            label="ساعت قرار ملاقات"
            sx={{
              "& #hourReserved": {
                animation: "unset !important",
                animationDuration: "unset !important",
              },
            }}
          >
            {timeTable?.map((item, index) => (
              <MenuItem
                key={index}
                disabled={item.reserved}
                value={`${item.hour}`}
                sx={{ animation: "unset", transition: "unset" }}
                disableRipple
                disableTouchRipple
              >
                {item.hour} {item.reserved && "(رزرو شده)"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CacheProvider>
    );
  }
);

export default HourAppointmentSelect;
