import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Rating } from "@material-ui/lab";
import classes from "./StarRater.module.css";
const StarRater = ({ rate, onRateChange, readOnly, state = false, name }) => {
  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <Rating
        name={name}
        value={rate}
        onChange={(event, newValue) => onRateChange(newValue)}
        readOnly={readOnly}
        className={
          state === "positive" ? classes.RatingPositive : classes.RatingNegative
        }
      />
    </Box>
  );
};

export default StarRater;
