import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import RequestState from "../../common/RequestState";
import { Styles } from "../../../values/Styles";
import AlertDialog from "../../common/AlertDialog";
import PaginationView from "../../common/PaginationView";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { reaction } from "mobx";

var moment = require("moment-jalaali");

let apiClient = null;

@inject("AccountStore")
@inject("BrokersStore")
@observer
class Registrations extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    let pageTitle = Registrations.getPageTitle(props.match.params.status);
    this.state = {
      status: props.match.params.status,
      pageTitle: pageTitle,
      search: "",
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      page: 1,
      totalPage: 1,
      message: "",
      alertDialogQuestionMessage: "",
      removeRequestIds: [],
    };

    this.alertFunction = null;

    this.reaction = reaction(
      () => this.props.AccountStore.notificationsUpdateKey,
      () => this.updateInfo()
    );
  }

  componentWillUnmount() {
    if (this.reaction) {
      this.reaction();
    }
  }

  static getPageTitle(status) {
    let pageTitle = "درخواست‌های ثبت نام";
    if (status) {
      switch (status) {
        case "created":
          pageTitle = "جدید";
          break;
        case "deleted":
          pageTitle = "حذف شده";
          break;
        case "done":
          pageTitle = "انجام شده";
          break;
      }
    }
    return pageTitle;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.status !== nextProps.match.params.status) {
      let pageTitle = Registrations.getPageTitle(nextProps.match.params.status);

      return {
        page: 1,
        pageTitle: pageTitle,
        status: nextProps.match.params.status,
        sendRequest: false,
        sendRequestErr: false,
        sendRequestStatus: "",
        data: [],
        totalPage: 1,
        search: "",
        removeRequestIds: [],
        showUpdateRequest: false,
      };
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      this.getRequests(true);
    }
  }

  componentDidMount() {
    this.getRequests();
  }

  register(item) {
    if (!item.registered) {
      this.props.BrokersStore.emptyCurrent();
      this.props.BrokersStore.current.name = item.name;
      this.props.BrokersStore.current.phone = item.phone;
      this.props.BrokersStore.current.location = item.location;
      this.props.BrokersStore.current.activityType = item.activityType;
      this.props.history.push(`/add_edit_broker/accepted`);
    }
  }

  updateInfo() {
    this.setState({ showUpdateRequest: true });
  }

  remove(id, index) {
    this.alertFunction = this.removeRequest.bind(this, id, index);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به حذف این مورد هستید ؟",
    });
  }

  removeRequest(id, index) {
    if (this.state.removeRequestIds.indexOf(id) >= 0) {
      return;
    }
    this.state.removeRequestIds.push(id);

    this.setState({ ref: true });

    apiClient.post(
      ApiHelper.removeRegRequestById_POST(id),
      (error, response) => {
        let i = this.state.removeRequestIds.indexOf(id);
        if (i >= 0) this.state.removeRequestIds.splice(i, 1);
        if (error) {
          this.setState({
            ref: false,
            message: response ? response.message : "",
          });
        } else {
          if (this.state.data[index]) {
            this.state.data[index]["removed"] = true;
          }
          this.setState({ ref: true });
        }
      }
    );
  }

  renderResult() {
    if (this.state.data.length <= 0) {
      return (
        <div style={{ margin: 10 }}>
          {`لیست درخواست‌های ثبت نام -  ${this.state.pageTitle} خالی.`}
        </div>
      );
    } else {
      return (
        <div style={{ ...Styles.containerWrap900, padding: 10 }}>
          {this.state.data.map((item, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                maxWidth: 350,
                padding: 4,
                border: "1px solid #d2d2d2",
              }}
            >
              <p
                style={{ flex: 1, margin: 5, minWidth: 300, color: "#5f5f5f" }}
              >
                {item.name} : {item.phone}
              </p>
              <p style={{ flex: 1, margin: 5, minWidth: 300, color: "#000" }}>
                {item.activityType}
              </p>
              <p
                style={{ flex: 1, margin: 5, minWidth: 300, color: "#969696" }}
              >
                {moment(item.createdAt).format("jYYYY/jM/jD _ HH:mm")} :{" "}
                {item.location.location}
              </p>
              <div style={{ display: "flex" }}>
                {
                  <Button
                    variant="contained"
                    onClick={() => this.register(item)}
                    style={{ margin: 5, flex: 1 }}
                    color={item.registered ? "primary" : "secondary"}
                    size="small"
                  >
                    {item.registered ? "ثبت نام شده" : "ثبت نام"}
                  </Button>
                }

                {!item.registered &&
                  !item.removed &&
                  this.state.status !== "deleted" && (
                    <Button
                      variant="contained"
                      onClick={() => this.remove(item._id, i)}
                      style={{ margin: 5, flex: 1, backgroundColor: "#a50b0b" }}
                      color="primary"
                      size="small"
                    >
                      {this.state.removeRequestIds.indexOf(item._id) >= 0 ? (
                        <CircularProgress size={18} color="inherit" />
                      ) : (
                        "حذف"
                      )}
                    </Button>
                  )}

                {(item.removed || this.state.status === "deleted") && (
                  <p
                    style={{
                      padding: 5,
                      margin: 5,
                      textAlign: "center",
                      flex: 1,
                      borderRadius: 5,
                      backgroundColor: "#ffcdcd",
                    }}
                  >
                    حذف شد
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  search(t) {
    if (this.intervalSearch) {
      clearTimeout(this.intervalSearch);
    }
    this.intervalSearch = setTimeout(() => {
      this.setState(
        {
          search: t,
          data: [],
          totalPage: 1,
          page: 1,
          message: "",
        },
        () => this.getRequests(true)
      );
    }, 800);
  }

  getRequests(ref) {
    if (this.state.sendRequest) {
      return;
    }

    if (ref) {
      this.state.data = [];
      this.state.page = 1;
      this.state.totalPage = 1;
    }
    this.props.history.replace("/registrations/" + this.state.status);

    this.setState({ sendRequest: true, sendRequestErr: false });

    let data = {};

    if (this.state.search && this.state.search.trim() !== "")
      data["search"] = this.state.search;

    apiClient.get(
      ApiHelper.getRegistrations_GET(this.state.page, this.state.status),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
            totalPage: response.totalPage,
          });
        }
      },
      data
    );
  }

  render() {
    return (
      <Main
        page={"registrations/" + this.state.status}
        pageName={"ثبت نام - " + this.state.pageTitle}
        centerAppBarComponent={
          this.state.showUpdateRequest && this.state.status === "created" ? (
            <Button
              onClick={() => {
                this.setState({ showUpdateRequest: false });
                this.getRequests();
              }}
              variant="contained"
              color="secondary"
            >
              درخواست جدید رسید ! بروزرسانی
            </Button>
          ) : (
            <RequestState
              error={this.state.sendRequestErr}
              retryOnPress={() => this.getRequests()}
              title={"در حال دریافت لیست ..."}
              request={this.state.sendRequest}
            />
          )
        }
        context={this.props.history}
      >
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            <div style={{ ...Styles.contentContainerCol900, marginBottom: 10 }}>
              <TextField
                onChange={(t) => this.search(t.target.value)}
                placeholder="جستجو ..."
                type="search"
                defaultValue={this.state.search}
                variant="standard"
                margin={"dense"}
                style={{ maxWidth: 190, margin: 20 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    this.search(ev.target.value);
                  }
                }}
              />
            </div>

            {this.renderResult()}
          </div>

          <div style={{ ...Styles.centerAlignItemsContainer }}>
            <PaginationView
              total={parseInt(this.state.totalPage)}
              page={parseInt(this.state.page)}
              nextOnPress={() => {
                this.state.page += 1;
                this.getRequests();
              }}
              prevOnPress={() => {
                this.state.page -= 1;
                this.getRequests();
              }}
            />
          </div>
        </div>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}

        {this.state.alertDialogQuestionMessage !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) this.alertFunction();
              this.alertFunction = null;
              this.setState({ alertDialogQuestionMessage: "" });
            }}
            type="alert"
            question={true}
            description={this.state.alertDialogQuestionMessage}
          />
        )}
      </Main>
    );
  }
}

export default Registrations;
