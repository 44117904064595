import React from "react";
import { Grid, Container, useMediaQuery } from "@mui/material";
import SwitchRequest from "./SwitchRequest";
import SortFilter from "./SortFilter";
import FilterDateRadio from "./FilterDateRadio";
import FilterDateMobile from "./FilterDateMobile";
import MobileFilter from "../../components/common/filterMobile/MobileFilter";
import { useDispatch, useSelector } from "react-redux";
import AccordionFilterMobile from "./AccordionFilterMobile";
import DatePickerMobileNewRequest from "./DatePickerMobileNewRequest";
import {
  changeRecentQuery,
  setStatusParams,
  setUserId,
} from "../../Store/slice/sliceNewRequest";
import { useGetAllCoworkersQuery } from "../../Store/callApi/apiCoworkers";
import StatusTabs from "../../components/new-request-mutual-components/StatusTabs";
import Searchbar from "../../components/new-request-mutual-components/Searchbar";
import NewRequestCalendar from "../../components/new-request-mutual-components/NewRequestCalendar";
const Header = () => {
  const dispatch = useDispatch();
  const maxWidth319 = useMediaQuery("(max-width: 319px)");
  const maxWidth284 = useMediaQuery("(max-width: 284px)");
  const maxWidth600 = useMediaQuery("(max-width: 599.95px)");
  const {
    isSuccess,
    data: coworkersData,
    isLoading,
  } = useGetAllCoworkersQuery({
    status: "accepted",
    type: "coworker",
  });
  const hasAccess = useSelector(
    (state) => state.newRequest.data?.[0]?.hasAdminAccess
  );
  let { userId, status, recent, data } = useSelector(
    (state) => state.newRequest
  );
  recent = recent === true ? "جدیدترین" : "قدیمی ترین";
  status =
    status === "" || status === "all"
      ? "همه"
      : status === "notChecked"
      ? "بررسی نشده"
      : status === "unknown"
      ? "مجهول"
      : status === "waiting"
      ? "در انتظار مشتری"
      : "";
  let coworkerName;
  if (isSuccess) {
    coworkerName = coworkersData?.records?.find((item) => item._id === userId);
  }
  // const uniqueCoworkerNames =
  //   userId === "" ? null : [...new Set(coworkerName.name)];
  const userIdSearch = userId && !hasAccess ? "درخواست های من" : null;
  const handleRemoveFilterButtons = (e) => {
    if (e.target.value === "درخواست های من") {
      dispatch(setUserId(""));
    }

    if (e.target.value === coworkerName.name) dispatch(setUserId(""));
  };

  const handleRemoveFilters = () => {
    dispatch(setStatusParams("all"));
    dispatch(changeRecentQuery(true));
    dispatch(setUserId(""));
  };

  const filterButton = maxWidth600
    ? [status, recent, userIdSearch, coworkerName?.name]
    : [status, userIdSearch, coworkerName?.name];

  return (
    <>
      <Container maxWidth="xl" sx={{ display: { xs: "flex", lg: "none" } }}>
        {
          <MobileFilter
            filteredButtons={filterButton.flat()}
            customAccordionContainer={<AccordionFilterMobile />}
            onClickFilteredBtn={null}
            onHandleRemoveFilters={handleRemoveFilters}
          />
        }
      </Container>
      <Container
        maxWidth="xl"
        sx={[
          {
            px: { xl: "52px !important" },
          },
          maxWidth319 && { paddingInline: "7px" },
          maxWidth284 && { paddingInline: "5px" },
        ]}
      >
        <Grid
          container
          columns={{ xl: 12, lg: 12, md: 12, sm: 8, xs: 4 }}
          sx={{
            marginTop: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          alignItems="center"
        >
          <Grid item xl={4} lg={5} md={8} sm={5.5} xs={4}>
            <StatusTabs />
          </Grid>
          {!hasAccess && (
            <Grid
              sx={{
                display: { xs: "none", lg: "block" },
                justifyContent: "flex-end",
              }}
              item
              xl={3}
              lg={2.5}
            >
              <SwitchRequest />
            </Grid>
          )}
          <Grid
            item
            xl={hasAccess ? 6 : 3}
            lg={4.5}
            sm={2.5}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <SortFilter />
          </Grid>
        </Grid>
        <Grid
          container
          columns={{ xl: 12, lg: 12, md: 12, sm: 8, xs: 4 }}
          sx={{
            marginTop: { xs: 3, lg: 4 },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
          }}
          alignItems="center"
        >
          <Grid item xl={4} lg={5} xs={12}>
            <Searchbar />
          </Grid>
          <Grid
            item
            xl={5}
            lg={6}
            sx={{
              display: { xs: "none", lg: "block" },
            }}
          >
            <NewRequestCalendar />
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={6}
            sx={{ display: { lg: "none", xs: "none", sm: "block" }, mt: 4 }}
          >
            <FilterDateRadio />
          </Grid>
          <Grid item xs={4} mt={3} sx={{ display: { sm: "none" } }}>
            <FilterDateMobile />
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={6}
            sx={{
              display: { lg: "none" },
              marginTop: 3,
              "& div:first-child": { marginBottom: "unset", width: "100%" },
            }}
          >
            <DatePickerMobileNewRequest />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Header;
