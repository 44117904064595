
import { withStyles ,createTheme } from "@material-ui/core/styles";
import { yellow } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";

const theme = createTheme({
  palette: {
    primary: {
      main: '#bfbdbd',
    },
  },
});

export const GoldenSwitch = withStyles({
    switchBase: {
      color: theme.palette.primary.main,
      "&$checked": {
        color: yellow[700],
      },
      "&$checked + $track": {
        backgroundColor: yellow[700],
      },
    },
    checked: {},
    track: {},
  })(Switch);