import { Box, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { setting, BtnIcon, heading } from "../../styles/calendarTop";

import { useDispatch, useSelector } from "react-redux";
const DisplayDateString = ({ dateMutual, onChange = null }) => {
  const styles = setting();
  const btnStyle = BtnIcon();
  const headingStyle = heading();
  const [dateCounter, setDateCounter] = useState(0);
  const [dateTop, setDateTop] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    const DateFormatter = () => {
      const newDate = dateMutual.setDate(dateMutual.getDate() + dateCounter);
      setDateCounter(0);

      onChange(new Date(newDate).toISOString().slice(0, 10));
      const solarDate = new Intl.DateTimeFormat("fa", {
        year: "numeric",
        day: "2-digit",
        weekday: "long",
        month: "long",
      })
        .format(newDate)
        .replace(",", "")
        .split(" ");
      const reformattingSolarDate = solarDate.reverse().join(" ");
      setDateTop(reformattingSolarDate);
    };

    DateFormatter();
  }, [dateCounter, dateMutual]);
  return (
    <Box component="div" className={styles.root}>
      <IconButton
        aria-label="before"
        className={btnStyle.root}
        onClick={() => setDateCounter((c) => c - 1)}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <Typography component="h5" variant="h5" className={headingStyle.root}>
        {dateTop}
      </Typography>
      <IconButton
        aria-label="before"
        className={`${btnStyle.root} ${btnStyle.btnLeft}`}
        onClick={() => setDateCounter((c) => c + 1)}
      >
        <ArrowBackIosIcon />
      </IconButton>
    </Box>
  );
};

export default DisplayDateString;
