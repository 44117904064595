import React from "react";
const SortDown = () => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11H7M1 6H10M15 5V13M15 13L18 10M15 13L12 10M1 1H13"
        stroke="#01579B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SortDown;
