import React from "react";

export default function TinyBlueCar() {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.00002H2.04548M2.04548 7.00002H17.9545M2.04548 7.00002C2.05661 6.94855 2.06977 6.89753 2.08496 6.84713C2.12122 6.72684 2.17242 6.61117 2.27539 6.37949C2.27539 6.37949 2.99665 1.71413 5.28809 1.11839C5.84097 0.974649 5.99336 1.00002 6.74609 1.00002H13.2536C14.0063 1.00002 14.1589 0.97476 14.7119 1.11839C17.0086 1.71484 17.7296 6.39215 17.7296 6.39215C17.8289 6.61542 17.8794 6.72905 17.915 6.84713C17.9301 6.89753 17.9433 6.94855 17.9545 7.00002M2.04548 7.00002C2.03302 7.05765 2.0231 7.11585 2.01572 7.17433C2 7.29898 2 7.42569 2 7.67922V13M17.9545 7.00002H19M17.9545 7.00002C17.967 7.05765 17.977 7.11585 17.9844 7.17433C18 7.29822 18 7.4242 18 7.67466V13.0001M18 13.0001L14 13.0001M18 13.0001V13.9999C18 15.1045 17.1046 16 16 16C14.8954 16 14 15.1046 14 14V13.0001M14 13.0001L6 13M6 13H2M6 13V14C6 15.1046 5.10457 16 4 16C2.89543 16 2 15.1046 2 14V13"
        stroke="#2196F3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
