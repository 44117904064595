import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStatus,
  setStatusParams,
} from "../../Store/slice/sliceNewRequest";
const StatusMobile = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.newRequest.status);
  return (
    <Box sx={{ display: "flex", width: 1, paddingRight: "30px" }}>
      <FormControl component="fieldset" variant="standard" sx={{ width: 1 }}>
        <FormGroup
          sx={{
            "& label": {
              justifyContent: "flex-end",
              position: "relative",
              fontSize: 14,
            },

            "& label span:last-child": {
              fontSize: 14,
            },

            "& .MuiButtonBase-root": {
              marginRight: "auto",
            },
            "& label:first-child::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              background: "#D9D9D9",
              right: "-16px",
            },
            "& label:nth-child(2)::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              background: "#42A5F5",
              right: "-16px",
            },
            "& label:nth-child(3)::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              background: "#BA68C8",
              right: "-16px",
            },
            "& label:nth-child(4)::before": {
              content: '""',
              display: "block",
              width: "5.82px",
              height: "5.82px",
              borderRadius: "50%",
              position: "absolute",
              background: "#FF9800",
              right: "-16px",
            },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={status === "all" || status === ""}
                onChange={() => {
                  dispatch(setStatusParams("all"));
                  dispatch(setActiveStatus("all"));
                }}
              />
            }
            label="همه"
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              marginInline: "unset",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  status === "notChecked" || status === "all" || status === ""
                }
                onChange={() => {
                  dispatch(setStatusParams("notChecked"));
                  dispatch(setActiveStatus("notChecked"));
                }}
              />
            }
            label="بررسی نشده"
            labelPlacement="start"
            sx={{ justifyContent: "space-between", marginInline: "unset" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  status === "waiting" || status === "all" || status === ""
                }
                onChange={() => {
                  dispatch(setStatusParams("waiting"));
                  dispatch(setActiveStatus("waiting"));
                }}
              />
            }
            label="درانتظارمشتری"
            labelPlacement="start"
            sx={{ justifyContent: "space-between", marginInline: "unset" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  status === "unknown" || status === "all" || status === ""
                }
                onChange={() => {
                  dispatch(setStatusParams("unknown"));
                  dispatch(setActiveStatus("unknown"));
                }}
              />
            }
            label="مجهول"
            labelPlacement="start"
            sx={{ justifyContent: "space-between", marginInline: "unset" }}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default StatusMobile;
