import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Main from "../../common/UserMain";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Button from "@material-ui/core/Button";
import SnackBar from "../../common/SnackBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import style from "./coworkers.module.css";
import AlertDialog from "../../common/AlertDialog";
import { convertNumberToEN } from "../../../language/NumberConverter";
import DialogUpdateConfirm from "./DialogUpdateConfirm";
import { goBackUseHistory } from "../../AppHistory";
import Warning from "@material-ui/icons/Warning";
import SubPermissions from "../../common/subPermission/subPermissions";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";

import { permissionsCode } from "../../../data/presenter/GetCases";
import Permissions from "./permissions";
let apiClient = null;
let idCoworker = "";

@inject("AccountStore")
@inject("CoworkerStore")
@observer
class AddEditCoworkers extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();
    idCoworker = props.CoworkerStore.getCurrent();
    this.HandlerCloseAlert = this.HandlerCloseAlert.bind(this);
    this.submit = this.submit.bind(this);

    if (!props.AccountStore.isLogin()) this.props.history.replace("/");
    this.state = {
      sendRequest: false,
      sendRequestErrMessage: "",
      inputWarn: 0,
      warnInputMessage: "",
      success: false,
      getAgentErr: false,
      message: "",
      AlertDialog: false,
      levelChecked: false,
      selectedPermissionCodes: [],
    };
  }

  isValidInput() {
    this.setState({ inputWarn: 0 });

    let info = this.props.CoworkerStore.getCurrent();
    if (info.username.replace(/ /g, "").length < 5) {
      this.setState({ inputWarn: 1 });
      this.inputUsername.focus();
      return false;
    }

    if (this.state.isNewBroker && info.password.replace(/ /g, "").length < 6) {
      this.setState({ inputWarn: 2 });
      this.inputPass.focus();
      return false;
    }

    if (info.name.replace(/ /g, "") === "") {
      this.setState({ inputWarn: 3 });
      this.inputName.focus();
      return false;
    }

    this.setState({ AlertDialog: true });
  }

  HandlerCloseAlert(isShow) {
    this.setState({ AlertDialog: isShow });
  }

  handlerListSubPermissions(subPermissionCode) {
    this.setState({ selectedPermissionCodes: subPermissionCode });
  }
  componentDidMount() {
    if (idCoworker._id) {
      const ListSubPermission = this.props.CoworkerStore.getCurrent();
      const subPermissionCodes = ListSubPermission.subPermission.map(
        (itemCode) => itemCode?.permissionCode
      );
      this.setState({ selectedPermissionCodes: subPermissionCodes });
    }
  }

  handlerIsAdmin() {
    const { CoworkerStore } = this.props;
    if (CoworkerStore.coworkers.hasAccessToSubPermission) {
      return (
        <>
          <SubPermissions
            numberPermissionCodes={this.state.selectedPermissionCodes}
            idEdit={idCoworker._id}
            handlerSubPermission={this.handlerListSubPermissions.bind(this)}
          />
        </>
      );
    } else {
      return (
        <div className={style.notSubPermission}>
          <Warning />
          <h4>کاربر گرامی شما دسترسی های لازم را برای این بخش ندارید.</h4>
        </div>
      );
    }
  }

  submit() {
    if (this.state.sendRequest) return;
    let data = this.props.CoworkerStore.getCurrent();
    const statePermissionCodes = this.state.selectedPermissionCodes;
    data = {
      ...data,
      permissionCodes: statePermissionCodes,
    };
    this.setState({ sendRequest: true });
    apiClient.post(
      ApiHelper.addEditCoworker_POST,
      (error, response) => {
        this.setState({ sendRequest: false });
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false, success: true });
        }
      },
      data
    );
  }

  render() {
    const { CoworkerStore, history } = this.props;
    return (
      <Main
        onBackPress={() => goBackUseHistory(history)}
        page={"coworker/accepted"}
        pageName={"ثبت همکار"}
        context={history}
      >
        <Paper style={{ padding: 15 }}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputUsername = r)}
                onChange={(t) =>
                  (CoworkerStore.current.username = t.target.value)
                }
                defaultValue={CoworkerStore.current.username}
                error={this.state.inputWarn === 1}
                label="نام کاربری"
                placeholder={
                  !this.state.isNewBroker ? "تغییر نام کاربری (غیر ضروری)" : ""
                }
                inputProps={{ maxLength: 50 }}
                fullWidth={true}
                styles={classes.textInput}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) =>
                  ev.key === "Enter" && this.inputPass.focus()
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>account_circle</Icon>
                    </InputAdornment>
                  ),
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value).toString()
                    : "";
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputPass = r)}
                onChange={(t) =>
                  (CoworkerStore.current.password = t.target.value)
                }
                defaultValue={CoworkerStore.current.password}
                error={this.state.inputWarn === 2}
                placeholder={
                  !this.state.isNewBroker ? "تغییر رمز عبور (غیر ضروری)" : ""
                }
                label="رمز عبور"
                inputProps={{ maxLength: 50 }}
                fullWidth={true}
                styles={classes.textInput}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) =>
                  ev.key === "Enter" && this.inputName.focus()
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>lock</Icon>
                    </InputAdornment>
                  ),
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value).toString()
                    : "";
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputName = r)}
                onChange={(t) => (CoworkerStore.current.name = t.target.value)}
                defaultValue={CoworkerStore.current.name}
                error={this.state.inputWarn === 3}
                label="نام کامل"
                inputProps={{ maxLength: 50 }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) =>
                  ev.key === "Enter" && this.inputPhone.focus()
                }
              />
            </Grid>

            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputPhone = r)}
                onChange={(t) => (CoworkerStore.current.phone = t.target.value)}
                defaultValue={CoworkerStore.current.phone}
                label={"موبایل"}
                placeholder={"موبایل با ۰۹ شروع شود"}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                inputProps={{
                  style: { textAlign: "left", direction: "ltr", fontSize: 15 },
                  maxLength: 11,
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") this.officePhone.focus();
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value)
                      .toString()
                      .replace(/[^0-9]/g, "")
                      .toString()
                    : "";
                }}
                type="tel"
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Divider />
              <p>دسترسی ها</p>
              <Grid item sm={6} xs={12}>
                {Permissions.map((perm, index) => {
                  return (
                    <FormControl key={index} component="fieldset">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          checked={
                            CoworkerStore.current.permissions.indexOf(
                              permissionsCode[`${perm?.permissionCode}`]
                            ) >= 0
                          }
                          onChange={(e) => {
                            let iA = CoworkerStore.current.permissions.indexOf(
                              permissionsCode[`${perm?.permissionCode}`]
                            );
                            if (iA >= 0) {
                              CoworkerStore.current.permissions.splice(iA, 1);
                            } else {
                              CoworkerStore.current.permissions.push(
                                permissionsCode[`${perm?.permissionCode}`]
                              );
                            }
                            this.setState({ ref: true });
                          }}
                          control={<Checkbox color="primary" />}
                          label={perm.label}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </FormControl>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item sm={12} xs={12}>
              <Divider />
              {this.handlerIsAdmin()}
            </Grid>

            <Grid item sm={12} xs={12}>
              <Divider />
              {this.state.sendRequest ? (
                <div style={classes.progressBarContainer}>
                  <LinearProgress
                    style={{ width: 150, height: 4, marginTop: 15 }}
                  />
                  <p>درحال ارسال اطلاعات ...</p>
                </div>
              ) : (
                <div style={{ display: "flex", marginTop: 20, maxWidth: 330 }}>
                  <Button
                    variant="contained"
                    onClick={() => this.isValidInput()}
                    style={{ margin: 5 }}
                    fullWidth
                    color={"primary"}
                  >
                    {this.state.isNewBroker ? "ارسال" : "بروزرسانی"}
                  </Button>
                  {this.state.AlertDialog && (
                    <DialogUpdateConfirm
                      name={CoworkerStore.current.name}
                      permissions={CoworkerStore.current.permissions}
                      subpermissions={this.state.selectedPermissionCodes}
                      HandlerCloseAlert={this.HandlerCloseAlert}
                      submit={this.submit}
                    />
                  )}
                  <Button
                    variant="outlined"
                    style={{ margin: 5 }}
                    onClick={() => goBackUseHistory(history)}
                    fullWidth
                    color={"secondary"}
                  >
                    بازگشت
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </Paper>

        <SnackBar
          variant={"error"}
          message={this.state.sendRequestErrMessage}
          open={this.state.sendRequestErrMessage !== ""}
          onClose={() => this.setState({ sendRequestErrMessage: "" })}
        />

        {this.state.success && (
          <AlertDialog
            onClose={() => goBackUseHistory(history)}
            question={false}
            description={
              this.state.isNewBroker
                ? "فروشنده با موفقیت ایجاد شد."
                : "بروزرسانی با موفقیت انجام شد."
            }
          />
        )}

        {this.state.warnInputMessage !== "" && (
          <AlertDialog
            onClose={() => this.setState({ warnInputMessage: "" })}
            question={false}
            description={this.state.warnInputMessage}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </Main>
    );
  }
}

export default AddEditCoworkers;

const classes = {
  textInput: {
    margin: 10,
  },
  gridInput: {
    maxWidth: 350,
    minWidth: 300,
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
};
