import React from "react";
import { Switch } from "@material-ui/core";
import { useState } from "react";
import styles from "./Filter.module.css";

const FilterSwitchOne = ({
  title,
  handlertypeDate,
  switchOff,
  switchOn,
  defaultState,
}) => {
  const [isChecked, setIsChecked] = useState(defaultState);
  const handleSwitchChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    handlertypeDate(newCheckedState, isChecked); 
  };
  return (
    <>
      <h4>{title}</h4>
      <div className={styles.holder}>
        <Switch
          checked={isChecked}
          onChange={handleSwitchChange}
          name="checkedA"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        <div className={styles.holder}>
          <p className={isChecked ? styles.bolder : styles.option}>
            {switchOn}
          </p>
          <p> / </p>
          <p className={!isChecked ? styles.bolder : styles.option}>
            {switchOff}
          </p>
        </div>
      </div>
    </>
  );
};

export default FilterSwitchOne;
