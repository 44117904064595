import { makeStyles } from "@material-ui/core";

export const button = makeStyles((theme) => ({
  root: {
    background: "#fff",
    fontSize: "14px",
    boxShadow: theme.shadows[2],
    width: "100%",
    "& .MuiButton-label": {
      color: "#01579B",
    },
  },

  responsiveFontSize: {
    fontSize: "12.5px",
    background: "#fff",
    boxShadow: theme.shadows[2],
    width: "100%",
    "& .MuiButton-label": {
      color: "#01579B",
    },
  },

  responsiveLaptop: {
    fontSize: "11.5px",
    padding: "6px 8px",
  },

  responsiveMobile: {
    fontSize: "11px",
    padding: "6px .5px",
    "& .MuiButton-startIcon svg": {
      height: "10px",
      width: "16px",
    },

    "& .MuiButton-startIcon": {
      marginRight: "1.5px",
    },
  },

  responsiveGrid: {
    margin: "unset",
  },
}));
