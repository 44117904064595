export const topics = [
  { title: "sms", id: 1 },
  { title: "client", id: 2 },
  { title: "server", id: 3 },
  { title: "fcm", id: 4 },
  { title: "application", id: 5 },
  { title: "general", id: 6 },
];

export const criteriaLevel = [
  { title: "normal", id: 7 },
  { title: "necessary", id: 8 },
  { title: "ultimate", id: 9 },
];
