import { Drawer, SwipeableDrawer } from "@mui/material";
import React from "react";
import DrawerMobileLists from "./DrawerMobileLists";
const DrawerMobileFilter = ({
  openFilters,
  onSetOpenFilters,
  customAccordionContainer,
  onHandleRemoveFilters,
}) => {
  return (
    <SwipeableDrawer
      sx={{
        "& .MuiPaper-root": {
          width: 1,
          maxWidth: 400,
        },

        "& .MuiPaper-root.MuiDrawer-paper": {
          transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) !important",
        },
      }}
      anchor="right"
      open={openFilters}
      onClose={() => onSetOpenFilters(!openFilters)}
    >
      <DrawerMobileLists
        onSetOpenFilters={onSetOpenFilters}
        customAccordionContainer={customAccordionContainer}
        onHandleRemoveFilters={onHandleRemoveFilters}
      />
    </SwipeableDrawer>
  );
};

export default DrawerMobileFilter;
