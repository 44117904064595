import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanIds,
  pushIds,
  pushIndividualId,
  removeIds,
} from "../../Store/slice/sliceContactUs";
import { useMediaQuery } from "@mui/material";
const CheckboxContactUs = ({ name, id }) => {
  const maxWidth600 = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const { checkedItems } = useSelector((state) => state.contactUs);
  const isChecked = checkedItems.some((item) => item === id);
  const handleChecked = (e) => {
    console.log(e.target.value);
    if (isChecked) {
      dispatch(removeIds(e.target.value));
    }
    if (!isChecked) {
      dispatch(pushIndividualId(e.target.value));
    }
  };
  return (
    <FormControlLabel
      value={id}
      sx={[
        {
          marginRight: "unset",
          color: "#000000DE",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "& .MuiTypography-root": {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
          "& .MuiButtonBase-root.MuiCheckbox-root": {
            marginRight: { xs: 1, md: 3 },
          },
        },
        maxWidth600 && {
          "& span:nth-child(2)": {
            maxWidth: "calc(100% - 80px)",
          },
        },
      ]}
      label={name}
      control={<Checkbox checked={isChecked} onChange={handleChecked} />}
    />
  );
};

export default CheckboxContactUs;
