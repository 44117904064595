import { useEffect, useState } from "react";
import { ApiClient, ApiHelper } from "../data/remote";
import { recentDaysService } from "../services/date/RecentDaysService";

export const useListShutdownDays = () => {
  let apiClient = ApiClient.getInstance();

  const [listOffDaye, setListOffDaye] = useState();
  const [startEndDay, setStartEndDay] = useState({
    startDay: "",
    endDay: "",
  });

  const executeDefaultDays = () => {
    const appointments = recentDaysService(20);
    setStartEndDay((prevState) => {
      const newStartDay = appointments[0].date.split("T")[0];
      const newEndDay =
        appointments[appointments.length - 1].date.split("T")[0];
      handlerListOffDaye(newStartDay, newEndDay);
      return {
        ...prevState,
        startDay: newStartDay,
        endDay: newEndDay,
      };
    });
  };

  const getListOffDays = (startDate, endDate) => {
    if (startDate && endDate) {
      setStartEndDay((prevState) => {
        const newStartDay = startDate.split("T")[0];
        const newEndDay = endDate.split("T")[0];
        handlerListOffDaye(newStartDay, newEndDay);
        return {
          ...prevState,
          startDay: newStartDay,
          endDay: newEndDay,
        };
      });
    } else {
      executeDefaultDays();
    }
  };

  useEffect(() => {
    executeDefaultDays();
  }, []);

  const handlerListOffDaye = (start, end) => {
    apiClient.get(
      ApiHelper.shutdownDay_GET,
      (error, response) => {
        if (error) {
        } else {
          setListOffDaye(response.shutedDownDays);
        }
      },
      {
        start_date: start,
        end_date: end,
      }
    );
  };

  return { listOffDaye, getListOffDays };
};
