import { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import stores from "../data/stores";

const AppHistory = withRouter(() => {
  const h = useHistory();

  useEffect(() => {
    stores.AppRouterStore.pushHistory(h.location);
  });

  return null;
});

const goBackUseHistory = (history, replacement = "") => {
  if (stores.AppRouterStore.canGoBack()) {
    history.goBack();
  } else {
    history.replace("/" + replacement);
  }
};

export { AppHistory, goBackUseHistory };
