import React from "react";
import { FormControl, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";

const SelectForm = ({
  options,
  value = "",
  isDisabled,
  onchange,
  ...props
}) => {
  const OptionsOutPut = options.map((option, i) => (
    <MenuItem key={i} style={MenuItemStyles} value={option.key || i}>
      {option.option}
    </MenuItem>
  ));

  return (
    <>
      <FormControl fullWidth>
        <Select
          disabled={isDisabled}
          value={value}
          variant="outlined"
          margin={"dense"}
          style={props.styles ?? SelectStyles}
          onChange={(e) => onchange(e.target.value)}
        >
          {OptionsOutPut}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectForm;

const SelectStyles = { fontSize: 13, marginTop: 10 };
const MenuItemStyles = { fontSize: 13 };
