import { Box, Input, InputAdornment } from "@mui/material";
import Search from "../../packages/icons/Search";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../Store/slice/sliceNewRequest";
const Searchbar = memo(() => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
      <Input
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: { xs: "3px", sm: "5px" },
          border: "1px solid #0000001F",
          padding: { xs: "8px 0 8px 10px", sm: "8px 10px 8px 10px" },
          borderRadius: "8px",
          fontSize: 12,
          "&.MuiInputBase-root .MuiInputBase-input::placeholder": {
            fontSize: { xs: 11, sm: 12 },
          },

          "&.MuiInputBase-root.MuiInput-root::before": {
            borderBottom: "unset !important",
          },

          "&.MuiInputBase-root.MuiInput-root::after": {
            borderBottom: "unset",
          },
        }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setTimeout(() => {
            dispatch(setSearchQuery(e.target.value));
          }, 2500);
        }}
        type="text"
        startAdornment={
          <InputAdornment
            position="start"
            sx={{ pointerEvents: "none", width: { xs: 12, sm: 17 } }}
          >
            <Search />
          </InputAdornment>
        }
        placeholder="نام مشتری، شماره همراه، کدپذیرش یا... را جستجو کنید"
      />
    </Box>
  );
});

export default Searchbar;
