import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeAllMobileFilters,
  removeCreatedAt,
  setBranch,
  setMutualDate,
  setResolved,
  setUnActiveFilterDate,
} from "../../Store/slice/sliceContactUs";
import SelectItem from "../../components/main/contact-us/SelectItem";
import FilterDate from "./FilterDate";
import DateSelection from "./DateSelection";
import DatepickerContactus from "./DatepickerContactus";
import MobileFilter from "../../components/common/filterMobile/MobileFilter";
import AccordionContactus from "./AccordionContactus";
import { useEventHandler } from "../../Hooks/contactus/useEventHandlers";
const Header = () => {
  const dispatch = useDispatch();
  const { activeFilterDate, mutualDate, filteredButton, resolved } =
    useSelector((state) => state.contactUs);
  const maxWidth330 = useMediaQuery("(max-width: 330px)");
  const {
    handleBranchChange,
    handleStatusChange,
    handleDate,
    handleDeleteIndividualFilter,
  } = useEventHandler();

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        sx={{ paddingTop: { lg: 1, md: 2 }, alignItems: "center" }}
      >
        <Grid item xs={12}>
          <MobileFilter
            filteredButtons={filteredButton}
            customAccordionContainer={<AccordionContactus />}
            hasObject={true}
            onHandleRemoveFilters={() => {
              dispatch(removeAllMobileFilters());
              dispatch(setResolved("all"));
              dispatch(setBranch(""));
            }}
            onClickFilteredBtn={handleDeleteIndividualFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
              flexDirection: "column",
              mb: "20px",
            }}
          >
            <Box sx={{ "& div:first-child": { width: "100%" } }}>
              <DatepickerContactus onChange={handleDate} value={mutualDate} />
            </Box>
            <Button
              disabled={!activeFilterDate}
              disableElevation
              variant="contained"
              sx={[
                {
                  display: "flex",
                  alignItems: "center",
                  gap: "11px",
                  color: "#EF5350",
                  bgcolor: "unset",
                  "&:hover": {
                    bgcolor: "#fafafa",
                  },
                  justifyContent: "center",
                  marginRight: "auto",
                  marginTop: -5,
                },
                maxWidth330 && {
                  "&.MuiButton-root.Mui-disabled": {
                    bgcolor: "unset !important",
                  },
                },
              ]}
              onClick={() => {
                dispatch(setUnActiveFilterDate());
                dispatch(setMutualDate(new Date().toISOString().slice(0, 10)));
                dispatch(removeCreatedAt());
              }}
            >
              <Box component="span" sx={{ fontSize: 20 }}>
                &times;
              </Box>
              حذف فیلتر تاریخ
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8} sm={12}>
          <Box sx={{ marginBottom: -6, display: { xs: "none", sm: "block" } }}>
            <DateSelection />
          </Box>
        </Grid>
        <Grid sx={{ display: { xs: "none", lg: "block" } }} item lg={4}>
          <Stack
            direction="row"
            justifyContent={"flex-end"}
            useFlexGap
            spacing={2}
          >
            <SelectItem
              label="وضعیت"
              renderValue={false}
              onChange={handleStatusChange}
              options={[
                { value: "all", title: "همه" },
                { value: true, title: "بررسی شده ها" },
                { value: false, title: "بررسی نشده ها" },
              ]}
            />

            <SelectItem
              onChange={handleBranchChange}
              label="تیم"
              renderValue={true}
              options={[
                { value: "all", title: "همه" },
                { value: "support", title: "کارشناسی" },
                { value: "tech", title: "فنی" },
                { value: "management", title: "مدیریت" },
              ]}
            />
          </Stack>
        </Grid>
        <Grid item lg={12} sx={{ display: { xs: "none", lg: "block" } }}>
          <FilterDate />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
