import React from "react";
import Button from "@material-ui/core/Button";

const BtnsStatus = ({ onClickStatus, color, className, statusIndicator }) => {
  const handlerStatusIndicator = () => {
    if (statusIndicator) return <CircularProgress size={18} color="inherit" />;
    return "وضعیت";
  };
  return (
    <Button onClick={onClickStatus} color={color} style={{ marginTop: 2 }}>
      {handlerStatusIndicator()}{" "}
    </Button>
  );
};

export default BtnsStatus;
