import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import { Styles } from "../../../values/Styles";
import RequestState from "../../common/RequestState";
import AlertDialog from "../../common/AlertDialog";
import ListItems from "../../../components/main/sellers/ListItems";
import ChangeStatusRequestDialog from "./ChangeStatusRequestDialog";
import { Chip, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { goBackUseHistory } from "../../AppHistory";

let apiClient = null;

@inject("AccountStore")
@inject("CoworkerStore")
@observer
class Coworkers extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    let pageTitle = Coworkers.getPageTitle(props.match.params.status);

    this.state = {
      status: props.match.params.status,
      pageTitle: pageTitle,
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      message: "",
      messageChangeSeller: "",
      search: "",
      showChangeStatusDialog: false,
      changeStatusUpdateIndexRequest: -1,
    };
  }

  static getPageTitle(page) {
    let pageTitle = "همکاران";
    if (page) {
      switch (page) {
        case "blocked":
          pageTitle = "مسدود شده";
          break;
        case "accepted":
          pageTitle = "تایید شده";
          break;
        case "deleted":
          pageTitle = "حذف شده";
          break;
      }
    }
    return pageTitle;
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.status !== nextProps.match.params.status) {
      let pageTitle = Coworkers.getPageTitle(nextProps.match.params.status);
      return {
        search: "",
        status: nextProps.match.params.status,
        pageTitle: pageTitle,
      };
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      this.getData(true);
    }
  }

  componentDidMount() {
    this.getData();
  }

  search(t) {
    if (this.intervalSearch) {
      clearTimeout(this.intervalSearch);
    }
    this.intervalSearch = setTimeout(() => {
      this.setState(
        {
          search: t,
          data: [],
          totalPage: 1,
          page: 1,
          message: "",
        },
        () => this.getData(true)
      );
    }, 800);
  }

  getData(ref) {
    if (this.state.sendRequest) {
      return;
    }

    if (ref) this.state.data = [];

    this.setState({
      sendRequest: true,
      sendRequestErr: false,
      changeStatusUpdateIndexRequest: -1,
    });

    let data = {};
    if (this.state.search && this.state.search.trim() !== "")
      data["search"] = this.state.search;

    apiClient.get(
      ApiHelper.getCoworkers_GET(this.state.status, "coworker"),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          if (this.state.status === "accepted")
            this.props.CoworkerStore.setCoWorkers(response);
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
          });
        }
      },
      data
    );
  }

  changeCoWorkerRequest(item, i) {
    if (this.state.changeStatusUpdateIndexRequest >= 0) {
      return;
    }

    this.setState({ changeStatusUpdateIndexRequest: i });
    let data = { sellerId: item._id };

    apiClient.post(
      ApiHelper.changeSeller_POST(
        this.props.CoworkerStore.changeCoworkerItem._id
      ),
      (error, response) => {
        if (error) {
          this.setState({
            message: response ? response.message : "خطایی رخ داده است",
            changeStatusUpdateIndexRequest: -1,
          });
        } else {
          this.props.CoworkerStore.emptyChangeSellerItem();
          this.setState({
            messageChangeSeller: response.message,
            changeStatusUpdateIndexRequest: -1,
          });
        }
      },
      data
    );
  }

  renderResult() {
    if (this.state.data.length <= 0 && this.state.status !== "admins") {
      return (
        <div style={{ margin: 10 }}>
          {`لیست همکاران ${this.state.pageTitle} خالی.`}
        </div>
      );
    }
    return (
      <div style={{ ...Styles.containerWrap900 }}>
        {this.state.data.map((item, i) => (
          <ListItems
            key={i}
            name={item.name}
            username={item.username}
            status={item.status}
            onClickEdit={() => {
              this.props.CoworkerStore.setCurrent(item);
              this.props.history.push("/add_edit_coworkers");
            }}
            onClickStatus={() => this.changeStatus(item)}
            statusIndicator={this.state.changeStatusUpdateIndexRequest === i}
          />
        ))}
      </div>
    );
  }

  changeStatus(item) {
    this.props.CoworkerStore.setCurrent(item);
    this.setState({ showChangeStatusDialog: true });
  }

  updateChangeStatus(id) {
    let i = this.state.data.findIndex((t) => t._id === id);
    this.setState(
      { showChangeStatusDialog: false, changeStatusUpdateIndexRequest: i },
      () => this.getData()
    );
  }

  addNew() {
    this.props.CoworkerStore.emptyCurrent();
    this.props.history.push("/add_edit_coworkers");
  }
  handlerClick(isAdmins) {
    if (isAdmins) return;
    this.addNew();
  }
  render() {
    return (
      <Main
        page={"coworkers/" + this.state.status}
        pageName={"همکاران - " + this.state.pageTitle}
        leftAppBarComponent={
          <Fab
            onClick={() => this.handlerClick(this.state.status === "admins")}
            variant="extended"
            size="medium"
            disabled={this.state.status === "admins"}
            color="primary"
            aria-label="add"
          >
            ایجاد
            <AddIcon />
          </Fab>
        }
        centerAppBarComponent={
          <RequestState
            error={this.state.sendRequestErr}
            retryOnPress={() => this.getData()}
            title={"در حال دریافت لیست ..."}
            request={this.state.sendRequest}
          />
        }
        context={this.props.history}
      >
        {this.state.showChangeStatusDialog && (
          <ChangeStatusRequestDialog
            history={this.props.history}
            onClose={() => this.setState({ showChangeStatusDialog: false })}
            onSuccess={(id) => {
              this.updateChangeStatus(id);
            }}
          />
        )}
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, marginBottom: 10 }}>
            <TextField
              onChange={(t) => this.search(t.target.value)}
              placeholder="جستجو ..."
              type="search"
              defaultValue={this.state.search}
              variant="standard"
              margin={"dense"}
              style={{ maxWidth: 190, margin: 20 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  this.search(ev.target.value);
                }
              }}
            />
          </div>

          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            {this.renderResult()}
          </div>
        </div>
        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
        {this.state.messageChangeSeller !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) {
                goBackUseHistory(this.props.history);
              } else {
                this.setState({ messageChangeSeller: "" });
              }
            }}
            type={"success"}
            negativeTextButton={"بستن"}
            positiveTextButton={"بازگشت"}
            question={true}
            description={this.state.messageChangeSeller}
          />
        )}
      </Main>
    );
  }
}

export default Coworkers;
