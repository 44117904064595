import React from "react";
import { Calendar, CalendarProvider } from "zaman";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";
const CalendarLeft = ({
  dateMutual,
  className,
  onSetDateMutual,
  param,
  updateStore,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMatchesTablet = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {!isMatchesTablet && (
        <div className={className}>
          <CalendarProvider
            key={new Date()}
            locale="fa"
            round="x3"
            accentColor="#2196F3"
          >
            <Calendar
              key={new Date().getMilliseconds()}
              defaultValue={new Date(dateMutual)}
              onChange={(date) => {
                onSetDateMutual(date);
                dispatch(
                  updateStore({ [param]: `${date.toISOString().slice(0, 10)}` })
                );
              }}
              weekends={[6]}
            />
          </CalendarProvider>
        </div>
      )}
    </>
  );
};

export default CalendarLeft;
