import { useEffect } from "react";
import { useState } from "react";
import { ApiClient, ApiHelper } from "../../data/remote";
let apiClient = null;

const UseGetSellers = () => {
  const [seller, setSeller] = useState([]);
  const [sellerloading, setSellerloading] = useState(false);
  apiClient = ApiClient.getInstance();
  const handlerGetSellers = (seller_status) => {
    setSellerloading(true);
    apiClient.get(
      ApiHelper.getAgents_GET(seller_status, "seller"),
      (error, response) => {
        if (error) {
          setSellerloading(false);
        } else {
          setSeller(response.records);
          setSellerloading(false);
        }
      }
    );
  };
  return [seller, handlerGetSellers, sellerloading];
};

export default UseGetSellers;
