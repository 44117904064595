import React from "react";

export default function leftVectorIcon() {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 11L3.23703 9.25269C1.8311 7.85925 1.12813 7.16252 1.01989 6.31333C0.99337 6.10527 0.99337 5.89473 1.01989 5.68667C1.12813 4.83748 1.8311 4.14075 3.23703 2.74731L5 1"
        stroke="black"
        stroke-opacity="0.87"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}
