import React from "react";
import { Dialog } from "@material-ui/core";
import { DialogContent, Box, Divider } from "@material-ui/core";
import { useStyles } from "./stylesReporting";
import CloseIcon from "@material-ui/icons/Close";

const moment = require("moment-jalaali");
moment.loadPersian({ dialect: "persian-modern" });
const ContractDialoge = ({
  handlerDialog,
  showDialog,
  createdAt,
  updatedAt,
}) => {
  const classes = useStyles();

  const handlerCreatedAt = () => {
    const formatDate = (date) => moment(date).format("dddd jD jMMMM jYYYY");
    const createdContract = formatDate(createdAt);
    const updatedContract = updatedAt ? formatDate(updatedAt) : null;

    return (
      <>
        <p>
          <span className={classes.textContract}>تاریخ ساخت قرار داد :</span>{" "}
          {createdContract}
        </p>
        {updatedContract && (
          <p>
            <span className={classes.textContract}>
              تاریخ بروز رسانی قرار داد :
            </span>
            {updatedContract}
          </p>
        )}
      </>
    );
  };

  return (
    <Dialog open={showDialog} onClose={() => handlerDialog(false)}>
      <CloseIcon
        onClick={() => handlerDialog(false)}
        className={classes.closeIconStyle}
      />
      <Divider/>
      <DialogContent>
        <Box className={classes.styleCreatedAt}>{handlerCreatedAt()}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default ContractDialoge;
