import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Watch from "../../packages/icons/Watch";
import Calendar from "../../packages/icons/Calendar";
import { useDispatch, useSelector } from "react-redux";
import {
  setCreatedAt,
  setIsRequestDateMobile,
  setReservedAt,
} from "../../Store/slice/sliceNewRequest";
const FilterDateMobile = () => {
  const [active, setActive] = useState("");
  const handleActiveTab = (param) => setActive(param);
  const maxWidth387 = useMediaQuery("(max-width: 387px)");
  const { isRequestDateMobile } = useSelector((state) => state.newRequest);
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        width: 1,
      }}
    >
      <Typography variant="caption" sx={{ fontSize: 12 }}>
        فیلتربراساس:
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: 1,
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={[
            {
              borderRadius: "0px 100px 100px 0px",
              width: 1,
              maxWidth: 160,
              fontSize: 12,
              color:
                isRequestDateMobile === "submit"
                  ? "text.primary"
                  : "text.secondary",
              gap: "10px",
              "& .MuiButton-startIcon svg path": {
                fill:
                  isRequestDateMobile === "submit" ? "#2196F3" : "#00000099",
              },

              "& .MuiButton-startIcon": {
                marginRight: "unset",
                marginLeft: "unset",
              },
            },
            maxWidth387 && { gap: "3px", padding: "5px" },
            maxWidth387 && {
              "& .MuiButton-startIcon": { display: "none" },
              bgcolor:
                isRequestDateMobile === "submit"
                  ? "rgba(25, 118, 210, 0.04)"
                  : "unset",
            },
          ]}
          onClick={() => {
            dispatch(setIsRequestDateMobile("submit"));
            dispatch(setCreatedAt());
          }}
          startIcon={<Calendar sx={[maxWidth387 && { display: "none" }]} />}
        >
          تاریخ ثبت درخواست
        </Button>
        <Button
          variant="outlined"
          sx={[
            {
              borderRadius: "100px 0px 0px 100px",
              width: 1,
              maxWidth: 160,
              fontSize: 12,
              gap: "10px",
              color:
                isRequestDateMobile === "reserve"
                  ? "text.primary"
                  : "text.secondary",
              "& .MuiButton-startIcon svg path": {
                stroke:
                  isRequestDateMobile === "reserve" ? "#2196F3" : "#00000099",
              },

              "& .MuiButton-startIcon": {
                marginRight: "unset",
                marginLeft: "unset",
              },
            },
            maxWidth387 && { gap: "3px", padding: "5px" },
            maxWidth387 && {
              "& .MuiButton-startIcon": { display: "none" },
              bgcolor:
                isRequestDateMobile === "reserve"
                  ? "rgba(25, 118, 210, 0.04)"
                  : "unset",
            },
          ]}
          startIcon={<Watch sx={[maxWidth387 && { display: "none" }]} />}
          onClick={() => {
            dispatch(setIsRequestDateMobile("reserve"));
            dispatch(setReservedAt());
          }}
        >
          تاریخ رزرو
        </Button>
      </Box>
    </Box>
  );
};

export default FilterDateMobile;
