import { observable, action, get } from "mobx";

class BrokerModel {
  _id = null;
  name = "";
  phone = "";
  location = "";
  activityType = "";
  PositiveRate = 0;
  NegativeRate = 0;
  description = "";
  status = "";
  statusCode = 0;
  createdAt = "";
  updatedAt = "";
}

export default class Brokers {
  @observable current = new BrokerModel();

  @observable carPaymentFilter = null;
  @observable brokerPaymentFilter = null;

  @action setCurrent(item) {
    this.current = JSON.parse(JSON.stringify(item));
  }

  @action emptyCurrent() {
    this.current = new BrokerModel();
  }

  @action setCarPaymentFilter(car) {
    this.carPaymentFilter = car;
  }

  @action setBrokerPaymentFilter(broker) {
    this.brokerPaymentFilter = broker;
  }

  @get getCurrent() {
    return JSON.parse(JSON.stringify(this.current));
  }
}
