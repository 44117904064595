exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ActiveStatusCircle_textContainer__2XYF2 {\n  width: 47px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.ActiveStatusCircle_DiactiveTextContainer__2iOVl {\n  width: 65px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.ActiveStatusCircle_text__3GWW9 {\n  color: #00000099;\n  font-weight: 400;\n  font-size: 16px;\n}\n.ActiveStatusCircle_activeDot__2YvxP {\n  height: 8px;\n  width: 8px;\n  background-color: #4caf50;\n  border-radius: 50%;\n  display: inline-block;\n}\n\n.ActiveStatusCircle_diActiveDot__3kD2k {\n  height: 8px;\n  width: 8px;\n  background-color: #ef5350;\n  border-radius: 50%;\n  display: inline-block;\n}\n", ""]);

// Exports
exports.locals = {
	"textContainer": "ActiveStatusCircle_textContainer__2XYF2",
	"DiactiveTextContainer": "ActiveStatusCircle_DiactiveTextContainer__2iOVl",
	"text": "ActiveStatusCircle_text__3GWW9",
	"activeDot": "ActiveStatusCircle_activeDot__2YvxP",
	"diActiveDot": "ActiveStatusCircle_diActiveDot__3kD2k"
};