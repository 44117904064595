export const timetable = [
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
];

export const timetableThursday = [
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
];

export const data_KM = [
  { lable: "کمتر از 1000 کیلومتر", data: "1000" },
  { lable: "کیلومتر 10,000 - 20,000", data: "20000,10000" },
  { lable: "کیلومتر 20,000 - 40,000", data: "40000,20000" },
  { lable: "کیلومتر 40,000 - 60,000", data: "60000,40000" },
  { lable: "کیلومتر 60,000 - 100,000", data: "100000,60000" },
  { lable: "کیلومتر 100,000 - 200,000", data: "200000,100000" },
  { lable: "بیشتر از 200,000 کیلومتر", data: "200000" },
];
