import React, { useState, useEffect, useRef } from "react";
import styles from "./brand.module.css";
import Grid from "@material-ui/core/Grid";
import Main from "../../common/UserMain";
import { Chip, Fab, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ApiHelper, ApiClient } from "../../../data/remote";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { convertNumberToEN } from "../../../language/NumberConverter";
import TextField from "@material-ui/core/TextField";
import ImageUploader from "../../common/imageUploader/imageUploader";
import AlertDialog from "../../common/AlertDialog";
import StarRater from "../../common/StarRater/StartRater";
import Editor from "../../common/Editor";

const apiClient = ApiClient.getInstance();
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  defaultButton: {
    margin: theme.spacing(0),
    padding: 0,
  },
  deleteButton: {
    margin: theme.spacing(0),
    padding: 0,
    color: "red",
  },
  deleted: {
    color: "red",
  },
}));

const Brands = () => {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [brand, setBrand] = useState({});
  const [fetchedInfoBrand, setFetchedInfoBrand] = useState({});
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [canonical, setCanonical] = useState("");
  const [qa, setQA] = useState([]);
  const [addQaMode, setAddQaMode] = useState(false);
  const [sendUpdateRequest, setSendUpdateRequest] = useState(false);
  const [madeInCountry, setMadeInCountry] = useState("");
  const [meta, setMeta] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [modelBackGround , setModelBackGround] = useState("");
  const [qaBackGround , setQaBackGround] = useState("");
  const [expertRate, setExpertRate] = useState(null);
  const [brandNameEnglish , setBrandNameEnglish] = useState("")
  const [brandBanner , setBrandBanner] = useState("")
  const [bannerText , setBannerText] = useState('');
  const joditInputRef = useRef();
  const classes = useStyles();
  useEffect(() => {
    getCategoriesList();
  }, []);

  useEffect(() => {
    getSelectedBrand();
  }, [brand]);

  useEffect(() => {
    updateSelectedBrand();
  }, [sendUpdateRequest]);

  const getCategoriesList = () => {
    setMessage("گرفتن لیست برند ها");
    apiClient.get(ApiHelper.getCategories_GET, (error, response) => {
      if (error) {
        setError(true);
      } else {
        setError(false);
        setCategories(response.records);
      }
    });
  };

  const getSelectedBrand = () => {
    if (!brand._id) return;
    setMessage("در حال دریافت اطلاعات برند");
    apiClient.get(ApiHelper.getBrandInfo(brand._id), (error, response) => {
      if (error) {
        setError(true);
        setMessage(
          error.message ?? "مشکلی در دریافت اطلاعات برند پیش آمده است"
        );
      } else {
        setError(false);
        setFetchedInfoBrand(response.brand);
        setQA(response.brand.commonQuestion ?? []);
        setDescription(response.brand.description ?? "");
        setTitle(response.brand.brandPageTitle ?? "");
        setCanonical(response.brand.brandPageCanonical ?? "");
        setMadeInCountry(response.brand.madeInCountry ?? "");
        setExpertRate(response.brand.expertRanking ?? 0);
        setMeta(response.brand.meta ?? "");
        setMessage("");
        setBrandNameEnglish(response.brand.brandNameEnglish ?? "")
        setBannerText(response.brand.bannerText ?? "")
      }
    });
  };

  const updateSelectedBrand = () => {};

  const handleBrandChange = (brand) => {
    setBrand(brand);
  };

  const handleDeleteQA = (id) => {
    setQA((state) => {
      return state.map((q) => {
        if (id === q.id || id === q._id) {
          q.deleted = !q.deleted;
        }
        return q;
      });
    });
  };

  const addBlankQA = () => {
    setQA((oldqa) => {
      return [
        ...oldqa,
        {
          id: qa.length + 1,
          title: "",
          body: "",
          deleted: false,
          addmode: true,
        },
      ];
    });
  };

  const handleQatitleChange = (value, id) => {
    setQA((oldState) => {
      return oldState.map((qa) => {
        if (qa.id === id || id === qa._id) {
          qa.title = value;
        }
        return qa;
      });
    });
  };

  const handleQaDescription = (value, id) => {
    setQA((oldState) => {
      return oldState.map((qa) => {
        if (qa.id === id || id === qa._id) {
          qa.body = value;
        }
        return qa;
      });
    });
  };

  const submitChanges = () => {
    setQA((oldState) => {
      return oldState.filter((oldQa) => !oldQa.deleted);
    });
    setMessage("در حال ارسال درخواست ویرایش");
    apiClient.post(
      ApiHelper.addBrandInfo(brand._id),
      (error, response) => {
        if (error) {
          setError(true);
          setMessage("مشکلی در ارسال درخواست ویرایش پیش آمده است");
        } else {
          setError(false);
          setMessage("با موفقیت بروزرسانی شد");
        }
      },
      {
        description: joditInputRef.current,
        madeInCountry: madeInCountry,
        commonQuestion: qa.filter((oldQa) => !oldQa.deleted),
        brandPageTitle: title,
        brandPageCanonical: canonical,
        expertRanking: expertRate,
        meta: meta,
        bannerText : bannerText,
        brandNameEnglish : brandNameEnglish
      }
    );
  };
  return (
    <>
      <Main page={"car_brand"} pageName="برند خودرو" transparentBar={true}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid lg={4} item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">برند</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={brand}
                style={{ width: "150px" }}
                onChange={(e) => handleBrandChange(e.target.value)}
              >
                {categories.length ? (
                  categories.map((category) => {
                    return (
                      <MenuItem value={category}>{category.brand}</MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={"در حال لود"}>در حال لود...</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid lg={4} item>
            <Chip
              label={
                brand._id
                  ? "میتوانید تغییرات ایجاد کنید"
                  : "لطفا برند مورد نظر را برای ویرایش انتخاب کنید"
              }
              style={{
                backgroundColor: brand._id ? "green" : "red",
                color: "white",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="space-around"
          alignItems="flex-start"
          style={{ marginTop: 20, width: "100%" }}
        >
          <Grid item sm={12} xs={12}>
            <Typography variant="h6" component="h6">
              {`توضیحات برند : ${brand.brand ?? ""}`}
            </Typography>
            <Editor
              value={description}
              onChange={(value) => {
                joditInputRef.current = value;
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="space-around"
          alignItems="flex-start"
          style={{ marginTop: 20 }}
        >
          <Grid item lg={12} style={{ width: "100%" }}>
            <Typography variant="h6" component="h6">
              {`سوالات متداول برای برند `}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="space-around"
          alignItems="baseline"
          style={{ marginTop: 20 }}
        >
          <Grid item lg={3}>
            <Button
              onClick={() => addBlankQA()}
              style={{ marginBottom: 10 }}
              variant="contained"
              size="small"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              اضافه کردن پرسش و پاسخ
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          {qa.length
            ? qa.map((q) => {
                return (
                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ width: "100%" }}
                    key={q.id}
                  >
                    <Grid item lg={1} style={{ padding: 0 }}>
                      <IconButton
                        aria-label="delete"
                        className={
                          q.deleted
                            ? classes.deleteButton
                            : classes.defaultButton
                        }
                        onClick={() => handleDeleteQA(q.id || q._id)}
                      >
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                    <Grid item lg={11}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <TextField
                            onChange={(t) => {
                              handleQatitleChange(
                                t.target.value,
                                q.id || q._id
                              );
                            }}
                            defaultValue={q.title ?? "وارد کنید"}
                            label={"عنوان"}
                            placeholder={"عنوان را وارد کنید"}
                            variant="outlined"
                            margin={"dense"}
                            inputProps={{
                              style: {
                                textAlign: "right",
                                direction: "ltr",
                                fontSize: 15,
                              },
                              maxLength: 200,
                            }}
                            style={{
                              margin: 5,
                              minWidth: 200,
                              flex: 1,
                              maxWidth: 350,
                              backgroundColor: "#fff",
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                ? convertNumberToEN(e.target.value).toString()
                                : "";
                            }}
                          />
                        </AccordionSummary>
                        <TextareaAutosize
                          style={{
                            width: "300px",
                            minWidth: 200,
                            flex: 1,
                            maxWidth: 350,
                            backgroundColor: "#fff",
                            marginRight: 20,
                          }}
                          aria-label="brand description"
                          minRows={10}
                          placeholder="توضیح"
                          defaultValue={q.body ?? "واردکنید"}
                          onChange={(e) => {
                            handleQaDescription(e.target.value, q.id || q._id);
                          }}
                        />
                      </Accordion>
                    </Grid>
                  </Grid>
                );
              })
            : null}
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item lg={3}>
            <Typography variant="h6" component="h6">
              {`تیتر صفحه `}
            </Typography>
            <TextField
              onChange={(t) => {
                setTitle(t.target.value);
              }}
              value={title ?? "وارد کنید"}
              label={"title"}
              placeholder={"title را وارد کنید"}
              variant="outlined"
              margin={"dense"}
              inputProps={{
                style: {
                  textAlign: "right",
                  direction: "ltr",
                  fontSize: 15,
                },
                maxLength: 100,
              }}
              style={{
                margin: 5,
                minWidth: 300,
                flex: 1,
                maxWidth: 350,
                backgroundColor: "#fff",
              }}
              onInput={(e) => {
                e.target.value = e.target.value
                  ? convertNumberToEN(e.target.value).toString()
                  : "";
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6" component="h6">
              {`  کنونیکال صفحه `}
            </Typography>
            <TextField
              onChange={(t) => {
                setCanonical(t.target.value);
              }}
              value={canonical ?? "وارد کنید"}
              label={"canonical"}
              placeholder={"canonical را وارد کنید"}
              variant="outlined"
              margin={"dense"}
              inputProps={{
                style: {
                  textAlign: "right",
                  direction: "ltr",
                  fontSize: 15,
                },
                maxLength: 200,
              }}
              style={{
                margin: 5,
                minWidth: 300,
                flex: 1,
                maxWidth: 350,
                backgroundColor: "#fff",
              }}
              onInput={(e) => {
                e.target.value = e.target.value
                  ? convertNumberToEN(e.target.value).toString()
                  : "";
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6" component="h6">
              {` کشور سازنده`}
            </Typography>
            <TextField
              onChange={(t) => setMadeInCountry(t.target.value)}
              value={madeInCountry ?? "وارد کنید"}
              label={"madeInCountry"}
              placeholder={"madeInCountry را وارد کنید"}
              variant="outlined"
              margin={"dense"}
              inputProps={{
                style: {
                  textAlign: "right",
                  direction: "ltr",
                  fontSize: 15,
                },
                maxLength: 200,
              }}
              style={{
                margin: 5,
                minWidth: 300,
                flex: 1,
                maxWidth: 350,
                backgroundColor: "#fff",
              }}
              onInput={(e) => {
                e.target.value = e.target.value
                  ? convertNumberToEN(e.target.value).toString()
                  : "";
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6" component="h6">
              {`متا`}
            </Typography>
            <TextField
              onChange={(t) => setMeta(t.target.value)}
              value={meta ?? "وارد کنید"}
              label={"meta description"}
              placeholder={"meta را وارد کنید"}
              variant="outlined"
              margin={"dense"}
              inputProps={{
                style: {
                  textAlign: "right",
                  direction: "ltr",
                  fontSize: 15,
                },
                maxLength: 200,
              }}
              style={{
                margin: 5,
                minWidth: 300,
                flex: 1,
                maxWidth: 350,
                backgroundColor: "#fff",
              }}
              onInput={(e) => {
                e.target.value = e.target.value
                  ? convertNumberToEN(e.target.value).toString()
                  : "";
              }}
            />
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6" component="h6">
              {`نام برند به انگلیسی`}
            </Typography>
            <TextField
              onChange={(t) => setBrandNameEnglish(t.target.value)}
              value={brandNameEnglish ?? "وارد کنید"}
              label={"brand english name"}
              placeholder={"brandNameEnglish را وارد کنید"}
              variant="outlined"
              margin={"dense"}
              inputProps={{
                style: {
                  textAlign: "right",
                  direction: "ltr",
                  fontSize: 15,
                },
                maxLength: 200,
              }}
              style={{
                margin: 5,
                minWidth: 300,
                flex: 1,
                maxWidth: 350,
                backgroundColor: "#fff",
              }}
              onInput={(e) => {
                e.target.value = e.target.value
                  ? convertNumberToEN(e.target.value).toString()
                  : "";
              }}
            />
          </Grid>
           <Grid item lg={3}>
            <Typography variant="h6" component="h6">
              {`توضیحات برند در بنر`}
            </Typography>
            <TextField
              onChange={(t) => setBannerText(t.target.value)}
              value={bannerText ?? "وارد کنید"}
              label={"banner text"}
              placeholder={"bannerText را وارد کنید"}
              variant="outlined"
              margin={"dense"}
              inputProps={{
                style: {
                  textAlign: "right",
                  direction: "ltr",
                  fontSize: 15,
                },
                maxLength: 200,
              }}
              style={{
                margin: 5,
                minWidth: 300,
                flex: 1,
                maxWidth: 350,
                backgroundColor: "#fff",
              }}
              onInput={(e) => {
                e.target.value = e.target.value
                  ? convertNumberToEN(e.target.value).toString()
                  : "";
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item sm={6} xs={12} style={classes.gridInput}>
            <Typography variant="h6" component="h6">
              امتیاز کارشناسان
            </Typography>
            <StarRater
              rate={expertRate}
              state="positive"
              onRateChange={(newPostiveValue) => setExpertRate(newPostiveValue)}
              name="expert-ranking"
            />
          </Grid>
        </Grid>
        {brand._id ? (
          <>
            <Grid item lg={12} style={{ width: "100%" }}>
              <Typography variant="h6" component="h6">
                {`  لوگو برای برند `}
              </Typography>
            </Grid>
            <Grid item lg={12} style={{ marginTop: 40 }}>
              <ImageUploader
                getGalleryURL={ApiHelper.getBrandImage(brand?._id)}
                uploadImageURL={ApiHelper.uploadBrandImage(brand?._id)}
                removeImageURL={ApiHelper.removeBrandImage(brand?._id)}
                urlChanged={ApiHelper.getBrandImage(brand?._id)}
                afterUploadCallBack={(imageAddress) => {
                  setBrandLogo(imageAddress);
                }}
              />
            </Grid>
          </>
        ) : null}
               {brand._id ? (
          <>
            <Grid item lg={12} style={{ width: "100%" }}>
              <Typography variant="h6" component="h6">
                {`پیش زمینه برای تمام مدل ها`}
              </Typography>
            </Grid>
            <Grid item lg={12} style={{ marginTop: 40 }}>
              <ImageUploader
                getGalleryURL={ApiHelper.getModelsBackGround(brand?._id)}
                uploadImageURL={ApiHelper.uploadModelsBackGround(brand?._id)}
                removeImageURL={ApiHelper.removeModelsBackGround(brand?._id)}
                urlChanged={ApiHelper.getModelsBackGround(brand?._id)}
                afterUploadCallBack={(imageAddress) => {
                  setModelBackGround(imageAddress);
                }}
              />
            </Grid>
          </>
        ) : null}

        {brand._id ? (
          <>
            <Grid item lg={12} style={{ width: "100%" }}>
              <Typography variant="h6" component="h6">
                {`پیش زمینه بخش پرسش و پاسخ`}
              </Typography>
            </Grid>
            <Grid item lg={12} style={{ marginTop: 40 }}>
              <ImageUploader
                getGalleryURL={ApiHelper.getQaBackGround(brand?._id)}
                uploadImageURL={ApiHelper.uploadQaBackGround(brand?._id)}
                removeImageURL={ApiHelper.removeQaBackGround(brand?._id)}
                urlChanged={ApiHelper.getQaBackGround(brand?._id)}
                afterUploadCallBack={(imageAddress) => {
                  setQaBackGround(imageAddress);
                }}
              />
            </Grid>
          </>
        ) : null}
        {brand._id ? (
          <>
            <Grid item lg={12} style={{ width: "100%" }}>
              <Typography variant="h6" component="h6">
                {`بنر برای صفحه`}
              </Typography>
            </Grid>
            <Grid item lg={12} style={{ marginTop: 40 }}>
              <ImageUploader
                getGalleryURL={ApiHelper.getBrandBanner(brand?._id)}
                uploadImageURL={ApiHelper.uploadBrandBanner(brand?._id)}
                removeImageURL={ApiHelper.removeBrandBanner(brand?._id)}
                urlChanged={ApiHelper.getBrandBanner(brand?._id)}
                afterUploadCallBack={(imageAddress) => {
                  setBrandBanner(imageAddress);
                }}
              />
            </Grid>
          </>
        ) : null}
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: 50 }}
        >
          <Grid item lg={3}>
            <Button
              onClick={() => submitChanges()}
              style={{ marginBottom: 10 }}
              variant="contained"
              size="small"
              color="primary"
            >
              ثبت تغییرات
            </Button>
          </Grid>
        </Grid>
      </Main>
      {message != "" && (
        <AlertDialog
          onClose={() => {
            setMessage("");
          }}
          type={error ? "error" : "success"}
          question={false}
          description={message}
        />
      )}
    </>
  );
};

export default Brands;
