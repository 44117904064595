import { createApi } from "@reduxjs/toolkit/query/react";
import createBaseQueryWithReauth from "../../modules/HOC/createBaseQueryWithReauth ";
import { baseURL } from "../../baseURL";

export const apiCoworkers = createApi({
  reducerPath: "coworkers",
  baseQuery: createBaseQueryWithReauth(`${baseURL}admin/coworkers/`),
  tagTypes: ["coworker"],
  endpoints: (builder) => ({
    getAllCoworkers: builder.query({
      query: ({ status, type }) => `get_all/${status}${type ? "/" + type : ""}`,
      providesTags: ["coworker"],
    }),
  }),
});

export const { useGetAllCoworkersQuery } = apiCoworkers;
