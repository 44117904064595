import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { filterMobileStyles } from "../../../styles/filterMobile";

import React from "react";
import { useDispatch } from "react-redux";

const ButtonFilterd = ({
  title,
  updateStore,
  deleteFromStore,
  firstSection,
  secondSection,
  number,
}) => {
  const dispatch = useDispatch();
  const filterMobile = filterMobileStyles();

  const handleDelete = (param) => {
    dispatch(deleteFromStore(param));
    dispatch(
      updateStore({
        [param.id < { number } ? `${firstSection}` : `${secondSection}`]: "",
      })
    );
  };

  return (
    <Button
      endIcon={<CloseIcon />}
      variant="outlined"
      className={filterMobile.selectCheckBox}
      onClick={() => handleDelete(title)}
    >
      {title.title}
    </Button>
  );
};

export default ButtonFilterd;
