import stores from "../data/stores";

const panelSectionContainer = (page) => ({
  devider: { divider: true },
  cities: { text: "شهرها", icon: "place", page: "locations" },
  carCategories: {
    text: "دسته بندی خودرو",
    icon: "list",
    page: "categories",
  },
  coworkers: {
    text: "همکاران",
    icon: "people_out_line",
    page: "",
    open: page.toString().search("coworkers") === 0,
    collapsable: [
      { text: "تایید شده", page: "coworkers/accepted" },
      // { text: "مسدود شده", page: "agents/blocked" },
      // { text: "حذف شده", page: "agents/deleted" },
    ],
  },
  agents: {
    text: "نمایندگان",
    icon: "support_agent",
    page: "",
    open: page.toString().search("agents") === 0,
    collapsable: [
      { text: "تایید شده", page: "agents/accepted" },
      { text: "مسدود شده", page: "agents/blocked" },
      { text: "حذف شده", page: "agents/deleted" },
    ],
  },

  experts: {
    text: "کارشناسان",
    icon: "engineering",
    page: "",
    open: page.toString().search("experts") === 0,
    collapsable: [
      { text: "تایید شده", page: "experts/accepted" },
      { text: "مسدود شده", page: "experts/blocked" },
      { text: "حذف شده", page: "experts/deleted" },
    ],
  },

  sellers: {
    text: "فروشندگان",
    icon: "reduce_capacity",
    page: "",
    open: page.toString().search("sellers") === 0,
    collapsable: [
      { text: "تایید شده", page: "sellers/accepted" },
      { text: "مسدود شده", page: "sellers/blocked" },
      { text: "حذف شده", page: "sellers/deleted" },
    ],
  },

  cars: {
    text: "خودروها",
    icon: "directions_car",
    page: "",
    open: page.toString().search("cars") === 0,
    marker:
      stores.AccountStore.notifications.carsAuctions_payment_done +
      stores.AccountStore.notifications.carsAuctions_awaiting_payment +
      stores.AccountStore.notifications.carsAuctions_negotiating +
      stores.AccountStore.notifications.carsAuctions_live +
      stores.AccountStore.notifications.carsAuctions_live_ended +
      stores.AccountStore.notifications.carsAuctions_depot,
    collapsable: [
      { text: "ایجاد شده", page: "cars/created/all" },
      {
        text: "کارشناسی",
        page: "cars/accepted/in_queue",
        marker: stores.AccountStore.notifications.carsAuctions_inqueue,
      },
      {
        text: "مزایده زنده",
        page: "cars/accepted/live",
        marker: stores.AccountStore.notifications.carsAuctions_live,
      },
      {
        text: "مزایده پایان یافته",
        page: "cars/accepted/live_ended",
        marker: stores.AccountStore.notifications.carsAuctions_live_ended,
      },
      {
        text: "دپو",
        page: "cars/accepted/depot",
        marker: stores.AccountStore.notifications.carsAuctions_depot,
      },
      { text: "عمومی", page: "cars/accepted/public" },
      {
        text: "درحال مذاکره",
        page: "cars/accepted/negotiating",
        marker: stores.AccountStore.notifications.carsAuctions_negotiating,
      },
      {
        text: "تسویه نشده",
        page: "cars/accepted/awaiting_payment",
        marker: stores.AccountStore.notifications.carsAuctions_awaiting_payment,
      },
      {
        text: "پرداخت شده",
        page: "cars/accepted/payment_done",
        marker: stores.AccountStore.notifications.carsAuctions_payment_done,
      },
      { text: "تمام شده", page: "cars/accepted/ended" },
      { text: "حذف شده", page: "cars/deleted/all" },
    ],
  },

  brokers: {
    text: "بروکرها",
    icon: "groups",
    page: "",
    open: page.toString().search("brokers") === 0,
    collapsable: [
      { text: "آنلاین ها", page: "brokers/online" },
      { text: "تایید شده", page: "brokers/accepted" },
      { text: "مسدود شده", page: "brokers/blocked" },
      { text: "آمارگیری", page: "brokerss/reporting" },
    ],
  },

  timeManagement: {
    text: "  مدیریت زمان ",
    icon: "access_time",
    page: "manage_req",
    open: page.toString().search("manage_req") === 0,
    marker: null, //stores.accounterstore "as an example"
  },

  receits: {
    text: "پرداخت ها",
    icon: "receipt_long",
    page: "",
    open: page.toString().search("payments") === 0,
    collapsable: [
      { text: "ایجاد شده", page: "payments/created" },
      { text: "در انتظار", page: "payments/awaiting" },
      { text: "انجام شده", page: "payments/done" },
      { text: "لغو شده", page: "payments/canceled" },
    ],
  },

  requests: {
    text: "درخواست ها",
    icon: "list",
    page: "",
    open: page.toString().search("requests") === 0,
    marker:
      stores.AccountStore.notifications.userRequestsSell_reserved +
      stores.AccountStore.notifications.userRequestsBuy_verified,
    collapsable: [
      {
        text: "خرید ایجاد شده",
        page: "requests/created/buy",
        marker: stores.AccountStore.notifications.userRequestsBuy_verified,
        marker_2: stores.AccountStore.notifications.userRequestsBuy,
      },
      { text: "خرید انجام شده", page: "requests/done/buy" },
      {
        text: "فروش ایجاد شده",
        page: "requests/created/sell",
        marker: stores.AccountStore.notifications.userRequestsSell_reserved,
        marker_2: stores.AccountStore.notifications.userRequestsSell,
      },
      { text: "فروش انجام شده", page: "requests/done/sell" },
      { text: "درخواست های لغو شده", page: "requests/canceled/sell" },
    ],
  },

  registerRequests: {
    text: "درخواست‌های ثبت نام",
    icon: "how_to_reg",
    page: "",
    open: page.toString().search("registrations") === 0,
    marker: stores.AccountStore.notifications.regRequests,
    collapsable: [
      {
        text: "ثبت نام - جدید",
        page: "registrations/created",
        marker: stores.AccountStore.notifications.regRequests,
      },
      {
        text: "ثبت نام - انجام شده",
        page: "registrations/done",
      },
      {
        text: "ثبت نام - حذف شده",
        page: "registrations/deleted",
      },
    ],
  },

  carPrices: {
    text: "خودرو و قیمت",
    icon: "local_car_wash",
    page: "car_price",
    open: page.toString().search("car_price") === 0,
    marker: null, //stores.accounterstore "as an example"
  },
  systemLogs: {
    text: "لاگ های سیستم",
    icon: "policy_icon",
    page: "logs",
    open: page.toString().search("logs") === 0,
    marker: null, //stores.accounterstore "as an example"
  },
  contracts: {
    text: "قرارد داد ها",
    icon: "article",
    page: "contracts",
    open: page.toString().search("contracts") === 0,
    marker: null, //stores.accounterstore "as an example"
    collapsable: [
      {
        text: "مبایعه نامه",
        page: "contracts/affidavit",
      },
      {
        text: "کارشناسی خودرو",
        page: "contracts/critic",
      },
      {
        text: "درخواست برگزاری مزایده خودرو",
        page: "contracts/request_auction",
      },
      {
        text: "رسید تحویل خودرو و مدرک",
        page: "contracts/delivery_receipt",
      },
      {
        text: "رسید دریافت خودرو و مدرک",
        page: "contracts/receive_receipt",
      },
      {
        text: "فرم نظر سنجی آمر خرید",
        page: "contracts/purchase_order_survey_form",
      },
      {
        text: "فرم نظر سنجی آمر فروش",
        page: "contracts/sales_order_survey_form",
      },
      {
        text: "قرارداد ارائه خدمات خودرو",
        page: "contracts/car_service_contract",
      },
      {
        text: "آمارگیری",
        page: "contracts/reporting",
      },
    ],
  },

  brands: {
    text: "برند ها",
    icon: "receipt_long",
    page: "brands",
    open: page.toString().search("brands") === 0,
  },

  backups: { text: "بکاپ داده", icon: "cloud_done", page: "backup" },

  errors: {
    text: "خطاها",
    icon: "errors",
    page: "errors",
    marker: stores.AccountStore.notifications.errors,
  },
});

export default panelSectionContainer;
