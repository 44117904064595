import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../baseURL";
import createBaseQueryWithReauth from "../../modules/HOC/createBaseQueryWithReauth ";

export const DataListAdmins = createApi({
  baseQuery: createBaseQueryWithReauth(baseURL + "admin"),
  tagTypes: ["listAdmins"],
  endpoints: (builder) => ({
    getAdmins: builder.query({
      query: (status) => `/admins/get_all/${status}`,
      providesTags: ["listAdmins"],
    }),
    changeAdminStatus: builder.mutation({
      query: ({ adminId, status }) => ({
        url: `/admins/change_status/${adminId}/${status}`,
        method: "POST",
      }),
      invalidatesTags: ["listAdmins"],
    }),

    getOnlyAdmin: builder.query({
      query: (adminId) => ({
        url: `/admins/${adminId}`,
      }),
      invalidatesTags: ["listAdmins"],
    }),
    deleteAdmin: builder.mutation({
      query: (adminId) => ({
        url: `/admins/delete_admin/${adminId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["listAdmins"],
    }),

    createAdmin: builder.mutation({
      query: (body) => ({
        url: `/admins/create_admin`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["listAdmins"],
    }),

    updateAdmin: builder.mutation({
      query: ({ adminID, body }) => ({
        url: `/admins/update_admin/${adminID}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["listAdmins"],
    }),

    grantPermission: builder.mutation({
      query: ({ id, body }) => ({
        url: `/sub-permission/grant-permission/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["listAdmins"],
    }),

    removePermission: builder.mutation({
      query: ({ id, body }) => ({
        url: `/sub-permission/remove-permission/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["listAdmins"],
    }),

    updateAgentStatus: builder.mutation({
      query: ({ agentID, shutDown }) => {
        return {
          url: `/agents/shut_down/${agentID}`,
          method: "PATCH",
          body: shutDown,
        };
      },
      invalidatesTags: ["listAdmins"],
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useCreateAdminMutation,
  useGrantPermissionMutation,
  useRemovePermissionMutation,
  useUpdateAdminMutation,
  useGetOnlyAdminQuery,
  useDeleteAdminMutation,
  useChangeAdminStatusMutation,
  useUpdateAgentStatusMutation,
} = DataListAdmins;
