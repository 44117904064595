import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import { Styles } from "../../../values/Styles";
import RequestState from "../../common/RequestState";
import AlertDialog from "../../common/AlertDialog";
import PaginationView from "../../common/PaginationView";
import ListItems from "./ListItems";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import ChangeStatusDialog from "./ChangeStatusDialog";
import { reaction } from "mobx";
import Button from "@material-ui/core/Button";
import DatePickerCom from "../../common/DatePicker/DatePicker";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import RequestsBtns from "../../../features/Requests/RequestsBtns";
import SetAgentForRequestsModule from "../../../features/Requests/AgentForRequests/SetAgentForRequestsModule";
import styles from "./requests.module.css";

import "./user_req.css";
let apiClient = null;

@inject("AccountStore")
@inject("RequestsStore")
@observer
class Requests extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    let pageTitle = Requests.getPageTitle(
      props.match.params.status,
      props.match.params.type
    );
    this.state = {
      status: props.match.params.status,
      type: props.match.params.type,
      reservedFilter: true,
      pageTitle: pageTitle,
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      totalPage: 1,
      totalItems: 0,
      page: props.match.params.page ? parseInt(props.match.params.page) : 1,
      message: "",
      changeStatusUpdateIndexRequest: -1,
      showStatusDialog: false,
      search: "",
      showUpdateRequest: false,
      resetSearch: false,
      resetSearchBaseReserve: false,
      inprogress: false,
      hasAdminAccess: false,
    };

    this.reaction = reaction(
      () => this.props.AccountStore.notificationsUpdateKey,
      () => this.updateInfo()
    );
  }

  componentWillUnmount() {
    if (this.reaction) this.reaction();
  }

  search(t) {
    if (this.intervalSearch) {
      clearTimeout(this.intervalSearch);
    }
    this.intervalSearch = setTimeout(() => {
      this.setState(
        {
          search: t,
          data: [],
          totalPage: 1,
          page: 1,
          message: "",
          resetSearchBaseReserve: false,
        },
        () => this.getRequests(true)
      );
    }, 800);
  }

  searchBaseOnReserve(t) {
    let data = {};
    data["search"] = t;

    apiClient.get(
      ApiHelper.getReservedRequests_GET(
        this.state.type,
        this.state.status,
        this.state.page,
        this.state.reservedFilter
      ),
      (error, response) => {
        console.log("res**: ", response);
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
            resetSearch: false,
          });
        } else {
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
            totalPage: response.totalPage,
            resetSearch: false,
            hasAdminAccess: response.hasAdminAccess,
          });
        }
      },
      data
    );
  }

  updateInfo() {
    this.setState({ showUpdateRequest: true });
  }

  static getPageTitle(status, type) {
    let pageTitle = "درخواست ها";

    if (status && type) {
      switch (status + "/" + type) {
        case "created/buy":
          pageTitle = "خرید / ایجاد شده";
          break;
        case "done/buy":
          pageTitle = "خرید / انجام شده";
          break;
        case "created/sell":
          pageTitle = "فروش / ایجاد شده";
          break;
        case "done/sell":
          pageTitle = "فروش / انجام شده";
          break;
      }
    }
    return pageTitle;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.status !== nextProps.match.params.status ||
      prevState.type !== nextProps.match.params.type
    ) {
      let pageTitle = Requests.getPageTitle(
        nextProps.match.params.status,
        nextProps.match.params.type
      );

      return {
        page: 1,
        pageTitle: pageTitle,
        status: nextProps.match.params.status,
        type: nextProps.match.params.type,
        sendRequest: false,
        sendRequestErr: false,
        sendRequestStatus: "",
        data: [],
        totalPage: 1,
        reservedFilter: true,
        showCreateCarDialog: false,
      };
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.status !== this.state.status ||
      prevState.type !== this.state.type
    ) {
      this.getRequests(true);
    }
  }

  componentDidMount() {
    this.getRequests();
  }

  getRequests(ref) {
    if (this.state.sendRequest) {
      return;
    }

    if (ref) {
      this.state.data = [];
      this.state.page = 1;
      this.state.totalPage = 1;
    }

    this.props.history.replace(
      "/requests/" +
        this.state.status +
        "/" +
        this.state.type +
        "/" +
        this.state.page
    );

    this.setState({
      sendRequest: true,
      sendRequestErr: false,
      changeStatusUpdateIndexRequest: -1,
    });

    let data = {};

    if (this.state.search && this.state.search?.trim() !== "")
      data["search"] = this.state.search;
    let status = this.state.status;
    if (this.state.inprogress) {
      status = "in_progress";
    }
    apiClient.get(
      ApiHelper.getRequests_GET(
        this.state.type,
        status,
        this.state.page,
        this.state.reservedFilter,
        this.state.inprogress
      ),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
            totalPage: response.totalPage,
            totalItems: response.totalItems,
            hasAdminAccess: response.hasAdminAccess,
          });
        }
      },
      data
    );
  }
  resetSearchCom() {
    this.setState({ search: "" });
    setTimeout(() => {
      this.setState({ resetSearch: false });
      this.getRequests(true);
    }, 300);
  }

  resetSearchBaseReserve() {
    this.setState({ search: "" });
    setTimeout(() => {
      this.setState({ resetSearchBaseReserve: false });
      this.getRequests(true);
    }, 300);
  }
  renderResult() {
    if (this.state.data.length <= 0) {
      return (
        <div style={{ margin: 10 }}>
          {`لیست درخواست ها  ${this.state.pageTitle} خالی.`}
        </div>
      );
    }
    return (
      <div style={{ ...Styles.containerWrap900, padding: 10 }}>
        {this.state.data.map((item, i) => (
          <ListItems
            key={item._id}
            phone={item.phone}
            name={item.name}
            createdAt={item.createdAt}
            onClickStatus={() => this.setState({ showStatusDialog: item._id })}
            status={item.status}
            type={item.type}
            sellCarInfo={item.sellCarInfo}
            verifiedPhone={item.verifiedPhone}
            statusIndicator={this.state.changeStatusUpdateIndexRequest === i}
            userDiscount={item.userDiscount}
            customDiscount={item?.customDiscount}
            userReceptionCode={item.userReceptionCode}
            clerkSellRequest={item.clerkSellRequest ?? ""}
            agentName={item?.reserveLocation?.name || "وارد نشده"}
          />
        ))}
      </div>
    );
  }

  updateChangeStatus(item) {
    let i = this.state.data.findIndex((t) => t._id === item._id);
    this.setState(
      { showStatusDialog: false, changeStatusUpdateIndexRequest: i },
      () => this.getRequests()
    );
  }

  render() {
    console.log("**: ", !this.state.reservedFilter && !this.state.inprogress);
    return (
      <Main
        page={"requests/" + this.state.status + "/" + this.state.type}
        pageName={"درخواست ها - " + this.state.pageTitle}
        centerAppBarComponent={
          this.state.showUpdateRequest && this.state.status === "created" ? (
            <Button
              onClick={() => {
                this.setState({ showUpdateRequest: false });
                this.getRequests();
              }}
              variant="contained"
              color="secondary"
            >
              درخواست جدید رسید ! بروزرسانی
            </Button>
          ) : (
            <RequestState
              error={this.state.sendRequestErr}
              retryOnPress={() => this.getRequests()}
              title={"در حال دریافت لیست ..."}
              request={this.state.sendRequest}
            />
          )
        }
        context={this.props.history}
      >
        {this.state.hasAdminAccess && <SetAgentForRequestsModule />}
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, marginBottom: 10 }}>
            <TextField
              onChange={(t) => this.search(t.target.value)}
              placeholder="جستجو ..."
              type="search"
              defaultValue={this.state.search}
              variant="standard"
              margin={"dense"}
              style={{ maxWidth: 190, margin: 20 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  this.search(ev.target.value);
                }
              }}
            />

            <div style={{ display: "flex", flexWrap: "wrap", margin: 10 }}>
              <RequestsBtns
                onclick={() =>
                  this.setState(
                    { reservedFilter: true, inprogress: false },
                    () => this.getRequests(true)
                  )
                }
                variant={
                  this.state.reservedFilter && !this.state.inprogress
                    ? "contained"
                    : "outlined"
                }
                text={
                  this.state.type === "sell" ? "رزرو شده ها" : "تایید شده ها"
                }
                btnInfo={{
                  type: this.state.type, //persist sell buy
                  status: this.state.status, //persist created done
                  page: this.state.page, //persist
                  reservedFilter: 1,
                  inprogress: 0,
                }}
                inputStyle={styles.btnBadgeStyle}
              />
              <RequestsBtns
                onclick={() =>
                  this.setState(
                    { reservedFilter: false, inprogress: false },
                    () => this.getRequests(true)
                  )
                }
                variant={
                  !this.state.reservedFilter && !this.state.inprogress
                    ? "contained"
                    : "outlined"
                }
                text={this.state.type === "sell" ? "رزرو نشده ها" : "مجهول ها"}
                btnInfo={{
                  type: this.state.type,
                  status: this.state.status,
                  page: this.state.page,
                  reservedFilter: 0,
                  inprogress: 0,
                }}
                inputStyle={styles.btnBadgeStyle}
              />
              {this.state.type === "sell" ? (
                <RequestsBtns
                  onclick={() =>
                    this.setState(
                      {
                        inprogress: true,
                        reservedFilter: true,
                        status: "in_progress",
                      },
                      () => {
                        this.getRequests(true);
                      }
                    )
                  }
                  variant={this.state.inprogress ? "contained" : "outlined"}
                  text={"در حال پیگیری"}
                  btnInfo={{
                    type: this.state.type,
                    status: "in_progress",
                    page: this.state.page,
                    reservedFilter: 0,
                    inprogress: 1,
                  }}
                  inputStyle={styles.btnBadgeStyle}
                />
              ) : null}
            </div>
          </div>
          <div className="date_pickers_holder">
            <div className="date_picker_container">
              <div className="date_picker_intro">
                <Icon size={16} style={{ color: "black" }}>
                  edit_note
                </Icon>
                <p>جستجو بر اساس تاریخ</p>
                <b style={{ marginRight: "1px" }}>ثبت درخواست</b>
              </div>
              <div>
                {!this.state.resetSearch && (
                  <DatePickerCom
                    onchange={(e) => {
                      this.search(e.value.toISOString());
                      this.setState({ search: true });
                    }}
                  />
                )}
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    this.setState({ resetSearch: true });
                    this.resetSearchCom();
                  }}
                >
                  <Clear />
                </IconButton>
              </div>
            </div>
            <div className="date_picker_container">
              <div className="date_picker_intro">
                <Icon size={16} style={{ color: "blue" }}>
                  fact_check
                </Icon>
                <p> جستجو بر اساس تاریخ</p>
                <b style={{ marginRight: "1px" }}> رزرو</b>
              </div>
              <div>
                {!this.state.resetSearchBaseReserve && (
                  <DatePickerCom
                    onchange={(e) => {
                      this.searchBaseOnReserve(e.value.toISOString());
                      this.setState({ resetSearch: true });
                    }}
                  />
                )}
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    this.setState({ resetSearchBaseReserve: true });
                    this.resetSearchBaseReserve();
                  }}
                >
                  <Clear />
                </IconButton>
              </div>
            </div>
          </div>
          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            {this.renderResult()}
          </div>
        </div>

        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <PaginationView
            total={parseInt(this.state.totalPage)}
            page={parseInt(this.state.page)}
            nextOnPress={() => {
              this.state.page += 1;
              this.getRequests();
            }}
            prevOnPress={() => {
              this.state.page -= 1;
              this.getRequests();
            }}
          />
        </div>

        {this.state.showStatusDialog && (
          <ChangeStatusDialog
            id={this.state.showStatusDialog}
            history={this.props.history}
            onClose={() => this.setState({ showStatusDialog: false })}
            onSuccess={(item) => {
              this.updateChangeStatus(item);
            }}
            isUnknown={!this.state.reservedFilter && !this.state.inprogress}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </Main>
    );
  }
}

export default Requests;
