import { fabClasses } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import { stat } from "fs-extra";
const initialState = {
  mutualDate: new Date().toISOString().slice(0, 10),
  activeFilterDate: "",
  createdAt: "",
  resolved: "",
  showCalendar: false,
  currentPage: 1,
  branch: "",
  status: "",
  checkedItems: [],
  isSelectAll: false,
  filteredButton: [{ message: "همه پیام ها" }, { team: "همه تیم ها" }],
};

const sliceContactUs = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    setMutualDate: (state, action) => {
      state.mutualDate = action.payload;
      state.createdAt = action.payload;
    },
    setActiveFilterDate: (state) => {
      state.activeFilterDate = "submit";
    },

    setUnActiveFilterDate: (state) => {
      state.activeFilterDate = "";
    },

    setShowCalendar: (state) => {
      state.showCalendar = true;
    },

    removeShowCalendar: (state) => {
      state.showCalendar = false;
    },

    setPage: (state, action) => {
      state.currentPage = action.payload;
    },

    removeCreatedAt: (state) => {
      state.createdAt = "";
    },

    setBranch: (state, action) => {
      state.branch = action.payload;
    },

    setResolved: (state, action) => {
      state.resolved = action.payload;
    },

    pushIds: (state, action) => {
      const hasAlready = state.checkedItems.some(
        (item) => item === action.payload
      );
      if (hasAlready) return;
      state.checkedItems.push(action.payload);
      state.isSelectAll = true;
    },

    cleanIds: (state) => {
      state.checkedItems = initialState.checkedItems;
      state.isSelectAll = false;
    },

    removeIds: (state, action) => {
      state.checkedItems = state.checkedItems.filter(
        (item) => item !== action.payload
      );
    },
    pushIndividualId: (state, action) => {
      state.checkedItems.push(action.payload);
    },

    addMessageToFilteredButton: (state, action) => {
      if (
        state.filteredButton.some(
          (item) => item.message === action.payload.message
        )
      )
        return;
      const newState = state.filteredButton.filter((item) => !item.message);
      state.filteredButton = [...newState, action.payload];
    },

    addTeamToFilteredButton: (state, action) => {
      if (
        state.filteredButton.some((item) => item.team === action.payload.team)
      )
        return;

      const newState = state.filteredButton.filter((item) => !item.team);
      state.filteredButton = [...newState, action.payload];
    },

    removeAllMobileFilters: (state) => {
      state.filteredButton = initialState.filteredButton;
    },

    removeMessage: (state) => {
      const newState = state.filteredButton.filter((item) => !item.message);

      state.filteredButton = [...newState, { message: "همه پیام ها" }];
    },

    removeTeam: (state) => {
      const newState = state.filteredButton.filter((item) => !item.team);
      state.filteredButton = [...newState, { team: "همه تیم ها" }];
    },
  },
});

export const {
  setMutualDate,
  setActiveFilterDate,
  setUnActiveFilterDate,
  setShowCalendar,
  removeShowCalendar,
  setPage,
  removeCreatedAt,
  setBranch,
  setResolved,
  pushIds,
  cleanIds,
  removeIds,
  pushIndividualId,
  addMessageToFilteredButton,
  addTeamToFilteredButton,
  removeAllMobileFilters,
  removeMessage,
  removeTeam,
} = sliceContactUs.actions;
export default sliceContactUs.reducer;
