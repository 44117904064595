import { useState } from "react";
import { ApiClient, ApiHelper } from "../../data/remote";
let apiClient = null;

const useGetRequestTotalItems = (requestInfo) => {
  const { type, status, page, reservedFilter, inprogress } = requestInfo;
  const [totalItems, setTotalItems] = useState(0);
  apiClient = ApiClient.getInstance();
  let data = {};
  apiClient.get(
    ApiHelper.getRequests_GET(type, status, page, reservedFilter, inprogress),
    (error, response) => {
      if (error) {
        //logic for handling error in here
      } else {
        setTotalItems(response.totalItems);
      }
    },
    data
  );

  return totalItems;
};

export default useGetRequestTotalItems;
