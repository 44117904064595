import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import { Styles } from "../../../values/Styles";
import RequestState from "../../common/RequestState";
import AlertDialog from "../../common/AlertDialog";
import {
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { arrayMove, List } from "react-movable";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";

let apiClient = null;

@inject("AccountStore")
@inject("CarsStore")
@observer
class Categories extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    this.state = {
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      message: "",
      alertDialogQuestionMessage: "",
      deleteRequestIndex: -1,
      bestSellersRequestIndex: -1,
    };

    this.alertFunction = null;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    if (this.state.sendRequest) {
      return;
    }

    this.setState({ sendRequest: true, sendRequestErr: false });

    apiClient.get(ApiHelper.getCategories_GET, (error, response) => {
      if (error) {
        this.setState({
          sendRequest: false,
          sendRequestErr: true,
          message: response ? response.message : "",
        });
      } else {
        this.props.CarsStore.setCategories(response.records);
        this.setState({ sendRequest: false, sendRequestErr: false });
      }
    });
  }

  remove(id) {
    let index = this.props.CarsStore.categories.findIndex((t) => t._id === id);
    this.alertFunction = this.removeRequest.bind(this, id, index);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به حذف دسته هستید ؟",
    });
  }

  removeRequest(id, index) {
    if (this.state.deleteRequestIndex !== -1) return;

    this.setState({ deleteRequestIndex: id });

    apiClient.get(ApiHelper.removeCategory_GET(id), (error, response) => {
      if (error) {
        this.setState({ deleteRequestIndex: -1, message: "حذف شهر ناموفق" });
      } else {
        if (response.success) {
          this.props.CarsStore.categories.splice(index, 1);
          this.setState({ deleteRequestIndex: -1 });
        } else {
          this.setState({ deleteRequestIndex: -1, message: "حذف شهر ناموفق" });
        }
      }
    });
  }

  updateBestsellers(item) {
    if (this.state.bestSellersRequestIndex !== -1) return;

    this.setState({ bestSellersRequestIndex: item._id });

    apiClient.post(
      ApiHelper.updateCategoryBestsellers_POST,
      (error, response) => {
        if (error) {
          this.setState({
            bestSellersRequestIndex: -1,
            message: "حذف شهر ناموفق",
          });
        } else {
          if (response.success) {
            item.bestsellers = response.status;
            this.props.CarsStore.updateCategoryItem(item);
            this.setState({ bestSellersRequestIndex: -1 });
          } else {
            this.setState({
              bestSellersRequestIndex: -1,
              message: "حذف شهر ناموفق",
            });
          }
        }
      },
      {
        _id: item._id,
        status: !item.bestsellers,
      }
    );
  }

  updatePositionRequest(categories) {
    let data = [];
    categories.slice().forEach((v) => data.push(v._id));
    apiClient.post(
      ApiHelper.updateCategoriesPosition_POST,
      (error, response) => {},
      { categories: data }
    );
  }

  renderResult() {
    if (this.props.CarsStore.categories.length <= 0) {
      return <div style={{ margin: 10 }}>{`لیست دسته ها خالی.`}</div>;
    }
    return (
      <TableContainer>
        <List
          values={this.props.CarsStore.categories}
          onChange={({ oldIndex, newIndex }) => {
            this.props.CarsStore.categories = arrayMove(
              this.props.CarsStore.categories,
              oldIndex,
              newIndex
            );
            this.updatePositionRequest(this.props.CarsStore.categories);
          }}
          renderList={({ children, props }) => (
            <Table
              style={{
                border: "1px solid #d2d2d2",
                minWidth: 200,
                maxWidth: 450,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: "#000", fontWeight: 600 }}>
                    برند
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody {...props}>{children}</TableBody>
            </Table>
          )}
          renderItem={({ value, props }) => (
            <TableRow {...props}>
              <TableCell style={{ flex: 1, cursor: "move" }} align="left">
                {" "}
                <Icon
                  style={{
                    fontSize: 18,
                    margin: "5px -5px -5px 5px",
                    cursor: "move",
                  }}
                >
                  drag_indicator
                </Icon>{" "}
                {value.brand}{" "}
              </TableCell>
              <TableCell style={{ width: 200 }} align="right">
                <Button
                  onClick={() => this.edit(value)}
                  size="small"
                  variant="text"
                  color="primary"
                >
                  ویرایش
                </Button>

                <Button
                  onClick={() => this.remove(value._id)}
                  size="small"
                  variant="text"
                  color="secondary"
                >
                  {this.state.deleteRequestIndex === value._id ? (
                    <CircularProgress size={16} style={{ margin: 0 }} />
                  ) : (
                    "حذف"
                  )}
                </Button>
              </TableCell>

              <TableCell style={{ width: 50 }} align="right">
                <FormControl component="fieldset">
                  <FormGroup row>
                    <FormControlLabel
                      checked={value.bestsellers}
                      onChange={(e) => {
                        this.updateBestsellers(value);
                      }}
                      control={
                        this.state.bestSellersRequestIndex === value._id ? (
                          <CircularProgress size={16} style={{ margin: 10 }} />
                        ) : (
                          <Checkbox color="primary" />
                        )
                      }
                      label={<p style={{ fontSize: 12 }}>پرفروش</p>}
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
              </TableCell>
            </TableRow>
          )}
        />
      </TableContainer>
    );
  }

  edit(item) {
    this.props.CarsStore.setCurrentCategory(item);
    this.props.history.push("/add_edit_category");
  }

  addNew() {
    this.props.CarsStore.emptyCurrentCategory();
    this.props.history.push("/add_edit_category");
  }

  render() {
    return (
      <Main
        page={"categories"}
        pageName={"دسته بندی خودرو"}
        leftAppBarComponent={
          <Fab
            onClick={() => this.addNew()}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            ایجاد
            <AddIcon />
          </Fab>
        }
        centerAppBarComponent={
          <RequestState
            error={this.state.sendRequestErr}
            retryOnPress={() => this.getData()}
            title={"در حال دریافت لیست ..."}
            request={this.state.sendRequest}
          />
        }
        context={this.props.history}
      >
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            {this.renderResult()}
          </div>
        </div>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}

        {this.state.alertDialogQuestionMessage !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) this.alertFunction();
              this.alertFunction = null;
              this.setState({ alertDialogQuestionMessage: "" });
            }}
            type="alert"
            question={true}
            description={this.state.alertDialogQuestionMessage}
          />
        )}
      </Main>
    );
  }
}

export default Categories;
