import React, { useEffect, useState } from "react";
import { Toolbar, AppBar } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "../../../../styleConfig/usermain";
import styles from "./searchCarPrice.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";

const SearchCarPrice = ({
  brandNames,
  modelNames,
  priceModels,
  optionNames,
  searchFilterChange,
  searchFilterHandler,
  filter,
  clearSearchInputs,
  brandNameValues,
  searchLoading,
  setBrandNameValues,
  modelNamesValues,
  setModelNamesValues,
  priceNumberValue,
  setPriceNumberValues,
}) => {
  const classes = useStyles();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  //lets first implement the state of filters which we want to make
  const brandoptions = brandNames.map((option) => {
    const firstLetter = option.label[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const modeloptions = modelNames.map((option) => {
    const firstLetter = option.label[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const priceoptions = priceModels.map((option) => {
    const firstLetter = option.label[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const optionoptions = optionNames.map((option) => {
    const firstLetter = option.label[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 72);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible]);

  return (
    <AppBar
      position="sticky"
      color={"inherit"}
      className={`${styles.headerSearch} ${
        visible ? styles.headerVisible : styles.headerHidden
      }`}
    >
      <Toolbar>
        <div className={styles.toolcontainer}>
          <div className={styles.tools}>
            <div className={styles.toolbarname}>جستجو</div>
            <div className={styles.autocompleteContainer}>
              <Autocomplete
                onChange={(event, newValue) => {
                  searchFilterChange("brand", newValue?.label ?? "");
                  setBrandNameValues(newValue);
                }}
                value={brandNameValues}
                size="medium"
                sx={{ width: 400 }}
                disablePortal
                groupBy={(option) => option.firstLetter}
                id="brand_auto"
                getOptionLabel={(option) => option?.label?.toString() || ""}
                options={brandoptions}
                renderInput={(params) => <TextField {...params} label="برند" />}
              />
            </div>
            <div className={styles.autocompleteContainer}>
              <Autocomplete
                onChange={(event, newValue) => {
                  searchFilterChange("model", newValue?.label ?? "");
                  setModelNamesValues(newValue);
                }}
                value={modelNamesValues}
                size="medium"
                sx={{ width: 400 }}
                disablePortal
                id="model_auto"
                getOptionLabel={(option) => option?.label?.toString() || ""}
                options={modeloptions}
                renderInput={(params) => <TextField {...params} label="مدل" />}
              />
            </div>
            <div className={styles.autocompleteContainer}>
              <Autocomplete
                onChange={(event, newValue) => {
                  searchFilterChange(
                    "price",
                    newValue?.label.toString().replace(/,/g, "") ?? ""
                  );
                  setPriceNumberValues(newValue);
                }}
                value={priceNumberValue}
                size="medium"
                sx={{ width: 400 }}
                disablePortal
                id="price_auto"
                getOptionLabel={(option) => option?.label?.toString() || ""}
                options={priceoptions}
                renderInput={(params) => <TextField {...params} label="قیمت" />}
              />
            </div>
            <div className={styles.autocompleteContainer}></div>
          </div>
          <div className={styles.searchContainer}>
            {searchLoading && (
              <CircularProgress
                className={styles.styleProgress}
                color="secondary"
              />
            )}
            <Button onClick={() => searchFilterHandler()} variant="contained">
              جستجو
            </Button>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default SearchCarPrice;
