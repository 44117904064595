export default class ContractInitialInformation {
  constructor() {
    this.initObject = {
      buyerName: "",
      buyerLastName: "",
      buyerBirthCirtificateCode: "",
      buyerNationalCode: "",
      buyerFathersName: "",
      buyersPhoneNumber: "",
      buyerBirthDate: "",
      buyerAddress: "",
      sellerName: "",
      sellerLastName: "",
      sellerBirthCirtificateCode: "",
      sellerNationalCode: "",
      sellerFathersName: "",
      sellerPhoneNumber: "",
      sellerBirthDate: "",
      sellerAddress: "",
      carBrand: "",
      carKind: "",
      carTreim: "",
      carProductionYear: "",
      carCapacity: "",
      carFuelType: "",
      carBodyColor: "",
      carKm: "",
      carMotorCode: "",
      carShasiCode: "",
      carVIN: "",
      carPlqueCode: "",
      transactionPriceAmount: "",
      sumOfDeduction: "",
      restOfPrice: "",
      agreedAmountAssembly: "",
      bankName: "",
      sellerShabaNumber: "",
      price: "",
      date: "",
      description: "",
      receptionCode: "",
      dateNow: new Date(),
      banihashem: false,
      dehkade: false,
    };
  }
  addCustomeInformation(customeInfo) {
    this.initObject = { ...this.initObject, ...customeInfo };
  }
  getInitObject() {
    return this.initObject;
  }
  getReceptionCode() {
    return this.receptionCode;
  }
  deleteInfo(key) {
    delete this.initObject[key];
  }
}
