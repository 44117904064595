import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import GetDataImage from "../../data/presenter/GetDataImage";
import ReactCrop from "react-image-crop";
import "../../styles/croper.css";
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

class CropImageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      crop: {
        unit: "%",
        x: 0,
        y: 0,
        width: 100,
        height: props.aspect === 0 ? 90 : 100 / parseFloat(props.aspect),
        aspect: parseFloat(props.aspect),
      },
      cropped: null,
    };
  }

  onImageLoaded = (image) => {
    this.setState({ image: image, min: Math.min(image.height, image.width) });
  };

  onCropComplete(crop) {
    this.state.cropped = crop;
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  done() {
    if (this.state.image)
      GetDataImage(this.state.image, this.state.crop, "img").then((r) => {
        this.props.onClose({ base64Image: r.base64, blob: r.blob });
      });
  }

  render() {
    return (
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 50,
            paddingBottom: 50,
          }}
          open={true}
          onClose={() => this.props.onClose()}
        >
          <Paper style={{ padding: 5, margin: 15 }}>
            <ReactCrop
              circularCrop={this.props.circularCrop}
              src={this.state.src}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={(c) => this.onCropComplete(c)}
              onChange={this.onCropChange}
            />
            <div style={{ display: "flex", marginTop: 10 }}>
              <Button
                variant="contained"
                onClick={() => this.done()}
                style={{ margin: 5, borderRadius: 2, height: 35 }}
                color={"primary"}
              >
                تایید
              </Button>

              <Button
                variant="outlined"
                style={{ margin: 5, borderRadius: 2, height: 35 }}
                onClick={() => this.props.onClose()}
                color={"secondary"}
              >
                انصراف
              </Button>

              <Select
                value={this.state.crop.aspect}
                label="ابعاد"
                variant="outlined"
                style={{ margin: 5, height: 35 }}
                onChange={(e) =>
                  this.setState({
                    src: this.props.src,
                    crop:
                      e.target.value === 0
                        ? {
                            unit: "%",
                            x: 0,
                            y: 0,
                            width: 50,
                            height: 50,
                          }
                        : {
                            unit: "px",
                            x: 0,
                            y: 0,
                            width: this.state.min - 10,
                            height:
                              (this.state.min - 10) /
                              parseFloat(e.target.value),
                            aspect: parseFloat(e.target.value),
                          },
                    cropped: null,
                  })
                }
              >
                <MenuItem value={1}>ابعاد 1x1</MenuItem>
                <MenuItem value={4 / 3}>ابعاد 4:3</MenuItem>
                <MenuItem value={3 / 2}>ابعاد 3:2</MenuItem>
                <MenuItem value={2}>ابعاد 2:1</MenuItem>
                <MenuItem value={0}>دلخواه</MenuItem>
              </Select>
            </div>
          </Paper>
        </Modal>
      </div>
    );
  }
}

CropImageModal.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  onClose: PropTypes.func,
  src: PropTypes.string,
  circularCrop: PropTypes.bool,
  aspect: PropTypes.number,
};

CropImageModal.defaultProps = {
  circularCrop: true,
  aspect: 1,
};

export default CropImageModal;
