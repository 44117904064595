import React, { useState, useEffect } from "react";
import styles from "./imageUploader.module.css";
import { ApiClient } from "../../../data/remote";
import { FileDrop } from "react-file-drop";
import SelectImage from "../SelectImage";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import AlertDialog from "../AlertDialog";

const ImageUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState("");
  const [err, setErr] = useState(false);
  const [message, setMessage] = useState("");
  let apiClient = ApiClient.getInstance();

  useEffect(() => {
    getGallery();
  }, [props?.urlChanged]);

  const getGallery = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    apiClient.get(props.getGalleryURL, (error, response) => {
      setLoading(false);
      if (error) {
        setLoading(false);
      } else {
        if (response.success) {
          setLoading(false);
          setImg(response.data[0] || "");
        } else {
          setMessage("خطا در بارگیری");
          setErr(true);
        }
      }
    });
  };

  const uploadImage = (file) => {
    if (loading) {
      return;
    }
    setLoading(true);
    let data = null;
    data = new FormData();
    data.append("image", file[0], file[0].name);
    apiClient.postFile(
      props.uploadImageURL,
      (error, response) => {
        if (error) {
          setErr(true);
          setMessage("خطا در بارگذاری فایل رخ داد");
          setLoading(false);
        } else {
          if (response.success) {
            setImg(response.data);
            setLoading(false);
            props?.afterUploadCallBack(response.data);
          } else {
            setErr(true);
            setMessage("خطا در بارگذاری فایل رخ داد");
            setLoading(false);
          }
        }
      },
      data,
      (onUploadProgress) => {}
    );
  };

  const removeImageRequest = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    apiClient.get(
      props.removeImageURL,
      (error, response) => {
        setLoading(false);
        if (error) {
          setErr(true);
          setMessage("خطا در حذف فایل.");
          setLoading(false);
        } else {
          if (response.success) {
            setImg("");
            setLoading(false);
          } else {
            setErr(true);
            setMessage("خطا در حذف فایل.");
            setLoading(false);
          }
        }
      },
      { imagePath: img }
    );
  };

  const removeImage = (address) => {
    setErr(true);
    setMessage("آیا مطمئن به حذف این تصویر هستید ؟");
  };

  return (
    <>
      <Grid container direction="column">
        <FileDrop
          className="file-drop"
          onDrop={(files, event) => {
            console.log("files: ", files);
          }}
        >
          <SelectImage
            width={150}
            iconButton={"cloud_upload"}
            textButton={"بارگذاری تصویر"}
            aspect={4 / 3}
            colorButton="secondary"
            onSelect={(file) => {
              uploadImage(file);
            }}
          />
        </FileDrop>
        {img.length ? (
          <div className={styles.imagePreview}>
            <div className={styles.deleteImgIconContainer}>
              <IconButton
                size="small"
                onClick={() => removeImage(img)}
                style={{ margin: 3, backgroundColor: "#fff" }}
              >
                <Icon size={16} style={{ color: "red" }}>
                  delete
                </Icon>
              </IconButton>
            </div>
            <img src={img} alt="imageThumbnail" />
          </div>
        ) : null}
      </Grid>
      {message && err && (
        <AlertDialog
          onClose={({ yesPress }) => {
            if (yesPress) {
              removeImageRequest();
            }
            setErr(false);
            setMessage("");
          }}
          question={true}
          description={message}
        />
      )}
    </>
  );
};

export default ImageUploader;
