import { useEffect, useState } from "react";
import { ApiClient, ApiHelper } from "../data/remote";
import {
  timetable,
  timetableThursday,
} from "../components/main/time_management/data_timeManagement";
var moment = require("moment-jalaali");
moment.loadPersian({ dialect: "persian-modern" });

const RangeDateInterval = 20;

const usemakeTime = ({ agentInfo, date }) => {
  const [loading, setLoading] = useState(false);
  const [InRangeReservedTimes, setInRangeReservedTimes] = useState([]);
  const [filteredReserved, setFilteredReserved] = useState([]);
  const apiClient = ApiClient.getInstance();

  let appointments = [];
  let currentTime = moment();
  appointments.push({
    date: currentTime.toISOString(),
    label: currentTime.format("dddd jD jMMMM jYYYY"),
  });
  for (var i = 0; i < RangeDateInterval; i++) {
    currentTime.add(1, "day");
    appointments.push({
      date: currentTime.toISOString(),
      label: currentTime.format("dddd jD jMMMM jYYYY"),
    });
  }

  const getInRangeReservedTimes = () => {
    let prevDayDate = new Date();
    prevDayDate.setDate(prevDayDate.getDate() - 1);
    setLoading(true);
    apiClient.post(
      ApiHelper.getAllInRangeRequestsTimes,
      (error, response) => {
        if (error) {
          setLoading(false);
        } else {
          setLoading(false);
          setInRangeReservedTimes(response.data);
        }
      },
      {
        startDate: prevDayDate,
        rangeOfDays: 20,
        agentId: agentInfo || null,
      }
    );
  };
  useEffect(() => {
    getInRangeReservedTimes();
  }, [agentInfo]);

  const makeTimeTableInformative = () => {
    let timepassed = timetable;
    let dateCurrent = moment(new Date(date));
    if (dateCurrent.format("dddd jD jMMMM jYYYY").includes("پنج‌شنبه ")) {
      timepassed = timetableThursday;
    }

    let informativeTimeTable = timepassed.map((hourlyTimeString) => {
      let dateCurrent = moment(new Date(date));
      let splitedHour = hourlyTimeString.split(":");
      dateCurrent.set({ h: splitedHour[0], m: splitedHour[1] });
      dateCurrent = new Date(dateCurrent.toISOString());
      return {
        hour: hourlyTimeString,
        hourInDateObj: dateCurrent,
        reserved: false,
      };
    });
    let filteredReserved = filterBaseOnReservedtimeTable(
      informativeTimeTable,
      InRangeReservedTimes
    );
    setFilteredReserved(filteredReserved);
  };

  useEffect(() => {
    makeTimeTableInformative();
  }, [InRangeReservedTimes, date]);

  const filterBaseOnReservedtimeTable = (
    timeTableInObj,
    allInRangeUserRequestTimes
  ) => {
    let filteredByReservedHours = timeTableInObj.map((hourTimeObj) => {
      let reserved = false;

      allInRangeUserRequestTimes.forEach((rangeDate) => {
        if (
          new Date(rangeDate.reserveDatetime).getDay() ===
            hourTimeObj.hourInDateObj.getDay() &&
          new Date(rangeDate.reserveDatetime).getHours() + 1 ===
            hourTimeObj.hourInDateObj.getHours() &&
          new Date(rangeDate.reserveDatetime).getMinutes() ===
            hourTimeObj.hourInDateObj.getMinutes() &&
          new Date(rangeDate.reserveDatetime).getDate() ===
            hourTimeObj.hourInDateObj.getDate()
        ) {
          reserved = true;
        }
      });
      return {
        ...hourTimeObj,
        reserved: reserved,
      };
    });
    return filteredByReservedHours;
  };

  return [filteredReserved];
};
export default usemakeTime;

//passing day and receive time
