import React, { useReducer, useState } from "react";
import Main from "../../../common/UserMain";
import styles from "./reporting.module.css";
import { Statement } from "../../../../values/Statement";
import DayFilter from "../../../../features/filters/dayFilter";
import SellerFilter from "../../../../features/filters/sellerFilter";
import FilterSwitchOne from "../../../../features/filters/filterSwitchOne";
import { ApiClient, ApiHelper } from "../../../../data/remote";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RatingFilter from "../../../../features/filters/ratingFilter";
import TotalRequest from "../../../../features/filters/totalRequest";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  Checkbox,
  Divider,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useEffect } from "react";

const initialValue = {
  fromJoinDate: "",
  toJoinDate: "",
  isOnline: false,
  sellerId: "",
  fromLastTimeOnlineDate: "",
  toLastTimeOnlineDate: "",
  enableSMS: false,
  positiveRate: "",
  negativeRate: "",
  fromTotalRequest: "",
  toTotalRequest: "",
};
const initialReset = {
  resetFromJoinDate: true,
  resetSellers: true,
  resetLastTimeOnlineDate: true,
  resetRating: true,
  resetTotalRequest: true,
};
const reducer = (data, action) => {
  switch (action.type) {
    case "FORMJOINDDATE":
      return { ...data, fromJoinDate: action.payload };
    case "TOJOINDATE":
      return { ...data, toJoinDate: action.payload };
    case "ISONLINE":
      return { ...data, isOnline: action.payload };
    case "SELLERID":
      return { ...data, sellerId: action.payload };
    case "TOLASTTIMEON":
      return { ...data, toLastTimeOnlineDate: action.payload };
    case "FROMLASTTIMEON":
      return { ...data, fromLastTimeOnlineDate: action.payload };
    case "ENABLESMS":
      return { ...data, enableSMS: action.payload };
    case "POSITIVERATING":
      return { ...data, positiveRate: action.payload };
    case "NEGATIVERATING":
      return { ...data, negativeRate: action.payload };
    case "FROMTOTALREQ":
      return { ...data, fromTotalRequest: action.payload };
    case "TOTOTALREQ":
      return { ...data, resetTotalRequest: action.payload };
    default:
      return data;
  }
};

const resertReducer = (data, action) => {
  switch (action.type) {
    case "RESETJOINDDATE":
      return { ...data, resetFromJoinDate: action.payload };
    case "RESETSELLERID":
      return { ...data, resetSellers: action.payload };
    case "RESETLASTTIMEON":
      return { ...data, resetLastTimeOnlineDate: action.payload };
    case "RESETRATING":
      return { ...data, resetRating: action.payload };
    case "RESETTOTALREQ":
      return { ...data, resetTotalRequest: action.payload };
    default:
      return data;
  }
};

const ReportingRequests = () => {
  let apiClient = ApiClient.getInstance();
  const [data, dispatch] = useReducer(reducer, initialValue);
  const [reset, dispatchReset] = useReducer(resertReducer, initialReset);
  const [linkDownload, setLinkDownload] = useState("");
  const [isloading, setIsloading] = useState(false);

  const handlerDayToFrom = (to_day, from_day) => {
    dispatch({ type: "FORMJOINDDATE", payload: from_day });
    dispatch({ type: "TOJOINDATE", payload: to_day });
  };
  const handlerisOnline = (inOnline) => {
    dispatch({ type: "ISONLINE", payload: inOnline });
  };
  const handlerSeller = (sellerId) => {
    dispatch({ type: "SELLERID", payload: sellerId });
  };
  const handlerEnableSMS = (enableSMS) => {
    dispatch({ type: "ENABLESMS", payload: enableSMS });
  };
  const handlerRating = (Negative, Positive) => {
    dispatch({ type: "POSITIVERATING", payload: Positive });
    dispatch({ type: "NEGATIVERATING", payload: Negative });
  };
  const handlerLastTimeOnline = (to_day, from_day) => {
    dispatch({ type: "TOLASTTIMEON", payload: to_day });
    dispatch({ type: "FROMLASTTIMEON", payload: from_day });
  };
  const handlerTotalRequest = (to_day, from_day) => {
    dispatch({ type: "FROMTOTALREQ", payload: to_day });
    dispatch({ type: "TOTOTALREQ", payload: from_day });
  };
  const handlerResetFilter = (nameReset) => {
    dispatchReset({ type: nameReset, payload: false });
    setTimeout(() => {
      dispatchReset({ type: nameReset, payload: true });
    }, 300);
  };

  useEffect(() => {
    setIsloading(true);
    apiClient.post(
      ApiHelper.brokersDetailedReport_POST,
      (error, response) => {
        if (error) {
        } else {
          setLinkDownload(response.result.path);
          setIsloading(false);
        }
      },
      data
    );
  }, [data]);

  const handleBrokerCheckbox = () => {
    console.log(90);
  };
  return (
    <Main page={"brokers/reporting"} pageName={"آمارگیری بروکر ها"}>
      <Accordion>
        <AccordionSummary
          className={styles.titleAccordionStyle}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h4 style={{ margin: 0 }}> فیلتر ها</h4>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid style={{ height: "108px" }} item xs={12}>
              <Divider />
              <Box className={styles.rowBoxStyle}>
                <h4>{Statement.registeryDate}</h4>
                <Button
                  disabled={!(data.fromJoinDate || data.toJoinDate)}
                  onClick={() => {
                    handlerResetFilter("RESETJOINDDATE");
                    handlerDayToFrom("", "");
                  }}
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  پاک سازی از فیلتر
                </Button>
              </Box>
              {reset.resetFromJoinDate && (
                <DayFilter handlerDay={handlerDayToFrom} />
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
              <div className={styles.rowBoxStyle}>
                <div>
                  <FilterSwitchOne
                    handlertypeDate={handlerisOnline}
                    title={Statement.onlineOflineFilter}
                    switchOn="آنلاین"
                    switchOff="آفلاین"
                    defaultState={false}
                  />
                </div>
              </div>
              <Divider />
            </Grid>

            <Divider />
            <Grid item xs={12}>
              <div className={styles.rowBoxStyle}>
                <div style={{ height: "80px" }}>
                  <h4>{Statement.selerFilter}</h4>
                  {reset.resetSellers && (
                    <SellerFilter
                      handlerSeller={handlerSeller}
                      title={Statement.selerFilter}
                    />
                  )}
                </div>
                <Button
                  disabled={!data.sellerId}
                  onClick={() => {
                    handlerResetFilter("RESETSELLERID");
                    handlerSeller("");
                  }}
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  پاک سازی از فیلتر
                </Button>
              </div>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <div style={{ height: "108px" }}>
                <Box className={styles.rowBoxStyle}>
                  <h4>{Statement.lastOnlineDay}</h4>
                  <Button
                    disabled={
                      !(
                        data.toLastTimeOnlineDate || data.fromLastTimeOnlineDate
                      )
                    }
                    onClick={() => {
                      handlerResetFilter("RESETLASTTIMEON");
                      handlerLastTimeOnline("", "");
                    }}
                    size="small"
                    color="primary"
                    variant="contained"
                  >
                    پاک سازی از فیلتر
                  </Button>
                </Box>
                {reset.resetLastTimeOnlineDate && (
                  <DayFilter handlerDay={handlerLastTimeOnline} />
                )}
              </div>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <div className={styles.rowBoxStyle}>
                <div>
                  <FilterSwitchOne
                    handlertypeDate={handlerEnableSMS}
                    title={Statement.sendMessageFilter}
                    switchOn="باز"
                    switchOff="بسته"
                    defaultState={false}
                  />
                </div>
              </div>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <div className={styles.rowBoxStyle}>
                <div style={{ height: "108px" }}>
                  <h4>{Statement.scoreAndRank}</h4>
                  {reset.resetRating && (
                    <RatingFilter
                      handlerRating={handlerRating}
                      ratingOne="نکته مثبت"
                      ratingTwo="نکته منفی"
                    />
                  )}
                </div>
                <Button
                  disabled={!(data.negativeRate || data.positiveRate)}
                  onClick={() => {
                    handlerResetFilter("RESETRATING");
                    handlerRating("", "");
                  }}
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  پاک سازی از فیلتر
                </Button>
              </div>
            </Grid>
            <Divider />
            <Grid item sm={4} xs={6} lg={2}>
              <h4>سیستم</h4>
              <Checkbox
                checked={true}
                onChange={() => handleBrokerCheckbox()}
              />
              <p>PWA</p>
              <Checkbox
                checked={true}
                onChange={() => handleBrokerCheckbox()}
              />
              <p>android</p>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <div className={styles.rowBoxStyle}>
                <div style={{ height: "70px" }}>
                  <h4>{Statement.numberPurchases}</h4>
                  {reset.resetTotalRequest && (
                    <TotalRequest handlerTotalRequest={handlerTotalRequest} />
                  )}
                </div>
                <Button
                  disabled={!(data.toTotalRequest || data.fromTotalRequest)}
                  onClick={() => {
                    handlerResetFilter("RESETTOTALREQ");
                    handlerTotalRequest("", "");
                  }}
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  پاک سازی از فیلتر
                </Button>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Paper className={styles.resultsSearchStyle}>
        <h4 className={styles.borkerMessageStyle}>
          {Statement.reportingBrokerMessage}
        </h4>
        <div className={styles.linkDownloadStyle}>
          <a href={linkDownload}>
            <Button disabled={isloading} variant="contained" color="primary">
              {isloading ? "در حال دریافت اطلاعات" : "دانلود فایل اکسل"}
            </Button>
            {isloading && <LinearProgress className={styles.progressStyle} />}
          </a>
        </div>
      </Paper>
    </Main>
  );
};
export default ReportingRequests;
