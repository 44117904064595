import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Icon,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { ApiClient, ApiHelper } from "../../../data/remote";
import DatePickerCom from "../../common/DatePicker/DatePicker";
import style from "./timeManagement.module.css";
import { Statement } from "../../../values/Statement";
import SnackBar from "../../common/SnackBar";
import { GoldenSwitch } from "../../../styles/colors_mui";
import DialogManagement from "./DialogManagement";
import { useListShutdownDays } from "../../../Hooks/useListShutdownDays";

const SearchBox = (props) => {
  const apiClient = ApiClient.getInstance();
  const moment = require("moment-jalaali");
  moment.loadPersian({ dialect: "persian-modern" });
  const { listOffDaye, getListOffDays } = useListShutdownDays();

  const [choiceDay, setChoiceDay] = useState({
    dateTime: "",
    isChecked: false,
    showAlert: false,
  });

  const [stateBox, setStateBox] = useState({
    resetSearchBaseReserve: true,
    checkstate: true,
    dateTime: "",
    onClose: false,
    sendDateServer: "",
  });

  const resetSearchBaseReserve = () => {
    setTimeout(() => {
      setStateBox((prev) => ({
        ...prev,
        resetSearchBaseReserve: true,
      }));
    }, 300);
  };

  const handlerStatusDay = (selectedDate) => {
    let statusDay = listOffDaye?.some(
      (dateInfo) => dateInfo.date.split("T")[0] === selectedDate
    );
    setChoiceDay((prev) => ({ ...prev, isChecked: !statusDay }));
    getListOffDays();
  };

  const searchBaseOnReserve = (t) => {
    const dateTime = moment(t);
    const DateServer = t.split("T")[0];

    props.setState((prev) => ({ ...prev, showMessageOrTable: true }));
    setStateBox((prev) => ({
      ...prev,
      checkstate: false,
      dateTime: dateTime.format("dddd jD jMMMM jYYYY"),
      sendDateServer: DateServer,
    }));
    const data = { search: t };
    apiClient.get(
      ApiHelper.getRequests_GET("sell", "created", 1, "true"),
      (error, response) => {
        if (error) {
        } else {
          props.setState((prev) => ({
            ...prev,
            data: response.records,
          }));
          if (response.records.length <= 0) {
            setStateBox((prev) => ({ ...prev, onClose: true }));
            props.setState((prev) => ({
              ...prev,
              showMessageOrTable: false,
              showImage: true,
              message: Statement.NoRequestRegistered,
            }));
          } else {
            setStateBox((prev) => ({ ...prev, onClose: true }));
          }
        }
      },
      data
    );
    handlerStatusDay(DateServer);
  };

  useEffect(() => {
    if (props.date !== undefined) {
      searchBaseOnReserve(props.date);
    }
  }, [props.date]);

  return (
    <Grid item xs={12}>
      <div className={stateBox.checkstate ? style.search_box : style.textDay}>
        {stateBox.checkstate && (
          <h4 className={style.textbox}>
            <Icon size={14} style={{ color: "blue", marginLeft: "2px" }}>
              fact_check
            </Icon>
            جستجو بر اساس{" "}
            <span className={style.searchBoxTextDate}>تاریخ ثبت</span> درخواست
          </h4>
        )}
        <div>
          {stateBox.resetSearchBaseReserve && (
            <DatePickerCom
              inputClass={style.customInputClass}
              onchange={(e) => {
                searchBaseOnReserve(e.value.toISOString());
                setChoiceDay((prev) => ({
                  ...prev,
                  dateTime: e.value.toISOString(),
                }));
              }}
            />
          )}

          <IconButton
            aria-label="delete"
            onClick={() => {
              props.setState((prev) => ({
                ...prev,
                data: [],
                showImage: false,
                showMessageOrTable: false,
                message: Statement.NoDaySelected,
              }));
              setStateBox((prev) => ({
                ...prev,
                checkstate: true,
                resetSearchBaseReserve: false,
              }));
              resetSearchBaseReserve();
            }}
          >
            <Clear className={style.customStyleClearIcon} />
          </IconButton>
        </div>
        {stateBox.checkstate && (
          <div style={{ display: "flex" }}>
            <h4 style={{ display: "flex", alignItems: "center", margin: "0" }}>
              روز مورد نظر را از اینجا انتخاب کنید
            </h4>
            <div className={style.anmationBox}>
              <ArrowUpwardIcon />
            </div>
          </div>
        )}
        {!stateBox.checkstate && <h2>{stateBox.dateTime}</h2>}
        {!stateBox.checkstate && (
          <Tooltip
            className={style.statusday}
            title="تغییر وضعیت"
            placement="right"
          >
            <FormControlLabel
              control={
                <GoldenSwitch
                  checked={choiceDay.isChecked}
                  onChange={() =>
                    setChoiceDay((prev) => ({ ...prev, showAlert: true }))
                  }
                />
              }
              label="وضعیت روز"
            />
          </Tooltip>
        )}
      </div>
      {choiceDay.showAlert && (
        <DialogManagement
          setChoiceDay={setChoiceDay}
          showAlert={choiceDay.showAlert}
          sendDateServer={stateBox.sendDateServer}
          dateTime={stateBox.dateTime}
          DateServer={stateBox.sendDateServer}
        />
      )}
      <SnackBar
        variant={"warning"}
        message={Statement.NoRequestRegistered}
        open={props.state.data.length <= 0 && stateBox.onClose}
        onClose={() => setStateBox((prev) => ({ ...prev, onClose: false }))}
      />
    </Grid>
  );
};
export default SearchBox;
