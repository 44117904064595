import React from "react";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../baseURL";
import createBaseQueryWithReauth from "../../modules/HOC/createBaseQueryWithReauth ";
export const webApiSell = createApi({
  reducerPath: "sell",
  baseQuery: createBaseQueryWithReauth(`${baseURL}app/sell/`),
  endpoints: (builder) => ({
    getAppointments: builder.query({
      query: ({ agentID }) =>
        `appointments?agentID=${agentID}&rangeNumberAppointment=20`,
    }),
  }),
});

export const { useGetAppointmentsQuery } = webApiSell;
