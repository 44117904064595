let moment = require("moment-jalaali");
moment.loadPersian({ dialect: "persian-modern" });
 
export const recentDaysService = (range) => {
  let appointments = [];
  let currentTime = moment();
  appointments.push({
    date: currentTime.toISOString(),
    label: currentTime.format("dddd jD jMMMM jYYYY"),
  });
  for (var i = 0; i < range; i++) {
    currentTime.add(1, "day");
    appointments.push({
      date: currentTime.toISOString(),
      label: currentTime.format("dddd jD jMMMM jYYYY"),
    });
  }
  return appointments;
};