import React, { useEffect, useState } from "react";
import styles from "./subPermissions.module.css";
import subPermissionArray from "../../../values/subPermission/subPermissionArray";
import SnackBar from "../SnackBar";
import {
  useGrantPermissionMutation,
  useRemovePermissionMutation,
} from "../../../Store/callApi/AdminDataService";

import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";

const SubPermissions = ({
  handlerSubPermission,
  idEdit,
  numberPermissionCodes,
}) => {
  const [grantPermission] = useGrantPermissionMutation();
  const [removePermission] = useRemovePermissionMutation();
  const [selectedPermissionCode, setSelectedPermissionCode] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState("");

  const handleLabelClick = async (perCode) => {
    const updatedSelection = [...selectedPermissionCode];
    if (updatedSelection.includes(perCode)) {
      updatedSelection.splice(updatedSelection.indexOf(perCode), 1);
    } else {
      updatedSelection.push(perCode);
    }
    setSelectedPermissionCode(updatedSelection);
    handlerSubPermission(updatedSelection);

    if (idEdit) {
      let res;
      const prem = { permissionCode: perCode };
      updatedSelection.includes(perCode)
        ? (res = await grantPermission({ id: idEdit, body: prem }))
        : (res = await removePermission({ id: idEdit, body: prem }));

      res.data?.success && setShowSnackBar(res.data.message);
    }
  };
  useEffect(() => {
    if (idEdit) {
      const matchingNumbers = subPermissionArray.flatMap((category) =>
        category.subPermission
          .filter((sub) => numberPermissionCodes.includes(sub.permissionCode))
          .map((sub) => sub.permissionCode)
      );
      setSelectedPermissionCode(matchingNumbers);
    }
  }, [idEdit, numberPermissionCodes]);

  return (
    <>
      <List>
        <Box mt={1} className={styles.titleSubPermission}>
          زیر دسترسی ها
        </Box>
        {subPermissionArray.map((item, index) => (
          <ListItem key={index} className={styles.subPermissionStyle}>
            <ListItemText primary={item.title} />
            <List>
              {item.subPermission.map((subItem, index) => (
                <FormControlLabel
                  key={index}
                  checked={selectedPermissionCode.includes(
                    subItem.permissionCode
                  )}
                  onChange={() => handleLabelClick(subItem.permissionCode)}
                  control={
                    idEdit ? (
                      <Switch color="primary" />
                    ) : (
                      <Checkbox color="primary" />
                    )
                  }
                  label={subItem.label}
                  labelPlacement="start"
                />
              ))}
            </List>
          </ListItem>
        ))}
      </List>
      <SnackBar
        variant="info"
        message={showSnackBar}
        open={showSnackBar}
        onClose={() => setShowSnackBar("")}
      />
    </>
  );
};
export default SubPermissions;
