import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Main from "../../common/UserMain";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Button from "@material-ui/core/Button";
import SnackBar from "../../common/SnackBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import AlertDialog from "../../common/AlertDialog";
import { convertNumberToEN } from "../../../language/NumberConverter";
import { goBackUseHistory } from "../../AppHistory";
import SubPermissions from "../../common/subPermission/subPermissions";
import ShutDownSwitch from "../../../features/agents/ShutDownSwitch";
import {
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";

let apiClient = null;
let infoAgent = "";
@inject("AccountStore")
@inject("AgentsStore")
@inject("LocationsStore")
@observer
class AddEditAgent extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();
    infoAgent = props.AgentsStore.getCurrent();
    if (!props.AccountStore.isLogin()) this.props.history.replace("/");

    this.state = {
      permissionCodes: [],
      isNewBroker: !props.AgentsStore.current._id,
      sendRequest: false,
      sendRequestErrMessage: "",
      inputWarn: 0,
      warnInputMessage: "",
      success: false,
      getLocationErr: false,
      message: "",
      expertName: "",
      expertPhone: "",
    };
  }
  isValidInput() {
    this.setState({ inputWarn: 0 });

    let info = this.props.AgentsStore.getCurrent();
    if (info.username.replace(/ /g, "").length < 5) {
      this.setState({ inputWarn: 1 });
      this.inputUsername.focus();
      return false;
    }

    if (this.state.isNewBroker && info.password.replace(/ /g, "").length < 6) {
      this.setState({ inputWarn: 2 });
      this.inputPass.focus();
      return false;
    }

    if (info.name.replace(/ /g, "") === "") {
      this.setState({ inputWarn: 3 });
      this.inputName.focus();
      return false;
    }

    return true;
  }

  componentDidMount() {
    this.getLocation();
    if (infoAgent._id) {
      const ListSubPermission = this.props.AgentsStore.getCurrent();
      const subPermissionCodes = ListSubPermission?.subPermission.map(
        (itemCode) => itemCode.permissionCode
      );
      this.setState({ permissionCodes: subPermissionCodes });
    }
  }

  getLocation(ref = false) {
    if (!ref && this.props.LocationsStore.locations.length > 0) return;

    this.setState({ getLocationErr: false });

    apiClient.get(ApiHelper.getLocations_GET, (error, response) => {
      if (error) {
        this.setState({
          getLocationErr:
            this.props.LocationsStore.locations.length <= 0 && !ref,
        });
      } else {
        this.props.LocationsStore.setLocations(response.records);
        this.setState({ getLocationErr: false });
      }
    });
  }

  submit() {
    if (!this.isValidInput()) return;

    if (this.state.sendRequest) return;

    let data = this.props.AgentsStore.getCurrent();
    const statePermissionCodes = this.state.permissionCodes;
    data = {
      ...data,
      permissionCodes: statePermissionCodes,
    };
    if (data.latlng && data.latlng.length > 0)
      data.latlng = data.latlng.toString().split(",");

    if (data.location && data.location._id) data.location = data.location._id;

    this.setState({ sendRequest: true });
    apiClient.post(
      ApiHelper.addEditAgent_POST,
      (error, response) => {
        this.setState({ sendRequest: false });
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false, success: true });
        }
      },
      data
    );
  }

  addExpert() {
    if (
      this.state.expertName.toString().replace(/ /g, "") === "" ||
      this.state.expertPhone.toString().replace(/ /g, "") === ""
    ) {
      this.setState({ message: "نام و شماره کارشناس را وارد کنید" });
      return;
    }
    this.props.AgentsStore.current.experts.push({
      name: this.state.expertName,
      phone: this.state.expertPhone,
    });
    this.setState({ expertName: "", expertPhone: "" });
  }

  handlerSubPermissionCode(premCode) {
    this.setState({ permissionCodes: premCode });
  }

  render() {
    return (
      <Main
        onBackPress={() => goBackUseHistory(this.props.history)}
        page={"agents/accepted"}
        pageName={"ثبت نماینده"}
        context={this.props.history}
      >
        <Paper style={{ padding: 15 }}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputUsername = r)}
                onChange={(t) =>
                  (this.props.AgentsStore.current.username = t.target.value)
                }
                defaultValue={this.props.AgentsStore.current.username}
                error={this.state.inputWarn === 1}
                label="نام کاربری"
                placeholder={
                  !this.state.isNewBroker ? "تغییر نام کاربری (غیر ضروری)" : ""
                }
                inputProps={{ maxLength: 50 }}
                fullWidth={true}
                styles={classes.textInput}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) =>
                  ev.key === "Enter" && this.inputPass.focus()
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>account_circle</Icon>
                    </InputAdornment>
                  ),
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value).toString()
                    : "";
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputPass = r)}
                onChange={(t) =>
                  (this.props.AgentsStore.current.password = t.target.value)
                }
                defaultValue={this.props.AgentsStore.current.password}
                error={this.state.inputWarn === 2}
                placeholder={
                  !this.state.isNewBroker ? "تغییر رمز عبور (غیر ضروری)" : ""
                }
                label="رمز عبور"
                inputProps={{ maxLength: 50 }}
                fullWidth={true}
                styles={classes.textInput}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) =>
                  ev.key === "Enter" && this.inputName.focus()
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>lock</Icon>
                    </InputAdornment>
                  ),
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value).toString()
                    : "";
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputName = r)}
                onChange={(t) =>
                  (this.props.AgentsStore.current.name = t.target.value)
                }
                defaultValue={this.props.AgentsStore.current.name}
                error={this.state.inputWarn === 3}
                label="نام کامل"
                inputProps={{ maxLength: 50 }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) =>
                  ev.key === "Enter" && this.inputPhone.focus()
                }
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.inputPhone = r)}
                onChange={(t) =>
                  (this.props.AgentsStore.current.phone = t.target.value)
                }
                defaultValue={this.props.AgentsStore.current.phone}
                label={"موبایل"}
                placeholder={"موبایل با ۰۹ شروع شود"}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                inputProps={{
                  style: { textAlign: "left", direction: "ltr", fontSize: 15 },
                  maxLength: 11,
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") this.officePhone.focus();
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value)
                        .toString()
                        .replace(/[^0-9]/g, "")
                        .toString()
                    : "";
                }}
                type="tel"
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.officePhone = r)}
                onChange={(t) =>
                  (this.props.AgentsStore.current.officePhone = t.target.value)
                }
                defaultValue={this.props.AgentsStore.current.officePhone}
                label={"تلفن دفتر"}
                placeholder={"تلفن دفتر"}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                inputProps={{
                  style: { textAlign: "left", direction: "ltr", fontSize: 15 },
                  maxLength: 25,
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") this.address.focus();
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value)
                        .toString()
                        .replace(/[^0-9]/g, "")
                        .toString()
                    : "";
                }}
                type="tel"
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.address = r)}
                onChange={(t) =>
                  (this.props.AgentsStore.current.address = t.target.value)
                }
                defaultValue={this.props.AgentsStore.current.address}
                label="آدرس"
                inputProps={{ maxLength: 500 }}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) => ev.key === "Enter" && this.map.focus()}
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.map = r)}
                onChange={(t) =>
                  (this.props.AgentsStore.current.map = t.target.value)
                }
                defaultValue={this.props.AgentsStore.current.map}
                label="نقشه html"
                multiline={true}
                rows={5}
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) => ev.key === "Enter" && this.latlng.focus()}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value).toString()
                    : "";
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              <TextField
                inputRef={(r) => (this.latlng = r)}
                onChange={(t) =>
                  (this.props.AgentsStore.current.latlng = t.target.value)
                }
                defaultValue={this.props.AgentsStore.current.latlng}
                label="lat , lng"
                fullWidth={true}
                variant="outlined"
                margin={"dense"}
                onKeyPress={(ev) => ev.key === "Enter" && this.map.focus()}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? convertNumberToEN(e.target.value).toString()
                    : "";
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12} style={classes.gridInput}>
              {this.state.getLocationErr ? (
                <Button
                  onClick={() => this.getLocation(true)}
                  color="secondary"
                  variant="outlined"
                  size="small"
                >
                  خطا در دریافت شهرها !
                </Button>
              ) : (
                <FormControl label="شهر">
                  <Select
                    defaultValue={
                      !this.props.AgentsStore.current.location
                        ? "0"
                        : this.props.AgentsStore.current.location._id
                    }
                    variant="outlined"
                    margin={"dense"}
                    style={{ fontSize: 13, marginTop: 10 }}
                    onChange={(e) =>
                      (this.props.AgentsStore.current.location = e.target.value)
                    }
                  >
                    <MenuItem style={{ fontSize: 13 }} value={"0"}>
                      {this.props.LocationsStore.locations.length < 0 ? (
                        <CircularProgress size={16} style={{ margin: 1 }} />
                      ) : (
                        "انتخاب شهر"
                      )}
                    </MenuItem>
                    {this.props.LocationsStore.locations.map((l, i) => (
                      <MenuItem
                        key={l._id}
                        style={{ fontSize: 13 }}
                        value={l._id}
                      >
                        {l.location}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <IconButton onClick={() => this.getLocation(true)}>
                <Icon>sync</Icon>
              </IconButton>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Divider />

              <p>افزودن کارشناس برای نماینده</p>
              <Grid item sm={6} xs={12} style={classes.gridInput}>
                <TextField
                  onChange={(t) =>
                    this.setState({ expertName: t.target.value })
                  }
                  value={this.state.expertName}
                  label="نام کارشناس"
                  inputProps={{ maxLength: 50 }}
                  fullWidth={true}
                  variant="outlined"
                  margin={"dense"}
                />

                <TextField
                  onChange={(t) =>
                    this.setState({ expertPhone: t.target.value })
                  }
                  value={this.state.expertPhone}
                  label={" موبایل کارشناس"}
                  placeholder={"موبایل کارشناس"}
                  fullWidth={true}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{
                    style: {
                      textAlign: "left",
                      direction: "ltr",
                      fontSize: 15,
                    },
                    maxLength: 11,
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      ? convertNumberToEN(e.target.value)
                          .toString()
                          .replace(/[^0-9]/g, "")
                          .toString()
                      : "";
                  }}
                  type="tel"
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.addExpert()}
                >
                  ایجاد
                </Button>
              </Grid>
              {this.props.AgentsStore.current.experts.map((expert, i) => (
                <Chip
                  key={expert._id}
                  size="medium"
                  color="secondary"
                  style={{
                    margin: "15px 0 10px 10px",
                    alignSelf: "flex-start",
                  }}
                  label={expert.name + " / " + expert.phone}
                  onDelete={() => {
                    this.props.AgentsStore.current.experts.splice(i, 1);
                    this.setState({ ref: true });
                  }}
                />
              ))}
            </Grid>
            <Grid item sm={12}>
              <Divider />
              <ShutDownSwitch
                agentID={infoAgent._id}
                ShutDownState={infoAgent.shutedDown}
              />
              <Divider />
              <SubPermissions
                numberPermissionCodes={this.state.permissionCodes}
                idEdit={infoAgent._id}
                handlerSubPermission={this.handlerSubPermissionCode.bind(this)}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Divider />
              {this.state.sendRequest ? (
                <div style={classes.progressBarContainer}>
                  <LinearProgress
                    style={{ width: 150, height: 4, marginTop: 15 }}
                  />
                  <p>درحال ارسال اطلاعات ...</p>
                </div>
              ) : (
                <div style={{ display: "flex", marginTop: 20, maxWidth: 330 }}>
                  <Button
                    variant="contained"
                    onClick={() => this.submit()}
                    style={{ margin: 5 }}
                    fullWidth
                    color={"primary"}
                  >
                    {this.state.isNewBroker ? "ارسال" : "بروزرسانی"}
                  </Button>

                  <Button
                    variant="outlined"
                    style={{ margin: 5 }}
                    onClick={() => goBackUseHistory(this.props.history)}
                    fullWidth
                    color={"secondary"}
                  >
                    بازگشت
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </Paper>

        <SnackBar
          variant={"error"}
          message={this.state.sendRequestErrMessage}
          open={this.state.sendRequestErrMessage !== ""}
          onClose={() => this.setState({ sendRequestErrMessage: "" })}
        />

        {this.state.success && (
          <AlertDialog
            onClose={() => goBackUseHistory(this.props.history)}
            question={false}
            description={
              this.state.isNewBroker
                ? "نماینده با موفقیت ایجاد شد."
                : "بروزرسانی با موفقیت انجام شد."
            }
          />
        )}

        {this.state.warnInputMessage !== "" && (
          <AlertDialog
            onClose={() => this.setState({ warnInputMessage: "" })}
            question={false}
            description={this.state.warnInputMessage}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </Main>
    );
  }
}

export default AddEditAgent;

const classes = {
  textInput: {
    margin: 10,
  },
  gridInput: {
    maxWidth: 350,
    minWidth: 300,
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
};
