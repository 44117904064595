import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../baseURL";
import createBaseQueryWithReauth from "../../modules/HOC/createBaseQueryWithReauth ";

export const GetApiLinks = createApi({
  reducerPath: "GetApiLinks",
  baseQuery: createBaseQueryWithReauth(baseURL),

  endpoints: (builder) => ({
    getPokemonByName: builder.query({
      query: ({ page }) => `/admin/links?page=${page}`,
    }),
  }),
});

export const { useGetPokemonByNameQuery } = GetApiLinks;
