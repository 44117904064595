import React, { useState, useEffect } from "react";
import Main from "../../common/UserMain";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { observer, inject } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Grid from "@material-ui/core/Grid";
import CarPriceBrands from "../car_price_brands/carPriceBrands";
import SearchCarPrice from "./searchCarPrice/searchCarPrice";
import PriceFormater from "../../../utils/priceFormater";
import styles from "./CarPrice.module.css";
import ReturnModelBasedOnOption from "./returnModelBasedOnOption";
import CustomSkeleton from "../../common/skeleton/customSkeleton";

const CarPrice = inject("CarsStore")(
  observer((props) => {
    let apiClient = ApiClient.getInstance();
    const [showCreateCarDialog, setShowCreateCarDialog] = useState(false);
    const [message, setMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [records, setRecords] = useState([]);
    const [getCarsAfterCallingApis, setGetCarsAfterApis] = useState(false);
    const [filter, setFilter] = useState({
      brand: "",
      model: "",
      price: "",
      option: "",
    });
    const [modelResults, setModelResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const addNew = () => {
      setShowCreateCarDialog(true);
    };
    useEffect(() => {
      setLoading(true);
      setMessage("در حال دریافت خودرو ها");
      apiClient.get(
        ApiHelper.getCategories_GET,
        (error, response) => {
          if (error) {
            setLoading(false);
            setAlertType("error");
            setMessage("مشکلی در هنگام بارگیری خودرو های قیمت بازار رخ داد");
          } else {
            setLoading(false);
            setMessage("");
            setRecords(response.records);
            setAlertType("success");
          }
        },
        {}
      );
    }, [showCreateCarDialog, getCarsAfterCallingApis]);

    const putCarPriceInBrandAndUpdate = ({ ...data }) => {
      // i havnt controlled any thing yet any error or possible drawbacks
      let { newCarPrice, brand, modelId, optionName } = data;
      newCarPrice = parseInt((9 / 10) * newCarPrice);
      let ModelInWichOptionLies = brand.models.find(
        (vmodel) => vmodel._id === modelId
      );
      let NearestYearInWithOptionLies = ModelInWichOptionLies.types.find(
        (year) => {
          return year.options.some((option) => {
            return option.option === optionName;
          });
        }
      );
      ModelInWichOptionLies.modelStartingPrice = newCarPrice;
      if (NearestYearInWithOptionLies) {
        NearestYearInWithOptionLies.options =
          NearestYearInWithOptionLies.options.map((option) => {
            if (option.option === optionName) {
              option.optionStartingPrice = newCarPrice;
              option.lastPriceUpdateDateOption = new Date();
            }
            return option;
          });
        let newTypesOfModel = ModelInWichOptionLies.types.map((year) => {
          if (
            year._id.toString() === NearestYearInWithOptionLies._id.toString()
          ) {
            return NearestYearInWithOptionLies;
          } else return year;
        });
        ModelInWichOptionLies.types = newTypesOfModel;
        let newModelsOfBrabnd = brand.models.map((model) => {
          if (model._id.toString() === ModelInWichOptionLies._id.toString()) {
            return ModelInWichOptionLies;
          } else return model;
        });
        brand.models = newModelsOfBrabnd;
      } else {
        let NearestYearInWithOptionLies = ModelInWichOptionLies.types[0];
        ModelInWichOptionLies.modelStartingPrice = newCarPrice;
        if (NearestYearInWithOptionLies) {
          NearestYearInWithOptionLies.yearStartingPrice = newCarPrice;
          NearestYearInWithOptionLies.lastPriceUpdateDateYear = new Date();
          let newTypesOfModel = ModelInWichOptionLies.types.map((year) => {
            if (
              year._id.toString() === NearestYearInWithOptionLies._id.toString()
            ) {
              return NearestYearInWithOptionLies;
            } else return year;
          });
          ModelInWichOptionLies.types = newTypesOfModel;
          let newModelsOfBrabnd = brand.models.map((model) => {
            if (model._id.toString() === ModelInWichOptionLies._id.toString()) {
              return ModelInWichOptionLies;
            } else return model;
          });
          brand.models = newModelsOfBrabnd;
        }
      }

      return brand;
    };

    const saveCategoryWithModelPriceChangesMade = (
      newCarPrice,
      brand,
      modelId,
      optionName
    ) => {
      let newBrand = putCarPriceInBrandAndUpdate({
        newCarPrice,
        brand,
        modelId,
        optionName,
      });
      setLoading(true);
      setMessage("در حال ارسال درخواست ثبت");
      apiClient.post(
        ApiHelper.addEditCategory_POST,
        (error, response) => {
          if (error) {
            setLoading(false);
            setAlertType("error");
            setMessage("مشکلی در هنگام ویرایش قیمت صفر مدل رخ داد");
          } else {
            setLoading(false);
            setAlertType("success");
            setMessage("ویرایش با موفقیت انجام شد");
            // setGetCarsAfterApis(!getCarsAfterCallingApis);
            setFilter({
              brand: "",
              model: "",
              price: "",
              option: "",
            });
            makeSearchInputsClear();
            setModelResults([]);
          }
        },
        newBrand
      );
    };
    const brandNames = records.map((record) => ({ label: record.brand }));
    const modelNames = records.map((record) => {
      const allRecordsModels = record?.models.map((model) => ({
        label: model.model,
      }));
      return allRecordsModels;
    });
    //we gotta have a certain algorithm here...
    const priceModels = records.map((record) => {
      const allRecordsModels = record?.models.map((model) => ({
        label: PriceFormater(model.modelStartingPrice),
      }));
      return allRecordsModels;
    });
    let unoptionNames = [];
    let uniqueOptions = [];
    records.forEach((record) => {
      record?.models.forEach((model) => {
        model?.types?.forEach((type) => {
          type.options.forEach((option) => {
            unoptionNames.push({ label: `${option.option}` });
          });
        });
      });
    });
    let optionNames = unoptionNames.filter((option) => {
      const isDuplicate = uniqueOptions.includes(option.label);
      if (!isDuplicate) {
        uniqueOptions.push(option.label);
        return true;
      }
      return false;
    });
    const searchFilterChange = (changeInput, value) => {
      setFilter((prevState) => ({ ...prevState, [changeInput]: value }));
    };

    const searchFilterHandler = () => {
      setSearchLoading(true);
      let filterSearch = filter;
      Object.keys(filterSearch).forEach((key) => {
        if (filterSearch[key] === "") {
          delete filterSearch[key];
        }
      });
      apiClient.post(
        ApiHelper.gettingAllModelsOfCarCategory,
        (error, response) => {
          if (error) {
            setAlertType("error");
            setMessage("مشکلی در هنگام گرفتن مدل ها رخ داد");
          } else {
            setAlertType("success");
            setMessage("گرفتن مدل ها انحام شد");
            setGetCarsAfterApis(!getCarsAfterCallingApis);
            setModelResults(response.models);
            setSearchLoading(false)
            if (filterSearch.option) {
              let modelOpt = ReturnModelBasedOnOption(
                response.models,
                filterSearch.option
              );
              setModelResults(modelOpt);
            }
            if (!response.models.length) {
              setAlertType("error");
              setMessage("نتیجه ای یافت نشد");
              setFilter({
                brand: "",
                model: "",
                price: "",
                option: "",
              });
              makeSearchInputsClear();
            }
          }
        },
        filterSearch
      );
    };
    const cancelSearch = () => {
      setModelResults([]);
      makeSearchInputsClear();
      setFilter({
        brand: "",
        model: "",
        price: "",
        option: "",
      });
    };
    const modelsSearchResultTable = modelResults.length ? (
      <>
        <Grid item xs={12}>
          <h1>نتیجه جستجو</h1>
        </Grid>
        {
          <CarPriceBrands
            newCarAdded={getCarsAfterCallingApis}
            models={modelResults || []}
            brand={""}
            saveCategoryWithModelPriceChangesMade={
              saveCategoryWithModelPriceChangesMade
            }
          />
        }
      </>
    ) : null;

    const breadcrumbs = modelResults.length ? (
      <div
        className={styles.breadCrumbsContainer}
        onClick={() => cancelSearch()}
      >
        <div className={styles.breadCrumbClose}>&#10006;</div>
        <div>جستجو:</div>
        {Object.entries(filter).map(([key, value]) => {
          if (value !== "") {
            return <div className={styles.breadCrumb}>{`${value} / `}</div>;
          }
        })}
      </div>
    ) : null;
    const [brandNameValues, setBrandNameValues] = useState([]);
    const [modelNamesValues, setModelNamesValues] = useState([]);
    const [priceNumberValue, setPriceNumberValues] = useState([]);
    const makeSearchInputsClear = () => {
      setBrandNameValues([]);
      setModelNamesValues([]);
      setPriceNumberValues([]);
    };
    return (
      <Main
        page={"car_price"}
        pageName="قیمت خودروها"
        context={props.history}
        transparentBar={false}
        leftAppBarComponent={
          <Fab
            onClick={() => addNew()}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            ایجاد
            <AddIcon />
          </Fab>
        }
      >
        <Grid container spacing={2}>
          <SearchCarPrice
            brandNames={brandNames}
            modelNames={modelNames.flat(1)}
            priceModels={priceModels.flat(1)}
            optionNames={optionNames}
            searchFilterChange={searchFilterChange}
            searchFilterHandler={searchFilterHandler}
            filter={filter}
            searchLoading={searchLoading}
            brandNameValues={brandNameValues}
            setBrandNameValues={setBrandNameValues}
            modelNamesValues={modelNamesValues}
            setModelNamesValues={setModelNamesValues}
            priceNumberValue={priceNumberValue}
            setPriceNumberValues={setPriceNumberValues}
          />

          {modelResults.length ? breadcrumbs : null}
          {modelResults.length
            ? modelsSearchResultTable
            : records.length
            ? records.map((brand, index) => {
                return (
                  <Grid key={brand._id} item xs={12}>
                    <h1>{brand.brand}</h1>
                    <CarPriceBrands
                      newCarAdded={getCarsAfterCallingApis}
                      models={brand.models || []}
                      brand={brand}
                      saveCategoryWithModelPriceChangesMade={
                        saveCategoryWithModelPriceChangesMade
                      }
                    />
                  </Grid>
                );
              })
            : null}
        </Grid>
        {loading && (
          <>
            <div className={styles.boxskeletonClass}>
              <CustomSkeleton
                customClass={styles.firstSkeletonStyle}
                variant="rect"
                width={120}
                height={30}
              />
              <CustomSkeleton
                customClass={styles.skeletonStyle}
                variant="rect"
                height={30}
              />
              <CustomSkeleton variant="rect" height={400} />
            </div>
            <div className={styles.boxskeletonClass}>
              <CustomSkeleton
                customClass={styles.firstSkeletonStyle}
                variant="rect"
                width={120}
                height={30}
              />
              <CustomSkeleton
                customClass={styles.skeletonStyle}
                variant="rect"
                height={30}
              />
              <CustomSkeleton variant="rect" height={400} />
            </div>
          </>
        )}
      </Main>
    );
  })
);

export default CarPrice;
