exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".reporting_rowBoxStyle__vLN9a {\n  padding: 10px 0 !important;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.reporting_titleAccordionStyle__2T0F3 {\n  min-height: 0 !important;\n}\n.reporting_resultsSearchStyle__lTVkQ {\n  margin-top: 10px;\n}\n.reporting_borkerMessageStyle__3GtMH {\n  text-align: center;\n  padding: 20px;\n}\n.reporting_linkDownloadStyle__WyWPo {\n  display: flex;\n  justify-content: center;\n  padding-bottom: 30px;\n}\n.reporting_progressStyle__2PZ1z {\n  margin-top: 5px;\n}\n", ""]);

// Exports
exports.locals = {
	"rowBoxStyle": "reporting_rowBoxStyle__vLN9a",
	"titleAccordionStyle": "reporting_titleAccordionStyle__2T0F3",
	"resultsSearchStyle": "reporting_resultsSearchStyle__lTVkQ",
	"borkerMessageStyle": "reporting_borkerMessageStyle__3GtMH",
	"linkDownloadStyle": "reporting_linkDownloadStyle__WyWPo",
	"progressStyle": "reporting_progressStyle__2PZ1z"
};