import React, { useState } from "react";
import { Box, Pagination, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
const PaginationPanel = ({
  marginTop,
  paddingBottom,
  count,
  currentPage,
  onSetPageStore,
}) => {
  const maxWidthMobile = useMediaQuery("(max-width: 600px)");
  const maxWidth345 = useMediaQuery("(max-width: 345px)");
  const [page, setPage] = useState(currentPage);
  const dispatch = useDispatch();

  const handleOnChange = (e, value) => {
    setPage(value);
    dispatch(onSetPageStore(value));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop,
        paddingBottom,
      }}
    >
      <Pagination
        count={+count}
        page={+page}
        variant="outlined"
        boundaryCount={1}
        siblingCount={maxWidthMobile ? 0 : 1}
        color="primary"
        shape="rounded"
        onChange={handleOnChange}
        hideNextButton={count - page === 0}
        hidePrevButton={page - 1 === 0}
        sx={[
          {
            display: count <= 1 ? "none" : "flex",

            "& .MuiButtonBase-root.MuiPaginationItem-root": {
              border: "unset",
              "&:hover": { bgcolor: "#2196F3", color: "#fff" },
              "&.Mui-select": {
                bgcolor: "#2196F3",
              },

              "&.Mui-selected": {
                backgroundColor: "#2196F3 !important",
                color: "#fff",
              },
            },
          },
          maxWidth345 && {
            "& .MuiPagination-ul li .MuiButtonBase-root.MuiPaginationItem-root":
              {
                fontSize: 12,
                minWidth: 23,
              },
          },
        ]}
      />
    </Box>
  );
};

export default PaginationPanel;
