export const dateFormatter = (iso, isLongMonth) => {
  const date = new Date(iso);
  const solarDate = new Intl.DateTimeFormat("fa", {
    year: "numeric",
    day: "2-digit",
    month: `${isLongMonth ? "long" : "2-digit"}`,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(date);

  const arraySolarDate = solarDate.split(" ");
  return {
    dateLong: `${arraySolarDate.at(0)} ${arraySolarDate.at(
      1
    )} ${arraySolarDate.at(2)}`,
    dateShort: `${arraySolarDate.at(0).replace(",", "")}`,
    longMonth: function () {
      return this.dateLong;
      // second parameter must be true
    },

    clock: function () {
      return arraySolarDate.at(-1);
    },

    shortMonth: function () {
      return this.dateShort;
    },
  };
};
