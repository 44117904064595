import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import { Styles } from "../../../values/Styles";
import RequestState from "../../common/RequestState";
import AlertDialog from "../../common/AlertDialog";
import PaginationView from "../../common/PaginationView";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { Chip, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ListItems from "./ListItems";
import ChangeStatusDialog from "./ChangeStatusDialog";
import SellersList from "./SellersList";
import Drawer from "@material-ui/core/Drawer";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import styles from "./broker.module.css";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
let apiClient = null;

@inject("AccountStore")
@inject("BrokersStore")
@inject("SellersStore")
@observer
class Brokers extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();
    if (props.AccountStore.token === "") props.history.replace("/");
    let pageTitle = Brokers.getPageTitle(props.match.params.status);
    this.state = {
      status: props.match.params.status,
      agentId: props.match.params.agentId,
      pageTitle: pageTitle,
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      totalPage: 1,
      search: "",
      page: props.match.params.page ? parseInt(props.match.params.page) : 1,
      message: "",
      changeEnableSMSRequestIndex: -1,
      changeStatusUpdateIndexRequest: -1,
      alertDialogQuestionMessage: "",
      showStatusDialog: false,
      seller: {},
      getAdminBroker: false, //this is the state which will be triggered to get brokers with no seller attached in admin to them
      sellerDrawer: true,
      selectAllBroker: false,
      selectedBrokers: [],
      sellerName: this.props.history.location?.state?.agentName,
      flag: true,
    };

    this.alertFunction = null;
  }

  static getPageTitle(status) {
    let pageTitle = "بروکرها";
    if (status) {
      switch (status) {
        case "online":
          pageTitle = "آنلاین ها";
          break;
        case "accepted":
          pageTitle = "تایید شده";
          break;
        case "blocked":
          pageTitle = "مسدود شده";
          break;
      }
    }
    return pageTitle;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.status !== nextProps.match.params.status) {
      let pageTitle = Brokers.getPageTitle(nextProps.match.params.status);
      return {
        page: 1,
        pageTitle: pageTitle,
        status: nextProps.match.params.status,
        sendRequest: false,
        sendRequestErr: false,
        sendRequestStatus: "",
        data: [],
        totalPage: 1,
        showCreateCarDialog: false,
        search: "",
        getAdminBroker: false, //this is the state which will be triggered to get brokers with no seller attached in admin to them
        sellerDrawer: false,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.componentIsAvailable = true;
    if (prevState.status !== this.state.status) {
      this.setState({ sellerName: null });
      this.getRequests(true);
    }
    if (this.props.match.params.agentId !== prevState.agentId) {
      this.setState({ agentId: this.props.match.params.agentId });
      this.getRequests(true);
    }

    if (prevState.seller.phone !== this.state.seller.phone) {
      this.getRequests(true);
    }
    if (prevState.seller._id !== this.state.seller._id) {
      this.setState({
        selectedBrokers: [],
      });
    }
  }

  componentWillUnmount() {
    this.componentIsAvailable = false;
    if (this.getRequestsTimeout) {
      clearTimeout(this.getRequestsTimeout);
    }
  }

  componentDidMount(pre, state) {
    this.setState({ flag: false });
    this.updateUrl();
    this.getRequests();
  }

  search(t) {
    if (this.intervalSearch) {
      clearTimeout(this.intervalSearch);
    }
    this.intervalSearch = setTimeout(() => {
      this.setState(
        {
          search: t,
          data: [],
          totalPage: 1,
          page: 1,
          message: "",
        },
        () => this.getRequests(true)
      );
    }, 800);
  }

  getRequests(ref, update) {
    if (this.state.sendRequest) {
      return;
    }
    if (ref) {
      if (this.getRequestsTimeout) {
        clearTimeout(this.getRequestsTimeout);
      }
      this.state.data = [];
      this.state.page = 1;
      this.state.totalPage = 1;
    }
    if (!update) {
      this.setState({
        sendRequest: true,
        sendRequestErr: false,
        changeStatusUpdateIndexRequest: -1,
      });
    }
    let data = {};

    if (this.state.search && this.state.search.trim() !== "")
      data["search"] = this.state.search;

    if (this.state.status === "online") {
      data["online_status"] = "online";
    }

    if (this.state.getAdminBroker) {
      data["getAdminBroker"] = true;
    }

    data["agentId"] = this.props.match.params.agentId;
    this.setState({ sendRequest: true });

    if (data.agentId === "undefined" || data.agentId === undefined) {
      this.setState({ sellerName: null });
      delete data.agentId;
    }

    apiClient.get(
      ApiHelper.getBrokers_GET(
        this.props.match.params.status,
        this.props.match.params.page || 1
      ),
      (error, response) => {
        if (this.getRequestsTimeout) {
          clearTimeout(this.getRequestsTimeout);
        }
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          console.log(response);
          if (
            this.componentIsAvailable &&
            (this.state.status === "accepted" || this.state.status === "online")
          ) {
            this.getRequestsTimeout = setTimeout(() => {
              this.getRequests(false, this.state.status);
            }, 5000);
          }
          if (update && update !== this.state.status) {
            return;
          }
          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
            totalPage: response.totalPage,
          });
        }
      },
      data
    );
  }

  updateUrl() {
    const { status, page } = this.state;
    const agentId = this.props.match.params.agentId;
    const newUrl = `/brokers/${status}/${agentId}/${page}`;
    this.props.history.replace(newUrl);
  }

  changeEnableSMSRequest(brokerId, index) {
    if (this.state.changeEnableSMSRequestIndex >= 0) {
      return;
    }

    this.setState({ changeEnableSMSRequestIndex: index });

    apiClient.get(
      ApiHelper.changeEnableSms_GET(brokerId),
      (error, response) => {
        if (error) {
          this.setState({
            changeEnableSMSRequestIndex: -1,
            message: response ? response.message : "",
          });
        } else {
          if (this.state.data[index]._id === response.brokerId) {
            this.state.data[index].enableSMS = response.status;
          }
          this.setState({ changeEnableSMSRequestIndex: -1 });
        }
      }
    );
  }

  logoutPWA(id, i) {
    this.alertFunction = this.logoutPWARequest.bind(this, id, i);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به خروج از pwa بروکر هستید ؟",
    });
  }

  logoutPWARequest(id, index) {
    if (this.state.changeEnableSMSRequestIndex >= 0) {
      return;
    }

    this.setState({ changeEnableSMSRequestIndex: index });

    apiClient.get(
      ApiHelper.logoutBrokerById_GET(id, "pwa"),
      (error, response) => {
        if (error) {
          this.setState({
            changeEnableSMSRequestIndex: -1,
            message: response ? response.message : "",
          });
        } else {
          if (this.state.data[index]._id === response.broker._id) {
            this.state.data[index] = response.broker;
          }
          this.setState({ changeEnableSMSRequestIndex: -1 });
        }
      }
    );
  }

  handleSelectBrokerId(idBroker) {
    const { selectedBrokers } = this.state;
    const isSelected = selectedBrokers.some((item) => item === idBroker);
    if (isSelected) {
      const updatedItems = selectedBrokers.filter((item) => item !== idBroker);
      this.setState({ selectedBrokers: updatedItems });
    } else {
      this.setState({ selectedBrokers: [...selectedBrokers, idBroker] });
    }
  }

  handlerShowNameBroker() {
    if (this.state.seller.name || this.state.sellerName) {
      return (
        <div className={styles.iconAllBrokerStyle}>
          <Tooltip placement="top" title="نمایش همه بروکر ها">
            <IconButton
              onClick={(seller) => {
                this.setState({
                  seller: seller,
                  sellerDrawer: false,
                  getAdminBroker: false,
                });
                this.props.history.replace("/brokers/accepted/undefined/1");
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
          <h4> : {this.state.seller.name || this.state.sellerName}</h4>
        </div>
      );
    } else {
      return <h4>همه بروکر ها</h4>;
    }
  }

  handlerCheckedAllBroker() {
    const allIdBrokers = this.state.data.map((item) => item._id);
    this.setState({
      selectAllBroker: !this.state.selectAllBroker,
      selectedBrokers: allIdBrokers,
    });
    if (this.state.selectAllBroker) {
      this.setState({
        selectedBrokers: [],
      });
    }
  }
  ChangeSellerOfBrokers() {
    this.props.SellersStore.setChangeSellerItem({});
    this.props.history.push("/sellers/accepted", {
      idBrokers: this.state.selectedBrokers,
      nameSeller: this.state.seller.name,
    });
  }

  renderResult() {
    if (this.state.data.length <= 0) {
      return (
        <div style={{ margin: 10 }}>
          {`لیست بروکر ها  ${this.state.pageTitle} خالی.`}
        </div>
      );
    }
    return (
      <div style={{ ...Styles.containerWrap900, padding: 10 }}>
        {this.state.data.map((item, i) => (
          <ListItems
            key={item._id}
            brokerId={item._id}
            yarn
            phone={item.phone}
            name={item.name}
            selectFirstBroke={this.state.selectedBrokers.length > 0}
            handleSelectBrokerId={this.handleSelectBrokerId.bind(this)}
            onClickEdit={() => this.edit(item)}
            selectAllBroker={this.state.selectAllBroker}
            onClickStatus={() => this.setState({ showStatusDialog: item._id })}
            status={item.status}
            onlineTime={item.onlineTime}
            isOnline={item.isOnline}
            enableSMS={item.enableSMS}
            activityType={item.activityType}
            sessions={item.sessions}
            logoutPWAOnClick={() => this.logoutPWA(item._id, i)}
            changeEnableSMSStatus={this.state.changeEnableSMSRequestIndex === i}
            changeEnableSMS={() => this.changeEnableSMSRequest(item._id, i)}
            sellerName={item.agentId ? item.agentId.name : "---"}
            onClickChangeSeller={
              this.props.AccountStore.type === "admin"
                ? () => {
                    this.props.SellersStore.setChangeSellerItem(item);
                    this.props.history.push("/sellers/accepted");
                  }
                : null
            }
            statusIndicator={this.state.changeStatusUpdateIndexRequest === i}
            PositiveRate={item.PositiveRate}
            NegativeRate={item.NegativeRate}
          />
        ))}
      </div>
    );
  }

  addNew() {
    this.props.BrokersStore.emptyCurrent();
    this.props.history.push(
      `/add_edit_broker/${this.state.status}/${this.props.match.params.agentId}/${this.state.page}`
    );
  }
  edit(item) {
    this.props.BrokersStore.setCurrent(item);
    if (item.agentId) {
      this.props.history.push(
        `/add_edit_broker/${this.state.status}/${item.agentId._id}/${this.state.page}`,
        { agentName: item.agentId.name }
      );
    } else {
      this.props.history.push(
        `/add_edit_broker/${this.state.status}/undefined/${this.state.page}`
      );
    }
  }

  pushIDInUrl(isId) {
    this.props.history.push(`/brokers/accepted/${isId}/1`);
  }

  updateChangeStatus(item) {
    let i = this.state.data.findIndex((t) => t._id === item._id);
    this.setState(
      { showStatusDialog: false, changeStatusUpdateIndexRequest: i },
      () => this.getRequests()
    );
  }

  updateItem(item) {
    let i = this.state.data.findIndex((t) => t._id === item._id);
    this.state.data[i] = item;
    this.setState({ ref: true });
  }

  toggleDrawer() {
    this.setState({ sellerDrawer: !this.state.sellerDrawer });
  }

  render() {
    return (
      <Main
        page={
          "brokers/" +
            this.state.status +
            this.state.agentId +
            this.state.page ?? 1
        }
        pageName={"بروکرها - " + this.state.pageTitle}
        centerAppBarComponent={
          <RequestState
            error={this.state.sendRequestErr}
            retryOnPress={() => {
              this.getRequests();
            }}
            title={"در حال دریافت لیست ..."}
            request={this.state.sendRequest}
          />
        }
        leftAppBarComponent={
          <Fab
            onClick={() => this.addNew()}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            ایجاد
            <AddIcon />
          </Fab>
        }
        context={this.props.history}
      >
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, marginBottom: 10 }}>
            <TextField
              onChange={(t) => this.search(t.target.value)}
              placeholder="جستجو ..."
              type="search"
              defaultValue={this.state.search}
              variant="standard"
              margin={"dense"}
              style={{ maxWidth: 190, margin: 20 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  this.search(ev.target.value);
                }
              }}
            />
          </div>
          <Drawer
            anchor={"right"}
            open={this.state.sellerDrawer}
            onClose={() => this.toggleDrawer()}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconButton onClick={() => this.toggleDrawer()}>
                <Icon style={{ color: "#000" }}>close</Icon>
              </IconButton>
            </div>
            <SellersList
              status="accepted"
              handleSellerClick={(seller) => {
                this.setState({
                  seller: seller,
                  sellerDrawer: false,
                  getAdminBroker: false,
                });
                this.pushIDInUrl(seller._id);
              }}
              handleGetAdminBrokers={() =>
                this.setState({
                  getAdminBroker: true,
                  seller: {},
                  sellerDrawer: false,
                })
              }
              seller={this.state.seller}
            />
          </Drawer>

          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            <div className={styles.headerListBrokerStyle}>
              {this.handlerShowNameBroker()}
              {this.state.selectedBrokers.length > 1 &&
                this.props.AccountStore.type === "admin" && (
                  <Button
                    onClick={() => this.ChangeSellerOfBrokers()}
                    variant="contained"
                    color="primary"
                  >
                    تغییر فروشنده بروکر های انتخاب شده
                  </Button>
                )}

              <div>
                {this.state.data.length > 0 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.selectAllBroker}
                        onChange={() => {
                          this.handlerCheckedAllBroker();
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="انتخاب همه"
                  />
                )}
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => this.toggleDrawer()}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </div>
            {this.renderResult()}
          </div>
        </div>

        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <PaginationView
            total={parseInt(this.state.totalPage)}
            page={
              this.props.match.params.page
                ? parseInt(this.props.match.params.page)
                : 1
            }
            nextOnPress={() => {
              this.getRequests();
              this.state.page += 1;
              this.updateUrl();
            }}
            prevOnPress={() => {
              this.state.page -= 1;
              this.updateUrl();
              this.getRequests();
            }}
          />
        </div>

        {this.state.showStatusDialog && (
          <ChangeStatusDialog
            id={this.state.showStatusDialog}
            history={this.props.history}
            onClose={() => this.setState({ showStatusDialog: false })}
            onSuccess={(item) => {
              this.updateChangeStatus(item);
            }}
            onUpdateItem={(item) => {
              this.updateItem(item);
            }}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}

        {this.state.alertDialogQuestionMessage !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) this.alertFunction();
              this.alertFunction = null;
              this.setState({ alertDialogQuestionMessage: "" });
            }}
            type="alert"
            question={true}
            description={this.state.alertDialogQuestionMessage}
          />
        )}
      </Main>
    );
  }
}

export default Brokers;
