import toast, { ToastBar, Toaster } from "react-hot-toast";
import React from "react";
const ToasterError = () => {
  return (
    <Toaster>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <button
                  style={{
                    background: "unset",
                    fontSize: "26px",
                    color: "#fff",
                    border: "unset",
                    cursor: "pointer",
                  }}
                  onClick={() => toast.dismiss(t.id)}
                >
                  &times;
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default ToasterError;
