import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useFormik } from "formik";
import { usePostCarInquiryMutation } from "../../../Store/callApi/carInquiryApi";
import { validationFormikInquiryCar } from "../../../values/validation_formik/validationFormikInquiryCar";
import CloseModal from "../../../packages/icons/CloseModal";
import TinyBlueCar from "../../../packages/icons/TinyBlueCar";
import ClearInput from "../../../packages/icons/ClearInput";
import Rial from "../../../packages/icons/rial";
import useStyles from "./CarInquiryDialogStyle";
import DatePickerMobile from "../../common/DatePickerMobile/DatePickerMobile";
import { fullWidth } from "../../../styles/main";
import { ApiClient, ApiHelper } from "../../../data/remote";
import { updateDate } from "../../../Store/slice/dateSlice";
import { useSelector } from "react-redux";
import PriceFormater from "../../../utils/priceFormater";
import WarningIcon from "@material-ui/icons/Warning";
var moment = require("moment-jalaali");

const CarInquiryModal = ({ isOpen, onClose, carId }) => {
  const classes = useStyles();
  const dateStyle = fullWidth();
  const [postCarInquiry] = usePostCarInquiryMutation();

  const inquiryPaidDate = useSelector(
    ({ inquiryData }) => inquiryData.paidDate
  );

  const [model, setModel] = useState(null);
  const [year, setYear] = useState(null);
  const [brand, setBrand] = useState(null);
  const [datePick, setDatePick] = useState("");
  const [isInquiryAccess, setIsInquiryAccess] = useState("");

  const formik = useFormik({
    initialValues: {
      vehicleViolation: "",
      customerPhoneNumber: "",
      freeRoadToll: "",
      transferTax: "",
      effectsOfTrafficPlan: "",
      annualFees: "",
      governmentCounterOfficeFee: "",
      total: "",
      paidDate: inquiryPaidDate || "",
      paid: false,
    },
    validationSchema: validationFormikInquiryCar,
    onSubmit: (values) => {
      const inquiryData = {
        vehicleViolation: parseInt(values.vehicleViolation, 10),
        customerPhoneNumber: parseInt(values.customerPhoneNumber, 10),
        freeRoadToll: parseInt(values.freeRoadToll, 10),
        transferTax: parseInt(values.transferTax, 10),
        effectsOfTrafficPlan: parseInt(values.effectsOfTrafficPlan, 10),
        annualFees: parseInt(values.annualFees, 10),
        governmentCounterOfficeFee: parseInt(
          values.governmentCounterOfficeFee,
          10
        ),
        total: parseInt(values.total, 10),
        paid: values.paid,
        paidDate: inquiryPaidDate?.paidDate,
      };

      try {
        postCarInquiry({ carId, inquiryData }).unwrap();
        onClose();
      } catch (error) {
        console.error("Failed to submit car inquiry:", error);
      }
    },
  });

  useEffect(() => {
    if (carId) {
      const apiClient = ApiClient.getInstance();

      apiClient.get(ApiHelper.getCarInfoById_GET(carId), (error, response) => {
        if (error) {
          console.log("error", error);
        } else {
          const inquiry = response?.data?.inquiry;
          setDatePick(response?.data?.inquiry.paidDate);
          setModel(response?.data?.model);
          setYear(response?.data?.year);
          setBrand(response?.data?.brand);
          setIsInquiryAccess(response.data.hasWriteInquiryAccess);
          formik.setValues((prev) => ({
            ...prev,
            vehicleViolation: inquiry?.vehicleViolation || "",
            customerPhoneNumber: inquiry?.customerPhoneNumber || "",
            freeRoadToll: inquiry?.freeRoadToll || "",
            transferTax: inquiry?.transferTax || "",
            effectsOfTrafficPlan: inquiry?.effectsOfTrafficPlan || "",
            annualFees: inquiry?.annualFees || "",
            governmentCounterOfficeFee:
              inquiry?.governmentCounterOfficeFee || "",
            paidDate: inquiry?.paidDate || "",
            total: inquiry?.total || "",
          }));
        }
      });
    }
  }, [carId]);

  useEffect(() => {
    const total = calculateTotal();
    formik.setFieldValue("total", total.toString());
  }, [
    formik.values.vehicleViolation,
    formik.values.freeRoadToll,
    formik.values.transferTax,
    formik.values.effectsOfTrafficPlan,
    formik.values.annualFees,
    formik.values.governmentCounterOfficeFee,
  ]);

  const calculateTotal = () => {
    return (
      (parseInt(formik.values.vehicleViolation, 10) || 0) +
      (parseInt(formik.values.freeRoadToll, 10) || 0) +
      (parseInt(formik.values.transferTax, 10) || 0) +
      (parseInt(formik.values.effectsOfTrafficPlan, 10) || 0) +
      (parseInt(formik.values.annualFees, 10) || 0) +
      (parseInt(formik.values.governmentCounterOfficeFee, 10) || 0)
    );
  };

  const handleClearInput = (inputId) => {
    formik.setFieldValue(inputId, "");
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modal}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <form onSubmit={formik.handleSubmit} className={classes.modalContent}>
        <div className={classes.topBar}>
          <Typography variant="h6" id="custom-modal-title">
            استعلام خودرو
          </Typography>
          <Button onClick={onClose} color="secondary">
            <CloseModal />
          </Button>
        </div>
        <Divider />
        <div className={classes.topBarContainer}>
          <div className={classes.icon}>
            <TinyBlueCar />
            <p className={classes.tinytext}>خودرو</p>
          </div>
          <div className={classes.triple}>
            <div>{brand}</div>
            <div className={classes.tinyLines}></div>
            <div>{model}</div>
            <div className={classes.tinyLines}></div>
            <div>{year}</div>
          </div>
        </div>
        <Grid container spacing={2} className={classes.inputContainer}>
          <Grid item xs={12} className={classes.inputItem}>
            <TextField
              id="name"
              label="نام خودرو"
              variant="outlined"
              fullWidth
              value={model}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClearInput("name")}
                      edge="end"
                    ></IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <TextField
              value={formik.values.customerPhoneNumber}
              id="customerPhoneNumber"
              name="customerPhoneNumber"
              label="شماره تماس"
              variant="outlined"
              fullWidth
              type="text"
              {...formik.getFieldProps("customerPhoneNumber")}
              error={
                formik.touched.customerPhoneNumber &&
                Boolean(formik.errors.customerPhoneNumber)
              }
              helperText={
                formik.touched.customerPhoneNumber &&
                formik.errors.customerPhoneNumber
              }
              placeholder="شماره مشتری"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClearInput("customerPhoneNumber")}
                      edge="end"
                    >
                      {" "}
                      <ClearInput />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <DatePickerMobile
              value={formik.values.paidDate}
              className={dateStyle.root}
              param="paidDate"
              updateStore={updateDate}
              dateOnChange={(value) =>
                formik.setFieldValue("paidDate", value.toISOString())
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <TextField
              value={PriceFormater(formik.values.vehicleViolation)}
              id="vehicleViolation"
              label="خلافی خودرو"
              variant="outlined"
              fullWidth
              type="text"
              onChange={formik.handleChange}
              error={
                formik.touched.vehicleViolation &&
                Boolean(formik.errors.vehicleViolation)
              }
              helperText={
                formik.touched.vehicleViolation &&
                formik.errors.vehicleViolation
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClearInput("vehicleViolation")}
                      edge="end"
                    >
                      <div className={classes.iconContainer}>
                        <Rial />
                        <ClearInput />
                      </div>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <TextField
              value={PriceFormater(formik.values.freeRoadToll)}
              id="freeRoadToll"
              label="عوارض آزادراهی"
              variant="outlined"
              fullWidth
              type="text"
              onChange={formik.handleChange}
              error={
                formik.touched.freeRoadToll &&
                Boolean(formik.errors.freeRoadToll)
              }
              helperText={
                formik.touched.freeRoadToll && formik.errors.freeRoadToll
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClearInput("freeRoadToll")}
                      edge="end"
                    >
                      {" "}
                      <div className={classes.iconContainer}>
                        <Rial />
                        <ClearInput />
                      </div>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <TextField
              value={PriceFormater(formik.values.transferTax)}
              id="transferTax"
              label="مالیات نقل و انتقال"
              variant="outlined"
              fullWidth
              type="text"
              onChange={formik.handleChange}
              error={
                formik.touched.transferTax && Boolean(formik.errors.transferTax)
              }
              helperText={
                formik.touched.transferTax && formik.errors.transferTax
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClearInput("transferTax")}
                      edge="end"
                    >
                      {" "}
                      <div className={classes.iconContainer}>
                        <Rial />
                        <ClearInput />
                      </div>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <TextField
              value={PriceFormater(formik.values.effectsOfTrafficPlan)}
              id="effectsOfTrafficPlan"
              label="عوارض طرح ترافیک"
              variant="outlined"
              fullWidth
              type="text"
              onChange={formik.handleChange}
              error={
                formik.touched.effectsOfTrafficPlan &&
                Boolean(formik.errors.effectsOfTrafficPlan)
              }
              helperText={
                formik.touched.effectsOfTrafficPlan &&
                formik.errors.effectsOfTrafficPlan
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClearInput("effectsOfTrafficPlan")}
                      edge="end"
                    >
                      {" "}
                      <div className={classes.iconContainer}>
                        <Rial />
                        <ClearInput />
                      </div>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <TextField
              value={PriceFormater(formik.values.annualFees)}
              id="annualFees"
              label="عوارض سالیانه"
              variant="outlined"
              fullWidth
              type="text"
              onChange={formik.handleChange}
              error={
                formik.touched.annualFees && Boolean(formik.errors.annualFees)
              }
              helperText={formik.touched.annualFees && formik.errors.annualFees}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClearInput("annualFees")}
                      edge="end"
                    >
                      {" "}
                      <div className={classes.iconContainer}>
                        <Rial />
                        <ClearInput />
                      </div>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <TextField
              value={formik.values.governmentCounterOfficeFee}
              id="governmentCounterOfficeFee"
              label="کارمزد دفتر پیشخوان دولت"
              variant="outlined"
              fullWidth
              type="text"
              onChange={formik.handleChange}
              error={
                formik.touched.governmentCounterOfficeFee &&
                Boolean(formik.errors.governmentCounterOfficeFee)
              }
              helperText={
                formik.touched.governmentCounterOfficeFee &&
                formik.errors.governmentCounterOfficeFee
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClearInput("governmentCounterOfficeFee")
                      }
                      edge="end"
                    >
                      {" "}
                      <div className={classes.iconContainer}>
                        <Rial />
                        <ClearInput />
                      </div>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <TextField
              value={PriceFormater(formik.values.total)}
              id=""
              label="جمع پرداختی"
              variant="outlined"
              fullWidth
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <Rial />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <FormControlLabel
              control={
                <Checkbox
                  id="paid"
                  checked={formik.values.paid}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
              label="پرداخت شده"
            />
          </Grid>
          <Grid item xs={12} className={classes.inputItem}>
            <div className={classes.buttonsContainer}>
              {!isInquiryAccess && (
                <h4 className={classes.textContainer}>
                  <WarningIcon />
                  همکار گرامی شما دسترسی به ویرایش استعلام را ندارید.
                </h4>
              )}
              <div>
                <Button
                  disabled={!isInquiryAccess}
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  type="submit"
                >
                  تایید
                </Button>

                <Button
                  variant="contained"
                  className={classes.cancelButton}
                  onClick={onClose}
                >
                  انصراف
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

CarInquiryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  carId: PropTypes.string.isRequired,
};

export default CarInquiryModal;
