import React from "react";
import { FormControl, Switch } from "@material-ui/core";

const SwitchForm = ({ checked, handleChange, isDisabled }) => {
  return (
    <FormControl fullWidth>
      <Switch
        disabled={isDisabled}
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </FormControl>
  );
};

export default SwitchForm;
