import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { ApiClient } from "../../data/remote";
import Button from "@material-ui/core/Button";
import SnackBar from "../common/SnackBar";
import AlertDialog from "../common/AlertDialog";
import FormLabel from "@material-ui/core/FormLabel";
import RequestState from "../common/RequestState";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import SelectImage from "./SelectImage";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import { FileDrop } from "react-file-drop";
import { inject } from "mobx-react";

let apiClient = null;

@inject("CarsStore")
class EditorGallery extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    this.state = {
      sendRequest: false,
      sendRequestErrMessage: "",
      imageFileSRC: "",
      inputWarn: 0,
      warnInputMessage: "",
      isChangedImage: false,
      success: false,
      galleryRequest: false,
      galleryRequestErr: false,
      alertDialogQuestionMessage: "",
      multipleSize: 0,
    };

    this.alertFunction = null;
  }

  componentDidMount() {
    if (this.props.forceGetGallery) {
      this.getGallery();
    }

    if (this.props.CarsStore[this.props.storeKey].length <= 0) {
      this.getGallery();
    }
  }

  getGallery() {
    if (this.state.galleryRequest) {
      return;
    }

    this.setState({ galleryRequest: true });

    apiClient.get(this.props.getGalleryURL, (error, response) => {
      this.setState({ galleryRequest: false });
      if (error) {
        this.setState({ galleryRequestErr: true });
      } else {
        if (response.success) {
          this.props.CarsStore[this.props.storeKey] = response.data;
          this.setState({ ref: true });
        } else {
          this.setState({ galleryRequestErr: true });
        }
      }
    });
  }

  uploadImage(images) {
    if (!this.props?.hasChangeAccess) {
      this.props?.accessError();
      return;
    }
    if (this.state.galleryRequest) {
      return;
    }

    this.setState({ galleryRequest: true, multipleSize: images.length });
    let data = null;

    if (!images.length) images = [images];

    [...images].forEach((img) => {
      data = new FormData();
      data.append("image", img, img.name);
      apiClient.postFile(
        this.props.uploadImageURL,
        (error, response) => {
          this.state.multipleSize -= 1;
          this.setState({ galleryRequest: this.state.multipleSize > 0 });
          if (error) {
            if (this.state.multipleSize <= 0)
              this.setState({ warnInputMessage: "خطا در ارسال فایل." });
          } else {
            if (response.success) {
              this.props.CarsStore[this.props.storeKey].push(response.data);
              this.setState({ ref: true });
            } else {
              if (this.state.multipleSize <= 0)
                this.setState({ warnInputMessage: "خطا در ارسال فایل." });
            }
          }
        },
        data,
        (onUploadProgress) => {
          //console.log("pr",Math.round((100 * onUploadProgress.loaded) / onUploadProgress.total))
        }
      );
    });
  }

  removeImage(address) {
    if (!this.props?.hasChangeAccess) {
      this.props?.accessError();
      return;
    }
    this.alertFunction = this.removeImageRequest.bind(this, address);
    this.setState({
      alertDialogQuestionMessage: "آیا مطمئن به حذف این تصویر هستید ؟",
    });
  }

  removeImageRequest(fileName) {
    if (this.state.galleryRequest) {
      return;
    }

    this.setState({ galleryRequest: true });

    apiClient.get(
      this.props.removeImageURL,
      (error, response) => {
        this.setState({ galleryRequest: false });
        if (error) {
          this.setState({ warnInputMessage: "خطا در حذف فایل." });
        } else {
          if (response.success) {
            let i = this.props.CarsStore[this.props.storeKey].indexOf(
              response.data
            );
            if (i >= 0) {
              this.props.CarsStore[this.props.storeKey].splice(i, 1);
              this.setState({ ref: true });
            }
            let j = this.props.images.indexOf(response.data);
            if (j >= 0) {
              let g = JSON.parse(JSON.stringify(this.props.images));
              g.splice(j, 1);
              this.props.onChangeImages(g);
              this.setState({ ref: true });
            }
          } else {
            this.setState({ warnInputMessage: "خطا در حذف فایل." });
          }
        }
      },
      { imagePath: fileName }
    );
  }

  addImageToSlider(src) {
    this.props.addImageToEditor(src);
    if (src === null) {
      if (
        this.props.images.length ===
        this.props.CarsStore[this.props.storeKey].length
      ) {
        this.props.onChangeImages([]);
      } else {
        this.props.onChangeImages(
          JSON.parse(JSON.stringify(this.props.CarsStore[this.props.storeKey]))
        );
      }
    } else {
      let i = this.props.images.indexOf(src);
      let g = JSON.parse(JSON.stringify(this.props.images));
      if (i >= 0) {
        g.splice(i, 1);
      } else {
        g = [...g, src];
      }
      this.props.onChangeImages(g);
    }
    this.setState({ ref: true });
  }

  renderGalleryItems() {
    return (
      <Grid container style={{ flex: 1, marginBottom: 10 }}>
        {this.props.CarsStore[this.props.storeKey].map((v) => (
          <div
            key={v}
            style={{
              margin: 5,
              border: "1px solid #d2d2d2",
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                width: 130,
                height: 100,
                margin: 0,
                padding: 0,
                backgroundColor: "#eee",
              }}
            >
              <a target="_blank" href={v}>
                <img
                  src={v}
                  style={{
                    width: 130,
                    height: 100,
                    margin: 0,
                    objectFit: "contain",
                    backgroundColor: "#eee",
                  }}
                />
              </a>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  flexDirection: "column",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => this.removeImage(v)}
                  style={{ margin: 3, backgroundColor: "#fff" }}
                >
                  <Icon size={16} style={{ color: "red" }}>
                    delete
                  </Icon>
                </IconButton>
              </div>
            </div>

            {!this.props.justShow && (
              <Button
                size="small"
                fullWidth
                onClick={() => this.addImageToSlider(v)}
                style={{
                  margin: 0,
                  borderRadius: 2,
                  backgroundColor:
                    this.props.images.indexOf(v) >= 0
                      ? "#00734c"
                      : "transparent",
                }}
              >
                {this.props.images.indexOf(v) < 0 ? (
                  <span style={{ fontSize: 14 }}>انتخاب</span>
                ) : (
                  <span
                    style={{
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    ({this.props.images.indexOf(v) + 1}) حذف
                  </span>
                )}
              </Button>
            )}
          </div>
        ))}
      </Grid>
    );
  }

  uploadMultiple(files) {
    let list = [];
    for (let file of files) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif"
      )
        list.push(file);
    }
    if (list.length > 0) this.uploadImage(list);
  }

  render() {
    return (
      <Grid container direction="column">
        <Dialog
          open={this.props.showGalleryDialog}
          onClose={() => this.props.closeGalleryDialog()}
        >
          <DialogContent>
            <div style={{ paddingTop: 15, paddingBottom: 10 }}>
              <FileDrop
                className="file-drop"
                onDrop={(files, event) => this.uploadMultiple(files)}
              >
                <SelectImage
                  width={150}
                  iconButton={"cloud_upload"}
                  textButton={"بارگذاری تصویر"}
                  aspect={4 / 3}
                  colorButton="secondary"
                  onSelect={(r) => this.uploadImage(r)}
                />
              </FileDrop>

              <RequestState
                title={"در حال دریافت گالری..."}
                retryOnPress={() => this.getGallery()}
                error={this.state.galleryRequestErr}
                request={this.state.galleryRequest}
              />
              {this.renderGalleryItems()}
            </div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              onClick={() => this.props.closeGalleryDialog()}
              color="secondary"
            >
              بستن
            </Button>
          </DialogActions>
        </Dialog>

        <Grid item sm={12} xs={12} style={classes.gridInputBoxFull}>
          <FormLabel component="legend" style={{ marginBottom: 5 }}>
            {this.props.title ? this.props.title : "گالری"}
          </FormLabel>

          <FileDrop
            className={
              this.props.justShow ? "file-drop just-show" : "file-drop"
            }
            onDrop={(files, event) => this.uploadMultiple(files)}
          >
            <SelectImage
              width={150}
              iconButton={"cloud_upload"}
              textButton={"بارگذاری تصویر"}
              aspect={4 / 3}
              colorButton="secondary"
              onSelect={(r) => this.uploadImage(r)}
            />
          </FileDrop>

          <RequestState
            title={"در حال دریافت گالری..."}
            retryOnPress={() => this.getGallery()}
            error={this.state.galleryRequestErr}
            request={this.state.galleryRequest}
          />
          {this.renderGalleryItems()}

          {this.props.CarsStore[this.props.storeKey].length > 0 &&
            !this.props.justShow && (
              <Button
                color="secondary"
                onClick={() => this.addImageToSlider(null)}
                style={{ margin: "5px 5px -5px 5px", fontSize: 13 }}
              >
                انتخاب همه
              </Button>
            )}
        </Grid>

        {this.state.sendRequestErrMessage !== "" && (
          <SnackBar
            variant={"error"}
            message={this.state.sendRequestErrMessage}
            onClose={() => this.setState({ sendRequestErrMessage: "" })}
          />
        )}

        {this.state.warnInputMessage !== "" && (
          <AlertDialog
            onClose={() => this.setState({ warnInputMessage: "" })}
            question={false}
            description={this.state.warnInputMessage}
          />
        )}

        {this.state.alertDialogQuestionMessage !== "" && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) this.alertFunction();
              this.alertFunction = null;
              this.setState({ alertDialogQuestionMessage: "" });
            }}
            question={true}
            description={this.state.alertDialogQuestionMessage}
          />
        )}
      </Grid>
    );
  }
}

EditorGallery.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  addImageToEditor: PropTypes.func.isRequired,
  getGalleryURL: PropTypes.string.isRequired,
  uploadImageURL: PropTypes.string.isRequired,
  removeImageURL: PropTypes.string.isRequired,
  closeGalleryDialog: PropTypes.func.isRequired,
  showGalleryDialog: PropTypes.bool.isRequired,
  forceGetGallery: PropTypes.any,
  title: PropTypes.string,
  justShow: PropTypes.bool,
  storeKey: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  onChangeImages: PropTypes.func.isRequired,
};
export default EditorGallery;

const classes = {
  progress: {
    width: "2rem",
    height: "2rem",
    marginTop: 20,
  },
  textInput: {
    margin: 10,
  },
  gridInput: {
    minWidth: 300,
  },
  gridInputBox: {
    flex: 1,
    minWidth: 284,
    margin: 8,
    padding: 10,
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d2d2d2",
  },
  gridInputBoxFull: {
    flex: 1,
    minWidth: 284,
    margin: 8,
    padding: 10,
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d2d2d2",
  },
  logo: {
    width: 130,
    height: 100,
    borderRadius: 5,
    marginLeft: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d2d2d2",
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
};
