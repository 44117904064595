import React from "react";
import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles3 } from "../../styles/drawer.js";
import { useSelector, useDispatch } from "react-redux";
import {
  updateRTK,
  addToCheck,
  deleteFromCheck,
} from "../../Store/slice/getErrorsParams.js";
export default function AccordionFilter({ topics, criterialLevel }) {
  const { titles } = useSelector((store) => store.getErrorsParams);
  const dispatch = useDispatch();
  const classes = useStyles3();
  const handleOnChange = (param, isTopic) => {
    if (titles.some((title) => title.id === param.id)) {
      const paramKey = param.id < 7 ? "topic" : "criteriaLevel";
      console.log(paramKey);
      dispatch(
        updateRTK({
          [paramKey]: "",
        })
      );
      if (paramKey === "topic") {
        dispatch(updateRTK({ topic: "" }));
      }

      if (paramKey === "criteriaLevel") {
        dispatch(updateRTK({ criteriaLevel: "" }));
      }

      dispatch(deleteFromCheck(param));
    }

    if (!titles.some((title) => title.id === param.id)) {
      dispatch(addToCheck(param));
      dispatch(
        updateRTK({
          [`${
            isTopic === "topic" ? "topic" : "criteriaLevel"
          }`]: `${param.title}`,
        })
      );
    }
  };

  const handleOnClick = (param, isTopic) => {
    dispatch(
      updateRTK({
        [`${
          isTopic === "topic" ? "topic" : "criteriaLevel"
        }`]: `${param.title}`,
      })
    );
  };
  return (
    <div className={classes.root}>
      <Accordion elevation={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            نوع خطا
            <Typography
              className="bullet"
              variant="span"
              component="span"
            ></Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.checkboxContainer}>
            {topics.map((topic) => (
              <FormGroup key={topic.id}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={titles.some((title) => title.id === topic.id)}
                        name="checkbox1"
                        onClick={() => handleOnChange(topic, "topic")}
                        // onClick={() => handleOnClick(topic, "topic")}
                        id={topic.id}
                      />
                    }
                    label={topic.title}
                    labelPlacement="start"
                  />
                </FormControl>
              </FormGroup>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={false} className={classes.controllLabel}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>وضعیت خطا</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="div" className={classes.checkboxContainer}>
            {criterialLevel.map((criterial) => (
              <FormGroup key={criterial.id}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={titles.some(
                          (title) => title.id === criterial.id
                        )}
                        onChange={() => handleOnChange(criterial)}
                        onClick={() =>
                          handleOnClick(criterial, "criteriaLevel")
                        }
                        id={criterial.id}
                      />
                    }
                    label={criterial.title}
                    labelPlacement="start"
                  />
                </FormControl>
              </FormGroup>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
