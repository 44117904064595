import React from "react";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import { CircularProgress } from "@material-ui/core";

export default function ListItems({
  name = "",
  username = "",
  status,
  onClickChangeSeller,
  onClickEdit,
  onClickStatus,
  statusIndicator = false,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        width: 280,
        backgroundColor: "#fff",
        border: "1px solid #ddd",
        margin: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ margin: 5 }}>{name}</h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ margin: 5 }}>{username}</h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 5 }}>وضعیت</p>
            <p style={{ margin: 5 }}>{status}</p>
          </div>
        </div>
      </div>
      <Divider style={{ marginTop: 5, marginBottom: 0 }} />
      <div style={{ display: "flex" }}>
        <Button onClick={onClickEdit} color="primary" style={{ marginTop: 2 }}>
          ویرایش
        </Button>
        <Button
          onClick={onClickStatus}
          color="secondary"
          style={{ marginTop: 2 }}
        >
          {statusIndicator ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            "وضعیت"
          )}
        </Button>
        {onClickChangeSeller && (
          <Button
            onClick={onClickChangeSeller}
            variant="contained"
            color="primary"
            style={{ marginTop: 2 }}
          >
            {statusIndicator ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              "انتخاب"
            )}
          </Button>
        )}
      </div>
    </div>
  );
}
