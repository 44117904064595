import React from "react";
import { observable, action, get } from "mobx";

class CategoriesModel {
  _id = null;
  position = 0;
  models = [];
  createdAt = "";
  updatedAt = "";
}

class CarModel {
  _id = null;
  code = "";
  hashCode = "";
  brand = "";
  model = "";
  year = "";
  option = "";
  address = "";
  basePrice = "";
  auctionPrice = "";
  discountAmount = "";
  typePrice = "";
  positivePoints = [];
  km = 0;
  attributes = [];
  auctionTime = 0;
  fairPrice = 0;
  stepPrice = 0;
  expertInfo = null;
  expertId = null;
  details = {
    ownerType: "",
    fuelType: "",
    bodyColor: "",
    colorInside: "",
    insurance: "",
    gearboxType: "",
    options: "",
    comprehensiveInsurance: "",
    insuranceDiscount: "",
    fuelCard: "",
    companyGuarantee: "",
    spareKey: "",
    numberPlate: "",
    more: [],
  };
  type = "";
  agentId = "";
  images = [];
  requests = [];
  specifications = {
    defects: {
      bodyAndTires: [],
      motor: [],
      electricAndInternal: [],
      suspension: [],
      ventilation: [],
    },
    reviews: {
      bodyAndTires: [],
      motor: [],
      electricAndInternal: [],
      suspension: [],
      ventilation: [],
    },
  };
  c_number = "";
  e_number = "";
  p_number = "";
  createdAt = "";
  totalRequestsPage = 0;
  updatedAt = "";
  requestWinner = null;
  brokerWinner = null;
  agentInfo = {};
  description = "";
  depoDateTime = null;
}

export default class Cars {
  @observable categories = [];
  @observable currentCategory = new CategoriesModel();
  @observable currentCar = new CarModel();

  @observable brokerFilter = null;
  @observable agentFilter = null;

  @observable currentGallery = [];
  @observable docsGallery = [];

  @observable carCategoryGallery = [];

  @action setCategories(data) {
    this.categories = data;
  }

  @action setCurrentCategory(item) {
    this.currentCategory = JSON.parse(JSON.stringify(item));
  }

  @action setBrokerFilter(broker) {
    this.brokerFilter = broker;
  }

  @action setAgentFilter(agent) {
    this.agentFilter = agent;
  }

  @action updateCategoryItem(item) {
    let index = this.categories.findIndex(
      (t) => t._id.toString() === item._id.toString()
    );
    if (index >= 0) {
      this.categories[index] = item;
    } else {
      this.categories.push(item);
    }
  }

  @action emptyCurrentCategory() {
    this.currentCategory = new CategoriesModel();
  }

  @get getCurrentCategory() {
    return JSON.parse(JSON.stringify(this.currentCategory));
  }

  @action emptyCurrentCar() {
    this.currentGallery = [];
    this.docsGallery = [];
    this.currentCar = new CarModel();
  }

  @action setCurrentCar(item, key) {
    if (key) {
      this.currentCar[key] = JSON.parse(JSON.stringify(item[key]));
    } else {
      this.currentCar = JSON.parse(JSON.stringify(item));
    }
  }

  @action setSpecificationsReviewsCar(item) {
    this.currentCar.specifications.reviews = JSON.parse(JSON.stringify(item));
  }

  @action setSpecificationsDefectsCar(item) {
    this.currentCar.specifications.defects = JSON.parse(JSON.stringify(item));
  }

  @get getCurrentCar() {
    return JSON.parse(JSON.stringify(this.currentCar));
  }
}
