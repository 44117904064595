import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Styles } from "../../../values/Styles";

const SearchBar = ({ search, searchChangehandler, operateSearch }) => {
  return (
    <div style={{ ...Styles.contentContainerCol900, marginBottom: 10 }}>
      <TextField
        onChange={(t) => searchChangehandler(t.target.value)}
        placeholder="جستجو ..."
        type="search"
        defaultValue={search}
        variant="standard"
        margin={"dense"}
        style={{ maxWidth: 190, margin: 20 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon>search</Icon>
            </InputAdornment>
          ),
        }}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            operateSearch(ev.target.value);
          }
        }}
      />
    </div>
  );
};

export default SearchBar;
