import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import styles from "./Filter.module.css";
import totalRequestValue from "../../values/mapped_values/totalRequestValue";

const TotalRequest = ({ handlerTotalRequest }) => {
  const [numberBuy, setNumberBuy] = useState({
    today: "",
    fromDay: "",
  });

  useEffect(() => {
    handlerTotalRequest(numberBuy.today, numberBuy.fromDay);
  }, [numberBuy]);
  return (
    <div>
      <div className={styles.holder}>
        <h4 style={{ paddingLeft: "10px" }}> از </h4>
        <FormControl>
          <NativeSelect
            value={numberBuy.fromDay}
            onChange={(e) =>
              setNumberBuy((prev) => ({ ...prev, fromDay: e.target?.value }))
            }
            inputProps={{
              name: "age",
              id: "age-native-helper",
            }}
          >
            <option>انتخاب کنید</option>
            {totalRequestValue.map((item) => (
              <option key={item.key} value={item.value}>
                {item.value}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
        <h4 style={{ paddingLeft: "10px" }}> تا </h4>
        <FormControl>
          <NativeSelect
            value={numberBuy.today}
            onChange={(e) =>
              setNumberBuy((prev) => ({
                ...prev,
                today: e.target?.value,
              }))
            }
            inputProps={{
              name: "age",
              id: "age-native-helper",
            }}
          >
            <option>انتخاب کنید</option>
            {totalRequestValue.map((item) => (
              <option key={item.key} value={item.value}>
                {item.value}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </div>
    </div>
  );
};

export default TotalRequest;
