import React, { useState } from "react";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";

const FeaturesAffadative = (props) => {
  const { selectedValue, AffidavitChecked, setSelectedValue } = props;

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "free_affadaptive") {
      AffidavitChecked(true);
    } else {
      AffidavitChecked(false);
    }
  };

  return (
    <>
      <h4>نوع قرارداد:</h4>
      <Divider />
      <div>
        <Radio
          label="End"
          checked={selectedValue === "affadaptive"}
          onChange={handleChange}
          value="affadaptive"
          name="radio-button-demo"
          inputProps={{ "aria-label": "A" }}
        />
        مبایعه نامه شرکتی
        <Radio
          checked={selectedValue === "free_affadaptive"}
          onChange={handleChange}
          value="free_affadaptive"
          name="radio-button-demo"
          inputProps={{ "aria-label": "B" }}
        />
        مبایعه نامه آزاد
      </div>
    </>
  );
};

export default FeaturesAffadative;
