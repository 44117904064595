export const priceTypesOptions = [
  { key: "0", option: "انتخاب نوع قیمت" },
  { key: "usual", option: "معمولی" },
  { key: "good", option: "خوب" },
  { key: "excellent", option: "عالی" },
];

export const ownerTypesOptions = [
  { key: "دست اول", option: "دست اول" },
  { key: "دست دوم", option: "دست دوم" },
  { key: "دست سوم", option: "دست سوم" },
];

export const fuelTypesOptions = [
  { key: "بنزین", option: "بنزین" },
  { key: "گاز", option: "گاز" },
  { key: "دیزل", option: "دیزل" },
  { key: "هیبرید", option: "هیبرید" },
  { key: "دوگانه سوز", option: "دوگانه سوز" },
];

export const gearboxTypesOptions = [
  { key: "دنده", option: "دنده" },
  { key: "اتوماتیک", option: "اتوماتیک" },
];

export const insuranceOptionsTypes = [
  { key: "1", option: "1" },
  { key: "2", option: "2" },
  { key: "3", option: "3" },
  { key: "4", option: "4" },
  { key: "5", option: "5" },
  { key: "6", option: "6" },
  { key: "7", option: "7" },
  { key: "8", option: "8" },
  { key: "9", option: "9" },
  { key: "10", option: "10" },
  { key: "11", option: "11" },
  { key: "12", option: "12" },
];

export const insuranceDiscountTypes = [
  { key: "1 ماه", option: "1 ماه" },
  { key: "2 ماه", option: "2 ماه" },
  { key: "3 ماه", option: "3 ماه" },
  { key: "4 ماه", option: "4 ماه" },
  { key: "5 ماه", option: "5 ماه" },
  { key: "6 ماه", option: "6 ماه" },
  { key: "7 ماه", option: "7 ماه" },
  { key: "8 ماه", option: "8 ماه" },
  { key: "9 ماه", option: "9 ماه" },
  { key: "10 ماه", option: "10 ماه" },
  { key: "11 ماه", option: "11 ماه" },
  { key: "12 ماه", option: "12 ماه" },
  { key: "ندارد", option: "ندارد" },
];

export const bodyColorTypes = [
  { key: "سفید", option: "سفید" },
  { key: "مشکی", option: "مشکی" },
  { key: "خاکستری", option: "خاکستری" },
  { key: "نقره ای", option: "نقره ای" },
  { key: "قرمز", option: "قرمز" },
  { key: "سایر", option: "سایر" },
];

export const colorInsideTypes = [
  { key: "مشکی", option: "مشکی" },
  { key: "طوسی", option: "طوسی" },
  { key: "مارون", option: "مارون" },
  { key: "کرم", option: "کرم" },
  { key: "سایر", option: "سایر" },
];

export const SelectFormCars = [
  { key: 1, optionName: "insurance", titleSelect: "بیمه شخص ثالث" },
  { key: 2, optionName: "insuranceDiscount", titleSelect: "سال تخفیف بیمه" },
  { key: 3, optionName: "fuelType", titleSelect: "نوع سوخت" },
  { key: 4, optionName: "ownerType", titleSelect: "نوع مالک ( سند)" },
  { key: 5, optionName: "bodyColor", titleSelect: "رنگ بدنه" },
  { key: 6, optionName: "colorInside", titleSelect: "رنگ داخل" },
  { key: 7, optionName: "gearboxType", titleSelect: "نوع گیربکس" },
];
