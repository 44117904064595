import { observable, action, get } from "mobx";

class SellerModel {
  _id = null;
  name = "";
  username = "";
  phone = "";
  password = "";
  agentId = null;
  type = "";
  status = "";
  statusCode = 0;
  createdAt = "";
  updatedAt = "";
  permissions = [];
  countOfBrokers = undefined;
}

export default class Sellers {
  @observable current = new SellerModel();
  @observable changeSellerItem = null;
  @observable sellers = [];

  @action setSellers(sellers) {
    this.sellers = sellers;
  }

  @action setCurrent(item, countOfBrokers) {
    this.current = JSON.parse(JSON.stringify(item));
    this.current.countOfBrokers = countOfBrokers;
  }

  @action setChangeSellerItem(broker) {
    this.changeSellerItem = broker;
  }
  @action emptyChangeSellerItem() {
    this.changeSellerItem = null;
  }

  @action emptyCurrent() {
    this.current = new SellerModel();
  }

  @get getCurrent() {
    return JSON.parse(JSON.stringify(this.current));
  }
}
