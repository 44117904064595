import React from "react";
import Main from "../../common/UserMain";
import { useMediaQuery } from "@mui/material";
import Header from "../../../features/contact-us/Header";
import MainSection from "../../../features/contact-us/MainSection";
const ContactUs = () => {
  const isMatchesTabletbp = useMediaQuery("(max-width: 959px)");
  const minWidth900 = useMediaQuery("(min-width: 0)");
  return (
    <Main
      page={"contact-us"}
      pageName={"تماس باما"}
      showToolbar={isMatchesTabletbp && minWidth900 ? true : false}
    >
      <Header />
      <MainSection />
    </Main>
  );
};

export default ContactUs;
