import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, FormControl, MenuItem } from "@material-ui/core";
import AlertDialog from "../../common/AlertDialog";
import { inject, observer } from "mobx-react";
import Select from "@material-ui/core/Select";
@inject("CarsStore")
@observer
class AddEditReviewsDialog extends Component {
  constructor(props) {
    super(props);

    let indexCurrent =
      props.curent && props.curent.index >= 0 ? props.curent.index : -1;
    let parent = indexCurrent >= 0 ? props.curent.parent : "";
    let valid = indexCurrent >= 0 ? props.curent.valid : true;
    let title = indexCurrent >= 0 ? props.curent.title : "";
    let value = indexCurrent >= 0 ? props.curent.value : "";

    this.state = {
      indexCurrent: indexCurrent,
      parent: parent,
      message: "",
      valid: valid,
      title: title,
      description: value,
    };
  }

  handleClose() {
    this.props.onClose();
  }

  submit() {
    if (this.state.parent === "") {
      this.setState({ message: "نوع نقص را مشخص کنید" });
      return;
    }

    if (this.state.title.toString().trim() === "") {
      this.setState({ message: "عنوان را مشخص کنید" });
      return;
    }

    if (this.state.indexCurrent >= 0) {
      this.props.CarsStore.currentCar.specifications.reviews[this.state.parent][
        this.state.indexCurrent
      ] = {
        valid: this.state.valid,
        title: this.state.title,
        value: this.state.description,
      };
    } else {
      this.props.CarsStore.currentCar.specifications.reviews[
        this.state.parent
      ].push({
        valid: this.state.valid,
        title: this.state.title,
        value: this.state.description,
      });
    }

    this.props.onSuccess(
      this.props.CarsStore.currentCar.specifications.reviews
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: 300, padding: 5 }}>
            <div style={{ position: "relative" }}>
              {this.state.indexCurrent < 0 && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControl label="نوع نقص" fullWidth>
                    <Select
                      defaultValue={
                        !this.state.parent ? "0" : this.state.parent
                      }
                      variant="outlined"
                      margin={"dense"}
                      style={{ fontSize: 13, margin: "10px 10px 0 10px" }}
                      onChange={(e) =>
                        this.setState({ parent: e.target.value, type: "" })
                      }
                    >
                      <MenuItem style={{ fontSize: 13 }} value={"0"}>
                        انتخاب نوع نقص
                      </MenuItem>

                      {[
                        { k: "bodyAndTires", v: "بدنه + لاستیک" },
                        { k: "motor", v: "موتور" },
                        { k: "electricAndInternal", v: "سیستم برقی + داخلی" },
                        {
                          k: "suspension",
                          v: "سیستم تعلیق",
                        },
                        { k: "ventilation", v: "تهویه مطبوع" },
                      ].map((l, i) => (
                        <MenuItem key={i} style={{ fontSize: 13 }} value={l.k}>
                          {l.v}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}

              <div style={{ display: "flex", flexWrap: "wrap", margin: 10 }}>
                <Button
                  variant={this.state.valid ? "contained" : "outlined"}
                  style={{ marginLeft: 15, borderRadius: 20 }}
                  onClick={() => this.setState({ valid: true })}
                  color="secondary"
                >
                  سالم
                </Button>

                <Button
                  variant={!this.state.valid ? "contained" : "outlined"}
                  style={{ marginLeft: 15, borderRadius: 20 }}
                  onClick={() => this.setState({ valid: false })}
                  color="secondary"
                >
                  دارای نقص
                </Button>
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  onChange={(t) => (this.state.title = t.target.value)}
                  style={{ margin: 10, flex: 1 }}
                  defaultValue={this.state.title}
                  label={"عنوان"}
                  placeholder={"عنوان"}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{ style: { fontSize: 15 }, maxLength: 400 }}
                />
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  onChange={(t) => (this.state.description = t.target.value)}
                  style={{ margin: 10, flex: 1 }}
                  defaultValue={this.state.description}
                  label={"توضیح"}
                  placeholder={"توضیح"}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{ style: { fontSize: 15 }, maxLength: 400 }}
                />
              </div>
            </div>
          </DialogContent>

          {this.state.sendRequest ? (
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={25} />
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => this.submit()} color="primary">
                انجام
              </Button>

              <Button onClick={() => this.handleClose()} color="secondary">
                بستن
              </Button>
            </DialogActions>
          )}
        </Dialog>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </div>
    );
  }
}

AddEditReviewsDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  curent: PropTypes.any,
};
export default AddEditReviewsDialog;

const selectOptionsStyles = { margin: 10, flex: 1 };

const titleOptions = [
  { key: "title one", option: "تایتل اول" },
  { key: "title two", option: "تایتل دوم" },
  { key: "title three", option: "تایتل سوم" },
  { key: "title four", option: "چهارم ایتل " },
];

const descriptionOptions = [
  { key: "desc one", option: "توضیحات اول" },
  { key: "desc two", option: "توضیحات دوم" },
  { key: "desc three", option: "توضیحات سوم" },
  { key: "desc four", option: "توضیحات چهارم" },
];
