import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React from "react";
import { useGetAllCoworkersQuery } from "../../Store/callApi/apiCoworkers";
import { useDispatch, useSelector } from "react-redux";
import { setUserId } from "../../Store/slice/sliceNewRequest";
const SearchCoworkerRequests = () => {
  const { userId } = useSelector((state) => state.newRequest);
  const dispatch = useDispatch();
  const {
    data: coworkersData,
    isLoading,
    isSuccess,
  } = useGetAllCoworkersQuery({
    status: "accepted",
    type: "coworker",
  });
  return (
    <Box sx={{ display: "flex", width: 1, paddingRight: "30px" }}>
      <FormControl component="fieldset" variant="standard" sx={{ width: 1 }}>
        <FormGroup
          sx={{
            "& label": {
              justifyContent: "flex-end",
              position: "relative",
              fontSize: 14,
            },

            "& label span:last-child": {
              fontSize: 14,
            },

            "& .MuiButtonBase-root": {
              marginRight: "auto",
            },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={userId === ""}
                onChange={() => dispatch(setUserId(""))}
              />
            }
            label={"همه"}
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              marginInline: "unset",
            }}
          />
          {isSuccess &&
            coworkersData?.records.map((coworker) => (
              <FormControlLabel
                key={coworker._id}
                control={
                  <Checkbox
                    checked={userId === "" || userId === coworker._id}
                    onChange={() => dispatch(setUserId(coworker._id))}
                  />
                }
                label={coworker.name}
                labelPlacement="start"
                sx={{
                  justifyContent: "space-between",
                  marginInline: "unset",
                }}
              />
            ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default SearchCoworkerRequests;
