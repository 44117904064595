const textFieldsConfig = [
  {
    name: "name",
    label: "نام",
  },
  {
    name: "username",
    label: "نام کاربری",
  },
  {
    name: "phone",
    label: "موبایل",
  },
  {
    name: "officePhone",
    label: "تلفن شرکت",
  },
  {
    name: "password",
    label: "رمز عبور",
  },
];

export default textFieldsConfig;
