import { Box, IconButton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveFilterDate,
  setMutualDate,
} from "../../Store/slice/sliceContactUs";
import DisplayDateString from "./DisplayDateString";
const DateSelection = () => {
  const dispatch = useDispatch();
  const { activeFilterDate, mutualDate } = useSelector(
    (state) => state.contactUs
  );

  const onChange = (arg) => {
    dispatch(setMutualDate(arg));
  };

  return (
    <>
      {activeFilterDate === "submit" && (
        <DisplayDateString
          dateMutual={new Date(mutualDate)}
          onChange={onChange}
        />
      )}
      {!activeFilterDate && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: -2.5 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 6 }}>
            <IconButton
              sx={{
                bgcolor: "primary.light",
                "&:hover": { bgcolor: "primary.light" },
                "& .MuiSvgIcon-root": {
                  fill: "#fff",
                },
              }}
              onClick={() => dispatch(setActiveFilterDate())}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <Typography
              variant="h5"
              component="h5"
              color="primary.dark"
              fontSize={18}
            >
              همه روزها
            </Typography>
            <IconButton
              sx={{
                bgcolor: "primary.light",
                width: 40,
                height: 40,
                position: "relative",
                "&:hover": { bgcolor: "primary.light" },
                "& .MuiSvgIcon-root": {
                  position: "absolute",
                  left: "33%",
                  fill: "#fff",
                },
              }}
              onClick={() => dispatch(setActiveFilterDate())}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DateSelection;
