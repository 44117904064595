import React, { useState } from "react";
import styles from "./reporting.module.css";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Grid, Box, Button } from "@material-ui/core";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import LinearProgress from "@material-ui/core/LinearProgress";

const ListRequests = ({ requestName, number, path }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadg, setIsDownload] = useState(true);

  const handlerDownload = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsDownload(false);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <Grid item xs={12}>
      <Box className={styles.typeRequestStyle}>
        <h4 className={styles.iconNameRequestStyle}>
          <ListAltIcon />
          {requestName} : {number}
        </h4>
        <div className={styles.iconAndBtnRequest}>
          {isDownloadg ? (
            <Button
              onClick={handlerDownload}
              variant="contained"
              color="primary"
              startIcon={<BorderAllIcon />}
            >
              خروجی اکسل
            </Button>
          ) : (
            <a href={path}>
              <Button
                className={styles.spasingStyle}
                variant="contained"
                color="primary"
                startIcon={<BorderAllIcon />}
              >
                دانلود اکسل
              </Button>
            </a>
          )}
          {isLoading && (
            <LinearProgress className={styles.linearProgressStyle} />
          )}
        </div>
      </Box>
    </Grid>
  );
};
export default ListRequests;
