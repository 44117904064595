import React, { useState } from "react";
import { Fab } from "@material-ui/core";
import fab from "../../styles/citiesTopBar";
import LeftVectorIcon from "../../packages/icons/leftVectorIcon";
import AlertDialog from "./CitiesDialogAddEdit";
import styles from "./InfoRequests.module.css";
import AddIcon from "../../packages/icons/AddIcon";
import { useMediaQuery } from "@material-ui/core";
const InfoRequestTopBar = () => {
  const classes = fab();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 500px)");
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const addNew = () => {
    openModal();
  };

  return (
    <div className={styles.topBar}>
      {!isSmallScreen && (
        <div className={styles.topBarBreadCrumb}>
          <div className={styles.breadTextGray}>درخواست ها</div>
          <LeftVectorIcon />
          <div className={styles.breadText}>شهر ها</div>
        </div>
      )}

      <Fab
        className={classes.root}
        onClick={addNew}
        variant="extended"
        size="medium"
        color="primary"
        aria-label="add"
      >
        <div className={styles.createButton}>
          <AddIcon />
          <p className={styles.createButtonText}>
            {isSmallScreen ? "ایجاد" : " ایجاد شهر جدید"}
          </p>
        </div>
      </Fab>

      <AlertDialog
        isNew={true}
        isOpen={isModalOpen}
        onClose={closeModal}
        title="ایجاد شهر جدید"
        cityName=""
        cityId=""
      />
    </div>
  );
};

export default InfoRequestTopBar;
