import React, { useEffect, useState } from "react";
import style from "./timeManagement.module.css";
import Subject from "@material-ui/icons/Subject";
import { useShutdownDay } from "./../../../Hooks/ShutdownDay";
import DialogListShutDownDays from "./DialogListShutDownDays";
import AlertDialog from "../../common/AlertDialog";
import SnackBar from "../../common/SnackBar";

import {
  Dialog,
  Grid,
  Tooltip,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";
let moment = require("moment-jalaali");
moment.loadPersian({ dialect: "persian-modern" });

const DialogManagement = (props) => {
  const [textDay, setTextDay] = useState("");
  const { sendDateServer, dateTime, DateServer, showAlert, setChoiceDay } =
    props;
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(true);
  const { successRes, HandlerShutdownDay } = useShutdownDay();

  const handleAlertDialogClose = (yesPress) => {
    if (yesPress) {
      HandlerShutdownDay(sendDateServer, textDay);
      setShowAlertDialog(false);
      setTextDay("");
      setShowSnackBar(true);
    } else {
      setShowAlertDialog(false);
    }
  };

  useEffect(() => {
    if (successRes) {
      setChoiceDay((prev) => ({ ...prev, isChecked: !successRes }));
    }
  }, [successRes]);

  return (
    <>
      <Dialog
        open={showAlert}
        onClose={() => setChoiceDay((prev) => ({ ...prev, showAlert: false }))}
      >
        <div className={style.BoxDialogManagement}>
          <h4>لطفا مناسبت تعطیل شدن شرکت را وارد کنید.</h4>
          <Grid className={style.TextDialogManagement} container spacing={2}>
            <Grid item xs={10}>
              <TextField
                size="small"
                id="outlined-basic"
                label="متن را وارد کنید"
                variant="outlined"
                value={textDay}
                onChange={(e) => setTextDay(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip placement="top" title="حذف از لیست">
                <Subject
                  className={style.DialoglistDays}
                  onClick={() => {
                    setOpenDialogDelete(true);
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <Grid className={style.DialogBtnstyle} container spacing={2}>
            <Grid item xs={4}>
              <Button
                disabled={!textDay}
                variant="contained"
                size="small"
                fullWidth
                type="submit"
                color={"primary"}
                onClick={() => setShowAlertDialog(true)}
              >
                تایید
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                onClick={() =>
                  setChoiceDay((prev) => ({ ...prev, showAlert: false }))
                }
                color={"secondary"}
              >
                بازگشت
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      {showAlertDialog && (
        <AlertDialog
          onClose={({ yesPress }) => handleAlertDialogClose(yesPress)}
          question={true}
          description={`آیا از تعطیل شدن روز ${dateTime} به مناسبت  ${textDay} مطمئن هستین؟`}
          negativeTextButton={"خیر"}
          positiveTextButton={"بله"}
        />
      )}
      <SnackBar
        open={successRes === true && showSnackBar}
        variant={"success"}
        message="در خواست شما با موفقیت ثبت شد."
        onClose={() => setShowSnackBar(false)}
      />
      <SnackBar
        open={successRes === false && showSnackBar}
        variant={"warning"}
        message="این تاریخ قبلا ثبت شده است."
        onClose={() => setShowSnackBar(false)}
      />
      {openDialogDelete && (
        <DialogListShutDownDays
          setOpenDialogDelete={setOpenDialogDelete}
          OpenDialogDelete={openDialogDelete}
          setChoiceDay={setChoiceDay}
          DateServer={DateServer}
        />
      )}
    </>
  );
};

export default DialogManagement;
