import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { Send, Redo } from "@material-ui/icons";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Typography, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Main from "../../common/UserMain";
import { Statement } from "../../../values/Statement";
import { ApiClient, ApiHelper } from "../../../data/remote";
import { data_KM } from "./data_timeManagement";
import style from "./timeManagement.module.css";
import { useFormik } from "formik";
import { ValidationFormikSellCar } from "../../../values/validation_formik/validationFormikSellCar";
import { useHistory } from "react-router-dom";
import AlertDialog from "../../common/AlertDialog";
import usemakeTime from "../../../Hooks/makeTime";
import { GoldenSwitch } from "../../../styles/colors_mui";
import { useSelector } from "react-redux";
import SnackBar from "../../common/SnackBar";
import { useDaysWithHolidays } from "../../../Hooks/useDaysWithHolidays";
import { recentDaysService } from "../../../services/date/RecentDaysService";

let moment = require("moment-jalaali");
moment.loadPersian({ dialect: "persian-modern" });

const AddSellRequest = (props) => {
  const [RequestEdit, setRequestEdit] = useState(props.location.RequestEdit);
  const [dataCar, setDataCar] = useState([]);
  const [Location, setLocation] = useState([]);
  const [SwitchChecked, setSwitchChecked] = useState(false);
  const [state, setState] = useState({
    brandIndex: -1,
    modelIndex: -1,
    yearIndex: -1,
    agentID: [],
    date: [],
    success: false,
    error: false,
    resetForm: true,
    onClose: true,
  });
  const [agentInfo, setAgentInfo] = useState("");
  const [date, setDate] = useState();
  const [filteredReserved] = usemakeTime({ agentInfo, date });
  const [hourTime, setHourTime] = useState("");
  const [dayDate, setDayDate] = useState({});
  const selectDataStore = useSelector((state) => state.SellRequestCar);
  let appointments = recentDaysService(20);

  const { daysWithHolidays } = useDaysWithHolidays();

  let apiClient = ApiClient.getInstance();
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "60ch",
      },
    },
    isVIP: {
      display: "flex",
      alignItems: "center",
      padding: "12px",
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      brand: "",
      model: "",
      year: "",
      option: "",
      km: "",
      location: "",
      phone: "",
      agentID: "",
      name: "",
      isVIP: false,
      time: "",
      date: "",
      description: "",
    },
    validationSchema: ValidationFormikSellCar,
    onSubmit: (values) => {
      if (RequestEdit) {
        apiClient.put(
          ApiHelper.editCarTimeManagement_PUT + selectDataStore.IdRequest,
          (error, response) => {
            if (error) {
              setState((prev) => ({ ...prev, error: true, onClose: true }));
            } else {
              if (response.request.statusCode == 100) {
                setState((prev) => ({ ...prev, success: true }));
                setRequestEdit("");
              }
            }
          },
          values
        );
      } else {
        apiClient.post(
          ApiHelper.addCarTimeManagement_POST,
          (error, response) => {
            if (error) {
              setState((prev) => ({ ...prev, error: true, onClose: true }));
            } else {
              if (response.request.statusCode == 100) {
                setState((prev) => ({ ...prev, success: true }));
              }
            }
          },
          values
        );
      }
    },
  });

  const HandlerRequestEdit = () => {
    let dateTime = moment(selectDataStore.date);
    let hourlyTime = dateTime.format("HH:mm");
    let labelDayDate = dateTime.format("dddd jD jMMMM jYYYY");
    let valueDayDate = dateTime.toISOString();
    setState((prev) => {
      let brandIndex = dataCar.findIndex(
        (t) => t.brand === selectDataStore.brand
      );
      let modelIndex = dataCar[brandIndex]?.models.findIndex(
        (t) => t.model === selectDataStore.model
      );
      let yearIndex = dataCar[brandIndex]?.models[modelIndex]?.types?.findIndex(
        (t) => t.year === Number(selectDataStore.year)
      );
      return {
        ...prev,
        brandIndex: brandIndex,
        modelIndex: modelIndex,
        yearIndex: yearIndex,
      };
    });
    formik.setValues({
      ...selectDataStore,
      time: hourlyTime,
      date: valueDayDate,
    });
    setHourTime(hourlyTime);
    setDayDate({ label: labelDayDate, date: valueDayDate });
    setDate(selectDataStore.date);
    setAgentInfo(selectDataStore.agentID);
  };

  useEffect(() => {
    apiClient.get(ApiHelper.getCategories_GET, (error, response) => {
      if (error) {
      } else {
        setDataCar(response.records);
      }
    });

    apiClient.get(ApiHelper.getLocations_GET, (error, response) => {
      if (error) {
      } else {
        setLocation(response.records);
      }
    });

    apiClient.get(ApiHelper.getAgents_GET("accepted"), (error, response) => {
      if (error) {
      } else {
        setState((prev) => ({
          ...prev,
          agentID: response.records,
          onClose: true,
        }));
      }
    });
    setState((prev) => ({ ...prev, date: appointments }));

    let dateTime = moment(appointments.date);
    let hourlyTime = dateTime.format("HH:mm");
    let labelDayDate = dateTime.format("dddd jD jMMMM jYYYY");
    let valueDayDate = dateTime.toISOString();
    setHourTime(hourlyTime);
    setDayDate({ label: labelDayDate, date: valueDayDate });
  }, []);

  useEffect(() => {
    if (RequestEdit) {
      HandlerRequestEdit();
    }
  }, [dataCar]);

  const handleResetFormik = () => {
    setSwitchChecked(false);
    setState((prev) => ({ ...prev, resetForm: false, success: false }));
    setTimeout(() => {
      setState((prev) => ({ ...prev, resetForm: true }));
    }, 1);
  };

  const handlerBrandField = (event, value) => {
    formik.handleChange({
      target: {
        name: "brand",
        value: value ? value.brand : "",
      },
    });
    const brandIndex = dataCar.findIndex(
      (item) => item.brand === (value ? value.brand : "")
    );
    setState((prev) => ({
      ...prev,
      onClose: true,
      brandIndex: brandIndex,
    }));
  };

  return (
    <Main
      page={"agents/accepted"}
      pageName={"ثبت خودرو"}
      context={props.history}
    >
      {state.resetForm && (
        <form
          onSubmit={formik.handleSubmit}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2} className={style.headerAddEditCar}>
            <Grid item xs={2}>
              <div className={classes.isVIP}>
                <GoldenSwitch
                  onClick={() => {
                    setSwitchChecked(!SwitchChecked);
                  }}
                  name="isVIP"
                  checked={SwitchChecked}
                  value={SwitchChecked}
                  onChange={formik.handleChange}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <h2 className={style.is_vip}>VIP</h2>
              </div>
            </Grid>
            <Grid item xs={9}>
              {RequestEdit && (
                <Typography variant="h5">
                  {Statement.EditTextCarRequest}
                  <Box className={style.nameYourCar} component="span">
                    {" "}
                    {selectDataStore.name}
                  </Box>{" "}
                  هستید.
                </Typography>
              )}
            </Grid>
          </Grid>
          <div className={classes.isVIP}>
            {/* <GoldenSwitch

              onClick={() => {
                setSwitchChecked(!SwitchChecked);
              }}
              name="isVIP"
              checked={SwitchChecked}
              value={SwitchChecked}
              onChange={formik.handleChange}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <h2 className={style.is_vip}>VIP</h2> */}
          </div>
          <Divider />
          <Grid container spacing={2}>
            <Grid>
              <Autocomplete
                options={dataCar}
                className={style.textFieldStyle}
                getOptionLabel={(option) => option.brand}
                // value={formik.values.brand}
                onBlur={formik.handleBlur}
                onChange={handlerBrandField}
                renderInput={(params) => (
                  <TextField
                    style={{ marginTop: 0 }}
                    {...params}
                    label="انتخاب برند"
                    variant="outlined"
                    name="brand"
                    error={formik.touched.brand && Boolean(formik.errors.brand)}
                    helperText={formik.touched.brand && formik.errors.brand}
                  />
                )}
              />
            </Grid>

            <Grid>
              <TextField
                select
                label="مدل"
                disabled={state.brandIndex < 0}
                name="model"
                className={style.textFieldStyle}
                value={formik.values.model}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.model && Boolean(formik.errors.model)}
                helperText={formik.touched.model && formik.errors.model}
                onClick={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    modelIndex: dataCar[state.brandIndex]?.models.findIndex(
                      (t) => t.model === e.target.value
                    ),
                  }));
                }}
                variant="outlined"
              >
                <MenuItem>انتخاب مدل</MenuItem>
                {dataCar[state.brandIndex]?.models.map((item, index) => (
                  <MenuItem key={index} value={item.model}>
                    {item.model}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid>
              <TextField
                select
                disabled={state.modelIndex < 0}
                label="انتخاب سال"
                name="year"
                className={style.textFieldStyle}
                value={formik.values.year}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.year && Boolean(formik.errors.year)}
                helperText={formik.touched.year && formik.errors.year}
                onClick={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    yearIndex: dataCar[state.brandIndex]?.models[
                      state.modelIndex
                    ]?.types?.findIndex((t) => t.year === e.target.value),
                  }))
                }
                variant="outlined"
              >
                <MenuItem>انتخاب سال</MenuItem>
                {dataCar[state.brandIndex]?.models[state.modelIndex]?.types.map(
                  (item, index) => (
                    <MenuItem key={index} value={item.year}>
                      {item.year}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>

            <Grid>
              <TextField
                disabled={
                  !(
                    state.yearIndex >= 0 &&
                    dataCar[state.brandIndex]?.models[state.modelIndex]?.types[
                      state.yearIndex
                    ]?.options.length > 0
                  )
                }
                select
                label="انتخاب تیپ"
                name="option"
                className={style.textFieldStyle}
                value={formik.values.option}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.option && Boolean(formik.errors.option)}
                helperText={formik.touched.option && formik.errors.option}
                variant="outlined"
              >
                <MenuItem>انتخاب تیپ</MenuItem>
                {dataCar[state.brandIndex]?.models[state.modelIndex]?.types[
                  state.yearIndex
                ]?.options.map((item, index) => (
                  <MenuItem key={index} value={item.option}>
                    {item.option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid>
              <TextField
                select
                label="کارکرد"
                name="km"
                className={style.textFieldStyle}
                value={formik.values.km}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.km && Boolean(formik.errors.km)}
                helperText={formik.touched.km && formik.errors.km}
                variant="outlined"
              >
                <MenuItem>انتخاب کارکرد</MenuItem>
                {data_KM?.map((item, index) => (
                  <MenuItem key={index} value={item.data}>
                    {item.lable}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid>
              <TextField
                select
                label="شهر"
                name="location"
                className={style.textFieldStyle}
                value={formik.values.location}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
                helperText={formik.touched.location && formik.errors.location}
                variant="outlined"
              >
                <MenuItem>انتخاب شهر</MenuItem>
                {Location?.map((item, index) => (
                  <MenuItem key={index} value={item.location}>
                    {item.location}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid>
              <TextField
                select
                label="شعبه"
                name="agentID"
                value={formik.values.agentID}
                className={style.textFieldStyle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.agentID && Boolean(formik.errors.agentID)}
                helperText={formik.touched.agentID && formik.errors.agentID}
                variant="outlined"
                onClick={(agent) => setAgentInfo(agent.target.value)}
              >
                <MenuItem>انتخاب شعبه</MenuItem>
                {state?.agentID?.map((item, index) => {
                  if (!item?.shutedDown) {
                    return (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                })}
              </TextField>
            </Grid>

            <Grid>
              <TextField
                label="موبایل"
                name="phone"
                className={style.textFieldStyle}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                placeholder={"موبایل با ۰۹ شروع شود"}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid>
              <TextField
                select
                label="تاریخ مراجعه"
                name="date"
                className={style.textFieldStyle}
                value={formik.values.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
                variant="outlined"
                onClick={(date) => setDate(date.target.value)}
              >
                <MenuItem>انتخاب روز</MenuItem>
                <MenuItem value={dayDate.date}>{dayDate.label} (فعلی)</MenuItem>
                {daysWithHolidays?.map((item, index) => (
                  <MenuItem
                    disabled={item.ShutdownDay}
                    className={item.ShutdownDay ? style.BgShutdownDay : ""}
                    key={index}
                    value={item.date}
                  >
                    {item.label}
                    {item.ShutdownDay && (
                      <span className={style.textShutdownDay}>(تعطیل)</span>
                    )}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid>
              <TextField
                select
                label="ساعت مراجعه"
                name="time"
                className={style.textFieldStyle}
                value={formik.values.time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.time && Boolean(formik.errors.time)}
                helperText={formik.touched.time && formik.errors.time}
                variant="outlined"
              >
                <MenuItem>انتخاب ساعت مراجعه</MenuItem>
                {filteredReserved?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item.hour}
                      disabled={item.reserved}
                    >
                      {item.hour} {item.reserved ? "رزرو" : ""}
                      {hourTime === item.hour ? "(فعلی)" : ""}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            <Grid>
              <TextField
                label="نام و نام خانوادگی"
                name="name"
                className={style.textFieldStyle}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined"
              />
            </Grid>

            <Grid>
              <TextareaAutosize
                label="توضیحات"
                name="description"
                className={style.TextAreaStyle}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.description && formik.errors.description
                }
                placeholder={"توضیحات خود را بنویسید..."}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <div style={{ display: "flex", marginTop: 70, maxWidth: 330 }}>
            <Button
              disabled={!(formik.isValid && formik.dirty)}
              variant="contained"
              style={{ margin: 5 }}
              fullWidth
              type="submit"
              color={"primary"}
              startIcon={<Send />}
            >
              {RequestEdit ? "بروز رسانی" : "ارسال"}
            </Button>
            <Button
              variant="outlined"
              style={{ margin: 5 }}
              fullWidth
              startIcon={<Redo />}
              onClick={() => history.push(`/manage_req`)}
              color={"secondary"}
            >
              بازگشت
            </Button>
          </div>
        </form>
      )}
      {state.success && (
        <AlertDialog
          onClose={({ yesPress }) => {
            if (yesPress) {
              history.push({
                pathname: `/manage_req`,
                date: state.date[0].date,
              });
            } else {
              formik.handleReset();
              handleResetFormik();
            }
          }}
          question={true}
          description={"در خواست شما با موفقیت ثبت شد."}
          negativeTextButton={"بستن"}
          positiveTextButton={"بازگشت"}
        />
      )}
      <SnackBar
        variant={"error"}
        message="درخواست شما ثبت نشد!"
        open={state.error && state.onClose}
        onClose={() => setState((prev) => ({ ...prev, onClose: false }))}
      />
      <SnackBar
        variant={"success"}
        message="در خواست شما با موفقیت ثبت شد."
        open={state.success && state.onClose}
        onClose={() => setState((prev) => ({ ...prev, onClose: false }))}
      />
    </Main>
  );
};
export default AddSellRequest;
