import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  badge: {
    transform: "translate(-25%, -25%)", // Adjust the percentage as needed
  },
  color: {
    backgroundColor: "red",
  },
}));  

export default useStyles;
