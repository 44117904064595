import React from "react";
import PropTypes from "prop-types";
import JoditEditor from "jodit-react";

const joditButtons = [
  "source",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "eraser",
  "|",
  "superscript",
  "subscript",
  "|",
  "ul",
  "ol",
  "|",
  "outdent",
  "indent",
  "|",
  "fontsize",
  "brush",
  "paragraph",
  "link",
  "lineHeight",
  "|",
  "image",
  "file",
  "table",
  "link",
  "|",
  "align",
  "undo",
  "redo",
  "|",
  "\n",
  "hr",
  "|",
  "symbol",
  "|",
  "cut",
  "copy",
  "paste",
  "copyformat",
  "|",
  "find",
  "|",
  "selectall",
  "|",
  "spellcheck",
];

function Editor(props) {
  return (
    <JoditEditor
      value={props.value}
      config={{
        readonly: false,
        direction: props.lan === "fa" ? "rtl" : "ltr",
        placeholder:
          props.lan === "fa" ? "مطلب رو بنویس ..." : "Start writing ...",
        buttons: joditButtons,
        extraButtons: [
          {
            name: "اشتراکی",
            tooltip: "اشتراکی",
            popup: function (editor) {
              var br = document.createElement("BR");
              var div = document.createElement("DIV");
              div.style = "min-width:300px;";
              var title = document.createTextNode("کد Embed نوع iframe");
              title.style = "width:100%;margin:3px";
              div.append(title);
              div.append(br);
              var txt = document.createElement("TEXTAREA");
              txt.style = "width:100%;margin:3px;direction: ltr;";
              div.append(txt);
              var btn = document.createElement("BUTTON");
              btn.style = "width:100%;margin:3px";
              btn.innerHTML = "ایجاد";
              var currentSelection = editor.selection.save();
              btn.onclick = () => {
                editor.selection.restore(currentSelection);
                editor.s.insertHTML(
                  "<div class='jodit-div-o-selector' contenteditable=\"false\" data-jodit_iframe_wrapper=\"1\"><div class='media-content'>" +
                    txt.value +
                    "</div></div>"
                );
              };
              div.append(btn);
              return div;
            },
          },

          // {
          //     name: 'گالری',
          //     tooltip: 'گالری',
          //     exec   : function (e) {
          //         stores.PostsStore.showGalleryDialog = true;
          //     }
          // },
          {
            name: "اندازه تصویر",
            list: {
              100: "تمام صفحه",
              50: "یک دوم صفحه",
              200: "حداکثر ۲۰۰x",
              300: "حداکثر ۳۰۰x",
              400: "حداکثر ۴۰۰x",
              500: "حداکثر ۵۰۰x",
              600: "حداکثر ۶۰۰x",
              700: "حداکثر ۷۰۰x",
              800: "حداکثر ۸۰۰x",
              900: "حداکثر ۹۰۰x",
            },
            tooltip: "اندازه تصویر",
            exec: function (e) {
              if (
                e.selection.current() &&
                e.selection.current().tagName &&
                e.selection.current().tagName === "IMG"
              ) {
                if (!this.args) return;

                let img = e.selection.current();
                e.selection.removeNode(img);

                if (parseInt(this.args[0]) <= 100) {
                  img.style = "width:" + this.args[0] + "%;";
                } else {
                  img.style = "width:100%;max-width:" + this.args[0] + "px;";
                }
                e.selection.insertNode(img);
              }
            },
          },
          // {
          //     name: 'اسلایدر',
          //     tooltip: 'اسلایدر',
          //     list: {
          //         0: 'ایجاد اسلایدر تمام',
          //         1: 'ایجاد اسلایدر 500x',
          //         2: 'ایجاد اسلایدر 700x'
          //     },
          //     exec: (e, ...a) => {
          //
          //         if (a[1].control.args && a[1].control.args.length > 0) {
          //             if (stores.PostsStore.addToSlider.length <= 0) {
          //                 props.alert("لطفا ابتدا تصاویر اسلایدر را انتخاب کنید");
          //                 return
          //             }
          //             let id = Math.random().toString(36).substr(2, 5);
          //
          //             let style = "width:100%;";
          //             if (a[1].control.args[0] === "1")
          //                 style = "max-width:500px;";
          //             if (a[1].control.args[0] === "2")
          //                 style = "max-width:700px;";
          //
          //             let imgsTop = "";
          //             stores.PostsStore.addToSlider.map(v => {
          //                 imgsTop += "<div class=\"swiper-slide\" >" + "<img class='pan' src='" + v + "'  width=\"100%\" height=\"auto\"/>" + "</div>";
          //             });
          //
          //
          //             let imgs = "";
          //             stores.PostsStore.addToSlider.map(v => {
          //                 imgs += "<div class=\"swiper-slide\" >" + "<img src='" + v + "'  width=\"100%\" height=\"auto\"/>" + "</div>";
          //             });
          //
          //             let n =
          //                 "<div class='jodit-div-o-selector'  style='width: 100%;' contenteditable=\"false\" data-jodit_iframe_wrapper=\"1\"><div  style='"+style+"margin: auto;'  id='sp-slider' >\n" +
          //                 " <div id=\"swiper-top-" + id + "\" class=\"swiper-container  gallery-top\" dir=\"ltr\">\n"+
          //                 "   <div class=\"swiper-wrapper\">\n" +
          //                 imgsTop +
          //                 "   </div>\n" +
          //                 "  <div id=\"swiper-pagination-" + id + "\" class=\"swiper-pagination custom\"></div>\n"+
          //                 " </div>\n" +
          //
          //                 " <div id=\"swiper-thumbs-" + id + "\" class=\"swiper-container  gallery-thumbs\" dir=\"ltr\">\n"+
          //                 "   <div class=\"swiper-wrapper\">\n" +
          //                 imgs +
          //                 "   </div>\n" +
          //                 " </div>\n" +
          //
          //                 "<script>\n" +
          //                 "   var galleryThumbs = new Swiper('#swiper-thumbs-"+ id +"', {\n" +
          //                 "        direction: 'vertical',\n" +
          //                 "        spaceBetween: 10,\n" +
          //                 "        slidesPerView: 'auto',\n" +
          //                 "        loop: true,\n" +
          //                 "        freeMode: true,\n" +
          //                 "        watchSlidesVisibility: true,\n" +
          //                 "        watchSlidesProgress: true,\n" +
          //                 "        autoHeight: true,\n" +
          //                 "        breakpoints: {750: {\n" +
          //                 "                direction:'horizontal',\n" +
          //                 "                slidesPerView: 5,\n" +
          //                 "            }}});\n" +
          //                 "    var galleryTop = new Swiper('#swiper-top-"+ id +"', {\n" +
          //                 "        autoHeight:true,\n" +
          //                 "        spaceBetween: 10,\n" +
          //                 "        loop: true,\n" +
          //                 "        grabCursor: true,\n" +
          //                 "        autoplay: {delay: 4000,disableOnInteraction:false},\n" +
          //                 "        thumbs: {swiper: galleryThumbs},\n" +
          //                 "        keyboard: true,\n" +
          //                 "        pagination: {el: '#swiper-pagination-"+ id +"',type: 'bullets'}});"+
          //                 "</script>\n" +
          //                 "</div></div>";
          //
          //             if (props.sliderReplace){
          //                 e.setEditorValue(n);
          //             }else{
          //
          //                 e.selection.insertHTML(n);
          //             }
          //             stores.PostsStore.addToSlider = []
          //         }
          //     }
          // }
        ],

        // cleanHTML: {
        //     "timeout": 500,
        //     "removeEmptyElements": false,
        //     "fillEmptyParagraph": false,
        //     "replaceNBSP": false,
        //     "replaceOldTags": {
        //         "i": "em",
        //         "b": "strong"
        //     },
        //     "allowTags": false,
        //     "denyTags": false,
        // },
        // events: {
        //     afterInit: props.jodit,
        //     afterGetValueFromEditor : (v)=> v.value = v.value.replace(/style="([^"]+)"/g, (res, rules) => `style="${rules.replace(/&quot;/g, "'").replace(/font-family([^;]+);/g, '')}"`)
        //
        // },
        // toolbarInlineForSelection:true,
        minHeight: 700,
        imageDefaultWidth: "100%",
        // useIframeResizer:false,
        // colorPickerDefaultTab:"color",
        // addNewLineTagsTriggers:[
        //     "table",
        //     "iframe",
        //     "img",
        //     "hr",
        //     "jodit",
        //     "div"
        // ],
        // draggableTags:[
        //     "img",
        //     "a",
        //     "jodit-media",
        //     "jodit",
        //     "div"
        // ],
      }}
      tabIndex={1} // tabIndex of textarea
      onChange={props.onChange}
    />
  );
}

Editor.defaultProps = {
  lan: "fa",
  value: "",
  sliderReplace: false,
};

Editor.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  jodit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  lan: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  alert: PropTypes.func.isRequired,
  sliderReplace: PropTypes.bool,
};
export default Editor;
