import React, { useState } from "react";
import styles from "./admins.module.css";
import { useHistory } from "react-router-dom";
import DialogStatus from "./DialogStatus";
import { useDeleteAdminMutation } from "../../../Store/callApi/AdminDataService";
import AlertDialog from "../../common/AlertDialog";
import { Statement } from "../../../values/Statement";

import { Box, Divider, Button } from "@material-ui/core";

const AdminItem = (props) => {
  const {
    id,
    name,
    phone,
    status,
    username,
    officePhone,
    subPermission,
    setShowSnakBar,
  } = props;
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteAdminAlertshow, setDeleteAdminAlertshow] = useState(false);
  const [deleteAdmin] = useDeleteAdminMutation();

  const editAdminHandler = () => {
    history.push({
      adminStatus: history.location.pathname,
      pathname: "/add_edit_admin",
      state: {
        id,
        name,
        phone,
        status,
        username,
        officePhone,
        subPermission,
      },
    });
  };

  const deleteAdminHandler = () => {
    setDeleteAdminAlertshow(true);
  };

  return (
    <>
      <Box className={styles.itemAdminStyle}>
        <Box>
          <Box className={styles.styleTypography}>{name}</Box>
          <Box mt={1} className={styles.styleTypography}>
            {username}
          </Box>
          <Box className={styles.statusStyle}>
            وضعیت
            <p>{status}</p>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Button onClick={editAdminHandler} color="primary">
            ویرایش
          </Button>
          <Button onClick={() => setOpenDialog(true)} color="secondary">
            وضعیت
          </Button>
          <Button onClick={deleteAdminHandler} className={styles.btnColorRed}>
            حذف
          </Button>
          {openDialog && (
            <DialogStatus
              id={id}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              setShowSnakBar={setShowSnakBar}
            />
          )}
        </Box>
      </Box>
      {deleteAdminAlertshow && (
        <AlertDialog
          onClose={async ({ yesPress }) => {
            if (yesPress) {
              let res = await deleteAdmin(id);
              res.data.success && setShowSnakBar(true);
            } else {
              setDeleteAdminAlertshow(false);
            }
          }}
          question={true}
          description={Statement.deleteAdmin}
          negativeTextButton={"خیر"}
          positiveTextButton={"بله"}
        />
      )}
    </>
  );
};
export default AdminItem;
