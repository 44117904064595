import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import React, { useState, useMemo, useRef } from "react";
import PriceFormater from "../../../utils/priceFormater";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import { convertNumberToEN } from "../../../language/NumberConverter";
import AlertDialog from "../../common/AlertDialog";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import styles from "./carPriceBrands.module.css";


var moment = require("moment-jalaali");

const themeInstance = {
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) !important",
  color: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) !important",
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.background,
    color: theme.color,
  },
}));

const CarPriceBrands = ({
  models,
  brand,
  saveCategoryWithModelPriceChangesMade,
}) => {
  const classes = useStyles();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [carEditProcess, setCarEditProcess] = useState({});
  const [newCarPrice, setNewCarPrice] = useState("");
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const getTheMostRelevantDataForOption = (model, option) => {
    let extractedModel = model;
    if (model._doc) {
      extractedModel = model._doc;
    }
    if (option !== "ندارد") {
      let yearContaining = extractedModel.types.find((vyear) => {
        return vyear.options.some((voption) => voption.option === option);
      });
      let optionContaining = yearContaining.options.find(
        (voption) => voption.option === option
      );
      return [
        optionContaining.optionStartingPrice,
        optionContaining.lastPriceUpdateDateOption || "",
      ];
    } else {
      let yearContaining = extractedModel.types[0];
      if (yearContaining) {
        return [
          yearContaining.yearStartingPrice,
          yearContaining.lastPriceUpdateDateYear || "",
        ];
      } else {
        return ["", ""];
      }
    }
  };

  const closeEditModal = () => {
    setOpenEditDialog(false);
    setNewCarPrice("");
  };

  let newModelsList = [];
  models.forEach((model) => {
    let unoptionNames = [];
    let uniqueOptions = [];
    let accurateModel = model;
    if (model._doc) accurateModel = model._doc;
    accurateModel?.types?.forEach((type) => {
      type.options.forEach((option) => {
        unoptionNames.push({ option: option.option });
      });
    });

    let optionList = unoptionNames.filter((option) => {
      const isDuplicate = uniqueOptions.includes(option.option);
      if (!isDuplicate) {
        uniqueOptions.push(option.option);
        return true;
      }
      return false;
    });
    if (optionList.length) {
      optionList.forEach((eachOption, index) => {
        //here the algorithm should be implemented
        let [price, date] = getTheMostRelevantDataForOption(
          model,
          eachOption.option
        );
        newModelsList.push({
          ...model,
          option: eachOption.option,
          optionPrice: price ?? "ندارد",
          optionDate: date ?? "وارد نشده",
          newid: `${accurateModel?._id?.toString()}${index}`,
        });
      });
    } else {
      //algorithm should be implemented
      let [price, date] = getTheMostRelevantDataForOption(model, "ندارد");
      newModelsList.push({
        ...model,
        option: "ندارد",
        optionDate: date ?? "وارد نشده",
        optionPrice: price ?? "ندارد",
        newid: `${model?._id?.toString()}`,
      });
    }
  });
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className={styles.headerStyleTable}>
              <TableCell>برند</TableCell>
              <TableCell align="right">مدل</TableCell>
              <TableCell align="right">قیمت بر اساس</TableCell>
              <TableCell align="right">قیمت (تومان)</TableCell>
              <TableCell align="right">تریم</TableCell>
              <TableCell align="right">آخرین ویرایش قیمت</TableCell>
              <TableCell align="right">ستون ویرایش</TableCell>
              {/*<TableCell align="right"> حذف ستون</TableCell>*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {newModelsList.map((model, modelIndex) => {
              let helperBrandObj = model.brand;
              let helperOptionObj = model.option;
              let helperOptionPrice = model.optionPrice;
              let helperOptionUpdateTime = model.optionDate;
              model = model._doc || model;
              return (
                <TableRow
                  key={model.newid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {brand?.brand ?? helperBrandObj?.brand}
                  </TableCell>
                  <TableCell align="right">{model.model}</TableCell>
                  <TableCell align="right">قیمت صفر مدل</TableCell>
                  <TableCell align="right">
                    {PriceFormater(Math.ceil(helperOptionPrice * (10 / 9))) ||
                      "وارد نشده است"}
                  </TableCell>
                  <TableCell align="right">{helperOptionObj}</TableCell>
                  <TableCell align="right">
                    {moment(helperOptionUpdateTime).format(
                      "jYYYY/jM/jD _ HH:mm"
                    )}
                    {/* {" "}
                    <ThemeProvider theme={themeInstance}>
                    <Chip
                      icon={<FaceIcon />}
                      className={classes.root}   
                      style={{backgroundColor : "red !important"}}            
                      />
                    </ThemeProvider> */}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        setOpenEditDialog(true);
                        setCarEditProcess({
                          index: modelIndex,
                          ...model,
                          option: helperOptionObj,
                          optionPrice: helperOptionPrice,
                          brand: helperBrandObj || brand,
                        });
                      }}
                    >
                      ویرایش
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {carEditProcess._id ? (
        <Dialog onClose={() => setOpenEditDialog(false)} open={openEditDialog}>
          <DialogTitle>{`شما در حال ویرایش خودرو با مشخصات زیر هستید: `}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {" "}
                برند: {carEditProcess?.brand?.brand}
              </Grid>
              <Grid item xs={12}>
                {" "}
                مدل: {carEditProcess?.model}
              </Grid>
              <Grid item xs={12}>
                {" "}
                تریم : {carEditProcess?.option || "وارد نشده است"}
              </Grid>
              <Grid item xs={12}>
                {" "}
                قیمت صفر مدل :{" "}
                {PriceFormater(
                  Math.ceil(carEditProcess?.optionPrice * (10 / 9))
                ) +
                  " " +
                  "تومان" || "وارد نشده است"}
              </Grid>
              <Grid item xs={12}></Grid>
              <TextField
                onChange={(e) =>
                  setNewCarPrice(e.target.value.toString().replace(/,/g, ""))
                }
                placeholder="قیمت را وارد کنید"
                type="text"
                defaultValue={PriceFormater(newCarPrice)}
                label="قیمت جدید را وارد کنید"
                fullWidth={true}
                inputProps={{
                  style: {
                    fontSize: 14,
                    direction: "ltr",
                  },
                  maxLength: 22,
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    ? PriceFormater(convertNumberToEN(e.target.value))
                    : "";
                }}
                autoFocus
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                saveCategoryWithModelPriceChangesMade(
                  newCarPrice,
                  brand || carEditProcess.brand,
                  carEditProcess._id,
                  carEditProcess.option || "notregistered"
                );
                closeEditModal();
              }}
              color="primary"
            >
              ویرایش
            </Button>

            <Button
              onClick={() => {
                closeEditModal();
              }}
              color="secondary"
            >
              بستن
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {message !== "" && (
        <AlertDialog
          onClose={() => setMessage("")}
          type={alertType}
          question={false}
          description={message}
        />
      )}
    </>
  );
};

export default React.memo(CarPriceBrands);
