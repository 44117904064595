import React, { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import "./LogItem.module.css"
var moment = require("moment-jalaali");

const useStyles = makeStyles({
  root: {
    background: 'white',
    color : "orange ",
  },
});


const listUseStyles = makeStyles ({
  root : {
    '&:hover' : {
      transform : "scale3d(1.02 ,1,1)",
      transition : "0.3s",
      borderRadius : "20px",
    }
  }
})

const LogItem = ({ checked, setChecked, value, labelId }) => {
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value._id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value._id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();
  const listClasses = listUseStyles();

  const logItemStyle = {
    width: "100%",
    borderRadius: "5px",
    margin : 0
  }



  return (
    <>
      <ListItem
        style={logItemStyle}
        key={value}
        role={undefined}
        dense
        button
        onClick={handleToggle(value)}
        classes={{root : listClasses.root}}
      >
        <ListItemIcon>
          {value.requestType != "نوع درخواست" ?
          <Checkbox
            edge="start"
            checked={checked.indexOf(value._id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
          /> : ""
}
        </ListItemIcon>
        
            <ListItemText
              id={labelId}
              primary={`${value.requestType}`} style={{ width: 80 }}
            />
        
            <ListItemText
              id={labelId}
              primary={` ${value.serviceUsed}`}
              style={{ width: 80, paddingLeft: 10 }}
            />
            {value.requestType != "نوع درخواست"?
              <ListItemText
                id={labelId}
                primary={`${moment(value.createdAt).format(
                  " HH:mm:ss jYYYY/jM/jD"
                )}`}
                style={{ width: 100}}
              /> : <ListItemText
                id={labelId}
                primary={`زمان عملیات`}
                style={{ width: 80}}
              />
            }
            <p style={{margin: 0 , padding: 0 , marginLeft : 10 , display:'flex' , justifyContent:'center'}}>
            <ListItemText id={labelId} primary={value.name}/>
            { value.name == "نام وارد نشده" ?
            <p style={{margin : 0 , padding : 0}}>
              <WarningIcon classes={{root : classes.root}}/>
            </p> : ""
            }
            </p>
            </ListItem>
    </>
      );
};

      export default LogItem;
