import React from "react";
import Main from "../../common/UserMain";
import { Container, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import Header from "../../../features/errorPage/Header";
import { useStyles } from "../../../styles/header";
import GridMain from "../../../features/errorPage/Main";
import FilterMobile from "../../common/Drawer/FilterMobile";

const NewError = () => {
  const theme = useTheme();
  const isMatchesLapTopBp = useMediaQuery(theme.breakpoints.down("sm"));
  const containerClass = useStyles();
  const isMatches = useMediaQuery("(max-width:1085px)");
  return (
    <Main
      page={"newerrors"}
      pageName={"خطای جدید"}
      showToolbar={isMatchesLapTopBp ? true : false}
    >
      <Container
        maxWidth="false"
        className={`${containerClass.root} ${
          isMatches && containerClass.responsiveStyle
        } ${isMatchesLapTopBp ? containerClass.containerMobile : ""}`}
      >
        <Hidden mdUp>
          <FilterMobile />
        </Hidden>
        <Header />
        <GridMain />
      </Container>
    </Main>
  );
};

export default NewError;
