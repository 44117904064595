import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { ApiClient, ApiHelper } from "../../../data/remote";
import { timeOneHourAhead } from "../../../Hooks/timeOneHourAhead";

import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AlertDialog from "../../common/AlertDialog";
import { inject, observer } from "mobx-react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import RequestState from "../../common/RequestState";
import ChangeDateTimeDialog from "./ChangeDateTimeDialog";
import * as momentjs from "moment";
var moment = require("moment-jalaali");
import jm from "jalali-moment";
moment.loadPersian({ dialect: "persian-modern" });
moment().format("jYYYY/jM/jD");
jm().locale("fa").format("YYYY/M/D");
jm.bindCalendarSystemAndLocale();
momentjs().format();

let apiClient = null;
const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "red", // Set your desired color
        },
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "1rem",
        fontFamily: "IRANSans_LFN",
        fontWeight: 400,
        lineHeight: 1.5,
      },
    },
  },
});
const inProgresstheme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "orange", // Set your desired color
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: -10,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "1rem",
        fontFamily: "IRANSans_LFN",
        fontWeight: 400,
        lineHeight: 1.5,
      },
    },
  },
});

@inject("AccountStore")
@inject("RequestsStore")
@observer
class ChangeStatusDialog extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();

    props.RequestsStore.emptyCurrent();

    this.state = {
      id: props.id,
      data: props.RequestsStore.getCurrent(),
      status: "",
      msg: "",
      sendRequest: false,
      message: "",
      showUserInfo: "",
      getDataRequest: false,
      getDataRequestErr: true,
      changeDateDialog: false,
      sendSMS: false,
      cancelReq: false,
      canceledFromServer: false,
      inProgress: false,
      isUknown: this.props.isUknown,
      togetway: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  handleClose() {
    this.props.onClose();
  }

  getData() {
    if (this.state.getDataRequest) {
      return;
    }

    this.setState({ getDataRequest: true, getDataRequestErr: false });

    apiClient.get(
      ApiHelper.getRequestInfoById_GET(this.state.id),
      (error, response) => {
        if (error) {
          this.setState({
            getDataRequest: false,
            getDataRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          console.log("response*: ", response);
          this.setState({
            getDataRequest: false,
            getDataRequestErr: false,
            data: response.data,
            sendSMS: response.data.verifiedPhone,
            cancelReq: response.data.isDeleted || false,
            canceledFromServer: response.data.isDeleted || false,
            inProgress: response.data.inProgress || false,
            togetway: response.data.togetway,
          });
        }
      }
    );
  }

  submit() {
    if (this.state.sendRequest) return;

    if (this.state.canceledFromServer) {
      this.setState({
        message:
          " کاربر گرامی :این درخواست لغو شده است امکان ویرایش یا ثبت آن وجود ندارد",
      });
      return;
    }

    if (this.state.status === "") {
      this.setState({ message: "لطفا وضعیت را مشخص کنید" });
      return;
    }

    this.setState({ sendRequest: true, inputWarn: 0 });
    let status = "created";
    if (this.state.inProgress) {
      status = "in_progress";
    }
    if (this.state.cancelReq) {
      status = "deleted";
    }
    if (!this.state.inProgress && !this.state.cancelReq) {
      status = "done";
    }
    apiClient.get(
      ApiHelper.changeStatusRequest_GET(status),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false }, () =>
            this.props.onSuccess(response.data._id)
          );
        }
      },
      {
        id: this.state.data._id,
        sendSMS: this.state.sendSMS ? 1 : 0,
        deleteReq: this.state.cancelReq ? 1 : 0,
        inProgress: this.state.inProgress ? 1 : 0,
      }
    );
  }
  inProgressOptionProvider() {
    if (this.state.data.statusCode === 201) return;
    return (
      <div style={{ display: "flex", flex: 1, margin: 12 }}>
        <FormControl component="fieldset">
          <FormGroup style={{ margin: 0 }} aria-label="position" row>
            <ThemeProvider theme={inProgresstheme}>
              <FormControlLabel
                checked={this.state.inProgress}
                onChange={(e) => {
                  this.setState({
                    inProgress: !this.state.inProgress,
                    cancelReq: false,
                    sendSMS: false,
                  });
                }}
                control={
                  <Checkbox
                    color="secondary"
                    indeterminate
                    inputProps={{
                      "aria-label": "tirtiary checkbox",
                    }}
                  />
                }
                label={"در حال پیگیری میباشد"}
                labelPlacement="end"
              />
            </ThemeProvider>
          </FormGroup>
        </FormControl>
      </div>
    );
  }
  render() {
    const { formatDate } = timeOneHourAhead();
    let reservedDate = new Date(this.state.data.reserveDatetime);
    let PlusOneHourReserved = reservedDate.setTime(reservedDate.getTime());
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            style={{ width: "80vw", maxWidth: 500, minWidth: 300, padding: 5 }}
          >
            {this.state.getDataRequest || this.state.getDataRequestErr ? (
              <RequestState
                error={this.state.getDataRequestErr}
                retryOnPress={() => this.getData()}
                title={"در حال دریافت مشخصات درخواست ..."}
                request={this.state.getDataRequest}
              />
            ) : (
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.type === "sell"
                      ? "درخواست فروش"
                      : "درخواست خرید"}
                  </p>

                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.location &&
                      this.state.data.location.location}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p
                    style={{
                      flex: 1,
                      fontSize: 13,
                      margin: 5,
                    }}
                  >
                    {this.state.data.status}
                  </p>
                  <p
                    style={{
                      flex: 1,
                      fontSize: 13,
                      margin: 5,
                    }}
                  >
                    {moment(this.state.data.createdAt).format(
                      "jYYYY/jM/jD - HH:mm"
                    )}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.name === ""
                      ? "بدون نام"
                      : this.state.data.name}
                  </p>
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {
                      <span
                        style={{
                          color: this.state.data.verifiedPhone
                            ? "green"
                            : "red",
                          fontSize: 11,
                          padding: 5,
                        }}
                      >
                        {this.state.data.verifiedPhone
                          ? "تایید شده"
                          : "تایید نشده"}
                      </span>
                    }{" "}
                    {this.state.data.phone}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.sellCarInfo.brand} |{" "}
                    {this.state.data.sellCarInfo.model}
                  </p>
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    {this.state.data.sellCarInfo.option}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    margin: 12,
                    borderBottom: "1px solid #d2d2d2",
                  }}
                >
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    سال : {this.state.data.sellCarInfo.year}
                  </p>
                  <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                    کیلومتر : {this.state.data.sellCarInfo.km}
                  </p>
                </div>

                {this.state.data.type === "sell" && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 12,
                      borderBottom: "1px solid #d2d2d2",
                      backgroundColor: "#e4f6e9",
                    }}
                  >
                    <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                      زمان مراجعه
                    </p>
                    <p
                      style={{
                        flex: 1,
                        fontSize: 13,
                        margin: 5,
                      }}
                    >
                      {this.state.data.reserveDatetime
                        ? moment(PlusOneHourReserved).format(
                            "dddd jD jMMMM jYYYY  ساعت "
                          ) + formatDate(PlusOneHourReserved)
                        : "رزرو نشده"}
                    </p>
                  </div>
                )}

                {this.state.data.reserveLocation && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 12,
                      borderBottom: "1px solid #d2d2d2",
                    }}
                  >
                    <p style={{ flex: 1, fontSize: 13, margin: 5 }}>
                      شعبه مراجعه
                    </p>
                    <p
                      style={{
                        flex: 1,
                        fontSize: 13,
                        margin: 5,
                      }}
                    >
                      {this.state.data.reserveLocation &&
                        this.state.data.reserveLocation.name}
                    </p>
                  </div>
                )}
                {this.state.data.buyCarId && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 12,
                      borderBottom: "1px solid #d2d2d2",
                    }}
                  >
                    <p style={{ flex: 1, fontSize: 13, margin: 5 }}>کد خودرو</p>
                    <p
                      style={{
                        flex: 1,
                        fontSize: 13,
                        margin: 5,
                      }}
                    >
                      {this.state.data.buyCarId.code}
                    </p>
                  </div>
                )}
                {this.state.data.buyCarId && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 12,
                      borderBottom: "1px solid #d2d2d2",
                    }}
                  >
                    <p style={{ flex: 1, fontSize: 13, margin: 5 }}>کد خودرو</p>
                    <p
                      style={{
                        flex: 1,
                        fontSize: 13,
                        margin: 5,
                      }}
                    >
                      {this.state.data.buyCarId.code}
                    </p>
                  </div>
                )}
                {this.state.data.userReceptionCode && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 12,
                      borderBottom: "1px solid #d2d2d2",
                    }}
                  >
                    <p style={{ flex: 1, fontSize: 13, margin: 5 }}>کد پذیرش</p>
                    <p
                      style={{
                        flex: 1,
                        fontSize: 13,
                        margin: 5,
                      }}
                    >
                      {this.state.data.userReceptionCode}
                    </p>
                  </div>
                )}
                {this.state.data.description && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 12,
                      borderBottom: "1px solid #d2d2d2",
                    }}
                  >
                    <p style={{ flex: 1, fontSize: 13, margin: 5 }}>توضیحات</p>
                    <p
                      style={{
                        flex: 1,
                        fontSize: 13,
                        margin: 5,
                      }}
                    >
                      {this.state.data.description.slice(0, 40) + "..."}
                    </p>
                  </div>
                )}
                {this.state.data.type === "sell" &&
                  this.state.data.reserveLocation &&
                  this.state.data.reserveLocation && (
                    <div style={{ margin: 0 }}>
                      <div style={{ display: "flex", flex: 1, margin: 12 }}>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              checked={this.state.sendSMS}
                              onChange={(e) => {
                                this.setState({ sendSMS: !this.state.sendSMS });
                              }}
                              control={<Checkbox color="primary" />}
                              label={"تایید شماره و ارسال پیامک"}
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <ThemeProvider theme={theme}>
                              <FormControlLabel
                                checked={this.state.cancelReq}
                                onChange={(e) => {
                                  if (this.state.canceledFromServer) {
                                    return;
                                  }
                                  this.setState({
                                    cancelReq: !this.state.cancelReq,
                                  });
                                }}
                                control={
                                  <Checkbox
                                    color="secondary"
                                    indeterminate
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                }
                                label={"کنسل کردن این درخواست"}
                                labelPlacement="end"
                              />
                            </ThemeProvider>
                          </FormGroup>
                        </FormControl>
                      </div>
                      {this.inProgressOptionProvider()}
                    </div>
                  )}
              </div>
            )}
          </DialogContent>

          {this.state.sendRequest ||
          this.state.getDataRequestErr ||
          this.state.getDataRequest ? (
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={25} />
            </DialogActions>
          ) : (
            <DialogActions>
              <FormControl style={{ flex: 1, margin: 10 }} label="وضعیت">
                <Select
                  defaultValue={
                    this.state.status === "" ? "0" : this.state.status
                  }
                  variant="outlined"
                  margin={"dense"}
                  style={{ fontSize: 13 }}
                  onChange={(e) =>
                    this.setState({
                      status: e.target.value === "0" ? "" : e.target.value,
                    })
                  }
                >
                  <MenuItem style={{ fontSize: 13 }} value={"0"}>
                    انتخاب وضعیت
                  </MenuItem>
                  <MenuItem style={{ fontSize: 13 }} value={"done"}>
                    انجام شد
                  </MenuItem>
                </Select>
              </FormControl>

              {this.state.data.type === "sell" && (
                <Button
                  onClick={() => this.setState({ changeDateDialog: true })}
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    this.state.cancelReq ||
                    this.state.canceledFromServer ||
                    this.state.inProgress
                  }
                >
                  ویرایش
                </Button>
              )}

              <Button
                onClick={() => this.submit()}
                color="primary"
                size="small"
              >
                ثبت
              </Button>

              <Button
                onClick={() => this.handleClose()}
                color="secondary"
                size="small"
              >
                بستن
              </Button>
            </DialogActions>
          )}
        </Dialog>

        {this.state.changeDateDialog && (
          <ChangeDateTimeDialog
            onClose={() => {
              this.setState({ changeDateDialog: false });
            }}
            onSuccess={({
              dateTime,
              agentInfo,
              name,
              description,
              togetway,
            }) => {
              let myFixedDateTime = new Date(dateTime.toString());
              myFixedDateTime.setHours(myFixedDateTime.getHours());
              this.state.data.reserveDatetime = myFixedDateTime; // here i should decrease the time by one hour !
              this.state.data.reserveLocation = agentInfo;
              this.state.data.description = description;
              this.state.data.name = name;
              this.state.data.togetway = togetway;
              this.setState({ changeDateDialog: false });
            }}
            id={this.state.id}
            agentInfo={this.state.data.reserveLocation}
            name={this.state.data.name}
            dateTime={this.state.data.reserveDatetime}
            description={this.state.data.description}
            togetway={this.state.data.togetway}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </div>
    );
  }
}

ChangeStatusDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  history: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  id: PropTypes.string.isRequired,
};
export default ChangeStatusDialog;
