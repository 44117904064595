import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Snackbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseModal from "../../packages/icons/CloseModal";
import { useDeleteCityMutation } from "../../Store/callApi/MarketingInformation";
import styles from "./CitiesDialog.module.css";
const ArchiveDialog = ({ isOpen, onClose, cityName, cityId, cityArchive }) => {
  const [deleteCity] = useDeleteCityMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  let body;
  cityArchive
    ? (body = {
        archive: false,
      })
    : (body = {
        archive: true,
      });

  const handleArchive = async () => {
    try {
      const res = await deleteCity({ ID: cityId, body });

      if (res?.data?.success) {
        setSnackbarSeverity("warning");
        setSnackbarMessage(res?.data?.message);
      }
      setSnackbarOpen(true);
      onClose();
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(error?.data?.message || "خطایی رخ داد");
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <div className={styles.modal}>
          <div className={styles.modalContentArchive}>
            <div className={styles.modalHeader}>
              <p className={styles.dialogTitle}>
                {cityArchive ? "فعال کردن شهر " : "آرشیو کردن شهر"}
              </p>
              <span className={styles.customClose} onClick={onClose}>
                <CloseModal />
              </span>
            </div>
            <div className={styles.modalBody}>
              <p className={styles.archiveDescription}>
                {`آیا مطمئن هستید که می‌خواهید شهر `}
                <span className={styles.cityName}>{`"${cityName}"`}</span>
                {`${cityArchive ? " را فعال کنید؟" : " را آرشیو کنید؟"}`}
              </p>
            </div>
            <div className={styles.modalFooterArchive}>
              <Button
                id="archiveButton"
                className={styles.modalButton}
                onClick={handleArchive}
                style={{
                  backgroundColor: cityArchive ? "#2E7D32" : "#D32F2F",
                  color: "#fff",
                }}
              >
                {cityArchive ? "فعال کردن" : "آرشیو کردن"}
              </Button>
              <Button
                id="cancelButton"
                className={`${styles.modalButton} ${styles.modalButtonCancle} `}
                onClick={onClose}
              >
                انصراف
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

ArchiveDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cityName: PropTypes.string.isRequired,
  cityId: PropTypes.string.isRequired,
};

export default ArchiveDialog;
