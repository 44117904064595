import React from "react";
import Divider from "@material-ui/core/Divider";
import moment from "moment-jalaali";
import style from "./requests.module.css";

import RequestsBtnsDiscount from "../../../features/Requests/RequestsBtnsDiscount";
import BtnsCustomDiscount from "../../../features/Requests/BtnsCustomDiscount";
import BtnsStatus from "../../../features/Requests/BtnsStatus";
import BtnsClerkSellRequest from "../../../features/Requests/BtnsClerkSellRequest";
import { useCopyToClipboard } from "../../../Hooks/copy_to_clipboard/useCopyToClipboard";
import SnackBar from "../../common/SnackBar";
const ListItems = ({
  phone,
  name,
  createdAt,
  sellCarInfo,
  verifiedPhone,
  status,
  type,
  onClickStatus,
  statusIndicator = false,
  userDiscount,
  userReceptionCode,
  customDiscount,
  clerkSellRequest,
  agentName,
}) => {
  const formattedDateTime = () => {
    return moment(createdAt).format("jYYYY/jM/jD _ HH:mm");
  };
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  return (
    <div className={style.boxRequestStyle}>
      <div className={style.RequestTextStyle}>
        <div className={style.flexOne}>
          <div className={style.RequestTextStyle}>
            <h4
              className={`${verifiedPhone ? style.colorGreen : style.colorRed}`}
            >
              {verifiedPhone ? "تایید شده" : "مجهول"}
            </h4>
            <h4 onClick={() => copyToClipboard(phone)}>{phone}</h4>
          </div>

          <div className={style.RequestTextStyle}>
            <p>تاریخ</p>
            <h4 className={style.dateStyle}>{formattedDateTime()}</h4>
          </div>

          <div className={style.RequestTextStyle}>
            <p>{name || "بدون نام"}</p>
          </div>

          {sellCarInfo && (
            <div className={style.RequestTextStyle}>
              <h4>
                {sellCarInfo.brand} | {sellCarInfo.model}
              </h4>
              <h4>{sellCarInfo.year}</h4>
            </div>
          )}

          <div className={style.RequestTextStyle}>
            <p>{type === "buy" ? "خرید" : "فروش"}</p>
            <p>{status}</p>
          </div>

          <div className={style.RequestTextStyle}>
            <p>کد پذیرش</p>
            <p onClick={() => copyToClipboard(userReceptionCode)}>
              {userReceptionCode || "کد ندارد"}
            </p>
          </div>
          <div className={style.RequestTextStyle}>
            <p>نام شعبه</p>
            <p>{agentName || "وارد نشده"}</p>
          </div>
        </div>
      </div>
      <Divider className={style.marginTop} />

      <div className={style.displayFlex}>
        <BtnsStatus
          onClickStatus={onClickStatus}
          color="secondary"
          statusIndicator={statusIndicator}
        />

        <RequestsBtnsDiscount
          iconColor="primary"
          iconName="local_offer"
          text="دارای تخفیف"
          propClass={style.btnRequestBox}
          userDiscount={userDiscount}
        />

        <BtnsClerkSellRequest
          propClass={style.clerkSellRequest}
          clerkSellRequest={clerkSellRequest}
        />

        <BtnsCustomDiscount
          propClass={style.btnRequestBox}
          customDiscount={customDiscount}
          iconName="primary"
        />
      </div>
      <SnackBar variant={"success"} message={"شماره کپی شد"} open={isCopied} />
    </div>
  );
};

export default ListItems;
