var moment = require("moment");

export const openLink = (l, self = false) => {
  return window.open(l, self ? "_self" : "_blank");
};

export const getQueryVariable = () => {
  let r = {};
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair.length === 2) {
      r[pair[0]] = pair[1];
    }
  }
  return r;
};

export const toHHMMSS = (secs) => {
  let sec_num = parseInt(secs, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor(sec_num / 60) % 60;
  let seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => true)
    .join(" : ");
};

export const setMomentOffset = (serverTime) => {
  var offset = new Date(serverTime).getTime() - Date.now();
  moment.now = function () {
    return offset + Date.now();
  };
};
