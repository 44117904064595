import React, { useState, useEffect, useReducer } from "react";
import { ApiClient, ApiHelper } from "../../../../data/remote";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { convertNumberToEN } from "../../../../language/NumberConverter";
import Checkbox from "@material-ui/core/Checkbox";
import DialogReceiveCode from "../DialogReceiveCode/DialogReceiveCode";
import SnackBar from "../../../common/snackBar/snackBar";
import CircularIntegration from "../../../common/ProgressButton/ProgressButton";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContractInitialInformation from "../../../../interface/contract";
import Chip from "@material-ui/core/Chip";
import useGetAgents from "../../../../Hooks/Agents/useGetAgents";
import Agents from "./agents";

const reducer = (state, action) => {
  if (action.copy) {
    return action.payload;
  }
  if (action === "reset") {
    return {};
  } else
    return {
      ...state,
      ...(typeof action === "function" ? action() : action),
    };
};

let deliverReceiptContract = new ContractInitialInformation();
deliverReceiptContract.addCustomeInformation({
  stringDate: "",
  buyerFathersName: "",
  buyerCity: "",
  sellerCity: "",
  hour: "",
  registerCodeCompany: "579350",
  company: "نیکبخت خودرو تهران",
  nationalCodeCompany: "14010099179",
  branchName: "",
  stringDate: "",
  branchAddress: "",
  branchPhoneNumber: "021-22989934",
  carCard: true,
  carCompanyPaper: true,
  carGreenCard: true,
  carGuarantee: true,
  carThirdPartyInsurance: true,
  carTechExamSheet: true,
  carMortgageForm: true,
  carBodyInsurance: true,
  nationalCard: true,
  fuelCard: true,
  jackWheelWrench: true,
  carServiceBooklet: true,
  spareSwitch: true,
  spareWheel: true,
  timLock: true,
  goldenCard: true,
  soles: true,
  antena: true,
  ashTray: true,
  screwCorrosionWrench: true,
  reverseCamera: true,
  electricGlass: true,
  engineDoorScrewFenderTrunkHood: true,
  rearSeatMonitor: true,
  recordingMonitor: true,
  burglarAlarm: true,
  windshield: true,
  lighter: true,
  sunroof: true,
  cruiseControl: true,
  seatHeater: true,
  typeRadar: true,
  tronicBrigade: true,
  electricMemoryChair: true,
  reserveLocation: {
    name: "",
    address: "",
    officePhone: "",
  },
});
let initial = deliverReceiptContract.getInitObject();

let requestStateObject = {
  message: "",
  error: false,
  success: false,
  responseObj: {},
  openNotif: false,
  pending: false,
};
const requestReducer = (state, action) => {
  switch (action.type) {
    case "throwError":
      return {
        ...state,
        message: action.message,
        success: false,
        error: true,
        responseObj: action.responseObj || {},
        openNotif: true,
        pending: false,
      };
      break;
    case "throwSuccess":
      return {
        ...state,
        message: action.message,
        error: false,
        success: true,
        responseObj: action.responseObj || {},
        openNotif: true,
        pending: false,
      };
    case "closeNotif":
      return {
        ...state,
        error: false,
        success: false,
        openNotif: false,
        pending: false,
      };
    case "pending":
      return {
        ...state,
        pending: true,
        success: false,
        error: false,
      };
    case "openNotif":
      return {
        ...state,
        openNotif: true,
      };
    default:
      return {
        message: "",
        error: false,
        success: false,
        responseObj: {},
        openNotif: false,
        pending: false,
      };
      break;
  }
};

const DeliveryReceipt = ({ delivery = true }) => {
  const [agents, agentloading] = useGetAgents("accepted");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [information, dispatchInfo] = useReducer(reducer, initial);
  const [message, setMessage] = useState("");
  const [showDialogReceiveCode, setShowDialogReceiveCode] = useState(false);
  const [receptionCodeEnteredByUser, setReceptionCodeEnteredByUser] =
    useState("");
  const [requetsState, dispatchRequestState] = useReducer(
    requestReducer,
    requestStateObject
  );
  const [activeCheckboxAgent, setActiveCheckboxAgent] = useState(null);

  const [pdfUrl, setPdfUrl] = useState(null);
  const [infoFromCodeReq, setInfoFromCodeReq] = useState({ loading: false });
  const [contractRequest, setContractRequest] = useState({
    loading: false,
    success: false,
    error: false,
  });
  const [saveContractReq, setSaveContractReq] = useState({
    loading: false,
    success: false,
    error: false,
  });
  let apiClient = ApiClient.getInstance();
  const storedId = localStorage.getItem("accountStoreAdmin");
  const parsedData = JSON.parse(storedId);

  const getInfoByReceptionCode = () => {
    setShowDialogReceiveCode(true);
  };
  const getContract = () => {
    let data = {
      ...information,
      contractKind: delivery ? "car_delivery_receipt" : "receive_receipt",
    };
    dispatchRequestState({ type: "pending" });
    setContractRequest({ loading: true, success: false, error: false });
    apiClient.post(
      ApiHelper.getContract,
      (error, response) => {
        if (error) {
          dispatchRequestState({ type: "openNotif" });
          dispatchRequestState({
            type: "throwError",
            message: "لطفا بعد از چند ثانیه دوباره تلاش کنید !",
            responseObj: response,
          });
          setContractRequest({ loading: false, success: false, error: true });
        } else {
          dispatchRequestState({ type: "openNotif" });
          setPdfUrl(response.pdfurl);
          dispatchRequestState({
            type: "throwSuccess",
            message: "درخواست با موفقیت فرستاده شد",
            responseObj: response,
          });
          setContractRequest({ loading: false, success: true, error: false });
        }
      },
      data
    );
  };

  const saveInfoOfContract = () => {
    let data = {
      ...information,
      receptionCode: receptionCodeEnteredByUser,
      agentId: parsedData.profile._id,
      branchAddress: information.reserveLocation?.address,
      branchPhoneNumber: information.reserveLocation?.officePhone,
      branchName: information.reserveLocation?.name,
      dateNow: new Date(),
    };

    dispatchRequestState({ type: "pending" });
    setSaveContractReq({ loading: true, success: false, error: false });
    apiClient.post(
      ApiHelper.updateContract,
      (error, response) => {
        if (error) {
          dispatchRequestState({ type: "openNotif" });
          dispatchRequestState({
            type: "throwError",
            message: response.message,
            responseObj: response,
          });
          setSaveContractReq({ loading: false, success: false, error: true });
        } else {
          dispatchRequestState({ type: "openNotif" });
          dispatchRequestState({
            type: "throwSuccess",
            message: response.message,
            responseObj: response,
          });
          setSaveContractReq({ loading: false, success: true, error: false });
        }
        setTimeout(() => {
          setSaveContractReq({ loading: false, success: false, error: false });
        }, 5000);
      },
      data
    );
  };

  useEffect(() => {
    setActiveCheckboxAgent(information.reserveLocation?._id);
  }, [information.reserveLocation]);
  const handleAgentCheckbox = (index) => {
    setActiveCheckboxAgent(index);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Button
            style={{ whiteSpace: "nowrap" }}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => getInfoByReceptionCode()}
          >
            گرفتن اطلاعات با کد پذیرش
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Chip
            label={
              delivery ? "رسید تحویل خودرو و مدرک" : "رسید دریافت خودرو و مدرک"
            }
            color="primary"
            style={{ fontSize: "larger" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          {(() => {
            if (information.receptionCode === initial.receptionCode) {
              return (
                <Chip
                  label="کد پذیرش را وارد کنید"
                  style={{ backgroundColor: "red", color: "white" }}
                />
              );
            }
            return (
              <Chip
                label={"کد پذیرش : " + information.receptionCode}
                style={{ backgroundColor: "green", color: "white" }}
              />
            );
          })()}
        </Grid>
      </Grid>
      <h4>{`اطلاعات ${delivery ? "خریدار" : " فروشنده"}: `}</h4>
      <Divider />
      <Grid container spacing={2}>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => {
              delivery
                ? dispatchInfo({ buyerName: t.target.value })
                : dispatchInfo({ sellerName: t.target.value });
            }}
            value={
              delivery
                ? information.buyerName + " " + information.buyerLastName
                : information.sellerName + " " + information.sellerLastName
            }
            label="اینجانب"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => {
              delivery
                ? dispatchInfo({ buyerFathersName: t.target.value })
                : dispatchInfo({ sellerFathersName: t.target.value });
            }}
            value={
              delivery
                ? information.buyerFathersName
                : information.sellerFathersName
            }
            label="فرزند"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => {
              delivery
                ? dispatchInfo({ buyerNationalCode: t.target.value })
                : dispatchInfo({ sellerNationalCode: t.target.value });
            }}
            value={
              delivery
                ? information.buyerNationalCode
                : information.sellerNationalCode
            }
            label="کد ملی"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => {
              delivery
                ? dispatchInfo({ buyerCity: t.target.value })
                : dispatchInfo({ sellerCity: t.target.value });
            }}
            value={delivery ? information.buyerCity : information.sellerCity}
            label="صادره از"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) =>
              delivery
                ? dispatchInfo({ buyersPhoneNumber: t.target.value })
                : dispatchInfo({ sellerPhoneNumber: t.target.value })
            }
            value={
              delivery
                ? information.buyersPhoneNumber
                : information.sellerPhoneNumber
            }
            label="شماره تماس"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} lg={12}>
          <TextField
            onChange={(t) =>
              delivery
                ? dispatchInfo({ buyerAddress: t.target.value })
                : dispatchInfo({ sellerAddress: t.target.value })
            }
            value={
              delivery ? information.buyerAddress : information.sellerAddress
            }
            label="به نشانی"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carKm: t.target.value })}
            value={information.carKm ?? ""}
            label="کیلومتر کاردکرد"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ hour: t.target.value })}
            value={information.hour}
            label="ساعت"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ company: "نیکبخت خودرو تهران" })}
            value={"نیکبخت خودرو تهران"}
            label="شرکت"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ registerCodeCompany: "579350" })}
            value={"579350"}
            label="شماره ثبت"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) =>
              dispatchInfo({ nationalCodeCompany: "14010099179" })
            }
            value={"14010099179"}
            label="شناسه ملی"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ branchName: t.target.value })}
            value={information.reserveLocation?.name ?? ""}
            label="شعبه"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ stringDate: t.target.value })}
            value={information.stringDate}
            label="تاریخ"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} lg={12}>
          <TextField
            onChange={(t) =>
              dispatchInfo({
                branchAddress: t.target.value,
              })
            }
            value={information.reserveLocation?.address ?? ""}
            label="آدرس"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>

        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) =>
              dispatchInfo({ branchPhoneNumber: e.target.value })
            }
            value={information.reserveLocation?.officePhone ?? ""}
            label="شماره تماس شعبه"
            inputProps={{ maxLength: 100 }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
      </Grid>
      <h4>مشخصات خودرو:</h4>
      <Divider />
      <Grid container spacing={2}>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carKind: t.target.value })}
            value={information.carKind ?? ""}
            label="نوع خودرو"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carBrand: t.target.value })}
            value={information.carBrand ?? ""}
            label="برند خودرو"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carTreim: t.target.value })}
            value={information.carTreim ?? ""}
            label="تریم خودرو"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) =>
              dispatchInfo({ carProductionYear: t.target.value })
            }
            value={information.carProductionYear ?? ""}
            label="سال خودرو"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carCapacity: t.target.value })}
            value={information.carCapacity ?? ""}
            label="ظرفیت"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carBodyColor: t.target.value })}
            value={information.carBodyColor ?? ""}
            label="رنگ و بدنه"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carFuelType: t.target.value })}
            value={information.carFuelType ?? ""}
            label="نوع سوخت"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carMotorCode: t.target.value })}
            value={information.carMotorCode ?? ""}
            label="شماره موتور: "
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carShasiCode: t.target.value })}
            value={information.carShasiCode ?? ""}
            label="شماره شاسی: "
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carVIN: t.target.value })}
            value={information.carVIN ?? ""}
            label="شناسه وسیله نقلیه :"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>
        <Grid item sm={12} xs={4} style={classes.gridInput}>
          <TextField
            onChange={(t) => dispatchInfo({ carPlqueCode: t.target.value })}
            value={information.carPlqueCode ?? ""}
            label="شماره پلاک"
            inputProps={{ maxLength: 100, readOnly: true }}
            fullWidth={true}
            variant="outlined"
            margin={"dense"}
            onInput={(e) => {
              e.target.value = e.target.value
                ? convertNumberToEN(e.target.value).toString()
                : "";
            }}
          />
        </Grid>

        <Grid container spacing={1}>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.carCard}
              onChange={() => dispatchInfo({ carCard: !information.carCard })}
            />
            کارت خودرو
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.carCompanyPaper}
              onChange={() =>
                dispatchInfo({ carCompanyPaper: !information.carCompanyPaper })
              }
            />
            برگ کمپانی
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.carGreenCard}
              onChange={() =>
                dispatchInfo({ carGreenCard: !information.carGreenCard })
              }
            />
            برگ سبز
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.carGuarantee}
              onChange={() =>
                dispatchInfo({ carGuarantee: !information.carGuarantee })
              }
            />
            برگه گارانتی
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.carThirdPartyInsurance}
              onChange={() =>
                dispatchInfo({
                  carThirdPartyInsurance: !information.carThirdPartyInsurance,
                })
              }
            />
            بیمه شخحص ثالث
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.carTechExamSheet}
              onChange={() =>
                dispatchInfo({
                  carTechExamSheet: !information.carTechExamSheet,
                })
              }
            />
            برگه معاینه فنی
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.carMortgageForm}
              onChange={() =>
                dispatchInfo({ carMortgageForm: !information.carMortgageForm })
              }
            />
            برگه فک رهن
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.carBodyInsurance}
              onChange={() =>
                dispatchInfo({
                  carBodyInsurance: !information.carBodyInsurance,
                })
              }
            />
            بیمه بدنه
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.nationalCard}
              onChange={() =>
                dispatchInfo({ nationalCard: !information.nationalCard })
              }
            />
            کارت ملی
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.fuelCard}
              onChange={() => dispatchInfo({ fuelCard: !information.fuelCard })}
            />
            کارت سوخت
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.jackWheelWrench}
              onChange={() =>
                dispatchInfo({ jackWheelWrench: !information.jackWheelWrench })
              }
            />
            جک و آچار چرخ
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.carServiceBooklet}
              onChange={() =>
                dispatchInfo({
                  carServiceBooklet: !information.carServiceBooklet,
                })
              }
            />
            دفترچه سرویس خودرو
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.spareSwitch}
              onChange={() =>
                dispatchInfo({ spareSwitch: !information.spareSwitch })
              }
            />
            سوویچ یدک
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.spareWheel}
              onChange={() =>
                dispatchInfo({ spareWheel: !information.spareWheel })
              }
            />
            لاستیک بدنه
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.timLock}
              onChange={() => dispatchInfo({ timLock: !information.timLock })}
            />
            قفل رینگ
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.goldenCard}
              onChange={() =>
                dispatchInfo({ goldenCard: !information.goldenCard })
              }
            />
            کارت طلایی
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.soles}
              onChange={() => dispatchInfo({ soles: !information.soles })}
            />
            کفپایی
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.antena}
              onChange={() => dispatchInfo({ antena: !information.antena })}
            />
            آنتن
          </Grid>
          <Grid item sm={4} xs={6} lg={2}>
            <Checkbox
              {...label}
              checked={information.ashTray}
              onChange={() => dispatchInfo({ ashTray: !information.ashTray })}
            />
            زیر سیگاری
          </Grid>
        </Grid>
        <Divider />
        {delivery ? (
          <>
            <h4>اطلاعات تکمیلی خودرو:</h4>
            <Grid container spacing={1}>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.screwCorrosionWrench}
                  onChange={() =>
                    dispatchInfo({
                      screwCorrosionWrench: !information.screwCorrosionWrench,
                    })
                  }
                />
                آچار خوردگی پیچ ها
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.reverseCamera}
                  onChange={() =>
                    dispatchInfo({ reverseCamera: !information.reverseCamera })
                  }
                />
                دوربین دنده عقب
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.electricGlass}
                  onChange={() =>
                    dispatchInfo({ electricGlass: !information.electricGlass })
                  }
                />
                شیشه برقی
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.engineDoorScrewFenderTrunkHood}
                  onChange={() =>
                    dispatchInfo({
                      engineDoorScrewFenderTrunkHood:
                        !information.engineDoorScrewFenderTrunkHood,
                    })
                  }
                />
                پیچ های درب موتور و گلگیر و صندوق عقب
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.rearSeatMonitor}
                  onChange={() =>
                    dispatchInfo({
                      rearSeatMonitor: !information.rearSeatMonitor,
                    })
                  }
                />
                مانیتور صندلی عقب
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.recordingMonitor}
                  onChange={() =>
                    dispatchInfo({
                      recordingMonitor: !information.recordingMonitor,
                    })
                  }
                />
                مانیتور ضبط
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.burglarAlarm}
                  onChange={() =>
                    dispatchInfo({ burglarAlarm: !information.burglarAlarm })
                  }
                />
                دزدگیر
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.windshield}
                  onChange={() =>
                    dispatchInfo({ windshield: !information.windshield })
                  }
                />
                شیشه و چراغ شور ها جلو و عقب آینه تاشو
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.lighter}
                  onChange={() =>
                    dispatchInfo({ lighter: !information.lighter })
                  }
                />
                فندک
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.sunroof}
                  onChange={() =>
                    dispatchInfo({ sunroof: !information.sunroof })
                  }
                />
                سانروف
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.cruiseControl}
                  onChange={() =>
                    dispatchInfo({ cruiseControl: !information.cruiseControl })
                  }
                />
                کروز کنترل
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.seatHeater}
                  onChange={() =>
                    dispatchInfo({ seatHeater: !information.seatHeater })
                  }
                />
                گرم کن سرد کن صندلی
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.typeRadar}
                  onChange={() =>
                    dispatchInfo({ typeRadar: !information.typeRadar })
                  }
                />
                انواع رادار
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.tronicBrigade}
                  onChange={() =>
                    dispatchInfo({ tronicBrigade: !information.tronicBrigade })
                  }
                />
                تیپ تروئیک
              </Grid>
              <Grid item sm={4} xs={6} lg={3}>
                <Checkbox
                  {...label}
                  checked={information.electricMemoryChair}
                  onChange={() =>
                    dispatchInfo({
                      electricMemoryChair: !information.electricMemoryChair,
                    })
                  }
                />
                صندلی برقی و مموری
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
      <h4 style={classes.gridContainer}>شعبات:</h4>
      <Grid container spacing={2}>
        {agents.map((item) => (
          <Agents
            key={item._id}
            agentId={item._id}
            agenName={item.name}
            activeCheckboxAgent={activeCheckboxAgent}
            handleAgentCheckbox={handleAgentCheckbox}
          />
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={4} style={classes.getContractButton}>
          <Button
            style={{ whiteSpace: "nowrap" }}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => getContract()}
            disabled={contractRequest.loading}
          >
            درخواست قرارداد
            {contractRequest.loading ? (
              <CircularProgress color="secondary" />
            ) : null}
          </Button>
        </Grid>
        <Grid item sm={6} xs={4} style={classes.getContractButton}>
          <CircularIntegration
            title={"ذخیره اطلاعات قرارداد"}
            success={saveContractReq.success}
            loading={saveContractReq.loading}
            onclick={() => {
              saveInfoOfContract();
            }}
          />
        </Grid>
      </Grid>
      {pdfUrl ? (
        <Grid item sm={6} xs={4} style={classes.getContractButton}>
          <a href={pdfUrl} style={{ color: "black", width: "100%" }}>
            <CircularIntegration
              title={"دانلود قرارداد"}
              success={contractRequest.success}
              loading={contractRequest.loading}
              Icon={<PictureAsPdfIcon />}
            />
          </a>
        </Grid>
      ) : null}
      {message !== "" && (
        <AlertDialog
          onClose={() => this.setState({ message: "" })}
          type={"alert"}
          question={false}
          description={message}
        />
      )}
      {showDialogReceiveCode && (
        <DialogReceiveCode
          setShowDialogReceiveCode={setShowDialogReceiveCode}
          setMessage={setMessage}
          infoDispatch={dispatchInfo}
          setReceptionCodeEnteredByUser={setReceptionCodeEnteredByUser}
          dispatchRequestState={dispatchRequestState}
          sendRequest={infoFromCodeReq.loading}
          setInfoFromCodeReq={setInfoFromCodeReq}
          getterFunction={(receptionCode) =>
            ApiHelper.getContractByCode(receptionCode)
          }
        />
      )}
      <SnackBar
        open={requetsState.openNotif}
        handleClose={() => dispatchRequestState({ type: "closeNotif" })}
        message={requetsState.message}
        severity={requetsState.error ? "error" : "success"}
      />
    </>
  );
};

export default DeliveryReceipt;

const classes = {
  gridContainer: {
    marginTop: 20,
  },
  textInput: {
    margin: 10,
  },
  gridInput: {
    display: "flex",
    alignItems: "center",
    maxWidth: 400,
    minWidth: 200,
  },
  gridInputPercents: {
    display: "flex",
    alignItems: "center",
    maxWidth: 550,
    minWidth: 200,
    marginTop: 15,
  },
  progressBarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 330,
  },
  getContractButton: {
    marginTop: 20,
  },
};
