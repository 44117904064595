import { Box, Skeleton } from "@mui/material";
import React from "react";
const LoadingSkeleton = () => {
  return (
    <Box
      sx={{
        borderRadius: 2,
        marginTop: 2,
        width: 1,
        maxWidth: "1600px",
        display: "flex",
        flexDirection: "column",

        border: "1px solid #00000061",
      }}
    >
      {Array.from({ length: 3 }, () => (
        <Box
          key={new Date().getMilliseconds()}
          sx={{
            display: "flex",
            alignItems: "center",
            paddingRight: 4.5,
            paddingLeft: 8,
            paddingBlock: 4,
            flexShrink: 1,
            justifyContent: "space-between",
            width: 1,
            "&:not(:last-child)": {
              borderBottom: "1px solid #0000001F",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "18px" }}>
            <Skeleton
              variant="rectangular"
              sx={{
                bgcolor: "unset",
                border: "1px solid #0000001F",
                width: "100%",
                maxWidth: "18px",
                minWidth: "18px",
              }}
              width={18}
              height={18}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: 1,
                width: "100%",
                maxWidth: "186px",
                minWidth: "90px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: 1 }}
              width={77}
              height={6}
            />
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: 1 }}
              width={101}
              height={6}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: 1 }}
              width={60}
              height={6}
            />
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: 1 }}
              width={60}
              height={1}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Skeleton variant="circular" width={8} height={8} />
            <Skeleton
              variant="rectangular"
              width={64}
              height={4}
              sx={{ borderRadius: 1 }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default LoadingSkeleton;
