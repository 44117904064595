import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { emptyUserId, setUserId } from "../../Store/slice/sliceNewRequest";
const SwitchRequest = () => {
  const dispatch = useDispatch();
  const data = useSelector((store) => store?.newRequest?.data);
  const { userId } = useSelector((store) => store.newRequest);
  const isSetUserId = userId.length > 0;
  const handleOnChange = () => {
    if (!isSetUserId) {
      dispatch(setUserId(data?.at(0)?.userID));
    }
    if (isSetUserId) {
      dispatch(emptyUserId());
    }
  };
  return (
    <FormGroup sx={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        sx={{
          display: "flex",
          color: "text.secondary",
          "& .MuiTypography-root": {
            fontSize: { xl: "16px", lg: "14px" },
          },
        }}
        control={<Switch checked={isSetUserId} onChange={handleOnChange} />}
        label="درخواست های من"
      />
    </FormGroup>
  );
};

export default SwitchRequest;
