import React from "react";
const FilterIcon = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00016 8.91663C6.27631 8.91663 6.50016 8.69277 6.50016 8.41663C6.50016 8.14048 6.27631 7.91663 6.00016 7.91663V8.91663ZM1.3335 7.91663C1.05735 7.91663 0.833496 8.14048 0.833496 8.41663C0.833496 8.69277 1.05735 8.91663 1.3335 8.91663L1.3335 7.91663ZM6.00016 2.08329C5.72402 2.08329 5.50016 2.30715 5.50016 2.58329C5.50016 2.85943 5.72402 3.08329 6.00016 3.08329V2.08329ZM10.6668 3.08329C10.943 3.08329 11.1668 2.85944 11.1668 2.58329C11.1668 2.30715 10.943 2.08329 10.6668 2.08329V3.08329ZM6.00016 7.91663L1.3335 7.91663L1.3335 8.91663L6.00016 8.91663V7.91663ZM6.00016 3.08329L10.6668 3.08329V2.08329L6.00016 2.08329V3.08329ZM0.833496 2.58329C0.833496 3.50377 1.57969 4.24996 2.50016 4.24996V3.24996C2.13197 3.24996 1.8335 2.95148 1.8335 2.58329H0.833496ZM2.50016 4.24996C3.42064 4.24996 4.16683 3.50377 4.16683 2.58329H3.16683C3.16683 2.95148 2.86835 3.24996 2.50016 3.24996V4.24996ZM4.16683 2.58329C4.16683 1.66282 3.42064 0.916626 2.50016 0.916626V1.91663C2.86835 1.91663 3.16683 2.2151 3.16683 2.58329H4.16683ZM2.50016 0.916626C1.57969 0.916626 0.833496 1.66282 0.833496 2.58329H1.8335C1.8335 2.2151 2.13197 1.91663 2.50016 1.91663V0.916626ZM11.1668 8.41663C11.1668 7.49615 10.4206 6.74996 9.50016 6.74996V7.74996C9.86835 7.74996 10.1668 8.04844 10.1668 8.41663L11.1668 8.41663ZM9.50016 6.74996C8.57969 6.74996 7.8335 7.49615 7.8335 8.41663L8.8335 8.41663C8.8335 8.04844 9.13197 7.74996 9.50016 7.74996V6.74996ZM7.8335 8.41663C7.8335 9.3371 8.57969 10.0833 9.50016 10.0833V9.08329C9.13197 9.08329 8.8335 8.78482 8.8335 8.41663L7.8335 8.41663ZM9.50016 10.0833C10.4206 10.0833 11.1668 9.3371 11.1668 8.41663L10.1668 8.41663C10.1668 8.78482 9.86835 9.08329 9.50016 9.08329V10.0833Z"
        fill="black"
        fill-opacity="0.87"
      />
    </svg>
  );
};

export default FilterIcon;
