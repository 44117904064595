exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".linkForm_styleForm__2aFgR {\n  display: flex;\n  flex-direction: column;\n}\n.linkForm_colorType__15qhj {\n  display: flex;\n  align-items: center;\n  margin: 23px 0;\n}\n.linkForm_textFieldStyle__4rmir {\n  margin: 10px 0 !important;\n}\n.linkForm_btnStyle__2_u0j{\n  text-align: left;\n}", ""]);

// Exports
exports.locals = {
	"styleForm": "linkForm_styleForm__2aFgR",
	"colorType": "linkForm_colorType__15qhj",
	"textFieldStyle": "linkForm_textFieldStyle__4rmir",
	"btnStyle": "linkForm_btnStyle__2_u0j"
};