import React from "react";
import { TextField, Button, Box, Grid } from "@material-ui/core";

import style from "./linkForm.module.css";
import ExclusiveColorTag from "../exclusive_color_tag/exclusiveColorTag";

const LinkForm = ({
  colorTags,
  handleColorChange,
  handlerCloseDialog,
  isActiveColor,
  formik,
}) => {
  return (
    <form className={style.styleForm} onSubmit={formik.handleSubmit}>
      <TextField
        name="name"
        value={formik.values.name}
        className={style.textFieldStyle}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        label="نام تگ"
      />
      <TextField
        name="path"
        value={formik.values.path}
        className={style.textFieldStyle}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.path && Boolean(formik.errors.path)}
        helperText={formik.touched.path && formik.errors.path}
        label="مسیر تگ"
      />
      <Box className={style.colorType}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box fontWeight={500} m={1}>
              نوع رنگ:
            </Box>
          </Grid>
          <Grid item xs={10}>
            {colorTags.map((infoColor, index) => (
              <ExclusiveColorTag
                key={index}
                infoColor={infoColor}
                handleColorChange={handleColorChange}
                isActiveColor={isActiveColor}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
      <Box className={style.btnStyle}>
        <Button
          disabled={!(formik.isValid && formik.dirty)}
          type="submit"
          color="primary"
        >
          ثبت
        </Button>
        <Button onClick={handlerCloseDialog} color="secondary">
          بستن
        </Button>
      </Box>
    </form>
  );
};

export default LinkForm;
