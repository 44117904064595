import React, { Component } from "react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import { Styles } from "../../../values/Styles";
import { Chip } from "@material-ui/core";
let apiClient = null;

class SellersList extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();
    this.state = {
      status: props.status, //accepted blocked deleted
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      coworkers: [],
      message: "",
      changeStatusUpdateIndexRequest: -1,
      variant: "outlined",
    };
  }
  componentDidMount() {
    this.getData();
  }

  getData(ref) {
    if (this.state.sendRequest) {
      return;
    }

    if (ref) this.state.data = [];
    this.setState({
      sendRequest: true,
      sendRequestErr: false,
      changeStatusUpdateIndexRequest: -1,
    });
    apiClient.get(
      ApiHelper.getAgents_GET(this.state.status, "seller"),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          if (this.state.status.toString() === "accepted")
            this.setState({ data: response.records });

          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
          });
        }
      }
    );
    apiClient.get(
      ApiHelper.getCoworkers_GET(this.state.status, "coworker"),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          if (this.state.status.toString() === "accepted")
            this.setState({
              coworkers: response.records,
            });

          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            coworkers: response.records,
          });
        }
      }
    );
  }

  handleClick() {}

  render() {
    return (
      <div
        style={{
          ...Styles.contentContainerCol900,
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
          flexWrap: "wrap",
          padding: 10,
          maxWidth: 300,
          flex: 0,
        }}
      >
        <Chip
          label={"همه"}
          onClick={() => this.props.handleSellerClick({})}
          style={{ margin: 5, marginLeft: 10, width: 150, marginBottom: 15 }}
        />
        <Chip
          label={"ادمین"}
          onClick={() => this.props.handleGetAdminBrokers()}
          style={{ margin: 5, marginLeft: 10, width: 150, marginBottom: 15 }}
        />
        {!this.state.sendRequest ? (
          this.state.data.map((seller, index) => {
            return (
              <Chip
                key={seller.phone}
                label={seller.name || "مشکلی در نام وجود دارد"}
                variant={
                  this.props.seller.phone === seller.phone
                    ? "default"
                    : "outlined"
                }
                color={"primary"}
                onClick={() => this.props.handleSellerClick(seller)}
                style={{ margin: 5, width: 150, marginBottom: 15 }}
              />
            );
          })
        ) : (
          <p>در حال دریافت اطلاعات</p>
        )}
        {!this.state.sendRequest ? (
          this.state.coworkers.map((cowoker, index) => {
            return (
              <Chip
                key={cowoker.phone}
                label={cowoker.name || "مشکلی در نام وجود دارد"}
                variant={
                  this.props.seller.phone === cowoker.phone
                    ? "default"
                    : "outlined"
                }
                color={"primary"}
                onClick={() => this.props.handleSellerClick(cowoker)}
                style={{ margin: 5, width: 150, marginBottom: 15 }}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default SellersList;
