import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import Main from "../../common/UserMain";
import { Styles } from "../../../values/Styles";
import RequestState from "../../common/RequestState";
import AlertDialog from "../../common/AlertDialog";
import ListItems from "./ListItems";
import ChangeStatusRequestDialog from "./ChangeStatusRequestDialog";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

let apiClient = null;

@inject("AccountStore")
@inject("ExpertsStore")
@observer
class Experts extends Component {
  constructor(props) {
    super(props);

    apiClient = ApiClient.getInstance();

    if (props.AccountStore.token === "") props.history.replace("/");

    let pageTitle = Experts.getPageTitle(props.match.params.status);
    this.state = {
      status: props.match.params.status,
      pageTitle: pageTitle,
      sendRequest: false,
      sendRequestErr: false,
      sendRequestStatus: "",
      data: [],
      message: "",
      showChangeStatusDialog: false,
      changeStatusUpdateIndexRequest: -1,
    };
  }

  static getPageTitle(page) {
    let pageTitle = "کارشناسان";
    if (page) {
      switch (page) {
        case "blocked":
          pageTitle = "مسدود شده";
          break;
        case "accepted":
          pageTitle = "تایید شده";
          break;
        case "deleted":
          pageTitle = "حذف شده";
          break;
      }
    }
    return pageTitle;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.status !== nextProps.match.params.status) {
      let pageTitle = Experts.getPageTitle(nextProps.match.params.status);
      return {
        status: nextProps.match.params.status,
        pageTitle: pageTitle,
      };
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      this.getData(true);
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData(ref) {
    if (this.state.sendRequest) {
      return;
    }

    if (ref) this.state.data = [];

    this.setState({
      sendRequest: true,
      sendRequestErr: false,
      changeStatusUpdateIndexRequest: -1,
    });

    apiClient.get(
      ApiHelper.getAgents_GET(this.state.status, "expert"),
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            sendRequestErr: true,
            message: response ? response.message : "",
          });
        } else {
          if (this.state.status === "accepted")
            this.props.ExpertsStore.setExperts(response.records);

          this.setState({
            sendRequest: false,
            sendRequestErr: false,
            data: response.records,
          });
        }
      }
    );
  }

  renderResult() {
    if (this.state.data.length <= 0) {
      return (
        <div style={{ margin: 10 }}>
          {`لیست کارشناسان ${this.state.pageTitle} خالی.`}
        </div>
      );
    }
    return (
      <div style={{ ...Styles.containerWrap900, padding: 10 }}>
        {this.state.data.map((item, i) => (
          <ListItems
            key={i}
            name={item.name}
            username={item.username}
            status={item.status}
            onClickEdit={() => {
              this.props.ExpertsStore.setCurrent(item);
              this.props.history.push("/add_edit_expert");
            }}
            onClickStatus={() => this.changeStatus(item)}
            statusIndicator={this.state.changeStatusUpdateIndexRequest === i}
          />
        ))}
      </div>
    );
  }

  changeStatus(item) {
    this.props.ExpertsStore.setCurrent(item);
    this.setState({ showChangeStatusDialog: true });
  }

  updateChangeStatus(id) {
    let i = this.state.data.findIndex((t) => t._id === id);
    this.setState(
      { showChangeStatusDialog: false, changeStatusUpdateIndexRequest: i },
      () => this.getData()
    );
  }

  addNew() {
    this.props.ExpertsStore.emptyCurrent();
    this.props.history.push("/add_edit_expert");
  }

  render() {
    return (
      <Main
        page={"experts/" + this.state.status}
        pageName={"کارشناسان - " + this.state.pageTitle}
        leftAppBarComponent={
          <Fab
            onClick={() => this.addNew()}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
            ایجاد
            <AddIcon />
          </Fab>
        }
        centerAppBarComponent={
          <RequestState
            error={this.state.sendRequestErr}
            retryOnPress={() => this.getData()}
            title={"در حال دریافت لیست ..."}
            request={this.state.sendRequest}
          />
        }
        context={this.props.history}
      >
        <div style={{ ...Styles.centerAlignItemsContainer }}>
          <div style={{ ...Styles.contentContainerCol900, margin: 0 }}>
            {this.renderResult()}
          </div>
        </div>

        {this.state.showChangeStatusDialog && (
          <ChangeStatusRequestDialog
            history={this.props.history}
            onClose={() => this.setState({ showChangeStatusDialog: false })}
            onSuccess={(id) => {
              this.updateChangeStatus(id);
            }}
          />
        )}

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </Main>
    );
  }
}

export default Experts;
