import { Box } from "@mui/material";
import React from "react";
const Team = ({ branch }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingRight: { lg: 10, sm: 3 },
        justifySelf: "start !important",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      <Box
        component="span"
        sx={{
          display: "inline-block",
          width: "8px",
          height: "8px",
          bgcolor:
            branch === "tech"
              ? "#EF5350"
              : branch === "support"
              ? "#FF9800"
              : branch === "management"
              ? "#BA68C8"
              : "",
          borderRadius: "50%",
          marginLeft: 1,
        }}
      ></Box>
      <Box
        sx={{
          fontSize: { xs: 14, sm: 16 },
          color: "#00000099",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {branch === "tech"
          ? "فنی"
          : branch === "support"
          ? "کارشناسی"
          : branch === "management"
          ? "مدیریت"
          : ""}
      </Box>
    </Box>
  );
};

export default Team;
