const totalRequestValue = [
  { key: 1, value: '5' },
  { key: 2, value: '10' },
  { key: 3, value: '15' },
  { key: 4, value: '25' },
  { key: 5, value: '30' },
  { key: 6, value: '35' },
  { key: 7, value: '40' },
];
export default totalRequestValue;
