import React, { useEffect, useState } from "react";
import {
  useChangeAdminStatusMutation,
  useGetOnlyAdminQuery,
} from "../../../Store/callApi/AdminDataService";
import styles from "./admins.module.css";
import userCondition from "../../../values/userCondition";

import moment from "moment-jalaali";
import {
  Divider,
  Slide,
  Box,
  DialogActions,
  Dialog,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogStatus = ({ id, openDialog, setOpenDialog, setShowSnakBar }) => {
  const [adminStatus, setAdminStatus] = useState("");
  const [infoAdmin, setInfoAdmin] = useState({});

  const [changeStatus, { isError }] = useChangeAdminStatusMutation();
  const { data } = useGetOnlyAdminQuery(id);

  useEffect(() => {
    if (data) {
      setInfoAdmin(data.admin);
    }
  }, [data]);

  const handlerUpdatedAt = (timeCreate) => {
    return moment(timeCreate).format("jYYYY/jM/jD");
  };

  const changeStatusAdmin = async () => {
    await changeStatus({ adminId: id, status: adminStatus });
    setShowSnakBar(!isError);
  };

  return (
    <Dialog
      className={styles.statusDialogStyle}
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpenDialog(false)}
    >
      <Box className={styles.statusDialogStyle}>
        <Box>
          <p>{infoAdmin.status}</p>
        </Box>
        <Divider />
        <Box className={styles.dialogTextStyle}>
          <p>{infoAdmin.name}</p>
          <p>{handlerUpdatedAt(infoAdmin.updatedAt)}</p>
        </Box>
        <Divider />
        <Box className={styles.dialogTextStyle}>
          <p>{infoAdmin.username}</p>
          <p>{infoAdmin.type}</p>
        </Box>
        <Divider />
        <Box>
          <p>{infoAdmin.phone}</p>
        </Box>
        <Divider />
        <Box className={styles.selectStatus} mt={4}>
          <TextField
            size="small"
            fullWidth
            select
            label="وضعیت را انتخاب کنید"
            value={adminStatus}
            onChange={(e) => setAdminStatus(e.target.value)}
            variant="outlined"
          >
            {userCondition.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            بستن
          </Button>
          <Button onClick={changeStatusAdmin} color="secondary">
            ثبت
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogStatus;
