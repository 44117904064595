import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Calendar from "../../packages/icons/Calendar";
import Watch from "../../packages/icons/Watch";
import { useDispatch, useSelector } from "react-redux";
import {
  removeActiveRequestDate,
  removeDateFilters,
  setActiveFilterDate,
  setCreatedAt,
  setIsRequestDate,
  setIsReserveDate,
  setIsShowAllDays,
  setReservedAt,
} from "../../Store/slice/sliceNewRequest";
import Cancel from "../../packages/icons/Cancel";
const FilterDate = ({
  onSetIsShowCalendar,
  onSetIsStableCalendar,
  calendarEl,
  isShowReserveDate,
}) => {
  const { isShowAllDays, activeRequestDate, activeFilterDate } = useSelector(
    (store) => store.newRequest
  );
  const dispatch = useDispatch();

  const handleActive = (param) => {
    dispatch(setActiveFilterDate(param));
    onSetIsShowCalendar(true);
    onSetIsStableCalendar(true);
    dispatch(setIsShowAllDays());
  };
  const handleRequestDate = () => {
    dispatch(setCreatedAt());
    dispatch(setIsRequestDate());
  };
  const handleReserveDate = () => {
    dispatch(setReservedAt());
    dispatch(setIsReserveDate());
  };
  const handleMouseEnter = () => onSetIsShowCalendar(true);
  const handleMouseLeave = () => onSetIsShowCalendar(false);
  const handleRemoveDateFilter = () => {
    dispatch(removeDateFilters());
    dispatch(setActiveFilterDate(""));
    dispatch(removeActiveRequestDate());
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (calendarEl.current && !calendarEl.current.contains(e.target)) {
        onSetIsStableCalendar(false);
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => document.removeEventListener("click", handleClick, true);
  }, []);

  useEffect(() => {
    dispatch(setActiveFilterDate(activeRequestDate));
  }, [activeRequestDate]);
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 4,
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: "text.secondary", fontSize: 14 }}
        >
          فیلتربراساس:
        </Typography>
        <Box>
          <Button
            startIcon={<Calendar />}
            variant="outlined"
            sx={{
              color:
                activeFilterDate === "submit"
                  ? "text.primary"
                  : "text.secondary",
              bgcolor: activeFilterDate === "submit" ? "#2196F314" : "unset",
              borderRadius: isShowReserveDate ? "0 100px 100px 0" : "100px",
              fontSize: 12,
              gap: 1,
              width: 144,
              padding: "5px 7px",
              "& .MuiButton-startIcon svg path": {
                fill: activeFilterDate === "submit" ? "#2196F3" : "#00000099",
              },
              "& .MuiButton-startIcon": {
                marginLeft: "unset",
                marginRight: "unset",
              },
            }}
            onClick={() => {
              handleActive("submit");
              handleRequestDate();
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            تاریخ ثبت درخواست
          </Button>
          {isShowReserveDate && (
            <Button
              variant="outlined"
              startIcon={<Watch />}
              sx={{
                color:
                  activeFilterDate === "reserve"
                    ? "text.primary"
                    : "text.secondary",
                bgcolor: activeFilterDate === "reserve" ? "#2196F314" : "unset",
                borderRadius: "100px 0 0 100px ",
                fontSize: 12,
                gap: 1,
                width: 144,
                "& .MuiButton-startIcon svg path": {
                  stroke:
                    activeFilterDate === "reserve" ? "#2196F3" : "#00000099",
                },
                "& .MuiButton-startIcon": {
                  marginLeft: "unset",
                  marginRight: "unset",
                },
              }}
              onClick={() => {
                handleActive("reserve");
                handleReserveDate();
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              تاریخ رزرو
            </Button>
          )}
        </Box>
      </Box>
      {isShowAllDays && (
        <Button
          sx={{
            position: "absolute",
            left: "13%",
            transform: "translate(-2%, 50%) !important",
            color: "error.light",
            borderColor: "error.light",
            "& .MuiButton-startIcon": {
              marginLeft: "8px",
            },
          }}
          startIcon={<Cancel />}
          variant="outlined"
          onClick={handleRemoveDateFilter}
        >
          حذف فیلتر تاریخ
        </Button>
      )}
    </Box>
  );
};

export default FilterDate;
