import firebase from "firebase/app";
import "firebase/messaging";

let messaging = null;
if (firebase.messaging.isSupported()) {
  const initializedFirebaseApp = firebase.initializeApp({
    // Project Settings => Add Firebase to your web app
    apiKey: "AIzaSyBK3knxyddXr7Weevr7tG3MT3XtF4-5J94",
    authDomain: "nikbakht-khodro.firebaseapp.com",
    projectId: "nikbakht-khodro",
    storageBucket: "nikbakht-khodro.appspot.com",
    messagingSenderId: "767302353950",
    appId: "1:767302353950:web:71ef1fd9c03acb62079a0f",
    measurementId: "G-XJSNX5MWRJ",
  });
  messaging = initializedFirebaseApp.messaging();
}
export { messaging };
