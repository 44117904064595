import React, { useState } from "react";
import FilterSwitchOne from "../filters/filterSwitchOne";
import { useUpdateAgentStatusMutation } from "../../Store/callApi/AdminDataService";
import SnackBar from "../../components/common/SnackBar";
export default function ShutDownSwitch({ agentID, ShutDownState }) {
  const [showSnackBar, setShowSnackBar] = useState({
    isSuccess: false,
    isUnSuccess: false,
    message: "",
  });
  const [updateAgentStatus] = useUpdateAgentStatusMutation();
  const [isShutDown, setIsShutDown] = useState(ShutDownState);
  const shutDown = {
    shutDown: !isShutDown,
  };
  const handlerIsActive = async () => {
    try {
      const res = await updateAgentStatus({ agentID, shutDown });

      if (res.data.success) {
        setShowSnackBar((prev) => ({
          ...prev,
          isSuccess: true,
          message: res.data.message,
        }));
        setIsShutDown(!isShutDown);
      }
    } catch (error) {
      setShowSnackBar((prev) => ({
        ...prev,
        isUnSuccess: true,
        message: error.response?.data?.message || error.message,
      }));
    }
  };
  return (
    <>
      <FilterSwitchOne
        handlertypeDate={handlerIsActive}
        title="وضعیت نمایندگی"
        switchOn="فعال"
        switchOff="غیر فعال"
        defaultState={!ShutDownState}
      />
      <SnackBar
        variant={showSnackBar.isSuccess ? "success" : "error"}
        message={showSnackBar.message}
        open={showSnackBar.isSuccess || showSnackBar.isUnSuccess}
        onClose={() =>
          setShowSnackBar({
            isSuccess: false,
            isUnSuccess: false,
            message: "",
          })
        }
      />
    </>
  );
}
