import React from "react";
import CheckboxContactUs from "./CheckboxContactUs";
import { Box, useMediaQuery } from "@mui/material";
import DateContactUs from "./DateContactUs";
import OpenModalButton from "./OpenModalButton";
import Team from "./Team";
import { dateFormatter } from "../../utils/persianDate/dateFormatter";
const GridContainer = ({ message = {} }) => {
  const { _id, createdAt, name, branch } = message;
  const maxWidth600 = useMediaQuery("(max-width: 600px)");
  return (
    <Box
      sx={[
        {
          width: 1,
          display: "grid",
          // gridTemplateColumns: "3fr 1.5fr 1.5fr 1.5fr",
          gridTemplateColumns: {
            lg: "2fr 2fr 2fr 2fr",
            sm: "repeat(4, 1fr)",
            xs: "1fr",
          },
          alignItems: "center",
          paddingBlock: 2,
          "& :not(:first-child)": {
            justifySelf: { xs: "start", sm: "center" },
          },
          "&:not(:last-child)": {
            borderBottom: "1px solid #0000001F",
          },
        },
        maxWidth600 && {
          "& div:last-child": {
            gridRow: 2,
          },
          "& >*:not(:first-child)": {
            marginRight: 2.5,
          },

          "&": {
            gridAutoRows: "40px",
          },
        },
      ]}
      id={_id}
    >
      <CheckboxContactUs id={_id} name={name} />
      <DateContactUs
        clock={dateFormatter(createdAt, false).clock().slice(0, 5)}
        month={dateFormatter(createdAt, false).shortMonth()}
      />
      <OpenModalButton message={message} />
      <Team branch={branch} />
    </Box>
  );
};

export default GridContainer;
