import React, { useEffect, useState } from "react";
import { ApiClient, ApiHelper } from "../../../data/remote";
import { useListShutdownDays } from "../../../Hooks/useListShutdownDays";
import DeleteIcon from "@material-ui/icons/Delete";
import style from "./timeManagement.module.css";
import DatePickerCom from "../../common/DatePicker/DatePicker";
import AlertDialog from "../../common/AlertDialog";
import SnackBar from "../../common/SnackBar";
import { Close } from "@material-ui/icons";
import {
  Dialog,
  List,
  ListItemText,
  IconButton,
  MenuItem,
  Tooltip,
  Grid,
  Divider,
} from "@material-ui/core";
import { _ } from "core-js";
let moment = require("moment-jalaali");
moment.loadPersian({ dialect: "persian-modern" });
const DialogListShutDownDays = (props) => {
  let { OpenDialogDelete, setOpenDialogDelete, DateServer, setChoiceDay } =
    props;
  let apiClient = ApiClient.getInstance();
  const { listOffDaye, getListOffDays } = useListShutdownDays();
  const [stateListOffDay, setStateListOffDay] = useState([]);
  const [stateSnackBar, setStateSnackBar] = useState({
    successSnackBar: false,
    warningSnackBar: false,
  });

  const [DeleteDay, setDeleteDay] = useState({
    message: "",
    show: false,
    date: "",
  });
  const [StartEndDay, setStartEndDay] = useState({
    start: "",
    end: "",
  });

  const handlerStatusDay = (date) => {
    if (date == DateServer) {
      setChoiceDay((prev) => ({ ...prev, isChecked: true }));
    }
  };

  const deleteShutdownDay = (date) => {
    apiClient.delete(ApiHelper.shutdownDay_DELETE + date, (error, response) => {
      if (error) {
        setStateSnackBar((prev) => ({ ...prev, warningSnackBar: true }));
      } else {
        if (response.success) {
          setStateSnackBar((prev) => ({ ...prev, successSnackBar: true }));
          setDeleteDay((prev) => ({
            ...prev,
            show: false,
            message: response.message,
          }));
          handlerStatusDay(date);
          const updatedItems = stateListOffDay.filter((item) => {
            return item.date.split("T")[0] !== date;
          });
          setStateListOffDay(updatedItems);
        }
      }
    });
  };

  const handlerNotHoliDay = () => {
    let message = "";
    if (stateListOffDay?.length < 1) {
      message =
        StartEndDay.end && StartEndDay.start
          ? "هیچ روز تعطیلی در این بازه ثبت نشده است."
          : "هیچ روز تعطیلی در 20 روز اخیر ثبت نشده است.";
    }
    return message && <h4 className={style.DialogListOff}>{message}</h4>;
  };

  const handlerFormatMoment = (date) => {
    return moment(date).format("dddd jD jMMMM jYYYY");
  };

  useEffect(() => {
    setStateListOffDay(listOffDaye);
  }, [listOffDaye]);

  useEffect(() => {
    if (StartEndDay.end && StartEndDay.start) {
      getListOffDays(StartEndDay.start, StartEndDay.end);
    }
  }, [StartEndDay]);

  return (
    <>
      <Dialog open={OpenDialogDelete}>
        <div className={style.BoxDialogshutdown}>
          <div>
            <Tooltip title="بستن" placement="left">
              <Close
                onClick={() => setOpenDialogDelete(false)}
                className={style.CloseBox}
              />
            </Tooltip>
            <h3> روزهای تعطیل</h3>
          </div>
          <Divider />
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h4>شروع روز</h4>
              <DatePickerCom
                className={style.DatePicker}
                onchange={(e) =>
                  setStartEndDay((prev) => ({
                    ...prev,
                    start: e.value.toISOString(e),
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h4>پایان روز</h4>
              <DatePickerCom
                className={style.DatePicker}
                onchange={(e) => {
                  setStartEndDay((prev) => ({
                    ...prev,
                    end: e.value.toISOString(e),
                  }));
                }}
              />
            </Grid>
          </Grid>
          <List>
            {handlerNotHoliDay()}
            {stateListOffDay?.map((item, index) => (
              <MenuItem className={style.DialogListshutDownDay} key={index}>
                <div>
                  <ListItemText primary={item.occasion} />
                  <span className={style.dateShutDownDay}>
                    {handlerFormatMoment(item.date)}
                  </span>
                </div>
                <Tooltip placement="right-end" title="حذف">
                  <IconButton
                    style={{ color: "#b30808" }}
                    className={style.DialogIconeDelete}
                    edge="end"
                    onClick={() =>
                      setDeleteDay((prev) => ({
                        ...prev,
                        show: true,
                        date: item.date.split("T")[0],
                      }))
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
          </List>
        </div>
        {DeleteDay.show && (
          <AlertDialog
            onClose={({ yesPress }) => {
              if (yesPress) {
                deleteShutdownDay(DeleteDay.date);
              } else {
                setDeleteDay((prev) => ({ ...prev, show: false }));
              }
            }}
            question={true}
            description={"همکار محترم از حذف روز مطمئن هستین؟"}
            negativeTextButton={"خیر"}
            positiveTextButton={"بله"}
          />
        )}
      </Dialog>
      <SnackBar
        variant={"success"}
        message={DeleteDay.message}
        open={stateSnackBar.successSnackBar}
        onClose={() =>
          setStateSnackBar((prev) => ({ ...prev, successSnackBar: false }))
        }
      />
      <SnackBar
        variant={"warning"}
        message="خطایی درخ داده است لطفا مجددا تلاش نمایید."
        open={stateSnackBar.warningSnackBar}
        onClose={() =>
          setStateSnackBar((prev) => ({ ...prev, warningSnackBar: false }))
        }
      />
    </>
  );
};
export default DialogListShutDownDays;
