import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import {
  deleteFromCheck,
  updateRTK,
} from "../../../Store/slice/getErrorsParams";
import FilterIcon from "../../../packages/icons/FilterIcon";
import { filterMobileStyles } from "../../../styles/filterMobile";
import DrawerFilter from "../../common/Drawer/DrawerFilter";
import { useSelector } from "react-redux";
import ButtonFilterd from "../../common/Drawer/ButtonFilterd";
import { criteriaLevel, topics } from "../../../values/errorPage/values";

const FilterMobile = () => {
  const { titles } = useSelector((store) => store.getErrorsParams);
  const [isOpen, setisOpen] = useState(false);

  const filterMobile = filterMobileStyles();
  const toggleDrawer = () => {
    setisOpen((cur) => !cur);
  };

  return (
    <>
      <Box className={filterMobile.container}>
        <Box container item component="div" className={filterMobile.child}>
          <Button
            startIcon={<FilterIcon />}
            variant="outlined"
            className={filterMobile.btn}
            onClick={toggleDrawer}
          >
            فیلترها
          </Button>
          <Box component="div" className={filterMobile.child2}>
            {titles?.map((title) => (
              <ButtonFilterd
                key={title.id}
                title={title}
                deleteFromStore={deleteFromCheck}
                updateStore={updateRTK}
                number={7}
                firstSection={topics}
                secondSection={criteriaLevel}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <DrawerFilter isOpen={isOpen} onToggler={toggleDrawer} />
    </>
  );
};

export default FilterMobile;
