import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import AlertDialog from "../../common/AlertDialog";
import style from "./timeManagement.module.css";
import { GoldenSwitch } from "../../../styles/colors_mui";
import { Statement } from "../../../values/Statement";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDataCar } from "./../../../Store/slice/SliceDataRequestCar";
import { BranchField } from "../../../Hooks/BranchField";
import { ApiClient, ApiHelper } from "../../../data/remote";
import moment from "moment-jalaali";
import { timeOneHourAhead } from "../../../Hooks/timeOneHourAhead";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const InfoTable = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatDate } = timeOneHourAhead();
  const classes = useStyles();
  const [tooltip, setTooltip] = useState({
    EditRequest: false,
    DeleteRequest: false,
    requestID: "",
  });

  const apiClient = ApiClient.getInstance();

  const handleDelete = (requestID) => {
    apiClient.get(
      ApiHelper.changeStatusRequest_GET("done"),
      (error, response) => {
        if (error) {
        } else {
        }
      },
      {
        id: requestID,
        sendSMS: 1,
        deleteReq: 1,
      }
    );
    props.deleteRequest(props.data.filter((item) => item._id !== requestID));
  };

  const handleEdit = (row) => {
    dispatch(setDataCar(row));
    setTooltip((prev) => ({ ...prev, EditRequest: true }));
  };

  const handlerDay = (day) => {
    return moment(day).format("dddd jD jMMMM jYYYY");
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={style.HeaderRow}>
              <TableCell className={style.HeaderTable}>ویرایش/حذف</TableCell>
              <TableCell
                style={{ paddingRight: "32px" }}
                className={style.HeaderTable}
              >
                VIP
              </TableCell>
              <TableCell className={style.HeaderTable}>
                تاریخ کارشناسی
              </TableCell>
              <TableCell className={style.HeaderTable}>ساعت کارشناسی</TableCell>
              <TableCell className={style.HeaderTable}>نام شعبه</TableCell>
              <TableCell className={style.HeaderTable}>نام برند</TableCell>
              <TableCell className={style.HeaderTable}>مدل</TableCell>
              <TableCell align="right" className={style.HeaderTable}>
                شماره پذیرش
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => (
              <TableRow
                key={index}
                className={index % 2 === 0 ? "" : style.tableColorRow}
              >
                <TableCell>
                  <IconButton
                    onClick={() => handleEdit(row)}
                    className={style.EditRequest}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setTooltip((prev) => ({
                        ...prev,
                        DeleteRequest: true,
                        requestID: row._id,
                      }));
                    }}
                    className={style.DeleteRequest}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  <GoldenSwitch checked={row.vip} name="checkedA" />
                </TableCell>
                <TableCell>{handlerDay(row.reserveDatetime)}</TableCell>
                <TableCell className={style.tableTextCenter}>
                  {formatDate(row.reserveDatetime)}
                </TableCell>
                <TableCell>
                  <BranchField idAgent={row.reserveLocation} />
                </TableCell>
                <TableCell>{row.sellCarInfo.brand}</TableCell>
                <TableCell>{row.sellCarInfo.model}</TableCell>
                <TableCell align="right">{row.userReceptionCode}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {tooltip.EditRequest && (
        <AlertDialog
          onClose={({ yesPress }) => {
            if (yesPress) {
              history.push({
                pathname: "/add_sell_request",
                RequestEdit: "Edit",
              });
            }
            setTooltip((prev) => ({ ...prev, EditRequest: false }));
          }}
          question={true}
          description={Statement.EditsellRequestCar}
          negativeTextButton={"خیر"}
          positiveTextButton={"بله"}
        />
      )}

      {tooltip.DeleteRequest && (
        <AlertDialog
          onClose={({ yesPress }) => {
            if (yesPress) {
              handleDelete(tooltip.requestID);
              setTooltip((prev) => ({ ...prev, requestID: "" }));
            }
            setTooltip((prev) => ({ ...prev, DeleteRequest: false }));
          }}
          question={true}
          description={Statement.DeletesellRequestCar}
          negativeTextButton={"خیر"}
          positiveTextButton={"بله"}
        />
      )}
    </>
  );
};

export default InfoTable;
