import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import { Colors } from "../../values/Colors";

const imgAlert = require("../../assets/images/icons_8_attention.svg");
const imgError = require("../../assets/images/icons_8_cancel.svg");
const imgWarn = require("../../assets/images/icons_8_error.svg");
const imgSuccess = require("../../assets/images/icons8-ok-100.png");
const imgDefault = require("../../assets/images/icons8-happy-100.png");

function AlertDialog(props) {
  const color = () => {
    switch (props.type) {
      case "error":
        return Colors.colorAccent;
      case "warn":
        return Colors.colorAccent;
      case "alert":
        return Colors.colorAccent;
      case "success":
        return Colors.colorAccent;
      default:
        return Colors.colorAccent;
    }
  };

  const image = () => {
    switch (props.type) {
      case "error":
        return imgError;
      case "warn":
        return imgWarn;
      case "alert":
        return imgAlert;
      case "success":
        return imgSuccess;
      default:
        return imgDefault;
    }
  };

  const title = () => {
    switch (props.type) {
      case "error":
        return "خطا";
      case "warn":
        return "هشدار";
      case "alert":
        return "توجه";
      case "success":
        return "موفق";
      default:
        return "توجه";
    }
  };

  function handleClose(yesPress = false) {
    props.onClose({ yesPress });
  }

  function secondaryAction() {
    props.secondaryAction();
  }

  return (
    <div style={{ userSelect: "none" }}>
      <Dialog
        open={true}
        onClose={() => handleClose(!props.question)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={styles.content}>
          <img
            width={30}
            height={30}
            style={{ objectFit: "contain", margin: 5 }}
            src={image()}
          />
          {/*<h3 style={{...styles.title, color: color()}}*/}
          {/*    id="alert-dialog-title">{props.title ? props.title : title()}</h3>*/}

          <div id="alert-dialog-description" style={styles.description}>
            {props.description}
          </div>
        </div>
        <div style={styles.actionContent}>
          <Button
            fullWidth={true}
            style={{ borderRadius: 5 }}
            size="large"
            onClick={() => handleClose(true)}
            color="inherit"
          >
            {props.question ? (
              <p
                style={{
                  margin: 5,
                  fontSize: 14,
                  color: props.positiveTextButtonColor
                    ? props.positiveTextButtonColor
                    : color(),
                }}
              >
                {" "}
                {props.positiveTextButton}
              </p>
            ) : (
              <p style={{ margin: 5, fontSize: 14, color: color() }}>خب</p>
            )}
          </Button>
          {props.question && (
            <div style={{ width: 2, height: 38, backgroundColor: "#d2d2d2" }} />
          )}
          {props.question && (
            <Button
              fullWidth={true}
              style={{
                borderRadius: 5,
                fontSize: 14,
                color:
                  props.negativeTextButtonColor &&
                  props.negativeTextButtonColor,
              }}
              color={"inherit"}
              size="large"
              onClick={() => {
                props.secondaryActionState ? secondaryAction() : handleClose();
              }}
            >
              {props.negativeTextButton}
            </Button>
          )}
        </div>
      </Dialog>
    </div>
  );
}

AlertDialog.defaultProps = {
  question: true,
  positiveTextButton: "بله",
  negativeTextButton: "خیر",
};

AlertDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.any,
  question: PropTypes.bool,
  type: PropTypes.string,
  positiveTextButton: PropTypes.string,
  negativeTextButton: PropTypes.string,
  negativeTextButtonColor: PropTypes.string,
  positiveTextButtonColor: PropTypes.string,
};

const styles = {
  header: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    margin: 10,
    minWidth: 230,
  },
  title: {
    width: "90%",
    textAlign: "center",
    margin: 2,
  },
  description: {
    marginTop: 10,
    padding: 10,
    textAlign: "right",
    color: Colors.colorPrimaryLight,
    fontSize: 15,
    whiteSpace: "pre-line",
    lineHeight: "170%",
  },
  actionContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: "#d2d2d2",
    borderTopStyle: "solid",
  },
};

export default AlertDialog;
