import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { ApiClient, ApiHelper } from "../../../data/remote";
import { CircularProgress } from "@material-ui/core";
import AlertDialog from "../../common/AlertDialog";
import { inject, observer } from "mobx-react";

let apiClient = null;

@inject("LocationsStore")
@observer
class AddEditDialog extends Component {
  constructor(props) {
    super(props);
    apiClient = ApiClient.getInstance();

    this.state = {
      sendRequest: false,
      message: "",
    };
  }

  handleClose() {
    this.props.onClose();
  }

  submit() {
    if (this.state.sendRequest) return;

    let data = this.props.LocationsStore.getCurrent();

    this.setState({ sendRequest: true, inputWarn: 0 });

    apiClient.post(
      ApiHelper.addEditLocation_POST,
      (error, response) => {
        if (error) {
          this.setState({
            sendRequest: false,
            message: response ? response.message : "",
          });
        } else {
          this.setState({ sendRequest: false }, () =>
            this.props.onSuccess(response.data)
          );
        }
      },
      data
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: "100%", padding: 5 }}>
            <div style={{ position: "relative" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  onChange={(t) =>
                    (this.props.LocationsStore.current.location =
                      t.target.value)
                  }
                  style={{ margin: 10, flex: 1 }}
                  defaultValue={this.props.LocationsStore.current.location}
                  label={"نام شهر"}
                  placeholder={"نام شهر"}
                  variant="outlined"
                  margin={"dense"}
                  inputProps={{ style: { fontSize: 15 }, maxLength: 200 }}
                />
              </div>
            </div>
          </DialogContent>

          {this.state.sendRequest ? (
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={25} />
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={() => this.submit()} color="primary">
                ثبت
              </Button>

              <Button onClick={() => this.handleClose()} color="secondary">
                بستن
              </Button>
            </DialogActions>
          )}
        </Dialog>

        {this.state.message !== "" && (
          <AlertDialog
            onClose={() => this.setState({ message: "" })}
            type={"alert"}
            question={false}
            description={this.state.message}
          />
        )}
      </div>
    );
  }
}

AddEditDialog.defultProps = {};

AddEditDialog.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
export default AddEditDialog;
